
import { takeLatest, call, put, takeEvery } from 'redux-saga/effects';
import ApiServices from '../../graphqlApiServices';
import screeningReducer from '../reducers/screening.reducer';
import students from '../../graphqlApiServices/students';

const { screening } = ApiServices;

const { getScreeningById, updateScreeningPub, getOpsScreening, getOpsDashboard, getScreeningByStudentId, getManagerStudentsScreeningOps, getReport, getBehavioralScreeningById, postMasimoPhoto, toggleDevice, getBhvReport, getOperator, integrateHigo, welchallynIntegrate, uploadReport } = screening;
const { getStudentByID } = students;

export function* watchScreenRequests() {
    yield takeLatest(screeningReducer.actions.getScreenDashboardReq, getOpsDashboardSaga);
    yield takeLatest(screeningReducer.actions.getOpsTeamScreenReq, getOpsTeamScreenSaga);
    yield takeLatest(screeningReducer.actions.getScreeningByIdRequest, getScreenSaga);
    yield takeLatest(screeningReducer.actions.getBhvScreeningByIdRequest, getBhvScreenSaga);
    yield takeLatest(screeningReducer.actions.updateScreeningByIdRequest, updateScreenSaga);
    yield takeLatest(screeningReducer.actions.getScreeningByStudentIdRequest, getScreeningByStudentIdRequestSaga);
    yield takeLatest(screeningReducer.actions.getOpsManagerStudentScreeningRequest, getOpsScreenSaga)
    yield takeLatest(screeningReducer.actions.getReportRequest, getReportSaga)
    yield takeEvery(screeningReducer.actions.getBulkReportRequest, getBulkReportSaga)
    yield takeLatest(screeningReducer.actions.postMasimoPhotoReq, postMasimoPhotoSaga)
    yield takeLatest(screeningReducer.actions.toggleDeviceReq, toggleDevice);
    yield takeLatest(screeningReducer.actions.getOperatorReq, getOperatorSaga);
    yield takeLatest(screeningReducer.actions.higoInteReq, integrateHigoSaga);
    yield takeLatest(screeningReducer.actions.getWelchallynReq, getWelchallynLinkSaga);
    yield takeLatest(screeningReducer.actions.uploadReportRequest, uploadReportSaga);
}


export function* getOpsDashboardSaga(payload) {
    try {
        const response = yield call(getOpsDashboard, payload);
        const data = response.data.getOpsDashboard
        yield put(screeningReducer.actions.getScreenDashboardSuccesss(data))
    } catch (error) {

        yield put(screeningReducer.actions.getScreenDashboardfail({ error }))
    }
}

export function* uploadReportSaga(payload) {
    try {
        const response = yield call(uploadReport, payload);
        const data = response
        console.log(data)
        yield put(screeningReducer.actions.uploadReportSuccess(data))
    } catch (error) {
        console.log(error)
        yield put(screeningReducer.actions.uploadReportFailure({ error }))
    }
}

export function* getOpsTeamScreenSaga(payload) {
    try {
        const response = yield call(getOpsScreening, payload.payload);
        const data = response.data.getScreening
        yield put(screeningReducer.actions.getOpsTeamScreenSuccess(data))
    } catch (error) {

        yield put(screeningReducer.actions.getOpsTeamScreenfail({ error }))
    }
}


export function* getScreenSaga(payload) {
    try {
        const response = yield call(getScreeningById, payload);
        const data = response?.data?.getScreeningFiles
        yield put(screeningReducer.actions.getScreeningByIdSuccess({ data }))
    } catch (error) {

        yield put(screeningReducer.actions.getScreeningByIdFail({ error }))
    }
}


export function* getBhvScreenSaga(payload) {
    try {
        const response = yield call(getBehavioralScreeningById, payload);
        let data = response.data;
        yield put(screeningReducer.actions.getBhvScreeningByIdSuccess({ data }))
    } catch (error) {
        if (error?.data) {
            yield put(screeningReducer.actions.getBhvScreeningByIdSuccess({ data: error?.data }))
        } else {
            yield put(screeningReducer.actions.getBhvScreeningByIdFail({ error }))
        }
    }
}

export function* getScreeningByStudentIdRequestSaga(payload) {
    const payL = {
        payload: {
            studentId: payload.payload
        }
    }
    try {
        const response = yield call(getStudentByID, payL);
        const data = response.data.getStudentByID.screenings;
        console.log(data);
        if (data?.length) {
            yield put(screeningReducer.actions.getScreeningByIdRequest(data[data.length - 1]));
        }
    } catch (error) {

        // yield put(screeningReducer.actions.getScreenDashboardfail({ error }))
    }
}

export function* getOpsScreenSaga(payload) {
    try {
        const response = yield call(getManagerStudentsScreeningOps, payload);

        const data = response.data.getScreeningOps
        yield put(screeningReducer.actions.getOpsManagerStudentScreeningSuccess({ data }))
    } catch (error) {

        if (error.data?.getScreeningOps?.length > 0) {
            const data = error.data.getScreeningOps
            yield put(screeningReducer.actions.getOpsManagerStudentScreeningSuccess({ data }))
        } else {
            yield put(screeningReducer.actions.getOpsManagerStudentScreeningFail({ error }))
        }
    }
}



export function* updateScreenSaga(payload) {
    try {
        const response = yield call(updateScreeningPub, payload.payload);
        yield put(screeningReducer.actions.updateScreeningByIdSuccess({ response }))
    } catch (error) {

        yield put(screeningReducer.actions.updateScreeningByIdFail({ error }))
    }
}

export function* getReportSaga(payload) {
    let response;
    let data;
    try {
        if (payload.payload.report === 'bhv') {
            response = yield call(getBhvReport, payload);
            data = JSON.parse(response.data.getBhvReport?.data)
        } else {
            response = yield call(getReport, payload);
            data = JSON.parse(response.data.getReport?.data)
        }
        yield put(screeningReducer.actions.getReportSuccess(data))
    } catch (error) {

        if (error.data?.getReport?.length > 0) {
            const data = error.data.getReport.data
            yield put(screeningReducer.actions.getReportSuccess(data))
        } else {
            yield put(screeningReducer.actions.getReportFailure({ error }))
        }
    }
}

export function* getBulkReportSaga(payload) {
    let response;
    let data;
    try {
        if (payload.payload.report === 'bhv') {
            response = yield call(getBhvReport, payload);
            data = JSON.parse(response.data.getBhvReport?.data)
        } else {
            response = yield call(getReport, payload);
            data = JSON.parse(response.data.getReport?.data)
        }
        yield put(screeningReducer.actions.getBulkReportSuccess(data))
    } catch (error) {
        
        if (error.data?.getReport?.length > 0) {
            const data = error.data.getReport.data
            yield put(screeningReducer.actions.getBulkReportSuccess(data))
        } else {
            yield put(screeningReducer.actions.getBulkReportFailure({ error }))
        }
    }
}


export function* postMasimoPhotoSaga(payload) {
    try {

        const response = yield call(postMasimoPhoto, payload);

    } catch (error) {

    }
}




export function* integrateHigoSaga(payload) {

    try {
        yield call(integrateHigo, payload);
    } catch (error) {

    }
}

export function* getOperatorSaga(payload) {
    try {
        const response = yield call(getOperator, payload);
        const data = response.data.getHigoOperators;
        yield put(screeningReducer.actions.getOperatorSuccess(data))
    } catch (error) {
        yield put(screeningReducer.actions.getOperatorFailure({ error }))
    }
}


export function* getWelchallynLinkSaga(payload) {
    try {
        const response = yield call(welchallynIntegrate, payload);
        const data = response.data.getScreeningCsv.data;
        // const data = response.data.getHigoOperators;
        yield put(screeningReducer.actions.getWelchallynReqSuccess(JSON.parse(data)))
    } catch (error) {
        yield put(screeningReducer.actions.getWelchallynReqFailure({ error }))
    }
}


// export function* updateScreeningSubSaga() {
//     try {
//         const response = yield call(updateScreeningSub)
//
//     } catch (error) {
//         yield put(screeningReducer.actions.updateScreeningByIdFail({ error }))
//     }
// }