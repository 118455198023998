import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import customise from "../../../assets/images/customise.svg";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import classes from "./AppointmentsSideBar.module.css";
import Typography from "@mui/material/Typography";
import { Close, RemoveRedEye, Visibility } from "@mui/icons-material";
import { Avatar, Button } from "@mui/material";
import { getAge, getRisk, stringAvatar, stringToColor } from "../../../utils/utility";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

export default function AppointmentsSideBar({
  data,
}) {
  const [state, setState] = React.useState({
    right: false,
  });
  const [assessmentData, setAssessmentData] = useState(JSON.parse(data?.[data?.package[0]]))
  const [consultationData, setConsultationData] = useState(JSON.parse(data?.consultation))
  const navigate = useNavigate()

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  function convertToTitleCase(str) {
    return str.toLowerCase()
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  // get report 
  const getReport = () => {
    switch (convertToTitleCase(data?.package[0])) {
      case "Adhd": return '/d2c-report/ADHD'
      case "Autism": return '/d2c-report/AUTISM'
      case "Internet Addiction": return '/d2c-report/DIGITAL_DEPENDENCY'
      case "Anxiety": return '/d2c-report/ANXIETY'
      case "Conduct Disorder": return "/d2c-report/CONDUCT_DISORDER"
      case "Oppositional Defiant Disorder": return "/d2c-report/OPPOSITIONAL_DEFIANT_DISORDER"
      case "Depression": return "/d2c-report/DEPRESSION"
      case "Developmental Delay": return "/d2c-report/DEVELOPMENTAL_DELAY"
      case "Dyslexia 1": return "/d2c-report/DYSLEXIA"
      case "Dyslexia 2": return "/d2c-report/DYSLEXIA"
      case "Dyscalculia 1": return "/d2c-report/DYSCALCULIA"
      case "Dyscalculia 2": return "/d2c-report/DYSCALCULIA"
      case "Dysgraphia 1": return "/d2c-report/DYSGRAPHIA"
      case "Dysgraphia 2": return "/d2c-report/DYSGRAPHIA"
    }
  }
  const setLocalStorage = () => {
    const x = { ...data }
    x['student'] = data
    localStorage.setItem("assessment", JSON.stringify(x))
    localStorage.setItem('sId', x?.screeningId)
    localStorage.setItem('name', x?.name)
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 600 }}
      role="presentation"
    // onClick={toggleDrawer(anchor, true)}
    // onKeyDown={toggleDrawer(anchor, true)}
    >
      <div className={classes.container}>
        <div className={classes.customise_header}>
          <img src={customise} alt="customise" />
          <p>Quick View</p>
          <Close
            onClick={toggleDrawer(anchor, !anchor)}
            style={{ cursor: "pointer" }}
          />
        </div>

        {/* avatar  */}
        <div className={classes.header}>
          <Avatar {...stringAvatar(data?.name)} sx={{
            width: '64px',
            height: '64px',
            fontSize: '40px',
            fontWeight: '600',
            lineHeight: '64px',
            bgcolor: stringToColor(data?.name)
          }} />
          <h1>{data?.name}</h1>
          <p>{getAge(data?.DOB)} yrs</p>
          <button onClick={() => navigate(`/behavioral-doctor/info/${data?.name}`, { state: data })}>VIEW PROFILE</button>
        </div>

        {/* assesment  */}
        <div className={classes.assessment}>
          <div>
            <div>Assessment</div>
            <div>Score</div>
            <div>Risk</div>
          </div>
          <div>
            <div>{convertToTitleCase(data?.package[0])}</div>
            <div>{assessmentData?.score}</div>
            {getRisk(data?.package[0], assessmentData?.riskFactor)}
          </div>
          <a href={getReport()} target='_blank' onClick={setLocalStorage}>View assessment results</a>
        </div>

        {/* nextConsultation  */}
        <div className={classes.nextConsultation}>
          <p>Next Consultation</p>
          <div>{moment.utc(consultationData?.startDate).format("D MMMM YYYY, hh:mm A")}</div>
          <div>
            <a href={consultationData?.roomUrl} target="_blank">
              <Button sx={{ "&:hover": { backgroundColor: '#3E36B0' } }}>JOIN CALL</Button>
            </a>
          </div>
        </div>

        {/* lastConsultation  */}
        {!consultationData &&
          <div className={classes.lastConsultation}>
            {/* <div>
            <p>Last Consultation</p>
            <div>14 May 2023, 10:15 AM</div>
          </div> */}
            <div>
              <p>Observation</p>
              <div>
                {consultationData?.observation}
              </div>
            </div>
            <div>
              <p>Prescription</p>
              <div>
                {consultationData?.prescription}
              </div>
            </div>
          </div>
        }

      </div>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}><span></span></Button> */}

          {/* <Visibility /> */}
          <a className={classes.package_drawer_btn}
            onClick={toggleDrawer(anchor, true)} >Quick View</a>

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            PaperProps={{
              sx: {
                borderTopLeftRadius: "24px",
                borderBottomLeftRadius: "24px",
              },
            }}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
