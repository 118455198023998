import { createSlice } from "@reduxjs/toolkit";
import InitialStore from "../store/InitialStore";

// SETTING COGNITO USER
export const getClinicRequest = (state, action) => {
    state.data = [];
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
};

export const getClinicByIdRequest = (state, action) => {
    state.data = [];
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
}

export const getClinicSuccess = (state, action) => {
    state.data = action.payload.data;
    state.loading = false;
}

export const getClinicFail = (state, action) => {
    state.error = true;
    state.loading = false
};

export const createClinicRequest = (state, action) => {
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
};

export const createClinicSuccess = (state, action) => {
    state.data = action.payload.data;
    state.loading = false;
}

export const createClinicFail = (state, action) => {
    state.error = true;
    state.loading = false
};

export const updateClinicRequest = (state, action) => {
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
};

export const updateClinicSuccess = (state, action) => {
    state.data = action.payload.data;
    state.loading = false;
}

export const updatelinicFail = (state, action) => {
    state.error = true;
    state.loading = false
};
export const deactivateClinicRequest = (state, action) => {
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
};

export const deactivateClinicSuccess = (state, action) => {
    state.data = action.payload.data;
    state.loading = false;
}

export const deactivateClinicFail = (state, action) => {
    state.error = true;
    state.loading = false
};

export const activateClinicRequest = (state, action) => {
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
};

export const activateClinicSuccess = (state, action) => {
    state.data = action.payload.data;
    state.loading = false;
}

export const activateClinicFail = (state, action) => {
    state.error = true;
    state.loading = false
};

export const uploadPhotoRequest = (state, action) => {
    state.loading = true
    state.error = false
}
export const uploadPhotoSuccess = (state, action) => {
    // state.data = action.payload.data
    state.loading = false
    state.error = false
}
export const uploadPhotoFail = (state, action) => {
    state.error = false
    state.loading = false
    // state.errorMessage = action.payload.error.message
}

export default createSlice({
    name: "clinicReducer",
    initialState: InitialStore.clinic,
    reducers: {
        getClinicRequest,
        getClinicByIdRequest,
        getClinicSuccess,
        getClinicFail,
        createClinicRequest,
        createClinicSuccess,
        createClinicFail,
        updateClinicRequest,
        updateClinicSuccess,
        updatelinicFail,
        deactivateClinicRequest,
        deactivateClinicSuccess,
        deactivateClinicFail,
        activateClinicRequest,
        activateClinicSuccess,
        activateClinicFail,
        uploadPhotoRequest,
        uploadPhotoSuccess,
        uploadPhotoFail,
    },
});