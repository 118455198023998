import React from "react";
import classes from "./DevelopmentMilestones2.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import tufts from "../../../assets/images/tufts.svg";
import swyc from "../../../assets/images/swyc.svg";
import { textFormat } from "../../../utils/utility";
import clsx from "clsx";
import moment from "moment";


export const DevelopmentMilestones2 = ({ data }) => {

  const emoResponse = JSON.parse(data?.EMOTIONAL_AND_BEHAVIORAL)?.response

  const questionBank = [
    {
      qno: 7,
      question: "Does your child Fight with other children?",
      answer: textFormat(emoResponse?.[6])
    },
    {
      qno: 8,
      question: "Does your child Have trouble paying attention?",
      answer: textFormat(emoResponse?.[7])
    },
    {
      qno: 9,
      question: "Does your child Have a hard time calming down?",
      answer: textFormat(emoResponse?.[8])
    },
    {
      qno: 10,
      question: "Does your child Have trouble staying with one activity?",
      answer: textFormat(emoResponse?.[9])
    },
    {
      qno: 11,
      question:
        "Is your child aggressive? (For example, throws tantrums; hitting, kicking, or biting;  destroys property; bullys others; verbal attacks; attempts to control others through threats or violence)",
      answer: textFormat(emoResponse?.[10])
    },
    {
      qno: 12,
      question:
        "Is your child Fidgety or unable to sit still? (For example,tapping/shaking legs and feet, twirling hair, drumming fingers or turning a pen over and over in their hands)",
      answer: textFormat(emoResponse?.[11])
    },
    {
      qno: 13,
      question:
        "Is your child angry? (For example- feels frustrated, irritated, cries and displays aggressive behavior as a result of anger).",
      answer: textFormat(emoResponse?.[12])
    },
    {
      qno: 14,
      question: "Is it hard to Take your child out in public?",
      answer: textFormat(emoResponse?.[13])
    },
    {
      qno: 15,
      question: "Is it hard to Comfort your child?",
      answer: textFormat(emoResponse?.[14])
    },
    {
      qno: 16,
      question: "Is it hard to Know what your child needs?",
      answer: textFormat(emoResponse?.[15])
    },
    {
      qno: 17,
      question: "Is it hard to Keep your child on a schedule or routine?",
      answer: textFormat(emoResponse?.[16])
    },
    {
      qno: 18,
      question: "Is it hard to Get your child to obey you?",
      answer: textFormat(emoResponse?.[17])
    },
  ];

  return Math.abs(moment(data?.student.DOB).diff(moment(), "months")) >= 18 && (
    <>
      <div className={clsx(classes.container, 'xyz')}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>

        <div className={classes.assessment_question_container}>
          <div>Emotional and behavioral aspect Questionnaire</div>
          <div className={classes.question_select_container}>
            {questionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Not At All"
                          checked={el.answer === "Not At All"}
                        />
                        <p>Not At All</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Somewhat"
                          checked={el.answer === "Somewhat"}
                        />
                        <p>Somewhat</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Very Much"
                          checked={el.answer === "Very Much"}
                        />
                        <p>Very Much</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes.method_framework}>Method or Framework</div>
        <div className={classes.method_content}>
          This document is based on the Survey of Well-Being of Young Children®
          (SWYC) © 2016 Tufts Medical Center, all rights reserved. The SWYC
          which is the source of this document incorporates the Edinburgh
          Postnatal Depression Scale (“EPDS”) © 1987 The Royal College of
          Psychiatrists. Cox, J.L., Holden, J.M., and Sagovsky, R. (1987).
          Detection of postnatal depression. Development of the 10-item
          Edinburgh Postnatal Depression Scale. British Journal of Psychiatry.
          150, 782-786. All of the rights of the respective authors are reserved
          under applicable copyright law.”
        </div>

        <div className={classes.certification_container}>
          <img src={swyc} alt="swyc" />
          <img src={tufts} alt="tufts" />
        </div>
        <div className={classes.certification_content}>
          The information contained in this publication should not be used as a
          substitute for the medical care and advice of your paediatrician.
          There may be variations in treatment that your paediatrician may
          recommend based on individual facts and circumstances.
        </div>

        <div className={classes.footer_container_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 2 of 2</p>
          </div>
        </div>
      </div>
    </>
  );
};
