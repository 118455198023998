import React, { useEffect, useState } from "react";
import classes from "./EditUser.module.css";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import ArrowRight from "@mui/icons-material/ArrowRight";
import schoollogo from "../../../assets/images/schoollogo.png";
import upload from "../../../assets/images/upload.svg";
import { Link, useParams } from "react-router-dom";
import InputDashboard from "../InputDashboard/InputDashboard";
import InputDropdown from "../InputDashboard/InputDropdown";
import { useLocation } from "react-router-dom";
import DatePickerInput from "../InputDashboard/DatePickerInput";
import { useDispatch, useSelector } from "react-redux";
import userReducer from "../../../redux/reducers/user.reducer";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Alert, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../../utils/utility";

function EditUser() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthDay] = useState("");
  const [role, setRole] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [schoolClass, setSchoolClass] = useState("")
  const [specialty, setSpeciality] = useState("")
  const [section, setSection] = useState("")

  const dispatch = useDispatch();
  const location = useLocation();

  const createUser = userReducer.actions.createUserRequest;
  const updateUser = userReducer.actions.updateUserRequest

  useEffect(() => {
    setRole(location.state?.role ?? "");
  }, []);



  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const error = useSelector((state) => state.userReducer.error);
  const loading = useSelector((state) => state.userReducer.loading);
  

  let schoolId = useSelector((state) => state.userReducer.currentUser.schoolId);

  useEffect(() => {
    const values = location.state
    
    setEmail(values?.email ?? "")
    setTitle(values?.title ?? "")
    setFirstName(values?.given_name ?? "")
    setLastName(values?.family_name ?? "")
    setGender(values?.gender ?? "")
    setRole((values.groups[0]?.split("-")?.splice(1))[0] ?? "")
    setPhoneNumber(values?.phoneNumber ?? "")
    setSection(values?.section ?? "")
    setSchoolClass(values?.class ?? "")
    if(gender === 'Male'){
      setTitle('Mr.')
    }else if(gender === 'Female'){
      setTitle('Mrs.')
    }
  }, [])

  const handleSubmit = async () => {
    if (!location.pathname.includes("principal") ) {
      
      schoolId = location.state?.schoolId?.schoolId;
    }
    // 
    let formData;
    if(role === 'teacher'){
      formData = {
        email,
        title,
        firstName,
        lastName,
        schoolClass : schoolClass,
        section : section,
        role: `school-${role}`,
        phoneNumber,
        schoolId: schoolId,
        gender
      };
    }else{
    formData = {
      email,
      title,
      firstName,
      lastName,
      birthday,
      role: role==="doctor" || role==="nurse" ? `skids-${role}` :`school-${role}`,
      phoneNumber,
      schoolId: role==="doctor" || role==="nurse" ? location.state?.schoolId?.clinicCode :schoolId,
      gender
    };
  }
    if(location?.state?.type==="doctor"){
      formData.specialities = specialty
    }
    
    await dispatch(updateUser(formData));
    handleClick();
    // if(!loading && !error){
    //   setTimeout(() => navigate(-1), 5000)
    // }

  };


  return (
    <div className={classes.mainDiv}>
      <div className={classes.header}>
        <div className={classes.back}>
          {" "}
          <Link
            to="/superadmin-users"
            style={{ textDecoration: "none", color: "#9d9d9d" }}
          >
            Registered Users{" "}
          </Link>{" "}
        </div>
        <ArrowRight /> <div className={classes.header_text}>Edit User</div>
      </div>
      <div className={classes.container}>
        <div>
          <h4>Skids {role} registration information</h4>
          <InputDashboard
            placeholder={"Email Id"}
            margin={1}
            width={"32%"}
            className={classes.input_layout_schoolname}
            change={(e) => {
              setEmail(e.target.value);
            }}
            bgColor="#ffffff"
            value={email}
            disabled
          />
          {!validateEmail(email) && email?.length ? (
            <div className={classes.validation_text}>
              Please fill valid email id*
            </div>
          ) : (
            <></>
          )}

          <div className={classes.lowlight_text}>
            New user login credentials will be sent to this email id
          </div>
          <div className={classes.contact_info}>Personal information</div>

          <div className={classes.upload_logo}>
            <div className={classes.no_upload_logo}>
              <ImageRoundedIcon
                sx={{ fill: "#E8E7E7", width: "56px", height: "56px" }}
              />
            </div>
            {/* <img alt="" src = {schoollogo}  /> */}
          </div>
          <div className={classes.input_user_layout}>
            <div>
              <InputDropdown
                placeholder={"Title"}
                width={"95%"}
                className={classes.input_layout_boardname}
                bgColor="#fff"
                value={title}
                defaultValues={["Mr.", "Mrs."]}
                setValue={setTitle}
              />
              {/* {validateTitle && (
                <div className={classes.validation_text}>
                  Please select title*
                </div>
              )} */}
            </div>

            <div>
              <InputDashboard
                placeholder={"First Name"}
                width={"95%"}
                className={classes.input_layout_schoolname}
                change={(e) => {
                  setFirstName(e.target.value);
                }}
                bgColor="#ffffff"
                value={firstName}
              />
              {/* {validatefirstName && (
                <div className={classes.validation_text}>
                  Please type first name*
                </div>
              )} */}
            </div>
            <div>
              <InputDashboard
                placeholder={"Last Name"}
                width={"95%"}
                className={classes.input_layout_schoolname}
                change={(e) => {
                  setLastName(e.target.value);
                }}
                bgColor="#ffffff"
                value={lastName}
              />
              {/* {validateLastName && (
                <div className={classes.validation_text}>
                  Please type last name*
                </div>
              )} */}
            </div>
          </div>
          <div className={classes.input_school_layout}>
            {!location.state?.type==="doctor" && <DatePickerInput
              onChange={(e) => {
                setBirthDay(e.target.value);
              }}
              width={"95%"}
              placeholder={"Birthday"}
              value={birthday}
            />}
            {
              location.state?.type ==="doctor" && 
              <InputDropdown
                placeholder={"Specialty"}
                width={"95%"}
                margin={2}
                className={classes.input_layout_boardname}
                bgColor={"#fff"}
                value={specialty}
                defaultValues={["Pediatrician", "Behavioural", "Dental"]}
                setValue={setSpeciality}
              />
            }
            <InputDropdown
              placeholder={"Gender"}
              width={"95%"}
              margin={2}
              className={classes.input_layout_boardname}
              bgColor="#fff"
              value={gender}
              defaultValues={["Male", "Female",]}
              setValue={setGender}
            />
            <InputDropdown
              placeholder={"Role"}
              width={"95%"}
              margin={2}
              className={classes.input_layout_boardname}
              bgColor="#fff"
              value={role}
              defaultValues={["principal", "teacher", "doctor", "nurse","admin"]}
              setValue={setRole}
              disabled={
                role === "teacher" ||
                location.state?.type === "principal" ||
                location.state?.type === "doctor" ||
                location.state?.type === "nurse" ||
                location.state?.type === "admin"
              }
            />
              {role === 'teacher' &&               
              <InputDropdown
                placeholder={"Class"}
                width={"95%"}
                margin={2}
                className={classes.input_layout_boardname}
                bgColor="#fff"
                value={schoolClass}
                defaultValues={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                setValue={setSchoolClass}
              />}
          </div>
          {role === 'teacher' &&
          <div className = {classes.input_school_layout}>
          <InputDropdown
                placeholder={"Section"}
                width={"95%"}
                margin={2}
                className={classes.input_layout_boardname}
                bgColor="#fff"
                value={section}
                defaultValues={["A", "B", "C", "D", "E",]}
                setValue={setSection}
              />
          </div>}
          <div>


              {/* {validateRole && (
                <div className={classes.validation_text}>
                  Please select role*
                </div>
              )} */}
            </div>
          <div className={classes.contact_info}>Contact information</div>
          <div className={classes.input_school_layout}>
            <div>
              <InputDashboard
                placeholder={"Phone Number"}
                width={"95%"}
                margin={2}
                className={classes.input_layout_schoolname}
                change={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                bgColor="#ffffff"
                value={phoneNumber}
              />
              {/* {validateNumber && (
                <div className={classes.validation_text}>
                  Please type valid phone number*
                </div>
              )} */}
            </div>
          </div>
          {!loading &&
          <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={!error ? "success" : "error"}
              sx={{ width: "100%" }}
            >
              {!error ? `Successfully updated ${role}` : `Error while updating ${role}`}
            </Alert>
          </Snackbar>
}
          <div className={classes.buttons}>
            <button className={classes.button_with_border}>CANCEL</button>
            <button onClick={handleSubmit} className={classes.button_filled}

          //   disabled = {validateEmail(email) && email && role && title && firstName && lastName && 
          //  && phoneNumber && gender ? false : true}
           >

              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
