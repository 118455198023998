import { Configuration, OpenAIApi } from 'openai';
import React, { useEffect, useState } from 'react';
import classes from './SkidsGPT.module.css';
import logo from '../../assets/images/SkidsSuperManLogo.svg'
import { Avatar, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, LinearProgress, OutlinedInput, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';

function SkidsGPT({organ}) {

	const [response, setResponse] = useState('')
	const [prompt, setPrompt] = useState('')
	const [messages, setMessages] = useState([])

	const user = useSelector((state)=>state.userReducer.currentUser)
	const [loading, setLoading] = useState(false)


	useEffect(() => {
		setMessages([{sender: "skids", msg: `What do you want to know about ${organ}?`}])
	}, [])

	const configuration = new Configuration({
		apiKey: process.env.REACT_APP_GPT_API_KEY,
	});
	const openai = new OpenAIApi(configuration);

	const fetchResponse = async (e) => {
			if(prompt.length){
				e.preventDefault()
				setResponse('')
				setLoading(true)
				setMessages([...messages, {sender: "user", msg:prompt}])
				const temp = prompt;
				setPrompt('')

				const completion = await openai.createCompletion({
					model: "text-davinci-003",
					prompt: `Okay what you need to do is I'll give you prompt of my question and a topic name so then if my prompt question is not related to that topic you have to respond with "out of context" and you don't have to give any explanation. Question: ${temp} [topic : ${organ}]`,
					max_tokens: 2048,
				});
				let a = messages.push({sender: "skids", msg:completion.data.choices[0].text})
				setMessages(messages=>[...messages, {sender: "skids", msg:completion.data.choices[0].text}]);
				setLoading(false)
			}
	}

	function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 12,
        height: 12,
        padding: 2
      },
      children: `${name[0]}`,
    };
  }

	return (
		<div className={classes.mainContainer}>
			<div className={classes.chatArea}>
				{
					messages.map((x)=>{
						return (
							<div className={classes.chatScreen} style={{ fontWeight: 500 }}>
								{
									x.sender === "user" ? 
										<Avatar {...stringAvatar(`${user?.given_name}`)} style={{ marginRight: 20 }} /> :
										<div className={classes.avatar}>
    									<img src={logo} width={15}/>
      				      </div>
								}
								<p>{x.msg}</p>
							</div>
						)
					})
				}
			</div>
			<div>
				{loading && <div className={classes.loader}><LinearProgress /></div>}
				<form onSubmit={fetchResponse}>
				<FormControl sx={{ width:"100%"}} variant="outlined" className={classes.textfield}>
						{/* <InputLabel htmlFor="outlined-adornment-password">Send a message</InputLabel> */}
						<OutlinedInput
							id="outlined-adornment-password"
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										edge="end"
										onClick={fetchResponse}
										disabled={loading}
									>
										<SendIcon/>
									</IconButton> 
								</InputAdornment>
							}
							placeholder="Send a message"
							inputProps={{
								sx: {
									textAlign: "left",
									"&::placeholder": {
										textAlign: "left",
									},
								},
							}}
							disabled={loading}
							fullWidth
							value={prompt}
							onChange={(e)=>{setPrompt(e.target.value)}}
						/>
					</FormControl>
					</form>
			</div>
	</div>
	)
}

export default SkidsGPT