import { API, graphqlOperation } from "aws-amplify";
import Cookies from "js-cookie";

function getclinics() {
    const token = Cookies.get('tokenId')
    return API.graphql({
        query: `query MyQuery {
            getClinics(count: 100, nextToken: " ") {
              address
              ameneties
              city
              clinicCode
              createdAt
              doctor
              email
              isActive
              establishment
              name
              nurse
              phone
              pincode
              specialty
              staff
              state
              status
              type
              url
              updatedAt
            }
        }`,
        authToken: token
    })
}

function getclinicById(payload) {
  const token = Cookies.get('tokenId')
  return API.graphql({
      query: `query MyQuery {
        getClinicById(clinicCode: "${payload.payload.clinicCode}", pincode: "${payload.payload.pincode}") {
          address
          ameneties
          city
          clinicCode
          createdAt
          doctor
          email
          isActive
          establishment
          name
          nurse
          phone
          pincode
          specialty
          staff
          state
          status
          type
          url
          updatedAt
        }
      }`,
      authToken: token
  })
}

function createClinic(payload) {
    const params = payload.payload
    const token = Cookies.get('tokenId')
    const specialty = params.speciality.split(' ').join('');
    const type = params.type.split(' ').join('')
    return API.graphql({
        query: `mutation MyMutation {
            createClinic(clinicCode: "${params.clinicCode}"address: "${params.address}", city: "${params?.city}", state:"${params.state}", email: "${params?.email}", establishment: "${params?.establishment}", name: "${params?.name}", phone: "${params?.mobNumber}", pincode: "${params?.pincode}", specialty: ${specialty} , type: ${type}, ameneties: "${params?.ameneties}", status: true, doctor: ${params?.doctor}, nurse: ${params?.nurse}, staff: ${params?.staff}) {
              address
              ameneties
              city
              clinicCode
              createdAt
              doctor
              email
              establishment
              name
              nurse
              phone
              pincode
              specialty
              staff
              state
              status
              type
              updatedAt
            }
          }`,
        authToken: token
    })
}

function updateClinic(payload){
  const params = payload.payload
  const token = Cookies.get('tokenId')
  const specialty = params.speciality.split(' ').join('');
  const type = params.type.split(' ').join('');
  return API.graphql({
    query: `mutation MyMutation {
      updateClinic(clinicCode: "${params.clinicCode}", pincode: "${params.pincode}", address: "${params.address}", ameneties: "${params.ameneties}", city: "${params.city}", state:"${params.state}", email: "${params.email}", establishment: "${params.establishment}", name: "${params.name}", phone: "${params.mobNumber}", specialty: ${specialty}, type: ${type}, nurse: ${params.nurse}, staff: ${params.staff}, doctor: ${params.doctor}) {
        address
        ameneties
        city
        clinicCode
        createdAt
        email
        establishment
        name
        phone
        pincode
        specialty
        nurse
        staff
        status
        type
        updatedAt
      }
    }
    `,
    authToken: token
})
}

function deactivateClinic(payload){
  const params = payload.payload
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `mutation MyMutation {
      updateClinic(clinicCode: "${params.clinicCode}", pincode : "${params.pincode}" status : false) {
        address
        ameneties
        city
        clinicCode
        createdAt
        email
        establishment
        name
        phone
        pincode
        specialty
        nurse
        staff
        status
        type
        updatedAt
      }
    }
    `,
    authToken: token
})
}


function activateClinic(payload){
  const params = payload.payload
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `mutation MyMutation {
      updateClinic(clinicCode: "${params.clinicCode}", pincode : "${params.pincode}" status : true) {
        address
        ameneties
        city
        clinicCode
        createdAt
        email
        establishment
        name
        phone
        pincode
        specialty
        nurse
        staff
        status
        type
        updatedAt
      }
    }
    `,
    authToken: token
})
}

function uploadClinicPhoto(payload) {
  const token = Cookies.get("tokenId");
  const params = payload.payload
  
  return API.graphql(graphqlOperation(
    `mutation MyMutation($logo: AWSJSON!) {
      uploadClinicLogo(data: $logo, clinicId: "${params?.clinicId}", pincode: "${params?.pincode}")
    }`,
    {logo: params?.logo},
    token,
  ));
}

const clinics = {
    getclinics,
    createClinic,
    updateClinic,
    activateClinic,
    deactivateClinic,
    getclinicById,
    uploadClinicPhoto
}

export default clinics;