import React from "react";
import classes from "./BehavioralReport.module.css";
import skidslogo from "../../assets/images/SkidsSuperManLogo.svg";
import BhvReportHeader from "./Anxiety/BhvReportHeader";
import { textFormat } from "../../utils/utility";
import clsx from "clsx";


export const BehavioralReport = ({ data }) => {

  const response = JSON.parse(data?.ADHD)?.response
  console.log(response);
  const questionBank = [
    {
      qno: 1,
      question: "Does not pay attention to details or makes careless mistakes with, for example, homework",
      answer: textFormat(response?.[0])
    },
    {
      qno: 2,
      question: "Has difficulty keeping attention to what needs to be done",
      answer: textFormat(response?.[1])
    },
    {
      qno: 3,
      question: "Does not seem to listen when spoken to directly",
      answer: textFormat(response?.[2])
    },
    {
      qno: 4,
      question: "Does not follow through when given directions and fails to finish activities (not due to refusal or failure to understand)",
      answer: textFormat(response?.[3])
    },
    {
      qno: 5,
      question: "Has difficulty organising tasks and activities",
      answer: textFormat(response?.[4])
    },
    {
      qno: 6,
      question: "Avoids, dislikes, or does not want to start tasks that require ongoing mental effort",
      answer: textFormat(response?.[5])
    },
    {
      qno: 7,
      question: "Lose things necessary for tasks or activities (toys, assignments, pencils, or books",
      answer: textFormat(response?.[6])
    },
    {
      qno: 8,
      question: "Is easily distracted by noises or other stimuli",
      answer: textFormat(response?.[7])
    },
    {
      qno: 9,
      question: "Is forgetful in daily activites",
      answer: textFormat(response?.[8])
    },
    {
      qno: 10,
      question: "Fidgets with hands or feet or squirms in seat",
      answer: textFormat(response?.[9])
    },
    {
      qno: 11,
      question: "Leaves seat when remaining seated is expected",
      answer: textFormat(response?.[10])
    },
    {
      qno: 12,
      question: "Runs about or climbs too much when remaining seated is expected",
      answer: textFormat(response?.[11])
    },
    {
      qno: 13,
      question: "Has difficulty playing or beginning quiet play activities",
      answer: textFormat(response?.[12])
    },
    {
      qno: 14,
      question: "Is “on the go” or often acts as if “driven by a motor”",
      answer: textFormat(response?.[13])
    },

  ]

  return (
    <>
      <div className={clsx(classes.container, 'xyz')}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>
        <div className={classes.title_container}>
          Hey! This is my ADHD assessment report.
        </div>

        <BhvReportHeader data={data} />

        <div className={classes.question_container}>
          <div>Assessment Questionnaire</div>
          <div className={classes.question_select_container}>
            {questionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Never"
                          checked={el.answer === "Never"}
                        />
                        <p>Never</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Ocassionally"
                          checked={el.answer === "Occasionally"}
                        />
                        <p>Ocassionally</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Often"
                          checked={el.answer === "Often"}
                        />
                        <p>Often</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Very Often"
                          checked={el.answer === "Very Often"}
                        />
                        <p>Very Often</p>
                      </div>
                    </form>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className={classes.footer_container}>
          <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
          <p>Page 1 of 2</p>
        </div>
      </div>
    </>
  );
};
