import React, { useEffect, useState } from 'react'
import SendIcon from '@mui/icons-material/Send';
import classes from './EditableText.module.css'
import EditNoteIcon from '@mui/icons-material/EditNote';

const EditableText = (props) => {
    const { publishUpdates } = props;
    const [isEdit, setIsEdit] = useState(false)
    const [docInput, setDocInput] = useState(false)

    useEffect(() => {
        setDocInput(props?.value)
    }, [props.value])

    const handleInputChange = (e) => {

        setDocInput(e.target.value);
    }

    const handleInterpretationUpdate = (e) => {
        if (e) {
            e.preventDefault();
        }
        publishUpdates(props.c, docInput, e)
        setIsEdit(false);
    }

    return (
        <div>
            <p>{props.title}</p>
            {
                isEdit
                    ? <div className={classes.editInterpretation}>
                        <form onSubmit={handleInterpretationUpdate} style={{ width: "100%" }}>
                            <input onChange={handleInputChange} style={{ width: "100%", textAlign: 'start' }} value={docInput} type="text" name="" id="" placeholder={props.placeholder} />
                        </form>
                        <SendIcon sx={{ fill: '#222CC9' }} onClick={(e) => { handleInterpretationUpdate(e) }} />
                    </div>
                    :
                    <div>

                        <div className={classes.docEditText} >
                            <p>{props?.value?? "No value" }</p>
                            <EditNoteIcon onClick={() => { setIsEdit(true) }} sx={{ fill: "#222CC9", fontSize: "20px" }} />
                        </div>
                    </div>
            }
        </div>
    )
}

export default EditableText


// // <div>
// // <input type="text" />
// // <p></p>
// // </div>
// // <div>
// // <input type="text" />
// // 123
// // </div>
// // <div>
// // <input type="text" />
// // 123
// // </div>