import { takeLatest, call, put } from 'redux-saga/effects';
import ApiServices from '../../graphqlApiServices';
import validationReducer from '../reducers/validation.reducer';


const { validation } = ApiServices;

// const { getDoctorValidation, assignDoctorValidation, getDoctorValidationCount } = validation;
const { getDoctorValidation, assignDoctorValidation, getDoctorInterpreations, updateDoctorInterpretations, getDoctorValidationCount, updateValidation, getDoctorValidationProgress } = validation;

export function* watchValidationRequests() {
    yield takeLatest(validationReducer.actions.getDoctorValidation, getValidationSaga);
    yield takeLatest(validationReducer.actions.assignDoctorRequest, assignDoctorSaga);
    yield takeLatest(validationReducer.actions.assignDoctorValidationCountRequest, assignDoctorValidationCountSaga)
    yield takeLatest(validationReducer.actions.getDoctorInterpretation, getDoctorInterpretationSaga);
    yield takeLatest(validationReducer.actions.updateDoctorInterpretation, updateDoctorInterpretationSaga);
    yield takeLatest(validationReducer.actions.getDoctorValidationProgressRequest, getDoctorValidationProgressSaga)
    yield takeLatest(validationReducer.actions.updateValidationReq, updateDoctorValidationSaga);
}


export function* getValidationSaga(payload) {
    try {
        console.log(payload);
        const response = yield call(getDoctorValidation, { doctorId: payload?.payload?.doctorId, nextToken: payload?.payload?.nextToken });
        const data = response?.data?.getDoctorValidation.items
        yield put(validationReducer.actions.getDoctorValidationSuccess(data));
        if (response?.data?.getDoctorValidation.nextToken) {
            let params = {
                doctorId: payload?.payload?.doctorId,
                nextToken: response?.data?.getDoctorValidation.nextToken
            }
            yield put(validationReducer.actions.getDoctorValidation(params))
        }
    } catch (error) {
        if (error?.data?.getDoctorValidation?.items?.length > 0) {
            const data = error?.data?.getDoctorValidation.items
            yield put(validationReducer.actions.getDoctorValidationSuccess(data));
            if (error?.data?.getDoctorValidation.nextToken) {
                let params = {
                    doctorId: payload?.payload?.doctorId,
                    nextToken: error?.data?.getDoctorValidation.nextToken
                }
                yield put(validationReducer.actions.getDoctorValidation(params))
            }
        }
        else {

            yield put(validationReducer.actions.getDoctorValidationFail(error))
        }
    }
}

export function* assignDoctorSaga(payload) {
    try {
        const response = yield call(assignDoctorValidation, payload);
        const data = response?.data?.assignDoctorValidation
        yield put(validationReducer.actions.assignDoctorSuccess(data))
    } catch (error) {
        if (error?.data?.assignDoctorValidation?.length > 0) {
            const data = error?.data?.assignDoctorValidation
            yield put(validationReducer.actions.assignDoctorSuccess(data))
        }
        else {

            yield put(validationReducer.actions.assignDoctorFail(error))
        }
    }
}

export function* assignDoctorValidationCountSaga(payload) {
    try {
        const response = yield call(getDoctorValidationCount, payload);
        const data = response?.data?.getDoctorValidationCount
        yield put(validationReducer.actions.assignDoctorValidationCountSuccess(data))
    } catch (error) {
        if (error?.data?.getDoctorValidationCount?.length > 0) {
            const data = error?.data?.getDoctorValidationCount
            yield put(validationReducer.actions.assignDoctorValidationCountSuccess(data))
        }
        else {

            yield put(validationReducer.actions.assignDoctorValidationCountFail(error))
        }
    }
}

export function* getDoctorInterpretationSaga(payload) {
    try {
        const response = yield call(getDoctorInterpreations, payload.payload);
        const data = response.data.getScreening
        yield put(validationReducer.actions.getDoctorInterpretationSuccess(data))
    } catch (error) {

        yield put(validationReducer.actions.getDoctorInterpretationFail({ error }))
    }
}


export function* updateDoctorInterpretationSaga(payload) {
    try {
        const response = yield call(updateDoctorInterpretations, payload.payload);
        const data = response.data.updateScreening;
        yield put(validationReducer.actions.updateDoctorInterpretationSuccess(data));
    } catch (error) {

        yield put(validationReducer.actions.updateDoctorInterpretationFail({ error }))
    }
}

export function* getDoctorValidationProgressSaga(payload) {
    try {
        const response = yield call(getDoctorValidationProgress, payload);
        const data = response?.data?.getDoctorValidationProgressInRange
        yield put(validationReducer.actions.getDoctorValidationProgressSuccess(data))
    } catch (error) {
        if (error?.data?.getDoctorValidationProgressInRange?.length > 0) {
            const data = error?.data?.getDoctorValidationProgressInRange
            yield put(validationReducer.actions.getDoctorValidationProgressSuccess(data))
        }
        else {

            yield put(validationReducer.actions.getDoctorValidationProgressFail(error))
        }
    }
}

export function* updateDoctorValidationSaga(payload) {
    try {

        const response = yield call(updateValidation, payload.payload);
        // const data = response.data.updateScreening;
        yield put(validationReducer.actions.updateValidationSuccess(response.data));
    } catch (error) {

        yield put(validationReducer.actions.updateValidationFailure({ error }))
    }
}
