import React from "react";
import classes from "./AutismReport.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import { stringAvatar, textFormat } from "../../../utils/utility";
import { stringToColor } from "../../../utils/utility";
import { Avatar } from "@mui/material";
import highrisk from "../../../assets/images/highRisk.svg";
import psychologist from "../../../assets/images/psychologist.svg";
import BhvReportHeader from "../Anxiety/BhvReportHeader";

export const AutismReport4to6 = ({ data }) => {
  const questionBank = [
    {
      qno: 1,
      question:
        "Do you feel that your child has developed certain abilities much before their usual age? (Example: has good memory skills, ability to remember visual content and repeats sentences/words much earlier than other children of the same age)",
      answer: textFormat(data?.output?.[0]?.answers?.[0])
    },
    {
      qno: 2,
      question:
        "Does your child behave in odd and unusual ways? (Ex: frequent finger movements in front of their face, spinning in circles)",
      answer: textFormat(data?.output?.[0]?.answers?.[1])
    },
    {
      qno: 3,
      question:
        "Does your child ignore what's happening around them and tends to indulge in their own world most of the time? (Ex: Strong interest in a specific toy or TV show)",
      answer: textFormat(data?.output?.[0]?.answers?.[2])
    },
    {
      qno: 4,
      question:
        "Does your child find it hard to understand the meaning/context of tasks but has good memory for certain things? (Ex: repeats the question instead of answering, difficulty following instructions but repeats the instruction, repeats numbers from number plate but refuses when asked to tell numbers ...)",
      answer: textFormat(data?.output?.[0]?.answers?.[3])
    },
    {
      qno: 5,
      question: `Does your child often make unusual noise? (Ex: clears throat, grunts, smacks, cries or screams)`,
      answer: textFormat(data?.output?.[0]?.answers?.[4])
    },
    {
      qno: 6,
      question:
        "Do you feel that your child excels in certain skills and is poor in certain skills? (Examples- Poor motor skills: gross motor delay- difficulty in balancing and fine motor delay-difficulty in drawing, coloring, writing)",
      answer: textFormat(data?.output?.[0]?.answers?.[5])
    },
    {
      qno: 7,
      question:
        `Does your child find it hard to understand the intricacies of social interaction/communication? (Ex: difficulty in greeting people-waving "Hi" or "Bye", does not say thank you when appreciated)`,
      answer: textFormat(data?.output?.[0]?.answers?.[6])
    },
    {
      qno: 8,
      question:
        "Does your child's behavior put you in a socially awkward or embarrassing position? (Ex: hitting other children without any reason, going too close to another person's face)",
      answer: textFormat(data?.output?.[0]?.answers?.[7])
    },
    {
      qno: 9,
      question:
        "Does your child display atypical gaze pattern? (Ex: Staring at the door or toy than focusing on what people are saying, looking at the floor while moving around, difficulty following objects with eyes, looks from the corner of the eye, not maintaining eye contact)",
      answer: textFormat(data?.output?.[0]?.answers?.[8])
    },
    // {
    //   qno: 10,
    //   question: `Does your child find it hard to understand the intricacies of social interaction/communication? (Ex: difficulty in greeting people-waving "Hi" or "Bye", does not say thank you when appreciated)`,
    //   answer: textFormat(data?. output?.[0]?.answers?.[9])
    // }
  ];

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>
        <div className={classes.title_container}>
          Hey! This is my Autism assessment report.
        </div>

        <BhvReportHeader data={data} />

        <div className={classes.question_container}>
          <div>Assessment Questionnaire</div>
          <div className={classes.question_select_container}>
            {questionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="No"
                          checked={el.answer === "No"}
                        />
                        <p>No</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Yes"
                          checked={el.answer === "Yes"}
                        />
                        <p>Yes</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Somewhat"
                          checked={el.answer === "Somewhat"}
                        />
                        <p>Somewhat</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes.footer_container}>
          <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
          <p>Page 1 of 2</p>
        </div>
      </div>
    </>
  );
};
