import { call, put, takeLatest } from "redux-saga/effects";
import ApiServices from "../../graphqlApiServices";
import eventReducer from "../reducers/event.reducer";

const { events } = ApiServices

const { getEvents, getEventsBySchoolId, createEvent, createEventMap, getClasses, getEventMaps } = events

export function* watchEventRequests() {
    yield takeLatest(eventReducer.actions.getEventsRequest, getEventsSaga);
    yield takeLatest(eventReducer.actions.getEventsBySchoolIdRequest, getEventsBySchoolIdSaga);
    yield takeLatest(eventReducer.actions.createEventRequest, createEventSaga);
    yield takeLatest(eventReducer.actions.createEventMapRequest, createEventMapSaga);
    yield takeLatest(eventReducer.actions.getClassesRequest, getClassesSaga)
    yield takeLatest(eventReducer.actions.getEventMapsRequest, getEventMapsSaga)
}

export function* getEventsSaga(payload) {
    try {
        const response = yield call(getEvents, payload);
        const data = response.data.getEvents
        
        yield put(eventReducer.actions.getEventsSuccess({data}))
    } catch (error) {
        
        if(Object.keys(error.data?.getEvents).length > 0){
            const data = error.data.getEvents
            yield put(eventReducer.actions.getEventsSuccess({data}))
        }else{
            yield put(eventReducer.actions.getEventsFail({ error }))
        }
    }
}

export function* getEventsBySchoolIdSaga(payload) {
    try {
        const response = yield call(getEventsBySchoolId, payload);
        const data = response.data.getEventBySchool
        
        yield put(eventReducer.actions.getEventsSuccess({data}))
    } catch (error) {
        
        if(Object.keys(error.data?.getEventBySchool).length > 0){
            const data = error.data.getEventBySchool
            yield put(eventReducer.actions.getEventsSuccess({data}))
        }else{
            yield put(eventReducer.actions.getEventsFail({ error }))
        }
    }
}

export function* createEventSaga(payload) {
    try {
        const response = yield call(createEvent, payload);
        const data = response.data.createEvent
        
        yield put(eventReducer.actions.createEventSuccess({data}))
    } catch (error) {
        
        if(Object.keys(error.data?.createEvent).length > 0){
            const data = error.data.createEvent
            
            yield put(eventReducer.actions.createEventSuccess({data}))
        }else{
            yield put(eventReducer.actions.createEventFail({ error:error }))
        }
    }
}

export function* createEventMapSaga(payload) {
    try {
        const response = yield call(createEventMap, payload);
        const data = response.data.createEventMap
        
        yield put(eventReducer.actions.createEventSuccess({data}))
        yield put(eventReducer.actions.getEventMapsRequest(payload?.payload?.schoolId))
    } catch (error) {
        
        if(Object.keys(error.data?.createEventMap).length > 0){
            const data = error.data.createEventMap
            yield put(eventReducer.actions.createEventSuccess({data}))
        }else{
            yield put(eventReducer.actions.createEventFail({ error }))
        }
    }
}

export function* getClassesSaga(payload) {
    try {
        const response = yield call(getClasses, payload);
        const data = response.data.getClassBySchool
        
        yield put(eventReducer.actions.getClassesSuccess({data}))
    } catch (error) {
        
        if(error.data?.getClassBySchool && Object.keys(error.data?.getClassBySchool).length > 0){
            const data = error.data.getClassBySchool
            yield put(eventReducer.actions.getClassesSuccess({data}))
        }else{
            yield put(eventReducer.actions.getClassesFail(error))
        }
    }
}

export function* getEventMapsSaga(payload) {
    try {
        const response = yield call(getEventMaps, payload);
        const data = response.data.getEventMapBySchoolId
        
        yield put(eventReducer.actions.getEventMapsSuccess({data}))
    } catch (error) {
        
        if(Object.keys(error.data?.getEventMapBySchoolId).length > 0){
            const data = error.data.getEventMapBySchoolId
            yield put(eventReducer.actions.getEventMapsSuccess(data))
        }else{
            yield put(eventReducer.actions.getEventMapsFail({ error }))
        }
    }
}