import React from "react";
import classes from "./PrivacyPolicy.module.css";
function PrivacyPolicy() {
  return (
    <div className={classes.privacy_policies}>
      <div className={classes.privacy_policy}>Privacy Policy</div>
      <div className={classes.last_update}>
        Last updated on: 05-Feb-2023 | 12:22 PM
      </div>
      <div className={classes.privacy_contents}>
        <div className={classes.privacy_items}>
          At Skids Health, accessible from www.domain.com, one of our main
          priorities is the privacy of our visitors. This Privacy Policy
          document contains types of information that is collected and recorded
          by us and how we use it.
        </div>
        <div className={classes.privacy_items}>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </div>
        <div className={classes.privacy_items}>
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in our website. This policy is not applicable to
          any information collected offline or via channels other than this
          website.
        </div>
        <div className={classes.privacy_items}>
          <div className={classes.privacy_head}>Consent</div>
          <div>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </div>
        </div>
        <div className={classes.privacy_items}>
          <div className={classes.privacy_head}>Information we collect</div>
          <div>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </div>
          <div>
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </div>
        </div>
        <div className={classes.privacy_items}>
          <div className={classes.privacy_head}>Cookies and Web Beacons</div>
          <div>
            Like any other website, we uses &apos;cookies&apos;. These cookies
            are used to store information including visitors&apos; preferences,
            and the pages on the website that the visitor accessed or visited.
            The information is used to optimize the users&apos; experience by
            customizing our web page content based on visitors&apos; browser
            type and/or other information.
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
