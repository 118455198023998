import React from "react";
import classes from "./AutismReport.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import BhvReportHeader from "../Anxiety/BhvReportHeader";
import { textFormat } from "../../../utils/utility";
import clsx from "clsx";


export const AutismReport1to4 = ({ data }) => {

    const response = data?.output?.[0]?.answers
    const questionBank = [
        {
            qno: 1,
            question:
                "Does your child look in the direction where you point? (Ex: Does your child look at a toy/moon/bird or any object across the room when you point to that particular object?)",
            answer: textFormat(response?.[0])
        },
        {
            qno: 2,
            question:
                "Can you engage your child in activities without any difficulty?",
            answer: textFormat(response?.[1])
        },
        {
            qno: 3,
            question:
                "Does your child engage in imaginative play? (Ex: pretending to talk on phone, pretending to feed doll)",
            answer: textFormat(response?.[2])
        },
        {
            qno: 4,
            question:
                "Does your child play actively? (Ex: plays in the playground, climbs on stairs and furniture)",
            answer: textFormat(response?.[3])
        },
        {
            qno: 5,
            question: `My child does not wiggle his/her fingers randomly?`,
            answer: textFormat(response?.[4])
        },
        {
            qno: 6,
            question:
                "If something is out of reach from your child does he/she point with one finger to ask for a toy or snack?",
            answer: textFormat(response?.[5])
        },
        {
            qno: 7,
            question:
                `If there is something interesting happening around you, does your child point to show you the same?`,
            answer: textFormat(response?.[6])
        },
        {
            qno: 8,
            question:
                "Does your child like to play with other children? (Smiles and approaches other children not just for the toys they have)",
            answer: textFormat(response?.[7])
        },
        {
            qno: 9,
            question:
                `My child remains unaffected by certain noises/sounds? (Such as closing his/her ears or crying due to mixer sound, pressure cooker whistle, vacuum cleaner or any loud noises)`,
            answer: textFormat(response?.[8])
        },
        {
            qno: 10,
            question: `Does your child maintain eye contact with you? (Looks at you in the eye while you are talking to them or dressing them up)`,
            answer: textFormat(response?.[9])
        },
        {
            qno: 11,
            question: `Does your child immitate your actions and sounds? `,
            answer: textFormat(response?.[10])
        },
        {
            qno: 12,
            question: `Does your child respond when you call his/her name most of the time?`,
            answer: textFormat(response?.[11])
        }
    ];

    return (
        <>
            <div className={clsx(classes.container, 'xyz')}>
                <div className={classes.header_container}>
                    <img src={skidslogo} alt="skids_logo" />
                    <p>Skids Health</p>
                </div>
                <div className={classes.title_container}>
                    Hey! This is my Autism assessment report.
                </div>

                <BhvReportHeader data={data} />

                <div className={classes.question_container}>
                    <div>Assessment Questionnaire</div>
                    <div className={classes.question_select_container}>
                        {questionBank.map((el) => {
                            return (
                                <div className={classes.question_radio_box}>
                                    <p>
                                        {el.qno}. {el.question}
                                    </p>
                                    <div className={classes.radio_toolbar}>
                                        <form>
                                            <div className={classes.radio_btn_container}>
                                                <input
                                                    type="radio"
                                                    id="html"
                                                    name="RadButton"
                                                    value="No"
                                                    checked={el.answer === "No"}
                                                />
                                                <p>No</p>
                                            </div>

                                            <div className={classes.radio_btn_container}>
                                                <input
                                                    type="radio"
                                                    id="html"
                                                    name="RadButton"
                                                    value="Yes"
                                                    checked={el.answer === "Yes"}
                                                />
                                                <p>Yes</p>
                                            </div>

                                            <div className={classes.radio_btn_container}>
                                                <input
                                                    type="radio"
                                                    id="html"
                                                    name="RadButton"
                                                    value="Somewhat"
                                                    checked={el.answer === "Somewhat"}
                                                />
                                                <p>Somewhat</p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className={classes.footer_container}>
                    <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
                    <p>Page 1 of 2</p>
                </div>
            </div>
        </>
    );
};