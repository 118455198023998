import React, { useState } from 'react'
import classes from './Faq.module.css'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material';


function Faq() {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    let arr = [
        {
            question: "What is the purpose of holistic health screening for kids?",
            answer: "The purpose of holistic health screening for school kids is to assess and monitor their overall health and well-being. It involves evaluating various aspects of their physical, mental, and emotional health to gain a comprehensive understanding of their overall wellness."
        },
        {
            question: "How does the holistic health screening process work?",
            answer: "The holistic health screening involve a combination of assessments such as physical examinations, health questionnaires, behavioral health screenings, vision and hearing tests, nutritional evaluations, and more. The specific process varies depending on the health package selected."
        },
        {
            question: "What specific health parameters are assessed during the screening?",
            answer: "The specific health parameters assessed during holistic health screening include more than 100 + physical parameters like vital signs(e.g., blood pressure, heart rate), height, weight, body mass index(BMI), vision and hearing acuity etc.and 11 parameters for behavioral & social health indicators, and any specific health concerns identified by parents, teachers, or healthcare professionals."
        },
        {
            question: "What benefits can schools and parents expect from participating in holistic health screening ?",
            answer: "Participating in holistic health screening can bring several benefits to schools and parents.It helps identify any potential health issues or concerns at an early stage, allowing for timely intervention and support.It promotes proactive health management, enabling schools and parents to collaborate in optimizing the child's overall well-being. Additionally, the screening process can provide valuable insights into health trends and patterns within the student population, facilitating targeted health programs and interventions."
        },
        {
            question: "Is the screening process safe for children ? Are there any risks involved ?",
            answer: "Yes, he screening process is safe for children.It involves non - invasive assessments and screenings that prioritize the well - being and comfort of the students.Trained professionals or healthcare providers carry out the screenings, ensuring that they follow best practices and adhere to safety protocols.All our devices are medical grade, FDA approved and CE certified."
        },
        {
            question: "What happens if a child's screening results indicate a potential health issue?",
            answer: "If a child's screening results indicate a potential health issue, SKIDS will provide guidance or recommendations for further assessment or intervention. This could involve referral to SKIDS specialised clinics, healthcare professionals, suggesting lifestyle modifications, or offering resources and support to address the specific health concern."
        },
        {
            question: "Can parents and teachers access the screening results ? How are privacy and confidentiality ensured ?",
            answer: "Yes, parents and teachers have access to the screening results and personalised health report.SKIDS have strict data protection measures in place to ensure that health data is handled securely and in compliance with HIPPA."
        },
        {
            question: "Are the screening methods and tools used by SKIDS scientifically validated ?",
            answer: "SKIDS use medical grade, FDA approved and CE certified screening methods and tools.We have a team of healthcare professionals and experts in the field to develop evidence - based assessments and protocols.We maintains high standards in our screening processes and adheres to best practices."
        },
        {
            question: "What kind of support and resources does SKIDS provide to schools and parents based on the screening results ?",
            answer1: "SKIDS offers various support and resources to schools and parents based on the screening results.This includes personalized health reports for individual students, recommendations for lifestyle modifications, access to educational materials on health and wellness, guidance on nutrition and physical activity.Support is also provided through SKIDS proprietary digital platform ",
            answer2: " or through network of SKIDS clinics. "
        },
        {
            question: "How is the health data of school kids collected and stored by SKIDS ?",
            answer: "The health data of school kids is collected through a combination of methods, such as physical examinations, behavioral health questionnaires, and tests conducted during the holistic health screening process.Trained professionals or healthcare providers employed by SKIDS carry out the assessments and record the relevant information.\n Once collected, the health data is stored electronically in secure HIPPA compliant databases maintained by SKIDS.These databases are designed to handle sensitive health information and are subject to stringent privacy and security measures."
        },
        {
            question: "What measures are in place to ensure the security and confidentiality of the health data ?",
            answer: " SKIDS implements various measures to ensure the security and confidentiality of the health data.This includes employing robust data encryption techniques to protect data during transmission and storage.Access controls are implemented to restrict data access to authorized personnel only.Regular security audits and assessments are conducted to identify and address any vulnerabilities in the system.SKIDS has ____ level of encryption and is in compliance with HIPPA laws.\n Additionally, strict internal policies and procedures are in place to train employees on data protection practices, confidentiality obligations, and the importance of maintaining privacy.These measures are crucial in safeguarding the health data of school kids."
        },
        {
            question: "Who has access to the health data ? Is it shared with any third parties ?",
            answer: "SKIDS does not share patient or students data with any third party.Access to health data is limited to concerned healthcare professionals or caregiver.Even for internal analytics purposes, we only use anonymise data where we scrub the personal sensitive information. "
        },
        {
            question: "How long is the health data retained by SKIDS ? Is there a data retention policy in place ?",
            answer: "The retention period for health data is ____ years by SKIDS as per HIPPA compliance.SKIDS retains the data for a specific period necessary to fulfil its intended purpose, comply with applicable regulations, and provide ongoing support to parents and schools.\n We at SKIDS, communicate our data retention policy clearly to parents and guardians, outlining the duration for which the data will be stored.This ensures transparency and allows individuals to make informed decisions regarding their child's health data. "
        },
        {
            question: "What rights do parents and guardians have regarding their child's health data, such as the ability to review, update, or delete the information?",
            answer: `Parents and guardians can review the child’s health data collected during the screening process, request corrections or updates to inaccurate information. \n The following can be read in our`
        }
    ];

    const answerStyle = {
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "18px",
        paddingRight: "25px",
        fontFamily: "Inter"

    }
    return (
        <>
            <div className={classes.topHeading}>
                <h1>FAQ’s</h1>
                <p>Last updated on: 05-Jul-2023 | 12:22 PM</p>
            </div>
            <div className={classes.faq}>
                {arr.map((item, index) => (
                    <Accordion className={classes.accordion} disableGutters={true} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                        <AccordionSummary
                            expandIcon={expanded === `panel${index}` ? <KeyboardArrowUp sx={{ fill: "#66707E" }} /> : <KeyboardArrowRight style={{ fill: "#B2B7BE" }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.question} sx={{
                                fontSize: "16px",
                                fontWeight: "500",
                                lineHeight: "20px",
                                fontFamily: "Inter"
                            }}>{item.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {item.question === "What kind of support and resources does SKIDS provide to schools and parents based on the screening results ?" ?
                                <Typography className={classes.answer} sx={answerStyle}>
                                    {item.answer1}
                                    <a href='www.skids.health'>www.skids.health</a>
                                    {item.answer2}
                                </Typography>
                                :
                                item.question === "What rights do parents and guardians have regarding their child's health data, such as the ability to review, update, or delete the information?" ?
                                    <Typography className={classes.answer} sx={answerStyle}>
                                        {item.answer}
                                        <a href='/terms-and-conditions'> terms & condition </a>
                                        and
                                        <a href='/privacy-policy'> privacy policy </a>
                                        documents
                                    </Typography>
                                    :
                                    <Typography className={classes.answer} sx={answerStyle}>
                                        {item.answer}
                                    </Typography>
                            }
                        </AccordionDetails>
                    </Accordion>

                ))}
            </div>
        </>

    )
}

export default Faq