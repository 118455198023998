import { Avatar } from "@mui/material";
import React, { useState } from "react";
import classes from "./InfirmaryRows.module.css";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { CollapseTable } from "../StudentTable/CollapseTable";
import moment from "moment";
import { capitalizeFirstLetter, textFormat, truncateString } from "../../../utils/utility";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RowPopup from "../TableComponents/RowPopup";

export default function TeacherRows({ data, userRole }) {
  const [user, setUser] = useState(false);
  const location = useLocation()
  const navigate = useNavigate()

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 12,
        height: 12,
        padding: 2
      },
      children: `${name[0]}`,
    };
  }

  const handleReport = () => {
    setUser(true)
  }

  function navigateStudent(x) {
    // 

    if (location.pathname.includes('superadmin')) {
      if (x === 'info') {
        navigate('/superadmin/user/profile', { state: { ...data, breadCrumbs: [...location.state.breadCrumbs, `${data?.given_name} ${data?.family_name}`] } })
      }
      else if (x === 'edit') {
        // navigate(`/superadmin/individual/edit/student`)
      }
    } else if (location.pathname.includes('principal')) {
      if (x === 'info') {
        navigate('/principal/user/profile', { state: {...data} })
      }
      else if (x === 'edit') {
        // navigate(`/principal/individual/edit/teacher`, { state: { ...data } })
      }
    } else if (location.pathname.includes('admin')) {
      if (x === 'info') {
        navigate('/admin/user/profile', { state: {...data} })
      }
      else if (x === 'edit') {
        // navigate(`/principal/individual/edit/teacher`, { state: { ...data } })
      }
    }

  }

  const TableRowRender = () => {

    if (userRole === 'Event') {
      return (
        <>
          <div className={classes.eventRow_container}>

            {/* Name and Avatar Container */}
            <div>
              {/* Avatar Container */}
              <div>
                {!data?.url ? <Avatar {...stringAvatar(`${data?.schoolName}`)} /> :
                  <div>
                    <img src={data?.url} alt="schoolLogo" width="32" height="32" style={{ borderRadius: 50 }} />
                  </div>}
              </div>

              {/* Name Container */}
              <div>
                <p>{capitalizeFirstLetter(data?.schoolName)}</p>
              </div>
            </div>

            {/* Gender Container */}
            <div>
              <p>{data?.pincode}</p>
            </div>

            {/* Email Container */}
            <div>
              <p>{data?.schoolCode}</p>
            </div>

            {/* Employment Container */}
            <div>
              <p>{data?.strength}</p>

            </div>
            {/* Screening Container */}
            <div>
              {/* <CollapseTable data = {"2 pax"}/> */}
              {textFormat(data?.package)}
            </div>

            {/*  */}
            {/* Conditional Buttons Rendering --Role Section */}
            <div>
              {`${moment(data?.screeningFrom).format("DD/MM/YYYY")} - ${moment(data?.screeningTo).format("DD/MM/YYYY")}`}
            </div>


            {/* Action Container */}
            <div>
              <p><Link to={"/superadmin-events"} state={{ schoolData: data }} style={{ color: "#1740A1" }}>Map Events</Link></p>
            </div>

          </div>
        </>
      )
    }
    else if (userRole === 'Users') {
      return (
        <>
          <div className={classes.usersRow_container}>

            {/* Name and Avatar Container */}
            <div>
              {/* Avatar Container */}
              <div>
                <Avatar {...stringAvatar(`${data?.given_name}`)} />
              </div>

              {/* Name Container */}
              <div>
                <p>{capitalizeFirstLetter(truncateString(`${data?.given_name} ${data?.family_name}`, 30))}</p>
              </div>
            </div>

            {/* Gender Container */}
            <div>
              <p>{truncateString(data?.email)}</p>
            </div>

            {/* Email Container */}
            <div>
              <p></p>
            </div>

            {/* Employment Container */}
            <div>
              <p>{data?.groups?.join(", ")}</p>
              {/* <p><span>i</span></p> */}

            </div>
            {/* Screening Container */}


            {/* Conditional Buttons Rendering --Role Section */}
            <div>
              {user ? (<button className={classes.screened_btn}>INACTIVE</button>) :
                (<button onClick={handleReport} className={classes.report_btn}>ACTIVE</button>)
              }
            </div>


            {/* Action Container */}
            <div style={{ justifySelf: "center" }}>
              <p>...</p>
            </div>

          </div>
        </>
      )
    }
    else if (userRole === "Admin") {
      return (
        <>
          <div className={classes.studentRow_container}>

            {/* Name and Avatar Container */}
            <div>
              {/* Avatar Container */}
              <div>
                <Avatar {...stringAvatar(`${data?.given_name}`)} />
              </div>

              {/* Name Container */}
              <div>
                <p>{capitalizeFirstLetter(truncateString(`${data?.given_name} ${data?.family_name}`, 30))}</p>
              </div>
            </div>

            {/* Gender Container */}
            <div>
              <p>{data?.gender}</p>
            </div>

            {/* Email Container */}
            <div>
              <p>{truncateString(data?.email, 30)}</p>
            </div>

            {/* Employment Container */}
            <div>
              <p>{"Full Time"}</p>

            </div>
            {/* Screening Container */}
            <div>
              <p>{textFormat(data?.groups?.join(", ").split("-")[1])}</p>
            </div>


            {/* Conditional Buttons Rendering --Role Section */}
            <div>
              {user ? (<button className={classes.screened_btn}>INACTIVE</button>) :
                (<button className={classes.report_btn}>ACTIVE</button>)
              }
            </div>


            {/* Action Container */}
            <div style={{ position: "relative", cursor: "pointer", justifySelf: "center" }}>
              <div className={classes.infoHover}>
                <p>...</p>
                <div className={classes.popOnHover}>
                  <RowPopup navLink={navigateStudent} />
                </div>
              </div>
            </div>

          </div>
        </>
      )
    }
    else if (userRole === "Doctor / Nurse") {
      return (
        <>
          <div className={classes.studentRow_container}>

            {/* Name and Avatar Container */}
            <div>
              {/* Avatar Container */}
              <div>
                <Avatar {...stringAvatar(`${data?.given_name}`)} />
              </div>

              {/* Name Container */}
              <div>
                <p>{capitalizeFirstLetter(truncateString(`${data?.given_name} ${data?.family_name}`, 30))}</p>
              </div>
            </div>

            {/* Gender Container */}
            <div>
              <p>{data?.gender}</p>
            </div>

            {/* Email Container */}
            <div>
              <p>{truncateString(data?.email, 30)}</p>
            </div>

            {/* Employment Container */}
            <div>
              <p>{"Full Time"}</p>

            </div>
            {/* Screening Container */}
            <div>
              <p>{data?.specialities ?? textFormat(data?.groups?.join(", ").split("-")[1]) ?? "Unavailable"}</p>
            </div>


            {/* Conditional Buttons Rendering --Role Section */}
            <div>
              {user ? (<button className={classes.screened_btn}>INACTIVE</button>) :
                (<button className={classes.report_btn}>ACTIVE</button>)
              }
            </div>


            {/* Action Container */}
            <div style={{ position: "relative", cursor: "pointer", justifySelf: "center" }}>
              <div className={classes.infoHover}>
                <p>...</p>
                <div className={classes.popOnHover}>
                  <RowPopup navLink={navigateStudent} />
                </div>
              </div>
            </div>

          </div>
        </>
      )
    }

    else if (userRole === "Infirmary") {
      return (
        <>
          <div className={classes.studentRow_container}>

            {/* Name and Avatar Container */}
            <div>
              {/* Avatar Container */}
              <div>
                <Avatar {...stringAvatar(`${data?.given_name}`)} />
              </div>

              {/* Name Container */}
              <div>
                <p>{capitalizeFirstLetter(truncateString(`${data?.given_name} ${data?.family_name}`, 30))}</p>
              </div>
            </div>

            {/* Gender Container */}
            <div>
              <p>{data?.gender}</p>
            </div>

            {/* Email Container */}
            <div>
              <p>{truncateString(data?.email, 30)}</p>
            </div>

            {/* Employment Container */}
            <div>
              <p>{"Full Time"}</p>

            </div>
            {/* Screening Container */}
            <div>
              <p>{data?.specialities ?? textFormat(data?.groups?.join(", ").split("-")[1]) ?? "Unavailable"}</p>
            </div>


            {/* Conditional Buttons Rendering --Role Section */}
            <div>
              {user ? (<button className={classes.screened_btn}>INACTIVE</button>) :
                (<button className={classes.report_btn}>ACTIVE</button>)
              }
            </div>


            {/* Action Container */}
            <div style={{ position: "relative", cursor: "pointer", justifySelf: "center" }}>
              <div className={classes.infoHover}>
                <p>...</p>
                <div className={classes.popOnHover}>
                  <RowPopup navLink={navigateStudent} />
                </div>
              </div>
            </div>

          </div>
        </>
      )
    }

    else if (userRole === "Principal") {
      
      return (
        <>
          <div className={classes.studentRow_container}>

            {/* Name and Avatar Container */}
            <div>
              {/* Avatar Container */}
              <div>
                <Avatar {...stringAvatar(`${data?.given_name}`)} />
              </div>

              {/* Name Container */}
              <div>
                <p>{capitalizeFirstLetter(truncateString(`${data?.given_name} ${data?.family_name}`, 30))}</p>
              </div>
            </div>

            {/* Gender Container */}
            <div>
              <p>{data?.gender}</p>
            </div>

            {/* Email Container */}
            <div>
              <p>{truncateString(data?.email)}</p>
            </div>

            {/* Employment Container */}
            <div>
              <p>{"Full Time"}</p>

            </div>
            {/* Screening Container */}
            <div>
              <p>{userRole}</p>
            </div>


            {/* Conditional Buttons Rendering --Role Section */}
            <div>
              {user ? (<button className={classes.screened_btn}>INACTIVE</button>) :
                (<button className={classes.report_btn}>ACTIVE</button>)
              }
            </div>


            {/* Action Container */}
            <div style={{ position: "relative", cursor: "pointer", justifySelf: "center" }}>
              <div className={classes.infoHover}>
                <p>...</p>
                <div className={classes.popOnHover}>
                  <RowPopup navLink={navigateStudent} />
                </div>
              </div>
            </div>

          </div>
        </>
      )
    }

    else if(userRole === "Doctor"){
      return (
        <>
          <div className={classes.studentRow_container}>

            {/* Name and Avatar Container */}
            <div>
              {/* Avatar Container */}
              <div>
                <Avatar {...stringAvatar(`${data?.given_name}`)} />
              </div>

              {/* Name Container */}
              <div>
                <p>{capitalizeFirstLetter(truncateString(`${data?.given_name} ${data?.family_name}`, 30))}</p>
              </div>
            </div>

            {/* Gender Container */}
            <div>
              <p>{data?.gender}</p>
            </div>

            {/* Email Container */}
            <div>
              <p>{truncateString(data?.email, 30)}</p>
            </div>

            {/* Employment Container */}
            <div>
              <p>{"Full Time"}</p>

            </div>
            {/* Screening Container */}
            <div>
              <p>{textFormat(data?.specialities) ?? "Unavailable"}</p>
            </div>


            {/* Conditional Buttons Rendering --Role Section */}
            <div>
              {user ? (<button className={classes.screened_btn}>INACTIVE</button>) :
                (<button className={classes.report_btn}>ACTIVE</button>)
              }
            </div>


            {/* Action Container */}
            <div style={{ position: "relative", cursor: "pointer", justifySelf: "center" }}>
              <div className={classes.infoHover}>
                <p>...</p>
                <div className={classes.popOnHover}>
                  <RowPopup navLink={navigateStudent} />
                </div>
              </div>
            </div>

          </div>
        </>
      )
    }
  }


  return (
    <>
      {
        TableRowRender()
      }
    </>
  );
}