import React, { useEffect, useState } from "react";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import schoolReducer from "../../redux/reducers/school.reducer";
import studentReducer from "../../redux/reducers/student.reducer";
import { StudentPage } from "../common/StudentTable/Student";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import OpsStudentRows from "../OpsTeam/OpsTeamDashboard/OpsManager/OpsStudentRow";
import classes from "./AssignDoctor.module.css";
import cssClasses from "../../components/OpsTeam/OpsTeamDashboard/OpsManager/OpsOperatorModal/OpsOperatorModal.module.css";
import AssignStudentRow from "./AssignStudentRow";
import BlankDataScreen from "../BlankDataScreen/BlankDataScreen";
import validationReducer from "../../redux/reducers/validation.reducer";
import LoadingBar from "react-top-loading-bar";
import userReducer from "../../redux/reducers/user.reducer";
import InputDropdown from "../common/InputDashboard/InputDropdown";
import screening from "../../graphqlApiServices/screening";

const AssignDoctor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const schools = useSelector((state) => state.schoolReducer.data);
  const getSchools = schoolReducer.actions.getSchoolsRequest;
  const getDoctors = userReducer.actions.getDoctorsRequest;
  const getStudentsBySection =
    studentReducer.actions.getStudentBySectionRequest;
  const doctors = useSelector((state) => state.userReducer.users);
  const assign = validationReducer.actions.assignDoctorRequest;
  const data = useSelector((state) => state.studentReducer.data);
  // const loading = useSelector((state) => state.studentReducer.loading);
  const [selectedSchool, setSelectedSchool] = useState();
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [classList, setClassList] = useState([]);
  const [displayData, setDisplayData] = useState(data ?? []);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(1);

  const { immediateAssign } = screening;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    width: "90vw",
    maxWidth: "500px",
  };

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [open, setOpen] = useState(false);
  const [phyDoctors, setPhyDoctors] = useState([]);
  const [phyDoctor, setPhyDoctor] = useState();
  const [bhDoctors, setBhDoctors] = useState([]);
  const [bhDoctor, setBhDoctor] = useState();
  const [dentists, setDentists] = useState([]);
  const [dentist, setDentist] = useState();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const filteredData = data.filter((x) => x.status === "SCREENING_DONE");
    setDisplayData(filteredData);
  }, [data]);

  useEffect(() => {
    if (doctors && doctors.length > 0) {
      const phy = doctors.filter((x) => x.specialities === "PAEDIATRICIAN");
      const bh = doctors.filter((x) => x.specialities === "BEHAVIORAL");
      const dent = doctors.filter((x) => x.specialities === "DENTAL");
      setPhyDoctors(phy);
      setBhDoctors(bh);
      setDentists(dent);
    }
  }, [doctors]);

  useEffect(() => {
    if (selectedSchool) {
      const x = JSON.parse(selectedSchool?.classMap);
      const y = Object.keys(x);
      const cls = [];
      for (let i = 0; i < y?.length; i++) {
        cls.push(y[i]?.split("_").join("-"));
      }
      cls.sort((a, b) => a.localeCompare(b, "en", { numeric: true }));
      setClassList(cls);
    }
  }, [selectedSchool]);

  useEffect(() => {
    if (selectedSchool && selectedClass) {
      dispatch(
        getStudentsBySection({
          schoolId: selectedSchool?.schoolId,
          classVal: selectedClass.split("-")[0],
          section: selectedClass.split("-")[1],
        })
      );
    }
  }, [selectedClass]);

  useEffect(() => {
    setPhyDoctors([]);
    setBhDoctors([]);
    setDentists([]);
    if (location.pathname.includes("superadmin")) {
      dispatch(getSchools());
      dispatch(getDoctors());
    }
  }, []);

  const resetState = () => {
    setProgress(0);
    setDisplayData([]);
    setSelectedSchool();
    setSelectedClass();
    setSelectedSection();
    setPhyDoctor();
    setBhDoctor();
    setDentist();
  };

  const handleImmediateAssign = () => {
    setLoading(true);
    if (!bhDoctor || !dentist || !phyDoctor) {
      return;
    }
    handleClose();
    displayData?.map((x, index) => {
      immediateAssign({
        bhv: bhDoctor,
        dental: dentist,
        phy: phyDoctor,
        screeningId: x?.screenings[0],
      })
        .then((x) => {
          setProgress(index);
          if (index === displayData?.length - 1) {
            setLoading(false);
            setOpenSnackBar(true);
            resetState();
          }
        })
        .catch((error) => {
          setError(true);
          setOpenSnackBar(true);
          setLoading(false);
        });
    });
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            alignItems: "center",
            flexDirection: "column",
            width: "70%",
            zIndex: 999,
            position: "absolute",
            backgroundColor: "#FAF8F7",
          }}
        >
          <CircularProgress size={50} />
          <div style={{ marginTop: 40 }}>
            Please Wait, assigning doctors is in progress
          </div>
          <div
            style={{ marginTop: 20 }}
          >{`${progress}/${displayData.length}`}</div>
        </div>
      ) : (
        <>
          <div className={classes.assign_header}>
            <BreadCrumbs />
            <button
              className={classes.assign_validation_progress_btn}
              onClick={() => setOpen(true)}
              disabled={displayData?.length === 0}
            >
              Assign Doctor
            </button>
          </div>
          {schools && (
            <div style={{ display: "flex", gap: 10 }}>
              <FormControl>
                {/* <InputLabel id="demo-simple-select-label">School</InputLabel> */}
                <Autocomplete
                  size="small"
                  options={schools}
                  value={selectedSchool}
                  sx={{ width: 250 }}
                  // placeholder={"School"}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      label={"School"}
                      InputLabelProps={{ style: { fontSize: 15 } }}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>{option.schoolName}</li>
                  )}
                  getOptionLabel={(x) => {
                    return x?.schoolName ?? "";
                  }}
                  onChange={(e, value) => {
                    setSelectedSchool(value);
                  }}
                />
              </FormControl>
              <FormControl>
                <InputLabel id="demo-simple-select-label" size="small">
                  Class
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedClass}
                  label="Class"
                  onChange={(e) => setSelectedClass(e.target.value)}
                  size="small"
                  disabled={!selectedSchool}
                  sx={{ width: 150 }}
                >
                  {classList?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}
          {displayData?.length > 0 && (
            <div className={classes.student_table_header}>
              <div>STUDENT NAME</div>
              <div>AGE</div>
              <div>GENDER</div>
              <div>CLASS</div>
              <div>SCREENING DATE & TIME</div>
            </div>
          )}
          {/* Student Column-Rows Section */}
          <div id="schoolRowCy" className={classes.student_column_container}>
            {displayData?.length ? (
              displayData
                ?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo)
                .map((el, index) => {
                  return <AssignStudentRow key={index} data={el} />;
                })
            ) : (
              <>
                <div style={{ marginTop: 20 }}>
                  <BlankDataScreen
                    title="Oops! No data available"
                    description="Please select the above filters or try changing them."
                    hideButton={true}
                  />
                </div>
              </>
            )}
          </div>
          <div className={classes.pagination_container}>
            <div className={classes.page_item}>{`Showing ${
              rowsPerPage * (pageNo - 1) + 1
            } to ${
              pageNo * rowsPerPage <= displayData?.length
                ? pageNo * rowsPerPage
                : displayData?.length
            } of ${displayData?.length} items`}</div>
            <div className={classes.pagination}>
              <Button
                size="small"
                disabled={pageNo === 1}
                onClick={() => setPageNo(pageNo - 1)}
              >
                <ChevronLeft />
              </Button>
              <span>{pageNo}</span>
              <div>
                <span>of</span>
                <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
              </div>

              <div>
                <Button
                  size="small"
                  disabled={
                    pageNo === Math.ceil(displayData?.length / rowsPerPage)
                  }
                  onClick={() => setPageNo(pageNo + 1)}
                >
                  <ChevronRight />
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
      {/* <LoadingBar
                color='#1740A1'
                height={4}
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            /> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backdropFilter: "blur(2px)" }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
            Select Doctor
          </Typography>
          <FormControl fullWidth style={{ marginBottom: 20 }}>
            <InputLabel id="demo-simple-select-label">
              Physical Doctor
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={phyDoctor}
              label="Physical Doctor"
              onChange={(e) => setPhyDoctor(e.target.value)}
            >
              {phyDoctors.map((x) => {
                return (
                  <MenuItem
                    value={x.userId}
                  >{`${x.given_name} ${x.family_name}`}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 20 }}>
            <InputLabel id="demo-simple-select-label">
              Behavioral Doctor
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={bhDoctor}
              label="Physical Doctor"
              onChange={(e) => setBhDoctor(e.target.value)}
            >
              {bhDoctors.map((x) => {
                return (
                  <MenuItem
                    value={x.userId}
                  >{`${x.given_name} ${x.family_name}`}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 20 }}>
            <InputLabel id="demo-simple-select-label">Dental Doctor</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={dentist}
              label="Physical Doctor"
              onChange={(e) => setDentist(e.target.value)}
            >
              {dentists.map((x) => {
                return (
                  <MenuItem
                    value={x.userId}
                  >{`${x.given_name} ${x.family_name}`}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <div className={cssClasses.buttonDiv}>
            <button className={cssClasses.CancelButton} onClick={handleClose}>
              Cancel
            </button>
            <button
              className={cssClasses.sendButton}
              onClick={handleImmediateAssign}
            >
              Assign Doctors
            </button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenSnackBar(false)}
          severity={!error && !loading ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {!error
            ? "Doctors Assigned Successfully"
            : "Error While Assigning Doctor"}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AssignDoctor;
