import React, { useEffect, useState } from "react";
import classes from "./OpsEvent.module.css";
import BreadCrumbs from "../../../common/TableComponents/BreadCrumbs";
import moment from "moment";
import { Alert, Avatar, CircularProgress, List, Snackbar, Typography } from "@mui/material";
import notification from "../../../../assets/audios/notificationSound.mp3";
import { stringAvatar } from "../../../../utils/utility";
import TabButton from "../../../common/TableComponents/TabButton";
import { useLocation } from "react-router-dom";
import eventReducer from "../../../../redux/reducers/event.reducer";
import { useDispatch, useSelector } from "react-redux";
import events from '../../../../graphqlApiServices/events'
import { CheckCircle } from "@mui/icons-material";

function OpsEvent() {
    const [active, setActive] = useState(0);
    const location = useLocation()
    const schoolData = location.state
    const dispatch = useDispatch()
    const { completeEventMap } = events
    const getEventMaps = eventReducer.actions.getEventMapsRequest;
    const sortedEvent = useSelector((state) => state.eventReducer.eventMapData);
    const loading = useSelector((state) => state.eventReducer.loading);
    const eventMapData = [...sortedEvent]
    eventMapData.sort((a, b) => a.date.localeCompare(b.date))
    const dates = [...new Set(eventMapData.map(item => item.date))]
    const [selectedDate, setSelectedDate] = useState();

    useEffect(() => {
        setSelectedDate(moment(dates[0]).format("YYYY-MM-DD"));
        setActive(0)
    }, [sortedEvent])

    useEffect(() => {
        dispatch(getEventMaps(`${schoolData?.schoolId}`))
    }, [])

    const handleCompleted = (id) => {
        const newData = sortedEvent.filter(item => item.mapId == id)
        const params = {
            eventId: newData[0]?.eventId,
            mapId: newData[0]?.mapId
        };
        completeEventMap(params, callback)
    }

    function callback(type, res) {
        type == "success" ? dispatch(getEventMaps(`${schoolData?.schoolId}`)) : console.log("err" + JSON.stringify(res))
    }

    const handleClick = (e, i) => {
        setActive(i);
        setSelectedDate(moment(e).format("YYYY-MM-DD"));
    };

    return (
        <div className={classes.main_div}>
            <div className={classes.header}>
                <div>
                    <BreadCrumbs />
                </div>
            </div>
            <div className={classes.container}>
                <div className={classes.student_details_container}>
                    <div className={classes.student_details}>
                        <div>
                            <div className={classes.no_upload_logo}>
                                {schoolData?.url
                                    ? <img src={schoolData?.url} alt="" />
                                    : <Avatar style={{ width: '100%', height: '100%', fontSize: '150%' }} {...stringAvatar(schoolData?.schoolName)} />
                                }
                            </div>
                        </div>
                        <div className={classes.student_details_information}>
                            <div className={classes.student_details_information_name}>
                                {schoolData?.schoolName}
                            </div>
                            <div className={classes.student_details_information_general_info}>
                                <p>{`${schoolData?.address}, ${schoolData?.city}, ${schoolData?.state}, ${schoolData?.pincode}`}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={classes.physical_report_header}>
                        <div>School Code</div>
                        <div>Screening Dates</div>
                    </div>
                    <div className={classes.physical_report_header_data}>
                        <div className={classes.schoolCode}>{schoolData?.schoolCode}</div>
                        <div className={classes.schoolEvent}>
                            {`${moment(schoolData?.screeningFrom).format("DD/MM/YYYY")} - ${moment(schoolData?.screeningTo).format("DD/MM/YYYY")}`}
                        </div>
                    </div>

                    <div className={`${classes.physical_report_header} ${classes.total_ops_student_container}`}>
                        <div>Total Students</div>
                        <div>Ops Team</div>
                    </div>
                    <div className={classes.physical_report_header_data}>
                        <div className={classes.totalStudents_text}>
                            {schoolData?.strength}
                        </div>
                        <div className={classes.totalStudents_text}>-</div>
                    </div>
                </div>
                {loading ?
                    <CircularProgress sx={{ color: '#149457', margin: '40px 30px' }} />
                    : <>
                        <div className={classes.device_mapping_text}>
                            Mapped Events
                        </div>

                        <div className={classes.organs_container}>
                            {dates?.map((el, i) => {
                                return (
                                    <div className={`${active === i && classes.organs_container_elements_active}`}
                                        onClick={(e) => handleClick(el, i)}
                                        key={i}
                                    >{`${moment(el).format('DD MMM, YYYY')}`}</div>
                                );
                            })}
                        </div>
                        <div className={classes.class_mapping_text}>Mapped Classes</div>
                        <div style={{ marginTop: 20 }}>
                            <List dense>
                                {Array.isArray(eventMapData) && eventMapData?.map((x, i) => {
                                    return (
                                        x.date == selectedDate && <div className={classes.mappedClass} >
                                            <div>{x?.class + " " + x?.section}</div>
                                            {x?.completed ?
                                                <>
                                                    <button className={classes.button_filled}>Completed</button>
                                                    <p><CheckCircle sx={{ fill: "#149457" }} />Marked complete on {moment(x?.completedAt).format('DD MMM, YYYY')} at {moment(x?.completedAt).format('LT')}</p>
                                                </>
                                                : <button className={classes.button_with_border} onClick={() => handleCompleted(x?.mapId)}>Mark complete</button>
                                            }
                                        </div>
                                    )
                                })
                                }
                            </List>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

// function Calendar({ schoolData }) {
//     const data = useSelector((state) => state.eventReducer.data);

//     return <OpsEvent data={data} schoolData={schoolData} />;
// }

export default OpsEvent;
