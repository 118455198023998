import { React, useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import VideoComp from './VideoComp';
import CameraIcon from '@mui/icons-material/Camera';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const btn = {

  border: 'none',
  background: 'none'
}

const screenShotIcon = {
  position: 'absolute',
  top: "10px",
  left: "10px",
  fill: "white",
  fontSize: "30px"
}

const slideshow_container = {
  position: 'relative',
  height: '85vh',
  width: '60vw',
  borderRadius: '10px',
  top: '50%',
  left: '50%',
  zIndex: 9999,
  backgroundColor: '#000',
  transform: 'translate(-50%, -50%)',
}

const image_slides = {
  height: '500px',
  width: '500px',
  borderRadius: '10px'
}

const videoPlayer = {
  // borderRadius : '10px'',
  zIndex: 9999,
  height: '100%',
  width: '100%',
  objectFit: 'contain'
}


export default function BasicModal(props) {
  const captured = document.getElementById("captured");
  // const [isPlaying, setIsPlaying] = React.useState(false);
  const { videoUrl, imgs } = props;
  const [progress, setProgress] = useState(0);
  const videoRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  var scaleFactor = 0.25;
  var snapshots;


  useEffect(() => {
    if (captured) {
      captured.innerHTML = '';
    }
  }, [captured])

  function capture(video, scaleFactor) {
    if (scaleFactor == null) {
      scaleFactor = 1;
    }
    var w = video.videoWidth * scaleFactor;
    var h = video.videoHeight * scaleFactor;
    var canvas = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, w, h);
    return canvas;
  }

  function shoot() {
    let video = document.getElementById("videoId");
    let canvas = capture(video, scaleFactor);
    snapshots = canvas;
    if (captured) {
      captured.innerHTML = "";
      captured.appendChild(snapshots);
    }
  }


  const getImg = () => {
    const x = imgs?.length;
    return imgs?.[Math.floor(x / 2)]
  }


  return (
    <div>
      <VideoComp size={props?.size} onClick={handleOpen} img={getImg()} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box sx={style}> */}
        <div style={slideshow_container}>
          <video style={videoPlayer}
            id="videoId"
            ref={videoRef}
            // width="100%"
            // height="100%"
            zIndex='9999'
            controls
            muted
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
          {
            props?.type === "doctor" &&
            <div onClick={shoot}>
              <CameraIcon style={screenShotIcon} />
            </div>
          }
          <div>
          </div>
        </div>
        {/* </Box> */}
      </Modal>
    </div>
  );
}