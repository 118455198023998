import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route } from 'react-router-dom'
import DashboardLayout from '../components/common/DashboardLayout/DashboardLayout'
import Navbar from '../components/common/Navbar/Navbar'

function OpsRoutes({component}) {

  const user = useSelector((state)=>state.userReducer.currentUser)
  
  if(!user){
    return <Navigate to={"/login"} replace/>
  }
  // else if(!user?.groups?.includes("SKIDS-Ops")){
  //   return <Navigate to={`/404`}/>
  // }
  else{
    return (
      <>
        <div className='baseStructure'>
          <DashboardLayout />
          <div className='bsrs'>
            <Navbar />
            <div className='bsrb'>
              {component}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default OpsRoutes