import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./OpsCalendar.module.css";
// import SchoolRow from "./SchoolRow";
// import schoolReducer from "../../../redux/reducers/school.reducer";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";
import TabButton from "../common/TableComponents/TabButton";
import SearchBar from '../common/TableComponents/SearchBar'
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CustomAutoComplete from "../common/CustomAutoComplete/CustomAutoComplete"
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { City } from "country-state-city";
import schoolReducer from "../../redux/reducers/school.reducer";
import OpsCalendarRow from "./OpsCalendarRow";
import BlankDataScreen from "../BlankDataScreen/BlankDataScreen";

function OpsCalendar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const getSchools = schoolReducer.actions.getSchoolsRequest;
  const getSchoolByState = schoolReducer.actions.getSchoolbyStateRequest;
  const user = useSelector((state) => state.userReducer.currentUser)
  const breadCrumbs = [
    "Registered Schools"
  ]

  useEffect(() => {
    if (user?.userAttributes && user?.userAttributes?.state) {
      dispatch(getSchoolByState({ state: user?.userAttributes?.state }));
    } else {
      dispatch(getSchools());
    }
    location.state = { breadCrumbs: breadCrumbs }
  }, [])


  const data = useSelector((state) => state.schoolReducer.data)
  const loading = useSelector((state) => state.schoolReducer.loading)

  function getWordBeforeDash() {
    let newStr = "";
    for (let i = 1; i < location.pathname.length; i++) {
      if (location.pathname[i] === "-") {
        return newStr;
      }
      newStr += location.pathname[i];
    }
  }

  const allCities = [...new Set(City.getCitiesOfCountry("IN").map((x) => x.name))]

  const [open, setOpen] = useState(false);
  const [sec, setSec] = useState(false);
  const [text, setText] = useState("");
  const [displayData, setDisplayData] = useState(data ?? []);
  const [city, setCity] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageNo, setPageNo] = useState(1)
  const [chipModal, setChipModal] = useState(false)

  useEffect(() => {
    if (city && text) {
      setDisplayData(data.filter((x) => x.city === city && x.schoolName.toLowerCase().includes(text.toLowerCase())))
      setPageNo(1)
    }
    else if (city) {
      setDisplayData(data.filter((x) => x.city === city))
      setPageNo(1)
    }
    else if (text) {
      setDisplayData(data.filter((x) => x?.schoolName.toLowerCase().includes(text.toLowerCase())))
      setPageNo(1)
    }
    else {
      setDisplayData(data)
    }
  }, [city, text])

  useEffect(() => {
    if (Array.isArray(data)) {
      const compareDates = (a, b) => {
        if (a.screeningFrom < b.screeningFrom) {
          return 1;
        }
        if (a.screeningFrom > b.screeningFrom) {
          return -1;
        }
        return 0;
      };
      let sortedData = [...data].sort(compareDates);

      setDisplayData(sortedData)
    }
  }, [data])

  const handleOpen = () => {

    setOpen(!open);
  };
  const handleCity = (x) => {
    setCity(x)
    if (displayData?.length === data.length) {
      setDisplayData(data.filter((x) => x.city === x))
    }
    // setDisplayData()
  };


  return (
    <div className={classes.main_div}>
      <div className={classes.student_layout}>
        <div>
          Events
        </div>
      </div>
      {
        data.length > 0 ?
          <>
            <div className={classes.student_filters}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={rowsPerPage}
                  label="Rows"
                  onChange={(e) => setRowsPerPage(e.target.value)}
                  size='small'
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              {/* <CustomAutoComplete options={allCities} value={city} placeholder={"City"} onChange={setCity}/> */}
              <SearchBar id={"searchCy"} onchange={setText} value={text} />
            </div>
            {/* Student Table Header */}
            <div className={classes.student_table_header}>
              <div>SCHOOL NAME</div>
              <div>PINCODE</div>
              <div>SCHOOL CODE</div>
              <div>STUDENTS</div>
              <div>SCREENING START DATE</div>
              <div>SCREENING END DATE</div>
              <div>STATUS</div>
              <div style={{ justifySelf: "center" }}>ACTION</div>
            </div>
            {/* Student Column-Rows Section */}
            <div id="schoolRowCy" className={classes.student_column_container}>
              {Array.isArray(displayData) && displayData?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo).map((el, index) => {
                return (
                  <OpsCalendarRow data={el} key={index + Date.now()} index={index} />
                )
              })}
            </div>
            <div className={classes.pagination_container}>
              <div className={classes.page_item}>{`Showing ${(rowsPerPage * (pageNo - 1)) + 1} to ${pageNo * rowsPerPage <= displayData?.length ? pageNo * rowsPerPage : displayData?.length} of ${displayData?.length} items`}</div>
              <div className={classes.pagination}>
                <Button size="small" disabled={pageNo === 1} onClick={() => setPageNo(pageNo - 1)} >
                  <ChevronLeft />
                </Button>
                <span>{pageNo}</span>
                <div>
                  <span>of</span>
                  <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
                </div>

                <div>
                  <Button size="small" disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)} onClick={() => setPageNo(pageNo + 1)} >
                    <ChevronRight />
                  </Button>
                </div>
              </div>
            </div>
          </>
          :
          loading ? "" :
            <BlankDataScreen title="Oops! No data available" description="There is no data uploaded in this table to be shown. Click on Add Calender to upload data." handleClick="" />


      }

      {/* <ChipModal 
        action = {chipModal} 
        setAction = {setChipModal}
        title = "Share Add School Typeform"
        subtitle = "Please enter email id below to share “Add School” typeform to school representative."
      /> */}
    </div>
  );
}

export default OpsCalendar