import React from "react";
import classes from "./Anxiety4.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import mcneil from "../../../assets/images/McNeil.svg";
import nichq from "../../../assets/images/NICHQ.svg";
import americanPediatrics from "../../../assets/images/americanPediatrics.svg";

export const Anxiety4 = () => {


  return (
    <>
      <div className={classes.container}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>


        <div className={classes.copyright_details_container}>
          <div>
            The information contained in this publication should not be used as
            a substitute for the medical care and advice of your psychologist.
            There may be variations in treatment that your psychologist may
            recommend based on individual facts and circumstances.
          </div>
          <div>
            Copyright @2002 American Academy of Pediatrics and National
            Initiative for Children’s Healthcare Quality
            <br /> Adapted from the Vanderbilt Rating Scale developed by Mark L.
            Wolraich, MD.
            <br /> Revised - 1102
          </div>
        </div>
        <div className={classes.method_framework}>Method Framework</div>

        <div className={classes.certification_container}>
          <img src={americanPediatrics} alt="americanPediatrics" />
          <img src={nichq} alt="nichq" />
          <img src={mcneil} alt="mcneil" />
        </div>

        <div className={classes.footer_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 4 of 4</p>
          </div>
        </div>
      </div>
    </>
  );
};
