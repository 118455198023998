import { all } from "redux-saga/effects";
import { watchClinicRequests } from "./clinic.saga";
import { watchDashboardRequests } from "./Dashboard.saga";
import { watchSchoolRequests } from "./School.saga";
import { watchScreenRequests } from "./Screening.saga";
import { watchStudentRequests } from "./Student.saga";
import { watchUserRequests } from "./user.saga";
import { watchValidationRequests } from "./validation.saga";
import { watchEventRequests } from "./events.saga";
import { watchTypeformRequests } from "./typeform.saga";
import { watchAnalyticsRequests } from "./analytics.saga";
import { watchSupportRequest } from "./support.saga";
import { watchBlogRequest } from "./blog.saga";

export default function* rootSaga() {
    yield all(
        [
            watchSchoolRequests(),
            watchStudentRequests(),
            watchClinicRequests(),
            watchUserRequests(),
            watchScreenRequests(),
            watchDashboardRequests(),
            watchEventRequests(),
            watchTypeformRequests(),
            watchValidationRequests(),
            watchAnalyticsRequests(),
            watchSupportRequest(),
            watchBlogRequest()
        ]
    );
}