import { API, graphqlOperation } from "aws-amplify";
import Cookies from "js-cookie";

function createSupportTicket(payload) {
  const token = Cookies.get("tokenId");

  return API.graphql(
    graphqlOperation(
      `
    mutation MyMutation(
      $userId: String!, 
      $topic: String!, 
      $email: String!, 
      $mobile: String!, 
      $issue: String!, 
      $dateOfIssue: String!, 
      $issueImage: AWSJSON
    ) {
        createSupportTicket(
            userId: $userId
            topic: $topic
            email: $email
            mobile: $mobile
            dateOfIssue: $dateOfIssue
            issue: $issue
            issueImage: $issueImage
          ) {
            statusCode
            data
            message
          }
    }
  `,
      {
        userId: payload.userId,
        topic: payload.topic,
        email: payload.email,
        mobile: payload.mobile,
        issue: payload.issue,
        dateOfIssue: payload.dateOfIssue,
        issueImage: payload.issueImage,
      },
      token
    )
  );
}

function getSupportTickets() {
  const token = Cookies.get("tokenId");

  return API.graphql(
    graphqlOperation(
      `
    query MyQuery {
      getSupportTickets(count: 10, nextToken: "") {
          ticketId
          email
          topic
          mobile
          dateOfIssue
          issue
          status
          url
          userId
          createdAt
          updatedAt
      }
    }
  `,
      {},
      token
    )
  );
}

function getSupportTicketById(payload) {
  const token = Cookies.get("tokenId");
  const params = payload.payload;
  return API.graphql(
    graphqlOperation(
      `
    query MyQuery {
      getSupportTicketById(ticketId: "${params.ticketId}") {
          ticketId
          email
          mobile
          topic
          dateOfIssue
          issue
          status
          url
          userId
          createdAt
          updatedAt
      }
    }
  `,
      {},
      token
    )
  );
}

function updateSupportTicket(payload) {
  const token = Cookies.get("tokenId");
  const params = payload.payload;
  return API.graphql(
    graphqlOperation(
      `
    mutation MyMutation {
      updateSupportTicket(ticketId: "${params.ticketId}", status: "${params.status}") {
          ticketId
          email
          mobile
          topic
          dateOfIssue
          issue
          status
          url
          userId
          createdAt
          updatedAt
      }
    }
  `,
      {},
      token
    )
  );
}

const support = {
  createSupportTicket,
  getSupportTickets,
  getSupportTicketById,
  updateSupportTicket,
};

export default support;
