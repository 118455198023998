//regex check for username validation
export const verifyUserName = (item) => {
  var usernameRegex =
    /^[a-zA-Z0-9]+([a-zA-Z0-9](_|-| )[a-zA-Z0-9])*[a-zA-Z0-9]+$/;
  if (usernameRegex.test(item)) {
    return false;
  } else {
    return true;
  }
};

//regex check for password validation
export const verifyPassword = (item) => {
  var re = {
    capital: /[A-Z]/,
    lower: /[a-z]/,
    digit: /[0-9]/,
    specialcharacter: /[@$!%*?&_]/,
    full: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$/,
  };
  
  let obj = {
    capital: true,
    lower: true,
    digit: true,
    specialcharacter: true,
    full: true,
  }

  if(re.capital.test(item)){
    obj.capital = false;
  }
  if(re.lower.test(item)){
    obj.lower = false;
  }
  if(re.digit.test(item)){
    obj.digit = false;
  }
  if(re.specialcharacter.test(item)){
    obj.specialcharacter = false;
  }
  if(re.full.test(item)){
    obj.full = false;
  }
  return obj;
};

//regex check for email validation
export const verifyEmail = (item) => {
    var emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (emailRegex.test(item)) {
      return false;
    } else {
      return true;
    }
  };
