import InitialStore from "../store/InitialStore";
import { createSlice } from "@reduxjs/toolkit";

export const incrementRequest = (state, action) => {
    if(state.play){
        if(state.index===action.payload-1){
            state.index=0
            state.play=false
    }
        else{
            state.index = state.index + 1
        }
    }
};

export const playVideo = (state, action) => {
    state.play = true
}

export const pauseVideo = (state, action) => {
    state.play = false
}

export const resetIncrementRequest = (state, action) => {
    state.index = 0
    state.play = false
}

export default createSlice({
    name: "increment",
    initialState: InitialStore.increment,
    reducers: {
        incrementRequest,
        pauseVideo,
        playVideo,
        resetIncrementRequest
    }
})