import * as React from "react"
const ValidationRedCheck = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={19}
        fill="none"
        {...props}
    >
        <path
            fill="#D95A59"
            fillRule="evenodd"
            d="M.613 18.13a.72.72 0 0 0 .652.37h19.47a.72.72 0 0 0 .652-.37.662.662 0 0 0 0-.74L11.652.87A.72.72 0 0 0 11 .5a.72.72 0 0 0-.652.37L.613 17.39a.662.662 0 0 0 0 .74Zm9.944-10.565a.738.738 0 0 1 .54-.21c.217 0 .397.07.539.21.142.14.213.316.213.53v4.044c0 .214-.071.39-.213.53a.738.738 0 0 1-.54.21.738.738 0 0 1-.539-.21.712.712 0 0 1-.213-.53V8.095c0-.214.07-.39.213-.53Zm.54 8.05c.217 0 .397-.07.539-.21a.712.712 0 0 0 .213-.53.712.712 0 0 0-.213-.53.738.738 0 0 0-.54-.21.738.738 0 0 0-.539.21.712.712 0 0 0-.213.53c0 .214.07.391.213.53.142.14.322.21.54.21Zm8.333 1.406H2.57L11 2.72l8.43 14.301Z"
            clipRule="evenodd"
        />
    </svg>
)
export default ValidationRedCheck
