import React from "react";
import classes from "./ErrorLayout.module.css";
import { Link } from "react-router-dom";
function ErrorLayout({ errorHeader, message, link, linkMessage, errorCode, errorMessage }) {
  return (
    <div className={classes.error_layout}>
      <div className={classes.error_header}>{errorHeader}</div>
      <div className={classes.divider}></div>
      <div className={classes.error_subtext}>{message}</div>
      <Link to={link} style={{ textDecoration: "none", color: "#3b3cea" }}>
        <div className={classes.error_return}>{linkMessage}</div>
      </Link>
      <div className={classes.error_pannel}>
        <div className={classes.error_box}>
          <div className={classes.error_message}>{errorCode}</div>
          <div className={classes.error_warning}>{errorMessage}</div>
        </div>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.policy}>
        Skids Health 2023, All Rights Reserved |{" "}
        <Link
          to="/privacy-policy"
          style={{ textDecoration: "none", color: "black" }}
        >
          Privacy Policy{" "}
        </Link>{" "}
      </div>
    </div>
  );
}

export default ErrorLayout;
