import React from "react";
import classes from "./AutismReport2.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import { textFormat } from "../../../utils/utility";
import mcneil from "../../../assets/images/McNeil.svg";
import nichq from "../../../assets/images/NICHQ.svg";
import americanPediatrics from "../../../assets/images/americanPediatrics.svg";

export const AutismReport4to62 = ({ data }) => {
  const questionBank = [
    {
      qno: 10,
      question:
        "Do you feel your child has difficulty in playing or interacting with other kids? (Ex: doesn't show interest or Is eager to play with other kids but doesn't know how to interact with them)",
      answer: textFormat(data?.output?.[0]?.answers?.[9])
    },
    {
      qno: 11,
      question:
        "Is very adamant about sharing toys and play according to their wish?",
      answer: textFormat(data?.output?.[0]?.answers?.[10])
    },
    {
      qno: 12,
      question:
        "Does not show any particular liking towards other kids? (Ex: Does not have best friend to play with)",
      answer: textFormat(data?.output?.[0]?.answers?.[11])
    },
    {
      qno: 13,
      question:
        "Has difficulty following rule based games and cannot play in a group?(Ex: Difficulty understanding turn taking while playing)",
      answer: textFormat(data?.output?.[0]?.answers?.[12])
    },
    {
      qno: 14,
      question:
        "Does your child walk in an uncoordinated manner? (Ex: walks on toes/tip-toing)",
      answer: textFormat(data?.output?.[0]?.answers?.[13])
    },
    {
      qno: 15,
      question:
        "Does unusual face or body movements that are involuntary? (Ex: head banging, rocking back and forth, hand flapping, repetitive blinking, facial grimacing...)",
      answer: textFormat(data?.output?.[0]?.answers?.[14])
    },
    {
      qno: 16,
      question:
        "Does your child find it hard to complete day to day activities? (Ex: simple self-care activities such as cleaning teeth, bathing, eating, dressing and so on)",
      answer: textFormat(data?.output?.[0]?.answers?.[15])
    },
    {
      qno: 17,
      question:
        "Is it difficult for your child to accept change in routine and switching activities? (Ex: does not like to shift from one activity/task to another)",
      answer: textFormat(data?.output?.[0]?.answers?.[16])
    },
    {
      qno: 18,
      question:
        "Does your child show unusual attachment to certain toys or part of the toys or objects? (Ex: insists on playing only with one toy or playing in a repetitive manner with few toys, plays with wheels of toys)",
      answer: textFormat(data?.output?.[0]?.answers?.[17])
    },
    {
      qno: 19,
      question:
        "Do you feel your child gets excluded from social groups? (Ex: other children of the same age do not include him/her for play)",
      answer: textFormat(data?.output?.[0]?.answers?.[18])
    },
    {
      qno: 20,
      question:
        "Does your child have unusual posture? (Ex: Arched back, holding hands/fingers out at an angle)",
      answer: textFormat(data?.output?.[0]?.answers?.[19])
    },
    {
      qno: 21,
      question:
        "Is your child sensitive to certain sound, smell, touch, taste or texture?",
      answer: textFormat(data?.output?.[0]?.answers?.[20])
    },
    {
      qno: 22,
      question:
        "Does your child point to ask for help and point to show something interesting? (Ex: points to a car on the road to direct the parents attention and points to the cookie jar as a request to get the cookie)",
      answer: textFormat(data?.output?.[0]?.answers?.[21])
    },
    // {
    //   qno: 24,
    //   question:
    //     "Do you feel your child gets excluded from social groups? (Ex: other children of the same age do not include him/her for play)",
    //     answer: textFormat(data?.output?.[0]?.answers?.[23])
    // },
    // {
    //   qno: 25,
    //   question:
    //     "Does your child have unusual posture? (Ex: Arched back, holding hands/fingers out at an angle)",
    //     answer: textFormat(data?.output?.[0]?.answers?.[24])
    // },
    // {
    //   qno: 26,
    //   question:
    //     "Is your child sensitive to certain sound, smell, touch, taste or texture?",
    //     answer: textFormat(data?.output?.[0]?.answers?.[25])
    // },
    // {
    //   qno: 27,
    //   question:
    //     "Does your child point to ask for help and point to show something interesting? (Ex: points to a car on the road to direct the parents attention and points to the cookie jar as a request to get the cookie)",
    //     answer: textFormat(data?.output?.[0]?.answers?.[26])
    // },
  ];

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>

        <div className={classes.assessment_question_container}>
          <div>Assessment Questionnaire Continued...</div>
          <div className={classes.question_select_container}>
            {questionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="No"
                          checked={el.answer === "No"}
                        />
                        <p>No</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Yes"
                          checked={el.answer === "Yes"}
                        />
                        <p>Yes</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Somewhat"
                          checked={el.answer === "Somewhat"}
                        />
                        <p>Somewhat</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes.copyright_details_container}>
          <div>
            The information contained in this publication should not be used as
            a substitute for the medical care and advice of your psychologist.
            There may be variations in treatment that your psychologist may
            recommend based on individual facts and circumstances.
          </div>
          <div>
            Copyright @2002 American Academy of Pediatrics and National
            Initiative for Children’s Healthcare Quality
            <br /> Adapted from the Vanderbilt Rating Scale developed by Mark L.
            Wolraich, MD.
            <br /> Revised - 1102
          </div>
        </div>
        <div className={classes.method_container}>Method or Framework</div>
        <div className={classes.certification_container}>
          <img src={americanPediatrics} />
          <img src={nichq} />
          <img src={mcneil} />
        </div>

        <div className={classes.footer_container}>
          <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
          <p>Page 2 of 2</p>
        </div>
      </div>
    </>
  );
};
