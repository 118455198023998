import { call, put, takeLatest } from "redux-saga/effects";
import ApiServices from "../../graphqlApiServices";
import studentReducer from "../reducers/student.reducer";

const { students } = ApiServices

const {
    getStudents,
    getStudentByClass,
    getStudentByID,
    getStudentBySchoolId,
    getStudentBySection,
    createStudent,
    updateStudent,
    deleteStudent,
    addStudentHealthLog,
    UpdateStudentStatus,
    notifyClinicParent
} = students

export function* watchStudentRequests() {
    yield takeLatest(studentReducer.actions.getStudentsRequest, getStudentsSaga);
    yield takeLatest(studentReducer.actions.getStudentByClassRequest, getStudentByClassSaga);
    yield takeLatest(studentReducer.actions.createStudent, createStudentSaga);
    yield takeLatest(studentReducer.actions.getStudentByIDRequest, getStudentByIDSaga);
    yield takeLatest(studentReducer.actions.getStudentBySchoolIDRequest, getStudentBySchoolIDSaga);
    yield takeLatest(studentReducer.actions.getStudentBySectionRequest, getStudentBySectionSaga)
    yield takeLatest(studentReducer.actions.updateStudentRequest, updateStudentSaga)
    yield takeLatest(studentReducer.actions.deleteStudentRequest, deleteStudentSaga)
    yield takeLatest(studentReducer.actions.addStudentHealthLogRequest, addStudentHealthLogSaga)
    yield takeLatest(studentReducer.actions.updateStudentStatusRequest, updateStudentStatusSaga)
}

export function* getStudentsSaga() {
    try {
        const response = yield call(getStudents);
        const data = response.data.getStudents.students
        yield put(studentReducer.actions.getStudentsScuccess({ data }))
    } catch (error) {
        yield put(studentReducer.actions.getStudentsFail({ error: error.errors[0] }))
    }
}

export function* getStudentByClassSaga(payload) {
    try {
        const response = yield call(getStudentByClass, payload);
        const data = response.data.getStudents.students
        yield put(studentReducer.actions.getStudentsScuccess({ data }))
    } catch (error) {
        yield put(studentReducer.actions.getStudentsFail({ error: error.errors[0] }))
    }
}

export function* createStudentSaga(payload) {
    try {
        const response = yield call(createStudent, payload);
        const data = response.data.createStudent
        if (payload.payload.isClinic) {
            yield call(notifyClinicParent, { schoolId: data.schoolId, studentId: data.studentId })
        }
        yield put(studentReducer.actions.createStudentScuccess({ data }))
        setTimeout(() => window.history.back(), 2000)
    } catch (error) {
        yield put(studentReducer.actions.createStudentFail({ error }))
    }
}

export function* getStudentByIDSaga(payload) {
    try {
        const response = yield call(getStudentByID, payload);
        const data = response.data.getStudentByID;


        yield put(studentReducer.actions.getStudentsScuccess([data]))
    } catch (error) {
        yield put(studentReducer.actions.getStudentsFail({ error: error?.errors?.[0] }))
    }
}
export function* getStudentBySchoolIDSaga(payload) {
    try {
        const response = yield call(getStudentBySchoolId, payload);
        const data = response?.data?.getStudentBySchoolID?.items

        yield put(studentReducer.actions.getStudentsScuccess(data))
        if (response?.data?.getStudentBySchoolID?.nextToken) {
            const params = {
                schoolId: payload.payload.schoolId,
                nextToken: response?.data?.getStudentBySchoolID?.nextToken
            }
            yield put(studentReducer.actions.getStudentBySchoolIDRequest(params))
        }
    } catch (error) {
        if (error?.data?.getStudentBySchoolID?.length > 0) {
            yield put(studentReducer.actions.getStudentsScuccess(error?.data?.getStudentBySchoolID?.items))
            if (error?.data?.getStudentBySchoolID?.nextToken) {
                const params = {
                    schoolId: payload.payload.schoolId,
                    nextToken: error?.data?.getStudentBySchoolID?.nextToken
                }
                yield put(studentReducer.actions.getStudentBySchoolIDRequest(params))
            }
        }
        else {
            yield put(studentReducer.actions.getStudentsFail({ error }))
        }
    }
}
export function* getStudentBySectionSaga(payload) {
    try {
        const response = yield call(getStudentBySection, payload);

        const data = response.data.getStudentBySection?.items
        let x = []
        yield put(studentReducer.actions.getStudentsScuccess(data))
        console.log(response?.data?.getStudentBySection?.nextToken)
        if (response?.data?.getStudentBySection?.nextToken) {
            console.log("ABBA JABBA DABBA")
            const params = {
                schoolId: payload.payload.schoolId,
                classVal: payload.payload.classVal,
                section: payload.payload.section,
                nextToken: response?.data?.getStudentBySection?.nextToken
            }
            yield put(studentReducer.actions.getStudentBySectionRequest(params))
        }
    } catch (error) {

        if (error?.data?.getStudentBySection?.items?.length > 0) {
            const data = error.data.getStudentBySection
            yield put(studentReducer.actions.getStudentsScuccess(data))
        }
        else {
            yield put(studentReducer.actions.getStudentsFail({ error }))
        }
    }
}

export function* updateStudentSaga(payload) {
    try {
        const response = yield call(updateStudent, payload);
        const data = response.data
        yield put(studentReducer.actions.updateStudentScuccess({ data }))
        setTimeout(() => window.history.back(), 2000)
    } catch (error) {

        yield put(studentReducer.actions.updateStudentFail({ error }))
    }
}

export function* updateStudentStatusSaga(payload) {

    try {
        const response = yield call(UpdateStudentStatus, payload);
        const data = response.data
        yield put(studentReducer.actions.updateStudentStatusScuccess({ data }))
    } catch (error) {

        yield put(studentReducer.actions.updateStudentStatusFail({ error }))
    }
}


export function* deleteStudentSaga(payload) {
    // 
    try {
        const response = yield call(deleteStudent, payload);

        const data = response.data
        yield put(studentReducer.actions.deleteStudentSuccess({ data }))
    } catch (error) {

        yield put(studentReducer.actions.deleteStudentFail({ error }))
    }
}

export function* addStudentHealthLogSaga(payload) {
    try {
        const response = yield call(addStudentHealthLog, payload);
        const data = response?.data?.addLogsToStudent
        yield put(studentReducer.actions.addStudentHealthLogSuccess(data))
    } catch (error) {
        if (error?.data?.getStudentBySchoolID?.length > 0) {
            yield put(studentReducer.actions.addStudentHealthLogSuccess(error?.data?.addLogsToStudent))
        }
        else {
            yield put(studentReducer.actions.addStudentHealthLogFail(error.errors[0]))
        }
    }
}
