import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import classes from "./BehavioralDoctorDashboard.module.css"
import { Link, useNavigate } from 'react-router-dom'
import { ArrowRightAlt } from '@mui/icons-material'
import developmentMilestonesSvg from "../../assets/images/developmentMilestonesSvg";
import adhdSvg from "../../assets/images/adhdSvg";
import autismSvg from "../../assets/images/autismSvg";
import anxietySvg from "../../assets/images/anxietySvg";
import conductDisorderSvg from "../../assets/images/conductDisorderSvg";
import defiantSvg from "../../assets/images/defiantSvg";
import dependencySvg from "../../assets/images/dependencySvg";
import depressionSvg from "../../assets/images/depressionSvg";
import dyslexiaSvg from '../../assets/images/dyslexiaSvg'
import dyscalculiaSvg from '../../assets/images/dyscalculiaSvg'
import dysgraphiaSvg from '../../assets/images/dysgraphiaSvg'
// import Definition from '../../assets/images/Definition.svg'
import InputDropdown from '../common/InputDashboard/InputDropdown'
import behavioral from '../../graphqlApiServices/behaviroal'
import { Awsdateformatter } from '../../utils/utility'
import DatePickerInput from '../common/InputDashboard/DatePickerInput'
import moment from 'moment/moment'
import Loader from '../common/Loader/Loader'



function BehavioralDoctorDashboard() {

  const [type, setType] = useState("school")
  const navigate = useNavigate()
  const { getBehavioralDashboard, getBhvAssessment, getBhvScreenings } = behavioral;
  const [bhvData, setBhvData] = useState()
  const [bhvStudentData, setBhvStudentData] = useState()
  const [bhvSchoolData, setBhvSchoolData] = useState([])
  const [assessmentStats, setAssessmentStats] = useState(moment.utc(new Date()).format('YYYY-MM-DD'))
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    getBehavioralDashboard(Awsdateformatter(assessmentStats), callBack, type === "school" ? "SCHOOL" : "D2C")
    if (type === "school") {
      let x = []
      getBhvScreenings().then(async (res) => {
        x = res.data.getBhvScreenings.items
        console.log(x)
        if (res.data.getBhvScreenings.nextToken) {
          let nt
          while (true) {
            const newRes = await getBhvScreenings(nt)
            if (newRes.data.getBhvScreenings.nextToken) {
              for (let i = 0; i < newRes.data.getBhvScreenings.items.length; i++) {
                x.push(newRes.data.getBhvScreenings.items[i])
              }
              nt = newRes.data.getBhvScreenings.nextToken
            }
            else {
              setBhvSchoolData(x)
              setLoading(false)
              break;
            }
          }
          // getBhvScreenings(res.data.getBhvScreenings.nextToken).then((res) => {
          //   console.log([...bhvSchoolData, res.data.getBhvScreenings.items])
          //   for (let i = 0; i < res.data.getBhvScreenings.items.length; i++) {
          //     x.push(res.data.getBhvScreenings.items[i])
          //   }
          //   if (res.data.getBhvScreenings.nextToken) {
          //     getBhvScreenings(res.data.getBhvScreenings.nextToken).then((res) => {
          //       console.log([...bhvSchoolData, res.data.getBhvScreenings.items])
          //       for (let i = 0; i < res.data.getBhvScreenings.items.length; i++) {
          //         x.push(res.data.getBhvScreenings.items[i])
          //       }
          //       setBhvSchoolData(x)
          //     })
          //   }
          //   // setBhvSchoolData(x)
          // })
        }
        else {
          setBhvSchoolData(x)
          setLoading(false)
        }
      }).catch((err) => { console.log(err) })
    }
    else {
      getBhvAssessment(bhvAssessment)
      setLoading(false)
    }
  }, [assessmentStats, type])

  function callBack(type, res) {
    if (type == "success") {
      setBhvData(res)
    }
    else {
      // console.log(res);
    }
  }
  function bhvAssessment(type, res) {
    if (type == "success") {
      setBhvStudentData(res)
    }
    else {
      // console.log(res);
    }
  }
  function getAssessments(data, type) {
    // let count = 0;
    let arr = []
    // console.log(data)
    if (type === "DYSGRAPHIA_2") {
      data?.map((el, index) => {
        if (el[type]) {
          let value = JSON.parse(el["DYSGRAPHIA_2"])
          let val = JSON.parse(el["DYSGRAPHIA_1"])
          // let newValue
          // if (type == "DYSGRAPHIA_2" && !value?.score) {
          //   newValue = JSON.parse(el['DYSGRAPHIA_1'])
          //   arr.push(el)
          // }
          if (value?.score) {
            // count++
            arr.push(el)
          }
          else if (val?.score) {
            // count++
            arr.push(el)
          }
        }
      })
    } else if (type === "DYSLEXIA_2") {
      data?.map((el, index) => {
        if (el[type]) {
          let value = JSON.parse(el["DYSLEXIA_2"])
          let val = JSON.parse(el["DYSLEXIA_1"])
          // let newValue
          // if (type == "DYSGRAPHIA_2" && !value?.score) {
          //   newValue = JSON.parse(el['DYSGRAPHIA_1'])
          //   arr.push(el)
          // }
          // console.log(val)
          if (value?.score) {
            // count++
            arr.push(el)
          }
          else if (val?.score) {
            // count++
            arr.push(el)
          }
        }
      })
    } else if (type === "DYSCALCULIA_2") {
      data?.map((el, index) => {
        if (el[type]) {
          let value = JSON.parse(el["DYSCALCULIA_2"])
          let val = JSON.parse(el["DYSCALCULIA_1"])
          // let newValue
          // if (type == "DYSGRAPHIA_2" && !value?.score) {
          //   newValue = JSON.parse(el['DYSGRAPHIA_1'])
          //   arr.push(el)
          // }
          if (value?.score) {
            // count++
            arr.push(el)
          }
          else if (val?.score) {
            // count++
            arr.push(el)
          }
        }
      })
    }
    else {
      data?.map((el, index) => {
        if (el[type]) {
          let value = JSON.parse(el[type])
          // let newValue
          // if (type == "DYSGRAPHIA_2" && !value?.score) {
          //   newValue = JSON.parse(el['DYSGRAPHIA_1'])
          //   arr.push(el)
          // }
          if (value?.score) {
            // count++
            arr.push(el)
          }
        }
      })
    }
    return arr
  }

  useEffect(() => {
    console.log(loading)
  }, [loading])

  function getSchoolAssessments(data, type) {
    // let count = 0;
    let arr = []
    // console.log(data)
    data?.map((el, index) => {
      if (el[type]) {
        let value = JSON.parse(el[type])
        if (value?.score) {
          // count++
          arr.push(el)
        }
      }
    })
    return arr
  }

  // console.log(bhvData?.[type]?.["ADHD"]);
  let arry = [{
    title: "ADHD",
    SvgIcon: adhdSvg,
    risk: bhvData?.data["ADHD"],
    assessments: "",
    keyTitle: "ADHD",
    link: "/behavioral-doctor/dashboard/assessmment",
    color: "#EF1210",
    bg_color: "#FEECEC",
    data: getAssessments(type === "school" ? bhvSchoolData : bhvStudentData, "ADHD")
  }, {
    title: "Autism",
    SvgIcon: autismSvg,
    risk: bhvData?.data["AUTISM"],
    assessments: "",
    keyTitle: "AUTISM",
    link: "/behavioral-doctor/dashboard/assessmment",
    color: "#E09F1F",
    bg_color: "#FAF0DB",
    data: getAssessments(type === "school" ? bhvSchoolData : bhvStudentData, "AUTISM")

  }, {
    title: "Anxiety",
    SvgIcon: anxietySvg,
    risk: bhvData?.data["ANXIETY"],
    assessments: "",
    keyTitle: "ANXIETY",
    color: "#812D7F",
    bg_color: "#F8EAF7",
    link: "/behavioral-doctor/dashboard/assessmment",
    data: getAssessments(type === "school" ? bhvSchoolData : bhvStudentData, "ANXIETY")
  }, {
    title: "Conduct Disorder",
    SvgIcon: conductDisorderSvg,
    risk: bhvData?.data["CONDUCT_DISORDER"],
    assessments: "",
    keyTitle: "CONDUCT_DISORDER",
    color: "#0DB1AD",
    bg_color: "#E7F7F7",
    link: "/behavioral-doctor/dashboard/assessmment",
    data: getAssessments(type === "school" ? bhvSchoolData : bhvStudentData, "CONDUCT_DISORDER")
  }, {
    title: "Oppositional Defiant Disorder",
    SvgIcon: defiantSvg,
    risk: bhvData?.data["ODD"],
    assessments: "",
    keyTitle: "ODD",
    color: "#197BD2",
    bg_color: "#E8F2FB",
    link: "/behavioral-doctor/dashboard/assessmment",
    data: getAssessments(type === "school" ? bhvSchoolData : bhvStudentData, "ODD")
  }, {
    title: "Depression",
    SvgIcon: depressionSvg,
    risk: bhvData?.data["DEPRESSION"],
    assessments: "",
    keyTitle: "DEPRESSION",
    color: "#B64F35",
    bg_color: "#FCE7E2",
    link: "/behavioral-doctor/dashboard/assessmment",
    data: getAssessments(type === "school" ? bhvSchoolData : bhvStudentData, "DEPRESSION")
  }, {
    title: "Screen Dependency Disorder",
    SvgIcon: dependencySvg,
    risk: bhvData?.data["INTERNET_ADDICTION"],
    assessments: "",
    keyTitle: "INTERNET_ADDICTION",
    color: "#7042C9",
    bg_color: "#F1ECFA",
    link: "/behavioral-doctor/dashboard/assessmment",
    data: getAssessments(type === "school" ? bhvSchoolData : bhvStudentData, "INTERNET_ADDICTION")
  }, {
    title: "Developmental Milestones",
    SvgIcon: developmentMilestonesSvg,
    risk: bhvData?.data["DEVELOPMENTAL_DELAY"],
    assessments: "",
    keyTitle: "DEVELOPMENTAL_DELAY",
    color: "#179309",
    bg_color: "#DFECDE",
    link: "/behavioral-doctor/dashboard/assessmment",
    data: getAssessments(type === "school" ? bhvSchoolData : bhvStudentData, "DEVELOPMENTAL_DELAY")
  }, {
    title: "Dyscalculia Stage 1 & 2",
    SvgIcon: dyscalculiaSvg,
    risk: bhvData?.data["DYSCALCULIA_2"] + bhvData?.data["DYSCALCULIA_1"],
    assessments: "",
    keyTitle: "DYSCALCULIA_2",
    color: "#0DB1AD",
    bg_color: "#E7F7F7",
    link: "/behavioral-doctor/dashboard/assessmment",
    data: getAssessments(type === "school" ? bhvSchoolData : bhvStudentData, "DYSCALCULIA_2")
  },
  {
    title: "Dysgraphia Stage 1 & 2",
    SvgIcon: dysgraphiaSvg,
    risk: bhvData?.data["DYSGRAPHIA_1"] + bhvData?.data["DYSGRAPHIA_2"],
    assessments: "",
    keyTitle: "DYSGRAPHIA_2",
    color: "#E09F1F",
    bg_color: "#FAF0DB",
    link: "/behavioral-doctor/dashboard/assessmment",
    data: getAssessments(type === "school" ? bhvSchoolData : bhvStudentData, "DYSGRAPHIA_2")
  },
  {
    title: "Dyslexia Stage 1 & 2",
    SvgIcon: dyslexiaSvg,
    risk: bhvData?.data["DYSLEXIA_1"] + bhvData?.data["DYSLEXIA_2"],
    assessments: "",
    keyTitle: "DYSLEXIA_2",
    color: "#EF1210",
    bg_color: "#FEECEC",
    link: "/behavioral-doctor/dashboard/assessmment",
    data: getAssessments(type === "school" ? bhvSchoolData : bhvStudentData, "DYSLEXIA_2")
  },
  ]

  return (
    <>
      <div>
        <div>
          <Typography>Appointments</Typography>
          <div className={classes.appointmentTile}>
            <Typography>Today</Typography>
            <Typography>{bhvData?.today}</Typography>
            <span><Link to={'/behavioral-doctor/dashboard/patients'} style={{ color: "#CA6B6E" }}>View list</Link> of patients</span>
          </div>
        </div>
        <div className={classes.assessmentStats}>
          <h1>Assessment Stats</h1>
          <DatePickerInput
            onChange={(e) => {
              setAssessmentStats(e.target.value);
            }}
            disabled={loading}
            margin={true}
            futureDate={true}
            // width={"23vw"}
            placeholder={"Date"}
            value={assessmentStats}
          />
          {loading && <CircularProgress size={30} />}
        </div>
        <div>
          <div className={classes.assign_layout_header}>
            <div className={classes.assign_layout_header_inner_container_first}>
              <div
                className={`${type === 'school' ? `${classes.assign_layout_header_physical} ${classes.assign_layout_header_bgColor_active_physical}` :
                  `${classes.assign_layout_header_physical}`}`}
                onClick={() => setType('school')}>School</div>

              <div className={`${type === 'D2C' ? `${classes.assign_layout_header_dental} ${classes.assign_layout_header_bgColor_active_dental}` :
                `${classes.assign_layout_header_dental}`}`}
                onClick={() => setType('D2C')}>Website (D2C)</div>

              {/* <div className={`${type === 'Clinics' ? `${classes.assign_layout_header_behavioral} ${classes.assign_layout_header_bgColor_active_behavioral}` :
              `${classes.assign_layout_header_behavioral}`}`}
              onClick={() => setType('Clinics')} style={{ userSelect: "none", pointerEvents: "none" }}>Clinics</div> */}
            </div>
            <div className={classes.assign_layout_header_inner_container_second}></div>
          </div>

          <div className={classes.assign_layout_component_container}>
            {/* <div className={classes.assign_layout_component_header}>{type.toUpperCase()} SCREENING</div>
          <div className={classes.assign_layout_component_subheader}>
            <div>
              <img src={type === 'Behavioral' ? screeningsyellow : screeningsred} />
            </div>
            <div className={classes.screening_text}>
              <p className={`${type === 'Behavioral' ? `${classes.yellow_screening_number}` :
              `${classes.physical_screening_number}`}`}>{unvalidated}</p>
              <p className={classes.physical_screening_text}>screenings done today</p>
            </div>
          </div> */}
            <div className={classes.screening_cards_container}>
              {arry.map(item => (
                <div className={classes.cards_container} onClick={() => navigate(item.link, { state: { data: item?.data, title: item?.title, type: type, keyTitle: item?.keyTitle, date: assessmentStats } })} style={{ backgroundColor: item.bg_color }}>
                  <div>
                    <item.SvgIcon customStyle={{ width: "40px", height: "40px" }} head={item.title} />
                    <div>{item.assessments}</div>
                  </div>
                  <h1 style={{ color: item.color }}>{item.title}</h1>
                  <div>
                    <div> <span>{item.risk}</span>  are high risk</div>
                    <ArrowRightAlt />
                  </div>
                </div>
              ))}


              {/* {data?.map((doc,i)=>{
          
          return (
            <DoctorCard data={doc} countData={getCountData(doc)} key={i} handleAssign={assignDoctor} />
          )
         }) } */}

              {/* {!data.length && <p>{`No ${screeningType} Doctors Found`}</p>} */}

            </div>
          </div>
        </div>

      </div >
    </>
  )
}

export default BehavioralDoctorDashboard