import { CircularProgress } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Page1 } from '../HealthReport/Page1'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import screeningReducer from '../../redux/reducers/screening.reducer';
import { AbdomenReportSection, BehavioralReportSection, DentalReportSection, DoctorSection, EndOfReport, EyeReportSection, HairReportSection, HeartReportSection, LearningReportSection, LeftEarReportSection, LungsReportSection, MouthThroatReportSection, OtherVitalsReportSection, RightEarReportSection, SkinReportSection, VitalsReportSection } from '../HealthReport/DynamicContent';
import jsPDF from 'jspdf';
import skidssuperman from "../../assets/images/skids-logo-1.png";
import html2canvas from 'html2canvas';
import QRCode from 'react-qr-code';
import JSZip, { generateAsync } from 'jszip';
import { Buffer } from "buffer";
import html2PDF from 'jspdf-html2canvas';
import { textFormat } from '../../utils/utility';
import screening from '../../graphqlApiServices/screening';
import Background from '../../assets/images/Background.svg';

const appLink = process.env.REACT_APP_MOBILE_APP_LINK;

export const DynamicPages = ({ data, pageContent, currentPage, pages, dynamicClass }) => {

    return (
      <>
        <div id="healthReport2" className={`main ${dynamicClass}`}>
          <div class="parent_container">
            <div class="parent_container_inner">
              <div>
                <img src={skidssuperman} alt="skidssuperman"/>
                {/* <p>Skids Logo</p> */}
              </div>
              <div>
                <p>{data?.school?.schoolName}</p>
                <img src={data?.school?.url} alt="skidssuperman" style={{borderRadius:50, width:40, height:40}}/>
              </div>
            </div>
            {/* {
              currentPage !== pages &&
              <div class="screening_test_header_container">
                <p>Physical Screening</p>
                <p>Results</p>
                <p>Bio. Ref. Interval</p>
                <p>Units</p>
                <p>Interpretation</p>
              </div>} */}
          </div>
          {
            pageContent ?? <></>
          }
          {/* <div class="footer_container"> */}
          <div className="qrcode_container">
            <div>
            {/* <QRCode value={data?.qrCode} size={40}/> */}
            <QRCode value={appLink} size={40}/>
            </div>
            <div>
              <div>Scan here</div>
              <div>
                to look at your kids Physical & Behavioral detailed reports
              </div>
            </div>
          </div>
          <div class="footer_container">
            <p>www.skids.health | support@skids.health</p>
            <p style={{fontSize:8}}>Page {currentPage} of {pages}</p>
          </div>
          {/* </div> */}
        </div>
      </>
    );
  };

function Report({screeningId, data}) {
    let tempPageContent = [];
    let pageViews = [];
    let pdfPageContent = [];
    let currentPage = 1
    const [newView, setNewView] = useState([]);
  
    const reportTemplateRef = useRef(null);
  
    const dispatch = useDispatch()
    const location = useLocation()  
  
    useEffect(() => {
      
      if (data) {
        console.log(data)
        const vitals = data?.student?.healthPackage?.vitalSigns
        const vitalsshow = vitals?.length===0
        const otherVitalsShow = vitals?.includes("TEMPERATURE") || vitals?.includes("HEART_RATE") || vitals?.includes("HEMOGLOBIN") || vitals?.includes("BLOOD_PRESSURE") || vitals?.includes("SPO2")
        const hair = data?.student?.healthPackage?.hair
        const hairshow = hair?.length===0
        const eye = data?.student?.healthPackage?.eye
        const eyeshow = eye.validation?.length=== 0
        const lear = data?.student?.healthPackage?.ear
        const learshow = lear?.length===0
        const rear = data?.student?.healthPackage?.ear
        const rearshow = rear?.length===0
        const throat = data?.student?.healthPackage?.throat
        const throatshow = throat?.length===0
        const heart = data?.student?.healthPackage?.heart
        const heartshow = heart?.length===0
        const dental = data?.student?.healthPackage?.dental
        const dentalshow = dental?.length===0
        const lungs = data?.student?.healthPackage?.lungs
        const lungsshow = lungs?.length===0
        const abdomen = data?.student?.healthPackage?.abdomen
        const abdomenshow = abdomen?.length===0
        const skin = data?.student?.healthPackage?.skin
        const skinshow = skin?.length===0
        const behaveshow = data?.ADHD?.score || data?.ANXIETY?.score || data?.AUTISM?.score || data?.CONDUCT_DISORDER?.score || data?.DEPRESSION?.score || data?.DEVELOPMENTAL_DELAY?.score || data?.INTERNET_ADDICTION?.score || data?.ODD?.score;
        const learning = data?.DYSLEXIA_1?.outcome || data?.DYSCALCULIA_1?.outcome || data?.DYSGRAPHIA_1?.outcome
  
  
        // if (!vitalsshow) {
        //   pageViews.push({section: <VitalsReportSection data={data} />, title: "BMI"})
        // }
        // if(otherVitalsShow) {
        //   pageViews.push({section: <OtherVitalsReportSection data={data} />, title: "Vitals"})
        // }
        // if (!hairshow) {
        //   pageViews.push({section: <HairReportSection data={data} />, title: "Hair"})
        // }
        // if (!learshow || !rearshow) {
        //   pageViews.push({section: <LeftEarReportSection data={data} />, title: "Ear"})
        // }
        // // if (!rearshow) {
        // //   pageViews.push(<RightEarReportSection data={data} />)
        // // }
        // if (!eyeshow) {
        //   pageViews.push({section: <EyeReportSection data={data} />, title: "Eye"})
        // }
        // if (!throatshow) {
        //   pageViews.push({section: <MouthThroatReportSection data={data} />, title: "Throat"})
        // }
        // if (!dentalshow) {
        //   pageViews.push({section: <DentalReportSection data={data} />, title: "Dental"})
        // }
        // if (!heartshow) {
        //   pageViews.push({section: <HeartReportSection data={data} />, title: "Heart"})
        // }
        // if (!lungsshow) {
        //   pageViews.push({section: <LungsReportSection data={data} />, title: "Lungs"})
        // }
        // if (!abdomenshow) {
        //   pageViews.push({section: <AbdomenReportSection data={data} />, title: "Abdomen"})
        // }
        // if (!skinshow) {
        //   pageViews.push({section: <SkinReportSection data={data} />, title: "Skin"})
        // }
        // if (behaveshow) {
        //   pageViews.push({section: <BehavioralReportSection data={data} />, title: "Behavioral"})
        // }
        // if (learning) {
        //   pageViews.push({section: <LearningReportSection data={data} />, title: "Learning"})
        // }
        // pageViews.push({section: <DoctorSection data={data} />, title: "Doctor"});
        // pageViews.push({section: <EndOfReport data={data} />, title: "End"});
        
  
  
        // setNewView(pageViews?.forEach((view, index) => {
        //   tempPageContent.push(view.section);
        //   if (((index + 1) % 2) === 0 || (index === (pageViews.length - 1))) {
        //     currentPage += 1;
        //     pdfPageContent.push(
        //       <DynamicPages
        //         data={data}
        //         pages={Math.ceil(pageViews.length / 2) + 1}
        //         pageContent={tempPageContent}
        //         currentPage={currentPage} 
        //         dynamicClass={`main-${data?.student?.screeningId}`}
        //       />)
        //     tempPageContent = [];
        //   }
        // }))
        // setNewView(pdfPageContent);

         // Array to store sections with conditionally included components
        const pageSections = [];

        // BMI and Vitals (if either has content)
        if (!vitalsshow || otherVitalsShow) {
          const bmiVitalsPage = [];
          if (!vitalsshow) {
            bmiVitalsPage.push(<VitalsReportSection data={data} />);
          }
          if (otherVitalsShow) {
            bmiVitalsPage.push(<OtherVitalsReportSection data={data} />);
          }
          pageSections.push(bmiVitalsPage);
        }

        // Hair and Ear (if either has content)
        if (!hairshow || !learshow || !rearshow) {
          const hairEarPage = [];
          if (!hairshow) {
            hairEarPage.push(<HairReportSection data={data} />);
          }
          if (!learshow || !rearshow) {
            hairEarPage.push(<LeftEarReportSection data={data} />);
          }
          pageSections.push(hairEarPage);
        }

        // Eye (if it has content)
        if (!eyeshow) {
          pageSections.push([<EyeReportSection data={data} />]);
        }

        // MouthThroat and Dental (if either has content)
        if (!throatshow || !dentalshow) {
          const mouthThroatDentalPage = [];
          if (!throatshow) {
            mouthThroatDentalPage.push(<MouthThroatReportSection data={data} />);
          }
          if (!dentalshow) {
            mouthThroatDentalPage.push(<DentalReportSection data={data} />);
          }
          pageSections.push(mouthThroatDentalPage);
        }

        // Heart and Lungs (if either has content)
        if (!heartshow || !lungsshow) {
          const heartLungsPage = [];
          if (!heartshow) {
            heartLungsPage.push(<HeartReportSection data={data} />);
          }
          if (!lungsshow) {
            heartLungsPage.push(<LungsReportSection data={data} />);
          }
          pageSections.push(heartLungsPage);
        }

        // Abdomen and Skin (if either has content)
        if (!abdomenshow || !skinshow) {
          const abdomenSkinPage = [];
          if (!abdomenshow) {
            abdomenSkinPage.push(<AbdomenReportSection data={data} />);
          }
          if (!skinshow) {
            abdomenSkinPage.push(<SkinReportSection data={data} />);
          }
          pageSections.push(abdomenSkinPage);
        }

        // Doctor and End Section (always included)
        pageSections.push([
          <DoctorSection data={data} />,
          <EndOfReport data={data} />
        ]);

        // Add each page grouping to pdfPageContent
        pageSections.forEach((sections, index) => {
          pdfPageContent.push(
            <DynamicPages
              data={data}
              pages={pageSections.length + 1}
              pageContent={sections}
              currentPage={index + 2}
              dynamicClass={`main-${data?.student?.screeningId}`}
            />
          );
        });

        setNewView(pdfPageContent);
      }
    }, [data])
  
  
    return (
        <>
      {/* <div style={{ maxHeight: "100vh", overflow:"hidden" }}> */}
        {/* <div style={{display: "flex", justifyContent:"center", height:"100vh", alignItems:"center", flexDirection:"column", width:"100vw", zIndex:999, position:"absolute", backgroundColor:"white"}}>
          <CircularProgress size={50}/>
          <div style={{marginTop:40}}>Please Wait, your report will be dowloaded shortly</div>
          <div style={{marginTop:20}}>{coun}</div>
        </div> */}
        <div id='healthReport' ref={reportTemplateRef}>
          <Page1 data={data} dynamicClass={`main-${data?.student?.screeningId}`}/>
          {
            newView?.map((page) => {
              return <>
                <hr />
                {page}
              </>
            })
          }
        </div>
      {/* </div> */}
      </>
    )
}


function ReportDownloadPage({screeningIds, data, setDownloadReport}) {

  const dispatch = useDispatch()
  const location = useLocation()

  // const uploadReport = screeningReducer.actions.uploadReportRequest;

  const {uploadReport} = screening 

  const [count, setCount] = useState(0)

  const zip = new JSZip();

  // const toBase64 = (x) => new Promise((resolve, reject) => {
  //   if (x) {
  //     const readers = new FileReader();
  //     readers.readAsArrayBuffer(x);
  //     readers.onload = () => {
  //       resolve(Buffer.from(readers.result).toJSON())

  //     };
  //     readers.onerror = error => reject(error);
  //   }

  // });

  let k = 0

  const getPdf = async(x, sId) => {

    console.log(sId)
    return html2PDF(x, {
        jsPDF: {format:"a4", orientation:"p", unit:"mm"},
        imageType: 'image/jpeg',
        html2canvas: {proxy: data[0]?.school?.url, backgroundColor: "white", logging: true,
          allowTaint: true,
          scale: 2,
          // foreignObjectRendering: true,
          useCORS: true,},
        success: function async(res, u = x){
          console.log(u)
                for (let i = 50; i > 0; i--) {
                  if(i%2===0){
                    res?.deletePage(i)
                  }   
                }
                const x = res.output("arraybuffer")
                uploadReport(
                  {
                    payload: {
                      logo: x, 
                      screeningId: sId, 
                      schoolId:location.state.schoolData?.schoolId
                  }})
        }
    })
    // console.log(pdf , "xya")
    // return resolve(pdf)
}

    useEffect(()=>{
      console.log(screeningIds.length, data?.length)
        if(screeningIds.length === data?.length){  
            setTimeout(() => {
              (async () => {
                for (let sId of screeningIds) {
                  let elements
                    elements = document.querySelectorAll(`.main-${sId}`)
                    await getPdf(elements, sId)
                    setCount((prev)=>prev+1)
                }
                setDownloadReport(false)
              })()
            }, 5000);

        }
    },[data])
  return (
    <div style={{maxHeight:"100vh", overflow:"hidden"}}>
      <div style={{display: "flex", justifyContent:"center", height:"100vh", alignItems:"center", flexDirection:"column", width:"70%", zIndex:999, position:"absolute", backgroundColor:"#FAF8F7"}}>
        <CircularProgress size={50}/>
        <div style={{marginTop:40}}>Please Wait, your reports are being uploaded</div>
        <div style={{marginTop:20}}>{`${count}/${screeningIds.length}`}</div>
      </div>
        {
            screeningIds.length === data?.length && data?.map((x,i)=>{
               return <Report screeningId={screeningIds[i]} data={data[i]}/>
            })
        }
    </div>
  )
}

export default ReportDownloadPage
