import * as React from "react"
const SvgComponent = (props) => (
    <div style={{ ...props.customStyle }}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke="#02B1AC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                clipPath="url(#a)"
            >
                <path d="M20 29.137c4.226 0 7.651-3.61 7.651-8.065 0-4.453-3.425-8.064-7.65-8.064-4.226 0-7.651 3.61-7.651 8.064 0 4.454 3.425 8.065 7.65 8.065ZM29.592 12.364l2.27-4.088 1.514 2.891 2.459-4.487M25.023 8.222l2.27-4.088 1.514 2.892 2.46-4.487M10.411 12.364l-2.27-4.088-1.514 2.891L4.168 6.68M14.735 8.222l-2.27-4.088-1.514 2.892L8.492 2.54" />
                <path d="M20.002 37.45h12.711c-1.183-7.104-6.113-9.042-6.113-9.042s-1.312-.705-1.785-.705M20 37.45H7.286c1.183-7.104 6.114-9.042 6.114-9.042s1.311-.705 1.784-.705" />
                <path d="M13.404 28.406s-4.931 1.938-6.113 9.042h12.711" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M3.334 1.664h33.333v36.667H3.334z" />
                </clipPath>
            </defs>
        </svg>
    </div>
)
export default SvgComponent
