import { createSlice } from "@reduxjs/toolkit";
import InitialStore from "../store/InitialStore";
import { Auth } from "aws-amplify";
import Cookies from "js-cookie";

export const getAnalyticsRequest = (state,) => {
    state.loading = true;
    state.error = false;
};

export const getAnalyticsSuccess = (state,action) => {
    state.data = action.payload.data;
    state.loading = false;
    state.error = false;
}

export const getAnalyticsFail = (state,) => {
    state.loading = false;
    state.error = true;
}

export default createSlice({
    name: "analyticsReducer",
    initialState: InitialStore.analytics,
    reducers: {
        getAnalyticsRequest,
        getAnalyticsSuccess,
        getAnalyticsFail
    },
});