import React, { useEffect, useState } from "react";
import { json, useLocation, useNavigate } from "react-router-dom";
import classes from "./OpsStudent.module.css";
import OpsStudentRow from "./OpsStudentRow";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../../common/TableComponents/BreadCrumbs";
// import ShowPagesFilter from "../TableComponents/ShowPagesFilter";
import schoolReducer from "../../../../redux/reducers/school.reducer";
import TabButton from "../../../common/TableComponents/TabButton";
import SearchBar from '../../../common/TableComponents/SearchBar'
import { City } from "country-state-city";
import { Button, FormControl, InputLabel, MenuItem, Select, Skeleton } from "@mui/material";
import CustomAutoComplete from "../../../common/CustomAutoComplete/CustomAutoComplete";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import screeningReducer from "../../../../redux/reducers/screening.reducer";
import InputDropdown from "../../../common/InputDashboard/InputDropdown";
import moment from "moment";
import { compareDate } from "../../../../utils/utility";
import eventReducer from "../../../../redux/reducers/event.reducer";
import OpsOperatorModal from "./OpsOperatorModal/OpsOperatorModal";


function OpsStudentPage() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  // 
  const breadCrumbs = [
    "Events"
  ]

  const [openMod, setOpenMod] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageNo, setPageNo] = useState(1)
  const [selectedClass, setSelectedClass] = useState("")
  const [toDate, setToDate] = useState("")
  const [fromDate, setFromDate] = useState("")


  const getStudents = screeningReducer.actions.getOpsManagerStudentScreeningRequest;
  const getWelchallynCsv = screeningReducer.actions.getWelchallynReq;
  const getClassList = eventReducer.actions.getClassesRequest;

  var getDaysArray = function (start, end) {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push({ name: moment(dt).format("DD-MM-YYYY"), value: moment(dt).format("YYYY-MM-DD") })
    }
    return arr;
  };

  var daylist = getDaysArray(new Date(location.state.schoolData.screeningFrom), new Date(location.state.schoolData.screeningTo));

  useEffect(() => {
    const params = {
      fromDate: location.state.schoolData.isClinic ? moment().subtract(1,"day").format("YYYY-MM-DD") : location.state.schoolData.screeningFrom,
      toDate: location.state.schoolData.isClinic ? moment().format("YYYY-MM-DD") : location.state.schoolData.screeningTo,
      schoolId: location.state.schoolData.schoolId
    }
    dispatch(getStudents(params))
  }, [])


  useEffect(() => {

    if (fromDate && toDate && selectedClass) {

      if (compareDate(fromDate, toDate)) {
        const params = {
          fromDate,
          toDate,
          class: selectedClass.split("_")[0],
          section: selectedClass.split("_")[1],
          schoolId: location.state.schoolData.schoolId
        }
        dispatch(getStudents(params))
      }
    }
  }, [fromDate, toDate, selectedClass])

  const data = useSelector((state) => state.screeningReducer.opsManagerStudents)
  const IntegrationLinks = useSelector((state) => state.screeningReducer.welchallynLink)
  const classData = JSON.parse(location.state?.schoolData?.classMap)

  let classList = Object.keys(classData).map((cls) => {
    if (Array.isArray(classData[cls])) {
      return { name: cls.split("_").join("-"), value: cls }
    }
  })



  classList = classList?.filter((cls) => {
    return Array.isArray(classData[cls?.value])
  })
  classList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }));

  const [displayData, setDisplayData] = useState(data ?? []);

  const allCities = [...new Set(City.getCitiesOfCountry("IN").map((x) => x.name))]

  useEffect(() => {

    setDisplayData(data)
  }, [data])

  useEffect(() => {
    if (selectedClass) {
      let params = {
        schoolId: location?.state?.schoolData?.schoolId,
        class: selectedClass.split('_')?.[0],
        section: selectedClass.split('_')?.[1],
      }

      dispatch(getWelchallynCsv(params));
    }
  }, [selectedClass])


  // const handleWelchIntegration = () => {
  //   navigate("/"+welchallyn);
  // }


  return (
    <div className={classes.main_div}>
      <OpsOperatorModal
        open={openMod}
        handleClose={() => { setOpenMod(false) }}
        data={{
          schoolId: location?.state?.schoolData?.schoolId,
          class: selectedClass.split('_')?.[0],
          section: selectedClass.split('_')?.[1],
        }}
      />
      <div className={classes.student_layout}>
        <div>
          <BreadCrumbs />
        </div>
        <div>
          <button
            disabled={(!selectedClass || !fromDate || !toDate)}
            onClick={() => { setOpenMod(true) }}
            className={
              (selectedClass && fromDate && toDate)
                ? classes.opsBtn
                : classes.OpsBtnInactive
            } >HIGO Data Integration</button>
          <a href={IntegrationLinks?.csvFileScreening}>
            <button
              disabled={(!selectedClass || !fromDate || !toDate)}
              className={
                (selectedClass && fromDate && toDate)
                  ? classes.opsBtn
                  : classes.OpsBtnInactive
              }  >Welch Allyn CSV</button></a>
          <a href={IntegrationLinks?.csvFilePrinter}>
            <button
              disabled={(!selectedClass || !fromDate || !toDate)}
              className={
                (selectedClass && fromDate && toDate)
                  ? classes.opsBtn
                  : classes.OpsBtnInactive
              }  >Print/Download QR</button>
          </a>

          {/* <button className = {classes.scan_student_btn}>
        <div>
        <QrCodeScannerIcon sx = {{fill : "#1740A1", height : "13.33px", width : "13.33px"}}/>
        </div>
        <div>Generate QR</div>
        </button> */}

        </div>
      </div>
      <div className={classes.student_filters}>

        <FormControl>
          <InputLabel id="demo-simple-select-label" size='small'>From</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={fromDate}
            label="Rows"
            onChange={(e) => setFromDate(e.target.value)}
            size='small'
            sx={{ width: "150px" }}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 300
                }
              }
            }}
          >
            {daylist?.map((day) => {
              return <MenuItem value={day.value}>{day.name}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="demo-simple-select-label" size='small'>To</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={toDate}
            label="Rows"
            onChange={(e) => setToDate(e.target.value)}
            size='small'
            sx={{ width: "150px" }}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 300
                }
              }
            }}
          >
            {daylist?.map((day) => {
              return <MenuItem value={day.value}>{day.name}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="demo-simple-select-label" size='small'>Class</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedClass}
            label="Rows"
            onChange={(e) => setSelectedClass(e.target.value)}
            size='small'
            sx={{ width: "150px" }}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 300
                }
              }
            }}
          >
            {
              classList.map((cls) => {
                return <MenuItem value={cls.value}>{cls.name}</MenuItem>
              })
            }
          </Select>
        </FormControl>
        <div style={{ display: "flex", alignItems: "center", fontSize: 12, }}>Show</div>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Rows</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={rowsPerPage}
            label="Rows"
            onChange={(e) => setRowsPerPage(e.target.value)}
            size='small'
            sx={{ width: "72px" }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </div>
      {/* Student Table Header */}
      <div className={classes.student_table_header}>
        <div>STUDENT NAME</div>
        <div>DOB</div>
        <div>GENDER</div>
        <div>CLASS</div>
        <div>UUID</div>
        <div>QR CODE</div>
        <div>STATUS</div>
        <div>ACTION</div>
      </div>
      {/* Student Column-Rows Section */}
      <div id="schoolRowCy" className={classes.student_column_container}>
        {data?.length ? displayData?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo).map((el, index) => {
          return (
            <OpsStudentRow data={el} key={index + Date.now()} index={index} cls={selectedClass} />
          )
        }) :
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Skeleton variant="circular" width={40} height={40} sx={{ marginLeft: 2 }} animation={false} />
              <Skeleton variant="text" width={"100%"} sx={{ marginLeft: 5 }} animation={false} />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Skeleton variant="circular" width={40} height={40} sx={{ marginLeft: 2 }} animation={false} />
              <Skeleton variant="text" width={"100%"} sx={{ marginLeft: 5 }} animation={false} />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Skeleton variant="circular" width={40} height={40} sx={{ marginLeft: 2 }} animation={false} />
              <Skeleton variant="text" width={"100%"} sx={{ marginLeft: 5 }} animation={false} />
            </div>
          </>
        }
      </div>
      <div className={classes.pagination_container}>
        <div className={classes.page_item}>{`Showing ${(rowsPerPage * (pageNo - 1)) + 1} to ${pageNo * rowsPerPage <= displayData?.length ? pageNo * rowsPerPage : displayData?.length} of ${displayData?.length} items`}</div>
        <div className={classes.pagination}>
          <Button size="small" disabled={pageNo === 1} onClick={() => setPageNo(pageNo - 1)} >
            <ChevronLeft />
          </Button>
          <span>{pageNo}</span>
          <div>
            <span>of</span>
            <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
          </div>

          <div>
            <Button size="small" disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)} onClick={() => setPageNo(pageNo + 1)} >
              <ChevronRight />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function OpsManagerStudent() {

  const dispatch = useDispatch();

  return (
    <OpsStudentPage />
  );
}
export default OpsManagerStudent;