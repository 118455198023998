import { Avatar } from "@mui/material";
import React, { useState } from "react";
import classes from "./SchoolRow.module.css";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { useNavigate, useLocation, Link } from "react-router-dom";
import RowPopup from "../TableComponents/RowPopup";
import ScheduleModal from './ScheduleModal'
import moment from "moment";
import { capitalizeFirstLetter, truncateString } from "../../../utils/utility";

export default function TeacherRows({ data, index }) {
  // const { id, schoolName, pincode, schoolCode, board, organization, eventDate, status, action, url, strength } = data;

  const [user, setUser] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const getWordAfterDash = () => {
    let word = location.pathname.split('-')
    return word[1];
  }

  function getWordBeforeDash() {
    let newStr = "";
    for (let i = 1; i < location.pathname.length; i++) {
      if (location.pathname[i] === "-") {
        return newStr;
      }
      newStr += location.pathname[i];
    }
  }

  const getSchoolInfo = (whereTo) => {
    if (getWordBeforeDash() === "superadmin") {
      location.state.breadCrumbs.push(data?.schoolName);

      navigate(`/${getWordBeforeDash()}-${getWordAfterDash()}/${whereTo}/${data?.schoolName}`, { state: { ...location.state, ...data } });
    } else if(getWordBeforeDash() === "partner") {
      location.state.breadCrumbs.push(data?.schoolName);
      navigate(`/${getWordBeforeDash()}/${getWordBeforeDash()}-${getWordAfterDash()}/${whereTo}/${data?.schoolName}`, { state: { ...location.state, ...data } });
    }
  }



  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }


  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 12,
        height: 12,
        padding: 2
      },
      children: `${name[0]}`,
    };
  }


  const handleHover = (e) => {
    
  }

  const handleReport = () => {
    setUser(true)
  }
  return (
    <>
      <div className={classes.studentRow_container}>

        {/* Name and Avatar Container */}
        <div>
          {/* Avatar Container */}
          {/* {url ? (
            <div>
              <img src={url} alt="schoolLogo" width="32" height="32" />
            </div>
          ) : ( */}
          <div>
            {!data?.url ? <Avatar {...stringAvatar(`${data?.schoolName} `)} /> : <img src={data?.url} style={{ borderRadius: 50 }} alt="schoolLogo" width="32" height="32" />}
          </div>
          {/* )} */}

          {/* Name Container */}
          <div title={data?.schoolName}>
            <p>{data?.schoolName ? capitalizeFirstLetter(truncateString(data?.schoolName, 30)) : "Unavailable"}</p>
          </div>
        </div>

        {/* Gender Container */}
        <div>
          <p>{data?.pincode ? data?.pincode : "Unavailable"}</p>
        </div>

        {/* Class Container */}
        <div>
          <p>{data?.schoolCode ? capitalizeFirstLetter(data?.schoolCode) : "Unavailable"}</p>
        </div>

        {/* Email Container */}

        <Link to={getWordBeforeDash() === "partner" ? '/partner-school/table/student' : '/superadmin-school/table/student'} state={{ ...data, breadCrumbs: [...location.state.breadCrumbs, "Students"] }}>
          <p>{data?.strength ? data?.strength : "Unavailable"}</p>
        </Link>
        {/* Screening Container */}
        <div>
          <p>{data?.isActive ? (data?.screeningTo ? moment(data?.screeningFrom).format("DD-MM-YYYY") : <ScheduleModal />) : ("-")}</p>
        </div>



        {/*  Validated Container */}
        <div>
          <p>{data?.isActive ? (data?.screeningFrom ? moment(data?.screeningTo).format("DD-MM-YYYY") : "") : "-"}</p>
        </div>


        {/* Conditional Buttons Rendering --Role Section */}
        {/* {index < 3 ? (
          <div>
            {user ? (<button className={classes.screened_btn}>INACTIVE</button>) :
              (<button onClick={handleReport} className={classes.report_btn}>SAVED</button>)
            }
          </div>
        ) : ( */}
        <div >
          {data?.isActive === null || data?.isActive === false ? (<div className={classes.screened_btn}>INACTIVE</div>) :
            (<div className={classes.report_btn_active}>ACTIVE</div>)
          }
        </div>

        {/* )} */}



        {/* Action Container */}
        <div title="school info" style={{ cursor: 'pointer', position: "relative", justifySelf: "center" }}>
          <div className={classes.infoHover}  >
            <p>...</p>
            <div className={classes.popOnHover}>
              <RowPopup navLink={getSchoolInfo} data={data} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}