import { Alert, Box, Button, InputLabel, Modal, Snackbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import cssClasses from './ShareTypeformModal.module.css'
import InputDropdown from '../InputDashboard/InputDropdown';
import eventReducer from '../../../redux/reducers/event.reducer';
import { useDispatch, useSelector } from 'react-redux';
import InputDashboard from '../InputDashboard/InputDashboard';
import typeformReducer from '../../../redux/reducers/typeform.reducer';
import { validateEmail } from '../../../utils/utility';
import LoadingBar from 'react-top-loading-bar';
import schoolReducer from '../../../redux/reducers/school.reducer';
import CancelIcon from '@mui/icons-material/Cancel';
import { FormControl, MenuItem, Select } from '@mui/base';

function ShareTypeformModal({ open, handleOpen, handleClose, schoolId }) {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "fit-content",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  };

  const dispatch = useDispatch()

  const [recipients, setRecipients] = useState('');
  const [selectedClass, setSelectedClass] = useState('')
  const [classes, setClasses] = useState([])
  const [sections, setSections] = useState([])
  const [selectedSection, setSelectedSection] = useState('')
  const [email, setEmail] = useState('')
  const [progress, setProgress] = useState(0)
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState('')
  const [selectedScreeningId, setSelectedScreeningId] = useState('')
  const [finalStudent, setFinalStudent] = useState({})
  
  
  const school = useSelector((state) => state.schoolReducer.data)
  const [studenList, setStudentList] = useState([])
  const students = useSelector((state) => state.studentReducer.data)
  const loading = useSelector((state) => state.typeformReducer.loading)
  const error = useSelector((state) => state.typeformReducer.error)

  useEffect(() => {
    dispatch(schoolReducer.actions.getSchoolbyIdRequest({ schoolId: schoolId }))
  }, [open])


  useEffect(() => {
    if (school.classMap) {
      let x = JSON.parse(school.classMap)
      let cls = []
      let sec = []
      x = Object.keys(x)
      for (let i = 0; i < x.length; i++) {
        let z = x[i]?.split("_")
        cls.push(z[0])
        sec.push(z?.join("-"))
      }
      setClasses(cls)
      setSections(sec)
    }
  }, [school])

  // useEffect(()=>{
  //     const x = Object.keys(allClasses)?.filter((y)=> Array.isArray(allClasses[y]))
  //     const z = []
  //     const y = []
  //     for (let i = 0; i < x.length; i++) {
  //         const element = x[i].split("_");
  //         z.push(element[1])
  //         y.push(x[i].split("_").reverse().join("-"))
  //     }
  //     setClasses(z)
  //     setSections(y)
  // }, [allClasses])

  const handleSubmit = () => {
    setProgress(40)
    let formData = {}
    formData['schoolId'] = schoolId
    if (recipients === "Class") {
      formData['class'] = selectedClass
    }
    else if (recipients === "Section") {
      formData['section'] = selectedSection.split("-")[1]
      formData['class'] = selectedSection.split("-")[0]
    }
    else if (recipients === "Individual") {
      const parentName = students?.filter((x) => x?.fatherContact?.email === email)

      formData['email'] = finalStudent.fatherContact?.email
      formData['name'] = finalStudent.fatherName
      formData['screeningId'] = finalStudent.screenings[finalStudent.screenings?.length-1]
    }
    console.log(formData)
    try {
      dispatch(typeformReducer.actions.shareParentsTypeformRequest(formData))
    } catch (error) {

    } finally {
      setProgress(100)
      handleClick()
    }

  }

  const handleClick = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  useEffect(()=>{
    setSelectedClass("")
    setSelectedSection("")
    setFinalStudent("")
    setEmail('')
  },[recipients])

  useEffect(()=>{
    setStudentList(students.filter((x=>x.fatherContact.email===email)))
  },[email])

  const handleSiyapa = (x) =>{
    setSelectedStudent(x)
    const q = studenList.filter((y)=> y?.fatherContact?.email === email && `${y?.firstName} ${y?.lastName}` === x)
    console.log(q)
    setFinalStudent(q[0])
  }

  useEffect(()=>{
    console.log(finalStudent)
  },[finalStudent])

  return (
    <>
      <LoadingBar
        color='#1740A1'
        height={4}
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backdropFilter: "blur(2px)" }}
      >
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2" mb={5}>
              Share Forms
            </Typography>
            <button style={{ background: "none", border: "none", cursor: "pointer" }} onClick={handleClose}><CancelIcon /></button>
          </div>

          <div className={cssClasses.mainContainer}>
            <div className={cssClasses.inputDiv}>
              {/* <Typography className={cssClasses.recipientDiv} variant='body'>Recipients</Typography> */}
              <InputDropdown
                placeholder={"Recipients"}
                width={"25vw"}
                bgColor="#ffffff"
                value={recipients}
                margin={1}
                setValue={setRecipients}
                defaultValues={["All School", "Class", "Section", "Individual"]}
              />
            </div>

            {recipients === "Class" && <div className={cssClasses.inputDiv}>
              {/* <Typography className={cssClasses.recipientDiv} variant='body'>Class</Typography> */}
              <InputDropdown
                placeholder={"Class"}
                width={"25vw"}
                bgColor="#ffffff"
                value={selectedClass}
                margin={1}
                setValue={setSelectedClass}
                defaultValues={classes}
              />
            </div>}

            {recipients === "Section" && <div className={cssClasses.inputDiv}>
              {/* <Typography className={cssClasses.recipientDiv} variant='body'>Class</Typography> */}
              <InputDropdown
                placeholder={"Recipients"}
                width={"100%"}
                bgColor="#ffffff"
                value={selectedSection}
                margin={1}

                setValue={setSelectedSection}
                defaultValues={sections}
              />
            </div>}

            {recipients === "Individual" && 
            <> 
            <div className={cssClasses.inputDiv}>
              {/* <Typography className={cssClasses.recipientDiv} variant='body' >Recipient's Email</Typography> */}
              <InputDashboard
                
                placeholder={"Email"}
                width={"25vw"}
                margin={1}
                change={(e) => {
                  setEmail(e.target.value);
                }}
                bgColor="#ffffff"
                value={email}
              />
            </div>

            <InputDropdown
                placeholder={"Student"}
                width={"25vw"}
                bgColor="#ffffff"
                value={selectedStudent}
                margin={1}
                setValue={handleSiyapa}
                disabled={studenList.length===0 || email?.length===0}
                defaultValues={studenList.map((x)=>`${x.firstName} ${x.lastName}`)}
            />
            
            </>
            }
          
          {
            recipients === "Individual" && email.length>0 && selectedStudent && <Typography>{}</Typography>
          }

          </div>
          <div className={cssClasses.buttonDiv}>
            <button className={cssClasses.CancelButton} onClick={handleClose}>Cancel</button>
            <button className={cssClasses.sendButton} onClick={handleSubmit}>Send</button>
            <button className={cssClasses.sendButton} style={{marginLeft:20}} onClick={() => {navigator.clipboard.writeText(`${process.env.REACT_APP_PARENT_APP_URL}/home/${finalStudent.screenings[finalStudent.screenings?.length-1]}`)}}>Copy Link</button>
          </div>
        </Box>
      </Modal>
      {!loading && <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleCloseSnackbar} severity={`${error ? "error" : "success"}`} sx={{ width: '100%' }}>
          {error ? "Error while sending the form right now, please try after sometime" : "Form sent successfully"}
        </Alert>
      </Snackbar>}
    </>
  )
}

export default ShareTypeformModal