import * as React from "react"
const ValidationCheckGreen = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#149457"
      fillRule="evenodd"
      d="M17.899 7.628c-.068-.45-.162-.886-.282-1.306a.545.545 0 0 0-.348-.382.658.658 0 0 0-.506.022.652.652 0 0 0-.35.383.89.89 0 0 0-.033.54c.09.345.158.69.203 1.035.045.345.067.705.067 1.08 0 2.175-.731 3.994-2.194 5.456C12.994 15.92 11.175 16.65 9 16.65c-2.175 0-3.994-.731-5.456-2.194C2.08 12.994 1.35 11.175 1.35 9c0-2.175.731-3.994 2.194-5.456C5.006 2.08 6.825 1.35 9 1.35c.81 0 1.579.12 2.306.36.728.24 1.407.555 2.037.945a.717.717 0 0 0 .967-.157.639.639 0 0 0 .146-.496.574.574 0 0 0-.281-.427c-.75-.48-1.556-.862-2.419-1.147A8.73 8.73 0 0 0 9 0C7.725 0 6.54.233 5.445.698A8.934 8.934 0 0 0 2.587 2.61a8.784 8.784 0 0 0-1.9 2.858A9.07 9.07 0 0 0 0 9c0 1.275.229 2.46.686 3.555a8.784 8.784 0 0 0 1.901 2.857 8.784 8.784 0 0 0 2.858 1.902C6.54 17.77 7.725 18 9 18a9.07 9.07 0 0 0 3.533-.686 8.784 8.784 0 0 0 2.857-1.902 8.934 8.934 0 0 0 1.912-2.857C17.767 11.46 18 10.275 18 9c0-.465-.034-.922-.101-1.372ZM4.46 9.854l2.745 2.768a.59.59 0 0 0 .462.203.66.66 0 0 0 .483-.203l9.36-9.36a.711.711 0 0 0 .225-.518.711.711 0 0 0-.225-.517.672.672 0 0 0-.517-.214.714.714 0 0 0-.495.214l-8.82 8.82-2.183-2.205a.75.75 0 0 0-.517-.203.673.673 0 0 0-.495.203.711.711 0 0 0-.225.517c0 .195.067.36.202.495Z"
      clipRule="evenodd"
    />
  </svg>
)
export default ValidationCheckGreen
