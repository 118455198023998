import { Avatar } from "@mui/material";
import React, { useState } from "react";
import classes from "./Patient.module.css";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";


export default function PatientRows({ data }) {

  const navigate = useNavigate();
  const location = useLocation();



  const { validated, pending } = data;
  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)} `.slice(-2);
    }

    return color;
  }

  const getAge = (age) => {
    var today = new Date();
    var birthDate = new Date(age);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 12,
        height: 12,
        padding: 2
      },
      children: `${name[0]} `,
    };
  }

  const getValidationStatus = (x) => {
    const today = moment().format("DD-MM-YYYY")
    if (moment(x).format("DD-MM-YYYY") > today) {
      return true
    }
    return false
  }

  const handleValidationStart = () => {
    navigate(`/doctor-validation/${data?.studentId}`, { state: { ...data, breadCrumbs: [...location?.state?.breadCrumbs, data?.patientName] } })
  }

  return (
    <>
      <div className={classes.studentRow_container}>

        {/* Name and Avatar Container */}
        <div>
          <div>
            <Avatar {...stringAvatar(data?.patientName)} />
          </div>
          {/* Name Container */}
          <div>
            <p>{data?.patientName}</p>
          </div>
        </div>

        {/* Gender Container */}
        <div>
          <p>{getAge(data?.DOB)}</p>
        </div>

        {/* Class Container */}
        <div>
          <p>{data?.gender}</p>
        </div>

        {/* Email Container */}
        <div>
          <p>{moment(data?.screeningDate).format("DD-MM-YYYY")}</p>

        </div>
        {/* Screening Container */}
        <div>
          <p className={`${!getAge(data?.validationDueDate) ? `${classes.pending_text}` : ``}`}>{moment(data?.validationDueDate).format("DD-MM-YYYY")}</p>
        </div>



        {/*  Validated Container */}
        <div>
          <button className={`${data?.validated ? `${classes.validated_btn}` : `${classes.pending_btn}`}`}>
            {`${data?.validated ? `VALIDATED` : `PENDING`}`}
          </button>
        </div>


        {/* Conditional Buttons Rendering --Role Section */}
        {/* {index < 3 ? (
          <div>
            {user ? (<button className={classes.screened_btn}>INACTIVE</button>) :
              (<button onClick={handleReport} className={classes.report_btn}>SAVED</button>)
            }
          </div>
        ) : ( */}
        <div>
          {validated ? (
            <button onClick={handleValidationStart} >Edit Validation</button>
          ) : (
            <button onClick={handleValidationStart} >Start Validation</button>
          )}

        </div>
      </div>
    </>
  );
}