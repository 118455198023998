import { call, put, takeLatest } from "redux-saga/effects";
import ApiServices from "../../graphqlApiServices";
import dashboardReducer from "../reducers/dashboard.reducer";

const { dashboard } = ApiServices

const { getPrincipalDashboard,
    getTeacherDashboard,
    getSchoolAdminDashboard,
    getSuperAdminDashboard,
    getSuperAdminLiveScreeningData,
    getPrincipalLiveScreeningData,
    getSperadminLiveDoctorValidation,
    updateDashboardLiveData
} = dashboard

export function* watchDashboardRequests() {
    yield takeLatest(dashboardReducer.actions.getPrincipalDashboardRequest, getPrincipalDashboardSaga);
    yield takeLatest(dashboardReducer.actions.getTeacherDashboardRequest, getTeacherDashboardSaga);
    yield takeLatest(dashboardReducer.actions.getSchoolAdminDashboardRequest, getSchoolAdminDashboardSaga);
    yield takeLatest(dashboardReducer.actions.getSuperAdminDashboardRequest, getSuperAdminDashboardSaga);
    yield takeLatest(dashboardReducer.actions.getSuperAdminDashboardLiveScreeningRequest, getSuperAdminDashboardLiveScreeningSaga);
    yield takeLatest(dashboardReducer.actions.getPrincipalLiveScreeningRequest, getPrincipalLiveScreeningSaga);
    yield takeLatest(dashboardReducer.actions.getSuperAdminDashboardLiveValidationRequest, getSuperAdminDashboardLiveValidationSaga)
    yield takeLatest(dashboardReducer.actions.updateDashboardReq, updateDashboardSaga)
    yield takeLatest(dashboardReducer.actions.getBehavioralDashboardReq, behavioralDashboardSaga)
}

export function* getPrincipalDashboardSaga(payload) {
    try {
        const response = yield call(getPrincipalDashboard, payload);
        const data = response.data.getPrincipalsDashboard

        yield put(dashboardReducer.actions.getPrincipalDashboardSuccess({ data }))
    } catch (error) {

        if (Object.keys(error.data?.getPrincipalDashboard).length > 0) {
            const data = error.data.getPrincipalDashboard
            yield put(dashboardReducer.actions.getPrincipalDashboardSuccess({ data }))
        } else {
            yield put(dashboardReducer.actions.getPrincipalDashboardFail({ error }))
        }
    }
}

export function* getTeacherDashboardSaga(payload) {
    try {
        const response = yield call(getTeacherDashboard, payload);
        const data = response.data.getTeacherDashboard
        yield put(dashboardReducer.actions.getTeacherDashboardSuccess({ data }))
    } catch (error) {

        if (Object.keys(error.data?.getTeacherDashboard)?.length > 0) {
            const data = error.data.getTeacherDashboard
            yield put(dashboardReducer.actions.getTeacherDashboardSuccess({ data }))
        } else {
            yield put(dashboardReducer.actions.getTeacherDashboardFail({ error }))
        }
    }
}

export function* getSchoolAdminDashboardSaga(payload) {
    try {
        const response = yield call(getSchoolAdminDashboard, payload);
        const data = response.data.getSchoolAdminDashboard
        yield put(dashboardReducer.actions.getSchoolAdminDashboardSuccess({ data }))
    } catch (error) {
        if (Object.keys(error.data?.getSchoolAdminDashboard)?.length > 0) {
            const data = error.data.getSchoolAdminDashboard
            yield put(dashboardReducer.actions.getSchoolAdminDashboardSuccess({ data }))
        } else {
            yield put(dashboardReducer.actions.getSchoolAdminDashboardFail({ error }))
        }
    }
}

export function* getSuperAdminDashboardSaga(payload) {
    try {
        const response = yield call(getSuperAdminDashboard, payload);
        const data = response.data.countItems
        yield put(dashboardReducer.actions.getSuperAdminDashboardSuccess({ data }))
    } catch (error) {
        if (Object.keys(error.data?.countItems)?.length > 0) {
            const data = error.data.countItems
            yield put(dashboardReducer.actions.getSuperAdminDashboardSuccess({ data }))
        } else {
            yield put(dashboardReducer.actions.getSuperAdminDashboardSuccess({ error }))
        }
    }
}

export function* getSuperAdminDashboardLiveScreeningSaga(payload) {
    try {
        const response = yield call(getSuperAdminLiveScreeningData, payload);
        const data = response.data.screeningLiveData

        yield put(dashboardReducer.actions.getSuperAdminDashboardLiveScreeningSuccess({ data }))
    } catch (error) {

        if (error.data?.screeningLiveData?.length > 0) {
            const data = error?.data?.screeningLiveData
            yield put(dashboardReducer.actions.getSuperAdminDashboardLiveScreeningSuccess({ data }))
        } else {
            yield put(dashboardReducer.actions.getSuperAdminDashboardLiveScreeningFail(error))
        }
    }
}

export function* getPrincipalLiveScreeningSaga(payload) {
    try {
        const response = yield call(getPrincipalLiveScreeningData, payload);
        const data = response?.data?.screeningLiveData
        yield put(dashboardReducer.actions.getSuperAdminDashboardLiveScreeningSuccess({ data }))
    } catch (error) {
        if (error.data?.screeningLiveData?.length > 0) {
            const data = error?.data?.screeningLiveData
            yield put(dashboardReducer.actions.getSuperAdminDashboardLiveScreeningSuccess({ data }))
        } else {
            yield put(dashboardReducer.actions.getSuperAdminDashboardLiveScreeningFail({ error }))
        }
    }
}

export function* getSuperAdminDashboardLiveValidationSaga(payload) {
    try {
        const response = yield call(getSperadminLiveDoctorValidation, payload);
        const data = response?.data?.doctorValidationLiveData
        yield put(dashboardReducer.actions.getSuperAdminDashboardLiveValidationSuccess({ data }))
    } catch (error) {
        if (error.data?.doctorValidationLiveData?.length > 0) {
            const data = error?.data?.doctorValidationLiveData
            yield put(dashboardReducer.actions.getSuperAdminDashboardLiveValidationSuccess({ data }))
        } else {
            yield put(dashboardReducer.actions.getSuperAdminDashboardLiveVaidationFail({ error }))
        }
    }
}

export function* updateDashboardSaga(payload) {
    try {
        
        yield call(updateDashboardLiveData, payload);
        // const data = response?.data?.doctorValidationLiveData
        // yield put(dashboardReducer.actions.getSuperAdminDashboardLiveValidationSuccess({ data }))
    } catch (error) {
        
    }
}



export function* behavioralDashboardSaga(payload) {
    try {
        const response = yield call(getSperadminLiveDoctorValidation, payload);
        
        // const data = response?.data?.doctorValidationLiveData
        // yield put(dashboardReducer.actions.behavioralDashboardSuccess({ data }))
    } catch (error) {
        // if (error.data?.doctorValidationLiveData?.length > 0) {
        //     const data = error?.data?.doctorValidationLiveData
        //     yield put(dashboardReducer.actions.getSuperAdminDashboardLiveValidationSuccess({ data }))
        // } else {
            yield put(dashboardReducer.actions.behavioralDashboardFailure({ error }))
        // }
    }
}