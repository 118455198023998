import * as React from "react";
import { useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseModal from "../../../assets/images/closeModal.svg";
import { textAlign } from "@mui/system";
import "./ChipModal.css";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from 'react-redux'


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "16px",
  width: "472px",
  boxShadow: 24,
  p: 2,
};

const btn_container = {
  border: "none",
  background: "none",
  padding: "none",
  border: "none",
  background: "none",
  fontWeight: "600",
  fontSize: "14px",
  cursor: "pointer",
  textAlign: "left",
};

const close_btn_container = {
  display: "flex",
  flexDirection: "row-reverse",
};

const close_btn = {
  background: "none",
  border: "none",
  outline: "none",
  textAlign: "left",
};

const modal_header = {
  margin: "24px 0 0 0",
  fontSize: "20px",
  fontWeight: "normal",
  textAlign: "center",
};

const modal_header_info = {
  margin: "20px 0 0 0",
  textAlign: "center",
  padding: "3px 20px",
  fontSize: "14px",
};

const action_btn_container = {
  margin: "24px 0 0 0",
  display: "flex",
  gap: "32px",
  justifyContent: "center",
};

const action_btn = {
  border: "1px solid #1740A1",
  padding: "16px 0",
  borderRadius: "8px",
  width: "176px",
  height: "56px",
  background: "none",
  fontWeight: "600",
  fontSize: "16px",
  cursor : "pointer"
};

const black_btn = {
  background: "none",
  border: "none",
  padding: "16px 0",
  borderRadius: "8px",
  width: "176px",
  height: "56px",
  background: "none",
  fontWeight: "600",
  fontSize: "16px",
  border: "none !important",
  color: "#ffffff",
  backgroundColor: "#1740A1",
  cursor : "pointer"
};

const chip_input_container = {
    margin : "24px 0 0 0",
    padding : "0 0 0 25px",
    textAlign : "left"
}

const chip_input = {
    borderRadius : "8px",
    border : "1px solid #B2B7BE",
    width : "400px"
}

const chip_details_container = {
    margin : "5px 0 0 0",
    display : "flex",
    gap : "5px",
    flexWrap : "wrap",
    padding : "0 0 0 20px",
    width : "50vh"
}


export default function ChipModal({
  actionName,
  org,
  action,
  setAction,
  title,
  subtitle,
  sendFunctionCall,
  handleClick,
  setOpen
}) {
  const dispatch = useDispatch()
  const [chipArr, setChipArr] = React.useState([])
  const [email, setEmail] = React.useState("")
  // const errorTypeform = useSelector((state) => state.typeformReducer.error);
  const handleClose = () => {
    setAction(false);
    setChipArr([])
    setEmail("")
  }


  const handleSend = () => {
    if(chipArr.length){
      dispatch(sendFunctionCall(chipArr))
      handleClick()
      handleClose()
      // 
    }else if(email.length && validateEmail(email)){
      dispatch(sendFunctionCall([email]))
      handleClick()
      handleClose()
    }
  }


  const handleChange = (event) => {
    setEmail(event.target.value)
  }

  const handleDelete = (element) => {
    const filteredChipData = chipArr.filter((el) => el !== element )
    setChipArr([...filteredChipData])
  };

  const handleKeyDown = (event) => {
    event.preventDefault()
    if(email?.length && validateEmail(email)){
        setChipArr([...chipArr, email])
        setEmail("")
    }
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };



  return (
    <div>
      <Modal
        open={action}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div style={modal_header}>{title}</div>

          <div style={modal_header_info}>{subtitle}</div>

          <div style = {chip_input_container}>
            <form onSubmit = {(e) => handleKeyDown(e)}>
            <input 
            style = {chip_input} 
            placeholder= "Email Address"
            value = {email} 
            onChange = {handleChange}
            />
            </form>
            {!validateEmail(email) && email.length ? (
              <div className="validation_text">
                {" "}
                Please fill valid email id*
              </div>
            ) : (
              <></>
            )}
          </div>
          <div style = {chip_details_container}>
            {chipArr.map((el, index) => {
                return (
                    <Chip
                    key = {index+Date.now()}
                    label= {el}
                    onDelete={() => handleDelete(el)}
                />
                )
            })}
            </div>

          <div style={action_btn_container}>
            <button style={action_btn} onClick={handleClose}>
              Cancel
            </button>
            <button onClick = {() => handleSend()} style={black_btn}>Send</button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
