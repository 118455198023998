import React, { useEffect } from 'react'
import Infirmary from '../common/InfirmaryTable/Infirmary'
import DashboardLayout from '../common/DashboardLayout/DashboardLayout'
import Calendar from '../Calendar/Calendar'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import eventReducer from '../../redux/reducers/event.reducer'

export default function EventTable(){
  const location = useLocation()
  const dispatch = useDispatch()

  const getEventBySchool = eventReducer.actions.getEventsBySchoolIdRequest;
  const getClasses = eventReducer.actions.getClassesRequest;
  const getEventMaps = eventReducer.actions.getEventMapsRequest

  useEffect(()=>{
    dispatch(getEventBySchool(location.state?.schoolData?.schoolId))
    dispatch(getEventMaps(location.state?.schoolData?.schoolId))
  },[])

  
  return (
        <Calendar schoolData={location.state?.schoolData}/>
  )
}
