import { API, graphqlOperation } from "aws-amplify";
import Cookies from "js-cookie";

function getBehavioralDashboard(payload, callBack, type) {
    const token = Cookies.get('tokenId');
    return API.graphql(graphqlOperation(
        `query MyQuery {
            getBehavioralDashboard(from: "${payload}", ofType: "${type}") {
              data {
                ADHD
                ANXIETY
                CONDUCT_DISORDER
                AUTISM
                DEPRESSION
                DEVELOPMENTAL_DELAY
                DYSCALCULIA_1
                DYSCALCULIA_2
                DYSGRAPHIA_1
                DYSGRAPHIA_2
                DYSLEXIA_1
                DYSLEXIA_2
                INTERNET_ADDICTION
                ODD
                totalAssessments
              }
              today
            }
          }`,
        {

        }
        , token
    )).then((data) => {
        callBack('success', data?.data?.getBehavioralDashboard);
    }).catch((error) => {
        callBack('failure', error);
    })
}

function getBhvAssessment(callBack) {
    const token = Cookies.get('tokenId');
    return API.graphql(graphqlOperation(
        `query MyQuery {
            getBhvAssessment {
              bhvAssign {
                ADHD
                updatedAt
                status
                questionId
                parentId
                package
                name
                orderId
                kidId
                itemId
                gender
                createdAt
                consultation
                assessmentId
                PERFORMANCE
                ODD
                INTERNET_ADDICTION
                EMOTIONAL_AND_BEHAVIORAL
                DYSLEXIA_2
                DYSLEXIA_1
                DYSGRAPHIA_2
                DYSGRAPHIA_1
                DYSCALCULIA_2
                DYSCALCULIA_1
                DOB
                DEVELOPMENTAL_DELAY
                DEPRESSION
                CONDUCT_DISORDER
                AUTISM
                ANXIETY
              }
            }
          }`,
        {

        }
        , token
    )).then((data) => {
        callBack('success', data?.data?.getBhvAssessment?.bhvAssign);
    }).catch((error) => {
        callBack('failure', error);
    })
}


function getBhvScreenings(payload) {
    const token = Cookies.get('tokenId');
    console.log(payload)
    if(payload){
        return API.graphql(graphqlOperation(
            `query MyQuery {
                getBhvScreenings(nextToken: "${payload}") {
                  items {
                    screeningId
                    schoolId
                    ADHD
                    ANXIETY
                    AUTISM
                    CONDUCT_DISORDER
                    DEPRESSION
                    DEVELOPMENTAL_DELAY
                    EMOTIONAL_AND_BEHAVIORAL
                    INTERNET_ADDICTION
                    ODD
                    PERFORMANCE
                    DYSCALCULIA_1
                    DYSCALCULIA_2
                    DYSGRAPHIA_1
                    DYSGRAPHIA_2
                    DYSLEXIA_1
                    DYSLEXIA_2
                    behavioralId
                    Package
                    createdAt
                    partOf
                    patientId
                    screeningPackageDetails {
                      abdomen
                      behavioralScreening
                      dental
                      ear
                      eye
                      hair
                      heart
                      learningScreening
                      lungs
                      packageType
                      skin
                      throat
                      vitalSigns
                    }
                    status
                    updatedAt
                    name
                    gender
                    DOB
                  }
                  nextToken
                }
              }
              `,
            {},
            token
        ))
    }
    else{
        return API.graphql(graphqlOperation(
            `query MyQuery {
                getBhvScreenings {
                  items {
                    screeningId
                    schoolId
                    ADHD
                    ANXIETY
                    AUTISM
                    CONDUCT_DISORDER
                    DEPRESSION
                    DEVELOPMENTAL_DELAY
                    EMOTIONAL_AND_BEHAVIORAL
                    INTERNET_ADDICTION
                    ODD
                    PERFORMANCE
                    DYSCALCULIA_1
                    DYSCALCULIA_2
                    DYSGRAPHIA_1
                    DYSGRAPHIA_2
                    DYSLEXIA_1
                    DYSLEXIA_2
                    behavioralId
                    Package
                    createdAt
                    partOf
                    patientId
                    screeningPackageDetails {
                      abdomen
                      behavioralScreening
                      dental
                      ear
                      eye
                      hair
                      heart
                      learningScreening
                      lungs
                      packageType
                      skin
                      throat
                      vitalSigns
                    }
                    status
                    updatedAt
                    name
                    gender
                    DOB
                  }
                  nextToken
                }
              }
              `,
            {},
            token
        ))
    }

}

function getBhvPatients(payload, callBack) {
    const token = Cookies.get('tokenId');
    return API.graphql(graphqlOperation(
        `query MyQuery {
             getBhvPatients(createdAt: "${payload}") {
              nextToken
              kid {
               DOB
               bhvAssign
               cart
               createdAt
               gender
               kidId
               name
               orders
               parentId
               parentName
               updatedAt
              }
             }
            }`,
        {

        }
        , token
    )).then((data) => {
        callBack('success', data?.data?.getBhvPatients);
    }).catch((error) => {
        callBack('failure', error);
    })
}

function getParent(payload, callBack) {
    const token = Cookies.get('tokenId')
    return API.graphql(graphqlOperation(
        `query MyQuery {
            getParent(parentId: "${payload}") {
              createdAt
              email
              kids
              name
              parentId
              phone
              updatedAt
              email
            }
          }`,
        {

        }
        , token
    )).then((data) => {
        callBack('success', data);
    }).catch((error) => {
        callBack('failure', error);
    })
}

const behavioral = {
    getBhvAssessment,
    getBhvPatients,
    getBehavioralDashboard,
    getParent,
    getBhvScreenings,

}
export default behavioral;