import { useEffect, useState } from 'react';
import RadarChart from 'react-svg-radar-chart';
import 'react-svg-radar-chart/build/css/index.css'

const Spiderchart = (props) => {
    const { data, screen, packageDetails } = props;
    const [tempBhvSpiderChart, setTempBhvSpiderChart] = useState({
        ADHD: 'ADHD',
        autism: 'autism',
        digitalDependency: 'IA',
        anxiety: 'Anxiety',
        conductDisorder: 'CD',
        odd: 'ODD',
        depression: 'Depression',
        developmentDelay: 'DD'

    });
    const [tempLngSpiderChart, setTempLngSpiderChart] = useState({

    });



    // useEffect(() => {
    //     if (packageDetails?.behavioralScreening?.length > 0) {
    //         let tempObjectBhv = {};
    //         packageDetails?.behavioralScreening.forEach((tests) => {
    //             if (tests === 'ADHD') {
    //                 tempObjectBhv = {
    //                     ...tempObjectBhv,
    //                     ADHD: 'ADHD'
    //                 }
    //             }
    //             if (tests === 'AUTISM') {
    //                 tempObjectBhv = {
    //                     ...tempObjectBhv,
    //                     autism: 'autism'
    //                 }
    //             }
    //             if (tests === 'DIGITAL_DEPENDENCY') {
    //                 tempObjectBhv = {
    //                     ...tempObjectBhv,
    //                     digitalDependency: 'IA'
    //                 }
    //             }
    //             if (tests === 'ANXIETY') {
    //                 tempObjectBhv = {
    //                     ...tempObjectBhv,
    //                     anxiety: 'Anx'
    //                 }
    //             }
    //             if (tests === 'CONDUCT_DISORDER') {
    //                 tempObjectBhv = {
    //                     ...tempObjectBhv,
    //                     conductDisorder: 'CD'
    //                 }
    //             }
    //             if (tests === 'DEFIANT_BEHAVIOR') {
    //                 tempObjectBhv = {
    //                     ...tempObjectBhv,
    //                     odd: 'ODD'
    //                 }
    //             }
    //             if (tests === 'DEPRESSION') {
    //                 tempObjectBhv = {
    //                     ...tempObjectBhv,
    //                     depression: 'Dep'
    //                 }
    //             }
    //             if (tests === 'DEVELOPMENT_MILESTONES') {
    //                 tempObjectBhv = {
    //                     ...tempObjectBhv,
    //                     developmentDelay: 'DD'
    //                 }
    //             }
    //         })
            
    //         setTempBhvSpiderChart(tempObjectBhv);
    //     }

    // }, [packageDetails])





    const dataBehavioral = [
        {
            data: {
                ADHD: data?.ADHD?.riskFactor ?? 0,
                autism: data?.AUTISM?.riskFactor ?? 0,
                digitalDependency: data?.INTERNET_ADDICTION?.riskFactor ?? 0,
                anxiety: data?.ANXIETY?.riskFactor ?? 0,
                conductDisorder: data?.CONDUCT_DISORDER?.riskFactor ?? 0,
                odd: data?.ODD?.riskFactor ?? 0,
                depression: data?.DEPRESSION?.riskFactor ?? 0,
                developmentDelay: data?.DEVELOPMENTAL_DELAY?.riskFactor ?? 0
            },
            meta: { color: 'green' }
        }
    ];

    const dataLearning = [
        {
            data: {
                dyslexia: data?.DYSLEXIA_2?.riskFactor
                    ? data?.DYSLEXIA_2?.riskFactor ?? 0
                    : data?.DYSLEXIA_1?.riskFactor ?? 0,
                dyscalculia: data?.DYSCALCULIA_2?.riskFactor
                    ? data?.DYSCALCULIA_2?.riskFactor ?? 0
                    : data?.DYSCALCULIA_1?.riskFactor ?? 0,
                dysgraphia: data?.DYSGRAPHIA_2?.riskFactor
                    ? data?.DYSGRAPHIA_2?.riskFactor ?? 0
                    : data?.DYSGRAPHIA_1?.riskFactor ?? 0,
            },
            meta: { color: 'green' }
        }
    ];

    const captionsLearning = {
        dyslexia: "Dyslexia",
        dyscalculia: "Dyscal...",
        dysgraphia: "Dysgraph...",
    };

    return (
        <RadarChart
            captions={screen === "behavioral" ? tempBhvSpiderChart : captionsLearning}
            data={screen === "behavioral" ? dataBehavioral : dataLearning}
            size={props.size ?? 320}
        />
    )
}

export default Spiderchart