import React from "react";
import classes from "./CD.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import BhvReportHeader from "../Anxiety/BhvReportHeader";
import clsx from "clsx";


export const CD = ({ data }) => {
  const response = JSON.parse(data?.CONDUCT_DISORDER)?.response
  const responsePerformance = JSON.parse(data?.PERFORMANCE)?.response
  const questionBank = [
    {
      qno: 1,
      question: "Bullies, threatens, or intimidates others?",
      answer: response?.[0]
    },
    {
      qno: 2,
      question: "Starts physical fights?",
      answer: response?.[1]
    },
    {
      qno: 3,
      question:
        "Lies to get out of trouble or to avoid obligations (ie,“cons” others)?",
      answer: response?.[2]
    },
    {
      qno: 4,
      question: "Is truant from school (skips school) without permission?",
      answer: response?.[3]
    },
    {
      qno: 5,
      question: "Is physically cruel to people?",
      answer: response?.[4]
    },
    {
      qno: 6,
      question: "Has stolen things that have value?",
      answer: response?.[5]
    },
    {
      qno: 7,
      question: `Deliberately destroys others’ property?`,
      answer: response?.[6]
    },
    {
      qno: 8,
      question:
        "Has used a weapon that can cause serious harm (bat, knife, brick, gun)?",
      answer: response?.[7]
    },
    {
      qno: 9,
      question:
        "Is physically cruel to animals?",
      answer: response?.[8]
    },
    {
      qno: 10,
      question:
        "Has deliberately set fires to cause damage?",
      answer: response?.[9]
    },
    {
      qno: 11,
      question:
        "Has broken into someone else’s home, business, or car?",
      answer: response?.[10]
    },
    {
      qno: 12,
      question:
        "Has stayed out at night without permission?",
      answer: response?.[11]
    },
    {
      qno: 13,
      question:
        "Has run away from home overnight?",
      answer: response?.[12]
    },
    {
      qno: 14,
      question:
        "Has forced someone into sexual activity?",
      answer: response?.[13]
    },
  ];

  const performanceQuestionBank = [
    {
      qno: 1,
      question: "Overall school performance",
      answer: responsePerformance?.[0]
    },

  ];

  return (
    <>
      <div className={clsx(classes.container, 'xyz')}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>
        <div className={classes.title_container}>
          Hey! This is my CD assessment report.
        </div>

        <BhvReportHeader data={data} />

        <div className={classes.question_container}>
          <div>Assessment Questionnaire</div>
          <div className={classes.question_select_container}>
            {questionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Never"
                          checked={el.answer === "NEVER"}
                        />
                        <p>Never</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Ocassionally"
                          checked={el.answer === "OCCASIONALLY"}
                        />
                        <p>Ocassionally</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Often"
                          checked={el.answer === "OFTEN"}
                        />
                        <p>Often</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Very Often"
                          checked={el.answer === "VERY_OFTEN"}
                        />
                        <p>Very Often</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes.question_container}>
          <div>Performance Questionnaire</div>
          <div className={classes.question_select_container}>
            {performanceQuestionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p style={{ flexBasis: "160px" }}>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form style={{ columnGap: "20px" }}>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Excellent"
                          checked={el.answer === "EXCELLENT"}
                        />
                        <p>Excellent</p>
                      </div>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Above Average"
                          checked={el.answer === "ABOVE_AVERAGE"}
                        />
                        <p>Above Average</p>
                      </div>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Average"
                          checked={el.answer === "AVERAGE"}
                        />
                        <p>Average</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Somewhat of a problem"
                          checked={el.answer === "SOMEWHAT_OF_A_PROBLEM"}
                        />
                        <p style={{ width: "52px" }}>Somewhat of a problem</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Problematic"
                          checked={el.answer === "PROBLEMATIC"}
                        />
                        <p>Problematic</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.footer_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 1 of 2</p>
          </div>
        </div>
      </div>
    </>
  );
};
