import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import DashboardLayout from '../components/common/DashboardLayout/DashboardLayout'
import Navbar from '../components/common/Navbar/Navbar'

function TeacherRoutes({ component }) {
  const user = useSelector((state) => state.userReducer.currentUser)
  const loggedIn = useSelector((state) => state.authReducer.loggedIn)

  if (!loggedIn) {
    return <Navigate to={"/login"} />
  }
  else if (user && !user?.groups?.includes("school-teacher")) {
    
    // return <Navigate to={`/404`}/>
  }
  else {
    return (
      <>
        <div className='baseStructure'>
          <DashboardLayout />
          <div className='bsrs'>
            <Navbar />
            <div className='bsrb'>
              {component}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default TeacherRoutes