import React from "react";
import classes from "./ODD.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import { stringAvatar } from "../../../utils/utility";
import { stringToColor } from "../../../utils/utility";
import { Avatar } from "@mui/material";
import highrisk from "../../../assets/images/highRisk.svg";
import psychologist from "../../../assets/images/psychologist.svg";
import BhvReportHeader from "../Anxiety/BhvReportHeader";

export const ODD = ({ data }) => {
  const questionBank = [
    {
      qno: 1,
      question: "Argues with adults?",
      answers: data?.output?.[0]?.answers?.[0]
    },
    {
      qno: 2,
      question: "Loses temper?",
      answers: data?.output?.[0]?.answers?.[1]
    },
    {
      qno: 3,
      question:
        "Actively defies or refuses to go along with adults’ requests or rules?",
      answers: data?.output?.[0]?.answers?.[2]
    },
    {
      qno: 4,
      question: "Deliberately annoys people?",
      answers: data?.output?.[0]?.answers?.[3]
    },
    {
      qno: 5,
      question: "Blames others for his or her mistakes or misbehaviors?",
      answers: data?.output?.[0]?.answers?.[4]
    },
    {
      qno: 6,
      question: "Is touchy or easily annoyed by others",
      answers: data?.output?.[0]?.answers?.[5]
    },
    {
      qno: 7,
      question: `Is angry or resentful?`,
      answers: data?.output?.[0]?.answers?.[6]
    },
    {
      qno: 8,
      question: "Is spiteful and wants to get even?",
      answers: data?.output?.[0]?.answers?.[7]
    },
  ];

  const performanceQuestionBank = [
    {
      qno: 1,
      question: "Overall school performance",
      answers: data?.output?.[1]?.answers?.[0]
    },
    {
      qno: 2,
      question:
        "Reading",
      answers: data?.output?.[1]?.answers?.[1]
    },
    {
      qno: 3,
      question:
        "Writing",
      answers: data?.output?.[1]?.answers?.[2]
    },
    {
      qno: 4,
      question: "Mathematics",
      answers: data?.output?.[1]?.answers?.[3]
    },

    {
      qno: 5,
      question: "Relationships with parents",
      answers: data?.output?.[1]?.answers?.[4]
    },

  ];

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>
        <div className={classes.title_container}>
          Hey! This is my ODD assessment report.
        </div>

        <BhvReportHeader data={data} />

        <div className={classes.question_container}>
          <div>Assessment Questionnaire</div>
          <div className={classes.question_select_container}>
            {questionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Never"
                          checked={el.answers === "NEVER"}
                        />
                        <p>Never</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Ocassionally"
                          checked={el.answers === "OCCASIONALLY"}
                        />
                        <p>Ocassionally</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Often"
                          checked={el.answers === "OFTEN"}
                        />
                        <p>Often</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Very Often"
                          checked={el.answers === "VERY_OFTEN"}
                        />
                        <p>Very Often</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes.question_container}>
          <div>Performance Questionnaire</div>
          <div className={classes.question_select_container}>
            {performanceQuestionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p style={{ flexBasis: "160px" }}>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form style={{ columnGap: "20px" }}>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Excellent"
                          checked={el.answers === "EXCELLENT"}
                        />
                        <p>Excellent</p>
                      </div>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Above Average"
                          checked={el.answers === "ABOVE_AVERAGE"}
                        />
                        <p>Above Average</p>
                      </div>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Average"
                          checked={el.answers === "AVERAGE"}
                        />
                        <p>Average</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Somewhat of a problem"
                          checked={el.answers === "SOMEWHAT_OF_A_PROBLEM"}
                        />
                        <p style={{ width: "52px" }}>Somewhat of a problem</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Problematic"
                          checked={el.answers === "PROBLEMATIC"}
                        />
                        <p>Problematic</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.footer_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 1 of 2</p>
          </div>
        </div>
      </div>
    </>
  );
};
