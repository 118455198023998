import React, { useState } from 'react'
import { Avatar, Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { capitalizeFirstLetter, stringAvatar, truncateString } from '../../../../utils/utility'
import classes from './RetakeTable.module.css'
import FilterListIcon from '@mui/icons-material/FilterList';
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { useEffect } from 'react'
import InputDropdown from '../../../common/InputDashboard/InputDropdown'
import BlankDataScreen from '../../../BlankDataScreen/BlankDataScreen';
import { useNavigate } from 'react-router-dom';

function RetakeTable() {
    const [pageNo, setPageNo] = useState(1);
    const [displayData, setDisplayData] = useState([]);
    const [filterCity, setFilterCity] = useState('');
    const [filterSchool, setFilterSchool] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const data = []
    const navigate = useNavigate()

    // filter function 
    useEffect(() => {
        if (filterSchool && filterCity) {
            setDisplayData(data.filter((x) => x.city === filterCity.toLowerCase() && x.schoolName.toLowerCase().includes(filterSchool.toLowerCase())))
            setPageNo(1)
        }
        else if (filterSchool) {
            setDisplayData(data?.filter((x) => x?.schoolName.toLowerCase().includes(filterSchool.toLowerCase())))
            setPageNo(1)
        }
        else if (filterCity) {
            setDisplayData(data?.filter((y) => y?.city === filterCity.toLowerCase()))
            setPageNo(1)
        }
        else {
            setDisplayData(data)
        }
    }, [filterCity, filterSchool])

    return <BlankDataScreen title="Oops! No data available" handleClick={() => navigate(-1)} buttonText="Back" />
    // return <div>
    //     <div>Re-exam Students</div>
    //     <br />
    //     <div className={classes.filterBox}>
    //         <div className={classes.filterOptions}>
    //             <FormControl>
    //                 <InputLabel id="demo-simple-select-label">Rows</InputLabel>
    //                 <Select
    //                     labelId="demo-simple-select-label"
    //                     id="demo-simple-select"
    //                     value={rowsPerPage}
    //                     label="Rows"
    //                     onChange={(e) => { setRowsPerPage(e.target.value); setPageNo(1) }}
    //                     size='small'
    //                 >
    //                     <MenuItem value={10}>10</MenuItem>
    //                     <MenuItem value={20}>20</MenuItem>
    //                     <MenuItem value={50}>50</MenuItem>
    //                 </Select>
    //             </FormControl>
    //             <InputDropdown
    //                 placeholder={`Select City`}
    //                 width={"10vw"}
    //                 className={classes.input_layout_boardname}
    //                 bgColor="#ffffff"
    //                 value={filterCity}
    //                 margin={2}
    //                 setValue={setFilterCity}
    //                 defaultValues={["Delhi", "Gurgaon"]}
    //             />
    //             <InputDropdown
    //                 placeholder={`Select School`}
    //                 width={"23vw"}
    //                 className={classes.input_layout_boardname}
    //                 bgColor="#ffffff"
    //                 value={filterSchool}
    //                 margin={2}
    //                 setValue={setFilterSchool}
    //                 defaultValues={["school1", "school2"]}
    //             />
    //             {/* <FilterListIcon sx={{ marginLeft: "20px" }} /> */}
    //         </div>
    //         {(filterCity && filterSchool) &&
    //             <div className={classes.filterBtn}>
    //                 <Button variant="outlined">
    //                     HIGO Data Integration
    //                 </Button>
    //                 <Button variant="outlined">
    //                     Download/Print QR
    //                 </Button>
    //             </div>
    //         }
    //     </div>
    //     <br />
    //     {(!filterCity || !filterSchool) ?
    //         <>
    //             <div style={{ color: '#3E36B0', textAlign: 'center' }}>Please select the field to proceed further</div>
    //             {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() =>
    //                 <Box sx={{ pt: 0.5, display: 'flex', gap: '1rem', padding: '10px 0', justifyContent: 'space-between' }}>
    //                     <Skeleton animation="wave" variant="circular" width={40} height={40} />
    //                     {[1, 2, 3, 4, 5].map(() => <Skeleton variant="rectangular" width={210} height={40} />)}
    //                 </Box>)}
    //         </>
    //         : <div>
    //             <TableContainer component={Paper} sx={{
    //                 '&.MuiTableContainer-root': {
    //                     boxShadow: 'none',
    //                     backgroundColor: 'transparent'
    //                 },
    //                 'thead th': {
    //                     fontWeight: '600',
    //                     fontSize: '12px',
    //                     lineHeight: '15px'
    //                 }
    //             }}>
    //                 <Table sx={{ minWidth: 650 }} aria-label="simple table">
    //                     <TableHead sx={{ height: "70px" }}>
    //                         <TableRow>
    //                             <TableCell>STUDENT NAME</TableCell>
    //                             <TableCell align="right">DOB</TableCell>
    //                             <TableCell align="right">GENDER</TableCell>
    //                             <TableCell align="right">CLASS</TableCell>
    //                             <TableCell align="right">SCREENING DATE</TableCell>
    //                             <TableCell align="right">RE-EXAM ORGAN</TableCell>
    //                             <TableCell align="right">RE-EXAM REQUEST BY </TableCell>
    //                             <TableCell align="right">QR CODE</TableCell>
    //                             <TableCell align="right">STATUS</TableCell>
    //                         </TableRow>
    //                     </TableHead>
    //                     <TableBody>
    //                         {Array.isArray(displayData) && displayData?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo).map((row) => (
    //                             <TableRow key={row?.qrCode}
    //                                 sx={{
    //                                     '&:last-child td, &:last-child th': { border: 0 },
    //                                     'th': {
    //                                         height: '70px'
    //                                     },
    //                                     ' td:nth-child(2)': {
    //                                         color: '#343434'
    //                                     },
    //                                     ' td': {
    //                                         fontWeight: '400',
    //                                         fontSize: '12px',
    //                                         lineHeight: '16px'
    //                                     }
    //                                 }}>
    //                                 <TableCell component="th" scope="row">
    //                                     <div className={classes.tabBtn_container}>
    //                                         {row?.url
    //                                             ? <img style={{ width: "32px", height: "32px", borderRadius: "20px", objectFit: "cover" }} src={row?.url} alt="" />
    //                                             : <Avatar {...stringAvatar(capitalizeFirstLetter(row?.name ?? ''))} />
    //                                         }{capitalizeFirstLetter(truncateString(row?.name, 30))}
    //                                     </div>
    //                                 </TableCell>
    //                                 <TableCell align="right">{row?.dob}</TableCell>
    //                                 <TableCell align="right">{row?.gender}</TableCell>
    //                                 <TableCell align="right">{row?.class}</TableCell>
    //                                 <TableCell align="right">{row?.screeningDate}</TableCell>
    //                                 <TableCell align="right">{row?.reExamOrgan}</TableCell>
    //                                 <TableCell align="right">{row?.reExamRequest}</TableCell>
    //                                 <TableCell align="right">{row?.qrCode}</TableCell>
    //                                 <TableCell align="right" >
    //                                     <button className={classes.statusBtn} style={{ background: row?.status == 'Active' ? '#E09F26' : '#8E8F8F' }}>
    //                                         {row?.status == 'Active' ? 'RE-EXAM' : 'INACTIVE'}
    //                                     </button></TableCell>
    //                             </TableRow>
    //                         ))}
    //                     </TableBody>
    //                 </Table>
    //             </TableContainer>

    //             {/* pagination  */}
    //             <div className={classes.pagination_container}>
    //                 <div className={classes.page_item}>{`Showing ${rowsPerPage * (pageNo - 1) + 1} to ${pageNo * rowsPerPage <= data?.length
    //                     ? pageNo * rowsPerPage
    //                     : data?.length} 
    //             of ${data?.length} items`}</div>
    //                 <div className={classes.pagination}>
    //                     <Button
    //                         size="small"
    //                         disabled={pageNo === 1}
    //                         onClick={() => setPageNo(pageNo - 1)}
    //                     >
    //                         <ChevronLeft />
    //                     </Button>
    //                     <span>{pageNo}</span>
    //                     <div>
    //                         <span>of</span>
    //                         <span>{Math.ceil(data?.length / rowsPerPage)}</span>
    //                     </div>
    //                     <div>
    //                         <Button
    //                             size="small"
    //                             disabled={pageNo === Math.ceil(data?.length / rowsPerPage)}
    //                             onClick={() => setPageNo(pageNo + 1)}
    //                         >
    //                             <ChevronRight />
    //                         </Button>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     }
    // </div >
}

export default RetakeTable
