import React, { useEffect } from 'react'
import classes from './PatientsTable.module.css'
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import CustomAutoComplete from '../../common/CustomAutoComplete/CustomAutoComplete';
import SearchBar from '../../common/TableComponents/SearchBar';
import { ChevronLeft, ChevronRight, Filter } from '@mui/icons-material';
import PatientRow from './PatientRow';
import behavioral from '../../../graphqlApiServices/behaviroal';
import { useState } from 'react';
import { Awsdateformatter } from '../../../utils/utility';

function PatientsTable() {
    const { getBhvPatients } = behavioral
    const [displayData, setDisplayData] = useState()
    const [patientData, setPatientData] = useState([])
    const [text, setText] = useState()
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [pageNo, setPageNo] = useState(1)

    useEffect(() => {
        getBhvPatients("2023-08-08T10:48:22.811Z", callBack)
    }, [])

    useEffect(() => {
        if (text) {
            setDisplayData(
                patientData?.filter((x) => `${x.name}`.toLowerCase().includes(text.toLowerCase()))
            )
            setPageNo(1)
        }
        else {
            setDisplayData(patientData)
        }
    }, [text])

    function callBack(type, res) {
        if (type === 'success') {
            console.log(res);
            setPatientData(res?.kid)
            setDisplayData(res?.kid)
        } else if (type = "failure") {
            console.log(res);
        }
    }
    return (
        <div className={classes.main_div}>
            <div className={classes.student_layout}>
                Patients
            </div>
            <div className={classes.student_filters}>
                <div className={classes.show_text}>Show</div>
                <FormControl>
                    <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={rowsPerPage}
                        label="Rows"
                        onChange={(e) => setRowsPerPage(e.target.value)}
                        size="small"
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                    </Select>
                </FormControl>
                <SearchBar id={"searchCy"} onchange={setText} value={text} />
                {/* <Filter
                    label1={"Active"}
                    label2={"Inactive"}
                filter1={activeFilter}
                filter2={inactiveFilter}
                setFilter1={setActiveFilter}
                setFilter2={setInActiveFilter}
                /> */}
            </div>
            {/* Student Table Header */}
            <div className={classes.student_table_header}>
                <div>PATIENT NAME</div>
                <div>AGE</div>
                <div>GENDER</div>
                <div>PARENTS DETAIL</div>
                <div>HISTORY AVAILABLE</div>
                <div>NEXT CONSULTATION</div>
                <div style={{ justifySelf: "center" }}>ACTION</div>
            </div>
            {/* Student Column-Rows Section */}
            <div id="schoolRowCy" className={classes.student_column_container}>
                {displayData?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo)?.map(item => (
                    <PatientRow data={item} />
                ))}
            </div>
            <div className={classes.pagination_container}>
                <div className={classes.page_item}>{`Showing ${rowsPerPage * (pageNo - 1) + 1
                    } to ${pageNo * rowsPerPage <= displayData?.length
                        ? pageNo * rowsPerPage
                        : displayData?.length
                    } of ${displayData?.length} items`}</div>
                <div className={classes.pagination}>
                    <Button
                        size="small"
                        disabled={pageNo === 1}
                        onClick={() => setPageNo(pageNo - 1)}
                    >
                        <ChevronLeft />
                    </Button>
                    <span>{pageNo}</span>
                    <div>
                        <span>of</span>
                        <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
                    </div>

                    <div>
                        <Button
                            size="small"
                            disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)}
                            onClick={() => setPageNo(pageNo + 1)}
                        >
                            <ChevronRight />
                        </Button>
                    </div>
                </div>
            </div>

            {/* <ChipModal
                action={chipModal}
                setAction={setChipModal}
                title="Share Add School Typeform"
                subtitle="Please enter email id below to share “Add School” typeform to school representative."
                sendFunctionCall={shareTypeform}
                handleClick={handleClick}
                setOpen={setOpen}
            />
            {!loadingTypeform && (
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                    <Alert
                        onClose={handleClose}
                        severity={!errorTypeform ? "success" : "error"}
                        sx={{ width: "100%" }}
                    >
                        {!errorTypeform
                            ? `Typeform Sent Successfully`
                            : `Something Wrong! Try Again`}
                    </Alert>
                </Snackbar>
            )} */}
        </div>
    )
}

export default PatientsTable