import React from "react";
import classes from "./BehavioralContinue.module.css";
import skidslogo from "../../assets/images/SkidsSuperManLogo.svg";
import mcneil from "../../assets/images/McNeil.svg";
import nichq from "../../assets/images/NICHQ.svg";
import americanPediatrics from "../../assets/images/americanPediatrics.svg";
import { textFormat } from "../../utils/utility";
import clsx from "clsx";

export const BehavioralContinue = ({ data }) => {

  const response = JSON.parse(data?.ADHD)?.response
  const responsePerformance = JSON.parse(data?.PERFORMANCE)?.response
  const questionBank = [
    {
      qno: 15,
      question: "Talks too much",
      answer: textFormat(response?.[14])
    },
    {
      qno: 16,
      question: "Blurts out answers before questions have been completed",
      answer: textFormat(response?.[15])
    },
    {
      qno: 17,
      question: "Has difficulty waiting his or her turn",
      answer: textFormat(response?.[16])
    },
    {
      qno: 18,
      question:
        "Interrupts or intrudes in or others’ conversations and/or activities",
      answer: textFormat(response?.[17])
    },
  ];

  const performanceQuestionBank = [
    {
      qno: 1,
      question: "Overall school performance",
      answer: textFormat(responsePerformance?.[0])
    },
    {
      qno: 2,
      question: "Reading",
      answer: textFormat(responsePerformance?.[1])
    },
    {
      qno: 3,
      question: "Writing",
      answer: textFormat(responsePerformance?.[2])
    },
    {
      qno: 4,
      question: "Mathematics",
      answer: textFormat(responsePerformance?.[3])
    },
    {
      qno: 5,
      question: "Relationships with parents",
      answer: textFormat(responsePerformance?.[4])
    },
    {
      qno: 6,
      question: "Relationships with siblings",
      answer: textFormat(responsePerformance?.[5])
    },
    {
      qno: 7,
      question: "Relationships with peers",
      answer: textFormat(responsePerformance?.[6])
    },
    {
      qno: 8,
      question: "Participation in organised activities (eg, teams)",
      answer: textFormat(responsePerformance?.[7])
    },
  ];

  return (
    <>
      <div className={clsx(classes.container, 'xyz')}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>

        <div className={classes.assessment_question_container}>
          <div>Assessment Questionnaire Continued...</div>
          <div className={classes.question_select_container}>
            {questionBank.map((el) => {

              return (
                <div className={classes.question_radio_box}>
                  <p>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Never"
                          checked={el.answer === "Never"}
                        />
                        <p>Never</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Ocassionally"
                          checked={el.answer === "Occasionally"}
                        />
                        <p>Ocassionally</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Often"
                          checked={el.answer === "Often"}
                        />
                        <p>Often</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Very Often"
                          checked={el.answer === "Very Often"}
                        />
                        <p>Very Often</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes.performance_question_container}>
          <div>Performance Questionnaire</div>
          <div className={classes.question_select_container}>
            {performanceQuestionBank.map((el) => {
              return (
                <div className={`${classes.question_radio_box}`}>
                  <p className={classes.box_min_range}>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButtonNext"
                          value="Excellent"
                          checked={el.answer === "Excellent"}
                        />
                        <p>Excellent</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButtonNExt"
                          value="Above Average"
                          checked={el.answer === "Above Average"}
                        />
                        <p>Above Average</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButtonNext"
                          value="Average"
                          checked={el.answer === "Average"}
                        />
                        <p>Average</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButtonNext"
                          value="Somewhat of a problem"
                          checked={el.answer === "Somewhat Of A Problem"}
                        />
                        <p>Somewhat of a problem</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButtonNext"
                          value="Problematic"
                          checked={el.answer === "Problematic"}
                        />
                        <p>Problematic</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes.copyright_details_container}>
          <div>
            The information contained in this publication should not be used as
            a substitute for the medical care and advice of your psychologist.
            There may be variations in treatment that your psychologist may
            recommend based on individual facts and circumstances.
          </div>
          <div>
            Copyright @2002 American Academy of Pediatrics and National
            Initiative for Children’s Healthcare Quality
            <br /> Adapted from the Vanderbilt Rating Scale developed by Mark L.
            Wolraich, MD.
            <br /> Revised - 1102
          </div>
        </div>
        <div className={classes.method_framework}>Method Framework</div>

        <div className={classes.certification_container}>
          <img src={americanPediatrics} alt="americanPediatrics" />
          <img src={nichq} alt="nichq" />
          <img src={mcneil} alt="mcneil" />
        </div>

        <div className={classes.footer_container}>
          <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
          <p>Page 1 of 2</p>
        </div>
      </div>
    </>
  );
};
