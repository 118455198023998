import React from 'react'
import classes from './gap.module.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Hr from '../HrLine/Hr';
import bulb from '../../../assets/images/bulb.png'

const GetAdvanceBehavioral = (props) => {

    return (
        <div>
            <p className={classes.titleTextBhv}>{props.titleText}</p>
            <Hr />
            <div className={classes.locateClinics}>
                <img src={bulb} alt="" />
                <div>
                    <p>Interested in getting the {props?.titleText?.split(' ')?.[0]} assessment done?</p>
                    <div className={classes.ClickHereBlueBlack}>
                        <span>Click here</span>
                        <p>to do online assessment of {props?.titleText?.split(' ')?.[0]}</p>
                    </div>
                </div>
            </div>
            <Hr />
        </div>

    )
}

export default GetAdvanceBehavioral