import React, { useRef } from 'react'
import classes from './TabButton.module.css'
import addcircleblue from '../../../assets/images/addcircleblue.svg'
import bulkupload from '../../../assets/images/upload-bulk.svg'
import typeformUpload from '../../../assets/images/typeformUpload.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function TabButton({ uploadBulk, role, eventButton }) {
    const navigate = useNavigate()
    const location = useLocation()
    const ref = useRef();

    const schoolId = useSelector((state) => state.userReducer?.currentUser?.schoolId)

    const handleAddRole = () => {
        
        if (role === "Principal") {
            navigate('/superadmin-users/individual/user', { state: { type: "principal", schoolId: location.state, breadCrumbs: [...location.state.breadCrumbs, "Add Principal"] } })
        }
        else if (role === "Teacher") {
            if (location.pathname.includes("superadmin")) {
                navigate('/superadmin-users/individual/user', { state: { type: "teacher", schoolId: location.state, breadCrumbs: [...location.state.breadCrumbs, "Add Teacher"] } })
            }
            else if (location.pathname.includes("principal")) {
                navigate('/principal-teacher/individual/user', { state: { type: "teacher", } })
            }
            else if (location.pathname.includes("admin")) {
                navigate('/admin-teacher/individual/user', { state: { type: "teacher", } })
            }
        } else if (role === 'Admin') {
            navigate('/superadmin-users/individual/user', { state: { type: "admin", schoolId: location.state, breadCrumbs: [...location.state.breadCrumbs, "Add Admin"] } })
        } else if (role === 'Counsellor') {
            navigate('/superadmin-users/individual/user', { state: { type: "counsellor", schoolId: location.state, breadCrumbs: [...location.state.breadCrumbs, "Add Counsellor"] } })
        }
        else if (location.pathname.includes('clinic') && location.pathname.includes('doctor')) {
            // navigate(':userentity/individual/person')
            navigate('/superadmin-users/individual/user', { state: { type: "doctor", schoolId: location.state }, breadCrumbs: [...location.state.breadCrumbs, "Add Doctor"] })
        }
        else if (location.pathname.includes('clinic') && location.pathname.includes('nurse')) {
            // navigate(':userentity/individual/person')
            navigate('/superadmin-users/individual/user', { state: { type: "nurse", schoolId: location.state, breadCrumbs: [...location.state.breadCrumbs, "Add Nurse"] } })
        }
        else if (location.pathname.includes('school') && location.pathname.includes('doctor')) {
            // navigate(':userentity/individual/person')
            navigate('/superadmin-users/individual/user', { state: { type: "infirmary", schoolId: location.state }, breadCrumbs: [...location.state.breadCrumbs, "Add Infirmary"] })
        }else if (location.pathname.includes('partner-school')) {
            navigate('/partner/partner-school/school', { state: { ...location.state, breadCrumbs: [...location.state.breadCrumbs, "Add School"] } })
        } else if (location.pathname.includes('writer')) {
            navigate('/writer/write', { state: { ...location.state, breadCrumbs: [...location.state.breadCrumbs, "Add New Blog"] } })
        } 
        else if (location.pathname.includes('school')) {
            navigate('/superadmin-school/school', { state: { ...location.state, breadCrumbs: [...location.state.breadCrumbs, "Add School"] } })
        } else if (location.pathname.includes('clinic')) {
            navigate('/superadmin-clinc/add-clinic')
        } else if (location.pathname.includes('clinic') && location.pathname.includes('staff')) {
            navigate(':userentity/individual/person')
        } else if (location.pathname.includes('clinic') && location.pathname.includes('staff')) {
            navigate(':userentity/individual/person')
        } else if (location.pathname?.includes("principal-infirmary")) {
            navigate('/principal-infirmary/individual/user', { state: { type: "infirmary", schoolId: { schoolId } } })
        } else if (role === "Internal User") {
            navigate('/superadmin-users/individual/user', { state: { type: "Internal User" } })
        } else if (role === "Infirmary"){
            if(location.pathname.includes('admin-infirmary')){
                navigate('/admin-infirmary/individual/user', { state: { type: "infirmary",}})
            }
        }
    }
    return (
        <>
            {!location.pathname.includes('events') ? (<div className={classes.tabButton} >
                <button onClick={handleAddRole}>
                    <img src={addcircleblue} alt="addCircleFill" />
                    <span>{`Add ${role}`}</span>
                </button>

                {uploadBulk ? (
                    <button>
                        <img src={typeformUpload} alt="addCircleFill" />
                        <span>Upload Bulk</span>
                    </button>
                ) : (<></>)}

            </div>) : (<div className={classes.eventButtonContainer}>
                <div>5 devices added</div>
                <div className={classes.tabButton}>
                    <button>
                        <img src={addcircleblue} alt="addCircleFill" />
                        <span>Add Event</span>
                    </button>
                    <button>
                        <img src={addcircleblue} alt="addCircleFill" />
                        <span>Add Device</span>
                    </button>
                </div>
            </div>)}



        </>
    )
}