import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./TicketTable.module.css";
import TicketRow from "./TicketRow";
import schoolReducer from "../../redux/reducers/school.reducer";
import typeformReducer from "../../redux/reducers/typeform.reducer";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";
import TabButton from "../common/TableComponents/TabButton";
import SearchBar from "../common/TableComponents/SearchBar";
import { City } from "country-state-city";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import CustomAutoComplete from "../common/CustomAutoComplete/CustomAutoComplete";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import typeformUpload from "../../assets/images/typeformUpload.svg";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ChipModal from "../common/ChipModal/ChipModal";
import Cookies from "js-cookie";
// import ScheduleModal from "./ScheduleModal";
import { Alert, Snackbar } from "@mui/material";
import { Filter } from "../common/TableComponents/Filter";
// import { compareDate } from "../../../utils/utility";
import moment from "moment";
import BlankDataScreen from "../BlankDataScreen/BlankDataScreen";
import supportReducer from "../../redux/reducers/support.reducer";

function TicketsPage() {
    const token = Cookies.get("tokenId");
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const breadCrumbs = ["Raised Tickets"];
    const getSupportTickets = supportReducer.actions.getSupportTicketsRequest;
    const tickets = useSelector((state) => state.supportReducer.tickets);
    console.log(tickets, 'tickets');
    useEffect(() => {
      dispatch(getSupportTickets());
    }, []);
  
    location.state = { breadCrumbs: breadCrumbs };
    const data = useSelector((state) => state.schoolReducer.data);
    const loading = useSelector((state) => state.schoolReducer.loading);
    const errorTypeform = useSelector((state) => state.typeformReducer.error);
    const loadingTypeform = useSelector((state) => state.typeformReducer.loading);
    const shareTypeform = typeformReducer.actions.shareTypeformRequest;
  
    function getWordBeforeDash() {
      let newStr = "";
      for (let i = 1; i < location.pathname.length; i++) {
        if (location.pathname[i] === "-") {
          return newStr;
        }
        newStr += location.pathname[i];
      }
    }
  
    const allCities = [
      ...new Set(City.getCitiesOfCountry("IN").map((x) => x.name)),
    ];
  
    const [open, setOpen] = useState(false);
    const [sec, setSec] = useState(false);
    const [text, setText] = useState("");
    const [displayData, setDisplayData] = useState(data ?? []);
    const [city, setCity] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const [chipModal, setChipModal] = useState(false);
    const [resolvedFilter, setResolvedFilter] = useState(false);
    const [unresolvedFilter, setUnresolvedFilter] = useState(false);
    const handleClose = (event, reason) => {
      setOpen(false);
    };
    useEffect(() => {
      localStorage.removeItem('filter')
    }, [])
    const handleClick = () => {
      setOpen(true);
    };
  
    useEffect(() => {
      if (text) {
        setDisplayData(
          tickets.filter((x) =>
            x?.topic?.toLowerCase()?.includes(text?.toLowerCase()) || x?.issue?.toLowerCase()?.includes(text?.toLowerCase())
          )
        );
        setPageNo(1)
      } else {
        setDisplayData(tickets);
      }
    }, [text]);
  
    useEffect(() => {
      if (Array.isArray(tickets)) {
        const y = tickets?.slice()?.sort((a, b) => {
          return b?.createdAt.localeCompare(a?.createdAt)
        })
        setDisplayData(y);
      }
    }, [tickets]);
  
    const handleOpen = () => {
  
      setOpen(!open);
    };
    const handleCity = (x) => {
      setCity(x);
  
      if (displayData?.length === data.length) {
        setDisplayData(data.filter((x) => x.city === x));
      }
      // setDisplayData()
    };
  
    const handleAddSchool = () => {
      navigate("/superadmin/school");
    };
  
    useEffect(() => {
      if (resolvedFilter && unresolvedFilter) {
        setDisplayData(tickets);
        setPageNo(1)
      } else if (resolvedFilter) {
        setDisplayData(tickets?.filter((el) => el?.status === "resolved"));
        setPageNo(1)
      } else if (unresolvedFilter) {
        setDisplayData(tickets?.filter((el) => el?.status !== "resolved"));
        setPageNo(1)
      } else {
        setDisplayData(tickets);
        setPageNo(1)
      }
    }, [resolvedFilter, unresolvedFilter]);
    return (
      <div className={classes.main_div}>
        <div className={classes.student_layout}>
          <div>
            <BreadCrumbs />
          </div>
          <div className={classes.tabBtn_container}>
            {/* <button
              onClick={() => setChipModal(true)}
              className={classes.sharetypeform_btn}
            >
              <img src={typeformUpload} />
              <span>Share Typeform</span>
            </button> */}
            <TabButton role="Ticket" />
          </div>
        </div>
        {
          tickets?.length > 0 ?
            <>
              <div className={classes.student_filters}>
                <div className={classes.show_text}>Show</div>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rowsPerPage}
                    label="Rows"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                    size="small"
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
                {/* <CustomAutoComplete
                  options={allCities}
                  value={city}
                  placeholder={"City"}
                  onChange={setCity}
                /> */}
                <SearchBar id={"searchCy"} onchange={setText} value={text} />
                <Filter
                  label1={"Resolved"}
                  label2={"Unresolved"}
                  filter1={resolvedFilter}
                  filter2={unresolvedFilter}
                  setFilter1={setResolvedFilter}
                  setFilter2={setUnresolvedFilter}
                />
              </div>
              {/* Student Table Header */}
              <div className={classes.student_table_header}>
                <div>TOPIC</div>
                <div>ISSUE</div>
                <div>ISSUER's EMAIL</div>
                <div>ISSUER's PHONE</div>
                <div>STATUS</div>
                <div>CREATED AT</div>
                <div>UPDATED AT</div>
                <div style={{ justifySelf: "center" }}>ACTION</div>
              </div>
              {/* Student Column-Rows Section */}
              <div id="schoolRowCy" className={classes.student_column_container}>
                {Array.isArray(displayData) &&
                  displayData
                    ?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo)
                    .map((el, index) => {
                      return (
                        <TicketRow data={el} key={index + Date.now()} index={index} />
                      );
                    })}
              </div>
              <div className={classes.pagination_container}>
                <div className={classes.page_item}>{`Showing ${rowsPerPage * (pageNo - 1) + 1
                  } to ${pageNo * rowsPerPage <= displayData?.length
                    ? pageNo * rowsPerPage
                    : displayData?.length
                  } of ${displayData?.length} items`}</div>
                <div className={classes.pagination}>
                  <Button
                    size="small"
                    disabled={pageNo === 1}
                    onClick={() => setPageNo(pageNo - 1)}
                  >
                    <ChevronLeft />
                  </Button>
                  <span>{pageNo}</span>
                  <div>
                    <span>of</span>
                    <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
                  </div>
  
                  <div>
                    <Button
                      size="small"
                      disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)}
                      onClick={() => setPageNo(pageNo + 1)}
                    >
                      <ChevronRight />
                    </Button>
                  </div>
                </div>
              </div>
            </>
            :
            loading ?
              ""
              :
              <BlankDataScreen title="Oops! No data available" description="There is no data uploaded in this table to be shown. Click on Add Student to upload data." handleClick={() =>
                navigate('/superadmin-school/school', { state: { ...location.state, breadCrumbs: [...location.state.breadCrumbs, "Add School"] } })
              } buttonText="Add School" />
  
        }
  
        <ChipModal
          action={chipModal}
          setAction={setChipModal}
          title="Share Add School Typeform"
          subtitle="Please enter email id below to share “Add School” typeform to school representative."
          sendFunctionCall={shareTypeform}
          handleClick={handleClick}
          setOpen={setOpen}
        />
        {!loadingTypeform && (
          <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={!errorTypeform ? "success" : "error"}
              sx={{ width: "100%" }}
            >
              {!errorTypeform
                ? `Typeform Sent Successfully`
                : `Something Wrong! Try Again`}
            </Alert>
          </Snackbar>
        )}
      </div>
    );
  }
  

  export default TicketsPage;