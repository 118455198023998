import { Avatar } from "@mui/material";
import React, { useState } from "react";
import classes from "./TicketRow.module.css";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { useNavigate, useLocation, Link } from "react-router-dom";
import RowPopup from "../common/TableComponents/RowPopup";
import moment from "moment";
import { capitalizeFirstLetter, truncateString } from "../../utils/utility";

export default function TicketRow({ data, index }) {
  // const { id, schoolName, pincode, schoolCode, board, organization, eventDate, status, action, url, strength } = data;

  const [user, setUser] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  
  function getWordBeforeDash() {
    let newStr = "";
    for (let i = 1; i < location.pathname.length; i++) {
      if (location.pathname[i] === "-") {
        return newStr;
      }
      newStr += location.pathname[i];
    }
  }

  const getTicketInfo = (whereTo) => {
    if (getWordBeforeDash() === "support") {
      location.state.breadCrumbs.push(data?.topic);

      navigate(
        `/support-ticket/${whereTo}/${
          data?.ticketId
        }`,
        { state: { ...location.state, ...data } }
      );
    }
  };

  return (
    <>
      <div className={classes.studentRow_container}>
        {/* Name and Avatar Container */}
        {/* <div> */}
        {/* Avatar Container */}
        {/* {url ? (
            <div>
              <img src={url} alt="schoolLogo" width="32" height="32" />
            </div>
          ) : ( */}
        {/* <div>
            {!data?.url ? <Avatar {...stringAvatar(`${data?.schoolName} `)} /> : <img src={data?.url} style={{ borderRadius: 50 }} alt="schoolLogo" width="32" height="32" />}
          </div> */}
        {/* )} */}

        {/* Name Container */}
        {/* <div title={data?.ticketId}>
            <p>{data?.ticketId ? capitalizeFirstLetter(truncateString(data?.ticketId, 30)) : "Unavailable"}</p>
          </div> */}
        {/* </div> */}

        {/* Gender Container */}
        <div>
          <p>{data?.topic ? data?.topic : "Unavailable"}</p>
        </div>

        {/* Class Container */}
        <div>
          <p>
            {data?.issue ? data?.issue.length > 30 ? truncateString(data?.issue, 30) : data?.issue : "Unavailable"}
          </p>
        </div>

        <div>
          <p>
            {data?.email ? capitalizeFirstLetter(data?.email) : "Unavailable"}
          </p>
        </div>

        <div>
          <p>{data?.mobile ? data?.mobile : "Unavailable"}</p>
        </div>

        {/* Email Container */}

        {/* <Link to={getWordBeforeDash() === "partner" ? '/partner-school/table/student' : '/superadmin-school/table/student'} state={{ ...data, breadCrumbs: [...location.state.breadCrumbs, "Students"] }}>
          <p>{data?.strength ? data?.strength : "Unavailable"}</p>
        </Link> */}
        {/* Screening Container */}
        {/* <div>
          <p>{data?.isActive ? (data?.screeningTo ? moment(data?.screeningFrom).format("DD-MM-YYYY") : <ScheduleModal />) : ("-")}</p>
        </div> */}

        {/*  Validated Container */}
        {/* <div>
          <p>{data?.status ===  ? (data?.screeningFrom ? moment(data?.screeningTo).format("DD-MM-YYYY") : "") : "-"}</p>
        </div> */}

        {/* Conditional Buttons Rendering --Role Section */}
        {/* {index < 3 ? (
          <div>
            {user ? (<button className={classes.screened_btn}>INACTIVE</button>) :
              (<button onClick={handleReport} className={classes.report_btn}>SAVED</button>)
            }
          </div>
        ) : ( */}
        <div>
          {data?.status !== "resolved" ? (
            <div className={classes.screened_btn}>OPEN</div>
          ) : (
            <div className={classes.report_btn_active}>RESOLVED</div>
          )}
        </div>

        {/* )} */}

        <div>
          <p>
            {data?.createdAt
              ? new Date(data?.createdAt).toLocaleTimeString("en-IN", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : "Unavailable"}
          </p>
        </div>

        <div>
          <p>
            {data?.updatedAt
              ? new Date(data?.updatedAt).toLocaleTimeString("en-IN", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : "Unavailable"}
          </p>
        </div>

        {/* Action Container */}
        <div title="school info" style={{ cursor: 'pointer', position: "relative", justifySelf: "center" }}>
          <div className={classes.infoHover}  >
            <p>...</p>
            <div className={classes.popOnHover}>
              <RowPopup navLink={getTicketInfo} data={data} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
