import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./OpsManagerTable.module.css";
import OpsManagerRow from "./OpsManagerRow";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../../common/TableComponents/BreadCrumbs";
// import ShowPagesFilter from "../TableComponents/ShowPagesFilter";
import schoolReducer from "../../../../redux/reducers/school.reducer";
import TabButton from "../../../common/TableComponents/TabButton";
import SearchBar from '../../../common/TableComponents/SearchBar'
import { City } from "country-state-city";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CustomAutoComplete from "../../../common/CustomAutoComplete/CustomAutoComplete";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";



function OpsManagerPage() {
  const location = useLocation();
  const dispatch = useDispatch();
  const getSchools = schoolReducer.actions.getSchoolsRequest;
  const navigate = useNavigate()
  const breadCrumbs = [
    "Events"
  ]

  useEffect(() => {
    dispatch(getSchools())
    location.state = { breadCrumbs: breadCrumbs }
  }, [])
  const data = useSelector((state) => state.schoolReducer.data)

  function getWordBeforeDash() {
    let newStr = "";
    for (let i = 1; i < location.pathname.length; i++) {
      if (location.pathname[i] === "-") {
        return newStr;
      }
      newStr += location.pathname[i];
    }
  }

  const allCities = [...new Set(City.getCitiesOfCountry("IN").map((x) => x.name))]

  const [open, setOpen] = useState(false);
  const [sec, setSec] = useState(false);
  const [text, setText] = useState("");
  const [displayData, setDisplayData] = useState(data ?? []);
  const [city, setCity] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageNo, setPageNo] = useState(1)

  useEffect(() => {
    if (city && text) {
      setDisplayData(data.filter((x) => x.city === city && x.schoolName.toLowerCase().includes(text.toLowerCase())))
    }
    else if (city) {
      setDisplayData(data.filter((x) => x.city === city))
    }
    else if (text) {
      setDisplayData(data.filter((x) => x.schoolName.toLowerCase().includes(text.toLowerCase())))
    }
    else {
      setDisplayData(data)
    }
  }, [city, text])

  useEffect(() => {
    setDisplayData(data)
  }, [data])

  const handleOpen = () => {

    setOpen(!open);
  };
  const handleCity = (x) => {
    setCity(x)

    if (displayData?.length === data.length) {
      setDisplayData(data.filter((x) => x.city === x))
    }
    // setDisplayData()
  };

  const handleAddSchool = () => {
    navigate('/superadmin/school')
  }

  return (
    <div className={classes.main_div}>
      <div className={classes.student_layout}>
        <div>
          <BreadCrumbs />
        </div>
      </div>
      <div className={classes.student_filters}>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Rows</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={rowsPerPage}
            label="Rows"
            onChange={(e) => setRowsPerPage(e.target.value)}
            size='small'
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
        <SearchBar id={"searchCy"} onchange={setText} value={text} />
      </div>
      {/* Student Table Header */}
      <div className={classes.student_table_header}>
        <div>SCHOOL NAME</div>
        <div>PINCODE</div>
        <div>SCHOOL CODE</div>
        <div>STUDENTS</div>
        <div>START DATE</div>
        <div>END DATE</div>
        <div>STATUS</div>
        <div style={{ justifySelf: "center" }}>ACTION</div>
      </div>
      {/* Student Column-Rows Section */}
      <div id="schoolRowCy" className={classes.student_column_container}>
        {displayData?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo).map((el, index) => {
          return (
            <OpsManagerRow data={el} key={index + Date.now()} index={index} />
          )
        })}
      </div>
      <div className={classes.pagination_container}>
        <div className={classes.page_item}>{`Showing ${(rowsPerPage * (pageNo - 1)) + 1} to ${pageNo * rowsPerPage <= displayData?.length ? pageNo * rowsPerPage : displayData?.length} of ${displayData?.length} items`}</div>
        <div className={classes.pagination}>
          <Button size="small" disabled={pageNo === 1} onClick={() => setPageNo(pageNo - 1)} >
            <ChevronLeft />
          </Button>
          <span>{pageNo}</span>
          <div>
            <span>of</span>
            <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
          </div>

          <div>
            <Button size="small" disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)} onClick={() => setPageNo(pageNo + 1)} >
              <ChevronRight />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function OpsManagerDashboard() {

  const dispatch = useDispatch();

  const getSchools = schoolReducer.actions.getSchoolsRequest;

  useEffect(() => {
    dispatch(getSchools());
  }, [])

  const data = useSelector((state) => state.schoolReducer.data)

  return (
    <OpsManagerPage data={data} />
  );
}
export default OpsManagerDashboard;