import React, { useEffect, useState } from "react";
import "./HelpSupport.css";
import DashboardLayout from "../common/DashboardLayout/DashboardLayout";
import ReportDetails from "../Report/ReportDetails";
import DownArrow from "../../assets/images/down-arrow.svg";
import NotificationClose from "../../assets/images/notification-close.svg";
import AttachFile from "../../assets/images/attach-a-file.svg";
import AccordionCard from "./AccordionCard";
// import CustomSelectTag from "./CustomSelectTag";
import axios from "axios";
import InputDropdown from "../common/InputDashboard/InputDropdown";
import InputDashboard from "../common/InputDashboard/InputDashboard";
import support from "../../graphqlApiServices/support";
import { useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";
import DatePickerInput from "../common/InputDashboard/DatePickerInput";

const FAQs = [
  {
    title: "How do I reset my password?",
    content:
      'To reset your password, click on the "Forgot Password" link on the login page and follow the instructions. You will receive an email with a link to reset your password. If you do not receive the email, check your spam folder or contact support.',
  },
  {
    title: "Can I add multiple users to the dashboard?",
    content:
      "Yes, administrators can add multiple users to the dashboard. Go to the 'User Management' section under settings, and click 'Add User.' Enter the required details and assign appropriate roles and permissions.",
  },
  {
    title: "Why is the dashboard not displaying the latest data?",
    content:
      "If the dashboard is not displaying the latest data, try refreshing the page or clearing your browser cache. If the issue persists, check if there are any scheduled maintenance activities or contact support for assistance.",
  },
];

const topics = [
  "Login Related",
  "Account Related",
  "Report Related",
  "Screening Related",
  "Others",
];

function HelpSupportPage() {
  const navigate = useNavigate();
  const [type, setType] = useState("");
  const [file, setFile] = useState("");
  const [activeBtn, setActiveBtn] = useState("All");
  const [topic, setTopic] = useState("");
  const [dateOfIssue, setDateOfIssue] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [issue, setIssue] = useState("");
  const [open, setOpen] = useState({
    state: false,
    message: "",
    toast: "success",
  });
  const user = useSelector((state) => state.userReducer.currentUser);

  const { createSupportTicket } = support;

  useEffect(() => {
    if (user) {
      setEmail(user?.email);
    }
  }, [user]);

  const resetState = () => {
    setType("");
    setFile("");
    setTopic("");
    setMobile("");
    setIssue("");
  }

  function uploadFile(formData) {
    // const slackApiUrl = process.env.REACT_APP_SLACK_API_URL;
    // const headers = {
    //   Authorization: `Bearer ${process.env.REACT_APP_SLACK_BOT_TOKEN}`,
    //   "Content-Type": "multipart/form-data",
    // };
    // const url = process.env.REACT_APP_SLACK_API_URL;
    // const params = {
    //   headers: headers,
    //   method: "post",
    //   url: url,
    //   data: formData,
    //   params: {
    //     channels: process.env.REACT_APP_SLACK_CHANNEL,
    //   },
    // };
    // return axios(params);
  }

  const toBase64 = () =>
    new Promise((resolve, reject) => {
      const readers = new FileReader();
      readers.readAsArrayBuffer(file);
      readers.onload = () => {
        resolve(Buffer.from(readers.result).toJSON());
      };
      readers.onerror = (error) => reject(error);
    });

  const handleSubmit = async () => {
    try {
      if (!user) return;
      let image;
      if (file) {
        image = await toBase64();
      }
      const payload = {
        userId: user?.userId,
        email: email,
        mobile: mobile,
        issue: issue,
        topic: topic,
        dateOfIssue: dateOfIssue,
        issueImage: image && JSON.stringify(image),
      };
      const res = await createSupportTicket(payload);
      if (res.errors) return;
      setOpen((prev) => {
        return {
          ...prev,
          message: "Ticket created successfully!",
          toast: "success",
          state: true,
        };
      });
      resetState();
    } catch (error) {
      console.log(error, "error");
      setOpen((prev) => {
        return {
          ...prev,
          message: "Something went wrong!",
          toast: "error",
          state: true,
        };
      });
    }
  };

  const handleonchange = (e) => {
    e.preventDefault();

    setFile(e.target.files[0]);
    e.target.value = "";
  };

  const deleteFile = (e) => {
    setFile("");
    e.target.value = "";
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFile(e.dataTransfer.files[0]);
  };

  const handleClose = () => {
    setOpen(() => {
      return {
        message: "",
        toast: "error",
        state: false,
      };
    });
  };

  return (
    <>
      <div className="container">
        <p>Need any help? Write to us..</p>
        <div className="topic-container">
          {/* First Column */}
          <div>
            {/* Button Filter */}
            <section className="btn_filter--container">
              <button
                onClick={() => setActiveBtn("All")}
                className={`${activeBtn === "All" ? "active_btn" : ""}`}
              >
                All
              </button>
              <button
                onClick={() => setActiveBtn("Topics")}
                className={`${activeBtn === "Topics" ? "active_btn" : ""}`}
              >
                Topics
              </button>
            </section>

            {/* Reusable Component - Accordion Card */}
            <section>
              {FAQs.map((faq, index) => (
                <section key={index}>
                  <AccordionCard accordionData={faq} />
                </section>
              ))}
            </section>
          </div>

          {/* Second  Column*/}
          <div>
            <section>
              <p>Select Topic</p>
            </section>

            <section>
             
            </section>

            <section>
            <InputDropdown
                placeholder={"Issue Type"}
                width={"18vw"}
                margin={2.5}
                sx={{ height: "64px !important" }}
                defaultValues={topics}
                value={topic}
                setValue={setTopic}
                required={true}
              />
              <DatePickerInput
                onChange={(e) => {
                  setDateOfIssue(e.target.value);
                }}
                // margin={true}
                futureDate={true}
                width={"18vw"}
                placeholder={"Date of Issue"}
                value={dateOfIssue}
              />
              <InputDashboard
                placeholder={`Enter email id`}
                width={"18vw"}
                margin={3.8}
                bgColor="#ffffff"
                disabled={true}
                change={(e) => setEmail(e.target.value)}
                value={email}
              />

              <InputDashboard
                placeholder={`Enter phone number`}
                width={"18vw"}
                type={"number"}
                margin={3.8}
                bgColor="#ffffff"
                change={(e) => setMobile(e.target.value)}
                value={mobile}
              />

            </section>
              <section>
              <InputDashboard
                placeholder={`Type description here`}
                width={"100%"}
                height={"150px"}
                margin={2.4}
                multiline={true}
                bgColor="#ffffff"
                change={(e) => setIssue(e.target.value)}
                value={issue}
              />
              </section>
            <section style={{margin: "20px 0", display: "flex", alignItems: "center", gap: "10px"}}>
              <img src={AttachFile} alt="attach-a-file" />
              <div onDragOver={handleDragOver} onDrop={handleDrop} style={{display: "flex", alignItems: "center"}}>
                <input
                  type="file"
                  onChange={handleonchange}
                  accept="video/*,image/*"
                />
              </div>
            </section>
            {file && (
              <section className="uploadedStatus">
                <div>{file?.name}</div>
                <button onClick={deleteFile}>
                  <img src={NotificationClose} />
                </button>
              </section>
            )}
            <section className="confirmBtn">
              <button
                onClick={handleSubmit}
                disabled={
                  !topic.length ||
                  !mobile.length ||
                  !email.length ||
                  !issue.length
                }
              >
                Send
              </button>
              <button onClick={() => navigate("/")}>Cancel</button>
            </section>
          </div>
        </div>
      </div>
      {
        <Snackbar
          open={open.state}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={open.toast}
            sx={{ width: "100%" }}
          >
            {open.message}
          </Alert>
        </Snackbar>
      }
    </>
  );
}

// function HelpSupport() {
//   return (
//     <>
//     {HelpSupportPage}
//     </>
//   );
// }
export default HelpSupportPage;
