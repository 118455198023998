import { Box, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import screeningReducer from '../../../redux/reducers/screening.reducer';
import InputDashboard from '../InputDashboard/InputDashboard';
import screening from '../../../graphqlApiServices/screening';
import { useLocation } from 'react-router-dom';
import studentReducer from '../../../redux/reducers/student.reducer';

function BPModal({open, handleClose}) {

    const dispatch = useDispatch()
    const location = useLocation()

    const updateStudent = studentReducer.actions.updateStudentStatusRequest;
    const studentScreening = screeningReducer.actions.getScreeningByStudentIdRequest;

    const { updateSuperStatus } = screening

    const testData = useSelector((state) => state.screeningReducer.data);

    const [bp, setBp] = useState("")

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleUpdateStatus = () => {
        console.log(testData)
        const params =  {
            vitalSignsAssessment : {
                SpO2: testData?.vitalSignsAssessment?.SpO2,
                bloodPressure: bp,
                heartRate: testData?.vitalSignsAssessment?.heartRate,
                height: testData?.vitalSignsAssessment?.height,
                hemoglobin: testData?.vitalSignsAssessment?.hemoglobin,
                temperature: testData?.vitalSignsAssessment?.temperature,
                weight: testData?.vitalSignsAssessment?.weight,
            },
            screeningId: testData?.screeningId,
            eventId: testData?.eventId,
            status: "SCREENING_DONE"
        }
        console.log(params)
        updateSuperStatus(params).then((res)=>{
            console.log(res)
            console.log({ studentId: location?.state?.studentId, schoolId: location?.state?.schoolId, status: "SCREENING_DONE" })
            dispatch(updateStudent({ studentId: location?.state?.studentId, schoolId: location?.state?.schoolId, status: "SCREENING_DONE" }))
            dispatch(studentScreening(location.state?.studentId))
            handleClose()
        }).catch((err)=>{
            console.log(err)
        })
        
    }

  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter Blood Pressure
            </Typography>
            <InputDashboard
                placeholder={`BP (High/Low)`}
                width={"100%"}
                // className={classes.input_layout_schoolname}
                change={(e) => {
                    setBp(e.target.value);
                }}
                margin={2}
                bgColor="#ffffff"
                value={bp}
            />
            <button
                style={{ backgroundColor: "#1740A1", color: "white", display: "flex", alignItems: "center", padding: "8px 10px", borderRadius: 8, border: "none", fontSize:15, margin: "auto" }}
                disabled={bp?.length<=0}
                onClick={handleUpdateStatus}
            >
                Update Screening
            </button>
        </Box>
        </Modal>
  )
}

export default BPModal