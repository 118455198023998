import React from 'react'
import sorting from '../../../assets/images/sorting.svg'

export const Sort = () => {

    const sort_btn = {
        background : "none",
        border : "none",
        display : "flex",
        gap : "11px",
        padding : "7px 0 0 0",
        cursor : "pointer"
    }

    const sort_text = {
        fontWeight: "400",
        fontSize : "12px",
        padding : "3px 0 0 0",
        lineHeight: "16px",
        color: "#001028",
        
    }

  return (
    <>
        <button style={sort_btn}>
            <img src = {sorting}/>
            <p style={sort_text}>Sort</p>
        </button>
    </>
  )
}
