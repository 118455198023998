import React from "react";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";
import classes from "./NurseHealthHistory.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Avatar } from "@mui/material";
import heartrate from "../../assets/images/heartrate.png";
import thermometer from "../../assets/images/thermometer.svg";
import doctordiagnosis from "../../assets/images/doctordiagnosis.svg";
import addcircleblue from "../../assets/images/addcircleblue.svg";
import InfoIcon from "@mui/icons-material/Info";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import userReducer from "../../redux/reducers/user.reducer";
import moment from "moment";
import height from "../../assets/images/height.svg";
import weight from "../../assets/images/weight-scale.svg";
import oxygen from "../../assets/images/Oxygen.png";
import Bpmachine from "../../assets/images/Bpmachine.png";
import bmi from '../../assets/images/meter.svg'

export const NurseHealthHistory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const studentHeaderData = location?.state?.data;
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  
  
  // 
  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)} `.slice(-2);
    }
    return color;
  }

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 12,
        height: 12,
        padding: 2,
      },
      children: `${name[0]} `,
    };
  }

  const handleAddLog = () => {
    navigate("/infirmary/nurse/student/addlog", {
      state: {
        data: [location.state.data],
        breadCrumbs: location.state
          ? [...location.state?.breadCrumbs, "Add Log"]
          : ["Add Log"],
      },
    });
  };
  const student_description_boxes = [
    {
      label: "Age",
      value: getAge(studentHeaderData.DOB) + " yrs",
    },
    {
      label: "Gender",
      value: studentHeaderData.gender,
    },
    {
      label: "Class",
      value: `${studentHeaderData.class} ${studentHeaderData.section}`,
    },
    {
      label: "Class Teacher",
      value: "-",
    },
    {
      label: "Parents Details",
      value: `${
        studentHeaderData?.fatherName ? studentHeaderData?.fatherName : "-"
      }`,
    },
    {
      label: "Parents Phone",
      value: `${
        studentHeaderData?.fatherContact?.mobile
          ? studentHeaderData?.fatherContact?.mobile
          : "-"
      }`,
    },
  ];

  const infirmary_card_description = [
    {
      label: "Date",
      value: "",
    },
    {
      label: "Time",
      value: "",
    },
    {
      label: "Infirmary Staff",
      value: `${currentUser?.given_name} ${currentUser?.family_name}`,
    },
    {
      label: "Situation",
      value: `${studentHeaderData?.logs[0]?.situation}`,
    },
  ];
  return (
    <>
      <div className={classes.nurse_student_layout}>
        <div>
          <BreadCrumbs />
        </div>
        <div className={classes.log_screening_container}>
          <button
            className={classes.log_screening_btn}
            onClick={() => handleAddLog()}
          >
            <img src={addcircleblue} />
            <span>Add log</span>
          </button>

          <button
            onClick={() =>
              navigate("/infirmary/nurse/student/screening", {
                state: { ...studentHeaderData },
              })
            }
            className={classes.log_screening_btn}
          >
            <VisibilityIcon
              sx={{
                fill: "#1740A1",
                backgroundColor: "#FFF",
                borderRadius: "50%",
                width: "24px",
                height: "24px",
                padding: "3px",
              }}
            />
            <span>Screening</span>
          </button>
        </div>
      </div>

      <div className={classes.student_info_container}>
        <div className={classes.avatar_container}>
          <div>
            <Avatar
              {...stringAvatar(
                `${studentHeaderData.firstName} ${studentHeaderData.lastName}`
              )}
              sx={{
                height: "64px",
                width: "64px",
                fontSize: "40px",
                fontWeight: "600",
                fontFamily: "Inter",
              }}
            />
          </div>
          <div>
            <p>{`${studentHeaderData.firstName} ${studentHeaderData.lastName}`}</p>
          </div>
        </div>

        <div className={classes.student_description_container}>
          {student_description_boxes.map((el) => {
            return (
              <div className={classes.description_boxes}>
                <p>{el.label}</p>
                <p>{el.value}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.infirmary_info_container}>
        <div className={classes.infirmary_info_header}>
          Infirmary Visit History
        </div>
        {studentHeaderData?.logs?.map((reportData) => {
          return (
            <>
              <div className={classes.infirmary_card}>
                <div className={classes.infirmary_card_description}>
                  <div>
                    <p>Date</p>
                    <p>{moment(reportData?.createdAt).format("DD-MM-YYYY")}</p>
                  </div>
                  <div>
                    <p>Time</p>
                    <p>{moment(reportData?.createdAt).format("hh:mm A")}</p>
                  </div>
                  <div>
                    <p>Infirmary Staff</p>
                    <p>{`${currentUser?.given_name} ${currentUser?.family_name}`}</p>
                  </div>
                  <div>
                    <p>Situation</p>
                    <p>{`${reportData?.situation}`}</p>
                  </div>
                </div>

                <div className={classes.vitals_container_main}>
                  <div className={classes.vitals_header}>Vital Signs</div>

                  <div className={classes.vitals_box_container}>
                    {reportData.temperature && (
                      <div className={classes.vitals_box}>
                        <img src={thermometer} alt="temperature" />
                        <p>
                          {reportData?.temperature}&deg; <span>C</span>
                        </p>
                        <div>
                          <p>Tempereture</p>
                          <div className={classes.historyHover}>
                            <InfoIcon
                              sx={{ fontSize: "17px", fill: "#66707E" }}
                            />
                            <div className={classes.popOnHover}>
                              <p>Temperature</p>
                              <p>Normal Range Of Temperature</p>
                              <p>5-12yrs</p>
                              <p>37°C (98.6°F)</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {reportData.heartRate && (
                      <div className={classes.vitals_box}>
                        <img src={heartrate} alt="heartrate" />
                        <p>
                          {reportData?.heartRate}
                          <span> BPM</span>
                        </p>
                        <div>
                          <p>Heart Rate</p>
                          <div className={classes.historyHover}>
                            <InfoIcon
                              sx={{ fontSize: "17px", fill: "#66707E" }}
                            />
                            <div className={classes.popOnHover}>
                              <p>Heart Rate</p>
                              <p>Normal Range Of Heart Rate</p>
                              <p>5-12yrs</p>
                              <p>70-120 bpm</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {reportData.height && (
                      <div className={classes.vitals_box}>
                        <img src={height} alt="height" />
                        <p>
                          {reportData?.height}
                          <span> cms</span>
                        </p>
                        <div>
                          <p>Height</p>
                          <div className={classes.historyHover}>
                            <InfoIcon
                              sx={{ fontSize: "17px", fill: "#66707E" }}
                            />
                            <div className={classes.popOnHover}>
                              <p>Height</p>
                              <p>Normal Range Of Height</p>
                              <p>5-12yrs</p>
                              <p>100-168cm</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {reportData.weight && (
                      <div className={classes.vitals_box}>
                        <img src={weight} alt="weight" />
                        <p>
                          {reportData?.weight}
                          <span> kgs</span>
                        </p>
                        <div>
                          <p>Weight</p>
                          <div className={classes.historyHover}>
                            <InfoIcon
                              sx={{ fontSize: "17px", fill: "#66707E" }}
                            />
                            <div className={classes.popOnHover}>
                              <p>Weight</p>
                              <p>Normal Range Of Weight</p>
                              <p>5-12yrs</p>
                              <p>20-50kg</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {reportData.weight && reportData.height && (
                      <div className={classes.vitals_box}>
                        <img src={bmi} alt="bmi" className= {classes.bmi_index}/>
                        <p>
                          {(reportData?.weight/ ((reportData?.height/100)**2)).toFixed(1)}
                          <span>
                             kg/m<sup>2</sup>
                          </span>
                        </p>
                        <div>
                          <p>BMI</p>
                          <div className={classes.historyHover}>
                            <InfoIcon
                              sx={{ fontSize: "17px", fill: "#66707E" }}
                            />
                            <div className={classes.popOnHover}>
                              <p>BMI</p>
                              <p>Normal Range Of BMI</p>
                              <p>5-12yrs</p>
                              <p>18.5-24.9</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {reportData.bloodPressure && (
                      <div className={classes.vitals_box}>
                        <img src={Bpmachine} alt="weight" />
                        <p>{reportData?.bloodPressure}<span>mm/Hg</span></p>
                        <div>
                          <p>Blood Pressure</p>
                          <div className={classes.historyHover}>
                            <InfoIcon
                              sx={{ fontSize: "17px", fill: "#66707E" }}
                            />
                            <div className={classes.popOnHover}>
                              <p>Weight</p>
                              <p>Normal Range Of Weight</p>
                              <p>5-12yrs</p>
                              <p>110/70mm/Hg</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {reportData.SpO2 && (
                      <div className={classes.vitals_box}>
                        <img src={oxygen} alt="weight" />
                        <p>
                          {reportData?.SpO2}
                          <span>%</span>
                        </p>
                        <div>
                          <p>SpO2</p>
                          <div className={classes.historyHover}>
                            <InfoIcon
                              sx={{ fontSize: "17px", fill: "#66707E" }}
                            />
                            <div className={classes.popOnHover}>
                              <p>Weight</p>
                              <p>Normal Range Of SpO2</p>
                              <p>5-12yrs</p>
                              <p>95-100</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className={classes.problem_text}>Problem</div>
                <div className={classes.problem_answer_text}>
                  {reportData.problemFaced}.{" "}
                </div>
                <div className={classes.doctor_diagnosis_container}>
                  <div>
                    <img src={doctordiagnosis} />
                  </div>
                  <div>
                    <p>Doctor Diagnosis</p>
                    <p>{reportData.diagnosis}. </p>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
