import * as React from "react";
import "../Main.css";

// const Throat = (props) => {
//   const { active, issue, onClick, disabled, top, right, scale } = props;
//   return disabled ? (
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 14 14"
//       className={`mainCon`}
//       style={{
//         top: top,
//         right: right,
//         transform: `scale(${scale})`,
//       }}
//       fill="none"
//       {...props}
//     >
//       <g>
//         <circle cx="7" cy="7" r="7"  fill="#B2B7BE" opacity="0.17" />
//       </g>
//       <path
//         d="M4.26238 11V9.28C3.86262 8.93333 3.55229 8.52833 3.33137 8.065C3.11046 7.60167 3 7.11333 3 6.6C3 5.6 3.36819 4.75 4.10458 4.05C4.84097 3.35 5.73515 3 6.78713 3C7.66378 3 8.44049 3.245 9.11726 3.735C9.79404 4.225 10.2341 4.86333 10.4375 5.65L10.9845 7.7C11.0196 7.82667 10.995 7.94167 10.9109 8.045C10.8267 8.14833 10.7145 8.2 10.5743 8.2H9.73267V9.4C9.73267 9.62 9.65027 9.80833 9.48546 9.965C9.32065 10.1217 9.12252 10.2 8.89109 10.2H8.0495V11H7.20792V9.4H8.89109V7.4H10.0272L9.62747 5.85C9.46617 5.24333 9.12252 4.75 8.59653 4.37C8.07054 3.99 7.46741 3.8 6.78713 3.8C5.9736 3.8 5.27929 4.07 4.70421 4.61C4.12913 5.15 3.84158 5.80667 3.84158 6.58C3.84158 6.98 3.9275 7.36 4.09932 7.72C4.27114 8.08 4.51485 8.4 4.83045 8.68L5.10396 8.92V11H4.26238ZM5.56683 11H6.40842L6.67141 8.6H8.0495V7.8H6.75557L6.80817 7.36C6.82219 7.26 6.86778 7.175 6.94493 7.105C7.02207 7.035 7.11324 7 7.21844 7H8.89109V6.2H7.22896C6.89934 6.2 6.61531 6.30333 6.37686 6.51C6.13841 6.71667 6.00165 6.97333 5.96658 7.28L5.56683 11Z"
//         fill="#B2B7BE"
//       />
//       <defs />
//     </svg>
//   ) : (
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 14 14"
//       fill="none"
//       className={`mainCon  ${active && (issue ? "activeRed" : "activeGreen")}`}
//       onClick={onClick}
//       style={{
//         top: top,
//         right: right,
//         transform: `scale(${scale})`,
//       }}
//       {...props}
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <g>
//         <circle cx="7" cy="7" r="7" fill={issue ? "#f0c5c5" : "#CAEFCF"} />
//       </g>
//       <path
//         d="M4.26238 11V9.28C3.86262 8.93333 3.55229 8.52833 3.33137 8.065C3.11046 7.60167 3 7.11333 3 6.6C3 5.6 3.36819 4.75 4.10458 4.05C4.84097 3.35 5.73515 3 6.78713 3C7.66378 3 8.44049 3.245 9.11726 3.735C9.79404 4.225 10.2341 4.86333 10.4375 5.65L10.9845 7.7C11.0196 7.82667 10.995 7.94167 10.9109 8.045C10.8267 8.14833 10.7145 8.2 10.5743 8.2H9.73267V9.4C9.73267 9.62 9.65027 9.80833 9.48546 9.965C9.32065 10.1217 9.12252 10.2 8.89109 10.2H8.0495V11H7.20792V9.4H8.89109V7.4H10.0272L9.62747 5.85C9.46617 5.24333 9.12252 4.75 8.59653 4.37C8.07054 3.99 7.46741 3.8 6.78713 3.8C5.9736 3.8 5.27929 4.07 4.70421 4.61C4.12913 5.15 3.84158 5.80667 3.84158 6.58C3.84158 6.98 3.9275 7.36 4.09932 7.72C4.27114 8.08 4.51485 8.4 4.83045 8.68L5.10396 8.92V11H4.26238ZM5.56683 11H6.40842L6.67141 8.6H8.0495V7.8H6.75557L6.80817 7.36C6.82219 7.26 6.86778 7.175 6.94493 7.105C7.02207 7.035 7.11324 7 7.21844 7H8.89109V6.2H7.22896C6.89934 6.2 6.61531 6.30333 6.37686 6.51C6.13841 6.71667 6.00165 6.97333 5.96658 7.28L5.56683 11Z"
//         fill="#1C1B1F"
//       />
//       <defs />
//     </svg>
//   );
// };
// export default Throat;


const Throat = (props) => {
  const { active, issue, onClick, disabled, top, right, scale } = props;
  return (
      disabled
          ?
          <div
              className={`mainCon`}
              style={{
                  top: top,
                  right: right,
                  transform: `scale(${scale})`
              }}>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  fill="none"
                  {...props}
              >
                  <circle cx={12} cy={12} r={12}
                      fill="#B2B7BE"
                      opacity="0.16" />
                  <path
                      fill="#B2B7BE"
                      d="M8.358 18a.47.47 0 0 1-.338-.128.427.427 0 0 1-.133-.322v-2.13a5.56 5.56 0 0 1-1.392-1.822A5.06 5.06 0 0 1 6 11.4c0-1.5.55-2.775 1.65-3.825C8.752 6.525 10.089 6 11.66 6c1.31 0 2.471.367 3.483 1.103 1.011.735 1.669 1.692 1.973 2.872l.865 3.27a.393.393 0 0 1-.079.383.463.463 0 0 1-.377.172h-1.462v2.1c0 .25-.092.463-.276.638a.932.932 0 0 1-.668.262h-1.572v.75c0 .13-.045.238-.134.322a.47.47 0 0 1-.338.128h-2.138l.409-3.75h1.572a.47.47 0 0 0 .338-.127.427.427 0 0 0 .134-.323.427.427 0 0 0-.134-.323.47.47 0 0 0-.338-.127H11.44l.094-.825c.021-.19.105-.35.252-.48a.757.757 0 0 1 .519-.195h1.87a.47.47 0 0 0 .339-.127.427.427 0 0 0 .134-.323.427.427 0 0 0-.134-.322.47.47 0 0 0-.338-.128H12.32c-.45 0-.838.143-1.163.428a1.635 1.635 0 0 0-.566 1.057L9.994 18H8.358Z"
                  />
              </svg>
          </div>
          :
          <div
              className={`mainCon  ${active && (issue ? "activeRed" : "activeGreen")}`}
              onClick={onClick}
              style={{
                  top: top,
                  right: right,
                  backgroundColor: active ? "#ffffff" : "",
                  transform: `scale(${scale})`
              }}>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  fill="none"
                  {...props}
              >
                  <circle cx={12} cy={12} r={12}
                      fill={active ? "#ffffff" : issue ? "#f0c5c5" : "#CAEFCF"}  />
                  <path
                      fill="#115EA3"
                      d="M8.358 18a.47.47 0 0 1-.338-.128.427.427 0 0 1-.133-.322v-2.13a5.56 5.56 0 0 1-1.392-1.822A5.06 5.06 0 0 1 6 11.4c0-1.5.55-2.775 1.65-3.825C8.752 6.525 10.089 6 11.66 6c1.31 0 2.471.367 3.483 1.103 1.011.735 1.669 1.692 1.973 2.872l.865 3.27a.393.393 0 0 1-.079.383.463.463 0 0 1-.377.172h-1.462v2.1c0 .25-.092.463-.276.638a.932.932 0 0 1-.668.262h-1.572v.75c0 .13-.045.238-.134.322a.47.47 0 0 1-.338.128h-2.138l.409-3.75h1.572a.47.47 0 0 0 .338-.127.427.427 0 0 0 .134-.323.427.427 0 0 0-.134-.323.47.47 0 0 0-.338-.127H11.44l.094-.825c.021-.19.105-.35.252-.48a.757.757 0 0 1 .519-.195h1.87a.47.47 0 0 0 .339-.127.427.427 0 0 0 .134-.323.427.427 0 0 0-.134-.322.47.47 0 0 0-.338-.128H12.32c-.45 0-.838.143-1.163.428a1.635 1.635 0 0 0-.566 1.057L9.994 18H8.358Z"
                  />
              </svg>
          </div>
  )
}
export default Throat