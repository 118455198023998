import { call, put, takeLatest } from "redux-saga/effects";
import ApiServices from "../../graphqlApiServices";
import userReducer from "../reducers/user.reducer";

const { user } = ApiServices

const {
    getUsers,
    createUser,
    getUsersByGroups,
    getUserById,
    getUserListForSuperadmin,
    getDoctorsBySpeciality,
    getDoctors,
    getAllClinicDoctors,
    getAllClinicNurses,
    updateUser,
    createSchoolInfirmary,
    getSchoolInfirmary,
    bulkTeacherUpload,
    getUserByEmail
} = user

export function* watchUserRequests() {
    yield takeLatest(userReducer.actions.getAllUsersRequest, getUsersSaga);
    yield takeLatest(userReducer.actions.createUserRequest, createUserSaga);
    yield takeLatest(userReducer.actions.getUserByGroupRequest, getUserbyGroupsSaga);
    yield takeLatest(userReducer.actions.getUserByIdRequest, getUserByIdSaga);
    yield takeLatest(userReducer.actions.getUserListforSuperadminRequest, getUserListForSuperadminSaga)
    yield takeLatest(userReducer.actions.getDoctorsBySpecialityRequest, getDoctosBySpeacialitySaga)
    yield takeLatest(userReducer.actions.getDoctorsRequest, getDoctorsSaga)
    yield takeLatest(userReducer.actions.getDoctorsByClinicRequest, getDoctorsByClinicSaga)
    yield takeLatest(userReducer.actions.getNursesByClinicRequest, getNursesByClinicSaga)
    yield takeLatest(userReducer.actions.updateUserRequest, updateUserSaga)
    yield takeLatest(userReducer.actions.createSchoolInfirmaryRequest, createSchoolInfirmarySaga)
    yield takeLatest(userReducer.actions.getSchoolInfirmaryRequest, getSchoolInfirmarySaga)
    yield takeLatest(userReducer.actions.bulkUploadTeacherRequest, bulkUploadTeacherSaga)
    yield takeLatest(userReducer.actions.getUserByEmailReq, getUserByEmail)
}

export function* getUsersSaga(payload) {
    try {
        const response = yield call(getUsers, payload);
        const data = response?.data.getUsers
        yield put(userReducer.actions.getAllUsersSuccess(data))
    } catch (error) {
        if (error?.data.getUsers.length > 0) {
            const data = error?.data.getUsers
            yield put(userReducer.actions.getAllUsersSuccess(data))
        }
        else {
            yield put(userReducer.actions.getAllUsersFail({ error: error.errors }))
        }
    }
}

export function* createUserSaga(payload) {
    try {
        const userResponse = yield call(getUserByEmail, payload.payload.email)
        
        if(!userResponse?.data?.getUserByEmail?.userId){
            const response = yield call(createUser, payload);
            const data = response?.data
            yield put(userReducer.actions.createUserSuccess(data))
            setTimeout(() => window.history.back(), 1000)
        }
        else {
            yield put(userReducer.actions.getUserByEmailFail({}))
        }
    } catch (error) {
        if (error?.data?.createUser?.length > 0) {
            const data = error?.data.getUsers
            yield put(userReducer.actions.createUserSuccess(data))
            setTimeout(() => window.history.back(), 1000)
        }
        else {
            yield put(userReducer.actions.createUserFail({ error: error.errors }))
        }
    }
}

export function* getUserbyGroupsSaga(payload) {
    try {
        const response = yield call(getUsersByGroups, payload);
        const data = response?.data?.getUsersByGroups
        yield put(userReducer.actions.getAllUsersSuccess(data))
    } catch (error) {
        if (error?.data?.getUsersByGroups?.length > 0) {
            const data = error?.data.getUsersByGroups
            yield put(userReducer.actions.getAllUsersSuccess(data))
        }
        else {
            yield put(userReducer.actions.getAllUsersFail({ error: error.errors }))
        }
    }
}

export function* getUserByIdSaga(payload) {
    try {
        const response = yield call(getUserById, payload);
        const data = response?.data?.getUserById
        yield put(userReducer.actions.getUserByIdSuccess(data))
    } catch (error) {
        if (error?.data?.getUserById?.length > 0) {
            const data = error?.data.getUserById
            yield put(userReducer.actions.getUserByIdSuccess(data))
        }
        else {
            yield put(userReducer.actions.getUserByIdFail({ error: error?.errors }))
        }
    }
}

export function* getUserListForSuperadminSaga(payload) {
    try {
        const response = yield call(getUserListForSuperadmin, payload);
        const data = response?.data?.getUsersListSuperAdmin
        yield put(userReducer.actions.getUserListforSuperadminSuccess(data))
    } catch (error) {
        if (error?.data?.getUsersListSuperAdmin?.length > 0) {
            const data = error?.data.getUsersListSuperAdmin
            yield put(userReducer.actions.getUserListforSuperadminSuccess(data))
        }
        else {
            yield put(userReducer.actions.getUserListforSuperadminFail({ error: error?.errors }))
        }
    }
}

export function* getDoctosBySpeacialitySaga(payload) {
    try {
        const response = yield call(getDoctorsBySpeciality, payload);
        const data = response?.data?.getDoctorsBySpeciality
        yield put(userReducer.actions.getDoctorsBySpecialitySuccess(data))
    } catch (error) {
        if (error?.data?.getUsersListSuperAdmin?.length > 0) {
            const data = error?.data.getDoctorsBySpeciality
            yield put(userReducer.actions.getDoctorsBySpecialitySuccess(data))
        }
        else {
            yield put(userReducer.actions.getDoctorsBySpecialityFail({ error: error?.errors }))
        }
    }
}

export function* getDoctorsSaga(payload) {
    try {
        const response = yield call(getDoctors, payload);
        const data = response?.data?.getDoctors
        yield put(userReducer.actions.getDoctorsSuccess(data))
    } catch (error) {
        if (error?.data?.getUsersListSuperAdmin?.length > 0) {
            const data = error?.data.getDoctors
            yield put(userReducer.actions.getDoctorsSuccess(data))
        }
        else {
            yield put(userReducer.actions.getDoctorsFail({ error: error?.errors }))
        }
    }
}

export function* getDoctorsByClinicSaga(payload) {
    try {
        const response = yield call(getAllClinicDoctors, payload);
        const data = response?.data?.getAllClinicDoctors
        yield put(userReducer.actions.getDoctorsByClinicSuccess(data))
    } catch (error) {
        if (error?.data?.getAllClinicDoctors?.length > 0) {
            const data = error?.data.getAllClinicDoctors
            yield put(userReducer.actions.getDoctorsByClinicSuccess(data))
        }
        else {
            yield put(userReducer.actions.getDoctorsByClinicFail({ error: error?.errors }))
        }
    }
}

export function* getNursesByClinicSaga(payload) {
    try {
        const response = yield call(getAllClinicNurses, payload);
        const data = response?.data?.getAllClinicNurses
        yield put(userReducer.actions.getNursesByClinicSuccess(data))
    } catch (error) {
        if (error?.data?.getAllClinicNurses?.length > 0) {
            const data = error?.data.getAllClinicNurses
            yield put(userReducer.actions.getNursesByClinicSuccess(data))
        }
        else {
            yield put(userReducer.actions.getNursesByClinicFail({ error: error?.errors }))
        }
    }
}

export function* updateUserSaga(payload) {
    try {
        const response = yield call(updateUser, payload);
        const data = response?.data?.updateUser
        yield put(userReducer.actions.updateUserSuccess(data))
        setTimeout(() => window.history.back(), 2000)
    } catch (error) {
        yield put(userReducer.actions.updateUserSuccess({ error: error?.errors }))
    }
}

export function* createSchoolInfirmarySaga(payload) {
    try {
        const userResponse = yield call(getUserByEmail, payload.payload.email)
        if(!userResponse?.data?.getUserByEmail?.userId){
            const response = yield call(createSchoolInfirmary, payload);
            const data = response?.data
            yield put(userReducer.actions.createUserSuccess(data))
            setTimeout(() => window.history.back(), 2000)
        } 
        else {
            yield put(userReducer.actions.getUserByEmailFail({}))
        }
    } catch (error) {
        if (error?.data?.createUser?.length > 0) {
            const data = error?.data.getUsers
            yield put(userReducer.actions.createUserSuccess(data))
            setTimeout(() => window.history.back(), 2000)
        }
        else {
            yield put(userReducer.actions.createUserFail({ error: error.errors }))
        }
    }
}

export function* getSchoolInfirmarySaga(payload) {
    try {
        const response = yield call(getSchoolInfirmary, payload);
        const data = response?.data?.getSchoolStaffListSuperAdmin?.schoolInfirmary
        yield put(userReducer.actions.getSchoolInfirmarySuccess(data))
    } catch (error) {
        if (error?.data?.getSchoolStaffListSuperAdmin?.schoolInfirmary?.length > 0) {
            const data = error?.data?.getSchoolStaffListSuperAdmin?.schoolInfirmary
            yield put(userReducer.actions.getSchoolInfirmarySuccess(data))
        }
        else {
            yield put(userReducer.actions.getSchoolInfirmaryFail({ error: error?.errors }))
        }
    }
}

export function* bulkUploadTeacherSaga(payload) {
    try {
        const response = yield call(bulkTeacherUpload, payload);
        const data = response?.data.batchTeacherUpload

        if (data?.statusCode === 400) {
            yield put(userReducer.actions.bulkUploadTeacherFail(data))
        }
        else {
            yield put(userReducer.actions.bulkUploadTeacherSuccess(data))
            yield put(userReducer.actions.getUserByGroupRequest({ role: "school-teacher", schoolId: payload.payload.schoolId }))
        }
    } catch (error) {
        yield put(userReducer.actions.bulkUploadTeacherFail(error))
    }
}


export function* getAllUserBy(payload) {
    try {
        const response = yield call(getUserByEmail, payload);
        
        // const data = response?.data.batchTeacherUpload
        yield put(userReducer.actions.getUserByEmailSuccess(response))

    } catch (error) {
        yield put(userReducer.actions.getUserByEmailFail(error))
    }
}
