import { Scale } from "@mui/icons-material";
import * as React from "react";
import "../Main.css";
// const Hair = (props) => {
//   const { active, issue, onClick, disabled, top, right, scale } = props;

//   return disabled ? (
//       <svg width={16} height={16} viewBox="0 0 14 14" fill="none"
//         className={`mainCon`}
//         style={{
//           top: top,
//           right: right,
//           transform: `scale(${scale ?? 1})`,
//         }}
//       {...props}>
//         <ellipse
//           cx={7}
//           cy={7.0018}
//           rx={7}
//           ry={7.0018}
//           fill="#B2B7BE"
//           opacity="0.17"
//         />
//         <path
//           d="M6.82701 9.81783C6.41367 9.81783 6.02529 9.65676 5.73357 9.36424C5.44209 9.07198 5.2815 8.68324 5.2815 8.26961C5.2815 8.09668 5.29654 7.93061 5.30979 7.7842C5.33443 7.51179 5.35574 7.27664 5.26543 7.17766C5.2061 7.11275 5.07674 7.07977 4.88064 7.07977L3.56177 7.07971V6.72119H4.88071C5.19005 6.72119 5.3964 6.78937 5.5301 6.93579C5.72705 7.15141 5.69907 7.45962 5.66682 7.8164C5.65363 7.96205 5.63994 8.11268 5.63994 8.26948C5.63994 8.9255 6.17246 9.45926 6.82703 9.45926C7.3858 9.45926 7.86198 9.07858 7.98494 8.53358L8.3346 8.61246C8.25862 8.94952 8.0684 9.25548 7.799 9.4738C7.52532 9.6957 7.18013 9.81785 6.82703 9.81785L6.82701 9.81783Z"
//           fill="#B2B7BE"
//         />
//         <path
//           d="M8.37225 8.26947H8.01383C8.01383 8.14661 8.00506 8.02574 7.99731 7.93426L8.35446 7.9043C8.36278 8.00283 8.37225 8.13324 8.37225 8.26947Z"
//           fill="#B2B7BE"
//         />
//         <path
//           d="M7.97225 7.64028C7.95471 7.39719 7.95068 7.12196 8.12707 6.93227C8.26097 6.78835 8.4663 6.72119 8.77321 6.72119L10.0921 6.72126V7.07977H8.77321C8.57831 7.07977 8.44922 7.1123 8.3895 7.17651C8.31909 7.25225 8.31468 7.40634 8.32972 7.61442L7.97225 7.64028Z"
//           fill="#B2B7BE"
//         />
//         <path
//           d="M6.2345 3.35696C6.2345 3.20799 6.35566 3.08691 6.50459 3.08691C6.65353 3.08691 6.77468 3.20798 6.77468 3.35696C6.77468 3.50595 6.65352 3.62713 6.50459 3.62713C6.35565 3.62713 6.2345 3.50593 6.2345 3.35696Z"
//           fill="#B2B7BE"
//         />
//         <path
//           d="M8.81654 2.77404C7.57556 4.55983 7.66017 6.55933 7.70561 7.63333C7.71611 7.88155 7.72443 8.07745 7.71508 8.22015C7.69844 8.47567 7.59315 8.69974 7.43193 8.86005C7.2707 9.02029 7.0536 9.11664 6.81319 9.11664C6.57669 9.11664 6.35555 9.02208 6.19062 8.85024C6.03464 8.68776 5.9355 8.46509 5.9115 8.22322C5.88571 7.96246 5.89441 7.05016 6.24074 5.97394C6.4412 5.35094 6.72244 4.77328 7.07672 4.25698C7.50914 3.6267 8.05278 3.08571 8.69251 2.64884L9.09432 2.37451L8.81654 2.77404Z"
//           fill="#B2B7BE"
//         />
//         <path
//           d="M9.42643 5.01479C9.42643 5.16364 9.30526 5.28484 9.15645 5.28484C9.00751 5.28484 8.88635 5.16365 8.88635 5.01479C8.88635 4.86582 9.00751 4.74463 9.15645 4.74463C9.30526 4.74469 9.42643 4.86588 9.42643 5.01479Z"
//           fill="#B2B7BE"
//         />
//         <path
//           d="M5.29754 5.46982C5.29754 5.68096 5.12576 5.85279 4.91467 5.85279C4.70364 5.85279 4.53186 5.68096 4.53186 5.46982C4.53186 5.25874 4.70364 5.08691 4.91467 5.08691C5.12576 5.08691 5.29754 5.25874 5.29754 5.46982Z"
//           fill="#B2B7BE"
//         />
//       </svg>
//   ) : (
//       <svg
//         width={16}
//         height={16}
//         viewBox="0 0 14 14"
//         fill="none"
//         onClick={onClick}
//         className={`mainCon  ${
//           active && (issue ? "activeRed" : "activeGreen")
//         }`}
//         style={{
//           top: top,
//           right: right,
//           transform: `scale(${scale ?? 1})`,
//         }}
//         {...props}
//       >
//         <ellipse
//           cx={7}
//           cy={7.0018}
//           rx={7}
//           ry={7.0018}
//           fill={issue ? "#f0c5c5" : "#CAEFCF"}
//         />
//         <path
//           d="M6.82701 9.81783C6.41367 9.81783 6.02529 9.65676 5.73357 9.36424C5.44209 9.07198 5.2815 8.68324 5.2815 8.26961C5.2815 8.09668 5.29654 7.93061 5.30979 7.7842C5.33443 7.51179 5.35574 7.27664 5.26543 7.17766C5.2061 7.11275 5.07674 7.07977 4.88064 7.07977L3.56177 7.07971V6.72119H4.88071C5.19005 6.72119 5.3964 6.78937 5.5301 6.93579C5.72705 7.15141 5.69907 7.45962 5.66682 7.8164C5.65363 7.96205 5.63994 8.11268 5.63994 8.26948C5.63994 8.9255 6.17246 9.45926 6.82703 9.45926C7.3858 9.45926 7.86198 9.07858 7.98494 8.53358L8.3346 8.61246C8.25862 8.94952 8.0684 9.25548 7.799 9.4738C7.52532 9.6957 7.18013 9.81785 6.82703 9.81785L6.82701 9.81783Z"
//           fill="black"
//         />
//         <path
//           d="M8.37225 8.26947H8.01383C8.01383 8.14661 8.00506 8.02574 7.99731 7.93426L8.35446 7.9043C8.36278 8.00283 8.37225 8.13324 8.37225 8.26947Z"
//           fill="black"
//         />
//         <path
//           d="M7.97225 7.64028C7.95471 7.39719 7.95068 7.12196 8.12707 6.93227C8.26097 6.78835 8.4663 6.72119 8.77321 6.72119L10.0921 6.72126V7.07977H8.77321C8.57831 7.07977 8.44922 7.1123 8.3895 7.17651C8.31909 7.25225 8.31468 7.40634 8.32972 7.61442L7.97225 7.64028Z"
//           fill="black"
//         />
//         <path
//           d="M6.2345 3.35696C6.2345 3.20799 6.35566 3.08691 6.50459 3.08691C6.65353 3.08691 6.77468 3.20798 6.77468 3.35696C6.77468 3.50595 6.65352 3.62713 6.50459 3.62713C6.35565 3.62713 6.2345 3.50593 6.2345 3.35696Z"
//           fill="black"
//         />
//         <path
//           d="M8.81654 2.77404C7.57556 4.55983 7.66017 6.55933 7.70561 7.63333C7.71611 7.88155 7.72443 8.07745 7.71508 8.22015C7.69844 8.47567 7.59315 8.69974 7.43193 8.86005C7.2707 9.02029 7.0536 9.11664 6.81319 9.11664C6.57669 9.11664 6.35555 9.02208 6.19062 8.85024C6.03464 8.68776 5.9355 8.46509 5.9115 8.22322C5.88571 7.96246 5.89441 7.05016 6.24074 5.97394C6.4412 5.35094 6.72244 4.77328 7.07672 4.25698C7.50914 3.6267 8.05278 3.08571 8.69251 2.64884L9.09432 2.37451L8.81654 2.77404Z"
//           fill="black"
//         />
//         <path
//           d="M9.42643 5.01479C9.42643 5.16364 9.30526 5.28484 9.15645 5.28484C9.00751 5.28484 8.88635 5.16365 8.88635 5.01479C8.88635 4.86582 9.00751 4.74463 9.15645 4.74463C9.30526 4.74469 9.42643 4.86588 9.42643 5.01479Z"
//           fill="black"
//         />
//         <path
//           d="M5.29754 5.46982C5.29754 5.68096 5.12576 5.85279 4.91467 5.85279C4.70364 5.85279 4.53186 5.68096 4.53186 5.46982C4.53186 5.25874 4.70364 5.08691 4.91467 5.08691C5.12576 5.08691 5.29754 5.25874 5.29754 5.46982Z"
//           fill="black"
//         />
//       </svg>
//   );
// };
// export default Hair;

const Hair = (props) => {

  const { active, issue, onClick, disabled, top, right, scale } = props;

  return (
      disabled
          ?
          <div
              className={`mainCon`}
              style={{
                  top: top,
                  right: right,
                  transform: `scale(${scale ?? 1})`
              }}>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  fill="none"
                  {...props}
              >
                  <circle cx={12} cy={12} r={12}
                      fill="#B2B7BE"
                      opacity="0.16" />
                  <path
                      fill="#B2B7BE"
                      d="M11.555 17.381a2.14 2.14 0 0 1-1.525-.632 2.148 2.148 0 0 1-.631-1.527c0-.241.021-.473.04-.677.034-.38.064-.708-.062-.846-.083-.09-.264-.136-.537-.136H7v-.5h1.84c.431 0 .72.095.906.299.274.3.236.73.19 1.228-.018.203-.037.413-.037.632 0 .915.743 1.66 1.656 1.66.78 0 1.444-.532 1.615-1.292l.488.11a2.16 2.16 0 0 1-2.103 1.681ZM13.71 15.222h-.5c0-.171-.012-.34-.023-.467l.499-.042c.011.137.024.32.024.51ZM13.152 14.344c-.025-.339-.03-.723.216-.987.187-.2.473-.294.901-.294h1.84v.5h-1.84c-.272 0-.452.045-.535.134-.098.106-.104.321-.084.611l-.498.036ZM10.73 8.37a.377.377 0 1 1 .755.002.377.377 0 0 1-.755-.001Z"
                  />
                  <path
                      fill="#B2B7BE"
                      d="M14.33 7.557c-1.73 2.49-1.613 5.28-1.55 6.777.016.346.027.62.014.819-.023.356-.17.668-.395.892a1.216 1.216 0 0 1-.863.358c-.33 0-.638-.132-.869-.372a1.471 1.471 0 0 1-.389-.874c-.036-.364-.024-1.636.46-3.137a9.212 9.212 0 0 1 1.165-2.395 8.602 8.602 0 0 1 2.255-2.242l.56-.383-.387.557ZM15.18 10.681a.377.377 0 1 1-.755 0 .377.377 0 0 1 .754 0ZM9.424 11.317a.535.535 0 1 1-1.07-.001.535.535 0 0 1 1.07.001Z"
                  />
              </svg>
          </div>
          :
          <div
              onClick={onClick}
              className={`mainCon  ${active && (issue ? "activeRed" : "activeGreen")}`}
              style={{
                  top: top,
                  right: right,
                  backgroundColor: active ? "#ffffff" : "",
                  transform: `scale(${scale ?? 1})`
              }}>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  fill="none"
                  {...props}

              >
                  <circle cx={12} cy={12} r={12}
                     fill={active ? "#ffffff" : issue ? "#f0c5c5" : "#CAEFCF"} />
                  <path
                      fill="#115EA3"
                      d="M11.555 17.381a2.14 2.14 0 0 1-1.525-.632 2.148 2.148 0 0 1-.631-1.527c0-.241.021-.473.04-.677.034-.38.064-.708-.062-.846-.083-.09-.264-.136-.537-.136H7v-.5h1.84c.431 0 .72.095.906.299.274.3.236.73.19 1.228-.018.203-.037.413-.037.632 0 .915.743 1.66 1.656 1.66.78 0 1.444-.532 1.615-1.292l.488.11a2.16 2.16 0 0 1-2.103 1.681ZM13.71 15.222h-.5c0-.171-.012-.34-.023-.467l.499-.042c.011.137.024.32.024.51ZM13.152 14.344c-.025-.339-.03-.723.216-.987.187-.2.473-.294.901-.294h1.84v.5h-1.84c-.272 0-.452.045-.535.134-.098.106-.104.321-.084.611l-.498.036ZM10.73 8.37a.377.377 0 1 1 .755.002.377.377 0 0 1-.755-.001Z"
                  />
                  <path
                      fill="#115EA3"
                      d="M14.33 7.557c-1.73 2.49-1.613 5.28-1.55 6.777.016.346.027.62.014.819-.023.356-.17.668-.395.892a1.216 1.216 0 0 1-.863.358c-.33 0-.638-.132-.869-.372a1.471 1.471 0 0 1-.389-.874c-.036-.364-.024-1.636.46-3.137a9.212 9.212 0 0 1 1.165-2.395 8.602 8.602 0 0 1 2.255-2.242l.56-.383-.387.557ZM15.18 10.681a.377.377 0 1 1-.755 0 .377.377 0 0 1 .754 0ZM9.424 11.317a.535.535 0 1 1-1.07-.001.535.535 0 0 1 1.07.001Z"
                  />
              </svg>
          </div>
  )
}
export default Hair