import * as React from "react"
const autismSvg = (props) => (
    <div style={{ ...props.customStyle }}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke="#E09F26"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                clipPath="url(#a)"
            >
                <path d="M4.274 14.796c-.323 2.588.114 5.088 1.193 6.954l3.56 6.158v7.787S8.882 37.5 10.84 37.5H23.55s1.416-.162 1.416-1.409v-1.953s.363-1.133 1.858-1.133h2.342s2 .141 2.355-1.486c.356-1.628.996-4.066.996-4.066s4.413-.465 3.056-3.08c-1.356-2.616-3.06-5.168-3.06-5.168S34.758 2.5 20.042 2.5a22.36 22.36 0 0 0-3.022.199" />
                <path d="M27.958 16.625h-3.036s.664-2.57-1.329-2.57c-1.993 0-1.697 2.015-1.138 2.57h-3.59" />
                <path d="M18.865 25.224v-3.02s2.584.66 2.584-1.321c0-1.982-2.025-1.689-2.584-1.129V16.18" />
                <path d="M18.974 7.547h7.308s1.672-.032 1.672 1.663v14.224s.159 2.265-2.277 2.265H12.015s-2.245.315-2.245-2.234v-5.6s-.023-1.293 1.3-1.293h1.925s-.665-2.513 1.394-2.513c2.058 0 1.3 2.57 1.3 2.57h3.18v-3.136s-2.389.314-2.389-1.51c0-1.825 1.749-1.864 2.39-1.228l.104-3.208ZM13.693 11.637v-3.57c-.558.555-2.584.85-2.584-1.132 0-1.981 2.584-1.321 2.584-1.321v-3.02H4.6v9.043h3.59c-.558-.554-.855-2.57 1.137-2.57 1.993 0 1.33 2.57 1.33 2.57h3.036Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M3.334 1.664h33.333v36.667H3.334z" />
                </clipPath>
            </defs>
        </svg>
    </div>
)
export default autismSvg
