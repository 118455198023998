import React, { useState } from 'react';
import Box from '@mui/material/Box';
import classes from './WelchZip.module.css'
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { CloseRounded } from '@mui/icons-material';
import UploadFile from '../../../BehavioralDoctorDashboard/AddLog/UploadFile';
import DatePickerInput from '../../../common/InputDashboard/DatePickerInput';
import screening from '../../../../graphqlApiServices/screening';
import { useLocation } from 'react-router-dom';
import { Buffer } from 'buffer';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: 'none',
    width: '600px',
    p: 4,
    borderRadius: '10px',
    outline: 'none'
};

export default function WelchZip({ open, handleClose }) {

    const location = useLocation()

    const { getWelchAllynUploadHistory } = screening

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [uploadFile, setUploadFile] = useState()
    const [loading, setLoading] = useState(false)

    const Reset = () => {
        setEndDate()
        setStartDate()
        setUploadFile()
        handleClose()
    }
    const { uploadWelchAllynZip } = screening

    const toBase64 = () => new Promise((resolve, reject) => {
        if (uploadFile) {
            const readers = new FileReader();
            readers.readAsArrayBuffer(uploadFile);
            readers.onload = () => {
                resolve(Buffer.from(readers.result))

            };
            readers.onerror = error => reject(error);
        }

    });

    const uploadZip = async () => {

        setLoading(true)
        const params = {
            schoolId: location.state.school.schoolId,
            fromDate: startDate,
            toDate: endDate,
        }
        // const buffer = Buffer.from(uploadFile)
        const zip = await toBase64()
        const x = await uploadWelchAllynZip(params)
        console.log(x.data.getWelchallynUpload)
        const q = await axios.put(x.data.getWelchallynUpload, zip)
        if (q.status === 200) {
            handleClose()
        }
        console.log(q.status)
        setLoading(false)
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={classes.modalBox}>
                        <h1>Welch Allyn Zip File </h1>
                        <h6>Select Date Range</h6>
                        <p>Please select the date range for which welch allyn is being uploaded</p>
                        <div className={classes.dateBox}>
                            <DatePickerInput
                                onChange={(e) => {
                                    setStartDate(e.target.value);
                                }}
                                placeholder={"Select Start Date"}
                                value={startDate}
                            />
                            <DatePickerInput
                                onChange={(e) => {
                                    setEndDate(e.target.value);
                                }}
                                pastDate={true}
                                pastDateValue={startDate}
                                placeholder={"Select End Date"}
                                value={endDate}
                            />
                        </div>
                        <UploadFile uploadFile={uploadFile} setUploadFile={setUploadFile} fileType={".zip"} />
                        <div className={classes.btnBox}>
                            <Button onClick={Reset} sx={{
                                background: '#fff', color: '#1740A1', marginRight: '20px', border: '1px solid #1740A1', ':hover': {
                                    background: '#fff'
                                }
                            }}>Cancel</Button>
                            <Button
                                sx={{
                                    background: '#1740A1', color: '#fff', ':hover': {
                                        background: '#1740A1'
                                    }, ':disabled': {
                                        color: "#fff"
                                    }
                                }}
                                disabled={!uploadFile || !startDate || !endDate || loading}
                                onClick={uploadZip}
                            >
                                {loading ? <CircularProgress size={25} color='secondary' /> : "Upload"}
                            </Button>
                        </div>
                        <CloseRounded className={classes.closeBtn} onClick={Reset} />
                    </div>
                </Box>
            </Modal>
        </div>
    );
}