import * as React from "react";
import "../Main.css";

// const Lungs = (props) => {
//   const { active, issue, onClick, disabled, right, top, scale } = props;

//   return disabled ? (
//     <svg
//       width="16"
//       height="16"
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 14 14"
//       fill="none"
//       className={`mainCon`}
//       style={{
//         top: top,
//         right: right,
//         transform: `scale(${scale})`,
//       }}
//       {...props}
//     >
//       <circle cx="7" cy="7" r="7" fill="#B2B7BE" opacity="0.16" />
//       <path
//         d="M4.11011 10.7502C3.75141 10.7502 3.45074 10.6304 3.20809 10.3908C2.96544 10.1512 2.84412 9.85433 2.84412 9.50016V7.76058L3.95186 4.8335C4.03626 4.60433 4.18396 4.42204 4.39496 4.28662C4.60595 4.1512 4.83454 4.0835 5.0807 4.0835C5.3972 4.0835 5.66622 4.19634 5.88777 4.42204C6.10932 4.64773 6.22009 4.9203 6.22009 5.23975V5.75016H5.3761V5.23975C5.3761 5.14947 5.34445 5.07308 5.28115 5.01058C5.21785 4.94808 5.144 4.91683 5.0596 4.91683C4.98927 4.91683 4.92421 4.93593 4.86443 4.97412C4.80464 5.01232 4.7642 5.06266 4.7431 5.12516L3.68811 7.91683V9.50016C3.68811 9.61822 3.72855 9.71718 3.80943 9.79704C3.89032 9.8769 3.99054 9.91683 4.11011 9.91683H5.3761C5.49566 9.91683 5.59589 9.8769 5.67677 9.79704C5.75765 9.71718 5.7981 9.61822 5.7981 9.50016V8.66683H6.64209V9.50016C6.64209 9.85433 6.51901 10.1512 6.27284 10.3908C6.02668 10.6304 5.72776 10.7502 5.3761 10.7502H4.11011ZM10.0075 10.7502H8.74153C8.38986 10.7502 8.09095 10.6304 7.84478 10.3908C7.59862 10.1512 7.47553 9.85433 7.47553 9.50016V8.66683H8.31953V9.50016C8.31953 9.61822 8.35997 9.71718 8.44085 9.79704C8.52174 9.8769 8.62196 9.91683 8.74153 9.91683H10.0075C10.1271 9.91683 10.2273 9.8769 10.3082 9.79704C10.3891 9.71718 10.4295 9.61822 10.4295 9.50016V7.91683L9.37452 5.12516C9.34639 5.06266 9.30419 5.01232 9.24792 4.97412C9.19166 4.93593 9.12836 4.91683 9.05802 4.91683C8.96659 4.91683 8.89098 4.94808 8.8312 5.01058C8.77142 5.07308 8.74153 5.14947 8.74153 5.23975V5.75016H7.89753V5.23975C7.89753 4.9203 8.00831 4.64773 8.22985 4.42204C8.4514 4.19634 8.72043 4.0835 9.03692 4.0835C9.28309 4.0835 9.50991 4.1512 9.71739 4.28662C9.92488 4.42204 10.0743 4.60433 10.1658 4.8335L11.2735 7.76058V9.50016C11.2735 9.85433 11.1504 10.1512 10.9043 10.3908C10.6581 10.6304 10.3592 10.7502 10.0075 10.7502ZM7.06409 6.76058L5.96689 7.8335L5.3761 7.25016L6.64209 6.00016V2.8335H7.48608V6.00016L8.75208 7.25016L8.15073 7.8335L7.06409 6.76058Z"
//         fill="#B2B7BE"
//       />
//     </svg>
//   ) : (
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 14 14"
//       xmlns="http://www.w3.org/2000/svg"
//       fill="none"
//       className={`mainCon  ${active && (issue ? "activeRed" : "activeGreen")}`}
//       onClick={onClick}
//       style={{
//         top: top,
//         right: right,
//         transform: `scale(${scale})`,
//       }}
//       {...props}
//     >
//       <circle cx="7" cy="7" r="7" fill={issue ? "#f0c5c5" : "#CAEFCF"} />
//       <path
//         d="M4.11011 10.7502C3.75141 10.7502 3.45074 10.6304 3.20809 10.3908C2.96544 10.1512 2.84412 9.85433 2.84412 9.50016V7.76058L3.95186 4.8335C4.03626 4.60433 4.18396 4.42204 4.39496 4.28662C4.60595 4.1512 4.83454 4.0835 5.0807 4.0835C5.3972 4.0835 5.66622 4.19634 5.88777 4.42204C6.10932 4.64773 6.22009 4.9203 6.22009 5.23975V5.75016H5.3761V5.23975C5.3761 5.14947 5.34445 5.07308 5.28115 5.01058C5.21785 4.94808 5.144 4.91683 5.0596 4.91683C4.98927 4.91683 4.92421 4.93593 4.86443 4.97412C4.80464 5.01232 4.7642 5.06266 4.7431 5.12516L3.68811 7.91683V9.50016C3.68811 9.61822 3.72855 9.71718 3.80943 9.79704C3.89032 9.8769 3.99054 9.91683 4.11011 9.91683H5.3761C5.49566 9.91683 5.59589 9.8769 5.67677 9.79704C5.75765 9.71718 5.7981 9.61822 5.7981 9.50016V8.66683H6.64209V9.50016C6.64209 9.85433 6.51901 10.1512 6.27284 10.3908C6.02668 10.6304 5.72776 10.7502 5.3761 10.7502H4.11011ZM10.0075 10.7502H8.74153C8.38986 10.7502 8.09095 10.6304 7.84478 10.3908C7.59862 10.1512 7.47553 9.85433 7.47553 9.50016V8.66683H8.31953V9.50016C8.31953 9.61822 8.35997 9.71718 8.44085 9.79704C8.52174 9.8769 8.62196 9.91683 8.74153 9.91683H10.0075C10.1271 9.91683 10.2273 9.8769 10.3082 9.79704C10.3891 9.71718 10.4295 9.61822 10.4295 9.50016V7.91683L9.37452 5.12516C9.34639 5.06266 9.30419 5.01232 9.24792 4.97412C9.19166 4.93593 9.12836 4.91683 9.05802 4.91683C8.96659 4.91683 8.89098 4.94808 8.8312 5.01058C8.77142 5.07308 8.74153 5.14947 8.74153 5.23975V5.75016H7.89753V5.23975C7.89753 4.9203 8.00831 4.64773 8.22985 4.42204C8.4514 4.19634 8.72043 4.0835 9.03692 4.0835C9.28309 4.0835 9.50991 4.1512 9.71739 4.28662C9.92488 4.42204 10.0743 4.60433 10.1658 4.8335L11.2735 7.76058V9.50016C11.2735 9.85433 11.1504 10.1512 10.9043 10.3908C10.6581 10.6304 10.3592 10.7502 10.0075 10.7502ZM7.06409 6.76058L5.96689 7.8335L5.3761 7.25016L6.64209 6.00016V2.8335H7.48608V6.00016L8.75208 7.25016L8.15073 7.8335L7.06409 6.76058Z"
//         fill="#1C1B1F"
//       />
//     </svg>
//   );
// };
// export default Lungs;

const Lungs = (props) => {

  const { active, issue, onClick, disabled, right, top, scale } = props;

  return (

      disabled
          ?
          <div
              className={`mainCon`}
              style={{
                  top: top,
                  right: right,
                  transform: `scale(${scale})`
              }}>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  fill="none"
                  {...props}
              >
                  <circle cx={12} cy={12} r={12}
                      fill="#B2B7BE"
                      opacity="0.16" />
                  <path
                      fill="#B2B7BE"
                      fillRule="evenodd"
                      d="M11.999 6.666c.184 0 .333.15.333.333h-.667c0-.184.15-.333.334-.333Zm.333 3.71V6.998h-.667v3.376c-.184.05-.343.142-.476.265-.198.184-.325.424-.416.652-.082.205-.145.427-.2.623l-.02.066a3.286 3.286 0 0 1-.186.536.333.333 0 0 0 .597.298c.099-.199.169-.437.23-.651l.019-.065a5.8 5.8 0 0 1 .179-.56c.075-.188.157-.323.25-.41a.488.488 0 0 1 .357-.13c.166 0 .272.052.356.13.094.087.176.222.25.41.07.171.122.359.18.56l.018.065c.061.214.132.452.231.651a.333.333 0 1 0 .596-.298 3.287 3.287 0 0 1-.186-.536 6.396 6.396 0 0 0-.22-.689c-.09-.228-.217-.468-.415-.652a1.123 1.123 0 0 0-.477-.265Z"
                      clipRule="evenodd"
                  />
                  <path
                      fill="#B2B7BE"
                      d="M9.83 8.675c-1.006-.318-1.971.29-2.632 1.276-.67 1-1.105 2.478-1.08 4.174.02 1.323 1.127 2.367 2.436 2.366.92 0 1.912-.173 2.4-.986.259-.432.368-.81.369-1.194 0-.37-.103-.719-.214-1.074l-.037-.118c-.219-.691-.497-1.57-.447-3.078.015-.482-.156-1.164-.795-1.366ZM14.172 8.675c1.006-.318 1.971.29 2.632 1.276.67 1 1.105 2.478 1.08 4.174-.02 1.323-1.126 2.367-2.436 2.366-.92 0-1.911-.173-2.4-.986-.258-.432-.368-.81-.368-1.194 0-.37.102-.719.214-1.074l.037-.118c.218-.691.496-1.57.447-3.078-.016-.482.155-1.164.794-1.366Z"
                  />
              </svg>
          </div>
          :
          <div
              className={`mainCon  ${active && (issue ? "activeRed" : "activeGreen")}`}
              onClick={onClick}
              style={{
                  top: top,
                  right: right,
                  backgroundColor: active ? "#ffffff" : "",
                  transform: `scale(${scale})`
              }}>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  fill="none"
                  {...props}
              >
                  <circle cx={12} cy={12} r={12}
                      fill={active ? "#ffffff" : issue ? "#f0c5c5" : "#CAEFCF"}  />
                  <path
                      fill="#115EA3"
                      fillRule="evenodd"
                      d="M11.999 6.666c.184 0 .333.15.333.333h-.667c0-.184.15-.333.334-.333Zm.333 3.71V6.998h-.667v3.376c-.184.05-.343.142-.476.265-.198.184-.325.424-.416.652-.082.205-.145.427-.2.623l-.02.066a3.286 3.286 0 0 1-.186.536.333.333 0 0 0 .597.298c.099-.199.169-.437.23-.651l.019-.065a5.8 5.8 0 0 1 .179-.56c.075-.188.157-.323.25-.41a.488.488 0 0 1 .357-.13c.166 0 .272.052.356.13.094.087.176.222.25.41.07.171.122.359.18.56l.018.065c.061.214.132.452.231.651a.333.333 0 1 0 .596-.298 3.287 3.287 0 0 1-.186-.536 6.396 6.396 0 0 0-.22-.689c-.09-.228-.217-.468-.415-.652a1.123 1.123 0 0 0-.477-.265Z"
                      clipRule="evenodd"
                  />
                  <path
                      fill="#115EA3"
                      d="M9.83 8.675c-1.006-.318-1.971.29-2.632 1.276-.67 1-1.105 2.478-1.08 4.174.02 1.323 1.127 2.367 2.436 2.366.92 0 1.912-.173 2.4-.986.259-.432.368-.81.369-1.194 0-.37-.103-.719-.214-1.074l-.037-.118c-.219-.691-.497-1.57-.447-3.078.015-.482-.156-1.164-.795-1.366ZM14.172 8.675c1.006-.318 1.971.29 2.632 1.276.67 1 1.105 2.478 1.08 4.174-.02 1.323-1.126 2.367-2.436 2.366-.92 0-1.911-.173-2.4-.986-.258-.432-.368-.81-.368-1.194 0-.37.102-.719.214-1.074l.037-.118c.218-.691.496-1.57.447-3.078-.016-.482.155-1.164.794-1.366Z"
                  />
              </svg>
          </div>
  )
}
export default Lungs