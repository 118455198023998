import React from "react";
import skidslogo from "../../assets/images/skids-logo-1.svg";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import classes from "./Splash.module.css";

function Splash() {
  return (
    <div className={classes.splash}>
      <div className={classes.centre}>
        <img src={skidslogo} alt=''></img>
      </div>
      <div className={classes.bigBubleTop} />
      <div className={classes.mediumBubleTop} />
      <div className={classes.smallBubleTop} />

      <div className={classes.bigBottomBubble} />
    </div>
  );
}

export default Splash;
