import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { BehavioralReport } from '../BehavioralReport'
import { BehavioralContinue } from '../BehavioralContinue'
import { AutismReport } from '../AutismReport/AutismReport'
import { AutismReport3 } from '../AutismReport/AutismReport3'
import { AutismReport2 } from '../AutismReport/AutismReport2'
import { ScreenDependency } from '../ScreenDependency/ScreenDependency'
import { ScreenDependency2 } from '../ScreenDependency/ScreenDependency2'
import { Anxiety } from '../Anxiety/Anxiety'
import { Anxiety2 } from '../Anxiety/Anxiety2'
import { Anxiety3 } from '../Anxiety/Anxiety3'
import { Anxiety4 } from '../Anxiety/Anxiety4'
import { CD } from '../CD/CD'
import { CD2 } from '../CD/CD2'
import { ODD } from '../ODD/ODD'
import { ODD2 } from '../ODD/ODD2'
import { Depression } from '../Depression/Depression'
import { Depression2 } from '../Depression/Depression2'
import { DevelopmentMilestones } from '../DevelopmentMilestones/DevelopmentMilestones'
import { DevelopmentMilestones2 } from '../DevelopmentMilestones/DevelopmentMilestones2'
import jsPDF from 'jspdf'
import { CircularProgress } from '@mui/material'
import { Dyslexia } from '../Dyslexia/Dyslexia'
import { Dyslexia2 } from '../Dyslexia/Dyslexia2'
import { Dyscalculia } from '../Dyscalculia/Dyscalculia'
import { Dyscalculia2 } from '../Dyscalculia/Dyscalculia2'
import { Dysgraphia } from '../Dysgraphia/Dysgraphia'
import { Dysgraphia2 } from '../Dysgraphia/Dysgraphia2'
import { AutismReport4to6 } from '../AutismReport/Autism4to6'
import { AutismReport4to62 } from '../AutismReport/Autism4to62'
import moment from 'moment'
import { ScreenDependency6to17 } from '../ScreenDependency/ScreenDependency6to17'
import { ScreenDependency6to172 } from '../ScreenDependency/ScreenDependency6to172'
import { AutismReport1to4 } from '../AutismReport/Autism1to4'
import { AutismReport1to42 } from '../AutismReport/Autism1to42'
import html2PDF from 'jspdf-html2canvas';
import { textFormat } from '../../../utils/utility'


function D2cMain() {

    const query = useParams()
    const location = useLocation()
    const dispatch = useDispatch()

    const [bhvReportData, setBhvReportData] = useState()

    useEffect(() => {
        setBhvReportData(JSON.parse(localStorage.getItem("assessment")))
    }, [])

    useEffect(() => {
        console.log(JSON.parse(localStorage.getItem("assessment")))
    }, [bhvReportData])

    const getPdf = async (x) => {
        console.log(x)
        const pdf = html2PDF(x, {
            jsPDF: { format: "a4", orientation: "p", unit: "mm" },
            imageType: 'image/jpeg',
            success: function (res) {
                for (let i = 10; i > 0; i--) {
                    var pageCount = res.internal.getNumberOfPages()
                    if (i % 2 === 0) {
                        res.deletePage(i)
                    }

                }
                res.save(`${textFormat(bhvReportData?.package[0]).split(" ")[0]}.pdf`)
                window.close()
            }
        })

        // return html2PDF(x, {
        //     jsPDF: {format:"a4", orientation:"p", unit:"mm"},
        //     // imageType: 'image/jpeg',
        //     output: ''
        // });
    }

    useEffect(() => {
        if (bhvReportData && Object.keys(bhvReportData)?.length) {
            console.log(bhvReportData)
            const pdf = new jsPDF('p', 'mm', 'a4');
            // const options = {
            //   background: 'white',
            //   scale: 3,
            // };

            // setTimeout(() => {
            const name = localStorage.getItem('name')

            const elements = document.querySelectorAll(".xyz")

            console.log("Noob", elements)

            // const pdf = await html2PDF(elements, {
            //     jsPDF: {
            //       format: 'a4',
            //     },
            //     imageType: 'image/jpeg',
            //     // output: 'abc.pdf'
            // });
            if (elements?.length > 0) {
                setTimeout(() => {
                    getPdf(elements)
                }, 3000);
                // const a = getPdf(elements).then((res)=>{
                //     console.log(res)
                //     for (let i = 10; i > 0; i--) {
                //         var pageCount = res.internal.getNumberOfPages()
                //         if(i%2===0){
                //             res.deletePage(i)
                //         }

                //     }
                //     res.save('aaa.pdf')
                // })
                // .catch((err)=>console.log(err))

            }

            elements?.forEach(async (element, index) => {
                //   html2canvas(element, options).then(canvas => {
                //     //   console.log(element)
                //     const imageData = canvas.toDataURL('image/png');
                //     const width = pdf.internal.pageSize.getWidth();
                //     const height = (canvas.height * width) / canvas.width;
                //     if (index !== 0) {
                //       pdf.addPage();
                //     }
                //     pdf.addImage(imageData, 'PNG', 0, 0, width, height, '', 'FAST');
                //     if (index === elements.length - 1) {
                //       pdf.save(`${textFormat(bhvReportData?.package[0]).split(" ")[0]}.pdf`);
                //     //   window.close()
                //     }
                //   });
                // setTimeout(async() => {
                // const canvas = await html2canvas(element,options)
                // console.log(canvas)
                //   const imageData = canvas.toDataURL('image/png');
                //     const width = pdf.internal.pageSize.getWidth();
                //     const height = (canvas.height * width) / canvas.width;
                //     if (index !== 0) {
                //       pdf.addPage();
                //     }
                //     pdf.addImage(imageData, 'PNG', 0, 0, width, height, '', 'FAST');
                //     pdf.html(element, {width:width})
                //     if (index === elements.length - 1) {
                //       pdf.save(`${textFormat(bhvReportData?.package[0]).split(" ")[0]}.pdf`);
                //     //   window.close()
                //     }
                // }, 3000);
            });
            // }, 10000);

        }
    }, [bhvReportData])

    // useEffect(() => {
    //     const params = {
    //         report: "bhv",
    //         screeningId: localStorage.getItem("sId"),
    //         types: [query.type.toUpperCase().split(" ").join("_")]
    //     }
    //     if(query.type.toUpperCase().split(" ").join("_")==="DIGITAL_DEPENDENCY"){
    //         params.types=["INTERNET_ADDICTION"]
    //     }
    //     if(query.type.toUpperCase().split(" ").join("_")==="OPPOSITIONAL_DEFIANT_DISORDER"){
    //         params.types=["ODD"]
    //     }
    //     if(query.type==="DYSLEXIA"){
    //         params.types=["DYSLEXIA_1", "DYSLEXIA_2"]
    //     }
    //     if(query.type==="DYSCALCULIA"){
    //         params.types=["DYSCALCULIA_1", "DYSCALCULIA_2"]
    //     }
    //     if(query.type==="DYSGRAPHIA"){
    //         params.types=["DYSGRAPHIA_1", "DYSGRAPHIA_2"]
    //     }
    //     if(query.type.toUpperCase().split(" ").join("_")==="ADHD" || query.type.toUpperCase().split(" ").join("_")==="OPPOSITIONAL_DEFIANT_DISORDER" || query.type.toUpperCase().split(" ").join("_")==="CONDUCT_DISORDER"){
    //         params.types.push("PERFORMANCE")
    //     } else if (query.type.toUpperCase().split(" ").join("_") === "DEVELOPMENTAL_DELAY"){
    //         params.types.push("EMOTIONAL_AND_BEHAVIORAL")
    //     }
    //     dispatch(getReport(params))
    // }, [])

    const getReportHTML = () => {
        switch (bhvReportData?.package?.[0]) {
            case "ADHD":
                return (<>
                    <BehavioralReport data={bhvReportData} />
                    <BehavioralContinue data={bhvReportData} />
                </>)
                break;
            case "AUTISM":
                console.log(Math.abs(moment(bhvReportData?.student?.DOB).diff(moment(), "months")))
                return (<>
                    {Math.abs(moment(bhvReportData?.student?.DOB).diff(moment(), "months")) >= 72 ?
                        <>
                            <AutismReport data={bhvReportData} />
                            <AutismReport2 data={bhvReportData} />
                            <AutismReport3 data={bhvReportData} />
                        </> :
                        (Math.abs(moment(bhvReportData?.student?.DOB).diff(moment(), "months")) < 48 ? <>
                            <AutismReport1to4 data={bhvReportData} />
                            <AutismReport1to42 data={bhvReportData} />
                        </> : <>
                            <AutismReport4to6 data={bhvReportData} />
                            <AutismReport4to62 data={bhvReportData} />
                        </>
                        )
                    }
                </>)
                break;
            case "INTERNET_ADDICTION":
                return (
                    <>
                        {Math.abs(moment(bhvReportData?.student?.DOB).diff(moment(), "months")) < 72 ?
                            <>
                                <ScreenDependency data={bhvReportData} />
                                <ScreenDependency2 data={bhvReportData} />
                            </> :
                            <>
                                <ScreenDependency6to17 data={bhvReportData} />
                                <ScreenDependency6to172 data={bhvReportData} />
                            </>
                        }
                    </>
                )
            case "ANXIETY":
                return (
                    <>
                        <Anxiety data={bhvReportData} />
                        <Anxiety2 data={bhvReportData} />
                        <Anxiety3 data={bhvReportData} />
                        <Anxiety4 data={bhvReportData} />
                    </>
                )
            case "CONDUCT_DISORDER":
                return (
                    <>
                        <CD data={bhvReportData} />
                        <CD2 data={bhvReportData} />
                    </>
                )
            case "ODD":
                return (
                    <>
                        <ODD data={bhvReportData} />
                        <ODD2 data={bhvReportData} />
                    </>
                )
            case "DEPRESSION":
                return (
                    <>
                        <Depression data={bhvReportData} />
                        <Depression2 data={bhvReportData} />
                    </>
                )
            case "DEVELOPMENTAL_DELAY":
                return (
                    <>
                        <DevelopmentMilestones data={bhvReportData} />
                        <DevelopmentMilestones2 data={bhvReportData} />
                    </>
                )
            case "DYSLEXIA_1":
                return (
                    <>
                        <Dyslexia data={bhvReportData} />
                        <Dyslexia2 data={bhvReportData} />
                    </>
                )
            case "DYSCALCULIA_1":
                return (
                    <>
                        <Dyscalculia data={bhvReportData} />
                        <Dyscalculia2 data={bhvReportData} />
                    </>
                )
            case "DYSGRAPHIA_1":
                return (
                    <>
                        <Dysgraphia data={bhvReportData} />
                        <Dysgraphia2 data={bhvReportData} />
                    </>
                )

            default:
                break;
        }
    }


    return (
        <>
            <div >
                <div style={{ display: "flex", justifyContent: "center", height: "100vh", alignItems: "center", flexDirection: "column", width: "100vw", zIndex: 999, position: "absolute", backgroundColor: "white" }}>
                    <CircularProgress size={50} />
                    <div style={{ marginTop: 40 }}>Please Wait, your report will be dowloaded shortly</div>
                </div>
                <div id='bhvreport' style={{ height: "100vh" }}>{(bhvReportData && Object.keys(bhvReportData)?.length) && getReportHTML()}</div>
            </div>
        </>
    )
}

export default D2cMain