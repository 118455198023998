import React, { useEffect, useRef, useState } from "react";
import classes from "./CreateBlog.module.css";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";
import InputDashboard from "../common/InputDashboard/InputDashboard";
import InputDropdown from "../common/InputDashboard/InputDropdown";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import blogReducer from "../../redux/reducers/blog.reducer";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import { CancelOutlined, Close, Download, DownloadOutlined } from "@mui/icons-material";

const CreateBlog = () => {
    const fileInputRef = useRef();
    const dispatch = useDispatch();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [author, setAuthor] = useState("");
    const [blogCategory, setBlogCategory] = useState("");
    const [thumbnail, setThumbnail] = useState("");
    const createBlogReq = blogReducer.actions.createBlogRequest;
    const getBlogs = blogReducer.actions.getBlogsRequest;
    const updateBlogReq = blogReducer.actions.updateBlogRequest;
    const error = useSelector((state) => state.blogReducer.error);
    const loading = useSelector((state) => state.blogReducer.loading);
    const user = useSelector((state) => state.userReducer.currentUser);

    useEffect(() => {
        if (location.state?.title) {
            setTitle(location.state?.title);
        }

        if (location.state?.description) {
            setDescription(location.state?.description);
        }

        if (location.state?.author) {
            setAuthor(location.state?.author);
        }

        if (location.state?.category) {
            setBlogCategory(location.state?.category);
        }

        if (location.state?.thumbnail) {
            setThumbnail(location.state?.thumbnail);
        }
    }, [location.state])

    const resetState = () => {
        setTitle("");
        setDescription("");
        setAuthor("");
        setBlogCategory("");
    }


    const handleClose = (event, reason) => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        try {
            if (!user || !title || !description || !author || !blogCategory || !thumbnail) return;
            const payload = {
                userId: user?.userId,
                title: title,
                description: description,
                author: author,
                thumbnail: thumbnail,
                category: blogCategory ?? "General",
                status: "DRAFT"
            };
            if (location.state?.blogId) {
                await dispatch(updateBlogReq({ ...payload, blogId: location.state?.blogId }));
            } else {
                await dispatch(createBlogReq(payload));
            }
            setOpen(true);
            setTimeout(() => {
                dispatch(getBlogs());
            }, 2000)
            resetState();
            navigate("/writer-dashboard");
        } catch (error) {
            console.log(error, "error");
            setOpen(true);
        }
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleOnChange = async (e) => {
        e.preventDefault()
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        console.log(base64, "base64");
        setThumbnail(base64);
        e.target.value = ''
    }

    return (
        <div className={classes.mainDiv}>
            <BreadCrumbs />
            <div className={classes.container}>
                <h4>{location.state?.blogId ? "Edit Blog" : "Create Blog"}</h4>
                <br />
                <div className={classes.input_layout}>
                    <InputDashboard
                        placeholder={"Blog Title"}
                        width={"95%"}
                        className={classes.input_layout_schoolname}
                        change={(e) => setTitle(e.target.value)}
                        bgColor="#ffffff"
                        value={title}
                    />
                    <InputDashboard
                        placeholder={"Author Name"}
                        width={"95%"}
                        className={classes.input_layout_schoolname}
                        change={(e) => setAuthor(e.target.value)}
                        bgColor="#ffffff"
                        value={author}
                    />
                    <InputDropdown
                        placeholder={"Blog Category"}
                        width={"95%"}
                        className={classes.input_layout_boardname}
                        bgColor="#fff"
                        value={blogCategory}
                        defaultValues={[
                            "General",
                            "Child Development",
                            "Nutrition & Diet",
                            "Mental Health",
                            "Parental Guidance",
                            "Physical Health",
                            "Kids Vision",
                            "Wellness",
                            "Other",
                        ]}
                        setValue={setBlogCategory}
                    />
                </div>
                <div>
                    <ReactQuill
                        theme="snow"
                        placeholder="Enter Blog Description"
                        style={{ width: "98%" }}
                        modules={{
                            toolbar: [
                                [{ header: "1" }, { header: "2" }, { font: [] }],
                                [{ size: [] }],
                                ["bold", "italic", "underline", "strike", "blockquote"],
                                [
                                    { list: "ordered" },
                                    { list: "bullet" },
                                    { indent: "-1" },
                                    { indent: "+1" },
                                ],
                                ["link", "image", "video"],
                                ["clean"],
                            ],
                            clipboard: {
                                // toggle to add extra line breaks when pasting HTML:
                                matchVisual: false,
                            },
                        }}
                        formats={[
                            "header",
                            "font",
                            "size",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                            "list",
                            "bullet",
                            "indent",
                            "link",
                            "image",
                            "video",
                        ]}
                        className={classes.input_layout_schoolname}
                        value={description}
                        onChange={setDescription}
                    />
                </div>
                <div style={{ margin: '1rem 0', display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <input type="file" onChange={handleOnChange} style={{ display: "none" }} ref={fileInputRef} />
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <DownloadOutlined style={{ color: "#222cc9" }} />
                        <p onClick={() => fileInputRef.current.click()} style={{ cursor: "pointer", color: "#222cc9" }}>Upload Image</p>
                    </div>
                    {thumbnail && <div style={{ position: 'relative' }}>
                        <CancelOutlined onClick={() => setThumbnail(null)} fontSize="small" style={{ cursor: "pointer", color: "#222cc9", position: 'absolute', top: 0, right: 0 }} />
                        <img src={thumbnail} alt="" style={{ width: 100, height: 100, objectFit: "contain" }} />
                    </div>}
                </div>
                {!loading && (
                    <Snackbar
                        open={open}
                        autoHideDuration={3000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    >
                        <Alert
                            onClose={handleClose}
                            severity={!error && !loading ? "success" : "error"}
                            sx={{ width: "100%" }}
                        >
                            {!error
                                ? location.state?.blogId ? "Blog Updated Successfully" : "Blog Added Successfully"
                                : "Something went wrong!"}
                        </Alert>
                    </Snackbar>
                )}
                <div className={classes.buttons}>
                    <button
                        className={classes.button_with_border}
                        onClick={() => navigate(-1)}
                    >
                        CANCEL
                    </button>
                    <button
                        onClick={handleSubmit}
                        className={classes.button_filled}
                        disabled={!title || !description || !author || !blogCategory}
                    >
                        {location.state?.blogId ? "SAVE CHANGES" : "SAVE AS DRAFT"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateBlog;
