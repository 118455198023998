import React, { useCallback, useEffect, useRef } from 'react'
import * as d3 from 'd3';
import './PrincipalScreeningGraph.css'
import moment from 'moment';

function D2CGraph({datax}) {

    const graphRef = useRef(null);

      useEffect(() => {
          
          if(typeof datax?.bhvGraph === "object" && Object.keys(datax?.bhvGraph).length>0)
          {
            document.querySelector("#bhvstackedChart")?.remove()
            let data =[]
            for(var [keys,value] of Object.entries(datax?.bhvGraph)){
              
                const x = {
                    category: moment(keys).format("DD/MM"),
                    type1: value[0],
                    type2: value[1]-value[0]       
                }
                data.push(x)
            }
              var keys = Object.keys(data[0]).filter(k => k !== "category");
              //INITIAL SETUP
              const margin = {
                top: 20,
                bottom: 35,
                left: 30,
                right: 20
              };
              var width = document.querySelector("#behavioural-chart-area").clientWidth - margin.left - margin.right;
              var height = 350 - margin.top - margin.bottom;
              var barwidth = 40;
              
              var graph = d3
                .select("#behavioural-chart-area")
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .attr("id","bhvstackedChart")
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
              
              //SCALES
              var x = d3
                .scaleBand()
                .domain(
                  data.map(function(d) {
                    return d.category;
                  })
                )
                .range([0, width]);
              
              var y = d3
                .scaleLinear()
                .range([height, 0])
                .domain([0, 100]);
              
              var z = d3.scaleOrdinal().range(["#72B97A", "#6C60F3"]);
              
              //AXES
              var xAxis = d3.axisBottom(x);
              graph
                .append("g")
                .attr("class", "x-axis")
                .attr("transform", "translate(0," + height + ")")
                .call(xAxis);
              
              var yAxis = d3.axisLeft(y);
              
              z.domain(keys);
              
              var stack = d3
                .stack()
                .keys(keys)
                .order(d3.stackOrderNone)
                .offset(d3.stackOffsetNone);
              
              var layers = stack(data);
              
              y.domain([0, 1.1 * d3.max(layers[layers.length - 1], d => d[1])]);
              
              graph
                .append("g")
                .attr("class", "y-axis")
                .call(yAxis);
              
              var layer = graph
                .selectAll(".layer")
                .data(layers)
                .enter()
                .append("g")
                .attr("class", "layer")
                .style("fill", function(d) {
                  return z(d.key);
                });
                            
              layer
                .selectAll("rect")
                .data(function(d) {
                  return d;
                })
                .enter()
                .append("rect")
                .attr("class", "bar-placeholder")
                .attr("x", function(d) {
                  return x(d.data.category) + x.bandwidth() / 2 - 20;
                })
                .attr("height", function(d) {
                  return d;
                })
                .attr("width", 40);
              
              layer
                .selectAll("bar-placeholder")
                .data(function(d) {
                  return d;
                })
                .enter()
                .append("rect")
                .attr("class", "bar")
                .attr("x", function(d) {
                  return x(d.data.category) + x.bandwidth() / 2 - 20;
                })
                .attr("y", function(d) {
                  return y(d[1]);
                })
                .attr("height", function(d) {
                  return y(d[0]) - y(d[1]);
                })
                .attr("width", 40);
              
              layer
                .selectAll("text")
                .data(function(d) {
                  return d;
                })
                .enter()
                .append("text")
                .attr("class", "bar-label")
                .attr("x", function(d) {
                  return x(d.data.category) + x.bandwidth() / 2;
                })
                .attr("y", function(d) {
                  return y(d[1]) - 5;
                })
                .text(function(d) {
                  return d.data.type1 + d.data.type2;
                });
              
        }
    }
    , [datax])
      
    
       
    return (
        <div id='behavioural-chart-area' />
    )
}

export default React.memo(D2CGraph)