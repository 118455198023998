import { ClassNames } from '@emotion/react';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import React from 'react'
import classes from './ValidationButtons.module.css'
import ValidationCheckGreen from '../../../../assets/images/ValidationGreenCheck';
import ValidationRedCheck from '../../../../assets/images/ValidationRedCheck';
import ValidationRetake from '../../../../assets/images/validationYellowRetake';

const ValidationButtons = (props) => {
    return (
        <div
            onClick={props?.onClick}
            className={props?.type === "good"
                ? classes.vbgb
                : props?.type === "reexam"
                    ? classes.vbyb
                    : classes.vbrb}>
            {
                props?.type === "good"
                    ?
                    <>
                        <ValidationCheckGreen />
                        <p>Good</p>
                    </>
                    :
                    props?.type === "reexam"
                        ?
                        <>
                            <ValidationRetake />
                            <p>Request Re-Exam</p>
                        </>
                        :
                        <>
                            <ValidationRedCheck />
                            <p>Issue</p>
                        </>
            }

        </div >
    )
}

export default ValidationButtons