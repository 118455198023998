import React from "react";
import classes from "./CD2.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import mcneil from "../../../assets/images/McNeil.svg";
import nichq from "../../../assets/images/NICHQ.svg";
import americanPediatrics from "../../../assets/images/americanPediatrics.svg";

export const CD2 = ({ data }) => {
  const performanceQuestionBank = [
    {
      qno: 2,
      question: "Reading",
      answer: data?.output?.[1]?.answers?.[1]
    },
    {
      qno: 3,
      question: "Writing",
      answer: data?.output?.[1]?.answers?.[2]
    },
    {
      qno: 4,
      question: "Mathematics",
      answer: data?.output?.[1]?.answers?.[3]
    },
    {
      qno: 5,
      question: "Relationships with parents",
      answer: data?.output?.[1]?.answers?.[4]
    },
    {
      qno: 6,
      question: "Relationships with siblings",
      answer: data?.output?.[1]?.answers?.[5]
    },
    {
      qno: 7,
      question: "Relationships with peers",
      answer: data?.output?.[1]?.answers?.[6]
    },
    {
      qno: 8,
      question: "Participation in organised activities (eg, teams)",
      answer: data?.output?.[1]?.answers?.[7]
    },
  ];

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>

        <div
          className={classes.performance_question_container}
          style={{ margin: "24px 0 0 0" }}
        >
          <div>Performance Questionnaire</div>
          <div className={classes.question_select_container}>
            {performanceQuestionBank.map((el) => {
              return (
                <div className={`${classes.question_radio_box}`}>
                  <p className={classes.box_min_range}>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form style={{ columnGap: "20px" }}>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Excellent"
                          checked={el.answer === "EXCELLENT"}
                        />
                        <p>Excellent</p>
                      </div>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Above Average"
                          checked={el.answer === "ABOVE_AVERAGE"}
                        />
                        <p>Above Average</p>
                      </div>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Average"
                          checked={el.answer === "AVERAGE"}
                        />
                        <p>Average</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Somewhat of a problem"
                          checked={el.answer === "SOMEWHAT_OF_A_PROBLEM"}
                        />
                        <p style={{ width: "52px" }}>Somewhat of a problem</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Problematic"
                          checked={el.answer === "PROBLEMATIC"}
                        />
                        <p>Problematic</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes.copyright_details_container}>
          <div>
            The information contained in this publication should not be used as
            a substitute for the medical care and advice of your psychologist.
            There may be variations in treatment that your psychologist may
            recommend based on individual facts and circumstances.
          </div>
          <div>
            Copyright @2002 American Academy of Pediatrics and National
            Initiative for Children’s Healthcare Quality
            <br /> Adapted from the Vanderbilt Rating Scale developed by Mark L.
            Wolraich, MD.
            <br /> Revised - 1102
          </div>
        </div>
        <div className={classes.method_framework}>Method Framework</div>

        <div className={classes.certification_container}>
          <img src={americanPediatrics} alt="americanPediatrics" />
          <img src={nichq} alt="nichq" />
          <img src={mcneil} alt="mcneil" />
        </div>

        <div className={classes.footer_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 2 of 2</p>
          </div>
        </div>
      </div>
    </>
  );
};
