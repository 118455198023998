import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./SchoolStaff.module.css";
import SchoolStaffRow from "./SchoolStaffRow";
import schoolReducer from "../../../../redux/reducers/school.reducer";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../../common/TableComponents/BreadCrumbs";
import TabButton from "../../../common/TableComponents/TabButton";
import SearchBar from '../../../common/TableComponents/SearchBar'
import { City } from "country-state-city";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CustomAutoComplete from "../../../common/CustomAutoComplete/CustomAutoComplete";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import userReducer from "../../../../redux/reducers/user.reducer";
import BlankDataScreen from "../../../BlankDataScreen/BlankDataScreen";

function SchoolStaffPage({ data, loading }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const getSchools = schoolReducer.actions.getSchoolsRequest;
  const breadCrumbs = [
    "School Staff"
  ]

  function getWordBeforeDash() {
    let newStr = "";
    for (let i = 1; i < location.pathname.length; i++) {
      if (location.pathname[i] === "-") {
        return newStr;
      }
      newStr += location.pathname[i];
    }
  }

  const allCities = [...new Set(City.getCitiesOfCountry("IN").map((x) => x.name))]
  const [text, setText] = useState("");
  const [displayData, setDisplayData] = useState(data ?? []);
  const [city, setCity] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageNo, setPageNo] = useState(1)

  useEffect(() => {
    if (city && text) {
      setDisplayData(data.filter((x) => x.city === city && `${x.given_name} ${x.family_name}`?.toLowerCase().includes(text.toLowerCase())))
      setPageNo(1)
    }
    else if (city) {
      setDisplayData(data.filter((x) => x.city === city))
      setPageNo(1)
    }
    else if (text) {
      setDisplayData(data.filter((x) => `${x.given_name} ${x.family_name}`?.toLowerCase().includes(text.toLowerCase())))
      setPageNo(1)
    }
    else {
      setDisplayData(data)
    }
  }, [city, text])

  useEffect(() => {
    setDisplayData(data)
  }, [data])

  const handleCity = (x) => {
    setCity(x)

    if (displayData?.length === data.length) {
      setDisplayData(data.filter((x) => x.city === x))
    }
    // setDisplayData()
  };


  return (
    <div className={classes.main_div}>
      <div className={classes.student_layout}>
        <div>
          <BreadCrumbs />
        </div>
        <div className={classes.tabBtn_container}>
          <TabButton role="User" />
        </div>
      </div>
      {data.length > 0 ?
        <>
          <div className={classes.student_filters}>
            <div className={classes.show_text}>Show</div>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Rows</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                label="Rows"
                onChange={(e) => setRowsPerPage(e.target.value)}
                size='small'
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
            <CustomAutoComplete options={allCities} value={city} placeholder={"City"} onChange={setCity} />
            <SearchBar id={"searchCy"} onchange={setText} value={text} />
          </div>
          {/* Student Table Header */}
          <div className={classes.student_table_header}>
            <div>USER NAME</div>
            <div>EMAIL ID</div>
            <div>ROLE</div>
            <div>STATUS</div>
            <div style={{ justifySelf: "center" }}>ACTION</div>
          </div>
          {/* Student Column-Rows Section */}
          <div id="schoolRowCy" className={classes.student_column_container}>
            {displayData?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo).map((el, index) => {
              return (
                <SchoolStaffRow data={el} key={index + Date.now()} index={index} />
              )
            })}
          </div>
          <div className={classes.pagination_container}>
            <div className={classes.page_item}>{`Showing ${(rowsPerPage * (pageNo - 1)) + 1} to ${pageNo * rowsPerPage <= displayData?.length ? pageNo * rowsPerPage : displayData?.length} of ${displayData?.length} items`}</div>
            <div className={classes.pagination}>
              <Button size="small" disabled={pageNo === 1} onClick={() => setPageNo(pageNo - 1)} >
                <ChevronLeft />
              </Button>
              <span>{pageNo}</span>
              <div>
                <span>of</span>
                <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
              </div>

              <div>
                <Button size="small" disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)} onClick={() => setPageNo(pageNo + 1)} >
                  <ChevronRight />
                </Button>
              </div>
            </div>
          </div>
        </>
        :
        loading ? "" :
          <BlankDataScreen title="Oops! No data available" description="There is no data uploaded in this table to be shown. Click on Add Staff to upload data." buttonText="Add Staff" role="User" />

      }

    </div>
  );
}

function SchoolStaff() {

  const dispatch = useDispatch();

  const getParentsUsers = userReducer.actions.getUserListforSuperadminRequest;

  useEffect(() => {
    dispatch(getParentsUsers("external"));
  }, [])

  const data = useSelector((state) => state.userReducer.users)
  const loading = useSelector((state) => state.userReducer.loading)

  return (
    <SchoolStaffPage data={data} loading={loading} />
  );
}
export default SchoolStaff;