import { createSlice } from "@reduxjs/toolkit";
import InitialStore from "../store/InitialStore";

export const getStudentsRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.data = []
    state.errorMessage = ''
};

export const getStudentByIDRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.data = []
    state.errorMessage = ''
};

export const deleteStudentCache = (state, action) => {
    state.data = [];
}

export const getStudentByClassRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.data = []
    state.errorMessage = ''
};

export const getStudentBySchoolIDRequest = (state, action) => {
    if (!action.payload.nextToken) {
        state.data = []
    }
    state.loading = true
    state.error = false
    state.errorMessage = ''
};

export const getStudentBySectionRequest = (state, action) => {
    state.loading = true
    state.error = false
    if (!action.payload.nextToken) {
        state.data = []
    }
    state.errorMessage = ''
};

export const getStudentsScuccess = (state, action) => {
    state.data = [...state.data, ...action.payload]
    state.loading = false
};

export const getStudentsFail = (state, action) => {
    state.error = true
    state.errorMessage = action.payload.error.message
    state.loading = false
};

export const createStudent = (state, action) => {
    state.loading = true
    state.error = false
    state.data = []
    state.errorMessage = ''
};

export const createStudentScuccess = (state, action) => {
    state.loading = false
};

export const createStudentFail = (state, action) => {
    state.error = true
    state.errorMessage = action.payload.error.message
    state.loading = false
};

export const updateStudentRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.errorMessage = ''
};

export const updateStudentScuccess = (state, action) => {
    state.loading = false
};

export const updateStudentFail = (state, action) => {
    state.error = true
    state.errorMessage = action.payload.error.message
    state.loading = false
};


export const deleteStudentRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.errorMessage = ''
};

export const deleteStudentSuccess = (state, action) => {
    state.loading = false
};

export const deleteStudentFail = (state, action) => {
    state.error = true
    state.errorMessage = action.payload.error.message
    state.loading = false
};

export const addStudentHealthLogRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.errorMessage = ''
};

export const addStudentHealthLogSuccess = (state, action) => {
    state.loading = false
};

export const addStudentHealthLogFail = (state, action) => {
    state.error = true
    state.errorMessage = action.payload.error.message
    state.loading = false
};

export const updateStudentStatusRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.errorMessage = ''
};

export const updateStudentStatusScuccess = (state, action) => {
    state.loading = false
};

export const updateStudentStatusFail = (state, action) => {
    state.error = true
    state.errorMessage = action.payload.error.message
    state.loading = false
};

export default createSlice({
    name: "studentReducer",
    initialState: InitialStore.students,
    reducers: {
        getStudentsRequest,
        getStudentByClassRequest,
        getStudentByIDRequest,
        deleteStudentCache,
        getStudentBySchoolIDRequest,
        getStudentBySectionRequest,
        getStudentsScuccess,
        getStudentsFail,
        createStudent,
        createStudentScuccess,
        createStudentFail,
        updateStudentRequest,
        updateStudentScuccess,
        updateStudentFail,
        deleteStudentRequest,
        deleteStudentSuccess,
        deleteStudentFail,
        addStudentHealthLogRequest,
        addStudentHealthLogSuccess,
        addStudentHealthLogFail,
        updateStudentStatusRequest,
        updateStudentStatusScuccess,
        updateStudentStatusFail
    }
})