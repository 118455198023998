const InitialStore = {
    auth: {
        loggedIn: false
    },
    user: {
        currentUser: {},
        users: [],
        loading: false,
        currentUserLoading: false,
        error: false,
        errorMessage: '',
        bulkUploadLoading: false,
        bulkUploadError: true,
    },
    support: {
        tickets: [],
        loading: false,
        error: false,
        errorMessage: ''
    },
    blog: {
        blogs: [],
        loading: false,
        error: false,
        errorMessage: ''
    },
    schools: {
        data: [],
        loading: false,
        error: false,
        errorMessage: '',
        created: {}
    },
    students: {
        data: [],
        loading: false,
        error: false,
        errorMessage: ''
    },
    clinic: {
        data: [],
        loading: false,
        error: false,
        errorMessage: ''
    },
    screening: {
        welchallynLink: '',
        operators: [],
        bhvData: {},
        bulkReportData: [],
        data: {},
        opsManagerStudents: [],
        testData: {
            dentalAssessment: {
                decoloration: false,
                gingivitis: false,
                orthodontic: false,
                tooth: [
                    "Q11_N,Q12_N,Q13_N,Q14_N,Q15_N,Q16_N,Q17_N,Q18_N,Q1A_N,Q1B_N,Q1C_N,Q1D_N,Q1E_N,Q21_N,Q22_N,Q23_N,Q24_N,Q25_N,Q26_N,Q27_N,Q28_N,Q2A_N,Q2B_N,Q2C_N,Q2D_N,Q2E_N,Q31_N,Q32_N,Q33_N,Q34_N,Q35_N,Q36_N,Q37_N,Q38_N,Q3A_N,Q3B_N,Q3C_N,Q3D_N,Q3E_N,Q41_N,Q42_N,Q43_N,Q44_N,Q45_N,Q46_N,Q47_N,Q48_N,Q4A_N,Q4B_N,Q4C_N,Q4D_N,Q4E_N"
                ]
            },
            hairAssessment: {
                color: null,
                dandruff: false,
                lossOfHair: false,
                pediculosis: false
            },
            vitalSignsAssessment: {
                weight: null,
                temperature: null,
                respiratoryRate: null,
                bloodPressure: null,
                height: null,
                BMI: null,
                SpO2: null,
                heartRate: null,
                hemoglobin: null
            },
            screeningStatus: {

                abdomin: {
                    auscultation: false
                },
                cough: {
                    exam: false
                },
                dental: {
                    decoloration: false,
                    gingivitis: false,
                    orthodontic: false,
                    tooth: false
                },
                ear: {
                    leftEarVideo: false,
                    rightEarVideo: false
                },
                eye: {
                    normalVision: false
                },
                hair: {
                    color: false,
                    dandruff: false,
                    lossOfHair: false,
                    pediculosis: false
                },
                hearing: {
                    hearingAssessment: false,
                    resultAwait: false
                },
                heart: {
                    estimation: false
                },
                lungs: {
                    auscultation: false
                },
                skin: {
                    video: false
                },
                throat: {
                    video: false
                },
                vitalSigns: {
                    SpO2: false,
                    bloodPressure: false,
                    heartRate: false,
                    height: false,
                    hemoglobin: false,
                    temperature: false,
                    weight: false
                }
            }
        }
    },
    dashboard: {
        data: [],
        liveScreeningData: [],
        liveDoctorValidationData: [],
        loading: false,
        error: false,
        errorMessage: ''
    },
    validation: {
        data: [],
        reTake : {},
        validatedStudents: [],
        validationDone: false,
        assignCountData: [],
        loading: false,
        error: false,
        errorMessage: ''
    },
    events: {
        data: [],
        eventMapData: [],
        loading: false,
        error: false,
        errorMessage: '',
        classList: {}
    },
    typeform: {
        loading: false,
        error: false,
        errorMessage: ''
    },
    increment: {
        index: 0,
        play: false
    },
    analytics: {
        data: [],
        loading: false,
        error: false,
        errorMessage: ''
    }
};

export default InitialStore;