import { API, graphqlOperation } from "aws-amplify";
import Cookies from "js-cookie";

function getUsers() {
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `query MyQuery {
            getUsers(count: 10) {
              email
              family_name
              gender
              given_name
              groups
              userId
            }
          }`,
    authToken: token
  })
}

function createUser(payload) {
  const token = Cookies.get('tokenId')
  const params = payload.payload;

  if (params.role === "skids-doctor" || params.role === "skids-nurse") {
    return API.graphql(graphqlOperation(
      `mutation MyMutation($phone: AWSPhone) {
        createUser(email: "${params.email}", family_name: "${params.lastName}", gender: "${params.gender}", given_name: "${params.firstName}", groups: "${params.role}", clinicId: "${params.clinicId}", specialities:${params.specialities}, salutation: "${params.salutation}", phone: $phone){
          data
          message
          statusCode
        }
      }`,
      { phone: `+91${params.phone}` }, token
    ))
  } else if (params.role?.includes("ops")) {
    return API.graphql(graphqlOperation(
      `mutation MyMutation($phone: AWSPhone) {
          createUser(email: "${params.email}", family_name: "${params.lastName}", gender: "${params.gender}", given_name: "${params.firstName}", groups: "${params.role}", salutation: "${params.salutation}", phone: $phone){
            data
            message
            statusCode
          }
        }`,
      { phone: `+91${params.phone}` }, token
    ))
  } else if (params.role === 'school-teacher') {

    return API.graphql(graphqlOperation(
      `mutation MyMutation($phone: AWSPhone) {
        createUser(email: "${params.email}", family_name: "${params.lastName}", gender: "${params.gender}", given_name: "${params.firstName}", classVal: "${params.schoolClass}", schoolId: "${params.schoolId}", section: "${params.section}", groups: "${params.role}", salutation: "${params.salutation}", phone: $phone){
          data
          message
          statusCode
        }
      }`,
      { phone: `+91${params.phone}` }, token
    ))
  }
  return API.graphql(graphqlOperation(
    `mutation MyMutation($phone: AWSPhone) {
        createUser(email: "${params.email}", family_name: "${params.lastName}", gender: "${params.gender}", given_name: "${params.firstName}", groups: "${params.role}", schoolId: "${params.schoolId}", salutation: "${params.salutation}", phone: $phone){
          data
          message
          statusCode
        }
      }`,
    { phone: `+91${params.phone}` }, token
  ))
}

function createSchoolInfirmary(payload) {
  const token = Cookies.get('tokenId')
  const params = payload.payload
  if (params?.role === "doctor") {
    return API.graphql(graphqlOperation(
      `mutation MyMutation($phone: AWSPhone) {
          createUser(email: "${params?.email}", family_name: "${params?.lastName}", gender: "${params?.gender}", given_name: "${params?.firstName}", groups: "school-doctor", schoolId: "${params?.schoolId}", salutation: "${params.salutation}", phone: $phone){
            data
            message
            statusCode
          }
        }`,
      { phone: `+91${params.phone}` }, token
    ))
  }
  else {
    return API.graphql(graphqlOperation(
      `mutation MyMutation($phone: AWSPhone) {
        createUser(email: "${params?.email}", family_name: "${params?.lastName}", gender: "${params?.gender}", given_name: "${params?.firstName}", groups: "school-nurse", schoolId: "${params?.schoolId}", salutation: "${params.salutation}", phone: $phone){
          data
          message
          statusCode
        }
      }`,
      { phone: `+91${params.phone}` },
      token
    ))
  }
}

function getUsersByGroups(payload) {
  const token = Cookies.get('tokenId')
  const params = payload.payload
  if (params.role === "skids-doctor") {
    return API.graphql({
      query: `query MyQuery {
        getUsersByGroups(groups: "${params.role}", clinicCode: "${params.clinicId}") {
          groups
          given_name
          email
          family_name
          gender
          schoolId
          userId
          specialities
          salutation
          phone
        }
      }`,
      authToken: token
    })
  }
  const customQuery = params.schoolId
    ? `groups: "${params.role}", schoolId: "${params.schoolId}"`
    : `groups: "${params.role}"`
  return API.graphql({
    query: `query MyQuery {
        getUsersByGroups(${customQuery}) {
          groups
          given_name
          email
          family_name
          gender
          schoolId
          userId
          class
          section
          specialities
          salutation
          phone
          students
        }
      }`,
    authToken: token
  })
}

function getUserById(payload) {
  const token = Cookies.get('tokenId')
  const params = payload.payload
  return API.graphql({
    query: `query MyQuery {
        getUserById(email: "${params.email}", userId: "${params.sub}") {
          email
          family_name
          gender
          given_name
          groups
          schoolId
          userId
          class
          section
          specialities
          phone
          userAttributes {
            state
          }
        }
      }`,
    authToken: token
  })
}

function getUserListForSuperadmin(payload) {
  const token = Cookies.get('tokenId')
  const params = payload.payload
  return API.graphql({
    query: `query MyQuery {
        getUsersListSuperAdmin(groups: "${payload.payload}") {
          groups
          email
          family_name
          gender
          given_name
          userId
          phone
        }
      }`,
    authToken: token
  })
}

function getDoctorsBySpeciality(payload) {
  const token = Cookies.get('tokenId')
  const params = payload.payload
  return API.graphql({
    query: `query MyQuery {
        getDoctorsBySpeciality(groups: "skids-doctor", specialities: "${params}") {
          userId
          specialities
          section
          schoolId
          groups
          given_name
          gender
          family_name
          email
          clinicId
          class
        }
      }`,
    authToken: token
  })
}


function getDoctors() {
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `query MyQuery {
        getDoctors(groups: "skids-doctor") {
          userId
          specialities
          section
          schoolId
          groups
          given_name
          gender
          family_name
          email
          clinicId
          class
        }
      }`,
    authToken: token
  })
}


function getAllClinicDoctors(payload) {
  const token = Cookies.get('tokenId')
  const params = payload.payload
  return API.graphql({
    query: `query MyQuery {
        getAllClinicDoctors(clinicCode: "${payload.payload}") {
          family_name
          schoolId
          clinicId
          gender
          class
          section
          specialities
          given_name
          email
        }
      }`,
    authToken: token
  })
}

function getAllClinicNurses(payload) {
  const token = Cookies.get('tokenId')
  const params = payload.payload
  return API.graphql({
    query: `query MyQuery {
        getAllClinicNurses(clinicCode: "${payload.payload}") {
          family_name
          schoolId
          clinicId
          gender
          class
          section
          specialities
          given_name
          email
        }
      }`,
    authToken: token
  })
}

function updateUser(payload) {
  const token = Cookies.get('tokenId')
  const params = payload.payload
  return API.graphql({
    query: `mutation MyMutation {
      updateUser(email: "${params.email}", userId: "${params.userId}", classVal: "${params.classVal}", clinicId: "", family_name: "${params.lastName}", gender: "${params.gender}", given_name: "${params.lastName}", schoolId: "${params.schoolId}", section: "${params.section}", specialities: "")
    }`,
    authToken: token
  })
}

function getSchoolInfirmary(payload) {
  const token = Cookies.get('tokenId')
  const params = payload.payload
  return API.graphql({
    query: `query MyQuery {
      getSchoolStaffListSuperAdmin(schoolId: "${params}") {
        schoolInfirmary {
          email
          family_name
          gender
          given_name
          groups
          schoolId
          phone
        }
      }
    }`,
    authToken: token
  })
}

function bulkTeacherUpload(payload) {
  const token = Cookies.get('tokenId')
  const params = payload.payload
  const dataArray = params.teachers

  return API.graphql(graphqlOperation(`
    mutation MyMutation($objects: [TeacherInput!]!, $schoolId: String!)
     {
      batchTeacherUpload(
        input: $objects, 
        schoolId: $schoolId
        ){
        message
        statusCode
      }
    }
  `, { objects: dataArray, schoolId: params?.schoolId }, token));
}

function getUserByEmail(payload) {
  const token = Cookies.get('tokenId')

  return API.graphql({
    query: `query MyQuery {
      getUserByEmail(email: "${payload}") {
        class
        clinicId
        email
        family_name
        gender
        given_name
        groups
        schoolId
        section
        specialities
        userId
      }
    }
    `,
    authToken: token
  })
}

const user = {
  getUsers,
  getUsersByGroups,
  createUser,
  getUserById,
  getUserByEmail,
  getUserListForSuperadmin,
  getDoctorsBySpeciality,
  getDoctors,
  getAllClinicDoctors,
  getAllClinicNurses,
  updateUser,
  createSchoolInfirmary,
  getSchoolInfirmary,
  bulkTeacherUpload
}

export default user;