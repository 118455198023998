
import bitmap from "../../../assets/images/bitmap.png";
import { useEffect, useRef, useState } from 'react';
import rectangle from "../../../assets/images/rectangle.png";
import initial from "../../../assets/images/initial.svg";
import questionmark from "../../../assets/images/questionmark.svg";
import line from "../../../assets/images/line.svg";
import classes from "./Navbar.module.css"
import { useLocation, useNavigate } from 'react-router-dom';
import notification from "../../../assets/images/notification.svg";
import NoNotification from "../../../assets/images/NoNotification.svg";
import { Avatar } from "@mui/material";
import { blue } from "@mui/material/colors";
import triangle from "../../../assets/images/triangle.svg";
import authReducers from "../../../redux/reducers/auth.reducers";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import userReducer from "../../../redux/reducers/user.reducer";
import { stringAvatar } from "../../../utils/utility";

const Navbar = () => {
    const logOut = authReducers.actions.logout
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [openUserPop, setOpenUserPop] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.userReducer.currentUser)

    const resetUser = userReducer.actions.resetCognitoUser;


    let userInfo;
    let dropdown_obj;

    function getWordBeforeDash() {
        let newStr = "";
        for (let i = 1; i < location.pathname.length; i++) {
            if (location.pathname[i] === "-" || location.pathname[i] === "/") {
                return newStr;
            }
            newStr += location.pathname[i];
        }
    }



    // const handleOpen = () => {
    //     setOpen(!open);
    //     setNotificationOpen(false)
    // };

    const handleNotification = () => {
        setNotificationOpen(!notificationOpen);
    }

    const handleOptions = async (item) => {
        if (item.label === "Logout") {
            dispatch(logOut())
            Cookies.remove("tokenId")
            dispatch(resetUser())
            navigate("/login")
            // navigate("/login")
        }
        else if (item.label === "My Profile") {
            navigate(item.link, { state: user })
        }
        else if (item.label === "School Information") {
            navigate(item.link)
        }
    };

    let useClickOutside = (handler) => {
        let domNode = useRef()

        useEffect(() => {
            let maybeHandler = (event) => {
                if (!domNode.current.contains(event.target)) {
                    handler();
                }
            }
            document.addEventListener("mousedown", maybeHandler)

            return () => {
                document.removeEventListener("mousedown", maybeHandler)
            }
        }, [])
        return domNode;
    }

    let domNode = useClickOutside(() => {
        setOpen(false)
        setNotificationOpen(false)
    })

    if (getWordBeforeDash() === "principal") {
        userInfo = {
            user_name: `${user.given_name} ${user.family_name}`,
            user_role: "PRINCIPAL",
            image: rectangle,
            bar: questionmark,
            helpLink: "/principal-helpsupport"
        };
        dropdown_obj = [
            { label: "My Profile", link: "/principal-profile" },
            { label: "School Info", link: "/principal-schoolinfo" },
            { label: "Logout", link: "/login" },
        ];

    } else if (getWordBeforeDash() === "admin") {
        userInfo = {
            user_name: `${user.given_name} ${user.family_name}`,
            user_role: "IT ADMIN",
            image: initial,
            bar: questionmark,
            line: line,
            helpLink: "/admin-helpsupport"
        }
        dropdown_obj = [
            { label: "My Profile", link: "/admin-profile" },
            { label: "School Info", link: "" },
            { label: "Logout", link: "/login" },
        ];
    } else if (getWordBeforeDash() === "ops" && location.pathname.includes("manager")) {
        userInfo = {
            user_name: `${user?.given_name} ${user?.family_name}`,
            user_role: "Ops",
            image: initial,
            bar: questionmark,
            line: line,
        };
        dropdown_obj = [
            { label: "My Profile", link: "/ops-manager-profile" },
            { label: "Logout", link: "/login" },
        ];

    }
    else if (getWordBeforeDash() === "ops" && location.pathname.includes("nurse")) {
        userInfo = {
            user_name: `${user?.given_name} ${user?.family_name}`,
            user_role: "Ops",
            image: initial,
            bar: questionmark,
            line: line,
        };
        dropdown_obj = [
            { label: "My Profile", link: "/ops-nurse-profile" },
            { label: "Logout", link: "/login" },
        ];

    }
    else if (getWordBeforeDash() === "superadmin") {
        userInfo = {
            user_name: `${user?.given_name} ${user?.family_name}`,
            user_role: "SUPER ADMIN",
            image: initial,
            bar: questionmark,
            line: line,
        };
        dropdown_obj = [
            { label: "My Profile", link: "/superadmin-profile" },
            { label: "Logout", link: "/login" },
        ];
    }

    else if (getWordBeforeDash() === "partner") {
        userInfo = {
            user_name: `${user?.given_name} ${user?.family_name}`,
            user_role: "PARTNER ADMIN",
            image: initial,
            bar: questionmark,
            line: line,
        };
        dropdown_obj = [
            { label: "My Profile", link: "/superadmin-profile" },
            { label: "Logout", link: "/login" },
        ];
    }
    
    else if (getWordBeforeDash() === "support") {
        userInfo = {
            user_name: `${user?.given_name} ${user?.family_name}`,
            user_role: "SUPPORT ADMIN",
            image: initial,
            bar: questionmark,
            line: line,
        };
        dropdown_obj = [
            { label: "My Profile", link: "/support-profile" },
            { label: "Logout", link: "/login" },
        ];
    }

    else if (getWordBeforeDash() === "writer") {
        userInfo = {
            user_name: `${user?.given_name} ${user?.family_name}`,
            user_role: "CONTENT WRITER",
            image: initial,
            bar: questionmark,
            line: line,
        };
        dropdown_obj = [
            { label: "My Profile", link: "/writer-profile" },
            { label: "Logout", link: "/login" },
        ];
    }

    else if (getWordBeforeDash() === 'teacher') {

        userInfo = {
            user_name: `${user.given_name} ${user.family_name}`,
            user_role: "Class Teacher",
            image: rectangle,
            bar: questionmark,
            helpLink: '/teacher-helpsupport'
        };
        dropdown_obj = [
            { label: "My Profile", link: "/teacher-dashboard" },
            { label: "School Info", link: "" },
            { label: "Logout", link: "/login" },
        ];
    }
    else if (getWordBeforeDash() === 'doctor') {

        userInfo = {
            user_name: `${user?.given_name} ${user?.family_name}`,
            user_role: "Doctor",
            image: rectangle,
            bar: questionmark,
            helpLink: '/teacher-helpsupport'
        };
        dropdown_obj = [
            { label: "My Profile", link: "/doctor-dashboard" },
            { label: "Logout", link: "/login" },
        ];
    }
    else if (getWordBeforeDash() === 'infirmary') {

        userInfo = {
            user_name: `${user?.given_name} ${user?.family_name}`,
            user_role: "Infirmary",
            image: rectangle,
            bar: questionmark,
            helpLink: '/teacher-helpsupport'
        };
        dropdown_obj = [
            { label: "My Profile", link: "/infirmary-dashboard" },
            { label: "Logout", link: "/login" },
        ];
    }
    else if (getWordBeforeDash() === 'behavioral') {

        userInfo = {
            user_name: `${user?.given_name} ${user?.family_name}`,
            user_role: "Behavioral doctor",
            image: rectangle,
            bar: questionmark,
            helpLink: '/doctor-helpsupport'
        };
        dropdown_obj = [
            { label: "My Profile", link: "/infirmary-dashboard" },
            { label: "Logout", link: "/login" },
        ];
    }


    const notification_obj = [
        { name: "Notification Center", tab: "view all" },
        {
            detail:
                "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
        },
        {
            detail:
                "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
        },
        {
            detail:
                "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
        },
    ];


    return (
        <div className={classes.dashboard_header}>
            <div className={classes.dashboard_header_left}>
                <div>
                    {/* <img src={bitmap} className={classes.main_logo} alt="" /> */}
                </div>
                <div className={classes.header_left_text}>Hello, <span style={{ color: "#1740A1", fontWeight: "bold" }}>{`${user?.given_name} ${user?.family_name}!`}</span></div>
            </div>
            <div className={classes.dashboard_header_right}>
                <div className={classes.dashboard_header_deatils}>
                    <div className={classes.notification}>
                        <img src={userInfo?.bar} alt="" />
                    </div>

                    <div
                        ref={domNode}
                        className={classes.notification}
                        onClick={() => {
                            handleNotification();
                        }}
                    >
                        <img src={NoNotification} alt=""></img>
                    </div>
                    {notificationOpen ? (
                        <div className={classes.dropdown_notification}>
                            {notification_obj.map((item) => (
                                <div>
                                    <div className={classes.notify_Heading}>
                                        <div className={classes.dropdown_notify}>{item.name}</div>
                                        <div className={classes.view_heading}>{item.tab}</div>
                                    </div>
                                    <div className={classes.notify_detail}>
                                        {/* <div>{}</div> */}

                                        <div className={classes.loreum}>{item.detail}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <></>
                    )}
                    {/* <Divider orientation="vertical" variant="fullWidth" flexItem /> */}
                    <div className={classes.dashboard_name}>
                        <div>
                            {getWordBeforeDash() === "principal" ||
                                getWordBeforeDash() === "teacher" ? (
                                <Avatar {...stringAvatar(`${user?.given_name} ${user?.family_name}`)} />
                            ) : (
                                <Avatar {...stringAvatar(`${user?.given_name} ${user?.family_name}`)} />
                            )}
                        </div>
                        <div>
                            <div className={classes.principal_name}>
                                {userInfo?.user_name}
                            </div>

                            <div className={classes.principal_text}>
                                {userInfo?.user_role}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="dropdownCy" className={classes.infoHover}>
                    <div
                        id="logoutArrow"
                        className={classes.dropdown}
                    >
                        <img src={triangle} alt=""></img>
                    </div>

                    <div className={classes.popOnHover}>
                        {dropdown_obj?.map((item) => (
                            <div
                                className={classes.dropdown_items}
                                onClick={() => handleOptions(item)}
                            >
                                {item.label}
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Navbar