import React, { useState, useRef, useEffect } from "react";
import classes from "./Filter.module.css";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

let useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode?.current?.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  }, []);
  return domNode;
};

export const Filter = ({
  label1,
  label2,
  filter1,
  filter2,
  setFilter1,
  setFilter2,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = React.useState([true, false]);
  const handleActive = (event) => {
    setFilter1(!filter1);
  };

  const handleInactive = (event) => {
    setFilter2(!filter2);
  };

  let domNode = useClickOutside(() => {
    setIsOpen(false);
  });

  return (
    <>
    <div ref={domNode} className = {classes.parent_container} >
      <div
        className={classes.filter_container}
        onClick={() => setIsOpen(!isOpen)}
        
      >
        <FilterAltIcon
          sx={{ fill: "#FAF8F7", stroke: "#66707E", strokeWidth: "1px" }}
        />
        <p>Filter</p>
        </div>
        {isOpen ? (
            <div  className={classes.filter_list_container}>
              <FormControlLabel
                label={label1}
                control={<Checkbox checked={filter1} onChange={handleActive} />}
              />
              <FormControlLabel
                label={label2}
                control={
                  <Checkbox checked={filter2} onChange={handleInactive} />
                }
              />
            </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
