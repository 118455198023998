import * as React from "react"
const SvgComponent = (props) => (
    <div style={{ ...props.customStyle }}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke="#812D7F"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                clipPath="url(#a)"
            >
                <path d="M32.513 27.447s4.413-.462 3.06-3.082c-1.352-2.621-3.06-5.167-3.06-5.167S34.759 2.492 20.042 2.492 1.908 15.59 5.467 21.745l3.56 6.159v7.787s-.143 1.804 1.814 1.804h12.704s1.416-.161 1.416-1.408v-1.954S25.324 33 26.82 33h2.341s2 .142 2.356-1.485c.355-1.628.996-4.068.996-4.068Z" />
                <path d="M19.382 10.08s-1.863 4.104.464 7.36c2.328 3.258 6.026 3.848 7.355 2.562 1.329-1.287 1.375-4.562-.855-5.12-2.23-.556-5.55.86-7.97 3.55s-6.505 2.878-7.688.896c-1.183-1.982-.426-4.01 2.045-4.152 2.47-.142 4.929 4.579 5.688 5.285.76.706 3.179 1.651 4.318 1.132 1.14-.52 2.335-2.609 1.721-4.62-.614-2.012-3.192-2.454-4.378-2.69-1.187-.237-7.545-.614-8.589-2.03-1.044-1.415-.616-4.719 2.705-5.38 3.32-.66 5.789 4.251 7.497 5.003 1.708.751 3.748.751 4.506-.993.759-1.744-.474-3.445-3.084-3.208-2.61.236-3.416 1.792-3.416 1.792" />
                <path d="M14.447 13.47s-2.051-3.25.605-3.676c2.657-.425 1.382 4.018 1.382 4.018s-3.11 5.154-2.008 6.773c1.101 1.62 5.321 4.344 8.905.598" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M3.334 1.664h33.333v36.667H3.334z" />
                </clipPath>
            </defs>
        </svg>
    </div>
)
export default SvgComponent
