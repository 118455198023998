import React , { useEffect, useState } from "react";
import classes from "./NursePreScreening.module.css";
import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import userReducer from '../../redux/reducers/user.reducer'
import studentReducer from "../../redux/reducers/student.reducer";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";



export const NursePreScreening = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [studentInfo, setStudentInfo] = useState([])
  const [navigatedData, setNavigatedData] = useState([])
  const [logClick, setLogClick] = useState(false)
  const schoolId = useSelector((state) => state.userReducer.currentUser.schoolId)
  const getStudents = studentReducer.actions.getStudentBySchoolIDRequest;
  const studentData = useSelector((state) => state.studentReducer.data)
  
  useEffect(() => {
    if(schoolId){
      dispatch(getStudents({schoolId}))
    }
  }, [schoolId])

  useEffect(() => {
    if(studentData){
    const temp = studentData?.map((el) => {
      return {label : `${el.admissionNumber} ${el.firstName} ${el.lastName} ${el.class}${el.section}`}
    })
    setStudentInfo(temp) 
    }
  }, [studentData])

  useEffect(() => {
    if(navigatedData && logClick){
      navigate("/infirmary/nurse/student/addlog", { state : { data : navigatedData,
        breadCrumbs : ['Add Log']
      }})
    }
  }, [navigatedData, logClick])


  const handleNavigatedData =  (value) => {
    let admitNo = value?.label.split(' ')
    admitNo = admitNo[0]
    
    setNavigatedData(studentData.filter((el => el.admissionNumber === admitNo)))
    setLogClick(true)
    // let finalSignal = false;
    // const navigationSignal = () => {
    //   return new Promise((resolve, reject) => {

    //       if(navigatedData.length){
    //         finalSignal = true;
    //         resolve(finalSignal)
    //       }else{
    //         reject(finalSignal)
    //       }
    //   })
    // }
    //  navigationSignal().then((res) => {
    //   if(res){
    //     navigate("/infirmary/nurse/student/addlog", { state : { data : navigatedData}})
    //   }
    //  })
    //  .catch((err) => 
}
  
  return (
    <div className={classes.container}>
      <div className={classes.parent_container}>
        <div className={classes.header_text}>Skids Health Infirmary Search</div>
        <div className={classes.searchbar_container}>
          <FormControl
            sx={{
              width: "100%",
              backgroundColor: "#FFF",
              borderColor: "1px solid #E8E7E7",
              borderRadius: "8px",


            }}
            variant="outlined"
          >
            <Autocomplete
              disablePortal
              freeSolo
              id="combo-box-demo"
              options={studentInfo}
              inputStyle={{ textAlign: 'left' }}
              hintStyle={{ width: '100%', textAlign: 'left' }}
              onChange={(event, value) => handleNavigatedData(value)}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}

                  // label="Search student by admission number"
                  placeholder="Search student by admission number or name"
                  sx={{
                    "& .MuiInputBase-root": {
                        "& input": {
                            textAlign: "left",
                            justifyContent : "left",
                            "&::placeholder": {
                              textAlign: "left",
                            },
                        }
                      }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    
                    startAdornment: (
                      <InputAdornment positionStart="start" classes={{ positionStart: "start"}}>
                        <SearchIcon sx = {{fill : "#66707E", marginRight : "10px", marginLeft : "5px"}}/> 
                      </InputAdornment>
                    ),
                  }}
                  
                />
              )}
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
};
