import React from "react";
import classes from "./Depression.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import BhvReportHeader from "../Anxiety/BhvReportHeader";
import clsx from "clsx";


export const Depression = ({ data }) => {
  const response = JSON.parse(data?.DEPRESSION)?.response

  const questionBank = [
    {
      qno: 1,
      question:
        "Do you think your child has little interest or pleasure in doing things?",
      answer: response?.[0]
    },
    {
      qno: 2,
      question: "Feeling down, depressed, or hopeless?",
      answer: response?.[1]
    },
    {
      qno: 3,
      question: "Trouble falling or staying asleep, or sleeping too much?",
      answer: response?.[2]
    },
    {
      qno: 4,
      question: "Feeling tired or having little energy?",
      answer: response?.[3]
    },
    {
      qno: 5,
      question: "Poor appetite or overeating?",
      answer: response?.[4]
    },
    {
      qno: 6,
      question:
        "Feeling bad about yourself or that you are a failure or have let yourself or your family down?",
      answer: response?.[5]
    },
    {
      qno: 7,
      question: `Trouble concentrating on things, such as reading the newspaper or watching television?`,
      answer: response?.[6]
    },
    {
      qno: 8,
      question:
        "Moving or speaking so slowly that other people could have noticed. Or the opposite being so fidgety or restless that you have been moving around a lot more than usual?",
      answer: response?.[7]
    },
    {
      qno: 9,
      question:
        "Thoughts that you would be better off dead, or of hurting yourself?",
      answer: response?.[8]
    },
  ];

  return (
    <>
      <div className={clsx(classes.container, 'xyz')}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>
        <div className={classes.title_container}>
          Hey! This is my Depression assessment report.
        </div>

        <BhvReportHeader data={data} />

        <div className={classes.question_container}>
          <div>Assessment Questionnaire</div>
          <div className={classes.question_select_container}>
            {questionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Not at all"
                          checked={el.answer === "NOT_AT_ALL"}
                        />
                        <p>Not at all</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Several Days"
                          checked={el.answer === "SEVERAL_DAYS"}
                        />
                        <p>Several Days</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="More than half the days"
                          checked={el.answer === "MORE_THAN_HALF_THE_DAYS"}
                        />
                        <p>More than half the days</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Nearly every day"
                          checked={el.answer === "NEARLY_EVERY_DAY"}
                        />
                        <p>Nearly every day</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.copyright_details_container}>
          <div>
            The information contained in this publication should not be used as
            a substitute for the medical care and advice of your psychologist.
            There may be variations in treatment that your psychologist may
            recommend based on individual facts and circumstances.
          </div>
          <div>
            Copyright @2002 American Academy of Pediatrics and National
            Initiative for Children’s Healthcare Quality
            <br /> Adapted from the Vanderbilt Rating Scale developed by Mark L.
            Wolraich, MD.
            <br /> Revised - 1102
          </div>
        </div>

        <div className={classes.footer_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 1 of 2</p>
          </div>
        </div>
      </div>
    </>
  );
};
