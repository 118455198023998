import React, { useEffect, useState } from 'react'
import classes from './InfoCard.module.css'
import ellipsis from '../../assets/images/ellipsis.svg'
import CircularProgressBar from '../CircularProgressBar/CircularProgressBar'
import { Link } from 'react-router-dom'
import CircularProgressWithLabel from '../CircularProgressBar/CircularProgressBar'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, Modal, Typography } from '@mui/material'
import dashboards from '../../graphqlApiServices/dashboard'


export const InfoCard = ({ title, entity, totalText, totalCount, totalPupilCount, data }) => {

    const percentage = 80
    const [open, setOpen] = useState(false)
    let renderCardColor;


    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "fit-content",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 5,
        width: "90vw",
        maxWidth: "500px",
    };

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [doctorModalOpen, setDoctorModalOpen] = useState(false);
    const [currentData, setCurrentData] = useState();
    const [schoolLiveData, setSchoolLiveData] = useState();
    const [doctorLiveData, setDoctorLiveData] = useState();
    const { getSuperAdminLiveSchoolData } = dashboards;

    useEffect(() => {
        if (currentData) {
            getSuperAdminLiveSchoolData({
                schoolId: currentData?.schoolId
            }).then((res) => {
                console.log(res, 'res');
                setSchoolLiveData(res?.data?.schoolLiveData);
            }).catch((err) => console.log(err))
        }
    }, [currentData])


    const handleClose = () => {
        setModalOpen(false);
        setSchoolLiveData();
    }

    const handleCloseDoctorModal = () => {
        setDoctorModalOpen(false);
        setDoctorLiveData();
    }

    switch (entity) {
        case ('Doctor'):
            renderCardColor = `${classes.infocard_parent} ${classes.infocard_color_violet}`
            break;

        case ('School'):
            renderCardColor = `${classes.infocard_parent} `
            break;

        case ('Test'):
            renderCardColor = `${classes.infocard_parent} ${classes.infocard_modal_color_pink}`
            break;

        case ('TestPost'):
            renderCardColor = <div className={classes.health_certificate_wrapper}>
                <div className={classes.health_certificate}></div>
                <div className={classes.health_certificate_download_wrapper}>
                    <FileDownloadOutlinedIcon sx={{ fill: "#3b3cea", width: "16px", height: "16px" }} />
                    <p>Download</p>
                </div>
            </div>

        default:
            renderCardColor = `${classes.infocard_parent}`
            break;
    }

    const getPercentage = (x) => {
        if (x?.screened != '0' && x?.total != '0') {
            const a = x?.screened
            const b = x?.total
            return ((a / b) * 100).toFixed(0)
        }
        else {
            return 0
        }
    }

    const getValidationPercent = (x) => {
        if (x?.totalAssigned && x?.validated) {
            const a = x?.validated
            const b = x?.totalAssigned
            return ((a / b) * 100).toFixed(0)
        }
        else {
            return 0
        }
    }

    const getDoctorType = (x) => {
        if (x?.typeOfScreening === "physicalScreening") {
            return "Physical Screening"
        }
        else if (x?.typeOfScreening === "dentalScreening") {
            return "Dental Screening"
        }
        else if (x?.typeOfScreening === "behaviroralScreening") {
            return "Behavoral Screening"
        }
    }

    const openModal = (x) => {
        setCurrentData(x);
        setModalOpen(true);
    }

    const openDoctorModal = (x) => {
        setDoctorModalOpen(true);
        setDoctorLiveData(x);
    }

    return (
        <>
            <div className={renderCardColor}>
                <div className={classes.titleHeader}>
                    <p className={classes.infocard_title}>{title}</p>
                    <button onClick={() => setOpen(!open)}>
                        <AddCircleIcon sx={{ fill: "#66707E", height: "20px", width: "20px" }} />
                    </button>

                </div>
                <div className={classes.entity_number}>{data?.length} {entity}s</div>
                {/* <div className={classes.totalText}>Total {totalText}: {totalPupilCount}</div> */}
                <div className={classes.live_status}>
                    <img src={ellipsis} />
                    <p>Live</p>
                </div>

                {open ? (
                    <div className={`${entity === 'Doctor' ? `${classes.infocard_modal_container} ${classes.infocard_modal_color_violet}` :
                        `${classes.infocard_modal_container}`}`}>
                        <div className={classes.infocard_modal_title_container}>
                            <p className={classes.infocard_modal_title}>{title}</p>
                            <button onClick={() => setOpen(!open)}>
                                <CancelIcon sx={{ fill: "#66707E", height: "20px", width: "20px" }} />
                            </button>
                        </div>
                        <div className={classes.live_status}>
                            <img src={ellipsis} />
                            <p>Live</p>
                        </div>

                        {
                            entity === "School" && data?.map((x) => {
                                return (
                                    <div className={`${entity == 'Doctor' ? `${classes.infocard_modal_description_container} ${classes.infocard_modal_description_violet_border}
                        ` : `${classes.infocard_modal_description_container}`}`}>
                                        <div className={classes.infocard_modal_description_progress_info_container}>
                                            <div className={classes.infocard_progress_bar}>
                                                <CircularProgressWithLabel value={getPercentage(x)} />
                                            </div>
                                            <div className={classes.infocard_modal_description_information}>
                                                <p className={classes.day_desc}>{`Day ${x?.DayOfScreening}`}</p>
                                                <p className={classes.entity_name}>{x?.schoolName}</p>
                                                <p className={classes.count_outof}>{`${x?.screened}/${x?.total}` ?? "0/0"}</p>
                                                <p className={classes.total_count}>{`Total students ${x?.strength}`}</p>
                                            </div>
                                        </div>
                                        <Link onClick={() => openModal(x)}>
                                            <div className={classes.check_progress}>View Progress</div>
                                        </Link>
                                    </div>
                                )
                            })
                        }

                        {entity === "Doctor" &&
                            <>
                                {
                                    data?.map((x) => {
                                        return (
                                            <div className={`${entity == 'Doctor' ? `${classes.infocard_modal_description_container} ${classes.infocard_modal_description_violet_border}
                                    ` : `${classes.infocard_modal_description_container}`}`}>
                                                <div className={classes.infocard_modal_description_progress_info_container}>
                                                    <div className={classes.infocard_progress_bar}>
                                                        <CircularProgressWithLabel value={getValidationPercent(x)} />
                                                    </div>
                                                    <div className={classes.infocard_modal_description_information}>
                                                        <p className={classes.day_desc}>{getDoctorType(x)}</p>
                                                        <p className={classes.entity_name}>{x?.doctorName}</p>
                                                        {/* <p className={classes.count_outof}><span>Due Date: </span>{x?.validationDueDate}</p> */}
                                                        <p className={classes.total_count}>{`Total students assigned: ${x?.totalAssigned}`}</p>
                                                    </div>
                                                </div>
                                                <Link onClick={() => openDoctorModal(x)}>
                                                    <div className={classes.check_progress}>View Progress</div>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                            </>
                        }
                    </div>
                ) :
                    (
                        <></>
                    )}

            </div>
            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ backdropFilter: "blur(2px)" }}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
                        Screening Progress
                    </Typography>
                    <div className={classes.infocard_modal_description_progress_info_container}>
                        <div className={classes.infocard_progress_bar}>
                            <CircularProgressWithLabel value={getPercentage(currentData)} />
                        </div>
                        <div className={classes.infocard_modal_description_information}>
                            <p className={classes.day_desc}>{`Day ${currentData?.DayOfScreening}`}</p>
                            <p className={classes.entity_name}>{currentData?.schoolName}</p>
                            {schoolLiveData && <div className={classes.count_outof_container}>
                                <p className={classes.count_outof}><span>Screened: </span>{`${currentData?.screened}/${currentData?.total}` ?? "0/0"}</p>
                                <p className={classes.count_outof}><span>Validation Pending: </span>{`${schoolLiveData?.validationPending}` ?? "0/0"}</p>
                                <p className={classes.count_outof}><span>Validated: </span>{`${schoolLiveData?.validated}` ?? "0/0"}</p>
                                <p className={classes.count_outof}><span>Re-exam Students: </span>{`${schoolLiveData?.reexam}` ?? "0/0"}</p>
                                <p className={classes.count_outof}><span>Absent Students: </span>{`${schoolLiveData?.absent}` ?? "0/0"}</p>
                                <p className={classes.total_count}>{`Total students ${currentData?.strength}`}</p>
                            </div>}
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={doctorModalOpen}
                onClose={handleCloseDoctorModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ backdropFilter: "blur(2px)" }}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
                        Screening Progress
                    </Typography>
                    <div className={classes.infocard_modal_description_progress_info_container}>
                        <div className={classes.infocard_progress_bar}>
                            <CircularProgressWithLabel value={getValidationPercent(doctorLiveData)} />
                        </div>
                        <div className={classes.infocard_modal_description_information}>
                            {/* <p className={classes.day_desc}>{`Day ${doctorLiveData?.DayOfScreening}`}</p> */}
                            <p className={classes.entity_name}>{doctorLiveData?.doctorName}</p>
                            {doctorLiveData && <div className={classes.count_outof_container}>
                                <p className={classes.count_outof}><span>Doctor Type: </span>{`${doctorLiveData?.typeOfScreening}` ?? "-"}</p>
                                <p className={classes.count_outof}><span>Total Validations: </span>{`${doctorLiveData?.totalAssigned}` ?? "0"}</p>
                                <p className={classes.count_outof}><span>Validated: </span>{`${doctorLiveData?.validated}` ?? "0"}</p>
                                <p className={classes.count_outof}><span>Validation Due Date: </span>{`${doctorLiveData?.validationDueDate}` ?? "-"}</p>
                            </div>}
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}
