import React, { useState } from 'react'
import InputDashboard from '../../../common/InputDashboard/InputDashboard'
import { Avatar, Button, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { capitalizeFirstLetter, stringAvatar, truncateString } from '../../../../utils/utility'
import classes from './WelchAllynTable.module.css'
import FilterListIcon from '@mui/icons-material/FilterList';
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { useEffect } from 'react'
import CustomAutoComplete from '../../../common/CustomAutoComplete/CustomAutoComplete'
import { City } from 'country-state-city'
import { useNavigate } from 'react-router-dom'
import SearchBar from '../../../common/TableComponents/SearchBar'
import { useDispatch, useSelector } from 'react-redux'
import schoolReducer from '../../../../redux/reducers/school.reducer'


function WelchAllynTable() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [pageNo, setPageNo] = useState(1);
    const [text, setText] = useState('');
    const [displayData, setDisplayData] = useState([]);
    const [city, setCity] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const data = useSelector((state) => state.schoolReducer.data)

    const allCities = [...new Set(City.getCitiesOfCountry("IN").map((x) => x.name))]
    const getSchools = schoolReducer.actions.getSchoolsRequest;
    const getSchoolByState = schoolReducer.actions.getSchoolbyStateRequest;
    const user = useSelector((state) => state.userReducer.currentUser)

    useEffect(() => {
        if (user?.userAttributes && user?.userAttributes?.state) {
            dispatch(getSchoolByState({ state: user?.userAttributes?.state }));
        } else {
          dispatch(getSchools());
        }
    }, [])
    useEffect(() => {
        const compareDates = (a, b) => {
            if (a.screeningFrom < b.screeningFrom) {
                return 1;
            }
            if (a.screeningFrom > b.screeningFrom) {
                return -1;
            }
            return 0;
        };
        let sortedData = [...data].sort(compareDates);
        setDisplayData(sortedData)
    }, [data])

    useEffect(() => {
        if (text && city) {
            setDisplayData(data.filter((x) => x.city === city.toLowerCase() && x.schoolName.toLowerCase().includes(text.toLowerCase())))
            setPageNo(1)
        }
        else if (text) {
            setDisplayData(data?.filter((x) => x?.schoolName.toLowerCase().includes(text.toLowerCase())))
            setPageNo(1)
        }
        else if (city) {
            setDisplayData(data?.filter((y) => y?.city === city.toLowerCase()))
            setPageNo(1)
        }
        else {
            setDisplayData(data)
        }
    }, [text, city])

    return <div>
        <div>Welch Allyn Data</div>
        <br />
        <div className={classes.filterBox}>
            <FormControl>
                <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rowsPerPage}
                    label="Rows"
                    onChange={(e) => { setRowsPerPage(e.target.value); setPageNo(1) }}
                    size='small'
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                </Select>
            </FormControl>
            <CustomAutoComplete options={allCities} value={city} placeholder={"City"} onChange={setCity} />
            <SearchBar id={"searchCy"} onchange={setText} value={text} />
            <FilterListIcon sx={{ marginLeft: "20px" }} />
        </div>
        <br />
        <TableContainer component={Paper} sx={{
            '&.MuiTableContainer-root': {
                boxShadow: 'none',
                backgroundColor: 'transparent'
            },
            'thead th': {
                fontWeight: '600'
            }
        }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{ height: "70px" }}>
                    <TableRow>
                        <TableCell>SCHOOL NAME</TableCell>
                        <TableCell align="right">STUDENTS</TableCell>
                        <TableCell align="right">SCHOOL CODE</TableCell>
                        <TableCell align="right">STATUS</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(displayData) && displayData?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo).map((row) => (
                        <TableRow
                            key={row?.schoolCode}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                'th': {
                                    height: '70px'
                                }
                            }}
                        >
                            <TableCell component="th" scope="row">
                                <div className={classes.tabBtn_container}>
                                    {
                                        row?.url
                                            ? <img style={{ width: "32px", height: "32px", borderRadius: "20px", objectFit: "cover" }} src={row?.url} alt="" />
                                            : <Avatar {...stringAvatar(capitalizeFirstLetter(row?.schoolName))} />
                                    }
                                    {capitalizeFirstLetter(truncateString(row?.schoolName, 30))}
                                </div>
                            </TableCell>
                            <TableCell align="right">{row?.strength}</TableCell>
                            <TableCell align="right">{row?.schoolCode}</TableCell>
                            <TableCell align="right" >
                                <button className={classes.statusBtn} style={{ background: row?.isActive ? '#149457' : '#8E8F8F' }}>
                                    {row?.isActive ? 'ACTIVE' : 'INACTIVE'}
                                </button>
                            </TableCell>
                            <TableCell align="right" >
                                <p onClick={() => navigate(`/ops-manager/welch/${row?.schoolName}`, { state: { breadCrumbs: ['Welch Allyn Data', row?.schoolName], school: row } })} className={classes.viewHistoryBtn}>
                                    View History
                                </p>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <div className={classes.pagination_container}>
            <div className={classes.page_item}>{`Showing ${rowsPerPage * (pageNo - 1) + 1
                } to ${pageNo * rowsPerPage <= displayData?.length
                    ? pageNo * rowsPerPage
                    : displayData?.length
                } of ${displayData?.length} items`}</div>
            <div className={classes.pagination}>
                <Button
                    size="small"
                    disabled={pageNo === 1}
                    onClick={() => setPageNo(pageNo - 1)}
                >
                    <ChevronLeft />
                </Button>
                <span>{pageNo}</span>
                <div>
                    <span>of</span>
                    <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
                </div>

                <div>
                    <Button
                        size="small"
                        disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)}
                        onClick={() => setPageNo(pageNo + 1)}
                    >
                        <ChevronRight />
                    </Button>
                </div>
            </div>
        </div>

    </div >
}

export default WelchAllynTable