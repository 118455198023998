import React, { useState } from "react";
import {
  Stack,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Chip,
  Select,
  FormControl,
} from "@mui/material";
import classes from "./MultiSelectDropdown.module.css";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

export default function MultipleSelectChip({
  entity,
  amenities,
  setAmenities,
  options,
  width,
  margin,
  label,
  height
}) {
  return (
    <>
      <MultiSelect
        height={height}
        entity={entity}
        amenities={amenities}
        setAmenities={setAmenities}
        options={options}
        width={width}
        margin={margin}
        label={label}
      />
    </>
  );
}

const MultiSelect = ({
  entity,
  amenities,
  setAmenities,
  options,
  width,
  margin,
  height,
  label
}) => {
  const [selectedNames, setSelectedNames] = useState([]);
  return (
    <FormControl sx={{ mt: margin, width: width, height: height || "100px" }}>
      <InputLabel
        sx={{
          backgroundColor: "#FFF",
          color: "#9d9d9d !important",
          fontFamily: "Inter",
          fontSize: "14px",
        }}
        size="small"
      >{
          label === "Available classes"
            ? label
            : "Amenities available"
        }
      </InputLabel>
      <Select
        multiple
        size="small"
        value={amenities}
        onChange={(e) => setAmenities(e.target.value)}
        sx={{
          backgroundColor: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#FFFFFF",
          },
          "&:active": {
            backgroundColor: "#FFFFFF",
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#E8E7E7",
            borderRadius: "8px",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E8E7E7",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E8E7E7",
          },
          ".MuiSvgIcon-root ": {
            fill: "#6F7E90 !important",
          },
        }}
        input={<OutlinedInput label="Amenities available" />}
        renderValue={(selected) => (
          <Stack gap={1} direction="row" flexWrap="wrap">
            {selected?.map((value) => (
              <Chip
                key={value}
                label={value}
                onDelete={() =>
                  setAmenities(amenities.filter((item) => item !== value))
                }
                deleteIcon={
                  <CancelIcon
                    sx={{ fill: "#66707E" }}
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
              />
            ))}
          </Stack>
        )}
      >
        {options.map((name) => (
          <MenuItem
            key={name}
            value={name}
            sx={{ justifyContent: "space-between" }}
          >
            {name}
            {amenities?.includes(name) ? <CheckIcon color="info" /> : null}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
