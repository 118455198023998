import React, { useState } from 'react'
import classes from './DoctorInfoCard.module.css'

import ellipsis from '../../assets/images/ellipsis.svg'
import { Link } from 'react-router-dom'
// import CircularProgressWithLabel from '../../CircularProgressBar/CircularProgressBar'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import moment from 'moment'
import CircularProgressWithLabel from '../CircularProgressBar/CircularProgressBar';


export const DoctorInfoCard = ({ title, entity, totalText, totalCount, totalPupilCount, live, data, subtitle }) => {
    
    const percentage = 80
    const [open, setOpen] = useState(false)
    let renderCardColor;

    switch (entity) {
        case ('Doctor'):
            renderCardColor = `${classes.infocard_parent} ${classes.infocard_color_violet}`
            break;

        case ('School'):
            renderCardColor = `${classes.infocard_parent} `
            break;

        case ('Test'):
            renderCardColor = `${classes.infocard_parent} ${classes.infocard_modal_color_pink}`
            break;

        case ('TestPost'):
            renderCardColor = <div className={classes.health_certificate_wrapper}>
                <div className={classes.health_certificate}></div>
                <div className={classes.health_certificate_download_wrapper}>
                    <FileDownloadOutlinedIcon sx={{ fill: "#3b3cea", width: "16px", height: "16px" }} />
                    <p>Download</p>
                </div>
            </div>

        default:
            renderCardColor = `${classes.infocard_parent}`
            break;
    }

    const getPercentage = (x) => {
        
        return ((data[x]?.done / data[x]?.outOf)*100).toFixed(0)
        if (x?.todayEvent?.screened) {
            const d = x?.todayEvent?.screened?.split("/")
            const a = parseInt(d[0])
            const b = parseInt(d[1])
            return ((a / b) * 100).toFixed(0)
        }
        else {
            return 0
        }
    }

    const getValidationPercent = (x) => {
        if (x?.screeningTillDate) {
            return 0
        }
        else {
            return 0
        }
    }

    const getDoctorType = (x) => {
        if (x?.typeOfScreening === "physicalScreening") {
            return "Physical Screening"
        }
        else if (x?.typeOfScreening === "dentalScreening") {
            return "Dental Screening"
        }
        else if (x?.typeOfScreening === "behaviroralScreening") {
            return "Behavoral Screening"
        }
    }

    return (
        <>
            <div className={renderCardColor}>
                <div className={classes.titleHeader}>
                    <p className={classes.infocard_title}>{title}</p>
                    {/* {entity!=="Doctor" && <button onClick={() => setOpen(!open)}>
                        <AddCircleIcon sx={{ fill: "#66707E", height: "20px", width: "20px" }} />
                    </button>} */}

                </div>
                {entity==="Doctor" && <div className={classes.entity_number}>{`${data?.done} / ${data?.outOf} Students Validated`}</div>}
                <div className={classes.totalText}>{totalText}: {totalPupilCount}</div>
                <div className={classes.live_status}>
                    <img src={ellipsis} />
                    <p>{live}</p>
                </div>

                {open ? (
                    <div className={`${entity === 'Doctor' ? `${classes.infocard_modal_container} ${classes.infocard_modal_color_violet}` :
                        `${classes.infocard_modal_container}`}`}>
                        <div className={classes.infocard_modal_title_container}>
                            <p className={classes.infocard_modal_title}>{title}</p>
                            <button onClick={() => setOpen(!open)}>
                                <CancelIcon sx={{ fill: "#66707E", height: "20px", width: "20px" }} />
                            </button>
                        </div>
                        <div className={classes.live_status}>
                            <img src={ellipsis} />
                            <p>Live</p>
                        </div>

                        {
                            entity === "School" && Object.keys(data)?.map((x,i) => {
                                return (
                                    <div className={`${entity == 'Doctor' ? `${classes.infocard_modal_description_container} ${classes.infocard_modal_description_violet_border}
                        ` : `${classes.infocard_modal_description_container}`}`}>
                                        <div className={classes.infocard_modal_description_progress_info_container}>
                                            {live==="Live" && <div className={classes.infocard_progress_bar}>
                                                <CircularProgressWithLabel value={getPercentage(x)} />
                                            </div>}
                                            <div className={classes.infocard_modal_description_information}>
                                                <p className={classes.day_desc}>{moment(x).format("DD MMMM, YYYY")}</p>
                                                <p className={classes.entity_name}>{`Class ${data[x]?.class?.join(", ")}`}</p>
                                                <p className={classes.count_outof}>{`${data[x]?.outOf} Students`}</p>
                                                <p className={classes.total_count}>{`Total ${totalPupilCount}`}</p>
                                            </div>
                                        </div>
                                        <Link>
                                            {/* <div className={classes.check_progress}>View Progress</div> */}
                                        </Link>
                                    </div>
                                )
                            })
                        }

                        {entity !== "School" &&
                            <>
                                {
                                    data?.map((x) => {
                                        return (
                                            <div className={`${entity == 'Doctor' ? `${classes.infocard_modal_description_container} ${classes.infocard_modal_description_violet_border}
                                    ` : `${classes.infocard_modal_description_container}`}`}>
                                                <div className={classes.infocard_modal_description_progress_info_container}>
                                                    <div className={classes.infocard_progress_bar}>
                                                        <CircularProgressWithLabel value={getValidationPercent(x)} />
                                                    </div>
                                                    <div className={classes.infocard_modal_description_information}>
                                                        <p className={classes.day_desc}>{getDoctorType(x)}</p>
                                                        <p className={classes.entity_name}>{x?.doctorName}</p>
                                                        <p className={classes.count_outof}>{x?.screeningTillDate}</p>
                                                        <p className={classes.total_count}>{`Total students ${x?.totalAssigned}`}</p>
                                                    </div>
                                                </div>
                                                <Link>
                                                    <div className={classes.check_progress}>View Progress</div>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                            </>
                        }
                    </div>
                ) :
                    (
                        <></>
                    )}

            </div>
        </>
    )
}
