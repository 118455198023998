import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import classes from './WelchSchool.module.css'
import FilterListIcon from '@mui/icons-material/FilterList';
import { ChevronLeft, ChevronRight, Refresh } from '@mui/icons-material'
import { useState } from 'react'
import DatePickerInput from '../../../common/InputDashboard/DatePickerInput'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import BreadCrumbs from '../../../common/TableComponents/BreadCrumbs'
import WelchZip from '../WelchZip/WelchZip';
import { useSelector } from 'react-redux';
import screening from '../../../../graphqlApiServices/screening';
import moment from 'moment';

export default function WelchSchool() {

    const { getWelchAllynUploadHistory } = screening

    const [pageNo, setPageNo] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([])
    // const [displayData, setDisplayData] = useState(data);
    const [filterDate, setFilterDate] = useState();

    const user = useSelector((state) => state.userReducer.currentUser)

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const navigate = useNavigate()
    const location = useLocation();

    const RefreshEvent = () => {
        getWelchAllynUploadHistory(user.userId).then((res) => {
            const x = res.data.getWelchUploadList
            setData(x?.filter((q) => q.schoolId === location.state.school?.schoolId))
        }).catch((err) => console.log(err))
        setPageNo(1)
    }

    useEffect(() => {
        getWelchAllynUploadHistory(user.userId).then((res) => {
            const x = res.data.getWelchUploadList
            setData(x?.filter((q) => q.schoolId === location.state.school?.schoolId))
        }).catch((err) => console.log(err))
    }, [open])

    const StudentStatus = (el) => {
        let statusColor = ''
        if (el == "IN_PROGRESS") {
            statusColor = "#EEA61F"
        }
        else if (el == "FILE_UPLOADING") {
            statusColor = "#1740A1"
        }
        else if (el == "COMPLETED") {
            statusColor = "#149457"
        }
        else {
            statusColor = "#D95A59"
        }

        return <button className={classes.statusBtn} style={{ background: statusColor }}>
            {el.split('_').join(" ")}
        </button>

    }
    return <div>
        <BreadCrumbs />
        <br />
        <div className={classes.filterBox}>
            <div style={{ justifyContent: 'flex-start' }} className={classes.filterBox}>
                {/* <DatePickerInput
                    onChange={(e) => {
                        setFilterDate(e.target.value);
                    }}
                    placeholder={"Select Date"}
                    value={filterDate}
                />
                <FilterListIcon sx={{ marginLeft: "20px", marginTop: '16px' }} /> */}
            </div>
            <div >
                <Button variant="outlined"
                    startIcon={<Refresh sx={{ background: '#fff', borderRadius: '20px' }} />}
                    sx={{
                        background: '#1740A1', color: '#fff', marginRight: '10px', ':hover': {
                            background: '#1740A1'
                        }
                    }}
                    onClick={RefreshEvent} >
                    Refresh
                </Button>
                <Button variant="outlined"
                    startIcon={<FileUploadOutlinedIcon sx={{ background: '#fff', borderRadius: '20px' }} />}
                    sx={{
                        background: '#1740A1', color: '#fff', ':hover': {
                            background: '#1740A1'
                        }
                    }}
                    onClick={handleOpen} >
                    Upload Zip
                </Button>
            </div>

        </div>
        <WelchZip open={open} handleClose={handleClose} />

        <br />
        <TableContainer component={Paper} sx={{
            '&.MuiTableContainer-root': {
                boxShadow: 'none',
                backgroundColor: 'transparent'
            },
            'thead th': {
                fontWeight: '600'
            }
        }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{ height: "70px" }}>
                    <TableRow>
                        <TableCell>SCHOOL SCREENING DATE RANGE</TableCell>
                        <TableCell align="right">UPLOAD DATE & TIME</TableCell>
                        <TableCell align="right">TOTAL PATIENTS</TableCell>
                        <TableCell align="right">SUCCESS</TableCell>
                        <TableCell align="right">FAILED</TableCell>
                        <TableCell align="right">STATUS</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(data) && data?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo).map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                'th': {
                                    height: '70px'
                                },
                                ' td:nth-child(2)': {
                                    color: '#343434'
                                }
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {`${moment(row.fromDate).format("DD-MM-YYYY")} - ${moment(row.toDate).format("DD-MM-YYYY")}`}
                            </TableCell>
                            <TableCell align="right">{`${moment(parseInt(row.createdAt)).format("DD-MM-YYYY hh:mm:ss A")}`}</TableCell>
                            <TableCell align="right">{`${row.totalStudents ?? 'Calculating...'}`}</TableCell>
                            <TableCell align="right" >{row?.success ?? '-'}</TableCell>
                            <TableCell align="right" >{row?.failed ?? '-'}</TableCell>
                            <TableCell align="right" >
                                {StudentStatus(row?.status)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <div className={classes.pagination_container}>
            <div className={classes.page_item}>{`Showing ${rowsPerPage * (pageNo - 1) + 1
                } to ${pageNo * rowsPerPage <= data?.length
                    ? pageNo * rowsPerPage
                    : data?.length
                } of ${data?.length} items`}</div>
            <div className={classes.pagination}>
                <Button
                    size="small"
                    disabled={pageNo === 1}
                    onClick={() => setPageNo(pageNo - 1)}
                >
                    <ChevronLeft />
                </Button>
                <span>{pageNo}</span>
                <div>
                    <span>of</span>
                    <span>{Math.ceil(data?.length / rowsPerPage)}</span>
                </div>

                <div>
                    <Button
                        size="small"
                        disabled={pageNo === Math.ceil(data?.length / rowsPerPage)}
                        onClick={() => setPageNo(pageNo + 1)}
                    >
                        <ChevronRight />
                    </Button>
                </div>
            </div>
        </div>

    </div >
}
