import React from "react";
import classes from "./ScreenDependency2.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import { textFormat } from "../../../utils/utility";
import clsx from "clsx";


export const ScreenDependency6to172 = ({ data }) => {

  const response = JSON.parse(data?.INTERNET_ADDICTION)?.response

  const performanceQuestionBank = [
    {
      qno: 13,
      question:
        "Does your child have problems with you about the amount of time he/she spends using the device?",
      answer: textFormat(response?.[12])
    },
    {
      qno: 14,
      question:
        "Does your child give more importance to using electronic gadgets and feel that using device is the most important thing in his/her life?",
      answer: textFormat(response?.[13])
    },
    {
      qno: 15,
      question:
        "Does your child feel that using the device is more enjoyable than doing other things?",
      answer: textFormat(response?.[14])
    },
    {
      qno: 16,
      question:
        "Does your child lie to you about what he/she does on the device?",
      answer: textFormat(response?.[15])
    },
    {
      qno: 17,
      question:
        "Do you feel that your child cannot control himself/herself from using the device?",
      answer: textFormat(response?.[16])
    },
    {
      qno: 18,
      question:
        "Do you find that your child is losing interest in hobbies or other activities because of their preference to use gadgets?",
      answer: textFormat(response?.[17])
    },
    {
      qno: 19,
      question:
        "Do you find that when your child stops using the device, it is not long before he/she starts using it again?",
      answer: textFormat(response?.[18])
    },
    {
      qno: 20,
      question:
        "Do you find your child checking the device when he/she is doing homework or other important things?",
      answer: textFormat(response?.[19])
    },
    {
      qno: 21,
      question:
        "Does your child get frustrated when asked to stop using the device?",
      answer: textFormat(response?.[20])
    },
    {
      qno: 22,
      question:
        "Does your child argue with you when asked to stop using the device?",
      answer: textFormat(response?.[21])
    },
    {
      qno: 23,
      question:
        "Does your child spend unnecessarily on things for the device? (Such as buying games, spending money for the characters in the game, play station and so on)",
      answer: textFormat(response?.[22])
    },
    {
      qno: 24,
      question:
        "Does your child use the device to feel better when they are feeling bad or low?",
      answer: textFormat(response?.[23])
    },
    {
      qno: 25,
      question:
        "Does your child continue using the device despite the fact that his/her grades at school are getting lower and lower?",
      answer: textFormat(response?.[24])
    },
  ];

  return (
    <>
      <div className={clsx(classes.container, 'xyz')}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>

        <div
          className={classes.performance_question_container}
          style={{ margin: "24px 0 0 0" }}
        >
          <div>Assessment Questionnaire Continued...</div>
          <div className={classes.question_select_container}>
            {performanceQuestionBank.map((el) => {
              return (
                <div className={`${classes.question_radio_box}`}>
                  <p
                  >
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Never"
                          checked={el.answer === "Never"}
                        />
                        <p>Never</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Rarely"
                          checked={el.answer === "Rarely"}
                        />
                        <p>Rarely</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Sometime"
                          checked={el.answer === "Sometime"}
                        />
                        <p>Sometime</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Often"
                          checked={el.answer === "Often"}
                        />
                        <p>Often</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Always"
                          checked={el.answer === "Always"}
                        />
                        <p>Always</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes.copyright_details_container}>
          <div>
            The information contained in this publication should not be used as
            a substitute for the medical care and advice of your psychologist.
            There may be variations in treatment that your psychologist may
            recommend based on individual facts and circumstances.
          </div>
          <div>
            Copyright @2023 Skids Health
            <br />
            Adapted from DASC (Digital Addiction Scale for Children)
          </div>
        </div>

        <div className={classes.footer_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 2 of 2</p>
          </div>
        </div>
      </div>
    </>
  );
};
