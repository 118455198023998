import React from 'react'

const Hr = () => {
    return (
        <div
            style={{
                height: "1.8px",
                backgroundColor: "#E8E7E7"
            }}
        />
    )
}

export default Hr