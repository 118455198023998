import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import userReducer from "../../../redux/reducers/user.reducer";
import DashboardLayout from "../../common/DashboardLayout/DashboardLayout";
import TeacherTable from '../../common/TeacherTable/Teacher'


export default function PrincipalTeacher() {

  const data = useSelector((state) => state.userReducer.users)
  const loading = useSelector((state) => state.userReducer.loading)
  let schoolId = useSelector((state) => state.userReducer.currentUser.schoolId)

  const dispatch = useDispatch()
  const location = useLocation()
  if (!location.pathname.includes("principal")) {

    schoolId = location.state?.schoolId
  }
  const getUserByGroup = userReducer.actions.getUserByGroupRequest;
  useEffect(() => {
    dispatch(getUserByGroup({ role: "school-teacher", schoolId: schoolId }))
  }, [])

  return (
    // <div style={{
    //   display: 'flex',
    //   padding: '25px'
    // }}>
    //   <DashboardLayout /> 
    <TeacherTable data={data} loading={loading} />
    // </div>
  )
}
