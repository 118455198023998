import React from "react";
import classes from "./ScreenDependency.module.css";
import BhvReportHeader from "../Anxiety/BhvReportHeader";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import { textFormat } from "../../../utils/utility";
import clsx from "clsx";


export const ScreenDependency6to17 = ({ data }) => {

  const response = JSON.parse(data?.INTERNET_ADDICTION)?.response

  const questionBank = [
    {
      qno: 1,
      question:
        "Does your child spend more than 2 hours using electronic gadgets?",
      answer: textFormat(response?.[0])
    },
    {
      qno: 2,
      question:
        "Does your child feel the need to spend more than 2 hours per day using electronic gadgets?",
      answer: textFormat(response?.[1])
    },
    {
      qno: 3,
      question:
        "Does your child feel upset when not being able to use gadgets?",
      answer: textFormat(response?.[2])
    },
    {
      qno: 4,
      question:
        "Does your child spend more than 2 hours on electronic gadgets and lie to you about the amount of time he/she spends using the device?",
      answer: textFormat(response?.[3])
    },
    {
      qno: 5,
      question: "Does your child use gadgets to forget his/her problems?",
      answer: textFormat(response?.[4])
    },
    {
      qno: 6,
      question: "Does your child prefer using gadgets rather than spending time with the family members?",
      answer: textFormat(response?.[5])
    },
    {
      qno: 7,
      question: `Do you feel that your child exceeds the limit of 2 hours per day and spends extra time using the device?`,
      answer: textFormat(response?.[6])
    },
    {
      qno: 8,
      question:
        "Does your child feel upset when asked to stop using gadgets?",
      answer: textFormat(response?.[7])
    },
    {
      qno: 9,
      question: "Have you tried to stop or limit your child’s screen time and failed in doing so?",
      answer: textFormat(response?.[8])
    },
    {
      qno: 10,
      question: "Does your child sleep less because of excessive screen time?",
      answer: textFormat(response?.[9])
    },
    {
      qno: 11,
      question: "Does your child get preoccupied about using the device that is even if he/she does not have the device do they keep thinking about what they do while using it (video games, social media, and texting, etc.)",
      answer: textFormat(response?.[10])
    },
    {
      qno: 12,
      question: "Does your child feel frustrated when he/she cannot use the device?",
      answer: textFormat(response?.[11])
    }
  ];



  return (
    <>
      <div className={clsx(classes.container, 'xyz')}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>
        <div className={classes.title_container}>
          Hey! This is my Digital Dependency assessment report.
        </div>

        <BhvReportHeader data={data} />

        <div className={classes.question_container}>
          <div>Assessment Questionnaire</div>
          <div className={classes.question_select_container}>
            {questionBank.map((el) => {

              return (
                <div className={classes.question_radio_box}>
                  <p>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Never"
                          checked={el.answer === "Never"}
                        />
                        <p>Never</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Rarely"
                          checked={el.answer === "Rarely"}
                        />
                        <p>Rarely</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Sometime"
                          checked={el.answer === "Sometime"}
                        />
                        <p>Sometime</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Often"
                          checked={el.answer === "Often"}
                        />
                        <p>Often</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Always"
                          checked={el.answer === "Always"}
                        />
                        <p>Always</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes.footer_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 1 of 2</p>
          </div>
        </div>
      </div>
    </>
  );
};
