import React, { useState, useRef } from 'react'
import './Audioplayer.css';
import { PlayArrow, Pause, Replay } from '@mui/icons-material';

const Audioplayer = (props) => {
    const { url } = props;
    const audioUrl = url;
    const audioRef = useRef()
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioDuration, setAudioDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);


    window.setInterval(function () {
        if (!audioRef.current) {
            return;
        }
        setCurrentTime(audioRef.current?.currentTime);
    }, 1000);

    function onLoadedMetadata() {
        setAudioDuration(audioRef.current.duration);
    }

    function handlePlayPause() {
        if (isPlaying) {
            audioRef.current?.pause();
            setIsPlaying(false);
        } else {
            audioRef.current?.play();
            setIsPlaying(true);
        }
    }

    function handleReplay() {
        audioRef.current.currentTime = 0;
    }

    function handleEnded() {
        setIsPlaying(false);
        audioRef.current.currentTime = 0;
    }

    return (
        <>
            <audio
                onEnded={handleEnded}
                ref={audioRef}
                onLoadedMetadata={onLoadedMetadata}
            >
                <source src={audioUrl} type="audio/x-wav" />
                Your browser does not support the audio element.
            </audio>
            <div className='audioContainer'>
                <div
                    className="progressBar">
                    <div
                        className='progressBarActive'
                        style={{
                            width:
                                audioDuration
                                    ? `${((currentTime / audioDuration) * 100)}%`
                                    : `0%`
                        }}
                    >
                        <span className='progressBarBadge'></span>
                    </div>

                </div>
                <time className='timeTag'>
                    {isPlaying
                        ?
                        currentTime
                            ? Math.floor(currentTime / 60) + ":" + (Math.floor(currentTime % 60) <= 9
                                ? "0" + Math.floor(currentTime % 60)
                                : Math.floor(currentTime % 60))
                            : "0:00"
                        : Math.floor(audioDuration / 60) + ":" + (Math.floor(audioDuration % 60) <= 9
                        ? "0" + Math.floor(audioDuration % 60)
                        : Math.floor(audioDuration % 60))
                    }
                </time>
                <div className='playPauseIco' onClick={handlePlayPause}>
                    {
                        isPlaying
                            ? <Pause />
                            : <PlayArrow />
                    }
                </div>
                <Replay style={{fontSize:"19px"}} onClick={handleReplay} />
            </div>
        </>
    )
}

export default Audioplayer