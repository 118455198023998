import React, { useEffect, useRef, useState } from "react";
import Layout from "../common/Layout/Layout";
import InputPage from "../common/Input/Input";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Alert, Snackbar, Typography } from "@mui/material";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { verifyPassword, verifyEmail } from "../common/commonFunctions";
import classes from "./Login.module.css";
import "./Login.css"
import { signIn } from "../../Auth/login";
import userReducer from "../../redux/reducers/user.reducer";
import { connect, useDispatch, useSelector } from "react-redux";
import authReducers from "../../redux/reducers/auth.reducers";
import Cookies from "js-cookie";
import { Auth } from "aws-amplify";

const LoginPage = () => {
  const setCognitoUser = userReducer.actions.setCognitoUser;
  const login = authReducers.actions.login;
  const setUser = userReducer.actions.setUser;
  const getUserById = userReducer.actions.getUserByIdRequest;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userName, setUserName] = useState("");
  const [errorUserName, setErrorUserName] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorStatement, setErrorStatement] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState({
    state: false,
    message: '',
    toast: 'success'
  });
  const [sms, setSms] = useState("")
  const [mfa, setMfa] = useState(false)
  const [cUser, setCUser] = useState({})


  const userRole = useSelector((state) => state.userReducer.currentUser?.groups)
  const loggedIn = useSelector((state) => state.authReducer.loggedIn)
  const specialities = useSelector((state) => state.userReducer.currentUser?.specialities)

  //error messages according to the password validation
  const errors = {
    capital: "The password must contain at least 1 Uppercase character",
    lower: "The password must contain at least 1 Lowercase character",
    digit: "The password must contain at least 1 numeric character",
    specialcharacter:
      "The password must contain at least one special character",
    lengthOfPass: "The password must be 8 characters or longer",
  };

  //handles email Id input and runs validation check
  const handleUserName = (item) => {
    let name = verifyEmail(item);
    setErrorUserName(name);
    setUserName(item);
  };
  //handles password input and runs validation check
  const handlePassword = (item) => {
    setPassword(item);
  };

  useEffect(() => {

    if (userRole && loggedIn) {
      // dispatch(login())
      if (userRole?.includes("school-principal")) {
        navigate('/principal-dashboard',)
      }
      else if (userRole?.includes("school-teacher")) {
        navigate('/teacher-dashboard')
      }
      else if (userRole?.includes("skids-sa")) {
        navigate('/superadmin-dashboard')
      }
      else if (userRole?.includes("skids-partner")) {
        navigate('/partner-school')
      }
      else if (userRole?.includes("school-admin")) {
        navigate('/admin-dashboard')
      }
      else if (userRole?.includes("SKIDS-ops")) {
        navigate('/ops-dashboard')
      }
      else if (userRole?.includes("skids-doctor")) {
        if (specialities === "BEHAVIORAL") {
          navigate("/behavioral-doctor/dashboard")
        }
        else {
          navigate('/doctor-dashboard')
        }
      }
      else if (userRole?.includes("school-nurse") || userRole?.includes("school-doctor")) {
        navigate('/infirmary-dashboard')
      }
      else if (userRole?.includes("skids-ops-nurse")) {
        navigate('/ops-nurse/dashboard')
      }
      else if (userRole?.includes("skids-ops-manager")) {
        navigate('/ops-manager/dashboard')
      }
      else if (userRole?.includes("skids-super-doctor")) {
        if (specialities === "BEHAVIORAL") {
          navigate("/behavioral-doctor/dashboard")
        }
        else {
          navigate('/doctor-dashboard')
        }
      } else if (userRole?.includes("skids-support-admin")) {
        navigate('/support-dashboard')
      } else if (userRole?.includes("skids-content-writer")) {
        navigate('/writer-dashboard')
      }
    }

  }, [userRole, loggedIn])



  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const user = await signIn(userName, password);
      console.log(user)
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        dispatch(setCognitoUser(user))
        navigate('/reset-password')
      }
      else if(user?.challengeName === "SMS_MFA"){
        setMfa(true)
        setCUser(user)
      }
      else if (user?.message === "Incorrect username or password.") {
        console.log(user);
        throw "Incorrect username or password"
      } else if (user.code === "MFAMethodNotFoundException") {
        // Auth.setPreferredMFA(user, 'SMS').then((res) => {
        //   console.log(res);
        // }).catch((error) => {
        //   console.log(error);
        // })
      } else {
        const token = user.signInUserSession.idToken.jwtToken;
        if (token) {
          Cookies.set("tokenId", token, { expires: 1 });
          await dispatch(login())
          await dispatch(getUserById({ email: user.attributes.email, sub: user.attributes.sub }))
          setOpen((prev) => {
            return {
              ...prev,
              message: "Logged in successfully",
              toast: "success",
              state: true
            }
          })
        }
      }
      handleClose();

    } catch (error) {

      // handleClose();
      setOpen((prev) => {
        return {
          ...prev,
          message: "Re-check credentials",
          toast: "error",
          state: true
        }
      })
    } finally {
      setUserName("");
      setPassword("");
    }
  }

  const loginAfterOTP = async (e) => {
    e.preventDefault();
    const loggedUser = await Auth.confirmSignIn(
      cUser, // Return object from Auth.signIn()
      sms, // Confirmation code
      "SMS_MFA" // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    );
    console.log(loggedUser);
    const token = loggedUser.signInUserSession.idToken.jwtToken;
        if (token) {
          Cookies.set("tokenId", token, { expires: 1 });
          await dispatch(login())
          await dispatch(getUserById({ email: loggedUser.attributes.email, sub: loggedUser.attributes.sub }))
          setOpen((prev) => {
            return {
              ...prev,
              message: "Logged in successfully",
              toast: "success",
              state: true
            }
          })
        }
        handleClose();
  }

  useEffect(() => {

  }, [userName, password])



  const handleClose = () => {
    setOpen(() => {
      return {
        message: "",
        toast: "error",
        state: false
      }
    });
  }

  return (
    <>
      <div className={classes.login_page}  >
        {!mfa ? <form onSubmit={handleSignIn}>
          <InputPage
            Id={"userName"}
            placeholder={"Email Address"}
            isPassword={false}
            width={'100%'}
            bgColor={'#FFFFFF'}
            change={(e) => {
              handleUserName(e.target.value);
            }}
            value={userName}
          />
          {errorUserName ? (
            <div className={classes.error_text}>Please enter valid email address</div>
          ) : (
            <></>
          )}
          <InputPage
            Id={"password"}
            placeholder={"Password"}
            width={'100%'}
            isPassword={true}
            change={(e) => {
              handlePassword(e.target.value);
            }}
            value={password}
          />
          {errorPassword ? (
            <div className={classes.error_text}>Password entered is incorrect. Please enter correct password</div>
          ) : (
            <></>
          )}

          <div className={classes.extra_settings}>

            <Link to="/forgot-password" style={{ textDecoration: "none" }}>
              <div className={classes.forgot_password}>Forgot password?</div>{" "}
            </Link>
          </div>

          {!errorUserName ? (
            <button
              id="loginBtn"
              className={classes.submit_button}
              style={{ opacity: 1 }}
              // onClick={(e) => handleSignIn(e)}
              disabled={password.length < 3 || userName.length < 3}
              type="submit"
            >
              Login
            </button>
          ) : (
            <button
              className={classes.submit_button}
              style={{ opacity: 0.25 }}
            >
              Login
            </button>
          )}
        </form> :
        <form onSubmit={loginAfterOTP}>
          <InputPage
            Id={"otp"}
            placeholder={"OTP"}
            width={'100%'}
            isPassword={false}
            change={(e) => {
              setSms(e.target.value);
            }}
            value={sms}
          />
          <button
              id="loginBtn"
              className={classes.submit_button}
              style={{ opacity: 1 }}
              // onClick={(e) => handleSignIn(e)}
              disabled={sms.length!==6}
              type="submit"
            >
              Login
            </button>
        </form>
        }
        
        {/* <div style={{ fontSize: "1em", textAlign: "center" }}>
          Don’t have an account?{" "}
          <Link
            to="/registration"
            style={{ textDecoration: "none", color: "#222cc9", fontWeight: '600', }}
          >
            Create account
          </Link>{" "}
        </div> */}
        {<Snackbar open={open.state} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert onClose={handleClose} severity={open.toast} sx={{ width: '100%' }}>
            {open.message}
          </Alert>
        </Snackbar>}
      </div>
    </>
  );
};

const TwoFactorAuth = () => {

  let mykey = "0123456789".split("");
  let otp_inputs;
  const [inputOtp, setUserInputOtp] = useState("");

  useEffect(() => {
    otp_inputs = document.querySelectorAll(".otp__digit");
    otp_inputs.forEach((_) => {
      _.addEventListener("keyup", handle_next_input);
    });

    return () => {
      otp_inputs.forEach((_) => {
        _.removeEventListener("keyup", handle_next_input);
      });
    }
  });

  const handleSubmitOtp = () => {

  }


  function handle_next_input(event) {
    let current = event.target;
    let index = parseInt(current.classList[1].split("__")[2]);
    current.value = event.key;

    if (event.keyCode == 8 && index > 1) {
      current.previousElementSibling.focus();
    }
    if (index < 6 && mykey.indexOf("" + event.key + "") != -1) {
      var next = current.nextElementSibling;
      next.focus();
    }
    var _finalKey = "";
    for (let { value } of otp_inputs) {
      _finalKey += value;
    }
    setUserInputOtp(_finalKey);
  }


  return <div>
    <div style={{ width: "100%" }} className={classes.input_container}>
      <div className={"otp_container"}>
        <form
          action="javascript: void(0)"
          className="otp-form"
          name="otp-form"
        >
          <div className="otp-input-fields">
            <input
              style={{ padding: 5, textAlign: "center" }}
              type="number"
              className="otp__digit otp__field__1"
            />
            <input
              style={{ padding: 5, textAlign: "center" }}
              type="number"
              className="otp__digit otp__field__2"
            />
            <input
              style={{ padding: 5, textAlign: "center" }}
              type="number"
              className="otp__digit otp__field__3"
            />
            <input
              style={{ padding: 5, textAlign: "center" }}
              type="number"
              className="otp__digit otp__field__4"
            />
            <input
              style={{ padding: 5, textAlign: "center" }}
              type="number"
              className="otp__digit otp__field__5"
            />
            <input
              style={{ padding: 5, textAlign: "center" }}
              type="number"
              className="otp__digit otp__field__6"
            />
          </div>
          <div className="result">
            <p id="_otp" className="_notok"></p>
          </div>
        </form>
      </div>
    </div>
    <button
      id="loginBtn"
      className={classes.submit_button}
      style={{ opacity: 1, marginTop: '40px' }}
      onClick={(e) => handleSubmitOtp(e)}
      type="submit"
    >Submit</button>
  </div>
}

function Login() {

  const [isToFactor, setIsToFactor] = useState(false);


  return (
    <>
      {
        isToFactor ?
          <Layout
            component={TwoFactorAuth()}
            head={"2-Step Verification"}
            subhead={"Enter the 6-digit otp send to your email address"}
          />
          :
          <Layout
            component={LoginPage()}
            head={"Login"}
            subhead={"Enter your credentials to login to your account."}
          />
      }
    </>
  );
}


export default Login

