import React, { useEffect, useState } from "react";
import classes from "./NewSaDashboard.module.css";
import eventsgreen from "../../assets/images/eventsgreen.svg";
import schoolsblue from "../../assets/images/schoolblue.svg";
import screeningsred from "../../assets/images/screeningsred.svg";
import clinicsviolet from "../../assets/images/clinicsviolet.svg";
import screeningsyellow from "../../assets/images/screeningsyellow.svg";
import { Link, useNavigate } from "react-router-dom";
import { InfoCard } from "./InfoCard";
import devicelogo from "../../assets/images/devicelogo.svg";
import moment from "moment";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ClinicChart from "../common/Graphs/ClinicChart";
import D2CGraph from "../common/Graphs/D2CGraph";
import { useSelector } from "react-redux";
import SuperAdminD2CGraph from "../common/Graphs/SuperAdminD2CGraph";
import { MissingDataCard } from "./MissingDataCard";

export const NewSaDashboard = () => {
  const navigate = useNavigate();
  const [analyticsEventsCard, setAnalyticsEventsCard] = useState(true);
  const [analyticsSchoolsCard, setAnalyticsSchoolsCard] = useState(true);
  const [analyticsScreeningsCard, setAnalyticsScreeningsCard] = useState(true);
  const [analyticsClinicsCard, setAnalyticsClinicsCard] = useState(true);
  const data = useSelector((state) => state.dashboardReducer.data);
  const liveScreeningData = useSelector((state) => state.dashboardReducer.liveScreeningData)
  const loading = useSelector((state) => state.dashboardReducer.loading);
  const liveDoctorData = useSelector((state) => state.dashboardReducer.liveDoctorValidationData);

  const table_header = [
    {
      theadname: "DEVICES",
    },
    {
      theadname: "NUMBER",
    },
    {
      theadname: "GURGAON",
    },
    {
      theadname: "BENGALURU",
    },
  ];

  const table_data = [
    {
      deviceName: "Higo",
      num: Array.isArray(data?.deviceData?.Higo)
        ? data?.deviceData?.Higo[2]
        : "-",
      gur: Array.isArray(data?.deviceData?.Higo)
        ? data?.deviceData?.Higo[0]
        : "-",
      blr: Array.isArray(data?.deviceData?.Higo)
        ? data?.deviceData?.Higo[1]
        : "-",
    },
    {
      deviceName: "Massimo",
      num: Array.isArray(data?.deviceData?.Massimo)
        ? data?.deviceData?.Massimo[2]
        : "-",
      gur: Array.isArray(data?.deviceData?.Massimo)
        ? data?.deviceData?.Massimo[0]
        : "-",
      blr: Array.isArray(data?.deviceData?.Massimo)
        ? data?.deviceData?.Massimo[1]
        : "-",
    },
    {
      deviceName: "Welch Allyn",
      num: Array.isArray(data?.deviceData?.WelchAllyn)
        ? data?.deviceData?.WelchAllyn[2]
        : "-",
      gur: Array.isArray(data?.deviceData?.WelchAllyn)
        ? data?.deviceData?.WelchAllyn[0]
        : "-",
      blr: Array.isArray(data?.deviceData?.WelchAllyn)
        ? data?.deviceData?.WelchAllyn[1]
        : "-",
    },
    {
      deviceName: "Amplivox",
      num: Array.isArray(data?.deviceData?.Amplivox)
        ? data?.deviceData?.Amplivox[2]
        : "-",
      gur: Array.isArray(data?.deviceData?.Amplivox)
        ? data?.deviceData?.Amplivox[0]
        : "-",
      blr: Array.isArray(data?.deviceData?.Amplivox)
        ? data?.deviceData?.Amplivox[1]
        : "-",
    },
    {
      deviceName: "Seca",
      num: Array.isArray(data?.deviceData?.Seca)
        ? data?.deviceData?.Seca[2]
        : "-",
      gur: Array.isArray(data?.deviceData?.Seca)
        ? data?.deviceData?.Seca[0]
        : "-",
      blr: Array.isArray(data?.deviceData?.Seca)
        ? data?.deviceData?.Seca[1]
        : "-",
    },
  ];
  const physicalArr = [
    {
      title: "PHYSICAL SCREENING",
      link: "Manage Screening",
      linkText: " that are ongoing in different schools",
      data: data?.physicalScreeningCount,
      navLink: '/superadmin/manage/screening/physical'
    },
    {
      title: "PHYSICAL VALIDATION",
      link: "Check the progress",
      linkText: " of validation done by doctors of screened kids",
      data: data?.physicalValidated,
      navLink: '/superadmin/manage/screening/physical'
    },
    {
      title: "BEHAVIORAL SCREENING",
      link: "Manage Assessments",
      linkText: " that are filled by parents of kids in different schools",
      data: data?.behavioralScreeningCount,
      navLink: '/superadmin/manage/screening/behavioral'
    },
    {
      title: "BEHAVIORAL VALIDATION",
      link: "Check the progress",
      linkText: " of validation done by doctors of filled assessments",
      data: data?.behavioralValidated,
      navLink: '/superadmin/manage/screening/behavioral'
    },
  ];

  const getPastEvents = () => {
    const today = moment().format("YYYY-MM-DD");
    const eventList = data?.SADashEventCard;
    let x = 0;
    for (let index = 0; index < eventList?.length; index++) {
      if (eventList[index].toDate < today) {
        x += 1;
      }
    }
    return x;
  };
  const getUpcomingEvents = () => {
    const today = moment().format("YYYY-MM-DD");
    const eventList = data?.SADashEventCard;
    let x = 0;
    for (let index = 0; index < eventList?.length; index++) {
      if (eventList[index].fromDate > today) {
        x += 1;
      }
    }
    return x;
  };
  const getOngoingEvents = () => {
    const today = moment().format("YYYY-MM-DD");
    const eventList = data?.SADashEventCard;
    let x = 0;
    for (let index = 0; index < eventList?.length; index++) {
      if (
        eventList[index]?.fromDate <= today &&
        eventList[index]?.toDate >= today
      ) {
        x += 1;
      }
    }
    return x;
  };

  const getStrength = () => {
    const schoolList = data?.SADashSchoolCard;
    let x = 0;
    schoolList?.forEach(function (school) {
      x = x + school?.strength;
    });
    return x;
  };

  const getConfirmedScreeningDate = () => {
    const schoolList = data?.SADashSchoolCard;
    let x = 0;
    schoolList?.forEach((school) => {
      if (school?.toDate && school?.fromDate) {
        x = x + 1;
      }
    });
    return x;
  };

  const getPendingScreeningDate = () => {
    const schoolList = data?.SADashSchoolCard;
    let x = 0;
    schoolList?.forEach((school) => {
      if (!school.toDate || !school.fromDate) {
        x = x + 1;
      }
    });
    return x;
  };

  const getPastScreenings = () => {
    const screeningList = data?.SADashScreeningCard;
    let x = 0;
    const today = moment().format("YYYY-MM-DD");
    screeningList?.forEach((screening) => {
      if (screening?.screeningDate < today) {
        x += 1;
      }
    });
    return x;
  };

  const getUpcomingScreenings = () => {
    const screeningList = data?.SADashScreeningCard;
    let x = 0;
    const today = moment().format("YYYY-MM-DD");
    screeningList?.forEach((screening) => {
      if (screening?.screeningDate > today) {
        x += 1;
      }
    });
    return x;
  };

  const getOngoingScreenings = () => {
    const screeningList = data?.SADashScreeningCard;
    let x = 0;
    const today = moment().format("YYYY-MM-DD");
    screeningList?.forEach((screening) => {
      if (screening?.screeningDate === today) {
        x += 1;
      }
    });
    return x;
  };

  const getSkidsClinic = () => {
    const clinicList = data?.SADashClinicCard;
    let x = 0;
    clinicList?.forEach((clinic) => {
      if (clinic?.type === "SKIDS_Owned") {
        x += 1;
      }
    });
    return x;
  };

  const getPartnerClinic = () => {
    const clinicList = data?.SADashClinicCard;
    let x = 0;
    clinicList?.forEach((clinic) => {
      if (clinic?.type === "Affiliated") {
        x += 1;
      }
    });
    return x;
  };

  const navigateToMissingData = (path, state) => {
    console.log(path, state, 'navigateToMissingData');
    navigate(path, { state: { ...state } });
  }

  const getTotalStaff = () => {
    const clinicList = data?.SADashClinicCard;
    let x = 0;
    clinicList?.forEach((clinic) => {
      x = x + clinic?.doctor + clinic?.nurse + clinic?.staff;
    });
    return x;
  };

  return (
    <>
      <div className={classes.infoCard_container}>
        <InfoCard
          title={"School Screening Program"}
          entity={"School"}
          totalText={"students"}
          totalCount={"4"}
          totalPupilCount={"4000"}
          data={liveScreeningData}
        />
        <InfoCard
          title={"Doctor Validation"}
          entity={"Doctor"}
          totalText={"validation"}
          totalCount={"4 "}
          totalPupilCount={"4000"}
          data={liveDoctorData}
        />
        <MissingDataCard
          title={"Missing Data"}
          entity={"MissingData"}
          totalText={"students"}
          totalCount={"4 "}
          totalPupilCount={"4000"}
          data={liveScreeningData}
          navigateToMissingData={navigateToMissingData}
        />
      </div>
      <div className={classes.analytics_text}>Overall Analytics</div>
      <div className={classes.analytics_cards_container}>
        <div className={classes.analytics_cards_main}>
          {/* Analytics Events Container Starts from here */}
          {analyticsEventsCard ? (
            <div
              className={classes.analytics_cards_container_inner}
              onMouseOver={() => {
                setAnalyticsEventsCard(false)
                setAnalyticsSchoolsCard(true)
                setAnalyticsScreeningsCard(true)
                setAnalyticsClinicsCard(true)
              }}
            >
              <p className={classes.analytics_cards_container_inner_header}>
                EVENTS
              </p>
              <div
                className={
                  classes.analytics_cards_container_logo_number_container
                }
              >
                <img src={eventsgreen} />
                <p
                  className={`${classes.analytics_cards_container_logo_number_container_text}
           ${classes.analytics_cards_inner_green}`}
                >
                  {data?.eventCount}
                </p>
                <p
                  className={
                    classes.analytics_cards_container_logo_number_container_countText
                  }
                ></p>
              </div>
              <Link to={"/superadmin-calendar"}>
                <div
                  className={`${classes.analytics_cards_container_inner_subtext} 
          ${classes.analytics_cards_inner_green}`}
                >
                  Manage Events
                </div>
              </Link>
            </div>
          ) : (
            <div
              className={classes.analytics_cards_container_inner_mouseover}
              onMouseLeave={() => {
                setAnalyticsEventsCard(true)
              }}
            >
              <p className={classes.analytics_cards_container_inner_header}>
                EVENTS
              </p>
              <p className={classes.analytics_card_mouseover_subtitle}>
                An overall analysis of events history till <br />
                date. Click here to{" "}
                <Link
                  className={`${classes.analytics_cards_inner_green} ${classes.events_link} `}
                  to={"/superadmin-calendar"}
                >
                  Manage events
                </Link>
              </p>
              <div className={classes.analytics_card_mouseover_data_container}>
                <div>
                  <p className={classes.analytics_mouseover_data_title}>
                    {" "}
                    Total Events
                  </p>
                  <p className={` ${classes.analytics_cards_inner_green} ${classes.analytics_mouseover_data_title_count}`}>
                    {data?.eventCount}
                  </p>
                </div>
                <div>
                  <p className={classes.analytics_mouseover_data_title}>
                    Past Events
                  </p>
                  <p className={` ${classes.analytics_cards_inner_green} ${classes.analytics_mouseover_data_title_count}`}>
                    {getPastEvents()}
                  </p>
                </div>
                <div>
                  <p className={classes.analytics_mouseover_data_title}>
                    Ongoing Events
                  </p>
                  <p className={` ${classes.analytics_cards_inner_green} ${classes.analytics_mouseover_data_title_count}`}>
                    {getOngoingEvents()}
                  </p>
                </div>
                <div>
                  <p className={classes.analytics_mouseover_data_title}>
                    Upcoming Events
                  </p>
                  <p className={` ${classes.analytics_cards_inner_green} ${classes.analytics_mouseover_data_title_count} `}>
                    {getUpcomingEvents()}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Analytics Event Container Ends here  */}

        {/* Analytics Schools Container Starts From here */}
        <div className={classes.analytics_cards_main}>
          {analyticsSchoolsCard ? (
            <div
              className={classes.analytics_cards_container_inner}
              onMouseOver={() => {
                setAnalyticsSchoolsCard(false)
                setAnalyticsEventsCard(true)
                setAnalyticsScreeningsCard(true)
                setAnalyticsClinicsCard(true)
              }}
            >
              <p className={classes.analytics_cards_container_inner_header}>
                SCHOOLS
              </p>
              <div
                className={
                  classes.analytics_cards_container_logo_number_container
                }
              >
                <img src={schoolsblue} />
                <p
                  className={`${classes.analytics_cards_container_logo_number_container_text} ${classes.analytics_cards_inner_blue}`}
                >
                  {data?.schoolCount}
                </p>
                <p
                  className={
                    classes.analytics_cards_container_logo_number_container_countText
                  }
                ></p>
              </div>
              <Link to={"/superadmin-school"}>
                <div
                  className={`${classes.analytics_cards_container_inner_subtext} 
        ${classes.analytics_cards_inner_blue}`}
                >
                  Manage Schools
                </div>
              </Link>
            </div>
          ) : (
            <div
              className={classes.analytics_cards_container_inner_mouseover}
              onMouseLeave={() =>
                setAnalyticsSchoolsCard(true)
              }
            >
              <p className={classes.analytics_cards_container_inner_header}>
                SCHOOLS
              </p>
              <p className={classes.analytics_card_mouseover_subtitle}>
                An overall analysis schools on platform
                <br />
                Click here to{" "}
                <Link className={`${classes.events_link} ${classes.analytics_cards_inner_blue}`} to={"/superadmin-school"}>
                  Manage Schools
                </Link>
              </p>
              <div className={classes.analytics_card_mouseover_data_container}>
                <div>
                  <p className={classes.analytics_mouseover_data_title}>
                    {" "}
                    Total Schools
                  </p>
                  <p className={` ${classes.analytics_cards_inner_blue} ${classes.analytics_mouseover_data_title_count} `}>
                    {data?.schoolCount}
                  </p>
                </div>
                <div>
                  <p className={classes.analytics_mouseover_data_title}>
                    Total Students
                  </p>
                  <p className={` ${classes.analytics_cards_inner_blue} ${classes.analytics_mouseover_data_title_count} `}>
                    {getStrength()}
                  </p>
                </div>
                <div>
                  <p className={classes.analytics_mouseover_data_title}>
                    Screening Date
                    <br />
                    Confirmed
                  </p>
                  <p className={` ${classes.analytics_cards_inner_blue} ${classes.analytics_mouseover_data_title_count} `}>
                    {getConfirmedScreeningDate()}
                  </p>
                </div>
                <div>
                  <p className={classes.analytics_mouseover_data_title}>
                    Screening Date
                    <br />
                    Pending
                  </p>
                  <p className={` ${classes.analytics_cards_inner_blue} ${classes.analytics_mouseover_data_title_count} `}>
                    {getPendingScreeningDate()}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Analytics Schools Container Ends here */}

        <div className={classes.analytics_cards_main}>
          {/* Analytics Screening Container Starts From here */}
          {analyticsScreeningsCard ? (
            <div
              className={classes.analytics_cards_container_inner}
              onMouseOver={() => {
                setAnalyticsScreeningsCard(false)
                setAnalyticsEventsCard(true)
                setAnalyticsSchoolsCard(true)
                setAnalyticsClinicsCard(true)
              }
              }
            >
              <p className={classes.analytics_cards_container_inner_header}>
                SCREENINGS
              </p>
              <div
                className={
                  classes.analytics_cards_container_logo_number_container
                }
              >
                <img src={screeningsred} />
                <p
                  className={`${classes.analytics_cards_container_logo_number_container_text} ${classes.analytics_cards_inner_red}`}
                >
                  {data?.screeningCount}
                </p>
                <p
                  className={
                    classes.analytics_cards_container_logo_number_container_countText
                  }
                >
                  till date
                </p>
              </div>
              <Link to={"/superadmin/manage/screening/physical"}>
                <div
                  className={`${classes.analytics_cards_container_inner_subtext} ${classes.analytics_cards_inner_red}`}
                >
                  Manage Screenings
                </div>
              </Link>
            </div>
          ) : (
            <div
              className={classes.analytics_cards_container_inner_mouseover}
              onMouseLeave={() =>
                setAnalyticsScreeningsCard(true)
              }
            >
              <p className={classes.analytics_cards_container_inner_header}>
                SCREENINGS
              </p>
              <p className={classes.analytics_card_mouseover_subtitle}>
                An overall analysis of events history till <br />
                date. Click here to{" "}
                <Link className={`${classes.events_link} ${classes.analytics_cards_inner_red}`}>Manage Screenings</Link>
              </p>
              <div className={classes.analytics_card_mouseover_data_container}>
                <div>
                  <p className={classes.analytics_mouseover_data_title}>
                    Total Screenings
                  </p>
                  <p
                    className={`${classes.analytics_cards_inner_red} ${classes.analytics_mouseover_data_title_count}`}
                  >
                    {data?.screeningCount}
                  </p>
                </div>
                <div>
                  <p className={classes.analytics_mouseover_data_title}>
                    Past Screenings
                  </p>
                  <p className={`${classes.analytics_cards_inner_red} ${classes.analytics_mouseover_data_title_count}`}>
                    {getPastScreenings()}
                  </p>
                </div>
                <div>
                  <p className={classes.analytics_mouseover_data_title}>
                    Ongoing Screenings
                  </p>
                  <p className={`${classes.analytics_cards_inner_red} ${classes.analytics_mouseover_data_title_count}`}>
                    {getOngoingScreenings()}
                  </p>
                </div>
                <div>
                  <p className={classes.analytics_mouseover_data_title}>
                    Upcoming Screenings
                  </p>
                  <p className={`${classes.analytics_cards_inner_red} ${classes.analytics_mouseover_data_title_count}`}>
                    {getUpcomingScreenings()}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Analytics Screening Container Ends Here */}

        <div className={classes.analytics_cards_main}>
          {/* Analytics Clinics Container Starts Here */}
          {analyticsClinicsCard ? (
            <div
              className={classes.analytics_cards_container_inner}
              onMouseOver={() => {
                setAnalyticsClinicsCard(false)
                setAnalyticsScreeningsCard(true)
                setAnalyticsEventsCard(true)
                setAnalyticsSchoolsCard(true)

              }}
            >
              <p className={classes.analytics_cards_container_inner_header}>
                CLINICS
              </p>
              <div
                className={
                  classes.analytics_cards_container_logo_number_container
                }
              >
                <img src={clinicsviolet} />
                <p
                  className={`${classes.analytics_cards_container_logo_number_container_text} ${classes.analytics_cards_inner_violet}`}
                >
                  {data?.clinicCount}
                </p>
                <p
                  className={
                    classes.analytics_cards_container_logo_number_container_countText
                  }
                ></p>
              </div>
              <Link to={"/superadmin-clinic"}>
                <div
                  className={`${classes.analytics_cards_container_inner_subtext} ${classes.analytics_cards_inner_violet}`}
                >
                  Manage Clinic
                </div>
              </Link>
            </div>
          ) : (
            <div
              className={`${classes.analytics_cards_container_inner_mouseover_last_box} ${classes.analytics_clinic_card}`}
              onMouseLeave={() =>
                setAnalyticsClinicsCard(true)
              }
            >
              <p className={classes.analytics_cards_container_inner_header}>
                CLINICS
              </p>
              <p className={classes.analytics_card_mouseover_subtitle}>
                An overall analysis of skids and partner
                <br />
                clinics. Click here to{" "}
                <Link className={`${classes.events_link} ${classes.analytics_cards_inner_violet}`} to={"/superadmin-clinic"}>
                  Manage clinics
                </Link>
              </p>
              <div className={classes.analytics_card_mouseover_data_container}>
                <div>
                  <p className={classes.analytics_mouseover_data_title}>
                    Skids Clinics
                  </p>
                  <p className={`${classes.analytics_cards_inner_violet} ${classes.analytics_mouseover_data_title_count}`}>
                    {getSkidsClinic()}
                  </p>
                </div>
                <div>
                  <p className={classes.analytics_mouseover_data_title}>
                    Partner Clinic
                  </p>
                  <p className={`${classes.analytics_cards_inner_violet} ${classes.analytics_mouseover_data_title_count}`}>
                    {getPartnerClinic()}
                  </p>
                </div>
                <div>
                  <p className={classes.analytics_mouseover_data_title}>
                    Total Staff
                  </p>
                  <p className={`${classes.analytics_cards_inner_violet} ${classes.analytics_mouseover_data_title_count}`}>
                    {getTotalStaff()}
                  </p>
                </div>
                <div>
                  <p className={classes.analytics_mouseover_data_title}>
                    Till Date Screening
                  </p>
                  <p className={`${classes.analytics_cards_inner_violet} ${classes.analytics_mouseover_data_title_count}`}>
                    3,205
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Analytics Clinics Container Ends Here */}

      <div style={{ display: "flex", gap: "24px", marginTop: 30 }}>
        <div className={classes.graphTile}>
          <div className={classes.filterContainer}>
            <span className={classes.devices_title}>CLINICS</span>
            <div>
              <FormControl style={{ marginRight: 20, width: 100 }}>
                <InputLabel
                  id="demo-simple-select-label"
                  size="small"
                  sx={{
                    backgroundColor: "#FFF",
                    color: "#001028 !important",
                    fontFamily: "Inter",
                    fontSize: "14px"
                  }}
                >
                  City
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={""}
                  label="Rows"
                  onChange={(e) => { }}
                  size="small"
                  sx={{
                    backgroundColor: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      color: "#9d9d9d",
                    },
                    "&:active": {
                      backgroundColor: "#FFFFFF",
                      color: "#9d9d9d",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      borderRadius: "8px",
                      color: "#9d9d9d !important",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      color: "#9d9d9d !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      color: "#9d9d9d !important",
                    },
                    "& label.Mui-focused": {
                      color: "#9d9d9d !important",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        color: "#9d9d9d !important",
                      },
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "#6F7E90 !important",
                    },
                  }}
                >
                  <MenuItem value={"Bengaluru"}>Bengaluru</MenuItem>
                  <MenuItem value={"Gurugram"}>Gurugram</MenuItem>
                </Select>
              </FormControl>
              <FormControl style={{ marginRight: 20, width: 100 }}>
                <InputLabel
                  size="small"
                  id="demo-simple-select-label"
                  sx={{
                    backgroundColor: "#FFF",
                    color: "#001028 !important",
                    fontFamily: "Inter",
                    fontSize: "14px"
                  }}
                >
                  Clinic
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={""}
                  label="Rows"
                  onChange={(e) => { }}
                  size="small"
                  sx={{
                    backgroundColor: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      color: "#9d9d9d",
                    },
                    "&:active": {
                      backgroundColor: "#FFFFFF",
                      color: "#9d9d9d",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      borderRadius: "8px",
                      color: "#9d9d9d !important",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      color: "#9d9d9d !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      color: "#9d9d9d !important",
                    },
                    "& label.Mui-focused": {
                      color: "#9d9d9d !important",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        color: "#9d9d9d !important",
                      },
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "#6F7E90 !important",
                    },
                  }}
                >
                  <MenuItem value={"Clinic 1"}>Clinic 1</MenuItem>
                  <MenuItem value={"Clinic 2"}>Clinic 2</MenuItem>
                </Select>
              </FormControl>
              <FormControl style={{ marginRight: 20, width: 100 }}>
                <InputLabel
                  id="demo-simple-select-label"
                  size="small"
                  sx={{
                    backgroundColor: "#FFF",
                    color: "#001028 !important",
                    fontFamily: "Inter",
                    fontSize: "14px"
                  }}
                >
                  Time
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={""}
                  label="Rows"
                  onChange={(e) => { }}
                  size="small"
                  sx={{
                    backgroundColor: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      color: "#9d9d9d",
                    },
                    "&:active": {
                      backgroundColor: "#FFFFFF",
                      color: "#9d9d9d",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      borderRadius: "8px",
                      color: "#9d9d9d !important",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      color: "#9d9d9d !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      color: "#9d9d9d !important",
                    },
                    "& label.Mui-focused": {
                      color: "#9d9d9d !important",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        color: "#9d9d9d !important",
                      },
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "#6F7E90 !important",
                    },
                  }}
                >
                  <MenuItem value={"Today"}>Today</MenuItem>
                  <MenuItem value={"This Week"}>This Week</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div>{!loading && data && <ClinicChart data={data} />}</div>
        </div>

        <div className={classes.graphTile}>
          <div className={classes.filterContainer}>
            <span className={classes.devices_title}>D2C</span>
            <div>
              <FormControl style={{ marginRight: 20, width: 100 }}>
                <InputLabel
                  id="demo-simple-select-label"
                  size="small"
                  sx={{
                    backgroundColor: "#FFF",
                    color: "#001028 !important",
                    fontFamily: "Inter",
                    fontSize: "14px"
                  }}
                >
                  City
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={""}
                  label="Rows"
                  onChange={(e) => { }}
                  size="small"
                  sx={{
                    backgroundColor: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      color: "#9d9d9d",
                    },
                    "&:active": {
                      backgroundColor: "#FFFFFF",
                      color: "#9d9d9d",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      borderRadius: "8px",
                      color: "#9d9d9d !important",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      color: "#9d9d9d !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      color: "#9d9d9d !important",
                    },
                    "& label.Mui-focused": {
                      color: "#9d9d9d !important",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        color: "#9d9d9d !important",
                      },
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "#6F7E90 !important",
                    },
                  }}
                >
                  <MenuItem value={"Bengaluru"}>Bengaluru</MenuItem>
                  <MenuItem value={"Gurugram"}>Gurugram</MenuItem>
                </Select>
              </FormControl>
              <FormControl style={{ marginRight: 20, width: 100 }}>
                <InputLabel
                  id="demo-simple-select-label"
                  size="small"
                  sx={{
                    backgroundColor: "#FFF",
                    color: "#001028 !important",
                    fontFamily: "Inter",
                    fontSize: "14px"
                  }}
                >
                  Clinic
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={""}
                  label="Rows"
                  onChange={(e) => { }}
                  size="small"
                  sx={{
                    backgroundColor: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      color: "#9d9d9d",
                    },
                    "&:active": {
                      backgroundColor: "#FFFFFF",
                      color: "#9d9d9d",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      borderRadius: "8px",
                      color: "#9d9d9d !important",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      color: "#9d9d9d !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      color: "#9d9d9d !important",
                    },
                    "& label.Mui-focused": {
                      color: "#9d9d9d !important",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        color: "#9d9d9d !important",
                      },
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "#6F7E90 !important",
                    },
                  }}

                >
                  <MenuItem value={"Clinic 1"}>Clinic 1</MenuItem>
                  <MenuItem value={"Clinic 2"}>Clinic 2</MenuItem>
                </Select>
              </FormControl>
              <FormControl style={{ marginRight: 20, width: 100 }}>
                <InputLabel
                  id="demo-simple-select-label"
                  size="small"
                  sx={{
                    backgroundColor: "#FFF",
                    color: "#001028 !important",
                    fontFamily: "Inter",
                    fontSize: "14px"
                  }}
                >
                  Time
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={""}
                  label="Rows"
                  onChange={(e) => { }}
                  size="small"
                  sx={{
                    backgroundColor: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      color: "#9d9d9d",
                    },
                    "&:active": {
                      backgroundColor: "#FFFFFF",
                      color: "#9d9d9d",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      borderRadius: "8px",
                      color: "#9d9d9d !important",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      color: "#9d9d9d !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      color: "#9d9d9d !important",
                    },
                    "& label.Mui-focused": {
                      color: "#9d9d9d !important",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        color: "#9d9d9d !important",
                      },
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "#6F7E90 !important",
                    },
                  }}
                >
                  <MenuItem value={"Today"}>Today</MenuItem>
                  <MenuItem value={"This Week"}>This Week</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div>{!loading && data && <SuperAdminD2CGraph data={data} />}</div>
        </div>
      </div>

      {/* Devices Data Container Starts Here*/}

      <div className={classes.devices_data_container}>
        <div className={classes.devices_container}>
          <p className={classes.devices_title}>DEVICES</p>
          <div className={classes.devices_logo_number}>
            <img src={devicelogo} />
            <p className={classes.device_pupil_count}>160</p>
          </div>

          <div className={classes.device_table_container}>
            <table>
              <thead>
                <tr>
                  {table_header.map((el) => {
                    return <th>{el.theadname}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {table_data.map((el) => {
                  return (
                    <tr className={classes.tablebody_row}>
                      <td>{el.deviceName}</td>
                      <td>{el.num}</td>
                      <td>{el.gur}</td>
                      <td>{el.blr}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Link>
            <div className={classes.manage_devices_link}>Manage Devices</div>
          </Link>
        </div>

        <div className={classes.data_container}>
          {physicalArr.map((el, index) => {
            return (
              <div className={classes.data_container_boxes}>
                <p className={classes.analytics_cards_container_inner_header}>
                  {el.title}
                </p>
                <div
                  className={
                    classes.analytics_cards_container_logo_number_container
                  }
                >
                  <img
                    src={`${index < 2 ? `${screeningsred}` : `${screeningsyellow}`
                      }`}
                  />
                  <p
                    className={`${index < 2
                      ? `${classes.analytics_cards_container_logo_number_container_text} ${classes.analytics_device_data_card_red}`
                      : `${classes.analytics_cards_container_logo_number_container_text} ${classes.analytics_device_data_card_yellow}`
                      }`}
                  >
                    {el.data}
                  </p>
                  <p
                    className={
                      classes.analytics_cards_container_logo_number_container_countText
                    }
                  >
                    till date
                  </p>
                </div>
                <Link to={el?.navLink}>
                  <span
                    className={`${index < 2
                      ? `${classes.analytics_cards_container_inner_subtext} ${classes.analytics_device_data_card_red}`
                      : `${classes.analytics_cards_container_inner_subtext} ${classes.analytics_device_data_card_yellow}`
                      }`}
                  >
                    {el.link}
                  </span>
                </Link>
                <span
                  className={classes.analytics_cards_container_inner_subtext}
                >
                  {el.linkText}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
