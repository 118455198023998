import { Alert, Button, Input, Snackbar } from '@mui/material'
import { API } from 'aws-amplify'
import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classes from './BulkUpload.module.css'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { AttachFileOutlined, DeleteOutlined, DeleteOutlineOutlined } from '@mui/icons-material'
import { read, write, utils } from 'xlsx';
import sampleFile from "../../assets/Student_xlsx_sample.xlsx";
import teacherSample from "../../assets/TeacherBulkUpload.xlsx";
import LoadingBar from 'react-top-loading-bar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import downloadwhite from '../../assets/images/downloadwhite.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';
import studentReducer from '../../redux/reducers/student.reducer'
import userReducer from '../../redux/reducers/user.reducer'

function BulkUpload({ openModal, setOpenModal, handleCloseModal, type }) {

  const [file, setFile] = useState("")
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getStudents = studentReducer.actions.getStudentBySchoolIDRequest;
  const bulkTeacherUpload = userReducer.actions.bulkUploadTeacherRequest;
  const getUserByGroup = userReducer.actions.getUserByGroupRequest;

  const teacherError = useSelector((state) => state.userReducer.bulkUploadError)
  const teacherLoading = useSelector((state) => state.userReducer.bulkUploadLoading)

  let schoolId = useSelector((state) => state.userReducer.currentUser.schoolId)

  if (!schoolId) {
    schoolId = location.state?.schoolId
  }

  const handleonchange = (e) => {
    e.preventDefault()
    setFile(e.target.files[0])
    e.target.value = ''
  }

  const deleteFile = (e) => {
    setFile("")
    e.target.value = ''
  }

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setFile(e.dataTransfer.files[0])
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const toBase64 = () => new Promise((resolve, reject) => {
    const readers = new FileReader();
    readers.readAsArrayBuffer(file);
    readers.onload = () => {
      resolve(Buffer.from(readers.result).toJSON())
    };
    readers.onerror = error => reject(error);
  });

  const handleSubmit = async (e) => {

    // var reader = new FileReader();
    // reader.readAsBinaryString(file);
    // reader.onload = function (e) {
    //   var data = e.target.result;
    //   var workbook = read(data, {
    //     type: 'binary'
    //   });
    //   workbook.SheetNames.forEach(function (sheetName){
    //     console.log(utils.sheet_to_json(workbook.Sheets[sheetName]));
    //   })     
    // }

    e.preventDefault();
    setProgress(30)
    if (type === "teacher") {
      var reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = async function (e) {

        var data = e.target.result;
        var workbook = read(data, {
          type: 'binary'
        });
        var result = {};
        workbook.SheetNames.forEach(function (sheetName) {
          var roa = utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          if (roa.length > 0) {
            result['data'] = roa;
          }
        });

        await dispatch(bulkTeacherUpload({ teachers: result?.data, schoolId: schoolId }))
        setProgress(60)
        setTimeout(() => {
          handleClick()
          setFile("")
          handleCloseModal()
          // dispatch(getUserByGroup({role:"school-teacher", schoolId: schoolId}))
          setProgress(100)
        }, 2000)
      }


    }
    else {
      setLoading(true)
      const token = Cookies.get("tokenId")
      const binFile = await toBase64()

      const init = {
        method: 'POST',
        body: binFile,
        headers: {
          "Authorization": token
        }
      }
      API.post('bulkUpload', `/${schoolId}`, init)
        .then(response => {
          console.log(response)
          if (response.message === "Job submitted successfully") {
            if (location.pathname.includes("superadmin")) {
              navigate("/superadmin/bulk-upload-error", { state: { schoolId: schoolId, breadCrumbs: [...location.state.breadCrumbs, "Upload Failed Data"] } })
            } else if (location.pathname.includes("partner")) {
              navigate("/partner/bulk-upload-error", { state: { schoolId: schoolId, breadCrumbs: [...location.state.breadCrumbs, "Upload Failed Data"] } })
            } else {
              navigate("/admin/bulk-upload-error", { state: { schoolId: schoolId, breadCrumbs: [...location.state.breadCrumbs, "Upload Failed Data"] } })
            }
            // }
            // if (response.data.failedItems.length > 0) {
            //   setError(true)
            //   handleClick()
            //   if (location.pathname.includes("superadmin")) {
            //     navigate("/superadmin/bulk-upload-error", { state: { data: response.data, breadCrumbs: [...location.state.breadCrumbs, "Upload Failed Data"] } })
            //   } else {
            //     navigate("/admin/bulk-upload-error", { state: { data: response.data, breadCrumbs: [...location.state.breadCrumbs, "Upload Failed Data"] } })
            //   }
          } else {
            setError(false)
            handleClick()
            setFile("")
            handleCloseModal()
            dispatch(getStudents({ schoolId }))
          }
        }
        )
        .catch(error => {

          setError(true);
          handleClick()
        })
        .finally(() => {
          setProgress(100);
          setLoading(false)
        });
    }
  }

  return (
    <>
      <LoadingBar
        color='#1740A1'
        height={4}
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={classes.divContainer}>
            <form className={classes.formContainer} onSubmit={handleSubmit}>
              <a className={`${classes.downloadButton}`} href={type === "teacher" ? teacherSample : sampleFile} download={type === "teacher" ? "SampleTeacherExcel.xlsx" : "SampleStudentExcel.xlsx"}>
                <img src={downloadwhite} />
                <a>Download reference file</a>
              </a>


              <div className={classes.uploadFilesContainer}>
                <div className={classes.dragFileArea} onDrop={handleDrop} onDragOver={handleDragOver}>
                  <div className={classes.uploadIconDiv}>
                    <span className={`${"material-icons-outlined"}, ${classes.uploadIcon}`}> <FileUploadOutlinedIcon style={{ width: "20px", height: "20px" }} /> </span>
                    <span style={{ fontSize: 16 }}>Upload .xlsx file</span>
                  </div>
                  <h3 className={classes.dynamicMessage}> Drag & drop excel file here </h3>
                  <div style={{ color: "#B2B7BE", marginTop: 20 }}>or</div>
                  <label className={classes.label}> <span className={classes.browseFiles}>
                    <input type="file" accept='.xls, .xlsx' className={classes.defaultFileInput} onChange={handleonchange} />
                    <span className={classes.browseFilesText}>Browse file</span> <span>from device</span> </span>
                  </label>
                </div>
                <span className={classes.cannotUploadMessage}> <span className="material-icons-outlined">error</span> Please select a file first <span className={`material-icons-outlined ${classes.cancelAlertButton}`}>cancel</span> </span>
                {file &&
                  <p className={classes.fileBlock}>
                    <AttachFileOutlined style={{ fill: "white" }} />
                    {`${file?.name} (${(file?.size / 1024).toFixed(0)}kb)`}
                    <DeleteOutlined style={{ fill: "white", cursor: "pointer" }} onClick={deleteFile} />
                  </p>
                }
                <div className={classes.buttonDiv}>
                  <button type='button' className={`${classes.cancelButton}`} onClick={() => handleCloseModal()}>Cancel</button>
                  <button type='submit' style={!file?.name ? { opacity: 0.5 } : {}} className={`${classes.uploadButton}`} disabled={!file?.name}> Upload </button>
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
      {type !== "teacher" && !loading && <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={`${error ? "error" : "success"}`} sx={{ width: '100%' }}>
          {error ? "Error while uploading data, please check the excel file uploaded" : "Uploaded Successfully"}
        </Alert>
      </Snackbar>}
      {type === "teacher" && !teacherLoading && <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={`${teacherError ? "error" : "success"}`} sx={{ width: '100%' }}>
          {teacherError ? "Error while uploading data, please check the excel file uploaded" : "Teachers Uploaded Successfully"}
        </Alert>
      </Snackbar>}
    </>
  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "16px",
  p: 4,
};

export default BulkUpload