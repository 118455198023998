import React from 'react'
import classes from './BlankDataScreen.module.css'
import PreScreening from "../../assets/images/ScreeningNotScheduled.png"
import YetToScreen from "../../assets/images/YetToScreen.png"
import noDataScreen from "../../assets/images/noData.png"
import { useLocation, useNavigate } from 'react-router-dom'
import { toDateString } from '../../utils/utility'

export default function BlankDataScreen({ title, description, handleClick, buttonText, role, hideButton }) {
    const location = useLocation()
    const navigate = useNavigate()
    
    const handleAddRole = () => {
        if (role === "Principal") {
            navigate('/superadmin-users/individual/user', { state: { type: "principal", schoolId: location.state, breadCrumbs: [...location.state.breadCrumbs, "Add Principal"] } })
        }
        else if (role === "Teacher") {
            if (location.pathname.includes("superadmin")) {
                navigate('/superadmin-users/individual/user', { state: { type: "teacher", schoolId: location.state, breadCrumbs: [...location.state.breadCrumbs, "Add Teacher"] } })
            }
            else if (location.pathname.includes("principal")) {
                navigate('/principal-teacher/individual/user', { state: { type: "teacher", } })
            }
            else if (location.pathname.includes("admin")) {
                navigate('/admin-teacher/individual/user', { state: { type: "teacher", } })
            }
        } else if (role === 'Admin') {
            navigate('/superadmin-users/individual/user', { state: { type: "admin", schoolId: location.state, breadCrumbs: [...location.state.breadCrumbs, "Add Admin"] } })
        } else if (role === 'Counsellor') {
            navigate('/superadmin-users/individual/user', { state: { type: "counsellor", schoolId: location.state, breadCrumbs: [...location.state.breadCrumbs, "Add Counsellor"] } })
        }
        else if (location.pathname.includes('clinic') && location.pathname.includes('doctor')) {
            // navigate(':userentity/individual/person')
            navigate('/superadmin-users/individual/user', { state: { type: "doctor", schoolId: location.state }, breadCrumbs: [...location.state.breadCrumbs, "Add Doctor"] })
        }
        else if (location.pathname.includes('clinic') && location.pathname.includes('nurse')) {
            // navigate(':userentity/individual/person')
            navigate('/superadmin-users/individual/user', { state: { type: "nurse", schoolId: location.state, breadCrumbs: [...location.state.breadCrumbs, "Add Nurse"] } })
        }
        else if (location.pathname.includes('school') && location.pathname.includes('doctor')) {
            // navigate(':userentity/individual/person')
            navigate('/superadmin-users/individual/user', { state: { type: "infirmary", schoolId: location.state }, breadCrumbs: [...location.state.breadCrumbs, "Add Infirmary"] })
        }
        else if (location.pathname.includes('school')) {
            navigate('/superadmin-school/school', { state: { ...location.state, breadCrumbs: [...location.state.breadCrumbs, "Add School"] } })
        } else if (location.pathname.includes('clinic')) {
            navigate('/superadmin-clinc/clinic')
        } else if (location.pathname.includes('clinic') && location.pathname.includes('staff')) {
            navigate(':userentity/individual/person')
        } else if (location.pathname.includes('clinic') && location.pathname.includes('staff')) {
            navigate(':userentity/individual/person')
        } else if (location.pathname?.includes("principal-infirmary")) {
            navigate('/principal-infirmary/individual/user', { state: { type: "infirmary", schoolId: location.state } })
        } else if (role === "Internal User") {
            navigate('/superadmin-users/individual/user', { state: { type: "Internal User" } })
        } else if (role === "Infirmary") {
            if (location.pathname.includes('admin-infirmary')) {
                navigate('/admin-infirmary/individual/user', { state: { type: "infirmary", } })
            }
        }
    }



    return (
        <div className={classes.noScreening}>
            <img src={noDataScreen} alt="Date not scheduled" />
            <div>
                {title}
            </div>
            <p>
                {description}
            </p>
            {!hideButton && <div onClick={() => role ? handleAddRole() : handleClick()} disabled={role == "parent"}>{buttonText}</div>}
        </div >
    )
}
