import React, { useEffect } from "react";
import classes from "./MyProfile.module.css";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import schoollogo from "../../../assets/images/schoollogo.png"
import email from "../../../assets/images/email.svg";
import phone from "../../../assets/images/phone.svg";
import address from "../../../assets/images/address.svg";
import extraactivities from "../../../assets/images/extraactivities.svg";
import infirmary from "../../../assets/images/infirmary.svg";
import medical from "../../../assets/images/medical.svg";
import library from "../../../assets/images/library.svg";
import internet from "../../../assets/images/internet.svg";
import bus from "../../../assets/images/bus.svg";
import cab from "../../../assets/images/cab.svg";
import hotel from "../../../assets/images/hotel.svg";
import canteen from "../../../assets/images/canteen.svg";
import sanitizer from "../../../assets/images/sanitizer.svg";
import smartclass from "../../../assets/images/smartclass.svg";
import doctor from "../../../assets/images/doctor.png";
import staff from "../../../assets/images/staff.png";
import counsellors from "../../../assets/images/counsellors.png";
import nurse from '../../../assets/images/nurse.png'
import students from '../../../assets/images/students.png'
import { Avatar, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import skidsLogo from '../../../assets/images/skidsLogo.png'
import ArrowRight from "@mui/icons-material/ArrowRight";
import ActionModal from '../ActionModal/ActionModal'
import BreadCrumbs from "../TableComponents/BreadCrumbs";
import studentsWhite from "../../../assets/images/students-white.svg";
import teacherblue from '../../../assets/images/teacherblue.svg'
import studentsblue from '../../../assets/images/studentsblue.svg'
import { useSelector } from "react-redux";



function MyProfilePage() {
  const location = useLocation();
  const navigate = useNavigate();

  const user = useSelector((state)=>state.userReducer.currentUser)

  const data = useSelector((state)=>state.dashboardReducer.data)

  let role

  if (user.groups.includes("school-admin")){
    role = "School Admin"
  }
  else if(user.groups.includes("skids-sa")){
    role = "Super User"
  }
  else if(user.groups.includes("school-principal")){
    role = "Principal"
  }
  else if(user.groups.includes("school-teacher")){
    role = "Teacher"
  }
  

  // const data = location.state;
  let navLink = "/superadmin-school"
  let navLinkHeaderSec = "Santhiniketan School"
  let editLink = "/superadmin-school/school"
  let status = "INACTIVE"
  let brandLogo = schoollogo
  let entity = "School"
  let entityName = "Santhiniketan School"
  let entityTitle = "CBSE affiliated"
  let basic_info = [
    {
      label: "Organization name",
      value: 'Not available',
    },
    {
      label: "Annual orientation month",
      value: data?.annualOrientationMonth ?? 'Not available',
    },
    {
      label: "Established from",
      value: data?.establishedFrom ?? 'Not available',
    },
    {
      label: "Screening Plan",
      value: data?.screeningPlan ?? 'Not available',
    },
    {
      label: "Screening From",
      value: data?.screeningFrom ?? 'Not available',
    },
    {
      label: "Screening From",
      value: data?.screeningTo ?? 'Not available',
    },

  ];
  let contact_details = [
    {
      icon: email,
      label: "Email id",
      value: "school@santhineketan.com",
    },
    {
      icon: phone,
      label: "Mobile no.",
      value: "9876543210",
    },
    {
      icon: address,
      label: "Address",
      value: data?.address ?? 'Not available',
    },
    {
      icon: teacherblue,
      label: "Skids Team",
      link: "/superadmin-school/table/admin",
      value: "1",
    },
    {
      icon: teacherblue,
      label: "Clinic",
      link: "/superadmin-school/table/teacher",
      value: data?.staffAndAmenities?.teachers ?? 'Not available',
    },
    {
      icon: studentsblue,
      label: "School",
      link: "/superadmin-school/table/student",
      value: data?.strength ?? 'Not available',
    },

    {
      icon: counsellors,
      label: "Principal",
      link: "/superadmin-school/table/principal",
      value: "1",
    },
    {
      icon: counsellors,
      label: "Counsellors",
      link: "/superadmin-school/table/counsellor",
      value: data?.staffAndAmenities?.counsellors === true ? 'Available' : 'Unavailable',
    },
    {
      icon: doctor,
      label: "Infirmary",
      link: "/superadmin-school/table/doctor",
      value: data?.staffAndAmenities?.infirmary === true ? 'Available' : 'Unavailable',
    }

  ];

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }
  
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width : 12,
        height : 12,
        padding : 2
      },
      children: `${name[0]}`,
    };
  }


  return (
    <div className={classes.school_info}>
      <div className={classes.school_header}>
        <div className={classes.header}>
          <BreadCrumbs  />
        </div>
        {/* <button className={classes.edit_button}>
          {" "}
          <Link to={editLink} style={{ textDecoration: "none", color: "white" }}>
            {" "}
            Edit{" "}
          </Link>{" "}
        </button> */}

      </div>
      <div className={classes.school_container}>
        <div className={`${entity === 'Clinic' ? `${classes.brandlogo_container} ${classes.brandlogo_container_clinic}` :
          `${classes.brandlogo_container}`}`}>
            <Avatar {...stringAvatar(`${user.given_name} ${user.family_name}`)} sx = {{ width : 120, height : 120, fontSize : 72, fontWeight : "bold"}}/>
        </div>

        <div className={classes.school_name}>{`${user.given_name} ${user.family_name}`}</div>
        <div className={classes.school_title}>{role}</div>

        <div className = {classes.header_information}>
        <div>Personal Information</div>
        <div>User Management</div>
        </div>
        <div className={classes.contact_detail}>
          <div>
            <div className={classes.contacts}>
              <img
                className={classes.contacts_icon}
                alt=""
                src={email}
              />
              <div className={classes.contacts_value}>
                <div>Email ID</div>
                <div>{user.email}</div>
              </div>
            </div>
            <div className={classes.contacts}>
              <img
                className={classes.contacts_icon}
                alt=""
                src={phone}
              />
              <div className={classes.contacts_value}>
                <div>Mobile no.</div>
                <div>{user.phone ?? "Unavailable"}</div>
              </div>
            </div>
            <div className={classes.contacts}>
              <img
                className={classes.contacts_icon}
                alt=""
                src={address}
              />
              <div className={classes.contacts_value}>
                <div>Address</div>
                <div>{user.address ?? "Unavailable"}</div>
              </div>
            </div>
          </div>
          <div className={classes.contact_right_pannel}>
            <Divider orientation="vertical" variant="middle" flexItem />
            <div className={classes.contact_items_right}>
              {contact_details?.map(
                (item, index) =>
                  index < 6 && index >= 3 && (

                    <div className={classes.contacts}>
                      <img
                        className={classes.contacts_icon}
                        alt=""
                        src={item.icon}
                      ></img>
                      <Link to={item.link}>
                        <div className={classes.contacts_value_right}>
                          <div>{item.label}</div>
                          <div>{item.value}</div>
                        </div>
                      </Link>
                    </div>
                  )
              )}
            </div>

          </div>
        </div>

      </div>
    </div>
  );
}

function MyProfile() {

  return (
      <MyProfilePage />
  )
}

export default MyProfile;
