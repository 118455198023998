import React from "react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import { TextField } from "@mui/material";
import classes from './Input.module.css'
import { useAsyncValue } from "react-router-dom";
import { getValue } from "@testing-library/user-event/dist/utils";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

function InputPage({ placeholder, isPassword, width, change, value, Id }) {
  const [showPassword, setShowPassword] = React.useState(false);



  //handles the visibility of password
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  return (
    <div>
      {isPassword ?
        (
          <TextField
            autoComplete="new-password"
            label={placeholder}
            id="outlined-size-small"
            type={showPassword ? "text" : "password"}
            value={value}
            size="normal"
            required
            sx={
              {
                width: width,
                height: "6vh",
                backgroundColor: "#FFF",
                fontFamily: "Inter",
                margin: "28px 0 0 0",
                borderRadius: "8px"
              }
            }
            onChange={change}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff
                        sx={{
                          fill: "#fff",
                          stroke: "#1740A1",
                          strokeWidth: "1px"
                        }} />
                    ) : (
                      <Visibility
                        sx={{
                          fill: "#fff",
                          stroke: "#1740A1",
                          strokeWidth: "1px"
                        }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                notchedOutline: classes['input-border'],
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: classes.inputLabel,
                asterisk: classes.labelAsterisk
              }
            }}
          />
        ) :
        (
          <TextField
            autoFocus
            label={placeholder}
            id="outlined-size-small"
            value={value}
            size="normal"
            required
            sx={
              {
                width: width,
                height: "6vh",
                backgroundColor: "#FFF",
                margin: "24px 0 0 0",
                borderRadius: "8px",
                textAlign: "left"
              }
            }
            onChange={change}
            InputProps={{
              classes: {
                notchedOutline: classes['input-border'],
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: classes.inputLabel,
                asterisk: classes.labelAsterisk
              }
            }}
          />
        )
      }
    </div>
  );
}

export default InputPage;
