import InitialStore from "../store/InitialStore";
import { createSlice } from "@reduxjs/toolkit";

export const getPrincipalDashboardRequest = (state, action) => {
    state.data = [];
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
};

export const getPrincipalDashboardSuccess = (state, action) => {
    state.data = JSON.parse(action.payload.data);
    state.loading = false;
}

export const getPrincipalDashboardFail = (state, action) => {
    state.error = true;
    state.loading = false
};

export const getTeacherDashboardRequest = (state, action) => {
    state.data = [];
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
};

export const getTeacherDashboardSuccess = (state, action) => {
    state.data = JSON.parse(action.payload.data);
    state.loading = false;
}

export const getTeacherDashboardFail = (state, action) => {
    state.error = true;
    state.loading = false
};

export const getSchoolAdminDashboardRequest = (state, action) => {
    state.data = [];
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
};

export const getSchoolAdminDashboardSuccess = (state, action) => {
    state.data = action.payload.data;
    state.loading = false;
}

export const getSchoolAdminDashboardFail = (state, action) => {
    state.error = true;
    state.loading = false
};

export const getSuperAdminDashboardRequest = (state, action) => {
    state.data = [];
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
};

export const getSuperAdminDashboardSuccess = (state, action) => {
    state.data = action.payload.data;
    state.loading = false;
}

export const getSuperAdminDashboardFail = (state, action) => {
    state.error = true;
    state.loading = false
};

export const getSuperAdminDashboardLiveScreeningRequest = (state, action) => {
    state.liveScreeningData = [];
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
};

export const getSuperAdminDashboardLiveScreeningSuccess = (state, action) => {
    state.liveScreeningData = action.payload.data;
    state.loading = false;
}

export const getSuperAdminDashboardLiveScreeningFail = (state, action) => {
    state.error = true;
    state.loading = false
};
export const getPrincipalLiveScreeningRequest = (state, action) => {
    state.liveScreeningData = [];
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
}

export const getSuperAdminDashboardLiveValidationRequest = (state, action) => {
    state.liveDoctorValidationData = [];
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
};

export const getSuperAdminDashboardLiveValidationSuccess = (state, action) => {
    state.liveDoctorValidationData = action.payload.data;
    state.loading = false;
}

export const getSuperAdminDashboardLiveVaidationFail = (state, action) => {
    state.error = true;
    state.loading = false
};


export const updateDashboardReq = (state, action) => {
}


export const getBehavioralDashboardReq = (state, action) => {
    state.data = [];
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
}

export const behavioralDashboardSuccess = (state, action) => {
    state.data = action.payload.data;
    state.loading = false;
}

export const behavioralDashboardFailure = (state, action) => {
    state.error = true;
    state.loading = false
}



export default createSlice({
    name: "dashboardReducer",
    initialState: InitialStore.dashboard,
    reducers: {
        getPrincipalDashboardRequest,
        getPrincipalDashboardSuccess,
        getPrincipalDashboardFail,
        getTeacherDashboardRequest,
        getTeacherDashboardSuccess,
        getTeacherDashboardFail,
        getSchoolAdminDashboardRequest,
        getSchoolAdminDashboardSuccess,
        getSchoolAdminDashboardFail,
        getSuperAdminDashboardRequest,
        getSuperAdminDashboardSuccess,
        getSuperAdminDashboardFail,
        getSuperAdminDashboardLiveScreeningRequest,
        getSuperAdminDashboardLiveScreeningSuccess,
        getSuperAdminDashboardLiveScreeningFail,
        getPrincipalLiveScreeningRequest,
        getSuperAdminDashboardLiveValidationRequest,
        getSuperAdminDashboardLiveValidationSuccess,
        getSuperAdminDashboardLiveVaidationFail,
        updateDashboardReq,
        getBehavioralDashboardReq,
        behavioralDashboardSuccess,
        behavioralDashboardFailure
    },
});