import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ArrowRight from "@mui/icons-material/ArrowRight";
import ArrowLeft from "@mui/icons-material/ArrowLeft"
import ImageSlideshow from './ImageSlideshow'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const slideshow_container = {
  position: 'relative',
  height: '60vh',
  width: '60vw',
  borderRadius: '10px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline : 'none',
}

const image_slides = {
  height: '60vh',
  width: '60vw',
  borderRadius: '10px',
  objectFit:"contain",
  backgroundColor: "#0000008f"
}

const num_indicator = {
  position: 'absolute',
  textAlign: 'center',
  width: '60px',
  height: '16px',
  borderRadius: '5px',
  color: '#fff',
  padding: '0 10px',
  fontSize: '13px',
  top: '0',
  left: '50%',
  backgroundColor: '#000'
}

const arrow_left = {
  position: 'absolute',
  background: 'none',
  border: 'none',
  top: '45%',
  left: '0',
  cursor: 'pointer',
  color: '#fff'
}

const arrow_right = {
  position: 'absolute',
  background: 'none',
  border: 'none',
  top: '45%',
  right: '0',
  cursor: 'pointer',
  color: '#fff'
}


export default function BasicModal(props) {
  const { imgArray } = props;
  const [imagePage, setImagePage] = React.useState(0)
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  
  return (
    <div>
      <ImageSlideshow imgArray={imgArray} onclick={handleOpen} /> 
      <Modal
      sx={{backdropFilter:'blur(2px)'}}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box sx={style}> */}
        <div style={slideshow_container}>
          <img style={image_slides}
            src={imgArray?.[imagePage]} />
          {
            imgArray?.length > 1 &&
            <>
              <div style={num_indicator}>{imagePage + 1}/{imgArray?.length}</div>
              <button onClick={() => setImagePage(page => page - 1)}
                style={arrow_left}><ArrowLeft /></button>
              <button onClick={() => setImagePage(page => page + 1)}
                style={arrow_right}><ArrowRight /></button>
            </>
          }
        </div>
        {/* </Box> */}
      </Modal>
    </div>
  );
}