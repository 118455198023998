import { FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import InputDashboard from '../../common/InputDashboard/InputDashboard';
import InputDropdown from '../../common/InputDashboard/InputDropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './AddLog.module.css'
import UploadFile from './UploadFile';
import { useState } from 'react';
import BreadCrumbs from '../../common/TableComponents/BreadCrumbs';
import { Visibility } from '@mui/icons-material';
import Cookies from 'js-cookie';
import axios from 'axios';
import { ImgDataToBuffer, textFormat } from '../../../utils/utility';
import { Buffer } from 'buffer';

function AddLog() {
    const navigate = useNavigate()
    const [assessment, setAssessment] = useState('')
    const [file, setFile] = useState('')
    const location = useLocation()

    const toArrayBuffer = () => new Promise((resolve, reject) => {
        // const arr = file.split(',');
        fetch(file)
            .then(function (result) {
                return resolve(result.arrayBuffer())
            }).catch((err) => { return reject(err) });
    });

    const toBase64 = () => new Promise((resolve, reject) => {
        const readers = new FileReader();
        readers.readAsArrayBuffer(file);
        readers.onload = () => {
            resolve(Buffer.from(readers.result))
        };
        readers.onerror = error => reject(error);
    });

    const handleAddLog = async () => {
        const token = Cookies.get('tokenId')
        const endpoint = process.env.REACT_APP_D2C_URL
        const headers = {
            "content-type": "application/json",
            "Authorization": token
        };
        let x = assessment
        if (x === "Screen dependency disorder") {
            x = "Internet Addiction"
        }
        const y = x.toUpperCase().split(" ").join("_")
        console.log(location.state)
        const q = JSON.parse(location.state.data[y])

        const p = file.name.split(".")[1]

        const url = `${process.env.REACT_APP_PRESCRIPTION}/${location.state.data.assessmentId}/${y}.${p}`

        q['prescription'] = url

        const graphqlQuery = {
            "operationName": "MyMutation",
            "query": `mutation MyMutation($op: AWSJSON) { updateBhvAssign(kidId: "${location.state.data.kidId}", assessmentId: "${location.state.data.assessmentId}", ${y}: $op){${y}}}`,
            "variables": { op: JSON.stringify(q) }
        };

        const options = {
            "method": "POST",
            "headers": headers,
            "body": JSON.stringify(graphqlQuery)
        };


        const a = await toBase64();
        // const a = Buffer.from(file).toString('base64')
        // var reader = new FileReader();
        // const a = await reader.readAsDataURL(file);

        console.log(a)
        const config = {
            method: "PUT",
            // headers: { "Content-Type": "application/json" }
        };

        axios.put(url, a, config).then(async (res) => {
            const response = await fetch(endpoint, options);
            setTimeout(() => { navigate(-1) }, 2000)
        }).catch((err) => { console.log(err) })

    }

    useEffect(() => {
        console.log(file)
    }, [file])

    return (
        <>
            <section className={classes.TopNav}>
                <div>
                    Add Log
                </div>
                {/* <div className={classes.buttonNav}>
                    <button
                        className={classes.button_filled}
                        onClick={() => navigate("/behavioral-doctor/dashboard/patients/log")}
                    >
                        <Visibility />
                        <span>Screening</span>
                    </button>
                </div> */}

            </section>
            <div className={classes.formContainer}>
                {/* form add log  */}

                <section className={classes.topForm}>
                    <div>
                        <h1>Add Prescription</h1>
                        <p>Please enter available data for children behavioral log</p>
                        <div>
                            <InputDropdown
                                placeholder={"Assessment"}
                                width={"400px"}
                                bgColor="#ffffff"
                                value={assessment}
                                setValue={setAssessment}
                                defaultValues={location.state.data.package.map((x) => textFormat(x))}
                            // margin={2.4}
                            />
                            {/* <InputDashboard
                                placeholder="Enter Score"
                                width={"188px"}
                                // className={classes.input_layout_schoolname}
                                // change={(e) => {
                                //     setSchoolName(e.target.value);
                                // }}
                                bgColor="#ffffff"
                                type="number"
                            // value={schoolName}
                            /> */}
                        </div>
                    </div>
                    {/* <div className={classes.checkContainer}>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Mark email</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="female" control={<Radio />} label="None" />
                                <FormControlLabel value="male" control={<Radio />} label="Parent" />
                            </RadioGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Mark Whatsapp</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="female" control={<Radio />} label="None" />
                                <FormControlLabel value="male" control={<Radio />} label="Parent" />
                            </RadioGroup>
                        </FormControl>
                    </div> */}
                </section>
                {/* <div>
                    <h1>Observation</h1>
                    <InputDashboard
                        placeholder="Enter Observation"
                        width={"100%"}
                        className={classes.input_layout}
                        // change={(e) => {
                        //     setSchoolName(e.target.value);
                        // }}
                        bgColor="#ffffff"
                    // value={schoolName}
                    />
                </div> */}
                {/* <div>
                    <h1>Prescription</h1>
                    <InputDashboard
                        placeholder="Prescription Suggested"
                        width={"100%"}
                        className={classes.input_layout}
                        // change={(e) => {
                        //     setSchoolName(e.target.value);
                        // }}
                        bgColor="#ffffff"
                    // value={schoolName}
                    />
                </div> */}
                <div>
                    <h1>Prescription</h1>
                    <UploadFile uploadFile={file} setUploadFile={setFile} fileType={".jpg, .pdf, .docx"} />
                </div>
                <div className={classes.buttons}>
                    <button
                        className={classes.button_with_border}
                        onClick={() => navigate(-1)}
                    >
                        CANCEL
                    </button>
                    <button
                        className={classes.add_log}
                        onClick={() => { handleAddLog() }}
                    >
                        Add Log
                    </button>
                </div>
            </div>
        </>

    )
}

export default AddLog