import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "./InputDropDown.css";
import { formControlUnstyledClasses } from "@mui/base";

function InputDropdown({
  placeholder,
  defaultValues,
  width,
  value,
  setValue,
  change,
  bgColor,
  disabled,
  margin,
  noneMenu,
  required
}) {
  return (
    <div>
      <FormControl
        variant="outlined"
        sx={{
          width: width,
          height: "6.5vh",
          // border: "1px solid #e8e7e7",
          // overflow: "hidden",
          // borderRadius: 2,
          // backgroundColor: bgColor,
          mt: margin,
        }}
        required={required === false ? false : true}
      >
        <InputLabel
          id="demo-simple-select-filled-label"
          sx={{
            backgroundColor: "#FFF",
            color: "#9d9d9d !important",
            fontFamily: "Inter",
            fontSize: "14px"
          }}
          size="small"
        >
          {placeholder}
        </InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          size="small"
          disabled={disabled}
          value={value}
          sx={{
            cursor: disabled && 'not-allowed',
            backgroundColor: bgColor,
            "&:hover": {
              backgroundColor: bgColor,
              color: '#9d9d9d'
            },
            "&:active": {
              backgroundColor: bgColor,
              color: '#9d9d9d'
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#E8E7E7",
              borderRadius: "8px",
              color: '#9d9d9d !important'
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#E8E7E7",
              color: '#9d9d9d !important'
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#E8E7E7",
              color: '#9d9d9d !important'
            },
            "& label.Mui-focused": {
              color: "#9d9d9d !important"
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                color: "#9d9d9d !important"
              }
            },
            ".MuiSvgIcon-root ": {
              fill: "#6F7E90 !important",
            },
            ".Mui-disabled ": {
              cursor: "not-allowed"
            }
          }}
        >
          {/* {noneMenu ? (<></>) : 
          (
            <MenuItem value="">
            <em>None</em>
          </MenuItem>
          )
          } */}

          {defaultValues?.map((item, index) => {
            return (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

export default InputDropdown;
