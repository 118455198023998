import React from "react";
import skidssuperman from "../../assets/images/skids-logo-1.png";
import height from "../../assets/images/height.svg";
import weightscale from "../../assets/images/weight-scale.svg";
import { Avatar } from "@mui/material";
import "./HealthReport.css"
import Hair from "../../assets/Bodypoints/Hair/Hair";
import Eye from "../../assets/Bodypoints/Eye/Eye";
import Ear from "../../assets/Bodypoints/Ear/Ear";
import Throat from "../../assets/Bodypoints/Throat/Throat";
import Dental from "../../assets/Bodypoints/Dental/Dental";
import Cough from "../../assets/Bodypoints/Cough/Cough";
import Lungs from "../../assets/Bodypoints/Lungs/Lungs";
import Heart from "../../assets/Bodypoints/Heart/Heart";
import Stomach from "../../assets/Bodypoints/Stomach/Stomach";
import Skin from "../../assets/Bodypoints/Skin/Skin";
import BmiScale from "../../assets/images/BmiScale/BmiScale";
import BoySvg from "../../assets/images/Kids/BoySvg";
import GirlSvg from "../../assets/images/Kids/GirlSvg";
import { calcBmi, getDMFTIndex, stringAvatar, textFormat } from "../../utils/utility";
import Spiderchart from "../SpiderChart/Spiderchart";
import moment from "moment";
import Background from '../../assets/images/Background.svg';

export const Page1 = ({ data, pages, dynamicClass }) => {

  const getCoughStatus = () => {
    const x = data?.lungsAssessment?.auscultationValidation?.filter((y) => y.issue === "Cough")
    if (x?.length > 0) {
      return true
    }
    else {
      return false
    }
  }

  console.log(data)

  const getBMI = () => {
    const w = data?.vitalSignsAssessment?.weight
    const h = data?.vitalSignsAssessment?.height / 100
    if (w && h && w !== "null" && h !== "null") {
      return (w / (h * h)).toFixed(1)
    }
    else {
      return ""
    }
    console.log(data)
  }

  return (
    <>
      <div id='healthReport1' className={`main ${dynamicClass}`}>
        <div className="parent_container">
          <div className="parent_container_inner">
            <div>
              <img crossOrigin="anonymous" src={skidssuperman} alt="skidssuperman" />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p>{data?.school?.schoolName}</p>
              <img crossOrigin="anonymous" src={data?.school?.url} alt="skidssuperman" style={{ borderRadius: 50, width: 40, height: 40 }} />
            </div>
          </div>
          <div className="report_header">
            Hey! This is my overall health report.
          </div>
          <div className="student_information_container">
            <div>
              <div>
                <Avatar {...stringAvatar(data?.student?.name)} />
              </div>
              <div>{data?.student?.name}</div>
            </div>
            <div>
              <p>Age</p>
              <p>{`${data?.student?.age} yrs`}</p>
            </div>
            <div>
              <p>Gender</p>
              <p>{data?.student?.gender ?? "Unavailable"}</p>
            </div>
            <div>
              <p>Class</p>
              <p>{data?.student?.class}</p>
            </div>
            <div>
              <p>Health Package</p>
              <p>{textFormat(data?.student?.package)}</p>
            </div>
          </div>
          <div className="grid_container">
            <div className="physical_screening_container">
              <div className="physical_header_text">Physical Screening</div>
              <div className="physical_screening_details_container">
                <div>
                  <p>Validation date & time</p>
                  <p>{moment(data?.physicalValidatedOn).format("DD-MM-YYYY") ?? "Unavailable"}</p>
                </div>
                <div>
                  <p>Validated by</p>
                  <p>{"Dr. Tanuja Mishra"}</p>
                </div>
              </div>

              <div className="physical_body_image_container">
                {
                  data?.student?.gender &&
                  <div
                    style={{ paddingRight: data?.student?.gender === "Male" ? '' : '9px' }}>
                    {
                      data?.student?.gender === "Male"
                        ?
                        <BoySvg size={133} />
                        :
                        <GirlSvg size={115} />
                    }
                  </div>
                }
                <Hair
                  disabled={data?.student?.healthPackage?.hair?.length === 0}
                  scale={0.7}
                  top={"-4px"}
                  right={"55px"}
                  issue={(data?.hairAssessment?.dandruff
                    || data?.hairAssessment?.pediculosis
                    || data?.hairAssessment?.lossOfHair)}
                />
                <Eye
                  disabled={data?.student?.healthPackage?.eye?.length === 0}
                  scale={0.7}
                  top={"22px"}
                  right={"64px"}
                  issue={data?.eyeAssessment?.validation?.length > 0}
                />
                <Eye
                  disabled={data?.student?.healthPackage?.eye?.length === 0}
                  scale={0.7}
                  top={"22px"}
                  right={"46px"}
                  issue={data?.eyeAssessment?.validation?.length > 0}
                />
                <Ear
                  disabled={data?.student?.healthPackage?.ear?.length === 0}
                  scale={0.7}
                  top={"28px"}
                  right={"78px"}
                  whichEar={"left"}
                  issue={data?.earAssessment?.leftEarValidation?.length > 0 || data?.hearingAssessment?.validation?.length > 0}
                />
                <Ear
                  disabled={data?.student?.healthPackage?.ear?.length === 0}
                  scale={0.7}
                  top={"28px"}
                  right={"32px"}
                  issue={data?.earAssessment?.rightEarValidation?.length > 0 || data?.hearingAssessment?.validation?.length > 0}
                />
                <Dental
                  disabled={data?.student?.healthPackage?.dental?.length === 0}
                  scale={0.7}
                  top={"42px"}
                  right={"55px"}
                  issue={(
                    getDMFTIndex(data?.dentalAssessment?.tooth) > 0
                    || data?.dentalAssessment?.decoloration
                    || data?.dentalAssessment?.gingivitis
                    || data?.dentalAssessment?.orthodontic)}
                />
                <Throat
                  disabled={data?.student?.healthPackage?.throat?.length === 0}
                  scale={0.7}
                  top={"62px"}
                  right={"55px"}
                  issue={data?.throatAssessment?.validation?.length > 0}
                />
                {/* <Cough
                  disabled={!data?.student?.healthPackage?.lungs?.includes("COUGH")}
                  scale={0.8}
                  top={"76px"}
                  right={"54px"}
                  issue={data?.coughAssessment?.validation?.length > 0}
                /> */}
                <Lungs
                  disabled={data?.student?.healthPackage?.lungs?.length === 0}
                  scale={0.7}
                  top={"92px"}
                  right={"55px"}
                  issue={data?.lungsAssessment?.auscultationValidation?.length > 0}

                />
                <Heart
                  disabled={data?.student?.healthPackage?.heart?.length === 0}
                  scale={0.7}
                  top={"110px"}
                  right={"39px"}
                  issue={data?.heartAssessment?.auscultationValidation?.length > 0}
                />
                <Stomach
                  disabled={data?.student?.healthPackage?.abdomen?.length === 0}
                  scale={0.7}
                  top={"145px"}
                  right={"55px"}
                  issue={data?.abdomenAssessment?.validation?.length > 0}
                />
                <Skin
                  disabled={data?.student?.healthPackage?.skin?.length === 0}
                  scale={0.7}
                  top={"160px"}
                  right={"105px"}
                  issue={data?.skinAssessment?.validation?.length > 0}
                />

              </div>

              <div className="physical_body_information_container_bottom">
                {data?.vitalSignsAssessment?.height != "null" && <div>
                  <img src={height} />
                  <p className="physical_body_information_container_bottom_para_normal">
                    {data?.vitalSignsAssessment?.height} cms
                  </p>
                  <p>Height</p>
                </div>}
                {(data?.vitalSignsAssessment?.height != "null" && data?.vitalSignsAssessment?.weight != "null") && <div>
                  <BmiScale size={35} BMI={calcBmi(data?.vitalSignsAssessment?.height, data?.vitalSignsAssessment?.weight)} />
                  <p className="physical_body_information_container_bottom_para">
                    {getBMI()} kg/m
                    <sup className="physical_body_information_container_bottom_sup">
                      2
                    </sup>
                  </p>
                  <p>BMI</p>
                </div>}
                {data?.vitalSignsAssessment?.weight != "null" && <div>
                  <img src={weightscale} />
                  <p className="physical_body_information_container_bottom_para_normal">
                    {data?.vitalSignsAssessment?.weight} kg
                  </p>
                  <p>Weight</p>
                </div>}
              </div>
            </div>
            {/* <!-- Behavioral --> */}
            <div className="behavioral_container">
              <div className="physical_header_text">Behavioral Assessment</div>

              <div className="physical_screening_details_container">
                {data?.student?.healthPackage?.behavioralScreening?.length > 0 ?
                  <>
                    <div>
                      <p>Validatio date & time</p>
                      <p>{moment(data?.behavioralValidatedOn).format("DD-MM-YYYY") ?? "Unavailable"}</p>
                    </div>
                    <div>
                      <p>Validated by</p>
                      <p>Ms. Pratikshya Kar</p>
                    </div>
                  </> :
                  <div>
                    <p>Assessments are not done</p>
                  </div>
                }
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spiderchart size={200} packageDetails={data?.student?.healthPackage} screen={"behavioral"} data={data} />
              </div>
            </div>
            {/* <!-- Learning --> */}
            <div className="learning_container">
              <div className="physical_header_text">
                Learning Disability Assessment
              </div>

              <div className="physical_screening_details_container">
                {data?.student?.healthPackage?.learningScreening?.length > 0 ?
                  <>
                    <div>
                      <p>Validation date & time</p>
                      <p>{moment(data?.behavioralValidatedOn).format("DD-MM-YYYY") ?? "Unavailable"}</p>
                    </div>
                    <div>
                      <p>Validated by</p>
                      <p>Ms. Pratikshya Kar</p>
                    </div>
                  </> :
                  <div>
                    <p>Assessments are not done</p>
                  </div>
                }
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spiderchart size={200} packageDetails={data?.student?.healthPackage} screen={"learning"} data={data} />
              </div>
            </div>
          </div>
          <div className="footer_container">
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 1 </p>
          </div>
        </div>
      </div>
    </>
  );
};
