import schools from "./schools";
import students from "./students";
import clinics from "./clinic";
import user from "./users";
import screening from "./screening";
import dashboard from "./dashboard";
import validation from "./validation";
import events from "./events";
import typeform from "./typeform";
import analytics from "./analytics";
import support from "./support";
import blog from "./blog";

const ApiServices = {
    schools,
    students,
    clinics,
    user,
    screening,
    dashboard,
    events,
    validation,
    typeform,
    analytics, 
    support,
    blog
}

export default ApiServices;