import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "../../common/DashboardLayout/DashboardLayout";
import classes from "./Teacher.module.css";
import rectangle from "../../../assets/images/rectangle.png";
import SearchIcon from "@mui/icons-material/Search";
import { Avatar, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import TeacherRow from "./TeacherRow";
import BreadCrumbs from "../TableComponents/BreadCrumbs";
import TabButton from "../TableComponents/TabButton";
import SearchBar from "../TableComponents/SearchBar";
import typeformUpload from '../../../assets/images/typeformUpload.svg'
import upload from '../../../assets/images/upload.svg'
import downloadfile from '../../../assets/images/downloadfile.svg'
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import BulkUpload from "../../BulkUpload/BulkUpload";
import { useSelector } from "react-redux";
import BlankDataScreen from "../../BlankDataScreen/BlankDataScreen";



function TeacherPage({ data, loading }) {

  const navigate = useNavigate()
  const location = useLocation();
  function getWordBeforeDash() {
    let newStr = "";
    for (let i = 1; i < location.pathname.length; i++) {
      if (location.pathname[i] === "-") {
        return newStr;
      }
      newStr += location.pathname[i];
    }
  }
  const [open, setOpen] = useState(false);
  const [sec, setSec] = useState(false);
  const [text, setText] = useState("");
  const [displayData, setDisplayData] = useState(data ?? []);
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageNo, setPageNo] = useState(1)
  const [selectedClass, setSelectedClass] = useState("All")
  const [uploadBulkOpen, setUploadBulkOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const userRole = useSelector((state) => state.userReducer?.currentUser?.groups)

  const handleCloseModal = () => {
    setOpenModal(false)
  }
  useEffect(() => {
    if (text && selectedClass !== "All") {
      setDisplayData(data?.filter((x) => `${x?.given_name} ${x?.family_name}`.toLowerCase().includes(text.toLowerCase())))
      setPageNo(1)
    }
    else if (text) {
      setDisplayData(data?.filter((x) => `${x?.given_name} ${x?.family_name}`.toLowerCase().includes(text.toLowerCase())))
      setPageNo(1)
    }
    else if (selectedClass !== "All") {
      setDisplayData(data?.filter((x) => x?.class === selectedClass))
      setPageNo(1)
    }
    else {
      setDisplayData(data)
    }
  }, [text, selectedClass])
  useEffect(() => {
    setDisplayData(data)
  }, [data])

  const dropdown_obj = [
    { label: "1st A" },
    { label: "1st B" },
    { label: "1st C" },
  ];
  const sectionDrop_obj = [
    { label: " A Section" },
    { label: "B Section" },
    { label: " C Section" },
  ];
  const numberDown_obj = [{ label: "20" }, { label: "30" }, { label: "40" }];

  const handleOpen = () => {

    setOpen(!open);
  };
  const handleSection = () => {

    setSec(!sec);
  };
  const handleText = () => {

    setText(!text);
  };
  return (
    <div className={classes.main_div}>
      <div className={classes.student_layout}>
        <div>
          <BreadCrumbs role="Teacher" />
        </div>
        <div>
          {/* {getWordBeforeDash() === 'admin' ? <button>
          <img src = {bulkupload} alt = "bulkupload"/>
          <span>Upload bulk</span>
        </button> : <></>}
        <button onClick={()=>{navigate('/superadmin-users/individual/user', {state:{type:"teacher"}})}}>
          <img src = {addCircleFill} alt = "addCircleFill"/>
          <span>Add Teacher</span>
        </button> */}
          {(userRole?.includes('school-admin') || userRole?.includes('skids-sa')) && <button className={classes.sharetypeform_btn} onClick={() => setOpenModal(true)}>
            <img src={typeformUpload} />
            <span>Upload Bulk</span>
          </button>}
          <TabButton role="Teacher" />
        </div>
      </div>
      <BulkUpload openModal={openModal} setOpenModal={setOpenModal} handleCloseModal={handleCloseModal} type={"teacher"} />
      {
        data?.length > 0 ?
          (
            <>

              <div className={classes.student_filters}>
                <div className={classes.show_text}>Show</div>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rowsPerPage}
                    label="Rows"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                    size='small'
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ width: 90 }}>
                  <InputLabel id="demo-simple-select-label">Class</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedClass}
                    label="Rows"
                    onChange={(e) => setSelectedClass(e.target.value)}
                    size='small'
                  >
                    {["All", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"].map((x) => <MenuItem key={x} value={x}>{x}</MenuItem>)}
                  </Select>
                </FormControl>
                <SearchBar onchange={setText} value={text} />
              </div>
              {/* Student Table Header */}
              <div className={classes.student_table_header}>
                <div>TEACHER NAME</div>
                <div>GENDER</div>
                <div>CLASS</div>
                <div>EMAIL ID</div>
                <div>STUDENTS AFFILIATED</div>
                <div>ROLE</div>
                <div>STATUS</div>
                <div style={{ justifySelf: "center" }}>ACTION</div>
              </div>
              {/* Student Column-Rows Section */}
              <div className={classes.student_column_container}>
                {Array.isArray(displayData) && displayData?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo)?.map((el, index) => {
                  return (
                    <TeacherRow data={el} key={index + Date.now()} />
                  )
                })}
              </div>
              <div className={classes.pagination_container}>
                <div className={classes.page_item}>{`Showing ${(rowsPerPage * (pageNo - 1)) + 1} to ${pageNo * rowsPerPage <= displayData?.length ? pageNo * rowsPerPage : displayData?.length} of ${displayData?.length} items`}</div>
                <div className={classes.pagination}>
                  <Button size="small" disabled={pageNo === 1} onClick={() => setPageNo(pageNo - 1)} >
                    <ChevronLeft />
                  </Button>
                  <span>{pageNo}</span>
                  <div>
                    <span>of</span>
                    <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
                  </div>

                  <div>
                    <Button size="small" disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)} onClick={() => setPageNo(pageNo + 1)} >
                      <ChevronRight />
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )
          :
          loading ? "" :
            (
              <BlankDataScreen title="Oops! No data available" description="There is no data uploaded in this table to be shown. Click on Add Teacher to upload data." buttonText="Add Teacher" role="Teacher" />
            )
      }

    </div>
  );
}

function Teacher({ data, loading }) {
  return (

    <TeacherPage data={data} loading={loading} />

  );
}
export default Teacher;