import React from 'react'
import AddIndividual from '../common/AddIndividual/AddIndividual'
import DashboardLayout from '../common/DashboardLayout/DashboardLayout'

const AddEditIndividual = () => {
    return (
            <AddIndividual/>
    )
}

export default AddEditIndividual