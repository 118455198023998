import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import userReducer from "../../../redux/reducers/user.reducer";
import Teacher from '../../common/TeacherTable/Teacher'

export default function AdminTeacher(){

    let schoolId = useSelector((state)=>state.userReducer.currentUser.schoolId)
    const data = useSelector((state)=>state.userReducer.users)
    
    const dispatch = useDispatch()
    const location = useLocation()
    // if(!location.pathname.includes("principal")){
    //   
    //   schoolId = location.state?.schoolId
    // }
    const getUserByGroup = userReducer.actions.getUserByGroupRequest;
    useEffect(() => {
      dispatch(getUserByGroup({role:"school-teacher", schoolId: schoolId}))
    }, [])
    
    return (
        <>
            <Teacher data={data}/>
        </>
    )
}