import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import classes from './HealthHistory.module.css'
import SearchBar from '../../../common/TableComponents/SearchBar'
import { Add, AddRounded, ArrowRight, Visibility } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter, getAge, getRisk, stringAvatar, stringToColor } from '../../../../utils/utility'
import moment from 'moment/moment'
import BreadCrumbs from '../../../common/TableComponents/BreadCrumbs'
import behavioral from '../../../../graphqlApiServices/behaviroal'

function HealthHistory() {
    const navigate = useNavigate()
    const location = useLocation()
    let data = location.state.data
    const [packageDetail, setPackageDetail] = useState()
    const [displayData, setDisplayData] = useState()
    const { getBhvAssessment, getParent } = behavioral;
    const [text, setText] = useState()
    const [bhvStudentData, setBhvStudentData] = useState()

    const [packageValue, setPackageValue] = useState()

    useEffect(() => {
        getBhvAssessment(bhvAssessment)
        getParent(data?.parentId, callBack)
    }, [])

    function callBack(type, res) {
        if (type == "success") {
            console.log(res);
        }
        else {
            // console.log(res);
        }
    }

    useEffect(() => {
        getPackageAssessments()
        // getParent(bhvStudentData[0]?.data?.kidId)
    }, [bhvStudentData])
    console.log(data?.parentId);
    function bhvAssessment(type, res) {
        if (type == "success") {
            setBhvStudentData(res)
        }
        else {// console.log(res);
        }
    }

    function getPackageAssessments() {
        let arr = []
        bhvStudentData?.map((el, index) => {
            if (data?.kidId == el?.kidId && JSON.parse(el[el?.package[0]])?.score) {
                if (el?.package[0] === "DYSCALCULIA_1" || el?.package[0] === "DYSGRAPHIA_1" || el?.package[0] === "DYSLEXIA_1") {
                    arr.push({ packageName: el?.package[0], data: el, consultation: JSON.parse(el?.consultation), packageDetail: JSON.parse(el[el?.package[0]]), prescription: JSON.parse(el[el?.package[0]])?.prescription })
                    arr.push({ packageName: el?.package[1], data: el, consultation: JSON.parse(el?.consultation), packageDetail: JSON.parse(el[el?.package[1]]), prescription: JSON.parse(el[el?.package[1]])?.prescription })
                }
                else {
                    arr.push({ packageName: el?.package[0], data: el, consultation: JSON.parse(el?.consultation), packageDetail: JSON.parse(el[el?.package[0]]), prescription: JSON.parse(el[el?.package[0]])?.prescription })
                }
            }
        })
        setPackageDetail(arr)
        setDisplayData(arr)
        return arr
    }
    useEffect(() => {
        if (text) { setDisplayData(packageDetail?.filter((x, index) => x?.packageName.toLowerCase().includes(text.toLowerCase()))) }
        else { setDisplayData(packageDetail) }
    }, [text])

    useEffect(() => {
        setPackageValue(displayData?.[0]?.packageName)
    }, [displayData])

    function convertToTitleCase(str) {
        return str.toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }
    console.log(packageDetail);
    // get report 
    const getReport = () => {
        let x = displayData?.filter(x => packageValue == x?.packageName)
        switch (convertToTitleCase(x[0].packageName)) {
            case "Adhd": return '/d2c-report/ADHD'
            case "Autism": return '/d2c-report/AUTISM'
            case "Internet Addiction": return '/d2c-report/DIGITAL_DEPENDENCY'
            case "Anxiety": return '/d2c-report/ANXIETY'
            case "Conduct Disorder": return "/d2c-report/CONDUCT_DISORDER"
            case "Oppositional Defiant Disorder": return "/d2c-report/OPPOSITIONAL_DEFIANT_DISORDER"
            case "Depression": return "/d2c-report/DEPRESSION"
            case "Developmental Delay": return "/d2c-report/DEVELOPMENTAL_DELAY"
            case "Dyslexia 1": return "/d2c-report/DYSLEXIA"
            case "Dyslexia 2": return "/d2c-report/DYSLEXIA"
            case "Dyscalculia 1": return "/d2c-report/DYSCALCULIA"
            case "Dyscalculia 2": return "/d2c-report/DYSCALCULIA"
            case "Dysgraphia 1": return "/d2c-report/DYSGRAPHIA"
            case "Dysgraphia 2": return "/d2c-report/DYSGRAPHIA"
        }
    }

    const setLocalStorage = () => {
        let xd = displayData?.filter(x => packageValue == x?.packageName)
        const x = { ...xd[0]?.data }
        x['student'] = xd[0]?.data
        localStorage.setItem("assessment", JSON.stringify(x))
        localStorage.setItem('sId', x?.screeningId)
        localStorage.setItem('name', x?.name)
    }

    // useEffect(() => {
    //     getPackageAssessments();
    // }, [])
    // console.log(packageValue);
    return (
        <div>
            <section className={classes.TopNav}>
                <div>
                    <BreadCrumbs />
                </div>
                {/* <div className={classes.buttonNav}>
                    
                    <button
                        className={classes.button_filled}
                    >
                        <Visibility />
                        <span>Screening</span>
                    </button>
                </div> */}

            </section>

            {/* profile hEader  */}
            <section className={classes.ProfileHeader}>
                <div>
                    <Avatar {...stringAvatar(capitalizeFirstLetter(data?.name))} sx={{
                        width: "64px",
                        height: "64px",
                        backgroundColor: stringToColor(capitalizeFirstLetter(data?.name)),
                        fontSize: '40px'
                    }} />
                    <h1>{capitalizeFirstLetter(data?.name)}</h1>
                </div>
                <section>
                    <div>
                        <h1>Age</h1>
                        <p>{getAge(data?.DOB)} yrs</p>
                    </div>
                    <div>
                        <h1>Gender</h1>
                        <p>{data?.gender}</p>
                    </div>
                    <div>
                        <h1>Parent Name</h1>
                        <p>{data?.parentName}</p>
                    </div>
                    <div>
                        <h1>Parent Phone</h1>
                        <p></p>
                    </div>
                </section>
            </section>

            <div className={classes.student_filters}>
                <div className={classes.show_text}>Show</div>
                {/* <FormControl>
                    <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value="10"
                        label="Rows"
                        // onChange={(e) => setRowsPerPage(e.target.value)}
                        size="small"
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                    </Select>
                </FormControl> */}
                <SearchBar id={"searchCy"} onchange={setText} value={text} />
                {/* <Filter
                    label1={"Active"}
                    label2={"Inactive"}
                filter1={activeFilter}
                filter2={inactiveFilter}
                setFilter1={setActiveFilter}
                setFilter2={setInActiveFilter}
                /> */}
            </div>

            <section className={classes.ProfileBottom}>
                <div>
                    {displayData?.map((item, index) => (
                        <div className={`${classes.riskCard} ${packageValue == item?.packageName && classes.active}`} onClick={() => setPackageValue(item?.packageName)}>
                            <div>
                                <h1>{moment.utc(item?.packageDetail?.responseAt).format("DD-MM-YYYY")}</h1>
                                <p>{moment.utc(item?.packageDetail?.responseAt).format("hh:mm A")}</p>
                            </div>
                            <div>
                                <h1>{convertToTitleCase(item?.packageName)}</h1>
                                {item?.consultation ? <span style={{ color: '#3E36B0' }}>Upcoming</span> : getRisk(item?.packageName, item?.packageDetail?.riskFactor)}
                            </div>
                            <div>
                                <ArrowRight color={`#6F7E90`} />
                            </div>
                        </div>
                    ))}
                </div>
                <div className={classes.detailRiskCard}>
                    {
                        displayData?.length !== 0 ?
                            displayData?.map(el => {
                                return el?.packageName == packageValue &&
                                    <>
                                        <div className={classes.nextConsultation}>
                                            <p>Consultation date & time</p>
                                            <div>{moment.utc(el?.packageDetail?.responseAt).format("D MMMM YYYY, hh:mm A")}</div>
                                        </div>

                                        {/* assesment  */}
                                        <div className={classes.assessment}>
                                            <div>
                                                <div>Assessment</div>
                                                <div>Score</div>
                                                <div>Risk</div>
                                            </div>
                                            <div>
                                                <div>{convertToTitleCase(packageValue)}</div>
                                                <div>{el?.packageDetail?.score}</div>
                                                {getRisk(el?.packageName, el?.packageDetail?.riskFactor)}
                                            </div>
                                            <a href={getReport()} target='_blank' onClick={setLocalStorage}>View assessment results</a>
                                        </div>

                                        {/* {!el?.consultation
                                            && */}
                                        <div className={classes.lastConsultation}>
                                            <div>
                                                <p>Observation</p>
                                                <div>
                                                    {el?.packageDetail?.outcome}
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <p>Prescription</p>
                                                    <button
                                                        className={classes.button_filled}
                                                        style={{ background: "none", color: "#1740A1", fontSize: 16, padding: 0, width: "fit-content" }}
                                                        onClick={() => navigate("/behavioral-doctor/dashboard/patients/log", { state: el })}
                                                    >
                                                        {/* <AddRounded /> */}
                                                        <span style={{ color: "#1740A1" }}>Add Log</span>
                                                    </button>
                                                </div>
                                                <div>
                                                    {el.prescription ? <a style={{ textDecoration: "underline", color: "#3E36B0" }} target='_blank' href={el.prescription}>View Prescription</a> : el?.packageDetail?.interpretation}
                                                </div>
                                            </div>
                                            {/* attachment  */}
                                            {/* <div className={classes.attachment}>
                                                    <h1>Attachment</h1>
                                                </div> */}
                                        </div>
                                        {/* } */}

                                    </>
                            })
                            :
                            <div>No History Available!</div>

                    }
                </div>
            </section >

        </div >
    )
}

export default HealthHistory