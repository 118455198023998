import { createSlice } from "@reduxjs/toolkit";
import InitialStore from "../store/InitialStore";


export const getScreenDashboardReq = (state, action) => {
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const getScreenDashboardSuccesss = (state, action) => {
    state.error = false;
    state.data = action.payload
    state.loading = false;
}
export const getScreenDashboardfail = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}


export const getOpsTeamScreenReq = (state, action) => {
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const getOpsTeamScreenSuccess = (state, action) => {

    state.error = false;
    state.testData = action.payload;
    state.loading = false;
}
export const getOpsTeamScreenfail = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}

export const deleteOpsCache = (state, action) => {
    state.testData = {
        dentalAssessment: {
            decoloration: false,
            gingivitis: false,
            orthodontic: false,
            tooth: [
                "Q11_N,Q12_N,Q13_N,Q14_N,Q15_N,Q16_N,Q17_N,Q18_N,Q1A_N,Q1B_N,Q1C_N,Q1D_N,Q1E_N,Q21_N,Q22_N,Q23_N,Q24_N,Q25_N,Q26_N,Q27_N,Q28_N,Q2A_N,Q2B_N,Q2C_N,Q2D_N,Q2E_N,Q31_N,Q32_N,Q33_N,Q34_N,Q35_N,Q36_N,Q37_N,Q38_N,Q3A_N,Q3B_N,Q3C_N,Q3D_N,Q3E_N,Q41_N,Q42_N,Q43_N,Q44_N,Q45_N,Q46_N,Q47_N,Q48_N,Q4A_N,Q4B_N,Q4C_N,Q4D_N,Q4E_N"
            ]
        },
        hairAssessment: {
            color: null,
            dandruff: false,
            lossOfHair: false,
            pediculosis: false
        },
        vitalSignsAssessment: {
            weight: null,
            temperature: null,
            respiratoryRate: null,
            bloodPressure: null,
            height: null,
            BMI: null,
            SpO2: null,
            heartRate: null,
            hemoglobin: null
        },
        screeningStatus: {

            abdomin: {
                auscultation: false
            },
            cough: {
                exam: false
            },
            dental: {
                decoloration: false,
                gingivitis: false,
                orthodontic: false,
                tooth: false
            },
            ear: {
                leftEarVideo: false,
                rightEarVideo: false
            },
            eye: {
                normalVision: false
            },
            hair: {
                color: false,
                dandruff: false,
                lossOfHair: false,
                pediculosis: false
            },
            hearing: {
                hearingAssessment: false,
                resultAwait: false
            },
            heart: {
                estimation: false
            },
            lungs: {
                auscultation: false
            },
            skin: {
                video: false
            },
            throat: {
                video: false
            },
            vitalSigns: {
                SpO2: false,
                bloodPressure: false,
                heartRate: false,
                height: false,
                hemoglobin: false,
                temperature: false,
                weight: false
            }
        }
    };
}



export const getScreeningByIdRequest = (state, action) => {
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const getScreeningByIdSuccess = (state, action) => {
    state.error = false;
    state.data = action.payload.data
    state.bhvData = JSON.parse(action.payload.data?.bhvScreening);
    state.loading = false;
}
export const getScreeningByIdFail = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}


export const getBhvScreeningByIdRequest = (state, action) => {
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const getBhvScreeningByIdSuccess = (state, action) => {
    state.error = false;
    state.bhvData = action.payload.data
    state.loading = false;
}
export const getBhvScreeningByIdFail = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}


export const updateScreeningByIdRequest = (state, action) => {
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const updateScreeningByIdSuccess = (state, action) => {
    state.error = false;

    state.loading = false;
}
export const updateScreeningByIdFail = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}


export const getOpsManagerStudentScreeningRequest = (state, action) => {
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
    state.opsManagerStudents = []
}
export const getOpsManagerStudentScreeningSuccess = (state, action) => {

    state.error = false;
    state.opsManagerStudents = action.payload.data
    state.loading = false;
}
export const getOpsManagerStudentScreeningFail = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error?.message
    state.loading = false;
}


export const updateScreeningSubRequest = (state, action) => {
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const updateScreeningSubSuccess = (state, action) => {
    state.error = false;
    state.loading = false;
}
export const updateScreeningSubFail = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}


export const getScreeningByStudentIdRequest = (state, action) => {
    state.data = []
    state.loading = true
    state.error = ''
}


export const getReportRequest = (state, action) => {
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const getReportSuccess = (state, action) => {
    state.error = false;
    state.loading = false;
    state.reportData = action.payload
}
export const getReportFailure = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}

export const getBulkReportRequest = (state, action) => {
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const getBulkReportSuccess = (state, action) => {
    state.error = false;
    state.loading = false;
    console.log([...state.bulkReportData, action.payload])
    state.bulkReportData = [...state.bulkReportData, action.payload]
}
export const getBulkReportFailure = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}

export const resetBulkReportData = (state, action) => {
    state.bulkReportData = []
}

export const uploadReportRequest = (state, action) => {
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const uploadReportSuccess = (state, action) => {
    state.error = false;
    state.loading = false;
    state.reportData = action.payload
}
export const uploadReportFailure = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}

export const postMasimoPhotoReq = (state, action) => {

}
export const toggleDeviceReq = (state, action) => {

}
export const higoInteReq = (state, action) => {
}



export const getOperatorReq = (state, action) => {
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const getOperatorSuccess = (state, action) => {
    state.error = false;
    state.loading = false;
    state.operators = action.payload
}
export const getOperatorFailure = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}



export const getWelchallynReq = (state, action) => {
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const getWelchallynReqSuccess = (state, action) => {

    state.error = false;
    state.loading = false;
    state.welchallynLink = action.payload
}
export const getWelchallynReqFailure = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}






export default createSlice({
    name: "screeningReducer",
    initialState: InitialStore.screening,
    reducers: {
        getScreenDashboardReq,
        getScreenDashboardSuccesss,
        getScreenDashboardfail,

        getOpsTeamScreenReq,
        getOpsTeamScreenSuccess,
        getOpsTeamScreenfail,

        deleteOpsCache,

        getScreeningByIdRequest,
        getScreeningByIdSuccess,
        getScreeningByIdFail,

        getBhvScreeningByIdRequest,
        getBhvScreeningByIdSuccess,
        getBhvScreeningByIdFail,

        getOpsManagerStudentScreeningRequest,
        getOpsManagerStudentScreeningSuccess,
        getOpsManagerStudentScreeningFail,

        updateScreeningByIdRequest,
        updateScreeningByIdSuccess,
        updateScreeningByIdFail,

        updateScreeningSubRequest,
        updateScreeningSubSuccess,
        updateScreeningSubFail,

        getScreeningByStudentIdRequest,

        postMasimoPhotoReq,

        toggleDeviceReq,

        higoInteReq,


        getReportRequest,
        getReportSuccess,
        getReportFailure,


        getOperatorReq,
        getOperatorSuccess,
        getOperatorFailure,

        getWelchallynReq,
        getWelchallynReqSuccess,
        getWelchallynReqFailure,

        getBulkReportRequest,
        getBulkReportSuccess,
        getBulkReportFailure,
        resetBulkReportData,

        uploadReportRequest,
        uploadReportSuccess,
        uploadReportFailure,

    }
})