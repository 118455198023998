import React from "react";
import Layout from "../common/Layout/Layout";
import classes from "./PasswordUpdateSucess.module.css";
import { Link } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
function PasswordUpdateSucessPage() {
  return (
    <div className={classes.success_page}>
      <CheckCircleIcon sx = {{ fill : "#5770E6", width : "120px", height : "120px"}}/>
      <div className={classes.success_page_head} >Password Updated</div>
      <div className={classes.success_page_subhead}>
        Password has been changed successfully. Use new password to login.
      </div>
      <Link to="/login">
          <button
            className={classes.submit_button}
            style={{ opacity: 1 }}
            onClick={() => {}}
          >
            Go back to Login
          </button>
        </Link>
    </div>
  );
}
function PasswordUpdateSucess() {
  return (
    <>
      <Layout component={PasswordUpdateSucessPage()} head={""} subhead={""} />
    </>
  );
}

export default PasswordUpdateSucess;
