import { call, put, takeLatest } from "redux-saga/effects";
import ApiServices from "../../graphqlApiServices";
import analyticsReducers from "../reducers/analytics.reducers";

const {analytics} = ApiServices

const {getAnalytics} = analytics

export function* watchAnalyticsRequests() {
    yield takeLatest(analyticsReducers.actions.getAnalyticsRequest, getAnalyticsSaga);
}

export function* getAnalyticsSaga(payload) {
    try {
        const response = yield call(getAnalytics, payload);
        const data = JSON.parse(response.data.getSchoolAnalysis)
        console.log(data)
        yield put(analyticsReducers.actions.getAnalyticsSuccess({ data }))
    } catch (error) {
        yield put(analyticsReducers.actions.getAnalyticsFail({ error }))
    }
}