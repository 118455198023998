import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "../../common/DashboardLayout/DashboardLayout";
import classes from "./ClinicTable.module.css";
import rectangle from "../../../assets/images/rectangle.png";
import SearchIcon from "@mui/icons-material/Search";
import ClinicRow from "./ClinicRow";
import left from "../../../assets/images/left.svg";
import leftArrow from "../../../assets/images/down.svg"
import active from "../../../assets/images/active.svg";
import rightArrow from "../../../assets/images/sort.svg";
import right from "../../../assets/images/right.svg";
import triangle from "../../../assets/images/triangle.svg";
import searchIcon from "../../../assets/images/search-icon.svg";
import addCircleFill from '../../../assets/images/add-circle-fill.svg'
import bulkupload from '../../../assets/images/upload-bulk.svg'
import BreadCrumbs from '../TableComponents/BreadCrumbs'
import TabButton from '../TableComponents/TabButton'
import ShowPagesFilter from '../TableComponents/ShowPagesFilter'
import ClassSectionFilter from '../TableComponents/ClassSectionFilter'
import SearchBar from '../TableComponents/SearchBar'
import { useDispatch, useSelector } from "react-redux";
import clinincReducer from "../../../redux/reducers/clininc.reducer";
import { City } from "country-state-city";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CustomAutoComplete from "../CustomAutoComplete/CustomAutoComplete";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Filter } from '../../common/TableComponents/Filter'
import BlankDataScreen from "../../BlankDataScreen/BlankDataScreen";

function ClinicPage() {

  const data = useSelector((state) => state.clinicReducer.data)
  const loading = useSelector((state) => state.clinicReducer.loading)

  const getClinics = clinincReducer.actions.getClinicRequest;

  const location = useLocation();
  const dispatch = useDispatch();

  const breadCrumbs = [
    "Registered Clinics"
  ]

  useEffect(() => {
    dispatch(getClinics())
    location.state = { breadCrumbs: breadCrumbs }
  }, [])

  const allCities = [...new Set(City.getCitiesOfCountry("IN").map((x) => x.name))]


  const navigate = useNavigate()
  function getWordBeforeDash() {
    let newStr = "";
    for (let i = 1; i < location.pathname.length; i++) {
      if (location.pathname[i] === "-") {
        return newStr;
      }
      newStr += location.pathname[i];
    }
  }
  const [open, setOpen] = useState(false);
  const [sec, setSec] = useState(false);
  const [text, setText] = useState("");
  const [displayData, setDisplayData] = useState(data ?? []);
  const [city, setCity] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageNo, setPageNo] = useState(1)
  const [activeFilter, setActiveFilter] = useState(false);
  const [inactiveFilter, setInActiveFilter] = useState(false);

  useEffect(() => {
    if (city && text) {
      setDisplayData(data.filter((x) => x.city === city && x.name.toLowerCase().includes(text.toLowerCase())))
      setPageNo(1)
    }
    else if (city) {
      setDisplayData(data.filter((x) => x?.city === city))
      setPageNo(1)
    }
    else if (text) {
      setDisplayData(data.filter((x) => x?.name?.toLowerCase().includes(text.toLowerCase())))
      setPageNo(1)
    }
    else {
      setDisplayData(data)
    }
  }, [city, text])

  useEffect(() => {
    setDisplayData(data)
  }, [data])

  useEffect(() => {

    if (activeFilter && inactiveFilter) {
      setDisplayData(data);
      setPageNo(1)
    } else if (activeFilter) {
      setDisplayData(data?.filter((el) => el?.isActive));
      setPageNo(1)
    } else if (inactiveFilter) {
      setDisplayData(data?.filter((el) => el?.isActive === false || el?.isActive === null));
      setPageNo(1)
    } else {
      setDisplayData(data);
      setPageNo(1)
    }
  }, [activeFilter, inactiveFilter]);

  const dropdown_obj = [
    { label: "1st A" },
    { label: "1st B" },
    { label: "1st C" },
  ];
  const sectionDrop_obj = [
    { label: " A Section" },
    { label: "B Section" },
    { label: " C Section" },
  ];
  const numberDown_obj = [{ label: "20" }, { label: "30" }, { label: "40" }];

  const handleOpen = () => {
    setOpen(!open);
  };
  const handleSection = () => {
    setSec(!sec);
  };
  const handleText = () => {
    setText(!text);
  };
  // 
  const handleAddClinic = () => {
    navigate("/superadmin/clinic")
  }
  
  return (
    <div className={classes.main_div}>
      <div className={classes.student_layout}>
        <div>
          <BreadCrumbs role="Registered Clinic" />
        </div>
        <div>
          <TabButton role="Clinic" />
        </div>
      </div>
      {Array.isArray(data) && data.length > 0 ?
        <>
          <div className={classes.student_filters}>
            <div className={classes.show_text}>Show</div>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Rows</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                label="Rows"
                onChange={(e) => setRowsPerPage(e.target.value)}
                size='small'
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
            <CustomAutoComplete options={allCities} value={city} placeholder={"City"} onChange={setCity} />
            <SearchBar id={"clinicSearchCy"} onchange={setText} value={text} />
            <Filter
              label1={"Active"}
              label2={"Inactive"}
              filter1={activeFilter}
              filter2={inactiveFilter}
              setFilter1={setActiveFilter}
              setFilter2={setInActiveFilter}
            />
          </div>
          {/* Student Table Header */}
          <div className={classes.student_table_header}>
            <div>CLINIC NAME</div>
            <div>PINCODE</div>
            <div>EMAIL</div>
            <div>PHONE NUMBER</div>
            <div>DOCTORS</div>
            <div>NURSE</div>
            <div>STAFF</div>
            <div>STATUS</div>
            <div style={{ justifySelf: "center" }}>ACTION</div>
          </div>
          {/* Student Column-Rows Section */}
          <div id="clinicRowCy" className={classes.student_column_container}>
            {displayData?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo)?.map((el, index) => {
              return (
                <ClinicRow data={el} key={index + Date.now()} />
              )
            })}
          </div>
          <div className={classes.pagination_container}>
            <div className={classes.page_item}>{`Showing ${(rowsPerPage * (pageNo - 1)) + 1} to ${pageNo * rowsPerPage <= displayData?.length ? pageNo * rowsPerPage : displayData?.length} of ${displayData.length} items`}</div>
            <div className={classes.pagination}>
              <Button size="small" disabled={pageNo === 1} onClick={() => setPageNo(pageNo - 1)} >
                <ChevronLeft />
              </Button>
              <span>{pageNo}</span>
              <div>
                <span>of</span>
                <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
              </div>

              <div>
                <Button size="small" disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)} onClick={() => setPageNo(pageNo + 1)} >
                  <ChevronRight />
                </Button>
              </div>
            </div>
          </div>
        </>
        :
        loading ?
          ""
          :
          <BlankDataScreen title="Oops! No data available" description="There is no data uploaded in this table to be shown. Click on Add Clinic to upload data." buttonText="Add Clinic" role="Clinic" />

      }

    </div>
  );
}

function School() {
  return (

    <ClinicPage />

  );
}
export default School;