import React, { useState } from 'react';
import classes from './Assign.module.css';

function DoctorCard({data, countData, handleAssign}) {

    const [students, setStudents] = useState("");
    const [dueDate, setDueDate] = useState("")


    return (
        <div className={classes.screening_card_large}>
            <p>{`Dr. ${data?.given_name+" " +data?.family_name}`}</p>
            <div className={classes.large_card_data_box}>
                <div>
                    <p>Total assigned</p>
                    <p>{countData?.[0]?.count ?? "0"}</p>
                </div>
                <div>
                    <p>Validation Progress</p>
                    <p>{`${countData?.[0]?.validated ?? "0"}/${countData?.[0]?.count ?? "0"}`}</p>
                </div>
            </div>
            <div className={classes.input_assign_container}>
                <div>
                    <p>Assign Students</p>
                    <input placeholder='Enter Number' value={students} onChange={(e)=>setStudents(e.target.value)}/>
                </div>
                <div>
                    <p>Set due date</p>
                    <input className={classes.date_input} type='date' value={dueDate} onChange={(e)=>setDueDate(e.target.value)} />
                </div>
            </div>
            <div className={classes.decision_buttons}>
                <div>
                    <button>Cancel</button>
                </div>
                <button onClick={()=>handleAssign(data?.userId, dueDate, students)}>Assign</button>
            </div>
        </div>
    )
}

export default DoctorCard