import React, { useState } from "react";
// import classes from "./OpsManagerDashboard.module.css";
import schoolLogo from "../../../../assets/images/schoolLogo.svg";
import studentgrey from "../../../../assets/images/studentgrey.svg";
import teachergrey from "../../../../assets/images/teachergrey.svg";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { InfoCard } from "../../../DashboardSuperAdmin/InfoCard";
import { Box, Modal } from "@mui/material";
import QrScanner from "react-qr-scanner";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useNavigate } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { styled } from "@mui/material/styles";
import classes from '../NewOpsTeamDashboard.module.css'
import { useEffect } from "react";
import studentReducer from "../../../../redux/reducers/student.reducer";
import screeningReducer from "../../../../redux/reducers/screening.reducer";
import { useDispatch } from "react-redux";

export const OpsManagerDashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [facing, setFacing] = useState("environment");
  const deleteOpsCache = screeningReducer.actions.deleteOpsCache;
  const deleteStudentCache = studentReducer.actions.deleteStudentCache;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(deleteOpsCache());
    dispatch(deleteStudentCache());
  }, [])


  const info_card = [
    {
      logo: studentgrey,
      label: "Date",
      labelContent: "11th Sep, 2023",
    },

    {
      logo: schoolLogo,
      label: "School",
      labelContent: "Santhiniketan School",
    },

    {
      logo: studentgrey,
      label: "Students",
      labelContent: "200",
    },
    {
      logo: teachergrey,
      label: "Classes",
      labelContent: "1-4 (All Section)",
    },
  ];

  const changeFacing = () => {
    if (facing === "environment") {
      setFacing("user")
    } else {
      setFacing("environment");
    }
  }

  const handleQrOpener = () => {
    setIsOpen(!isOpen);
  };

  const handleError = (error) => {

  };

  const handleScan = (data) => {
    if (data?.text) {
      let cred = data?.text?.split("/");
      let screeningId = cred[cred.length - 1];
      navigate(`/ops-manager/${screeningId}`);
    }
  };
  const handleClose = () => setIsOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "transparent",
    p: 0,
  };
  const previewStyle = {
    height: 520,
    width: 640,
    objectFit: "cover",
  };

  return (
    <>
      {/* <BreadCrumbs /> */}
      <div className={classes.cards_status_container}>
        <>
          <div className={classes.container}>
            <div>
              <div>
                School Screening Health Program
              </div>
              <div>
                <button onClick={() => handleQrOpener()}>
                  <div>
                    <QrCodeScannerIcon sx={{ fill: '#1740A1', height: "31px", width: "31px" }} />
                  </div>
                  <div>Scan Student</div>
                </button>
              </div>
            </div>
          </div>
        </>
      </div>

      <Modal onClose={handleClose} open={isOpen}>
        <Box sx={style}>
          <div style={{ position: "relative" }}>
            <div title="Close scanner" onClick={handleClose} className={classes.closeIcon}>
              <ClearIcon sx={{ fill: 'white', fontSize: '40px' }} />
            </div>
            <div onClick={changeFacing} title="Flip camera" className={classes.flipIcon}>
              <FlipCameraAndroidIcon sx={{ fill: 'white', fontSize: '35px' }} />
            </div>
            <QrScanner
              delay={1000}
              style={previewStyle}
              onError={handleError}
              onScan={handleScan}
              constraints={{
                audio: false,
                video: { facingMode: facing }
              }}
            />
            <span className={classes.qr_square} />
            <span className={classes.qr_beam} />
          </div>
        </Box>
      </Modal>
    </>
  );
};
