import React, { useEffect, useState } from "react";
import Layout from "../common/Layout/Layout";
import classes from "./ResetPassword.module.css";
import InputPage from "../common/Input/Input";
import { verifyPassword } from "../common/commonFunctions";
import { Auth } from "aws-amplify";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import userReducer from "../../redux/reducers/user.reducer";
import authReducers from "../../redux/reducers/auth.reducers";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Cookies from "js-cookie";

export const ResetPasswordPage = (resetCognitoUser, setUser, login, getUserById) => {
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorStatement, setErrorStatement] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  //error messages according to the password validation
  const user = useSelector((state) => state.userReducer.currentUser)
  const userRole = useSelector((state) => state.userReducer.currentUser?.groups)
  const loggedIn = useSelector((state) => state.authReducer.loggedIn)

  const errors = {
    capital: "The password must contain at least 1 Uppercase character",
    lower: "The password must contain at least 1 Lowercase character",
    digit: "The password must contain at least 1 numeric character",
    specialcharacter:
      "The password must contain at least one special character",
    lengthOfPass: "The password must be 8 characters or longer",
    passwordDoesNotMatch: "The entered paswords do not match"
  };

  useEffect(() => {

    if (userRole && loggedIn) {
      // dispatch(login())
      if (userRole?.includes("school-principal")) {
        navigate('/principal-dashboard',)
      }
      else if (userRole?.includes("school-teacher")) {
        navigate('/teacher-dashboard')
      }
      else if (userRole?.includes("skids-sa")) {
        navigate('/superadmin-dashboard')
      }
      else if (userRole?.includes("school-admin")) {
        navigate('/admin-dashboard')
      }
      else if (userRole?.includes("SKIDS-ops")) {
        navigate('/ops-dashboard')
      }
      else if (userRole?.includes("skids-doctor")) {
        navigate('/doctor-dashboard')
      }
      else if (userRole?.includes("skids-ops-nurse")) {
        navigate('/ops-nurse/dashboard')
      }
      else if (userRole?.includes("skids-ops-manager")) {
        navigate('/ops-manager/dashboard')
      }
      else if (userRole?.includes("school-doctor") || userRole?.includes("school-nurse")) {
        navigate('/ops-manager/dashboard')
      }
    }
  }, [userRole, loggedIn])

  //handles password input and runs validation check
  const handlePassword = (item) => {
    let pass = verifyPassword(item);

    if (pass.capital) {
      setErrorStatement(errors.capital);
      setErrorPassword(true);
    } else if (pass.lower) {
      setErrorStatement(errors.lower);
      setErrorPassword(true);
    } else if (pass.digit) {
      setErrorStatement(errors.digit);
      setErrorPassword(true);
    } else if (pass.specialcharacter) {
      setErrorStatement(errors.specialcharacter);
      setErrorPassword(true);
    } else if (item.length < 8) {
      setErrorStatement(errors.lengthOfPass);
      setErrorPassword(true);
    } else {
      setErrorStatement("");
      setErrorPassword(false);
    }
    setPassword(item);
  };

  //checks if the both the password input is same
  const handleConfirmPassword = (item) => {
    setConfirmPassword(item);
    if (item === password) {
      setErrorConfirmPassword(false);
      setIsSubmit(true);
    } else {
      setErrorConfirmPassword(true);
      setIsSubmit(false);
    }
  };

  const password_conditions = [
    {
      condition: "At least 8 character(s)"
    },
    {
      condition: "At least 1 uppercase"
    },
    {
      condition: "At least 1 special character"
    },
    {
      condition: "At least 1 number"
    },
    {
      condition: "At least 1 lowercase"
    },
    {
      condition: "Password do not match"
    }

  ]

  //handles Reset Password and redirects to show Successful
  const handleReset = () => {
    Auth.completeNewPassword(
      user, // the Cognito User Object
      confirmPassword, // the new password
    )
      .then(async (user) => {
        const token = user.signInUserSession.idToken.jwtToken;
        dispatch(resetCognitoUser())
        if (token) {
          Cookies.set("tokenId", token, { expires: 1 });
          dispatch(login())
          // dispatch(setUser(currentUser.attributes))
          dispatch(getUserById({ email: user?.challengeParam?.userAttributes?.email, sub: user?.username }))
        }
      })
      .catch((e) => {

      });
    setTimeout(() => { navigate("/login") }, 2000)

  };
  let passwordIconRender;
  const passwordConditionCheck = (index) => {
    switch (index) {
      case 0:
        if (password.length && password.length >= 8) {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#149457', color: '#ffffff' }} />
        } else if (password.length && password.length < 8) {
          return passwordIconRender = <CancelIcon sx={{ fill: '#D95A59', color: '#ffffff' }} />
        } else {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#EBECEE', color: '#ffffff' }} />
        }


      case 1:
        if (password.length && /[A-Z]/.test(password)) {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#149457', color: '#ffffff' }} />
        } else if (password.length && !(/[A-Z]/.test(password))) {
          return passwordIconRender = <CancelIcon sx={{ fill: '#D95A59', color: '#ffffff' }} />
        } else {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#EBECEE', color: '#ffffff' }} />
        }


      case 2:
        if (password.length && /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#149457', color: '#ffffff' }} />
        } else if (password.length && !(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password))) {
          return passwordIconRender = <CancelIcon sx={{ fill: '#D95A59', color: '#ffffff' }} />
        } else {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#EBECEE', color: '#ffffff' }} />
        }


      case 3:
        if (password.length && /\d/.test(password)) {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#149457', color: '#ffffff' }} />
        } else if (password.length && !(/\d/.test(password))) {
          return passwordIconRender = <CancelIcon sx={{ fill: '#D95A59', color: '#ffffff' }} />
        } else {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#EBECEE', color: '#ffffff' }} />
        }


      case 4:
        if (password.length && /[a-z]/.test(password)) {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#149457', color: '#ffffff' }} />
        } else if (password.length && !(/[a-z]/.test(password))) {
          return passwordIconRender = <CancelIcon sx={{ fill: '#D95A59', color: '#ffffff' }} />
        } else {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#EBECEE', color: '#ffffff' }} />
        }

      case 5:
        if (password.length && confirmPassword.length && password === confirmPassword) {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#149457', color: '#ffffff' }} />
        } else if (password.length && confirmPassword.length && password !== confirmPassword) {
          return passwordIconRender = <CancelIcon sx={{ fill: '#D95A59', color: '#ffffff' }} />
        } else {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#EBECEE', color: '#ffffff' }} />
        }

    }
  }

  const finalSubmissionCheck = () => {
    if (password.length
      && password.length >= 8 && /[A-Z]/.test(password)
      && /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)
      && /[a-z]/.test(password)
      && /\d/.test(password)
      && confirmPassword.length && password === confirmPassword) {
      setIsSubmit(true)
    }
  }


  return (
    <>
      <div className={classes.login_page}>
        <InputPage
          placeholder={"Enter new password"}
          isPassword={true}
          width={'100%'}
          change={(e) => {
            handlePassword(e.target.value);
          }}
          value={password}
        ></InputPage>
        {/* {errorPassword ? (
          <div className={classes.error_text}>{errorStatement}</div>
        ) : (
          <></>
        )} */}
        <InputPage
          placeholder={"Confirm new password"}
          isPassword={true}
          width={'100%'}
          change={(e) => {
            handleConfirmPassword(e.target.value);
          }}
          value={confirmPassword}
        ></InputPage>
        {/* {errorConfirmPassword ? (
          <div className={classes.error_text}>
            The password must match with above password
          </div>
        ) : (
          <></>
        )} */}

        <div className={classes.password_parameters}>
          <div className={classes.password_parameters_containers}>
            {password_conditions.map((el, index) => {
              return index < 3 && (
                <div className={classes.password_parameters_boxes}>
                  {passwordConditionCheck(index)}
                  <p>{el.condition}</p>
                </div>

              )
            })}
          </div>
          <div className={classes.password_parameters_containers}>
            {password_conditions.map((el, index) => {
              return index >= 3 && (
                <div className={classes.password_parameters_boxes}>
                  <div>
                    {passwordConditionCheck(index)}
                  </div>
                  <p>{el.condition}</p>
                </div>
              )
            })}
          </div>
        </div>

        {isSubmit && !errorPassword ? (
          <button
            className={classes.submit_button}
            style={{ opacity: 1 }}
            onClick={() => {
              handleReset();
            }}
          >
            Change Password
          </button>
        ) : (
          <button
            className={classes.submit_button}
            style={{ opacity: 0.25 }}
            disabled
          >
            Change Password
          </button>
        )}
      </div>
    </>
  );
};

function ResetPassword({ resetCognitoUser, setUser, login, getUserById }) {
  return (
    <>
      <Layout
        component={ResetPasswordPage(resetCognitoUser, setUser, login, getUserById)}
        head={"Reset Password"}
        subhead={"Enter a new password to reset"}
      />
    </>
  );
}

const mapDispatchToProps = {
  resetCognitoUser: userReducer.actions.resetCognitoUser,
  setUser: userReducer.actions.setUser,
  login: authReducers.actions.login,
  getUserById: userReducer.actions.getUserByIdRequest,
};

export default connect(undefined, mapDispatchToProps)(ResetPassword)
