import React from "react";
import classes from "../Dyslexia/Dyslexia.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import { getAge, stringAvatar } from "../../../utils/utility";
import { Avatar } from "@mui/material";
import highrisk from "../../../assets/images/highRisk.svg";
import psychologist from "../../../assets/images/psychologist.svg";
import moment from "moment";
import clsx from "clsx";


export const Dysgraphia = ({ data }) => {
  const response = JSON.parse(data?.DYSGRAPHIA_1)?.response

  const questionBank = [
    {
      qno: 1,
      question:
        "How often does your child make spelling errors in homework assignments?",
      answer: response[0]
    },
    {
      qno: 2,
      question:
        "How often does your child have messy handwriting?",
      answer: response[1]
    },
    {
      qno: 3,
      question:
        "How often does your child have trouble with punctuation and capitalization?",
      answer: response[2]
    },
    {
      qno: 4,
      question:
        "How often does your child resist writing tasks?",
      answer: response[3]
    },
    {
      qno: 5,
      question: "How often does your child have difficulty getting thoughts down on paper?",
      answer: response[4]
    },
  ];

  return (
    <>
      <div className={clsx(classes.container, 'xyz')}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>
        <div className={classes.title_container}>
          Hey! This is my Dysgraphia Stage 1 assessment report.
        </div>

        <div className={classes.student_details_container}>
          <div className={classes.student_avatar_container}>
            <Avatar
              {...stringAvatar(`${data?.student?.name}`)}
              sx={{
                backgroundColor: "#EC0606",
                height: "39px",
                width: "39px",
                fontSize: "24px",
                fontWeight: "600",
                fontFamily: "Inter",
                margin: "0 auto",
              }}
            />
            <div>{data?.student?.name}</div>
            <div>
              <div>
                <p>{getAge(data?.student?.DOB)} yrs</p>
                <p>.</p>
                <p>{data?.student?.gender}</p>
                <p>.</p>
                {/* <p>{`${data?.student?.class}-${data?.student?.section}`}</p> */}
              </div>
            </div>

            <div className={classes.validation_container}>
              <div>
                <p>Validated by</p>
                <p>Pratikshya Kar</p>
              </div>

              <div>
                <p>Validation date & Time</p>
                <p>{moment(data?.updatedAt).format("Do MMM, YYYY hh:mm A")}</p>
              </div>
            </div>
          </div>

          <div>
            <p>Outcome</p>
            <div>
              <p>{JSON.parse(data?.DYSGRAPHIA_2).outcome ? JSON.parse(data?.DYSGRAPHIA_2).outcome : JSON.parse(data?.DYSGRAPHIA_1).outcome}</p>
              {(JSON.parse(data?.DYSGRAPHIA_2).outcome ? JSON.parse(data?.DYSGRAPHIA_2).outcome?.includes("Risk of dysgraphia") : JSON.parse(data?.DYSGRAPHIA_1).outcome?.includes("Risk of dysgraphia")) && <img src={highrisk} alt="highrisk" />}
            </div>

            <div className={classes.interpretation_container}>
              <div>
                <img src={psychologist} />
              </div>
              <div>
                <p>Psychologist Intrepretation</p>
                <p>
                  {JSON.parse(data?.DYSGRAPHIA_2).interpretation ? JSON.parse(data?.DYSGRAPHIA_2)?.interpretation : JSON.parse(data?.DYSGRAPHIA_1)?.interpretation}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.question_container}>
          <div>Assessment Questionnaire</div>
          <div className={classes.question_select_container}>
            {questionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p style={{ width: "209px" }}>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Never"
                          checked={el.answer === "NEVER"}
                        />
                        <p>Never</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Rarely"
                          checked={el.answer === "RARELY"}
                        />
                        <p>Rarely</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Sometime"
                          checked={el.answer === "SOMETIME"}
                        />
                        <p>Sometime</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Frequently"
                          checked={el.answer === "FREQUENTLY"}
                        />
                        <p>Frequently</p>
                      </div>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Always"
                          checked={el.answer === "ALWAYS"}
                        />
                        <p>Always</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.copyright_details_container}>
          <div>
            The information contained in this publication should not be used as
            a substitute for the medical care and advice of your psychologist.
            There may be variations in treatment that your psychologist may
            recommend based on individual facts and circumstances.
          </div>
          <div>
            Copyright @2023 Skids Health<br />Proprietorship of Skids Health Pvt.
            Ltd.
          </div>
        </div>

        <div className={classes.footer_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 1 of {JSON.parse(!data?.DYSGRAPHIA_2).outcome ? "1" : "2"}</p>
          </div>
        </div>
      </div>
    </>
  );
};
