import { createSlice } from "@reduxjs/toolkit";
import InitialStore from "../store/InitialStore";

export const getDoctorValidation = (state, action) => {
    if (!action.payload.nextToken)
        state.data = []
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const getDoctorValidationSuccess = (state, action) => {
    state.error = false;
    state.data = [...state.data, ...action.payload]
    state.loading = false;
}
export const getDoctorValidationFail = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}

export const assignDoctorRequest = (state, action) => {
    state.data = []
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const assignDoctorSuccess = (state, action) => {
    state.error = false;
    state.data = action.payload
    state.loading = false;
}
export const assignDoctorFail = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}

export const assignDoctorValidationCountRequest = (state, action) => {
    state.assignCountData = []
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const assignDoctorValidationCountSuccess = (state, action) => {
    state.error = false;
    state.assignCountData = action.payload
    state.loading = false;
}
export const assignDoctorValidationCountFail = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}

export const getDoctorInterpretation = (state, action) => {
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const getDoctorInterpretationSuccess = (state, action) => {
    state.error = false;
    state.data = action.payload
    state.loading = false;
}
export const getDoctorInterpretationFail = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}

export const getDoctorValidationProgressRequest = (state, action) => {
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const getDoctorValidationProgressSuccess = (state, action) => {

    state.error = false;
    state.data = action.payload
    state.loading = false;
}
export const getDoctorValidationProgressFail = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error
    state.loading = false;
}

export const updateDoctorInterpretation = (state, action) => {
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const updateDoctorInterpretationSuccess = (state, action) => {
    state.error = false;
    state.data = action.payload
    state.loading = false;
}
export const updateDoctorInterpretationFail = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}


export const updateValidationReq = (state, action) => {
    state.loading = true;
    state.error = false;
    state.errorMessage = '';
}
export const updateValidationSuccess = (state, action) => {
    state.error = false;
    state.validationDone = action.payload?.updateValidation?.validated;
    state.loading = false;
}
export const updateValidationFailure = (state, action) => {
    state.error = true;
    state.errorMessage = action.payload.error.message
    state.loading = false;
}

export const addValidatedStudent = (state, action) => {
    state.validatedStudents = [...state.validatedStudents, action.payload];
}

export const addRetakeParams = (state, action) => {
    state.reTake = action.payload;
}



export default createSlice({
    name: "validationReducer",
    initialState: InitialStore.validation,
    reducers: {
        getDoctorValidation,
        getDoctorValidationSuccess,
        getDoctorValidationFail,

        assignDoctorRequest,
        assignDoctorSuccess,
        assignDoctorFail,

        assignDoctorValidationCountRequest,
        assignDoctorValidationCountSuccess,
        assignDoctorValidationCountFail,
        getDoctorInterpretation,
        getDoctorInterpretationSuccess,
        getDoctorInterpretationFail,

        updateDoctorInterpretation,
        updateDoctorInterpretationSuccess,
        updateDoctorInterpretationFail,

        getDoctorValidationProgressRequest,
        getDoctorValidationProgressSuccess,
        getDoctorValidationProgressFail,

        updateValidationReq,
        updateValidationSuccess,
        updateValidationFailure,
        addValidatedStudent,

        addRetakeParams
    }
})