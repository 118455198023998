import React from 'react'
import SchoolInfo from '../../SchoolInfo/SchoolInfo'

const PrincipalSchoolInfo = () => {
  return (
    <div>
      <SchoolInfo/>
    </div>
  )
}

export default PrincipalSchoolInfo
