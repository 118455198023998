import { Avatar } from "@mui/material";
import React, { useState } from "react";
import classes from "./ParentsRow.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import RowPopup from "../../../common/TableComponents/RowPopup";
import { truncateString } from '../../../../utils/utility'
import { capitalizeFirstLetter } from '../../../../utils/utility'

export default function TeacherRows({ data, index }) {
  const { id, schoolName, pincode, schoolCode, board, organization, eventDate, status, action, url } = data;
  const [user, setUser] = useState(false);
  const navigate = useNavigate();
  const location = useLocation()
  const [openPop, setOpenPop] = useState(false)

  const getWordAfterDash = () => {
    let word = location.pathname.split('-')
    return word[1];
  }

  // const getSchoolInfo = (whereTo) => {
  //   function getWordBeforeDash() {
  //     let newStr = "";
  //     for (let i = 1; i < location.pathname.length; i++) {
  //       if (location.pathname[i] === "-") {
  //         return newStr;
  //       }
  //       newStr += location.pathname[i];
  //     }
  //   }
  //   if (getWordBeforeDash() === "superadmin") {
  //     location.state.breadCrumbs.push(data.schoolName);
  //     
  //     navigate(`/${getWordBeforeDash()}-${getWordAfterDash()}/${whereTo}/${schoolName}`, { state: { ...location.state, ...data } });
  //   }
  // }



  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 12,
        height: 12,
        padding: 2
      },
      children: `${name[0]}`,
    };
  }


  const handleReport = () => {
    setUser(true)
  }
  return (
    <>
      <div className={classes.studentRow_container}>

        {/* Name and Avatar Container */}
        <div>
          {/* Avatar Container */}
          {/* {url ? (
            <div>
              <img src={url} alt="schoolLogo" width="32" height="32" />
            </div>
          ) : ( */}
          <div>
            <Avatar {...stringAvatar(`${data?.given_name} `)} />
          </div>
          {/* )} */}


          {/* Name Container */}
          <div>
            <p>{capitalizeFirstLetter(truncateString(`${data?.given_name} ${data?.family_name}`, 30))}</p>
          </div>
        </div>

        {/* Class Container */}
        <div>
          <p>{truncateString(data?.email, 30)}</p>
        </div>

        {/* Email Container */}
        <div>
          <p>{data?.phone ?? "-"}</p>
        </div>
        {/* Screening Container */}
        <div>
          <p>{data?.userId}</p>
        </div>



        {/*  Validated Container */}


        {/* Conditional Buttons Rendering --Role Section */}
        {/* {index < 3 ? (
          <div>
            {user ? (<button className={classes.screened_btn}>INACTIVE</button>) :
              (<button onClick={handleReport} className={classes.report_btn}>SAVED</button>)
            }
          </div>
        ) : ( */}
        <div>
          {data?.isActive === null || data?.isActive === false ? (<div className={classes.screened_btn}>INACTIVE</div>) :
            (<div className={classes.report_btn_active}>ACTIVE</div>)
          }
        </div>

        {/* )} */}



        {/* Action Container */}
        <div title="school info" style={{ cursor: 'pointer', position: "relative", justifySelf: "center" }} onClick={() => setOpenPop(!openPop)}>
          <p>...</p>
          {openPop ? <RowPopup /> : <></>}
        </div>

      </div>

    </>
  );
}