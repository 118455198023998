import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import classes from "./Student.module.css";
import rectangle from "../../../assets/images/rectangle.png";
import SearchIcon from "@mui/icons-material/Search";
import { Avatar, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import StudentRows from "./StudentRows";
import left from "../../../assets/images/left.svg";
import leftArrow from "../../../assets/images/down.svg"
import active from "../../../assets/images/active.svg";
import rightArrow from "../../../assets/images/sort.svg";
import right from "../../../assets/images/right.svg";
import triangle from "../../../assets/images/triangle.svg";
import searchIcon from "../../../assets/images/search-icon.svg";
import addCircleFill from '../../../assets/images/add-circle-fill.svg'
import bulkupload from '../../../assets/images/upload-bulk.svg'
import studentReducer from "../../../redux/reducers/student.reducer";
import { connect, useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../TableComponents/BreadCrumbs";
import SearchBar from "../TableComponents/SearchBar";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import BulkUpload from "../../BulkUpload/BulkUpload";
import addcircleblue from '../../../assets/images/addcircleblue.svg'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ShareIcon from '@mui/icons-material/Share';
import ShareTypeformModal from "./ShareTypeformModal";
import BlankDataScreen from "../../BlankDataScreen/BlankDataScreen";

export function StudentPage({ data, schoolId, loading }) {

  const navigate = useNavigate()
  const location = useLocation();
  function getWordBeforeDash() {
    let newStr = "";
    for (let i = 1; i < location.pathname.length; i++) {
      if (location.pathname[i] === "-") {
        return newStr;
      }
      newStr += location.pathname[i];
    }
  }

  // console.log(JSON.parse(localStorage.getItem("filter")))

  const preFilledFilter = JSON.parse(localStorage.getItem("filter"))

  console.log(preFilledFilter)

  const [open, setOpen] = useState(false);
  const [sec, setSec] = useState(false);
  const [text, setText] = useState("");
  const [displayData, setDisplayData] = useState(data ?? []);
  const [rowsPerPage, setRowsPerPage] = useState(preFilledFilter?.row ?? 10)
  const [pageNo, setPageNo] = useState(preFilledFilter?.page ?? 1)
  const [selectedClass, setSelectedClass] = useState(preFilledFilter?.class ?? "All")
  const [selectedSection, setSelectedSection] = useState(preFilledFilter?.section ?? "All")
  const [openBulkUpload, setOpenBulkUpload] = useState(false)
  const [classOption, setClassOption] = useState([])
  const [sectionOption, setSectionOption] = useState(["All", "A", "B", "C", "D", "E","F","G","H","I"])
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [openShare, setOpenShare] = React.useState(false);
  const handleOpenShare = () => setOpenShare(true);
  const handleCloseShare = () => setOpenShare(false);

  const userRole = useSelector((state) => state.userReducer?.currentUser?.groups)

  // class option 
  useEffect(() => {
    if(data){
      setDisplayData(data);
    }

    if(!userRole.includes("skids-sa"))
    setClassOption(["All" ,...new Set(data?.map((obj)=>obj?.class))].sort((a,b)=>{
      if(a==="All" || b==="All"){
        return 1
      }
      if(parseInt(a)>parseInt(b)){
        return 1
      }
      else{
        return -1
      }
    }))

  }, [data])


  // class option
  useEffect(() => {
    if(userRole.includes("skids-sa")){let object = Object.keys(JSON.parse(location.state.classMap))
    let new_object = [];
    object.map((item) => {
      return new_object.push(item.split("_")[0]);
    });
    setClassOption(['All', ...new Set(new_object.sort((a, b) => a.localeCompare(b, 'en', { numeric: true })))])}
  }, [])
  console.log(classOption);

  // select selectedSection 
  useEffect(() => {
    // setSelectedSection(fil 'All')
    if(userRole.includes("skids-sa")){let object = Object.keys(JSON.parse(location?.state?.classMap))
    let new_object = [];
    object?.map(item => {
      return item.includes(selectedClass) && new_object.push(item.split('_')[1])
    })
    setSectionOption(['All', ...new Set(new_object.sort())])}
  }, [selectedClass])

  useEffect(() => {
    if (!sectionOption.includes(selectedSection)) {
      setSelectedSection("All")
    }
  }, [sectionOption])

  const handleOpen = () => {
    setOpen(!open);
  };
  const handleSection = () => {
    setSec(!sec);
  };
  const handleText = () => {
    setText(!text);
  };

  useEffect(() => {
    console.log(preFilledFilter, data)
    if (text && selectedClass !== "All" && selectedSection !== "All") {
      setDisplayData(
        data?.filter((x) => `${x?.firstName} ${x?.lastName}`.toLowerCase().includes(text.toLowerCase()))
          .filter((y) => y?.class === selectedClass)
          .filter((z) => z?.section === selectedSection)
      )
      setPageNo(1)
    }
    else if (text && selectedClass !== "All") {
      setDisplayData(
        data?.filter((x) => `${x?.firstName} ${x?.lastName}`.toLowerCase().includes(text.toLowerCase()))
          .filter((y) => y?.class === selectedClass)
      )
      setPageNo(1)
    }
    else if (text && selectedSection !== "All") {
      setDisplayData(
        data?.filter((x) => `${x?.firstName} ${x?.lastName}`.toLowerCase().includes(text.toLowerCase()))
          .filter((z) => z?.section === selectedSection)
      )
      setPageNo(1)
    }
    else if (selectedClass !== "All" && selectedSection !== "All") {
      setDisplayData(data?.filter((y) => y?.class === selectedClass)
        .filter((z) => z?.section === selectedSection))
      // setPageNo(1)
    }
    else if (text) {
      setDisplayData(data?.filter((x) => `${x?.firstName} ${x?.lastName}`.toLowerCase().includes(text.toLowerCase())))
      setPageNo(1)
    }
    else if (selectedClass !== "All") {
      setDisplayData(
        data?.filter((y) => y?.class === selectedClass)
      )
      // setPageNo(1)
    }
    else if (selectedSection !== "All") {
      setDisplayData(data?.filter((z) => z?.section === selectedSection))
      // setPageNo(1)
    }
    else {
      setDisplayData(data)
    }
  }, [text, selectedClass, selectedSection, data])

  const handleAddStudent = () => {
    if (location.pathname.includes("admin-student")) {
      navigate('/admin-student/individual/student')
    }
    else {
      navigate(`/${location.pathname.split('/')[1]}/individual/student`, { state: { ...location.state, breadCrumbs: location.state ? [...location.state?.breadCrumbs, "Add student"] : ['Add Student'] } })
    }
  }

  useEffect(() => {
    const filter = {
      class: selectedClass,
      section: selectedSection,
      row: rowsPerPage,
      page: pageNo
    }
    localStorage.setItem("filter", JSON.stringify(filter))
  }, [selectedClass, selectedSection, rowsPerPage, pageNo])
  return (

    <div style={{ width: '100%' }}>
      <BulkUpload openModal={openModal} setOpenModal={setOpenModal} handleCloseModal={handleCloseModal} schoolId={schoolId} />
      <ShareTypeformModal open={openShare} handleOpen={handleOpenShare} handleClose={handleCloseShare} schoolId={schoolId} />
      <div className={classes.student_layout}>
        <BreadCrumbs />
        <div>
          {(userRole?.includes('school-admin') || userRole?.includes('skids-sa') || userRole?.includes('skids-partner')) ? (<button onClick={() => setOpenModal(true)}>
            <FileUploadOutlinedIcon sx={{ fill: "#1740A1", backgroundColor: "#FFF", borderRadius: "50%" }} />
            <span>Upload bulk</span>
          </button>) : <></>}
          <button
            onClick={handleAddStudent}
          >
            <img src={addcircleblue} alt="addCircleFill" />
            <span>Add Student</span>
          </button>
        </div>
      </div>
      {data.length > 0 ?
        <>
          <div className={classes.student_filters}>
            <div className={classes.show_text}>Show</div>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Rows</InputLabel>
              <Select

                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                label="Rows"
                onChange={(e) => { setRowsPerPage(e.target.value); setPageNo(1) }}
                size='small'
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: 90 }}>
              <InputLabel id="demo-simple-select-label">Class</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedClass}
                label="Rows"
                onChange={(e) => setSelectedClass(e.target.value)}
                size='small'
              >
                {classOption?.map((x) => <MenuItem key={x} value={x}>{x}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl sx={{ width: 90 }}>
              <InputLabel id="demo-simple-select-label">Section</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedSection}
                label="Rows"
                onChange={(e) => setSelectedSection(e.target.value)}
                size='small'
              >
                {sectionOption.map((x) => <MenuItem key={x} value={x}>{x}</MenuItem>)}
              </Select>
            </FormControl>
            <SearchBar onchange={setText} value={text} />
            {userRole.includes("skids-sa") && <button className={classes.filter_container} onClick={handleOpenShare}>
              <ShareIcon sx={{ fill: "#FAF8F7", stroke: "#66707E", strokeWidth: "1px" }} />
              <p>Share Forms</p>
            </button>}
          </div>
          {/* Student Table Header */}
          <div className={classes.student_table_header}>
            <div>STUDENT NAME</div>
            <div>AGE</div>
            <div>GENDER</div>
            <div>CLASS</div>
            <div>SCREENING DATE & TIME</div>
            <div>STATUS</div>
            <div>VALIDATED BY</div>
            <div style={{ justifySelf: "center" }}>ACTION</div>
          </div>
          {/* Student Column-Rows Section */}
          <div className={classes.student_column_container}>
            {displayData?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo)?.map((el, index) => {
              return (
                <StudentRows data={el} key={index + Date.now()} />
              )
            })
            }
          </div>
          <div className={classes.pagination_container}>
            <div className={classes.page_item}>{`Showing ${(rowsPerPage * (pageNo - 1)) + 1} to ${pageNo * rowsPerPage <= displayData?.length ? pageNo * rowsPerPage : displayData?.length} of ${displayData?.length} items`}</div>
            <div className={classes.pagination}>
              <Button size="small" disabled={pageNo === 1} onClick={() => setPageNo(pageNo - 1)} >
                <ChevronLeft />
              </Button>
              <span>{pageNo}</span>
              <div>
                <span>of</span>
                <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
              </div>

              <div>
                <Button size="small" disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)} onClick={() => setPageNo(pageNo + 1)} >
                  <ChevronRight />
                </Button>
              </div>
            </div>
          </div>
        </>
        :
        loading ?
          ""
          :
          <BlankDataScreen title="Oops! No data available" description="There is no data uploaded in this table to be shown. Click on Add Student to upload data." handleClick={handleAddStudent} buttonText="Add Student" />
      }



      {/* 
      {
        !displayData.length && (
          <BlankDataScreen title="Oops! No data available" description="There is no data uploaded in this table to be shown. Click on Add Student to upload data." handleClick={handleAddStudent} buttonText="Add Student" />
        )
      } */}
    </div>
  )
}

function Student({ getStudents }) {

  const location = useLocation();

  const dispatch = useDispatch();

  const getStudentsBySection = studentReducer.actions.getStudentBySectionRequest

  const data = useSelector((state) => state.studentReducer.data)
  const loading = useSelector((state) => state.studentReducer.loading)

  let user = useSelector((state) => state.userReducer.currentUser)

  const schoolId = user?.schoolId

  useEffect(() => {
    if (!schoolId) {
      schoolId = location.state
    }
    if (location.pathname?.includes('teacher-student')) {
      dispatch(getStudentsBySection({ schoolId, classVal: user?.class, section: user?.section }))
    }
    else {
      dispatch(getStudents({ schoolId }))
    }
  }, [])

  return (
    <StudentPage data={data} loading={loading} />
  );
}

const mapDispatchToProps = {
  getStudents: studentReducer.actions.getStudentBySchoolIDRequest
};

export default connect(undefined, mapDispatchToProps)(Student)
