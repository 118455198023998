import { useEffect, useState } from "react";
import "./App.css";
import CustomRoutes from "./routes/CustomRoutes";
import authReducers from "./redux/reducers/auth.reducers";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { Amplify, Auth, API } from 'aws-amplify';
import userReducer, { getUserByIdRequest } from "./redux/reducers/user.reducer";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Splash from "./components/SkidsSplash/Splash";

function App() {

  

  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    },
    API: {
      'aws_appsync_graphqlEndpoint': process.env.REACT_APP_SERVER_URL,
      'aws_appsync_region': process.env.REACT_APP_REGION,
      'aws_appsync_authenticationType': 'AWS_LAMBDA',
      endpoints: [
        {
          name: "bulkUpload",
          endpoint: process.env.REACT_APP_BULK_UPLOAD_URL,
        },
        {
          name: "MyLogoAPIGateway",
          endpoint: process.env.REACT_APP_SCHOOL_LOGO_URL,
        },
        {
          name: "massimoUpload",
          endpoint: process.env.REACT_APP_MASIMO_UPLOAD,
        },
        {
          name: "reportUpload",
          endpoint: process.env.REACT_APP_REPORT_UPLOAD_URL,
        },
        {
          name: "bulkReportDownload",
          endpoint: process.env.REACT_APP_REPORT_DOWNLOAD_URL
        }
      ]
    }
  })

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = authReducers.actions.login
  const logout = authReducers.actions.logout
  const setUser = userReducer.actions.setUser
  const getUserById = userReducer.actions.getUserByIdRequest

  const user = useSelector((state => state.userReducer.currentUser))

  const loggedIn = useSelector((state) => state.authReducer.loggedIn)

  const loading = useSelector((state) => state.userReducer.currentUserLoading)

  const [localLoading, setLocalLoading] = useState(true)
  const [splashOn, setSplashOn] = useState(true);

  useEffect(() => {
    setLocalLoading(true)
    const token = Cookies.get("tokenId")
    if (token) {
      let user;
      (async () => {
        user = await Auth.currentUserInfo();
        await dispatch(getUserById({ email: user?.attributes?.email, sub: user?.username }))
      })();
      dispatch(login())
    }
    else {
      Cookies.remove("tokenId");
      dispatch(logout())
      // navigate("/login")
    }
    setLocalLoading(false)
  }, [])

  setTimeout(() => {
    setSplashOn(false);
    sessionStorage.setItem('splash', 'true')
  }, 3000);


  return (
    <div className="App">
      {splashOn && !sessionStorage.getItem('splash')
        ? <Splash />
        : (!loading && !localLoading) && <CustomRoutes />
      }
    </div>
  );
}


export default App