import * as React from "react"
const SvgComponent = (props) => (
  <div style={{ ...props.customStyle }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          stroke="#02B1AC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M35.887 23.695h-3.235v11.666l3.235-1.734v-9.932ZM24.551 25.32c-.324.134-1.67 1.778-3.971 1.778h-4.167s-3.13-.464-3.13 2.434 2.999 2.41 2.999 2.41h4.776s3.082 1.689 5.558 1.689c2.475 0 5.818-1.514 5.818-1.514"
        />
        <path
          stroke="#02B1AC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M32.653 23.701H30.22l-6.676-3.264M15.05 26.876l8.4-12.353 2.772 1.974-7.208 10.6M13.286 29.531l-1.784 2.564-.541 3.736 3.311-1.762 1.427-2.097M21.613 17.227l2.484 1.917"
        />
        <path
          stroke="#02B1AC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M20.309 19.142s-.902-.801-2.031-.312c-1.13.49-3.231 1.604-3.231 1.604l-4.5 6.884s-.953 1.467.22 2.534c1.17 1.067 2.516 0 2.516 0M14.783 33.32s-.239 2.047 1.716 2.047c1.954 0 1.432-3.422 1.432-3.422s-.347 3.422 1.693 3.422c2.041 0 1.743-3.261 1.728-3.27M4.635 13.101s-1.716-2.532.996-4.506c2.713-1.974 4.542.783 4.542.783s1.397 1.886-.316 3.133c-1.712 1.247-.432 2.778-.432 2.778l.667.958"
        />
        <path
          fill="#02B1AC"
          d="M11.134 18.412a.656.656 0 0 0 .648-.663.656.656 0 0 0-.648-.663.656.656 0 0 0-.648.663c0 .366.29.663.648.663Z"
        />
        <path
          stroke="#02B1AC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M16.63 7.2s.036-3.083 3.362-3.083c3.327 0 3.257 3.334 3.257 3.334s.07 2.365-2.03 2.365c-2.102 0-1.927 2.008-1.927 2.008V13"
        />
        <path
          fill="#02B1AC"
          d="M19.293 15.506a.656.656 0 0 0 .647-.663.656.656 0 0 0-.648-.663.656.656 0 0 0-.648.663c0 .366.29.663.649.663Z"
        />
        <path
          stroke="#02B1AC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M29.395 8.867s.928-2.933 4.105-1.92c3.177 1.013 2.141 4.173 2.141 4.173s-.62 2.284-2.63 1.64c-2.009-.642-2.42 1.332-2.42 1.332l-.341 1.124"
        />
        <path
          fill="#02B1AC"
          d="M29.716 17.639a.656.656 0 0 0 .648-.663.656.656 0 0 0-.648-.663.656.656 0 0 0-.648.663c0 .366.29.663.648.663Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M3.334 3.336h33.333v33.333H3.334z" />
        </clipPath>
      </defs>
    </svg>
  </div>
)
export default SvgComponent