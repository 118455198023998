import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

function GuestRoutes({component}) {

    const user = useSelector((state)=>state.userReducer.currentUser)
    const loggedIn = useSelector((state)=>state.authReducer.loggedIn)
    let role;
    

    if(user?.groups?.includes("skids-sa")){
        role = "superadmin"
    }
    else if(user?.groups?.includes("school-principal")){
        role = "principal"
    }
    else if(user?.groups?.includes("school-teacher")){
        role = "teacher"
    }
    else if(user?.groups?.includes("school-admin")){
        role = "admin"
    }
    else if(user?.groups?.includes("SKIDS-Ops")){
        role = "ops"
    }
    
    // if(Object.keys(user).length>0){
    //     return <Navigate to={`/${role}-dashboard`} />
    // }
    // else{
        return (
            <>
                {component}
            </>
        )
    // }
}

export default GuestRoutes