import * as React from "react"
const ValidationRetake = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        {...props}
    >
        <path
            fill="#E09F26"
            d="M10.242 20.8c-1.806-.394-3.298-1.315-4.475-2.762C4.589 16.59 4 14.917 4 13.017a7.63 7.63 0 0 1 2.525-5.708.771.771 0 0 1 .542-.196.782.782 0 0 1 .558.246.79.79 0 0 1 .246.608c-.008.233-.11.444-.304.633a5.937 5.937 0 0 0-1.4 1.992 6.018 6.018 0 0 0-.5 2.425c0 1.494.454 2.804 1.362 3.929a6.306 6.306 0 0 0 3.471 2.212.861.861 0 0 1 .488.309c.13.161.195.339.195.533a.79.79 0 0 1-.283.638.747.747 0 0 1-.658.162Zm3.55 0a.741.741 0 0 1-.659-.166.801.801 0 0 1-.283-.642c0-.189.065-.364.196-.525a.861.861 0 0 1 .487-.308c1.417-.356 2.574-1.095 3.471-2.217.897-1.122 1.346-2.43 1.346-3.925 0-1.767-.614-3.264-1.842-4.492-1.227-1.228-2.725-1.841-4.491-1.841h-.359l.784.783a.79.79 0 0 1 .225.583.79.79 0 0 1-.225.584.815.815 0 0 1-.6.241.76.76 0 0 1-.584-.241l-2.216-2.2a.797.797 0 0 1-.188-.275.856.856 0 0 1-.054-.309c0-.11.018-.214.054-.308a.797.797 0 0 1 .188-.275l2.216-2.217a.79.79 0 0 1 .584-.225c.239 0 .439.075.6.225.15.161.225.361.225.6a.79.79 0 0 1-.225.584l-.784.783h.359c2.233 0 4.125.778 5.675 2.333 1.55 1.556 2.325 3.445 2.325 5.667 0 1.894-.586 3.567-1.759 5.017-1.172 1.45-2.66 2.372-4.466 2.766Z"
        />
    </svg>
)
export default ValidationRetake
