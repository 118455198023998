import React, { useEffect, useState } from "react";
import classes from "./AddIndividual.module.css";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import ArrowRight from "@mui/icons-material/ArrowRight";
import schoollogo from "../../../assets/images/schoollogo.png";
import upload from "../../../assets/images/upload.svg";
import { Link, useParams } from "react-router-dom";
import InputDashboard from "../InputDashboard/InputDashboard";
import InputDropdown from "../InputDashboard/InputDropdown";
import NotificationClose from "../../../assets/images/notification-close.svg";
import { useLocation, useNavigate } from "react-router-dom";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import CustomMultiSelectDropdown from "../InputDashboard/CustomMultiSelect";
import BasicDatePicker from "../InputDashboard/InputDatePicker";
import MaterialUIPickers from "../InputDashboard/InputDatePicker";
import InputDatePicker from "../InputDashboard/InputDatePicker";
import DatePickerInput from "../InputDashboard/DatePickerInput";
import { Country, State, City } from "country-state-city";
import studentReducer from "../../../redux/reducers/student.reducer";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar, TextField, selectClasses } from "@mui/material";
import BreadCrumbs from "../TableComponents/BreadCrumbs";
import { capitalizeFirstLetter, validateEmail } from "../../../utils/utility";
import schoolReducer from "../../../redux/reducers/school.reducer";
import Hair from "../../../assets/Bodypoints/Hair/Hair";
import notification from "../../../assets/audios/notificationSound.mp3";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/de';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import eventReducer from "../../../redux/reducers/event.reducer";
import { ConsoleLogger } from "@aws-amplify/core";

function AddEditPerson(props) {
  const location = useLocation();
  const createEventMap = eventReducer.actions.createEventMapRequest;
  const getEventBySchool = eventReducer.actions.getEventsBySchoolIdRequest;
  const userData = useSelector((state) => state.userReducer?.currentUser);
  let notify = new Audio(notification);
  const { breadCrumTitle, formTitle } = props?.formDetails;
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [role, setRole] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [admissionNo, setAdmissionNo] = useState(location.state.isClinic ? moment().unix() : "");
  const [studentClass, setStudentClass] = useState(location.state.isClinic ? moment() : "");
  const [studentSection, setStudentSection] = useState(location.state.isClinic ? moment() : "");
  const [studentBloodGroup, setStudentBloodGroup] = useState("");
  const [studentRhType, setStudentRhType] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [motherPhoneNumberError, setMotherPhoneNumberError] = useState(false);
  const [fatherPhoneNumberError, setFatherPhoneNumberError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const schoolEventData = useSelector((state) => state.eventReducer.data);
  console.log(schoolEventData)
  const navigate = useNavigate();
  const [studentInfo, setStudentInfo] = useState({
    studentFatherName: "",
    studentFatherMobileno: "",
    studentFatherEmailid: "",
    studentMotherName: "",
    studentMotherMobileno: "",
    studentMotherEmailid: "",
  });

  useEffect(() => {
    if (location.pathname.includes("teacher")) {
      setStudentClass(userData?.class);
      setStudentSection(userData?.section);
    }
    if (location.state.isClinic)
      dispatch(getEventBySchool(location.state?.schoolId))
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    notify.play();
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };





  useEffect(() => {
    const states = State.getStatesOfCountry("IN").map((state) => state.name);
    setStateOptions(states);
  }, []);

  useEffect(() => {
    const phoneRegEx = /^[6-9]{1}[0-9]{9}$/;
    if (studentInfo.studentFatherMobileno.length > 0) {
      setFatherPhoneNumberError(
        !phoneRegEx.test(studentInfo.studentFatherMobileno)
      );
    }
  }, [studentInfo.studentFatherMobileno]);

  useEffect(() => {
    const selectedDate = new Date(dateOfBirth);
    const currentDate = new Date();

    if (selectedDate > currentDate) {
      setDobError(true);
    } else {
      setDobError(false);
    }
  }, [dateOfBirth]);


  useEffect(() => {
    const phoneRegEx = /^[6-9]{1}[0-9]{9}$/;
    if (studentInfo.studentMotherMobileno.length > 0) {
      setMotherPhoneNumberError(
        !phoneRegEx.test(studentInfo.studentMotherMobileno)
      );
    }
  }, [studentInfo.studentMotherMobileno]);

  useEffect(() => {
    if (selectedState) {
      const selectedStateObject = State.getStatesOfCountry("IN").filter(
        (x) => x.name === selectedState
      );
      setCityOptions(
        City.getCitiesOfState("IN", selectedStateObject[0].isoCode).map(
          (x) => x.name
        )
      );
    }
  }, [selectedState]);

  const dispatch = useDispatch();

  const createStudent = studentReducer.actions.createStudent;
  const getSchoolById = schoolReducer.actions.getSchoolbyIdRequest;

  const error = useSelector((state) => state.studentReducer.error);
  const loading = useSelector((state) => state.studentReducer.loading);

  let schoolId = useSelector((state) => state.userReducer.currentUser.schoolId);
  const school = useSelector((state) => state.schoolReducer.data);
  //

  useEffect(() => {
    if (location.pathname.includes("superadmin") || location.pathname.includes("partner")) {
      dispatch(getSchoolById({ schoolId: location.state.schoolId }));
    } else {
      dispatch(getSchoolById({ schoolId: schoolId }));
    }
  }, []);

  console.log(location.state)
  const handleSubmit = async () => {
    if (location.pathname.includes("superadmin") || location.pathname.includes("partner")) {
      schoolId = location.state.schoolId;
    }

    const formData = {
      isClinic : location.state.isClinic,
      admissionNo,
      firstName: capitalizeFirstLetter(firstName),
      lastName: capitalizeFirstLetter(lastName),
      gender,
      dateOfBirth,
      role: "student",
      studentClass: location.state.isClinic ? moment(studentClass).format("YYYY-MM") : studentClass.toUpperCase(),
      studentSection: location.state.isClinic ? studentSection.date() : studentSection,
      studentBloodGroup,
      studentRhType,
      studentInfo,
      schoolId: schoolId,
      schoolCode: school?.schoolCode,
      ear: school?.screeningPackageDetails?.ear,
      abdomen: school?.screeningPackageDetails?.abdomen,
      behavioralScreening: school?.screeningPackageDetails?.behavioralScreening,
      dental: school?.screeningPackageDetails?.dental,
      eye: school?.screeningPackageDetails?.eye,
      hair: school?.screeningPackageDetails?.hair,
      heart: school?.screeningPackageDetails?.heart,
      learningScreening: school?.screeningPackageDetails?.learningScreening,
      lungs: school?.screeningPackageDetails?.lungs,
      packageType: school?.package,
      skin: school?.screeningPackageDetails?.skin,
      throat: school?.screeningPackageDetails?.throat,
      vitalSigns: school?.screeningPackageDetails?.vitalSigns,
    };

    dispatch(createStudent(formData));
    if (location.state.isClinic) {
      const params = {
        classes: [moment(studentClass).format("YYYY-MM")],
        eventDate: moment(new Date()).format("YYYY-MM-DD"),
        eventId: schoolEventData?.[0]?.eventId,
        sections: [`${studentSection.date()}`],
        stationId: "BLR_01"  
      };
      dispatch(createEventMap(params));
    }
    handleClick();
    // if(loading === false && error === false){
    //   setTimeout(() => navigate(-1), 5000)
    // }
  };
  return (
    <div className={classes.mainDiv}>
      <BreadCrumbs />
      <div className={classes.container}>
        <h4>{formTitle}</h4>
        {location.pathname.includes("student") ? (
          <></>
        ) : (
          <InputDashboard
            placeholder={"Email"}
            width={"32%"}
            className={classes.input_layout_schoolname}
            change={(e) => {
              setEmail(e.target.value);
            }}
            bgColor="#ffffff"
            value={email}
          />
        )}

        <div className={classes.lowlight_text}>
          {location.pathname.includes("student")
            ? "Personal information"
            : "New user login credentials will be sent to this email id"}
        </div>
        {/* <div className={classes.upload_logo}> */}
        {/* <div className={classes.no_upload_logo}>
            <ImageRoundedIcon
              sx={{ fill: "#E8E7E7", width: "56px", height: "56px" }}
            />
          </div> */}
        {/* <div className={classes.upload}>
                        <img alt="" src={upload}></img>
                        <div className={classes.upload_text}>Upload School Logo</div>
                    </div> */}
        {/* </div> */}
        <div className={classes.input_school_layout}>
          <InputDashboard
            placeholder={"First Name"}
            width={"95%"}
            className={classes.input_layout_schoolname}
            change={(e) => {
              setFirstName(e.target.value);
            }}
            bgColor="#ffffff"
            value={firstName}
          />
          <InputDashboard
            placeholder={"Last Name"}
            width={"95%"}
            className={classes.input_layout_schoolname}
            change={(e) => {
              setLastName(e.target.value);
            }}
            bgColor="#ffffff"
            value={lastName}
          />
          {location.pathname.includes("student") ? (
            <></>
          ) : (
            <InputDropdown
              placeholder={"Gender"}
              width={"95%"}
              className={classes.input_layout_boardname}
              bgColor="#fff"
              value={gender}
              defaultValues={["male", "female", "other"]}
              setValue={setGender}
            />
          )}
        </div>
        <div className={classes.input_school_layout}>
          <div>
            <DatePickerInput
              onChange={(e) => {
                setDateOfBirth(e.target.value);
              }}
              width={"95%"}
              placeholder={"Date of birth"}
              value={dateOfBirth}
            />
            {dobError && (
              <div className={classes.validation_text}>
                Please enter Valid Date of birth*
              </div>
            )}
          </div>

          {location.pathname.includes("student") ? (
            <InputDropdown
              placeholder={"Gender"}
              width={"95%"}
              margin={2}
              className={classes.input_layout_boardname}
              bgColor="#fff"
              value={gender}
              defaultValues={["Male", "Female", "Other"]}
              setValue={setGender}
            />
          ) : (
            <DatePickerInput
              onChange={(e) => {
                setDateOfJoining(e.target.value);
              }}
              width={"95%"}
              placeholder={"Date of Joining"}
              value={dateOfJoining}
            />
          )}

          {!location.state.isClinic && <InputDashboard
            placeholder={"Admission Number"}
            width={"95%"}
            margin={2}
            className={classes.input_layout_schoolname}
            change={(e) => {
              setAdmissionNo(e.target.value);
            }}
            bgColor="#ffffff"
            value={admissionNo}
          />}
          {/* <BasicDatePicker /> */}
        </div>
        {location.pathname.includes("student") ? (
          location.state.isClinic ?
            <div className={classes.input_school_layout}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Month and Year"
                  // inputFormat="YYYY/MM/DD"
                  value={studentClass}
                  onChange={(e) => { console.log(e); setStudentClass(e) }}
                  views={['month', 'year']}
                  renderInput={(params) => <TextField size="small"
                    {...params}
                    sx={{
                      height: "40px",
                      width: "23vw",
                      marginTop: "20px"
                    }}
                    className="myDatePicker"

                  />}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Day"
                  inputFormat="DD"
                  value={studentSection}
                  onChange={(e) => { console.log(e); setStudentSection(e) }}
                  views={['day']}
                  renderInput={(params) => <TextField size="small"
                    {...params}
                    sx={{
                      height: "40px",
                      width: "23vw",
                      marginTop: "20px"
                    }}
                  // className={classes.input_layout_boardname}
                  />}
                />
              </LocalizationProvider>

            </div> :
            <div className={classes.input_school_layout}>
              <InputDropdown
                disabled={location.pathname.includes("teacher")}
                placeholder={"Class"}
                width={"95%"}
                margin={2}
                className={classes.input_layout_boardname}
                bgColor="#fff"
                value={studentClass}
                defaultValues={[
                  "Nursery",
                  "LKG",
                  "UKG",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "10",
                  "11",
                  "12",
                ]}
                setValue={setStudentClass}
              />
              <InputDropdown
                disabled={location.pathname.includes("teacher")}
                placeholder={"Section"}
                width={"95%"}
                margin={2}
                className={classes.input_layout_boardname}
                bgColor="#fff"
                value={studentSection}
                defaultValues={[
                  "A",
                  "B",
                  "C",
                  "D",
                  "E",
                  "F",
                  "G",
                  "H",
                  "I",
                  "J",
                  "K",
                  "L",
                  "M",
                  "N",
                  "O",
                  "P",
                  "Q",
                  "R",
                  "S",
                  "T",
                  "U",
                  "V",
                  "W",
                  "X",
                  "Y",
                  "Z",
                ]}
                setValue={setStudentSection}
              />
            </div>
        ) : (
          <></>
        )}

        {location.pathname.includes("student") ? (
          <>
            <div className={classes.contact_info}>Health information</div>
            <div className={classes.input_layout}>
              <InputDropdown
                placeholder={"Blood group"}
                width={"95%"}
                margin={2}
                bgColor="#ffffff"
                value={studentBloodGroup}
                setValue={setStudentBloodGroup}
                defaultValues={["O", "A", "B", "AB"]}
                required={false}
              />

              <InputDropdown
                placeholder={"Rh type"}
                width={"95%"}
                bgColor="#ffffff"
                margin={2}
                value={studentRhType}
                setValue={setStudentRhType}
                defaultValues={["+", "-"]}
                required={false}
              />
            </div>
            <div className={classes.contact_info}>Parents information</div>
            <div className={classes.input_layout}>
              <InputDashboard
                placeholder={"Fathers Name"}
                width={"95%"}
                margin={2}
                className={classes.input_layout_schoolname}
                change={(e) => {
                  setStudentInfo({
                    ...studentInfo,
                    studentFatherName: e.target.value,
                  });
                }}
                bgColor="#ffffff"
                value={studentInfo.studentFatherName}
                required={false}
              />
              <div>
                <InputDashboard
                  placeholder={"Mobile Number"}
                  width={"95%"}
                  margin={2}
                  className={classes.input_layout_schoolname}
                  change={(e) => {
                    setStudentInfo({
                      ...studentInfo,
                      studentFatherMobileno: e.target.value,
                    });
                  }}
                  bgColor="#ffffff"
                  value={studentInfo.studentFatherMobileno}
                  required={false}
                />
                {fatherPhoneNumberError && (
                  <div className={classes.validation_text}>
                    Please enter valid 10 digit number*
                  </div>
                )}
              </div>
              <div>
                <InputDashboard
                  placeholder={"Parent Email Id"}
                  width={"95%"}
                  margin={2}
                  className={classes.input_layout_schoolname}
                  change={(e) => {
                    setStudentInfo({
                      ...studentInfo,
                      studentFatherEmailid: e.target.value,
                    });
                  }}
                  bgColor="#ffffff"
                  value={studentInfo.studentFatherEmailid}
                  required={false}
                />
                {!validateEmail(studentInfo.studentFatherEmailid) &&
                  studentInfo.studentFatherEmailid.length ? (
                  <div className={classes.validation_text}>
                    Please fill valid email id*
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={classes.input_layout}>
              <InputDashboard
                placeholder={"Mothers Name"}
                width={"95%"}
                margin={2}
                className={classes.input_layout_schoolname}
                change={(e) => {
                  setStudentInfo({
                    ...studentInfo,
                    studentMotherName: e.target.value,
                  });
                }}
                bgColor="#ffffff"
                value={studentInfo.studentMotherName}
                required={false}
                disabled={true}
              />
              <div>
                <InputDashboard
                  placeholder={"Mobile Number"}
                  width={"95%"}
                  margin={2}
                  className={classes.input_layout_schoolname}
                  change={(e) => {
                    setStudentInfo({
                      ...studentInfo,
                      studentMotherMobileno: e.target.value,
                    });
                  }}
                  bgColor="#ffffff"
                  value={studentInfo.studentMotherMobileno}
                  required={false}
                />
                {motherPhoneNumberError && (
                  <div className={classes.validation_text}>
                    Please enter valid 10 digit number*
                  </div>
                )}
              </div>
              <div>
                <InputDashboard
                  placeholder={"Email Id"}
                  width={"95%"}
                  margin={2}
                  className={classes.input_layout_schoolname}
                  change={(e) => {
                    setStudentInfo({
                      ...studentInfo,
                      studentMotherEmailid: e.target.value,
                    });
                  }}
                  bgColor="#ffffff"
                  value={studentInfo.studentMotherEmailid}
                  required={false}
                />
                {!validateEmail(studentInfo.studentMotherEmailid) &&
                  studentInfo.studentMotherEmailid.length ? (
                  <div className={classes.validation_text}>
                    Please fill valid email id*
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={classes.contact_info}>Contact information</div>
            <div className={classes.input_layout}>
              <InputDashboard
                placeholder={"Phone number"}
                width={"23vw"}
                margin={2.4}
                change={(e) => {
                  setNumber(e.target.value);
                }}
                bgColor="#ffffff"
                value={number}
              />
            </div>
            <InputDashboard
              placeholder={"Address"}
              width={"65%"}
              margin={2.4}
              change={(e) => {
                setAddress(e.target.value);
              }}
              bgColor="#ffffff"
              value={address}
            />
            <div className={classes.input_layout}>
              <InputDashboard
                placeholder={"Pincode"}
                width={"95%"}
                margin={2.4}
                change={(e) => {
                  setPincode(e.target.value);
                }}
                bgColor="#ffffff"
                value={pincode}
              />
              <InputDropdown
                placeholder={"City"}
                width={"95%"}
                margin={2.4}
                bgColor="#ffffff"
                value={city}
                setValue={setCity}
                defaultValues={cityOptions}
              />
              <InputDropdown
                placeholder={"State"}
                width={"95%"}
                margin={2.4}
                bgColor="#ffffff"
                value={state}
                setValue={setSelectedState}
                defaultValues={stateOptions}
              />
            </div>
          </>
        )}

        {/* <div className={classes.contact_info}>Staff & Ameneties</div>
                <div className={classes.input_layout}>
                    <InputDashboard
                        placeholder={"Teachers"}
                        width={"23vw"}
                        change={(e) => {
                            
                            setSchoolName(e.target.value);
                        }}
                        bgColor="#ffffff"
                        value={schoolName}
                    />
                    <InputDashboard
                        placeholder={"Students"}
                        width={"23vw"}
                        change={(e) => {
                            
                            setSchoolName(e.target.value);
                        }}
                        bgColor="#ffffff" 
                        value={schoolName}
                    />
                    <InputDropdown
                        placeholder={"Infirmary"}
                        width={"23vw"}
                        bgColor="white"
                        value={infirmary}
                        setValue={setInfirmary}
                    />
                    <CustomMultiSelectDropdown amenities={amenities} setAmenities={setAmenities} />
                </div> */}
        {/* <div className={classes.button_amenities_container}>
                    {amenities.map((el, index) => {
                        return (
                            <div key={index + Date.now()} className={classes.button_amenities}>
                                <p>{el}</p>
                                <button onClick={() => handleDeleteAmenities(el)}>
                                    <img src={NotificationClose} alt="Notification-Close" />
                                </button>
                            </div>
                        )
                    })}
                </div> */}
        {!loading && (
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={!error && !loading ? "success" : "error"}
              sx={{ width: "100%" }}
            >
              {!error
                ? "Student Added Successfully"
                : "Error While Adding Student"}
            </Alert>
          </Snackbar>
        )}
        <div className={classes.buttons}>
          <button
            className={classes.button_with_border}
            onClick={() => navigate(-1)}
          >
            CANCEL
          </button>
          <button
            onClick={handleSubmit}
            className={classes.button_filled}
            disabled={
              firstName &&
                lastName &&
                !dobError &&
                dateOfBirth &&
                admissionNo &&
                studentClass &&
                studentSection
                ? false
                : true
            }
          >
            {breadCrumTitle}
          </button>
        </div>
      </div>
    </div>
  );
}

function AddIndividual() {
  const { person } = useParams();

  let formDetails;
  if (person === "doctor") {
    formDetails = {
      breadCrumTitle: "Add Doctor",
      formTitle: "Doctor registration information",
    };
  } else if (person === "nurse") {
    formDetails = {
      breadCrumTitle: "Add Nurse",
      formTitle: "Nurse registration information",
    };
  } else if (person === "staff") {
    formDetails = {
      breadCrumTitle: "Add Staff",
      formTitle: "Staff registration information",
    };
  } else if (person === "schooladmin") {
    formDetails = {
      breadCrumTitle: "Add School Admin",
      formTitle: "School Admin registration information",
    };
  } else if (person === "principal") {
    formDetails = {
      breadCrumTitle: "Add Principal",
      formTitle: "Principal registration information",
    };
  } else if (person === "teacher") {
    formDetails = {
      breadCrumTitle: "Add Teacher",
      formTitle: "Teacher registration information",
    };
  } else if (person === "counsellor") {
    formDetails = {
      breadCrumTitle: "Add Counsellor",
      formTitle: "Counsellor registration information",
    };
  } else if (person === "infirmary") {
    formDetails = {
      breadCrumTitle: "Add Infirmary",
      formTitle: "Infirmary registration information",
    };
  } else if (person === "student") {
    formDetails = {
      breadCrumTitle: "Add Student",
      formTitle: "Student registration information",
    };
  } else {
    formDetails = {
      breadCrumTitle: "Add Student",
      formTitle: "Student registration information",
    };
  }

  return <AddEditPerson formDetails={formDetails} />;
}

export default AddIndividual;
