import React from 'react'
import SchoolInfo from '../../SchoolInfo/SchoolInfo'

const AdminSchoolInfo = () => {
  return (
    <div>
      
      <SchoolInfo/>
    </div>
  )
}

export default AdminSchoolInfo
