import React from "react";
import ErrorLayout from "../common/ErrorLayout/ErrorLayout";

function PageNotFound() {
  return (
    <div>
      <ErrorLayout
        errorHeader={"PAGE NOT FOUND"}
        message={
          "We couldn’t find that page. It’s either moved or doesn’t exist anymore."
        }
        link={-1}
        linkMessage={"Go back"}
        errorCode={"404 ERROR CODE"}
        errorMessage={"PAGE NOT FOUND"}
      />
    </div>
  );
}

export default PageNotFound;
