import React, { useEffect, useState } from "react";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";
import classes from "./TicketDetail.module.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import userReducer from "../../redux/reducers/user.reducer";
import user from "../../graphqlApiServices/users";
import supportReducer from "../../redux/reducers/support.reducer";
import { Button } from "@mui/material";
import LoadingBar from "react-top-loading-bar";

const TicketDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  console.log(location.state, "location.state");
  const { userId, email, ticketId } = location.state;
  const [userDetails, setUserDetails] = useState();
  const [progress, setProgress] = useState(0);
  const getTicketById = supportReducer.actions.getSupportTicketByIdRequest;
  const updateSupportTicket = supportReducer.actions.updateSupportTicketRequest;
  const currentTicket = useSelector(
    (state) => state.supportReducer.currentTicket
  );
  const { getUserById } = user;

  useEffect(() => {
    if (ticketId) {
      getTicketByTicketId();
    }
  }, [ticketId]);

  useEffect(() => {
    if (userId && email) {
      getUserDetails();
    }
  }, [userId, email]);

  const getUserDetails = async () => {
    try {
      const payload = {
        email: location.state?.email,
        sub: location.state?.userId,
      };
      const res = await getUserById({ payload });
      console.log(res, "res");
      if (res.errors) {
        return;
      }
      setUserDetails(res?.data?.getUserById);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getTicketByTicketId = () => {
    const payload = { ticketId: ticketId };
    dispatch(getTicketById(payload));
  };

  const handleStatusChange = async (status) => {
    try {
      setProgress(40);
      const payload = {
        ticketId: location.state?.ticketId,
        status: status,
      };
      setProgress(70);
      await dispatch(updateSupportTicket(payload));
      getTicketByTicketId();
      setTimeout(() => {
        setProgress(100);
      }, 500);
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <>
      <div className={classes.ticket_info}>
        <div className={classes.ticket_header}>
          <div className={classes.header}>
            {/* <div className={classes.back}>
          {" "}
          <Link
            to={navLink}
            style={{ textDecoration: "none", color: "#9d9d9d" }}
          >
            Registered {entity}{" "}
          </Link>{" "}
        </div>
        <ArrowRight />{" "}
        <div className={classes.header_text}>{location?.state?.name}</div> */}
            <BreadCrumbs />
          </div>
          {/* <Link
        to={editLink}
        style={{ textDecoration: "none", color: "white", pointerEvents: entity === "ticket" && !info?.isActive ? "none" : "all" }}
        state={info}
      >
        {entity === "ticket" && (info?.isActive ? (
          <button className={classes.edit_button}>
            <img src={editOrg} />
            <span>Edit {entity}</span>
          </button>
        ) : (
          <button className={classes.edit_button_disabled}>
            <img src={editdeactivated} />
            <span>Edit {entity}</span>
          </button>
        ))}
        {entity === "clinic" && (location.state?.isActive ? (
          <button className={classes.edit_button}>
            <img src={editOrg} />
            <span>Edit {entity}</span>
          </button>
        ) : (
          <button className={classes.edit_button_disabled}>
            <img src={editdeactivated} />
            <span>Edit {entity}</span>
          </button>
        ))}
      </Link> */}
        </div>
        <div className={classes.ticket_container}>
          <div className={classes.issue_header}>
            <div>
              <div>
                <h4>Issue Id: {location.state?.ticketId}</h4>
                <div
                  className={classes.issue_status}
                  style={
                    currentTicket?.status === "resolved"
                      ? { backgroundColor: "#2ECC71" }
                      : { backgroundColor: "#F39C12" }
                  }
                >
                  {currentTicket?.status === "resolved" ? "RESOLVED" : "OPEN"}
                </div>
              </div>
              <div className={classes.issue_metadata}>
                <div>
                  <h6>
                    Issuer's Name:{" "}
                    <span>
                      {userDetails?.given_name + " " + userDetails?.family_name}
                    </span>
                  </h6>
                </div>
                <div>
                  <h6>
                    Issuer's Email: <span>{userDetails?.email}</span>
                  </h6>
                </div>
                <div>
                  <h6>
                    Topic: <span>{location.state?.topic}</span>
                  </h6>
                </div>
              </div>
            </div>
            <div>
              <button
                // onClick={() => { ToggleDevice("SECA") }}
                onClick={() => handleStatusChange("resolved")}
                className={
                  currentTicket?.status === "resolved"
                    ? `${classes.heart_poll_container_button_active}`
                    : `${classes.heart_poll_container_button_inactive}`
                }
              >
                Resolved
              </button>
              <button
                // onClick={() => { publishTestUpdates('height', false) }}
                onClick={() => handleStatusChange("open")}
                className={
                  currentTicket?.status === "open"
                    ? `${classes.heart_poll_container_button_active}`
                    : `${classes.heart_poll_container_button_inactive}`
                }
              >
                Pending
              </button>
            </div>
          </div>
          {currentTicket?.url && (
            <div className={classes.issue_image}>
              <img src={currentTicket?.url} alt="issue image" />
            </div>
          )}
          <div className={classes.issue_text}>
            <p>{currentTicket?.issue}</p>
          </div>
        </div>
      </div>
      <LoadingBar
        color="#3b3cea"
        height={4}
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
    </>
  );
};

export default TicketDetail;
