import React from "react";
import classes from "./AutismReport2.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import mcneil from "../../../assets/images/McNeil.svg";
import nichq from "../../../assets/images/NICHQ.svg";
import americanPediatrics from "../../../assets/images/americanPediatrics.svg";
import { textFormat } from "../../../utils/utility";
import clsx from "clsx";


export const AutismReport1to42 = ({ data }) => {
  const response = JSON.parse(data?.AUTISM)?.response
  const questionBank = [];

  return (
    <>
      <div className={clsx(classes.container, 'xyz')}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>

        {/* <div className={classes.assessment_question_container}>
          <div>Assessment Questionnaire Continued...</div>
          <div className={classes.question_select_container}>
            {questionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                  <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="No"
                          checked={el.answer==="No"}
                        />
                        <p>No</p>
                      </div>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Yes"
                          checked={el.answer==="Yes"}
                        />
                        <p>Yes</p>
                      </div>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Somewhat"
                          checked={el.answer==="Somewhat"}
                        />
                        <p>Somewhat</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div> */}

        <div className={classes.copyright_details_container}>
          <div>
            The information contained in this publication should not be used as
            a substitute for the medical care and advice of your psychologist.
            There may be variations in treatment that your psychologist may
            recommend based on individual facts and circumstances.
          </div>
          <div>
            Copyright @2002 American Academy of Pediatrics and National
            Initiative for Children’s Healthcare Quality
            <br /> Adapted from the Vanderbilt Rating Scale developed by Mark L.
            Wolraich, MD.
            <br /> Revised - 1102
          </div>
        </div>
        <div className={classes.method_container}>Method or Framework</div>
        <div className={classes.certification_container}>
          <img src={americanPediatrics} />
          <img src={nichq} />
          <img src={mcneil} />
        </div>

        <div className={classes.footer_container}>
          <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
          <p>Page 2 of 2</p>
        </div>
      </div>
    </>
  );
};