import React from "react";
import GaugeChart from "react-gauge-chart";

const VitalsGaugeChart = ({
    label,
    value,
    maxValue,
    suffix,
    colors,
    nrOfLevels,
}) => {

    return (
        <>
            <div
                style={{
                    // backgroundColor: getRandomColor(),
                    width: "90%",
                    maxWidth: 500,
                    margin: '1rem 0'
                }}
            >
                <h6 style={{ fontSize: '0.8rem', fontWeight: 600, color: 'gray' }}>
                    {label}{" "}
                </h6>
                {colors ? (
                    <GaugeChart
                        nrOfLevels={nrOfLevels ? nrOfLevels : 10}
                        // colors={["#b7e2b7", "#ebeba7", "#e2b7b7"]}
                        id="gauge-chart1"
                        percent={maxValue ? value / maxValue : value}
                        textColor="#000"
                        fontSize="24"
                        colors={colors ? colors : []}
                        needleBaseColor="#ccc"
                        animateDuration={1000}
                        formatTextValue={(v) =>
                            `${((v * maxValue) / 100).toFixed(1)} ${suffix ? suffix : ""}`
                        }
                    />
                ) : (
                    <GaugeChart
                        nrOfLevels={nrOfLevels ? nrOfLevels : 10}
                        // colors={["#b7e2b7", "#ebeba7", "#e2b7b7"]}
                        id="gauge-chart1"
                        percent={maxValue ? value / maxValue : value}
                        textColor="#000"
                        fontSize="24"
                        needleBaseColor="#ccc"
                        animateDuration={1000}
                        formatTextValue={(v) =>
                            `${((v * maxValue) / 100).toFixed(1)} ${suffix ? suffix : ""}`
                        }
                    />
                )}
                <p className="tw-font-inter tw-text-2xl tw-font-bold tw-text-center">
                    {value} <span className="tw-text-sm tw-font-inter">{suffix}</span>
                </p>
            </div>
            <div style={{
                height: "1.8px",
                backgroundColor: "#E8E7E7"

            }} />
        </>
    );
};

export default VitalsGaugeChart;
