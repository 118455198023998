import { Avatar } from "@mui/material";
import React, { useState } from "react";
import classes from "./StudentRows.module.css";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { CollapseTable } from "./CollapseTable";
import { useLocation, useNavigate } from "react-router";
import RowPopup from "../TableComponents/RowPopup";
import { useDispatch } from "react-redux";
import studentReducer from "../../../redux/reducers/student.reducer";
import { info } from "logrocket";
import { textFormat, toDateString, numberTh, truncateString, capitalizeFirstLetter } from "../../../utils/utility";

export default function StudentRows({ data }) {
  const { studentId, firstName, lastName, DOB, gender, status } = data;
  const [user, setUser] = useState(false);
  const [openPop, setOpenPop] = useState(false)
  const location = useLocation();
  const navigate = useNavigate()
  const deleteStudent = studentReducer.actions.deleteStudentRequest
  const dispatch = useDispatch()
  function navigateStudent(x) {

    if (location.pathname.includes('superadmin')) {
      if (x === 'info') {
        navigate('/superadmin-screening', { state: { ...data, breadCrumbs: [...location.state.breadCrumbs, firstName + " " + lastName] } })
      }
      else if (x === 'edit') {
        navigate(`/superadmin/individual/edit/student`, { state: { data } })
      }
    } else if (location.pathname.includes('partner')) {
      if (x === 'info') {
        navigate('/partner-screening', { state: { ...data, breadCrumbs: [...location.state.breadCrumbs, firstName + " " + lastName] } })
      }
      else if (x === 'edit') {
        navigate(`/partner/individual/edit/student`, { state: { data } })
      }
    } else if (location.pathname.includes('principal')) {
      if (x === 'info') {
        navigate('/principal-screening', { state: { ...data } })
      }
      else if (x === 'edit') {
        navigate(`/principal/individual/student`, { state: { ...data } })
      }
      else if (x === 'delete') {
        // const {  schoolId, studentId } = data
        // dispatch(deleteStudent({ schoolId, studentId}))
      }
    } else if (location.pathname.includes('teacher')) {
      if (x === 'info') {
        navigate('/teacher-screening', { state: { ...data, breadCrumbs: [...location.state.breadCrumbs, firstName + " " + lastName] } })
      }
      else if (x === 'edit') {
        navigate(`/teacher/individual/student`, { state: { data: { ...data }, breadCrumbs: [...location.state.breadCrumbs, firstName + " " + lastName] } })
      }
    }

  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  const getWordAfterDash = () => {
    let word = location.pathname.split('-')
    return word[1];
  }

  const getSchoolInfo = (whereTo) => {
    function getWordBeforeDash() {
      let newStr = "";
      for (let i = 1; i < location.pathname.length; i++) {
        if (location.pathname[i] === "-") {
          return newStr;
        }
        newStr += location.pathname[i];
      }
    }

    if (getWordBeforeDash() === "superadmin") {
      navigate(`/${getWordBeforeDash()}-${getWordAfterDash()}/${whereTo}/${firstName}`, { state: data });
    }
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 12,
        height: 12,
        padding: 2
      },
      children: `${name[0]}`,
    };
  }

  const getAge = (age) => {
    var today = new Date();
    var birthDate = new Date(age);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }


  const handleReport = () => {
    setUser(true)
  }

  const getStatus = (x) => {
    switch (x) {
      case 'PATIENT_CREATED':
        return 'Student Created'
      case 'PATIENT_ADDED':
        return "Scheduled"
      case 'SCREENING_DONE':
        return 'Screened'
      case 'ASSIGN_VALIDATION':
        return 'Validation Pending'
      case 'VALIDATION_DONE':
        return 'Validated'
      default:
        return 'Unavailable'
    }
  }

  const getColor = (x) => {
    switch (x) {
      case 'PATIENT_CREATED':
        return '#898A8D'
      case 'PATIENT_ADDED':
        return "#E09F26"
      case 'SCREENING_DONE':
        return '#149457'
      case 'ASSIGN_VALIDATION':
        return '#FFFFFF'
      case 'VALIDATION_DONE':
        return '#1740A1'
      default:
        return '#D95A59'
    }
  }

  const getTextColor = (x) => {
    switch (x) {
      case 'PATIENT_CREATED':
        return '#FFFFFF'
      case 'PATIENT_ADDED':
        return "#FFFFFF"
      case 'SCREENING_DONE':
        return '#FFFFFF'
      case 'ASSIGN_VALIDATION':
        return '#343434'
      case 'VALIDATION_COMPLETE':
        return '#FFFFFF'
      default:
        return '#FFFFFF'
    }
  }


  return (
    <>
      <div className={classes.studentRow_container}>

        {/* Name and Avatar Container */}
        <div>
          {/* Avatar Container */}
          <div>
            <Avatar {...stringAvatar(`${firstName} ${lastName}`)} />
          </div>

          {/* Name Container */}
          <div>
            <p>{(firstName || lastName) ? capitalizeFirstLetter(truncateString(`${firstName} ${lastName}`, 30)) : "Unavailable"}</p>
          </div>
        </div>

        {/* Age Container */}
        <div>
          <p>{DOB ? `${getAge(DOB)} yrs` : "unavailable"}</p>
        </div>

        {/* Gender Container */}
        <div>
          <p>{gender ? gender : "unavailable"}</p>
        </div>


        <div>
          {/* <CollapseTable data={data.data} /> */}
          {location.state.isClinic ? data?.class :numberTh(data?.class)}-{data?.section}
        </div>
        {/* Screening Container */}
        <div>
          <p>{toDateString(data?.screeningDate)}</p>
        </div>

        {/* Conditional Buttons Rendering */}
        <div>
          <div style={{ backgroundColor: getColor(data?.status), color: getTextColor(data?.status) }} className={data?.status ? `${classes.screened_btn}` : `${classes.report_btn}`}>{getStatus(data?.status)}</div>
          {/* <div>{data?.screening ? 'Schedule' : 'Not Scheduled'}</div> */}
        </div>

        {/*  Validated Container */}
        <div>
          <p>{data?.validatedBy ?? "-"}</p>
        </div>


        {/* Action Container */}
        <div style={{ position: "relative", cursor: "pointer", justifySelf: "center" }} onClick={() => setOpenPop(!openPop)}>
          <div className={classes.infoHover}>
            <p>...</p>
            <div className={classes.popOnHover}>
              <RowPopup navLink={navigateStudent} />
            </div>
          </div>
        </div>

      </div>

    </>
  );
}