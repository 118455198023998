import React, { useEffect, useState } from 'react'
import classes from './Assign.module.css'
import screeningsred from '../../assets/images/screeningsred.svg'
import screeningsyellow from '../../assets/images/screeningsyellow.svg'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import userReducer from '../../redux/reducers/user.reducer'
import DoctorCard from './DoctorCard'
import validationReducer from '../../redux/reducers/validation.reducer'
import { Alert, Snackbar } from '@mui/material'
import BreadCrumbs from '../common/TableComponents/BreadCrumbs'
import { API } from 'aws-amplify'
import Cookies from "js-cookie";

export default function Assign() {
  const [screeningType, setScreeningType] = useState('Physical')

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation()

  const getDoctors = userReducer.actions.getDoctorsBySpecialityRequest;

  const getDoctorValidationCount = validationReducer.actions.assignDoctorValidationCountRequest;

  const assign = validationReducer.actions.assignDoctorRequest;

  const docSpec = useSelector((state) => state.userReducer.users)

  const countData = useSelector((state) => state.validationReducer.assignCountData)

  const error = useSelector((state) => state.validationReducer.error)

  const loading = useSelector((state) => state.validationReducer.loading)

  const [open, setOpen] = React.useState(false);
  const [unvalidated, setUnvalidated] = useState(0)

  const breadCrumbs = ["Assign Validation"]

  console.log(docSpec);

  useEffect(() => {
    let spec = "PAEDIATRICIAN";
    let screenType;

    if (screeningType === "Physical") {
      screenType = "physicalScreening"
    }
    else if (screeningType === "behavioralScreening") {
      screenType = "behavioralScreening"
    }
    else {
      screenType = "dentalScreening"
    }
    if (screeningType === "Dental") {
      spec = "DENTAL"
    }
    else if (screeningType === "Behavioral") {
      spec = "BEHAVIORAL"
    }
    const token = Cookies.get("tokenId");
    API.graphql({
      query: `query MyQuery {
        getPendingValidation(assignType: "${screenType}") {
          validationId
          userId
          screeningId
          studentId
          patientName
          DOB
          gender
          screeningDate
          validationDueDate
          status
          validated
          assignType
          doctorName
          validatedOn
          createdAt
          updatedAt
        }
      }`,
      authToken: token,
    }).then((res) => setUnvalidated(res?.data?.getPendingValidation?.length))
      .catch((error) => {
        if (error?.data?.getPendingValidation?.length) {
          setUnvalidated(error?.data?.getPendingValidation?.length)
        }
        else {
          setUnvalidated(0)
        }
      });
    dispatch(getDoctors(spec))
    dispatch(getDoctorValidationCount(screenType))


  }, [screeningType])

  useEffect(() => {
    location.state = { ...location.state, breadCrumbs: breadCrumbs }
  }, [])

  const assignDoctor = async (docId, date, student) => {
    let screenType;
    if (screeningType === "Physical") {
      screenType = "physicalScreening"
    }
    else if (screeningType === "behavioralScreening") {
      screenType = "behavioralScreening"
    }
    else {
      screenType = "dentalScreening"
    }
    const formData = {
      screeningType: screenType,
      doctorId: docId,
      dueDate: date,
      limit: student
    }
    await dispatch(assign(formData))

    handleClick()
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const getCountData = (doc) => {

    const x = countData?.filter((z) => z?.userId === doc?.userId)
    return x
  }

  return (
    <>
      <div className={classes.assign_header}>
        <BreadCrumbs />
        <button className={classes.assign_validation_progress_btn} onClick={() => navigate('/superadmin/validation/assign', { state: { ...location.state, breadCrumbs: [...location.state.breadCrumbs, "Assign Doctor"] } })}>Assign Doctor</button>
      </div>

      <div className={classes.assign_layout_header}>
        <div className={classes.assign_layout_header_inner_container_first}>
          <div
            className={`${screeningType === 'Physical' ? `${classes.assign_layout_header_physical} ${classes.assign_layout_header_bgColor_active_physical}` :
              `${classes.assign_layout_header_physical}`}`}
            onClick={() => setScreeningType('Physical')}>Physical Screening</div>

          <div className={`${screeningType === 'Dental' ? `${classes.assign_layout_header_dental} ${classes.assign_layout_header_bgColor_active_dental}` :
            `${classes.assign_layout_header_dental}`}`}
            onClick={() => setScreeningType('Dental')}>Dental Screening</div>

          <div className={`${screeningType === 'Behavioral' ? `${classes.assign_layout_header_behavioral} ${classes.assign_layout_header_bgColor_active_behavioral}` :
            `${classes.assign_layout_header_behavioral}`}`}
            onClick={() => setScreeningType('Behavioral')}>Behavioral Screening</div>
        </div>
        <div className={classes.assign_layout_header_inner_container_second}></div>
      </div>

      <div className={classes.assign_layout_component_container}>
        <div className={classes.assign_layout_component_header}>{screeningType.toUpperCase()} SCREENING</div>
        <div className={classes.assign_layout_component_subheader}>
          <div>
            <img src={screeningType === 'Behavioral' ? screeningsyellow : screeningsred} />
          </div>
          <div className={classes.screening_text}>
            <p className={`${screeningType === 'Behavioral' ? `${classes.yellow_screening_number}` :
              `${classes.physical_screening_number}`}`}>{unvalidated}</p>
            <p className={classes.physical_screening_text}>screenings done today</p>
          </div>
        </div>
        <div className={classes.screening_cards_container}>


          {/* {[1,2,3,4,5]?.map((doc,i)=>{
          
          return (
            <DoctorCard data={doc} countData={getCountData(doc)} key={i} handleAssign={assignDoctor} />
          )
         }) } */}

          {/* {!data.length && <p>{`No ${screeningType} Doctors Found`}</p>} */}

          {/* map Card for grid layout */}
          {docSpec?.map((el) => {
            return (
              <div className={classes.screening_card_large}>
                <p>Dr. {el.given_name + " " + el.family_name}</p>
                <div className={classes.large_card_data_box}>
                  <div>
                    <p>Total assigned</p>
                    <p>400</p>
                  </div>
                  <div>
                    <p>Validation Progress</p>
                    <p>200/400</p>
                  </div>
                </div>
              </div>
            )
          })}

        </div>
      </div>

      <Snackbar
        open={open && !loading}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={!error ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {!error ? "Patients Assign Successfully" : "Error While Assigning Patients"}
        </Alert>
      </Snackbar>

    </>
  )
}
