import React from 'react';
import classes from './BhvCertification.module.css'
import mcneil from "../../../assets/images/McNeil.svg";
import nichq from "../../../assets/images/NICHQ.svg";
import americanPediatrics from "../../../assets/images/americanPediatrics.svg";
import swyc from "../../../assets/images/SWYC.png";
import tufts from "../../../assets/images/TUFTS.png";



const BhvCertification = (props) => {

    return (
        <div className={classes.main}>
            <p>Method or Framework</p>
            {
                props?.copyRight &&
                <>
                    <p>Copyright @2023 Skids Health</p>
                    <p>Proprietorship of Skids Health Pvt. Ltd.</p>
                </>
            }
            {
                props.adopted &&
                <p>
                    Adapted from ASSQ (Autism Spectrum Screening Questionnaire) and M-CHAT (Modified Checklist for Autism in Toddlers)
                    Reference from DSM-5 (Diagnostic and Statistical Manual of Mental Disorders) and ICD-10 (International Classification of Diseases,Tenth Revision)
                </p>
            }
            {
                props?.scared &&
                <p>
                    SCARED (Screen for Child Anxiety Related Disorders) Reference from Journal of the American Academy of Child and Adolescent Psychiatry
                </p>
            }
            {
                props?.DASC &&
                <p>
                    Adapted from DASC (Digital Addiction Scale for Children)
                </p>
            }
            {
                props?.PHQ &&
                <p>
                    PHQ-9 Patient Depression Questionnaire
                </p>
            }
            {
                props?.swyctufts &&
                <p>
                    This document is based on the Survey of Well-Being of Young Children® (SWYC) © 2016 Tufts Medical Center, all rights reserved. The SWYC which is the source of this document incorporates the Edinburgh Postnatal Depression Scale (“EPDS”) © 1987 The Royal College of Psychiatrists. Cox, J.L., Holden, J.M., and Sagovsky, R. (1987). Detection of postnatal depression. Development of the 10-item Edinburgh Postnatal Depression Scale. British Journal of Psychiatry. 150, 782-786. All of the rights of the respective authors are reserved under applicable copyright law.”
                </p>
            }
            {
                props?.DSM &&
                <p>
                    Reference from DSM-5 (Diagnostic and Statistical Manual of Mental Disorders) and ICD-10 (International Classification of Diseases,Tenth Revision)
                </p>
            }
            <div>
                {
                    props.swyc &&
                    <>
                        <img src={tufts} alt="" />
                    </>
                }
                {
                    props.tufts &&
                    <img src={tufts} alt="" />
                }
                {
                    props.img3 &&
                    <>
                        <img src={americanPediatrics} alt="" />
                        <img src={nichq} alt="" />
                        <img src={mcneil} alt="" />
                    </>
                }
            </div>
        </div>
    )
}

export default BhvCertification