import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./NurseStudents.module.css";
import NurseStudentRow from "./NurseStudentRow";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../common/TableComponents/BreadCrumbs";
import SearchBar from "../../common/TableComponents/SearchBar";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Filter } from "../../common/TableComponents/Filter";
import studentReducer from "../../../redux/reducers/student.reducer";

function NurseStudentPage() {
  const location = useLocation();
  const dispatch = useDispatch();
  const breadCrumbs = ["Daily Diary"];

  // 

  const [text, setText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [historyFilter, setHistoryFilter] = useState(false)
  const [noHistoryFilter, setNoHistoryFilter] = useState(false)
  const schoolId = useSelector(
    (state) => state.userReducer.currentUser.schoolId
  );
  const getStudents = studentReducer.actions.getStudentBySchoolIDRequest;
  const studentData = useSelector((state) => state.studentReducer.data);
  const [displayData, setDisplayData] = useState(studentData ?? []);

  // 
  

  useEffect(() => {
    if (schoolId) {
      dispatch(getStudents({schoolId}));
    }
    location.state = { breadCrumbs: breadCrumbs };
  }, [schoolId]);

  useEffect(() => {
    setDisplayData(studentData);
  }, [studentData]);

  useEffect(() => {
    if (text) {
      setDisplayData(
        studentData?.filter(
          (el) =>
            (el.firstName === text ||
            el.firstName
              .toLowerCase()
              .includes(
                text.toLowerCase() 

              )) || (el.lastName === text || el.lastName.toLowerCase().includes(text.toLowerCase()))
        )
      );
    } else if (!text) {
      setDisplayData(studentData);
    }
  }, [text]);

  
  useEffect(() => {
    if(historyFilter && noHistoryFilter){
      setDisplayData(studentData)
    }
    else if(historyFilter){
      setDisplayData(studentData.filter(el => el?.logs?.length))
    }else if(noHistoryFilter){
      setDisplayData(studentData.filter(el => el?.logs === null))
    }else{
      setDisplayData(studentData)
    }
  }, [historyFilter, noHistoryFilter])

  return (
    <div className={classes.main_div}>
      <div className={classes.student_layout}>
        <div>
          <BreadCrumbs />
        </div>
      </div>
      <div className={classes.student_filters}>
        <div className={classes.show_text}>Show</div>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Rows</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={rowsPerPage}
            label="Rows"
            onChange={(e) => setRowsPerPage(e.target.value)}
            size="small"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
        <SearchBar id={"searchCy"} onchange={setText} value={text} />
        <Filter label1 = {"History"} label2 = {"No History"} 
        filter1 = {historyFilter} 
        filter2 = {noHistoryFilter} 
        setFilter1 = {setHistoryFilter}
        setFilter2 = {setNoHistoryFilter}
        />
      </div>
      {/* Student Table Header */}
      <div className={classes.student_table_header}>
        <div>STUDENT NAME</div>
        <div>CLASS</div>
        <div>GENDER</div>
        <div>LAST INFIRMARY VISIT</div>
        <div>SITUATION</div>
        <div>ADD LOG</div>
        <div>ACTION</div>
      </div>
      {/* Student Column-Rows Section */}
      <div id="schoolRowCy" className={classes.student_column_container}>
        {displayData
          ?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo)
          ?.map((el, index) => {
            return (
              <NurseStudentRow
                data={el}
                key={index + Date.now()}
                index={index}
              />
            );
          })}
      </div>
      <div className={classes.pagination_container}>
        <div className={classes.page_item}>{`Showing ${
          rowsPerPage * (pageNo - 1) + 1
        } to ${
          pageNo * rowsPerPage <= displayData?.length
            ? pageNo * rowsPerPage
            : displayData?.length
        } of ${displayData?.length} items`}</div>
        <div className={classes.pagination}>
          <Button
            size="small"
            disabled={pageNo === 1}
            onClick={() => setPageNo(pageNo - 1)}
          >
            <ChevronLeft />
          </Button>
          <span>{pageNo}</span>
          <div>
            <span>of</span>
            <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
          </div>

          <div>
            <Button
              size="small"
              disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)}
              onClick={() => setPageNo(pageNo + 1)}
            >
              <ChevronRight />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function NurseStudent() {
  return <NurseStudentPage />;
}
export default NurseStudent;
