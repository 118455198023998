
import * as React from "react"
const SvgComponent = (props) => (
  <div style={{ ...props.customStyle }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
      {...props}
    >
      <g
        stroke="#E09F26"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        clipPath="url(#a)"
      >
        <path d="M32.513 27.447s4.413-.462 3.06-3.082c-1.352-2.621-3.06-5.167-3.06-5.167S34.759 2.492 20.042 2.492 1.908 15.59 5.467 21.745l3.56 6.159v7.787s-.143 1.804 1.814 1.804h12.704s1.416-.161 1.416-1.408v-1.954S25.324 33 26.82 33h2.341s2 .142 2.356-1.485c.355-1.628.996-4.068.996-4.068Z" />
        <path d="M28.195 8.762a2.444 2.444 0 0 0-1.93-.629S25.19 4.955 21.647 6.34c0 0-3.827-3.275-6.611 1.037 0 0-4.143-2.263-5.377 2.299 0 0-4.646 2.012-1.012 5.977 0 0-1.487 2.422.063 4.467M29.49 11.54s.57 1.385 0 2.735c0 0 1.868 6.513-4.838 4.563 0 0-1.456 2.673-4.113 1.663 0 0-2.278 2.982-5.188.972 0 0-2.309 2.425-4.646 1.105" />
        <path d="M11.942 15.594s-2.374 1.201-1.404 2.517c.97 1.316 2.665-.697 2.665-.697s-1.68 1.646-.6 2.361c0 0 1.24.94 2.748-1.033M27.568 15.182l-.304 1.67-3.748-3.727M24.509 12.14l-1.917 1.906M11.594 10.6l1.663-1.655.456 3.512s.784 1.485 1.81.462c1.025-1.023.185-1.535.185-1.535M21.49 18.222l-1.206-3.464-2.92 2.904M19.278 18.035l-2.73-2.715M20.224 12.633l2.332-2.32-1.152-1.146-.291-.29-1.167 1.158s-1.022 1.304-2.05.277c-.773-.768.929-2.177 1.703-2.945" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M3.334 1.664h33.333v36.667H3.334z" />
        </clipPath>
      </defs>
    </svg>
  </div>
)
export default SvgComponent

