import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function CustomAutoComplete({value, options, placeholder, onChange}) {
  return (
    <Autocomplete
      size='small'
      options={options}
      value={value}
      sx={{ width: 200 }}
      placeholder={placeholder}
      renderInput={(params) => 
        <TextField
            {...params} 
            label={placeholder}
            InputLabelProps={{style: {fontSize: 15}}}
        />
      }
      onChange={(e,value)=>{onChange(value)}}
    />
  );
}
