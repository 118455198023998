import { Cookie } from "@mui/icons-material";
import { API, graphqlOperation } from "aws-amplify";
import Cookies from "js-cookie";

function getStudents() {
  const token = Cookies.get("tokenId");
  return API.graphql({
    query: `query MyQuery {
            getStudents(){
                DOB
                admissionNumber
                bloodGroup {
                    group
                    rhType
                }
                class
                fatherContact {
                    email
                    mobile
                }
                fatherName
                firstName
                gender
                isActive
                lastName
                motherContact {
                    email
                    mobile
                }
                motherName
                schoolId
                section
                studentId
                screeningPackageDetails {
                  abdomen
                  behavioralScreening
                  dental
                  ear
                  eye
                  hair
                  heart
                  learningScreening
                  lungs
                  skin
                  throat
                  vitalSigns
                }
            }
        }`,
    authToken: token,
  });
}

function getStudentByClass(payload) {
  const token = Cookies.get("tokenId");
  return API.graphql({
    query: `query MyQuery {
            getStudents(classVal: ${payload.payload.classVal} , schoolId: ${payload.payload.classVal}){
                DOB
                admissionNumber
                bloodGroup {
                    group
                    rhType
                }
                class
                fatherContact {
                    email
                    mobile
                }
                fatherName
                firstName
                gender
                isActive
                lastName
                motherContact {
                    email
                    mobile
                }
                motherName
                schoolId
                section
                studentId
            }
        }`,
    authToken: token,
  });
}
function getStudentByID(payload) {
  const token = Cookies.get("tokenId");
  return API.graphql({
    query: `query MyQuery {
            getStudentByID(schoolId: "${payload.payload?.schoolId ?? ""}" , studentId: "${payload.payload?.studentId}"){
                DOB
                admissionNumber
                bloodGroup {
                    group
                    rhType
                }
                class
                fatherContact {
                    email
                    mobile
                }
                fatherName
                firstName
                gender
                isActive
                lastName
                motherContact {
                    email
                    mobile
                }
                status
                motherName
                schoolId
                section
                screenings
                studentId
            }
        }`,
    authToken: token,
  });
}
function getStudentBySchoolId(payload) {
  const token = Cookies.get("tokenId");
  console.log(payload)
  let x
  if (payload.payload.nextToken) {
    x = `schoolId: "${payload.payload.schoolId}", nextToken: "${payload.payload.nextToken}"`;
  } else {
    x = `schoolId: "${payload.payload.schoolId}"`;
  }
  console.log(x)
  return API.graphql({
    query: `query MyQuery {
      getStudentBySchoolID(${x}) {
        items {
          DOB
          admissionNumber
          bhvComplete
          bhvSend
          bloodGroup {
            group
            rhType
          }
          class
          createdAt
          fatherContact {
            email
            mobile
          }
          fatherName
          firstName
          gender
          isActive
          lastName
          logs {
            SpO2
            bloodPressure
            createdAt
            diagnosis
            heartRate
            height
            infirmary
            infirmaryName
            problemFaced
            situation
            temperature
            weight
          }
          motherContact {
            mobile
            email
          }
          motherName
          package
          schoolId
          screeningDate
          screeningPackageDetails {
            abdomen
            behavioralScreening
            dental
            ear
            eye
            hair
            heart
            learningScreening
            lungs
            packageType
            skin
            throat
            vitalSigns
          }
          screenings
          section
          status
          studentId
          updatedAt
        }
        nextToken
      }
    }`,
    authToken: token,
  });
}

function getStudentBySection(payload) {
  const token = Cookies.get("tokenId");
  let x;
  if (payload.payload.nextToken) {
    x = `classVal: "${payload.payload.classVal}",schoolId: "${payload.payload.schoolId}", section: "${payload.payload.section}", nextToken: "${payload.payload.nextToken}"`;
  } else {
    x = `classVal: "${payload.payload.classVal}",schoolId: "${payload.payload.schoolId}", section: "${payload.payload.section}"`;
  }
  return API.graphql({
    query: `query MyQuery {
      getStudentBySection(${x}){
        items {
          DOB
          admissionNumber
          bhvComplete
          bhvSend
          bloodGroup {
            group
            rhType
          }
          class
          createdAt
          fatherContact {
            email
            mobile
          }
          fatherName
          firstName
          gender
          isActive
          lastName
          logs {
            SpO2
            bloodPressure
            createdAt
            diagnosis
            heartRate
            height
            infirmary
            infirmaryName
            problemFaced
            situation
            temperature
            weight
          }
          motherContact {
            mobile
            email
          }
          motherName
          package
          schoolId
          screeningDate
          screeningPackageDetails {
            abdomen
            behavioralScreening
            dental
            ear
            eye
            hair
            heart
            learningScreening
            lungs
            packageType
            skin
            throat
            vitalSigns
          }
          screenings
          section
          status
          studentId
          updatedAt
        }
        nextToken
      }
        }`,
    authToken: token,
  });
}

function createStudent(payload) {

  const token = Cookies.get("tokenId");
  const params = payload?.payload;
  const rhtype = params.studentRhType === "+" ? "Pos" : (params.studentRhType === "-" ? "Neg" : "");
  let variables
  let x
  if (params?.studentBloodGroup && rhtype !== "") {
    variables = { ear: params?.ear, abdomen: params?.abdomen, behavioralScreening: params?.behavioralScreening, dental: params?.dental, eye: params?.eye, hair: params?.hair, heart: params?.heart, lungs: params?.lungs, learningScreening: params?.learningScreening, skin: params?.skin, throat: params?.throat, vitalSigns: params?.vitalSigns, bloodGroup: params?.studentBloodGroup, rhtype: rhtype }
    x = "$ear: [String], $abdomen: [String], $behavioralScreening :[String], $dental: [String], $eye: [String], $hair: [String], $heart: [String], $lungs: [String], $learningScreening: [String], $skin: [String], $throat: [String], $vitalSigns: [String], $bloodGroup: BloodGroupEnum, $rhtype: RhTypeEnum"
  }
  else {
    variables = { ear: params?.ear, abdomen: params?.abdomen, behavioralScreening: params?.behavioralScreening, dental: params?.dental, eye: params?.eye, hair: params?.hair, heart: params?.heart, lungs: params?.lungs, learningScreening: params?.learningScreening, skin: params?.skin, throat: params?.throat, vitalSigns: params?.vitalSigns }
    x = "$ear: [String], $abdomen: [String], $behavioralScreening :[String], $dental: [String], $eye: [String], $hair: [String], $heart: [String], $lungs: [String], $learningScreening: [String], $skin: [String], $throat: [String], $vitalSigns: [String]"
  }
  return API.graphql(graphqlOperation(
    `mutation MyMutation (${x}) {
        createStudent(admissionNumber: "${params.admissionNo}", ${params?.studentBloodGroup && rhtype !== "" ? "bloodGroup: {group: $bloodGroup, rhType: $rhtype}" : ""}, DOB: "${params.dateOfBirth}", firstName: "${params.firstName}", gender: ${params.gender}, lastName: "${params.lastName}", schoolId: "${params.schoolId}", class: "${params.studentClass}", fatherContact: {email: "${params.studentInfo.studentFatherEmailid}", mobile: "${"+91" + params.studentInfo.studentFatherMobileno}"}, fatherName: "${params.studentInfo.studentFatherName}", motherContact: {email: "${params.studentInfo.studentMotherEmailid}", mobile: "${params.studentInfo.studentMotherMobileno}"}, package: ${params.packageType}, MotherName: "${params.studentInfo.studentMotherName}", section: "${params.studentSection}", schoolCode: "${params.schoolCode}", screeningPackageDetails: {abdomen: $abdomen, behavioralScreening: $behavioralScreening, dental: $dental, ear: $ear, eye: $eye, hair: $hair, heart: $heart, lungs: $lungs, learningScreening: $learningScreening, skin: $skin, throat: $throat, vitalSigns: $vitalSigns}) {
          DOB
          admissionNumber
          bloodGroup {
            group
            rhType
          }
          class
          createdAt
          fatherContact {
            email
            mobile
          }
          fatherName
          firstName
          gender
          isActive
          lastName
          motherContact {
            email
            mobile
          }
          motherName
          schoolId
          screeningDate
          screenings
          section
          studentId
          updatedAt
          screeningPackageDetails {
            abdomen
            behavioralScreening
            dental
            ear
            eye
            hair
            heart
            learningScreening
            lungs
            skin
            throat
            vitalSigns
          }
        }
      }`,
    variables, token
  ));
}

function updateStudent(payload) {

  const token = Cookies.get("tokenId");
  const params = payload?.payload;

  const rhtype = params.studentRhType === "+" ? "Pos" : (params.studentRhType === "-" ? "Neg" : "");
  let variables
  let x
  if (params?.studentBloodGroup && rhtype !== "") {
    variables = { ear: params?.ear, abdomen: params?.abdomen, behavioralScreening: params?.behavoral, dental: params?.dental, eye: params?.eye, hair: params?.hair, heart: params?.heart, lungs: params?.lungs, learningScreening: params?.learning, skin: params?.skin, throat: params?.throat, vitalSigns: params?.vitalSigns, bloodGroup: params?.studentBloodGroup, rhtype: rhtype }
    x = "$ear: [String], $abdomen: [String], $behavioralScreening :[String], $dental: [String], $eye: [String], $hair: [String], $heart: [String], $lungs: [String], $learningScreening: [String], $skin: [String], $throat: [String], $vitalSigns: [String], $bloodGroup: BloodGroupEnum, $rhtype: RhTypeEnum"
  }
  else {
    variables = { ear: params?.ear, abdomen: params?.abdomen, behavioralScreening: params?.behavoral, dental: params?.dental, eye: params?.eye, hair: params?.hair, heart: params?.heart, lungs: params?.lungs, learningScreening: params?.learning, skin: params?.skin, throat: params?.throat, vitalSigns: params?.vitalSigns }
    x = "$ear: [String], $abdomen: [String], $behavioralScreening :[String], $dental: [String], $eye: [String], $hair: [String], $heart: [String], $lungs: [String], $learningScreening: [String], $skin: [String], $throat: [String], $vitalSigns: [String]"
  }
  return API.graphql(graphqlOperation(
    `mutation MyMutation(${x}) {
        updateStudent(admissionNumber: "${params.admissionNo}", package: ${params.package}, ${params?.studentBloodGroup && rhtype !== "" ? "bloodGroup: {group: $bloodGroup, rhType: $rhtype}" : ""}, DOB: "${params.dateOfBirth}", firstName: "${params.firstName}", gender: ${params.gender}, lastName: "${params.lastName}", schoolId: "${params?.schoolId}",studentId: "${params.studentId}", class: "${params.studentClass}", fatherContact: {email: "${params.studentFatherEmailid}", mobile: "${params.studentFatherMobileno}"}, fatherName: "${params.studentFatherName}", motherContact: {email: "${params.studentMotherEmailid}", mobile: "${params.studentMotherMobileno}"}, MotherName: "${params.studentMotherName}", section: "${params.studentSection}",screeningPackageDetails: {abdomen: $abdomen, behavioralScreening: $behavioralScreening, dental: $dental, ear: $ear, eye: $eye, hair: $hair, heart: $heart, lungs: $lungs, learningScreening: $learningScreening,skin: $skin, throat: $throat, vitalSigns: $vitalSigns}) {
          DOB
          admissionNumber
          bloodGroup {
            group
            rhType
          }
          class
          createdAt
          fatherContact {
            email
            mobile
          }
          fatherName
          firstName
          gender
          isActive
          lastName
          motherContact {
            email
            mobile
          }
          motherName
          schoolId
          screeningDate
          section
          studentId
          updatedAt
          screeningPackageDetails {
            abdomen
            behavioralScreening
            dental
            ear
            eye
            hair
            heart
            learningScreening
            lungs
            skin
            throat
            vitalSigns
          }
        }
      }`,
    variables, token
  ));
}

function deleteStudent(payload) {

  const token = Cookies.get("tokenId");
  const params = payload?.payload;
  const rhtype = params.studentRhType === "+" ? "Pos" : "Neg";
  return API.graphql({
    query: `mutation MyMutation {
        updateStudent(admissionNumber: "isActive: false, DOB : " ", schoolId: "${params.schoolId.data.schoolId}",studentId: "${params.studentId}")
      }`,
    authToken: token,
  });
}

function UpdateStudentStatus(payload) {

  const token = Cookies.get("tokenId");
  const params = payload?.payload;
  return API.graphql({
    query: `mutation MyMutation {
        updateStudent(schoolId: "${params.schoolId}",studentId: "${params.studentId}", status: "${params.status}") {
          DOB
          status
          admissionNumber
          bloodGroup {
            group
            rhType
          }
          class
          createdAt
          fatherContact {
            email
            mobile
          }
          fatherName
          firstName
          gender
          isActive
          lastName
          motherContact {
            email
            mobile
          }
          motherName
          schoolId
          screeningDate
          section
          studentId
          updatedAt
          screeningPackageDetails {
            abdomen
            behavioralScreening
            dental
            ear
            eye
            hair
            heart
            learningScreening
            lungs
            skin
            throat
            vitalSigns
          }
        }
      }`,
    authToken: token,
  });
}

function addStudentHealthLog(payload) {

  const token = Cookies.get("tokenId");
  const params = payload?.payload;

  return API.graphql({
    query: `mutation MyMutation {
      addLogsToStudent(bloodPressure: "${params?.bp}", diagnosis: "${params?.diagnosis}", heartRate: "${params?.heartRate}", problemFaced: "${params?.problemFaced}", situation: "${params?.situation}", SpO2: "${params?.SpO2}", temperature: "${params?.temp}", schoolId: "${params?.schoolId}", studentId: "${params?.studentId}", email: "${params?.email}",
      height: "${params?.height}", weight: "${params.weight}", infirmary : "${params.infirmary}", infirmaryName : "${params.infirmaryName}" )
    }`,
    authToken: token,
  });
}

function getBatchUpload(payload, callBack) {
  const token = Cookies.get("tokenId");
  return API.graphql(graphqlOperation(
    `query MyQuery(
      ${payload?.nextToken ? `$nt : String` : ``},
      $si : String!
    ){
      getBatchUpload(
        schoolId: $si,
        ${payload?.nextToken ? `nextToken: $nt` : ``}
      ) {
        batchUpload {
          academicYear
          admissionNumber
          DOB
          bloodGroup {
            group

            rhType
          }
          class
          createdAt
          fatherContact {
            mobile
            email
          }
          fatherName
          firstName
          gender
          lastName
          name
          motherContact {
            email
            mobile
          }
          motherName
          schoolId
          section
          status
          reason
        }
        nextToken
      }
    }  
    `,
    { si: payload.schoolId, nt: payload.nextToken },
    token,
  )).then((data) => {
    callBack('success', data?.data?.getBatchUpload)
  }).catch((error) => {
    callBack('failure', error);
  })
}

function notifyClinicParent(payload) {
  console.log(payload);
  const token = Cookies.get("tokenId");
  return API.graphql(graphqlOperation(
    `mutation MyMutation(
      $schoolId : String!,
      $studentId : String!
    ){
      clinicParentSMS(
        schoolId: $schoolId, 
        studentId: $studentId)
    }
    `,
    {
      schoolId: payload.schoolId,
      studentId: payload.studentId
    },
    token
  )).catch((error) => {
    console.log(error);
  })
}

const students = {
  getStudents,
  getStudentByClass,
  getStudentByID,
  getStudentBySchoolId,
  getStudentBySection,
  createStudent,
  updateStudent,
  deleteStudent,
  addStudentHealthLog,
  UpdateStudentStatus,
  getBatchUpload,
  notifyClinicParent
};

export default students;
