import React, { useEffect } from 'react'
import Infirmary from '../../common/InfirmaryTable/Infirmary'
import DashboardLayout from '../../common/DashboardLayout/DashboardLayout'
import { useDispatch, useSelector } from 'react-redux'
import eventReducer from '../../../redux/reducers/event.reducer'
import schoolReducer from '../../../redux/reducers/school.reducer'

export default  function SuperAdminCalendar(){

  const dispatch = useDispatch()
  const getEvents = schoolReducer.actions.getSchoolsRequest
  const data = useSelector((state)=>state.schoolReducer.data)

  useEffect(()=>{
    dispatch(getEvents())
  },[])
  return (
        <Infirmary data={data} />
  )
}
