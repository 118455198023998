import React from "react";
import classes from "../../components/common/StudentTable/StudentRows.module.css";
import {
  capitalizeFirstLetter,
  getAge,
  numberTh,
  stringAvatar,
  toDateString,
  truncateString,
} from "../../utils/utility";
import { Avatar } from "@mui/material";

const AssignStudentRow = ({ data }) => {
  const { firstName, lastName, DOB, gender } = data;
  return (
    <div className={classes.studentRow_container}>
      {/* Name and Avatar Container */}
      <div>
        {/* Avatar Container */}
        <div>
          <Avatar {...stringAvatar(`${firstName} ${lastName}`)} />
        </div>

        {/* Name Container */}
        <div>
          <p>
            {firstName || lastName
              ? capitalizeFirstLetter(
                  truncateString(`${firstName} ${lastName}`, 30)
                )
              : "Unavailable"}
          </p>
        </div>
      </div>

      {/* Age Container */}
      <div style={{marginLeft: '1.4rem'}}>
        <p>{DOB ? `${getAge(DOB)} yrs` : "unavailable"}</p>
      </div>

      {/* Gender Container */}
      <div style={{marginLeft: '1rem'}}>
        <p>{gender ? gender : "unavailable"}</p>
      </div>

      <div>
        {/* <CollapseTable data={data.data} /> */}
        {numberTh(data?.class)}-{data?.section}
      </div>
      {/* Screening Container */}
      <div>
        <p>{toDateString(data?.screeningDate)}</p>
      </div>
    </div>
  );
};

export default AssignStudentRow;
