import React from "react";
import classes from "./Anxiety2.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import clsx from "clsx";

export const Anxiety2 = ({ data }) => {
  const response = JSON.parse(data?.ANXIETY)?.response
  const performanceQuestionBank = [
    {
      qno: 11,
      question: "My child gets stomachaches at school?",
      answer: response?.[10]
    },
    {
      qno: 12,
      question:
        "When my child gets frightened, he/she feels like he/she is going crazy?",
      answer: response?.[11]
    },
    {
      qno: 13,
      question: "My child worries about sleeping alone?",
      answer: response?.[12]
    },
    {
      qno: 14,
      question: "My child worries about being as good as other kids?",
      answer: response?.[13]
    },
    {
      qno: 15,
      question:
        "When he/she gets frightened, he/she feels like things are not real?",
      answer: response?.[14]
    },
    {
      qno: 16,
      question:
        "My child has nightmares about something bad happening to his/her parents?",
      answer: response?.[15]
    },
    {
      qno: 17,
      question: "My child worries about going to school?",
      answer: response?.[16]
    },
    {
      qno: 18,
      question: "When my child gets frightened, his/her heart beats fast?",
      answer: response?.[17]
    },

    {
      qno: 19,
      question: "He/she gets shaky?",
      answer: response?.[18]
    },

    {
      qno: 20,
      question:
        "My child has nightmares about something bad happening to him/her?",
      answer: response?.[19]
    },

    {
      qno: 21,
      question: "My child worries about things working out for him/her?",
      answer: response?.[20]
    },

    {
      qno: 22,
      question: "When my child gets frightened, he/she sweats a lot?",
      answer: response?.[21]
    },

    {
      qno: 23,
      question: "My child is a worrier?",
      answer: response?.[22]
    },

    {
      qno: 24,
      question: "My child gets really frightened for no reason at all?",
      answer: response?.[23]
    },

    {
      qno: 25,
      question: "My child is afraid to be alone in the house?",
      answer: response?.[24]
    },

    {
      qno: 26,
      question: "It is hard for my child to talk with people he/she doesn’t know well?",
      answer: response?.[25]
    },
  ];

  return (
    <>
      <div className={clsx(classes.container, 'xyz')}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>

        <div className={classes.question_container}>
          <div>Assessment Questionnaire Continued...</div>
          <div className={classes.question_select_container}>
            {performanceQuestionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Not true"
                          checked={el.answer === "NOT_TRUE"}
                        />
                        <p style={{ width: "60px" }}>
                          Not true
                        </p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Somewhat true"
                          checked={el.answer === "SOMEWHAT_TRUE"}
                        />
                        <p style={{ width: "60px" }}>
                          Somewhat true
                        </p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Very true"
                          checked={el.answer === "VERY_TRUE"}
                        />
                        <p style={{ width: "55px" }}>Very true</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes.footer_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 2 of 2</p>
          </div>
        </div>
      </div>
    </>
  );
};
