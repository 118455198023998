// export const ReportData = {
//   hair: {
//     overview:
//       "Hair health in children can be an indicator of nutrition, hygiene, and overall well-being. Common issues include scalp infections, hair thinning, and conditions like alopecia areata. Proper hair care and nutrition can help maintain healthy hair.",
//     conditions: [
//       {
//         condition: "Dandruff",
//         prevalence: "Affects about 30% of school-going children in India.",
//         symptoms: "White or yellow flakes on the scalp, itching, dry scalp.",
//         prevention:
//           "Regular hair washing with mild shampoo, ensuring proper scalp hygiene.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include anti-dandruff shampoos, improving scalp hygiene, and using medicated oils.",
//         whenToSeekHelp:
//           "If the dandruff persists, or the child experiences hair loss or intense itching.",
//       },
//       {
//         condition: "Head Lice",
//         prevalence:
//           "Common in school-age children, affecting up to 40% of children in crowded environments.",
//         symptoms: "Itching, visible lice or eggs (nits) on the scalp.",
//         prevention:
//           "Regular hair washing, avoiding sharing personal items like combs, and keeping the scalp clean.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include medicated shampoos, combing out lice/nits, and home treatment.",
//         whenToSeekHelp:
//           "If home treatment does not remove the lice or if the child shows signs of infection (red, swollen scalp).",
//       },
//       {
//         condition: "Alopecia Areata",
//         prevalence: "Affects approximately 2-5% of children in India.",
//         symptoms: "Sudden hair loss in patches on the scalp.",
//         prevention:
//           "There are no guaranteed prevention methods, but maintaining a healthy diet and proper scalp care may help.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include treatment options like topical medications or steroids.",
//         whenToSeekHelp:
//           "If hair loss becomes widespread or if the child experiences emotional distress due to the condition.",
//       },
//     ],
//   },

//   eye: {
//     overview:
//       "Eyes are essential for a child’s development, influencing learning and everyday activities. In India, common pediatric eye issues include refractive errors, conjunctivitis, and amblyopia.",
//     conditions: [
//       {
//         condition: "Refractive Errors",
//         prevalence: "Affects 10-20% of children in India.",
//         symptoms:
//           "Blurry vision, squinting, difficulty seeing distant or close objects, depending on the condition.",
//         prevention:
//           "Regular eye checkups, reduced screen time, and proper lighting for reading.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include glasses, reducing screen time, more outdoor activity, and regular eye check-ups.",
//         whenToSeekHelp:
//           "Sudden changes in vision, frequent headaches, or eye strain.",
//       },
//       {
//         condition: "Conjunctivitis",
//         prevalence: "Common in school-aged children in India.",
//         symptoms:
//           "Redness, itching, discharge, swollen eyelids, or sensitivity to light.",
//         prevention:
//           "Hand hygiene, avoiding face touching, and not sharing personal items.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include medication, and keeping the eyes clean.",
//         whenToSeekHelp:
//           "If symptoms persist, or if there is pain, vision problems, or a high fever.",
//       },
//       {
//         condition: "Amblyopia",
//         prevalence: "Affects 1-2% of children in India.",
//         symptoms: "Reduced vision in one eye, poor depth perception.",
//         prevention:
//           "Early eye screenings and treatment of vision problems in infants and toddlers.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include vision therapy or corrective lenses.",
//         whenToSeekHelp: "If vision does not improve despite treatment.",
//       },
//     ],
//   },

//   ear: {
//     overview:
//       "The ears are vital for hearing and balance, both of which play an essential role in a child’s development, especially language skills. In India, common ear-related conditions include ear infections, impacted earwax, and hearing loss.",
//     conditions: [
//       {
//         condition: "Otitis Media",
//         prevalence: "Affects around 7-10% of children under 5 years in India.",
//         symptoms:
//           "Ear pain, fever, irritability, trouble hearing, or discharge from the ear.",
//         prevention:
//           "Ensure good hygiene, vaccinate against the flu, and avoid second-hand smoke exposure.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include antibiotic treatment, ear drops, or monitoring for fluid buildup.",
//         whenToSeekHelp:
//           "If the child has persistent pain, high fever, or hearing difficulties.",
//       },
//       {
//         condition: "Impacted Earwax",
//         prevalence: "Affects approximately 10-15% of children.",
//         symptoms:
//           "Earache, difficulty hearing, or a feeling of fullness in the ear.",
//         prevention:
//           "Regular ear hygiene, avoiding the use of cotton swabs or other objects in the ear canal.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include ear irrigation or removal by a healthcare professional.",
//         whenToSeekHelp:
//           "If the child experiences pain, hearing loss, or blockage despite home care.",
//       },
//       {
//         condition: "Hearing Loss",
//         prevalence:
//           "Estimated to affect 6 out of every 1,000 children in India.",
//         symptoms:
//           "Delayed speech development, inattentiveness, difficulty understanding speech, or loud speech.",
//         prevention:
//           "Early screening, regular hearing checkups, and protecting the child’s ears from loud noises.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include hearing aids, speech therapy, or cochlear implants in severe cases.",
//         whenToSeekHelp:
//           "If the child shows signs of hearing difficulties or speech delays.",
//       },
//     ],
//   },

//   throat: {
//     overview:
//       "Throat health is important for swallowing, breathing, and vocalization. Common conditions include tonsillitis, pharyngitis, and laryngitis. These conditions are especially common in children attending school or daycare.",
//     conditions: [
//       {
//         condition: "Tonsillitis",
//         prevalence: "Affects around 5-7% of children under 10 in India.",
//         symptoms:
//           "Sore throat, difficulty swallowing, fever, swollen tonsils, bad breath.",
//         prevention:
//           "Good hygiene practices like handwashing, avoiding close contact with sick individuals.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include antibiotic treatment for bacterial tonsillitis or throat gargles for comfort.",
//         whenToSeekHelp:
//           "If the child has difficulty breathing, severe pain, or recurring infections.",
//       },
//       {
//         condition: "Pharyngitis",
//         prevalence: "Affects around 15-20% of children annually.",
//         symptoms:
//           "Pain or scratchiness in the throat, difficulty swallowing, red or swollen throat.",
//         prevention:
//           "Promote hygiene like frequent handwashing and avoiding sharing utensils.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include antibiotic treatment for bacterial tonsillitis or throat gargles for comfort.",
//         whenToSeekHelp:
//           "If the child has difficulty breathing, severe pain, or recurring infections.",
//       },
//     ],
//   },

//   dental: {
//     overview:
//       "Dental health is critical for a child’s overall well-being, particularly for nutrition and speech. Common issues in children include cavities, gum diseases, and dental malocclusion.",
//     conditions: [
//       {
//         condition: "Dental Caries",
//         prevalence: "Affects around 60% of children in India.",
//         symptoms:
//           "Toothache, visible holes or pits in teeth, sensitivity to hot, cold, or sweet foods.",
//         prevention:
//           "Encourage brushing twice a day, limit sugary foods and drinks, and regular dental check-ups.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include fillings, fluoride treatments, and improved dental hygiene practices.",
//         whenToSeekHelp:
//           "If the child complains of tooth pain, visible decay, or has trouble eating.",
//       },
//       {
//         condition: "Gingivitis",
//         prevalence: "Affects around 60% of children in India.",
//         symptoms: "Inflamed or bleeding gums, bad breath, or gum tenderness.",
//         prevention:
//           "Good oral hygiene, regular brushing, and avoiding excessive sugary foods.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include professional cleaning, fluoride treatment, and better oral hygiene practices.",
//         whenToSeekHelp: "If the gums are inflamed, bleeding, or receding.",
//       },
//     ],
//   },
//   heart: {
//     overview:
//       "Heart conditions in children can range from congenital heart defects (present at birth) to acquired conditions like rheumatic heart disease. Early detection and treatment are key to managing these conditions.",
//     conditions: [
//       {
//         condition: "Congenital Heart Defects (CHD)",
//         prevalence:
//           "Affects 8-10 children per 1,000 live births annually in India (approximately 200,000 children).",
//         symptoms:
//           "Rapid breathing, poor feeding, blue tint to skin (cyanosis), poor weight gain.",
//         prevention:
//           "Not preventable, but early detection through screening can improve outcomes.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include medication or surgical intervention.",
//         whenToSeekHelp:
//           "If the child experiences rapid breathing, fainting, or bluish skin.",
//       },
//       {
//         condition: "Rheumatic Heart Disease",
//         prevalence: "Affects about 0.3% of school-age children in India.",
//         symptoms:
//           "Shortness of breath, fatigue, chest pain, joint pain, fever.",
//         prevention:
//           "Timely treatment of streptococcal throat infections, regular medical follow-ups.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include long-term medications or surgery.",
//         whenToSeekHelp:
//           "If the child has recurrent sore throats, joint pain, or signs of heart trouble like breathlessness.",
//       },
//       {
//         condition: "Heart Murmurs",
//         prevalence:
//           "Around 1% of children in India are diagnosed with innocent heart murmurs, and most resolve on their own.",
//         symptoms:
//           "Generally asymptomatic, but may be detected during routine checkups.",
//         prevention: "Regular health checkups to monitor heart function.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Most heart murmurs are harmless and require no treatment.",
//         whenToSeekHelp:
//           "If the child shows signs of fatigue, poor growth, or breathing difficulties.",
//       },
//     ],
//   },

//   lungs: {
//     overview:
//       "Lung health in children is critical, especially in India where air pollution and respiratory infections are common. Common issues include asthma, pneumonia, and bronchitis.",
//     conditions: [
//       {
//         condition: "Asthma",
//         prevalence: "Affects approximately 10-15% of children in India.",
//         symptoms: "Wheezing, coughing, shortness of breath, chest tightness.",
//         prevention:
//           "Avoid triggers like allergens, air pollution, and smoking.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include inhalers, medication, and avoiding asthma triggers.",
//         whenToSeekHelp:
//           "If the child has difficulty breathing, frequent coughing, or an asthma attack.",
//       },
//       {
//         condition: "Pneumonia",
//         prevalence:
//           "Affects approximately 370,000 children annually in India, accounting for 13% of deaths in children under five.",
//         symptoms: "Cough, fever, difficulty breathing, chest pain.",
//         prevention:
//           "Vaccination, good nutrition, and reducing exposure to pollution.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include antibiotics and hospitalization in severe cases.",
//         whenToSeekHelp:
//           "If the child has trouble breathing, high fever, or bluish lips/skin.",
//       },
//       {
//         condition: "Bronchitis",
//         prevalence:
//           "Affects around 8-10% of children, especially during the cold season.",
//         symptoms: "Persistent cough, wheezing, shortness of breath.",
//         prevention: "Avoid exposure to tobacco smoke and other pollutants.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include cough medications and ensuring proper hydration.",
//         whenToSeekHelp:
//           "If symptoms persist for more than a week, or if the child has breathing difficulty.",
//       },
//     ],
//   },

//   abdomen: {
//     overview:
//       "The abdominal area houses vital organs responsible for digestion, metabolism, and excretion. Common abdominal conditions in children include gastroesophageal reflux, constipation, and infections.",
//     conditions: [
//       {
//         condition: "Gastroesophageal Reflux (GERD)",
//         prevalence: "Affects around 5-8% of infants and 1-3% of children.",
//         symptoms:
//           "Frequent vomiting, irritability, feeding difficulties, and coughing.",
//         prevention:
//           "Keep the baby upright during and after feeding, avoid spicy and acidic foods in older children.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include dietary changes, medications, or in severe cases, surgery.",
//         whenToSeekHelp:
//           "If the child has difficulty feeding, persistent vomiting, or signs of dehydration.",
//       },
//       {
//         condition: "Constipation",
//         prevalence: "Affects about 10-20% of children.",
//         symptoms: "Infrequent bowel movements, hard stools, abdominal pain.",
//         prevention:
//           "Ensure a diet high in fiber, adequate hydration, and regular physical activity.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include dietary modifications, increased water intake, and stool softeners if necessary.",
//         whenToSeekHelp:
//           "If the child has severe abdominal pain, blood in stools, or constipation lasting more than two weeks.",
//       },
//       {
//         condition: "Gastroenteritis",
//         prevalence: "Affects approximately 1 in 5 children annually.",
//         symptoms: "Diarrhea, vomiting, abdominal cramps, fever.",
//         prevention:
//           "Good hygiene, safe food practices, and vaccination against rotavirus.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include hydration therapy, anti-nausea medication, and monitoring for dehydration.",
//         whenToSeekHelp:
//           "If the child has severe vomiting, bloody stools, or signs of dehydration.",
//       },
//     ],
//   },

//   heightWeightBMI: {
//     overview:
//       "Monitoring height, weight, and BMI is crucial for assessing growth and nutritional status in children. Common issues include obesity, underweight, and growth disorders.",
//     conditions: [
//       {
//         condition: "Obesity",
//         prevalence: "Affects approximately 18% of children aged 5-19 in India.",
//         symptoms:
//           "Excess body fat, low physical activity levels, and health issues like diabetes.",
//         prevention:
//           "Encourage a balanced diet, regular physical activity, and limit screen time.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include dietary counseling, physical activity plans, and regular monitoring.",
//         whenToSeekHelp:
//           "If the child shows signs of related health issues like diabetes or heart problems.",
//       },
//       {
//         condition: "Underweight",
//         prevalence: "Affects about 20% of children under five in India.",
//         symptoms:
//           "Low energy levels, stunted growth, fatigue, and frequent infections.",
//         prevention:
//           "Ensure a nutritious diet, regular health checkups, and addressing underlying health issues.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include dietary supplements and addressing underlying health issues.",
//         whenToSeekHelp:
//           "If the child shows significant weight loss or fails to gain weight appropriately.",
//       },
//       {
//         condition: "Growth Disorders",
//         prevalence: "Estimated to affect around 6-7% of children.",
//         symptoms: "Stunted growth, delayed development, or failure to thrive.",
//         prevention:
//           "Monitor growth regularly, ensure proper nutrition, and address underlying health issues early.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include hormonal treatments or addressing specific health issues causing the disorder.",
//         whenToSeekHelp:
//           "If the child’s growth seems significantly delayed or irregular compared to peers.",
//       },
//     ],
//   },

//   skin: {
//     overview:
//       "Children are prone to various skin conditions such as eczema, fungal infections, and heat rashes due to their delicate skin.",
//     conditions: [
//       {
//         condition: "Eczema (Atopic Dermatitis)",
//         prevalence: "Affects about 10-15% of children in India.",
//         symptoms:
//           "Red, itchy, and inflamed patches of skin, especially on the face, elbows, and knees.",
//         prevention:
//           "Moisturize skin regularly, avoid irritants such as harsh soaps and allergens.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include topical treatments, moisturizers, and avoiding triggers.",
//         whenToSeekHelp:
//           "If the child has severe itching, skin infection, or widespread rashes.",
//       },
//       {
//         condition: "Fungal Infections",
//         prevalence:
//           "Common in warm and humid climates, affecting around 10% of children.",
//         symptoms:
//           "Itchy, red, scaly patches on the skin, especially in moist areas like feet and groin.",
//         prevention:
//           "Keep skin dry, wear loose-fitting clothing, and avoid sharing personal items.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include antifungal creams and maintaining good hygiene.",
//         whenToSeekHelp:
//           "If the child has persistent or spreading skin lesions, or signs of infection.",
//       },
//       {
//         condition: "Heat Rashes (Prickly Heat)",
//         prevalence:
//           "Common in children during hot, humid weather, affecting approximately 20% of children in India.",
//         symptoms:
//           "Small, itchy bumps on the skin, especially on the neck, chest, and back.",
//         prevention:
//           "Keep the child cool, dress them in breathable fabrics, and ensure proper hydration.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include using calamine lotion or other soothing treatments.",
//         whenToSeekHelp:
//           "If the rash becomes infected, spreads, or the child has a fever.",
//       },
//     ],
//   },

//   anemia: {
//     overview:
//       "Anemia is a condition where there is a lack of red blood cells or hemoglobin, leading to fatigue and weakness. In children, it is often due to iron deficiency or nutritional deficiencies.",
//     conditions: [
//       {
//         condition: "Iron-Deficiency Anemia",
//         prevalence: "Affects around 58% of children under five in India.",
//         symptoms: "Fatigue, pale skin, weakness, shortness of breath.",
//         prevention:
//           "Ensure a diet rich in iron-containing foods such as leafy greens, meat, and fortified cereals.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include iron supplements, dietary changes, and addressing underlying causes.",
//         whenToSeekHelp:
//           "If the child appears pale, has low energy levels, or experiences shortness of breath.",
//       },
//       {
//         condition: "Hemolytic Anemia",
//         prevalence:
//           "Affects around 3-4% of children, especially those with genetic conditions like thalassemia or sickle cell disease.",
//         symptoms: "Fatigue, jaundice, dark urine, rapid heartbeat.",
//         prevention:
//           "Regular checkups, early diagnosis of genetic conditions, and management through diet and medication.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include medication, blood transfusions, or specialized treatments for genetic conditions.",
//         whenToSeekHelp:
//           "If the child has persistent fatigue, jaundice, or other symptoms of anemia.",
//       },
//     ],
//   },

//   behavioralConditions: {
//     overview:
//       "Behavioral conditions in children can affect their emotional well-being and social interactions. Common conditions include ADHD, autism, and anxiety disorders.",
//     conditions: [
//       {
//         condition: "Attention Deficit Hyperactivity Disorder (ADHD)",
//         prevalence: "Affects approximately 5-7% of children in India.",
//         symptoms: "Difficulty focusing, hyperactivity, impulsivity.",
//         prevention:
//           "While ADHD cannot be prevented, early intervention and structured environments can help manage symptoms.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include behavioral therapy, medications, and support in school settings.",
//         whenToSeekHelp:
//           "If the child shows consistent patterns of inattention, hyperactivity, or disruptive behavior.",
//       },
//       {
//         condition: "Autism Spectrum Disorder (ASD)",
//         prevalence: "Affects approximately 1 in 100 children in India.",
//         symptoms:
//           "Delayed speech, difficulty in social interactions, repetitive behaviors.",
//         prevention:
//           "There is no known prevention, but early diagnosis and intervention can help improve outcomes.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include speech therapy, behavioral interventions, and specialized education support.",
//         whenToSeekHelp:
//           "If the child shows delayed speech, social interaction difficulties, or unusual repetitive behaviors.",
//       },
//       {
//         condition: "Anxiety Disorders",
//         prevalence: "Affects around 5-10% of children.",
//         symptoms:
//           "Excessive worry, avoidance of certain situations, difficulty sleeping.",
//         prevention:
//           "Encourage open communication, stress management techniques, and a supportive environment.",
//         careGuidelines:
//           "Consult your SKID pediatrician for further evaluation. Further steps may include therapy, relaxation techniques, and in some cases, medication.",
//         whenToSeekHelp:
//           "If the child experiences frequent or debilitating anxiety that affects daily functioning.",
//       },
//     ],
//   },
// };
// // export const ReportData = {
// //     hair: {
// //         overview: "Hair health in children can be an indicator of nutrition, hygiene, and overall well-being. Common issues include scalp infections, hair thinning, and conditions like alopecia areata. Proper hair care and nutrition can help maintain healthy hair.",
// //         common_conditions: "Hair-related conditions affecting children in India include dandruff, lice infestations, and alopecia areata.",
// //         dandruff: {
// //             prevalence: "Affects about 30% of school-going children in India.",
// //             symptoms: "White or yellow flakes on the scalp, itching, dry scalp.",
// //             prevention: "Regular hair washing with mild shampoo, ensuring proper scalp hygiene.",
// //             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include anti-dandruff shampoos, improving scalp hygiene, and using medicated oils.",
// //             when_to_seek_help: "If the dandruff persists, or the child experiences hair loss or intense itching."
// //         },
// //         head_lice: {
// //             prevalence: "Common in school-age children, affecting up to 40% of children in crowded environments.",
// //             symptoms: "Itching, visible lice or eggs (nits) on the scalp.",
// //             prevention: "Regular hair washing, avoiding sharing personal items like combs, and keeping the scalp clean.",
// //             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include medicated shampoos, combing out lice/nits, and home treatment.",
// //             when_to_seek_help: "If home treatment does not remove the lice or if the child shows signs of infection (red, swollen scalp)."
// //         },
// //         alopecia_areata: {
// //             prevalence: "Affects approximately 2-5% of children in India.",
// //             symptoms: "Sudden hair loss in patches on the scalp.",
// //             prevention: "There are no guaranteed prevention methods, but maintaining a healthy diet and proper scalp care may help.",
// //             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include treatment options like topical medications or steroids.",
// //             when_to_seek_help: "If hair loss becomes widespread or if the child experiences emotional distress due to the condition."
// //         }
// //     },
// //     eye: {
// //         overview: "Eyes are essential for a child’s development, influencing learning and everyday activities. In India, common pediatric eye issues include refractive errors, conjunctivitis, and amblyopia.",
// //         refractive_errors: {
// //             prevalence: "Affects 10-20% of children in India.",
// //             symptoms: "Blurry vision, squinting, difficulty seeing distant or close objects, depending on the condition.",
// //             prevention: "Regular eye checkups, reduced screen time, and proper lighting for reading.",
// //             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include glasses, reducing screen time, more outdoor activity, and regular eye check-ups.",
// //             when_to_seek_help: "Sudden changes in vision, frequent headaches, or eye strain."
// //         },
// //         conjunctivitis: {
// //             prevalence: "Common in school-aged children in India.",
// //             symptoms: "Redness, itching, discharge, swollen eyelids, or sensitivity to light.",
// //             prevention: "Hand hygiene, avoiding face touching, and not sharing personal items.",
// //             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include medication, and keeping the eyes clean.",
// //             when_to_seek_help: "If symptoms persist, or if there is pain, vision problems, or a high fever."
// //         },
// //         amblyopia: {
// //             prevalence: "Affects 1-2% of children in India.",
// //             symptoms: "Reduced vision in one eye, poor depth perception.",
// //             prevention: "Early eye screenings and treatment of vision problems in infants and toddlers.",
// //             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include vision therapy or corrective lenses.",
// //             when_to_seek_help: "If vision does not improve despite treatment."
// //         },
// //     },
// //     ear: {
// //         overview: "The ears are vital for hearing and balance, both of which play an essential role in a child’s development, especially language skills. In India, common ear-related conditions include ear infections, impacted earwax, and hearing loss.",
// //         otitis_media: {
// //             prevalence: "Affects around 7-10% of children under 5 years in India.",
// //             symptoms: "Ear pain, fever, irritability, trouble hearing, or discharge from the ear.",
// //             prevention: "Ensure good hygiene, vaccinate against the flu, and avoid second-hand smoke exposure.",
// //             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include antibiotic treatment, ear drops, or monitoring for fluid buildup.",
// //             when_to_seek_help: "If the child has persistent pain, high fever, or hearing difficulties."
// //         },
// //         impacted_earwax: {
// //             prevalence: "Affects approximately 10-15% of children.",
// //             symptoms: "Earache, difficulty hearing, or a feeling of fullness in the ear.",
// //             prevention: "Regular ear hygiene, avoiding the use of cotton swabs or other objects in the ear canal.",
// //             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include ear irrigation or removal by a healthcare professional.",
// //             when_to_seek_help: "If the child experiences pain, hearing loss, or blockage despite home care."
// //         },
// //         hearing_loss: {
// //             prevalence: "Estimated to affect 6 out of every 1,000 children in India.",
// //             symptoms: "Delayed speech development, inattentiveness, difficulty understanding speech, or loud speech.",
// //             prevention: "Early screening, regular hearing checkups, and protecting the child’s ears from loud noises.",
// //             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include hearing aids, speech therapy, or cochlear implants in severe cases.",
// //             when_to_seek_help: "If the child shows signs of hearing difficulties or speech delays."
// //         },
// //     },
// //     throat: {
// //         overview: "Throat health is important for swallowing, breathing, and vocalization. Common conditions include tonsillitis, pharyngitis, and laryngitis. These conditions are especially common in children attending school or daycare.",
// //         tonsillitis: {
// //             prevalence: "Affects around 5-7% of children under 10 in India.",
// //             symptoms: "Sore throat, difficulty swallowing, fever, swollen tonsils, bad breath.",
// //             prevention: "Good hygiene practices like handwashing, avoiding close contact with sick individuals.",
// //             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include antibiotic treatment for bacterial tonsillitis or throat gargles for comfort.",
// //             when_to_seek_help: "If the child has difficulty breathing, severe pain, or recurring infections."
// //         },
// //         pharyngitis: {
// //             prevalence: "Affects around 15-20% of children annually.",
// //             symptoms: "Pain or scratchiness in the throat, difficulty swallowing, red or swollen throat.",
// //             prevention: "Promote hygiene like frequent handwashing and avoiding sharing utensils.",
// //             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include antibiotic treatment for bacterial tonsillitis or throat gargles for comfort.",
// //             when_to_seek_help: "If the child has difficulty breathing, severe pain, or recurring infections."
// //         }
// //     },
// //     dental: {
// //         overview: "Dental health is critical for a child’s overall well-being, particularly for nutrition and speech. Common issues in children include cavities, gum diseases, and dental malocclusion.",
// //         dental_caries: {
// //             prevalence: "Affects around 60% of children in India.",
// //             symptoms: "Toothache, visible holes or pits in teeth, sensitivity to hot, cold, or sweet foods.",
// //             prevention: "Encourage brushing twice a day, limit sugary foods and drinks, and regular dental check-ups.",
// //             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include fillings, fluoride treatments, and improved dental hygiene practices.",
// //             when_to_seek_help: "If the child complains of tooth pain, visible decay, or has trouble eating."
// //         },
// //         gingivitis: {
// //             prevalence: "Affects around 60% of children in India.",
// //             symptoms: "Toothache, visible holes or pits in teeth, sensitivity to hot, cold, or sweet foods.",
// //             prevention: "Encourage brushing twice a day, limit sugary foods and drinks, and regular dental check-ups.",
// //             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include fillings, fluoride treatments, and improved dental hygiene practices.",
// //             when_to_seek_help: "If the child complains of tooth pain, visible decay, or has trouble eating."
// //         },
// //         dental_caries: {
// //             prevalence: "Affects around 60% of children in India.",
// //             symptoms: "Toothache, visible holes or pits in teeth, sensitivity to hot, cold, or sweet foods.",
// //             prevention: "Encourage brushing twice a day, limit sugary foods and drinks, and regular dental check-ups.",
// //             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include fillings, fluoride treatments, and improved dental hygiene practices.",
// //             when_to_seek_help: "If the child complains of tooth pain, visible decay, or has trouble eating."
// //         },
// //     },
// //     heart: {
// //         overview: "Heart conditions in children can range from congenital heart defects (present at birth) to acquired conditions like rheumatic heart disease. Early detection and treatment are key to managing these conditions."
// //     },
// //     lungs: {
// //         overview: "Lung health in children is critical, especially in India where air pollution and respiratory infections are common. Common issues include asthma, pneumonia, and bronchitis."
// //     },
// //     abdomen: {
// //         overview: "The abdominal area houses vital organs responsible for digestion, metabolism, and excretion. Common abdominal conditions in children include gastroesophageal reflux, constipation, and infections."
// //     },
// //     bmi: {
// //         overview: "Monitoring height, weight, and BMI is crucial for assessing growth and nutritional status in children. Common issues include obesity, underweight, and growth disorders."
// //     }
// // }



export const ReportData = {
  hair: {
    overview:
      "As a parent, it's important to keep an eye on your child’s hair health, as it can reflect their nutrition, hygiene, and overall well-being. Hair is primarily made of a protein called keratin, and it grows from hair follicles in the skin. Interestingly, the average human scalp has about 100,000 hair follicles, and each hair can grow about half an inch per month! Common hair issues in children include dandruff, head lice, and conditions like alopecia areata. Ensuring your child has proper hair care and nutrition is essential for maintaining healthy hair.",
    conditions: [
      {
        condition: "Dandruff",
        info: "Dandruff is something many children face, affecting around 30% of school-going kids in India. If you notice white or yellow flakes on your child's scalp or if they complain of itching and dryness, it may be time to take action. Regular hair washing with a mild shampoo can help, and it's crucial to maintain proper scalp hygiene. If the dandruff persists or if your child experiences hair loss or intense itching, don't hesitate to consult your SKIDS Pediatrician for complete evaluation and further care. They may recommend anti-dandruff shampoos or medicated oils to help with the condition."
      },
      {
        condition: "Head lice or pediculosis",
        info: "Head lice or pediculosis, which often affects children in crowded environments, with prevalence rates as high as 40% among school-age kids. Did you know that head lice can only live on the human scalp and feed on blood? If your child is itching their scalp and you see visible lice or eggs, it's important to address this quickly. To help prevent head lice, encourage your child to wash their hair regularly, avoid sharing personal items like combs, and maintain a clean scalp. If home treatments don’t seem to work or if your child shows signs of infection, such as a red, swollen scalp, be sure to reach out to your SKIDS Pediatrician for complete evaluation and further care."
      },
      {
        condition: "Alopecia areata",
        info: "Alopecia areata (patchy hair loss) , which affects about 2-5% of children in India, can be distressing. This condition leads to sudden hair loss in patches, which might be alarming for both you and your child. While there’s no guaranteed prevention, maintaining a healthy diet and proper scalp care may help. If you notice your child experiencing this type of hair loss, consult your SKIDS Pediatrician for complete evaluation and further care. They may suggest topical medications or steroids for treatment. If the hair loss becomes widespread or if your child is emotionally distressed, it’s crucial to seek help."
      },
    ],
  },

  eye: {
    overview:
      "Your child's eyesight is vital for their development, influencing their learning and everyday activities. Did you know that the human eye can distinguish about 10 million different colors? The eye works by focusing light onto the retina, where light-sensitive cells convert the light into signals sent to the brain. Common pediatric eye issues include refractive errors, conjunctivitis, and amblyopia. Regular check-ups are important to catch any problems early.",
    conditions: [
      {
        condition: "Myopia (Nearsightedness)",
        info: "In myopia, the eyeball is too long or the cornea is too curved, causing distant objects to appear blurry while nearby objects remain clear. Children with myopia may squint to see far objects, like the board at school, or complain of eye strain after trying to focus on distant things. Increasing outdoor time and limiting screen time can help lower the risk of myopia progression. Regular eye exams can detect myopia early, and glasses or contact lenses are commonly used for correction."
      },
      {
        condition: "Hyperopia (Farsightedness)",
        info: "Hyperopia occurs when the eyeball is too short or the cornea is too flat, making close objects blurry, though distant objects may be seen clearly. This can lead to difficulty with reading or close-up tasks, and children may experience eye strain or headaches. Many children outgrow mild hyperopia, but regular eye exams can catch more severe cases that require glasses for correction, especially for reading and schoolwork."
      },
      {
        condition: "Astigmatism",
        info: "This condition results from an irregularly shaped cornea or lens, which distorts vision at all distances. Children with astigmatism may complain of blurry or distorted vision, headaches, or difficulty seeing clearly, especially in low light. Like myopia and hyperopia, astigmatism is corrected with glasses or contact lenses, ensuring your child’s vision develops properly."
      },
      {
        condition: "Gaze Symmetry",
        info: "If you notice that your child’s eyes are not properly aligned or do not move together when they look at objects, they may have gaze asymmetry. This could be a sign of a misalignment in the eye muscles or a condition like strabismus, where one eye turns inward, outward, upward, or downward. Gaze asymmetry can affect depth perception and lead to other vision problems over time. Early intervention is key—treatment might include special glasses, exercises, or surgery depending on the severity. Consult your SKIDS Pediatrician for a complete evaluation and further care."
      },
      {
        condition: "Anisocoria",
        info: "Anisocoria is when your child’s pupils (the dark part of the eye) are not the same size. In some cases, anisocoria can be normal, but if you notice it alongside other symptoms like drooping eyelids, blurred vision, or sensitivity to light, it could indicate a more serious issue affecting the nerves or eye muscles. Early diagnosis is important to rule out any underlying conditions. If you observe a difference in your child’s pupil size, consult your SKIDS Pediatrician for a complete evaluation and further care."
      },
      {
        condition: "Anisometropia",
        info: "Anisometropia is a condition where each of your child’s eyes has a different refractive power, meaning one eye might see more clearly than the other. This can cause discomfort or blurred vision, especially if untreated, as the brain tends to favor the stronger eye, which could lead to amblyopia or “lazy eye.” Regular eye screenings are essential to detect this condition early. Treatment usually involves glasses with different prescriptions for each eye, and in some cases, patching the stronger eye to strengthen the weaker one. Consult your SKIDS Pediatrician for a complete evaluation and further care."
      },
    ],
  },

  ear: {
    overview:
      "Ear health is crucial for your child's ability to hear and maintain balance, both of which are essential for development, particularly in language skills. Did you know that the human ear can detect sounds as quiet as a whisper? In India, common ear-related issues include ear infections, impacted earwax, and hearing loss.",
    conditions: [
      {
        condition: "Otitis media",
        info: "Otitis media, or middle ear infections, affect about 7-10% of children under the age of five in India. If your child complains of ear pain, has a fever, or seems irritable, it may be a sign of an ear infection. To help prevent otitis media, good hygiene practices, vaccinations against the flu, and avoiding exposure to secondhand smoke can make a difference. If you suspect an infection, it’s best to consult your SKIDS Pediatrician for complete evaluation and further care, who may recommend antibiotics or monitor fluid buildup in the ear. If your child experiences persistent pain, high fever, or hearing difficulties, seek medical attention immediately."
      },
      {
        condition: "Otitis Externa",
        info: "Otitis externa, often referred to as 'swimmer's ear,' is an infection of the outer ear canal, typically caused by bacteria or fungi thriving in a moist environment. Children who swim frequently or clean their ears too aggressively with cotton swabs are more prone to this condition. Symptoms include ear pain, itching, swelling, and sometimes discharge. To prevent otitis externa, keep your child’s ears dry after swimming and avoid inserting objects into their ears. If your child complains of ear pain or itching, consult your SKIDS Pediatrician for complete evaluation and further care."
      },
      {
        condition: "Impacted earwax",
        info: "Impacted earwax which affects around 10-15% of children. Did you know that earwax is actually beneficial? It helps trap dirt and debris and has antibacterial properties. Symptoms might include earache, difficulty hearing, or a feeling of fullness in the ear. To prevent this, encourage regular ear hygiene and advise against using cotton swabs or other objects in the ear canal. If you notice symptoms, consult your SKIDS Pediatrician for complete evaluation and further care, who may recommend ear irrigation or removal by a professional. If pain, hearing loss, or blockage persists despite home care, don’t hesitate to seek help."
      },
      {
        condition: "Foreign Body in the Ear Canal",
        info: "Young children, out of curiosity, sometimes insert small objects like beads, buttons, or food into their ears. This can lead to discomfort, pain, and infection if not removed promptly. If you suspect your child has placed something in their ear, avoid trying to remove it yourself, as this could push the object further in or cause injury. Instead, consult your SKIDS Pediatrician for safe removal and to prevent any complications such as infections."
      },
    ],
  },

  throat: {
    overview:
      "Throat health is essential for your child’s ability to swallow, breathe, and speak. Did you know that the throat contains both the esophagus and the trachea, allowing for the passage of food and air? Common throat conditions include tonsillitis, pharyngitis, and laryngitis, which are especially prevalent among children in school or daycare settings.",
      conditions: [
        {
          condition: "Stomatitis",
          info: "Stomatitis refers to inflammation within the mouth, which may cause sores on the gums, cheeks, tongue, or lips. This condition can be caused by viral infections, like hand-foot-mouth disease, or by irritants such as certain foods, medicines, or poor oral hygiene. Stomatitis can make eating uncomfortable for children. To help soothe symptoms, encourage your child to drink plenty of fluids and maintain proper oral hygiene. If your child experiences frequent or painful mouth sores, consult your SKIDS Pediatrician for a complete evaluation and further care."
        },
        {
          condition: "Glossitis",
          info: "Glossitis is an inflammation of the tongue that can make it appear swollen, red, and sometimes smooth. It may cause discomfort or make it difficult for your child to eat or speak. Glossitis can be caused by infections, allergies, or nutritional deficiencies, such as a lack of vitamin B12 or iron. Ensuring your child has a balanced diet and stays hydrated can help prevent this condition. If your child complains of tongue soreness or if you notice swelling, consult your SKIDS Pediatrician for complete evaluation and further care."
        },
        {
          condition: "Pharyngitis",
          info: "Pharyngitis, or a sore throat, affects about 15-20% of children each year. Symptoms include pain or scratchiness in the throat and difficulty swallowing. Encourage your child to practice good hygiene and avoid sharing utensils to prevent this condition. If your child complains of a sore throat, consult your SKIDS Pediatrician for complete evaluation and further care. They may suggest throat lozenges, increased hydration, and medication for pain relief. If the sore throat is accompanied by high fever or lasts longer than a few days, it’s important to seek help."
        },
        {
          condition: "Tonsillitis",
          info: "Tonsillitis is a common issue, affecting around 5-7% of children under 10 in India. If your child has a sore throat, difficulty swallowing, fever, or swollen tonsils, it may be time to consult a doctor. Tonsils play a role in fighting infections, but when they become infected, they can cause discomfort. Good hygiene practices like handwashing and avoiding close contact with sick individuals can help prevent tonsillitis. If you suspect your child has it, your SKIDS Pediatrician can provide a complete evaluation and further care, recommending antibiotics for bacterial cases or throat gargles for comfort. If your child has difficulty breathing, severe pain, or recurrent infections, don’t hesitate to seek medical advice."
        },
        {
          condition: "White Patches (Oral Thrush)",
          info: "White patches inside the mouth, particularly on the tongue, cheeks, or throat, are often a sign of oral thrush, a fungal infection. It is more common in infants and young children, especially those who have been on antibiotics or have weakened immune systems. Oral thrush may cause discomfort during eating or swallowing. Regular oral hygiene can reduce the risk of infection. If you notice white patches in your child’s mouth, consult your SKIDS Pediatrician for evaluation and further care, as treatment may involve antifungal medications."
        },
        {
          condition: "Throat Congestion",
          info: "Throat congestion can cause your child to feel discomfort, irritation, or a 'lump' sensation in the throat. It’s often associated with common colds, allergies, or post-nasal drip. Drinking plenty of fluids, humidifying the air, and encouraging rest can help relieve throat congestion. If the congestion persists, or your child has difficulty breathing or swallowing, consult your SKIDS Pediatrician for a complete evaluation and further care."
        },
      ],
  },

  dental: {
    overview:
      "Dental health is crucial for your child’s overall well-being, impacting their nutrition and speech development. Did you know that enamel, the hard outer layer of teeth, is the hardest substance in the human body? In India, common dental issues include cavities, gum disease, and malocclusion.",
    conditions: [
      {
        condition: "Gingivitis",
        info: "Gum disease, or gingivitis, affects about 10-15% of children and is often caused by plaque buildup. Symptoms may include red, swollen gums or bleeding when brushing. Teaching your child proper brushing and flossing techniques can help prevent this condition. If you notice symptoms, consult your SKIDS Pediatrician for complete evaluation and further care, who may recommend a dental visit for further assessment. If gum disease progresses, it can lead to more serious issues, so it’s important to act early."
      },
      {
        condition: "Dental caries",
        info: "Dental caries, or cavities, are a major concern, affecting around 60% of children in India. Cavities occur when bacteria in the mouth produce acid that erodes tooth enamel. Encourage your child to maintain a good oral hygiene routine, including regular brushing and flossing, and to limit sugary snacks and drinks. If you notice signs of cavities, such as toothache or visible holes in teeth, it’s essential to consult a dental professional. Regular dental check-ups can help prevent cavities and ensure your child’s dental health remains on track."
      },
      {
        condition: "Malocclusion",
        info: "Malocclusion, or misalignment of the teeth, affects around 20-30% of children in India. Proper alignment is crucial for effective chewing and speech. If you notice that your child’s teeth are crooked or that they have difficulty biting or chewing, it may be time for an orthodontic evaluation. Good dental hygiene can help prevent further complications. Consult your SKIDS Pediatrician for complete evaluation and further care, who may recommend orthodontic treatment if needed. Early intervention can make a significant difference in your child’s dental health."
      },
    ],
  },
  heart: {
    overview:
    "Your child's heart is a vital organ responsible for pumping blood throughout their body, supplying oxygen and nutrients essential for growth and development. Did you know that a child's heart beats faster than an adult's, with an average rate of 70 to 120 beats per minute? This increased heart rate supports their higher energy levels and metabolic demands. Common heart issues in children include congenital heart defects, arrhythmias, and heart murmurs.",
    conditions: [
      {
        condition: "Heart murmurs",
        info: "Heart murmurs are common in children and are often harmless, affecting about 50% of children at some point. These sounds are typically caused by blood flowing through the heart. If your pediatrician detects a heart murmur during a check-up, they may monitor it over time. Most murmurs don’t indicate a serious problem, but if your child experiences symptoms like fatigue, shortness of breath, or chest pain, consult your SKIDS Pediatrician for complete evaluation and further care."
      },
      {
        condition: "Arrhythmias",
        info: "Arrhythmias, or irregular heartbeats, can also occur in children. Some arrhythmias are harmless, while others may require attention. A child's heart should have a regular rhythm; if you notice rapid heartbeats, palpitations, or fainting spells, it's essential to seek medical advice. Regular physical activity can promote heart health, but if you suspect an arrhythmia, consult your SKIDS Pediatrician for complete evaluation and further care. They may recommend an electrocardiogram (ECG) to assess heart function."
      },
      {
        condition: "Congenital heart defects",
        info: "Congenital heart defects occur in about 1 in 100 live births, making them the most common type of birth defect. These defects can affect the structure of the heart and how it functions. Signs to watch for include difficulty breathing, fatigue during feeding, or a bluish tint to the skin. To help prevent congenital heart issues, ensure your child receives regular health check-ups. If you notice any concerning symptoms, consult your SKIDS Pediatrician for complete evaluation and further care. Early diagnosis and treatment can significantly improve outcomes."
      },
    ],
  },

  lungs: {
    overview:
      "Healthy lungs are essential for your child's ability to breathe and engage in physical activities. Did you know that the lungs are made up of millions of tiny air sacs called alveoli, which help exchange oxygen and carbon dioxide? Children breathe faster than adults, taking about 20 to 30 breaths per minute. Common lung issues in children include asthma, pneumonia, and bronchitis.",
    conditions: [
      {
        condition: "Dyspnea",
        info: "Dyspnea refers to difficulty breathing or shortness of breath, which can be alarming for both the child and the parent. This can happen due to conditions like asthma, pneumonia, or severe allergies. In more serious cases, dyspnea can signal a respiratory emergency. Make sure your child avoids potential allergens, ensures good hydration, and stays indoors during high pollen seasons if they have asthma. If your child experiences sudden or persistent shortness of breath, consult your SKIDS Pediatrician for complete evaluation and further care. Urgent medical attention may be necessary in severe cases."
      },
      {
        condition: "Wheezing",
        info: "Wheezing is a high-pitched whistling sound that occurs when your child breathes, especially during exhalation. It indicates that the airways are narrowed or obstructed, often due to asthma, allergies, or infections like bronchiolitis. Wheezing can sometimes be mild, but if it happens frequently or is accompanied by breathing difficulty, it’s important to take action. Help your child avoid triggers, such as dust or pollen, and ensure they get enough rest. If wheezing is recurrent or your child is struggling to breathe, consult your SKIDS Pediatrician for complete evaluation and further care. Treatment may include inhalers or other medications."
      },
      {
        condition: "Cough",
        info: "A cough is a common reflex to clear the airway from mucus, irritants, or infection. Children may experience coughing due to colds, allergies, or respiratory infections like bronchitis. While most coughs are harmless, persistent coughing that lasts more than a week or is accompanied by fever, shortness of breath, or chest pain may require attention. Encourage your child to drink fluids, rest, and avoid irritants like smoke or dust. If the cough persists or worsens, consult your SKIDS Pediatrician for complete evaluation and further care."
      },
      {
        condition: "Asthma",
        info: "Asthma is a prevalent condition affecting about 10% of children in India. It can cause wheezing, coughing, chest tightness, and shortness of breath. Environmental triggers like dust, pollen, and smoke can worsen symptoms. Teaching your child to avoid triggers, using inhalers as prescribed, and ensuring regular check-ups can help manage asthma effectively. If your child experiences frequent coughing or wheezing, consult your SKIDS Pediatrician for complete evaluation and further care. They may suggest a personalized asthma management plan, including medications and lifestyle adjustments."
      },
      {
        condition: "Pneumonia",
        info: "Pneumonia affects around 1.5 million children under five in India annually, often resulting from infections. Symptoms include cough, fever, and difficulty breathing. To help prevent pneumonia, ensure your child receives vaccinations and practices good hygiene. If your child shows signs of pneumonia, it’s crucial to consult your SKIDS Pediatrician for complete evaluation and further care. They may recommend antibiotics or other treatments based on the severity of the infection."
      },
      {
        condition: "Bronchitis",
        info: "Bronchitis, often caused by viral infections, can lead to inflammation of the airways and is common among children, especially in the winter months. Symptoms may include a persistent cough, wheezing, and chest discomfort. To support recovery, ensure your child stays hydrated and rests adequately. If symptoms persist for more than a week or worsen, consult your SKIDS Pediatrician for complete evaluation and further care. They can provide appropriate treatment options to help manage symptoms."
      },
    ],
  },

  abdomen: {
    overview:
      "Your child’s abdomen houses vital organs, including the stomach, intestines, liver, and spleen, all crucial for digestion and overall health. Did you know that the digestive system can process food in about 24 to 72 hours? It’s essential to monitor your child’s abdominal health, as common issues include constipation, gastroenteritis, and abdominal pain.",
    conditions: [
      {
        condition: "Stomach Pain",
        info: "Stomach pain is a common complaint in children and can arise from various causes, including indigestion, infections, or even stress. Sometimes, it could be as simple as overeating or eating too fast. Mild stomach pain usually resolves on its own, especially with rest and hydration. However, if the pain is severe, recurrent, or accompanied by vomiting, diarrhea, or fever, it’s a good idea to have it checked. Encourage your child to eat slowly and chew food thoroughly to avoid indigestion. If the pain persists, consult your SKIDS Pediatrician for complete evaluation and further care to rule out any underlying issues."
      },
      {
        condition: "Acidity/Reflux",
        info: "Acidity or acid reflux occurs when stomach acid flows back up into the esophagus, causing discomfort or a burning sensation, especially after meals. This is common in children and can lead to symptoms like heartburn, a sour taste in the mouth, or burping. To help manage it, ensure your child eats smaller meals, avoids lying down immediately after eating, and stays away from spicy or fatty foods. If symptoms are frequent or causing distress, consult your SKIDS Pediatrician for complete evaluation and further care. Medication may be recommended to reduce acid levels."
      },
      {
        condition: "Constipation",
        info: "Constipation happens when your child has difficulty passing stool or has fewer bowel movements than usual. This can be uncomfortable and is often due to a lack of fiber, inadequate water intake, or changes in routine. Encourage your child to drink plenty of water, eat more fruits and vegetables, and maintain a regular bathroom routine. If constipation persists for more than a few days, or if there is pain or blood in the stool, consult your SKIDS Pediatrician for complete evaluation and further care. Your pediatrician may suggest dietary changes or gentle medications to relieve symptoms."
      },
      {
        condition: "Gastroenteritis",
        info: "Gastroenteritis, often referred to as the stomach flu, is a viral infection that affects the stomach and intestines. Symptoms include diarrhea, vomiting, and stomach cramps, impacting about 20% of children each year. To prevent gastroenteritis, practice good hygiene, such as frequent handwashing. If your child shows signs of dehydration, persistent vomiting, or severe abdominal pain, consult your SKIDS Pediatrician for complete evaluation and further care. They may suggest hydration solutions or other treatments to manage symptoms."
      },
    ],
  },

  heightWeightBMI: {
    overview:
      "Monitoring your child's height, weight, and Body Mass Index (BMI) is crucial for assessing their growth and development. Did you know that genetics plays a significant role in determining a child's height, but nutrition and overall health are equally important? Regularly tracking these metrics can help identify potential health issues early on.",
    conditions: [
      {
        condition: "Underweight",
        info: "Underweight affects around 20% of children in India, which can result from poor nutrition, illness, or genetic factors. If your child is consistently below the healthy weight range for their age and height, it's essential to consult your SKIDS Pediatrician for complete evaluation and further care. They can assess your child's dietary habits, physical activity, and overall health to recommend appropriate interventions."
      },
      {
        condition: "Overweight and obesity",
        info: "Overweight and obesity are increasingly common, affecting about 18% of children in India. This condition can lead to various health issues, including diabetes, high blood pressure, and joint problems. Encouraging regular physical activity and healthy eating habits is crucial in preventing obesity. If your child’s BMI is above the healthy range, consult your SKIDS Pediatrician for complete evaluation and further care. They can help create a personalized plan for your child to achieve and maintain a healthy weight."
      },
      {
        condition: "Growth disorders",
        info: "Growth disorders can also occur if a child is not growing as expected. This may manifest as consistently below-average height or weight for their age. Monitoring growth patterns and discussing any concerns with your SKIDS Pediatrician for complete evaluation and further care is essential. They may recommend tests to identify any underlying medical conditions affecting growth and development."
      }
    ],
  },

  skin: {
    overview:
      "The skin is the body’s largest organ and acts as the first line of defense against infections and environmental elements. It plays a crucial role in regulating body temperature and helps protect internal organs, bones, and muscles. Children's skin is more delicate and sensitive than adult skin, which makes it more prone to certain conditions like rashes, allergies, and infections. Proper skin care for children includes keeping their skin clean, moisturized, and protected from extreme temperatures or irritants. Sunscreen should be applied during outdoor activities to prevent sunburn, and it's always important to monitor for any changes in the skin that may require attention.",
    conditions: [
      {
        condition: "Scabies",
        info: "Scabies is a contagious skin condition caused by tiny mites that burrow into the skin, leading to intense itching, especially at night. It often results in small red bumps or blisters, particularly between the fingers, on the wrists, elbows, and around the waist. Scabies spreads easily through close physical contact or sharing clothing and bedding, so early treatment is essential to prevent it from spreading in families or schools. If your child shows signs of scabies, consult your SKIDS Pediatrician for complete evaluation and further care. Your doctor may prescribe medicated creams to eliminate the mites, along with guidelines for washing clothing and bedding to prevent reinfestation."
      },
      {
        condition: "Rashes",
        info: "Rashes can appear due to various reasons, including allergies, infections, heat, or irritants like soaps or detergents. They may show up as red, bumpy, or irritated patches on your child’s skin. While most rashes are harmless and clear up on their own, some may require attention, especially if they’re accompanied by fever or itching. To help soothe a rash, keep the area clean and dry, and avoid potential triggers like harsh chemicals or extreme heat. For persistent or worsening rashes, consult your SKIDS Pediatrician for complete evaluation and further care to determine the cause and best treatment."
      },
      {
        condition: "Blisters",
        info: "Blisters are small, fluid-filled pockets on the skin that can result from friction, burns, or infections. They can occur on hands or feet due to ill-fitting shoes or excessive rubbing, or they may form after a sunburn or contact with hot objects. In most cases, blisters heal on their own when left alone, but it’s important to keep the area clean and dry to prevent infection. If a blister pops, gently clean the area and cover it with a bandage. For large or painful blisters, or if signs of infection appear (such as redness or pus), consult your SKIDS Pediatrician for complete evaluation and further care."
      },
      {
        condition: "Urticaria (Hives)",
        info: "Urticaria, commonly known as hives, appears as raised, itchy welts on the skin. These welts can vary in size and may come and go quickly. Hives are often triggered by allergic reactions to food, medications, insect bites, or even temperature changes. In most cases, hives resolve on their own, but antihistamines can help relieve itching. It’s important to monitor for any signs of difficulty breathing or swelling of the lips and face, as these may indicate a more serious allergic reaction. If hives persist or are accompanied by other symptoms, consult your SKIDS Pediatrician for complete evaluation and further care to address any underlying allergies or conditions."
      },
      {
        condition: "Eczema",
        info: "Eczema, also known as atopic dermatitis, is a chronic skin condition that often starts in childhood. It causes dry, red, itchy patches that may become cracked or weepy if scratched. Eczema usually appears on the face, elbows, knees, and wrists. While the exact cause isn’t fully understood, eczema is often linked to a family history of allergies or asthma. Moisturizing the skin regularly, avoiding harsh soaps, and steering clear of triggers (like certain fabrics or foods) can help manage eczema. If the condition worsens or causes significant discomfort, consult your SKIDS Pediatrician for complete evaluation and further care. In some cases, medicated creams may be needed to reduce inflammation and control symptoms."
      },
      {
        condition: "Impetigo",
        info: "Impetigo is a contagious skin infection caused by bacteria, usually affecting the face, hands, and other exposed areas. It often starts as small red sores that quickly burst, leaving honey-colored crusts. It’s common in young children, especially in crowded environments like schools or daycare centers. Good hygiene, such as washing hands frequently and keeping cuts and scrapes clean, can help prevent impetigo. If your child develops this condition, consult your SKIDS Pediatrician for complete evaluation and further care. Antibiotic creams or oral medications may be prescribed to clear the infection and prevent it from spreading to others."
      },
      {
        condition: "Wart",
        info: "Warts are small, rough bumps on the skin caused by the human papillomavirus (HPV). They commonly appear on the hands, fingers, knees, or feet and can spread through direct contact or sharing personal items like towels. While warts are usually harmless and may disappear on their own, they can be bothersome or spread to other parts of the body. Over-the-counter treatments, such as salicylic acid, can be used to remove warts, but if they persist or cause discomfort, consult your SKIDS Pediatrician for complete evaluation and further care. A doctor may recommend freezing or other methods to remove stubborn warts safely."
      },
    ],
  },

  anemia: {
    overview:
      "Anemia is a condition where your child’s blood lacks enough healthy red blood cells to carry adequate oxygen to the body's tissues. Did you know that iron-deficiency anemia is the most common form of anemia in children, affecting about 30% of young children in India? This condition can lead to fatigue, weakness, and developmental delays, so it's essential to be vigilant about your child’s nutritional intake. Similarly there can be folate deficiency anemia and B12 deficiency anemia . consult your SKIDS Pediatrician for complete evaluation and further care. They can recommend dietary changes or supplements as needed."
  },

  behavioralConditions: {
    overview:
      "Behavioral conditions in children can affect their emotional well-being and social interactions. Common conditions include ADHD, autism, and anxiety disorders.",
    conditions: [
      {
        condition: "Attention Deficit Hyperactivity Disorder (ADHD)",
        prevalence: "Affects approximately 5-7% of children in India.",
        symptoms: "Difficulty focusing, hyperactivity, impulsivity.",
        prevention:
          "While ADHD cannot be prevented, early intervention and structured environments can help manage symptoms.",
        careGuidelines:
          "Consult your SKID pediatrician for further evaluation. Further steps may include behavioral therapy, medications, and support in school settings.",
        whenToSeekHelp:
          "If the child shows consistent patterns of inattention, hyperactivity, or disruptive behavior.",
      },
      {
        condition: "Autism Spectrum Disorder (ASD)",
        prevalence: "Affects approximately 1 in 100 children in India.",
        symptoms:
          "Delayed speech, difficulty in social interactions, repetitive behaviors.",
        prevention:
          "There is no known prevention, but early diagnosis and intervention can help improve outcomes.",
        careGuidelines:
          "Consult your SKID pediatrician for further evaluation. Further steps may include speech therapy, behavioral interventions, and specialized education support.",
        whenToSeekHelp:
          "If the child shows delayed speech, social interaction difficulties, or unusual repetitive behaviors.",
      },
      {
        condition: "Anxiety Disorders",
        prevalence: "Affects around 5-10% of children.",
        symptoms:
          "Excessive worry, avoidance of certain situations, difficulty sleeping.",
        prevention:
          "Encourage open communication, stress management techniques, and a supportive environment.",
        careGuidelines:
          "Consult your SKID pediatrician for further evaluation. Further steps may include therapy, relaxation techniques, and in some cases, medication.",
        whenToSeekHelp:
          "If the child experiences frequent or debilitating anxiety that affects daily functioning.",
      },
    ],
  },
};
// export const ReportData = {
//     hair: {
//         overview: "Hair health in children can be an indicator of nutrition, hygiene, and overall well-being. Common issues include scalp infections, hair thinning, and conditions like alopecia areata. Proper hair care and nutrition can help maintain healthy hair.",
//         common_conditions: "Hair-related conditions affecting children in India include dandruff, lice infestations, and alopecia areata.",
//         dandruff: {
//             prevalence: "Affects about 30% of school-going children in India.",
//             symptoms: "White or yellow flakes on the scalp, itching, dry scalp.",
//             prevention: "Regular hair washing with mild shampoo, ensuring proper scalp hygiene.",
//             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include anti-dandruff shampoos, improving scalp hygiene, and using medicated oils.",
//             when_to_seek_help: "If the dandruff persists, or the child experiences hair loss or intense itching."
//         },
//         head_lice: {
//             prevalence: "Common in school-age children, affecting up to 40% of children in crowded environments.",
//             symptoms: "Itching, visible lice or eggs (nits) on the scalp.",
//             prevention: "Regular hair washing, avoiding sharing personal items like combs, and keeping the scalp clean.",
//             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include medicated shampoos, combing out lice/nits, and home treatment.",
//             when_to_seek_help: "If home treatment does not remove the lice or if the child shows signs of infection (red, swollen scalp)."
//         },
//         alopecia_areata: {
//             prevalence: "Affects approximately 2-5% of children in India.",
//             symptoms: "Sudden hair loss in patches on the scalp.",
//             prevention: "There are no guaranteed prevention methods, but maintaining a healthy diet and proper scalp care may help.",
//             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include treatment options like topical medications or steroids.",
//             when_to_seek_help: "If hair loss becomes widespread or if the child experiences emotional distress due to the condition."
//         }
//     },
//     eye: {
//         overview: "Eyes are essential for a child’s development, influencing learning and everyday activities. In India, common pediatric eye issues include refractive errors, conjunctivitis, and amblyopia.",
//         refractive_errors: {
//             prevalence: "Affects 10-20% of children in India.",
//             symptoms: "Blurry vision, squinting, difficulty seeing distant or close objects, depending on the condition.",
//             prevention: "Regular eye checkups, reduced screen time, and proper lighting for reading.",
//             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include glasses, reducing screen time, more outdoor activity, and regular eye check-ups.",
//             when_to_seek_help: "Sudden changes in vision, frequent headaches, or eye strain."
//         },
//         conjunctivitis: {
//             prevalence: "Common in school-aged children in India.",
//             symptoms: "Redness, itching, discharge, swollen eyelids, or sensitivity to light.",
//             prevention: "Hand hygiene, avoiding face touching, and not sharing personal items.",
//             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include medication, and keeping the eyes clean.",
//             when_to_seek_help: "If symptoms persist, or if there is pain, vision problems, or a high fever."
//         },
//         amblyopia: {
//             prevalence: "Affects 1-2% of children in India.",
//             symptoms: "Reduced vision in one eye, poor depth perception.",
//             prevention: "Early eye screenings and treatment of vision problems in infants and toddlers.",
//             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include vision therapy or corrective lenses.",
//             when_to_seek_help: "If vision does not improve despite treatment."
//         },
//     },
//     ear: {
//         overview: "The ears are vital for hearing and balance, both of which play an essential role in a child’s development, especially language skills. In India, common ear-related conditions include ear infections, impacted earwax, and hearing loss.",
//         otitis_media: {
//             prevalence: "Affects around 7-10% of children under 5 years in India.",
//             symptoms: "Ear pain, fever, irritability, trouble hearing, or discharge from the ear.",
//             prevention: "Ensure good hygiene, vaccinate against the flu, and avoid second-hand smoke exposure.",
//             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include antibiotic treatment, ear drops, or monitoring for fluid buildup.",
//             when_to_seek_help: "If the child has persistent pain, high fever, or hearing difficulties."
//         },
//         impacted_earwax: {
//             prevalence: "Affects approximately 10-15% of children.",
//             symptoms: "Earache, difficulty hearing, or a feeling of fullness in the ear.",
//             prevention: "Regular ear hygiene, avoiding the use of cotton swabs or other objects in the ear canal.",
//             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include ear irrigation or removal by a healthcare professional.",
//             when_to_seek_help: "If the child experiences pain, hearing loss, or blockage despite home care."
//         },
//         hearing_loss: {
//             prevalence: "Estimated to affect 6 out of every 1,000 children in India.",
//             symptoms: "Delayed speech development, inattentiveness, difficulty understanding speech, or loud speech.",
//             prevention: "Early screening, regular hearing checkups, and protecting the child’s ears from loud noises.",
//             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include hearing aids, speech therapy, or cochlear implants in severe cases.",
//             when_to_seek_help: "If the child shows signs of hearing difficulties or speech delays."
//         },
//     },
//     throat: {
//         overview: "Throat health is important for swallowing, breathing, and vocalization. Common conditions include tonsillitis, pharyngitis, and laryngitis. These conditions are especially common in children attending school or daycare.",
//         tonsillitis: {
//             prevalence: "Affects around 5-7% of children under 10 in India.",
//             symptoms: "Sore throat, difficulty swallowing, fever, swollen tonsils, bad breath.",
//             prevention: "Good hygiene practices like handwashing, avoiding close contact with sick individuals.",
//             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include antibiotic treatment for bacterial tonsillitis or throat gargles for comfort.",
//             when_to_seek_help: "If the child has difficulty breathing, severe pain, or recurring infections."
//         },
//         pharyngitis: {
//             prevalence: "Affects around 15-20% of children annually.",
//             symptoms: "Pain or scratchiness in the throat, difficulty swallowing, red or swollen throat.",
//             prevention: "Promote hygiene like frequent handwashing and avoiding sharing utensils.",
//             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include antibiotic treatment for bacterial tonsillitis or throat gargles for comfort.",
//             when_to_seek_help: "If the child has difficulty breathing, severe pain, or recurring infections."
//         }
//     },
//     dental: {
//         overview: "Dental health is critical for a child’s overall well-being, particularly for nutrition and speech. Common issues in children include cavities, gum diseases, and dental malocclusion.",
//         dental_caries: {
//             prevalence: "Affects around 60% of children in India.",
//             symptoms: "Toothache, visible holes or pits in teeth, sensitivity to hot, cold, or sweet foods.",
//             prevention: "Encourage brushing twice a day, limit sugary foods and drinks, and regular dental check-ups.",
//             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include fillings, fluoride treatments, and improved dental hygiene practices.",
//             when_to_seek_help: "If the child complains of tooth pain, visible decay, or has trouble eating."
//         },
//         gingivitis: {
//             prevalence: "Affects around 60% of children in India.",
//             symptoms: "Toothache, visible holes or pits in teeth, sensitivity to hot, cold, or sweet foods.",
//             prevention: "Encourage brushing twice a day, limit sugary foods and drinks, and regular dental check-ups.",
//             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include fillings, fluoride treatments, and improved dental hygiene practices.",
//             when_to_seek_help: "If the child complains of tooth pain, visible decay, or has trouble eating."
//         },
//         dental_caries: {
//             prevalence: "Affects around 60% of children in India.",
//             symptoms: "Toothache, visible holes or pits in teeth, sensitivity to hot, cold, or sweet foods.",
//             prevention: "Encourage brushing twice a day, limit sugary foods and drinks, and regular dental check-ups.",
//             care_guidelines: "Consult your SKID pediatrician for further evaluation. Further steps may include fillings, fluoride treatments, and improved dental hygiene practices.",
//             when_to_seek_help: "If the child complains of tooth pain, visible decay, or has trouble eating."
//         },
//     },
//     heart: {
//         overview: "Heart conditions in children can range from congenital heart defects (present at birth) to acquired conditions like rheumatic heart disease. Early detection and treatment are key to managing these conditions."
//     },
//     lungs: {
//         overview: "Lung health in children is critical, especially in India where air pollution and respiratory infections are common. Common issues include asthma, pneumonia, and bronchitis."
//     },
//     abdomen: {
//         overview: "The abdominal area houses vital organs responsible for digestion, metabolism, and excretion. Common abdominal conditions in children include gastroesophageal reflux, constipation, and infections."
//     },
//     bmi: {
//         overview: "Monitoring height, weight, and BMI is crucial for assessing growth and nutritional status in children. Common issues include obesity, underweight, and growth disorders."
//     }
// }
