import * as React from "react";

const BmiScale = (props) => (
    <div

        style={{
            margin: `${props?.margin??''}`,
            width: `${props?.size ?? 50}px`
        }}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 47.5 24"
            fill="none"
            {...props}
        >
            <path
                fill="#FC1024"
                d="M47.205 23.974a23.603 23.603 0 0 0-47.205 0h5.9a17.702 17.702 0 0 1 35.405 0h5.9Z"
            />
            <path
                fill="#FFBE00"
                d="M12.171 3.324a23.603 23.603 0 0 0-12.17 20.72l5.9-.017a17.702 17.702 0 0 1 9.128-15.54l-2.858-5.163Z"
            />
            <path
                fill="#42C134"
                d="M35.404 3.533a23.602 23.602 0 0 0-23.55-.03l2.937 5.118a17.702 17.702 0 0 1 17.663.022l2.95-5.11Z"
            />
            {props.BMI < 18.5
                ? <path
                    fill="#272525"
                    d="m6.859 12.876 17.788 8.877-1.206 2.09L6.859 12.876Z"
                />
                : props.BMI >= 25
                    ? <path d="M40.6523 13.625L23.335 23.5187L22.2639 21.3477L40.6523 13.625Z" fill="#272525" />
                    : <path d="M24.2353 3.41016L25.451 23.4047H23.0196L24.2353 3.41016Z" fill="#272525" />
            }
            <path
                fill="#272525"
                d="M24.321 21.444a1.311 1.311 0 1 1-1.311 2.271 1.311 1.311 0 0 1 1.311-2.271Z"
            />
        </svg>
    </div>
)
export default BmiScale
