import React from "react";
import classes from "./Depression2.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import mcneil from "../../../assets/images/McNeil.svg";
import nichq from "../../../assets/images/NICHQ.svg";
import americanPediatrics from "../../../assets/images/americanPediatrics.svg";
import clsx from "clsx";


export const Depression2 = () => {

  return (
    <>
      <div className={clsx(classes.container, 'xyz')}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>

        <div className={classes.method_framework}>Method Framework</div>

        <div className={classes.certification_container}>
          <img src={americanPediatrics} alt="americanPediatrics" />
          <img src={nichq} alt="nichq" />
          <img src={mcneil} alt="mcneil" />
        </div>

        <div className={classes.footer_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 2 of 2</p>
          </div>
        </div>
      </div>
    </>
  );
};
