import { DoctorInfoCard } from '../DoctorValidation/DoctorInfoCard';
import BreadCrumbs from '../common/TableComponents/BreadCrumbs';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PatientRows from '../DoctorValidation/PatientRow';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import validationReducer from '../../redux/reducers/validation.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import classes from './Phydoctor.module.css'
import { useParams } from 'react-router-dom';

const PhydoctorStudents = () => {
    const dispatch = useDispatch();
    const { schoolId } = useParams();
    const getDoctorDashboard = validationReducer.actions.getDoctorValidation;
    const user = useSelector((state) => state.userReducer.currentUser);
    const dataRes = useSelector((state) => state.validationReducer.data);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const [status, setStatus] = useState("Pending")
    const [dueDate, setDueDate] = useState({ value: "Latest" })
    const [displayData, setDisplayData] = useState([])
    const [data, setData] = useState([])

    useEffect(() => {
        dispatch(getDoctorDashboard({ doctorId: user?.userId }))
    }, [user])

    useEffect(() => {
        if (dataRes.length)
            setData(dataRes.
                filter((validation) => validation?.schoolId === schoolId));
    }, [dataRes])

    useEffect(() => {
        if (Array.isArray(data) && data.length) {
            const y = data?.slice()?.sort((a, b) => {
                return b?.createdAt?.localeCompare(a?.createdAt)
            })
            const x = y?.filter(z => z?.validated === false)
            setDisplayData(x)
            setStatus("Pending")
            let newValue = { value: 'Latest' }
            setDueDate(newValue)
        }
    }, [data])

    useEffect(() => {
        let sorted
        if (Array.isArray(data)) {
            console.log("1");
            setDueDate("");
            if (status === "Pending") {
                sorted = data?.filter((x) => x?.validated === false)
                const y = sorted?.slice()?.sort((a, b) => {
                    return b?.createdAt.localeCompare(a?.createdAt)
                })
                setDisplayData(y)
            }
            else {
                sorted = data?.filter(x => x?.validated)
                const y = sorted?.slice()?.sort((a, b) => {
                    return b?.updatedAt.localeCompare(a?.updatedAt)
                })
                setDisplayData(y)
            }

            // const y = sorted?.slice()?.sort((a, b) => {
            //   return b?.createdAt.localeCompare(a?.createdAt)
            // })
            // setDisplayData(y)
            console.log('hello');
            let newValue = { value: 'Latest' }
            setDueDate(newValue)
        }
    }, [status])

    useEffect(() => {
        // if (!displayData.length) return;
        console.log('hello');
        const newData = [...displayData];
        console.log(newData);
        if (dueDate.value == "Latest") {
            newData.sort((a, b) => a.validationDueDate > b.validationDueDate ? -1 : 1)
            setDisplayData(newData)
        }
        else {
            newData.sort((a, b) => b.validationDueDate < a.validationDueDate ? 1 : -1)
            setDisplayData(newData)
        }
    }, [dueDate])

    const getTotalStudents = () => {
        let x
        if (Array.isArray(data)) {
            x = data?.filter((a) => a.validated === true)
        }
        return `${x?.length}/${data?.length}`
    }

    return (
        <>
            <BreadCrumbs />
            <div className={classes.infocard_container}>
                <DoctorInfoCard
                    title={`Validation Data`}
                    entity={"School"}
                    subtitle={"Class 1 - Class 12 (All Sections)"}
                    totalText={"Validation Progress"}
                    totalCount={"4 "}
                    totalPupilCount={getTotalStudents()}
                    live={"Live"}
                    data={data}
                />

            </div>
            <div className={classes.header_text}>Real Time Activity</div>
            <div className={classes.main_div}>
                <div className={classes.student_filters}>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={rowsPerPage}
                            label="Rows"
                            onChange={(e) => setRowsPerPage(e.target.value)}
                            size='small'
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            label="Rows"
                            onChange={(e) => setStatus(e.target.value)}
                            size='small'
                        >
                            <MenuItem value={"Pending"}>Pending</MenuItem>
                            <MenuItem value={"Validated"}>Validated</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={dueDate.value}
                            label="Rows"
                            onChange={(e) => {
                                let newValue = { value: e.target.value }
                                setDueDate(newValue)
                            }}
                            size='small'
                        >
                            <MenuItem value={"Latest"}>Latest</MenuItem>
                            <MenuItem value={"Oldest"}>Oldest</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {/* Student Table Header */}
                <div className={classes.student_table_header}>
                    <div>PATIENT NAME</div>
                    <div>AGE</div>
                    <div>GENDER</div>
                    <div>SCREENING DATE</div>
                    <div>VALIDATION DUE DATE</div>
                    <div>STATUS</div>
                    <div>ACTION</div>
                </div>
                {/* Student Column-Rows Section */}
                <div id="schoolRowCy" className={classes.student_column_container}>
                    {
                        Array.isArray(displayData) && displayData
                            ?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo).map((el, index) => {
                                return (
                                    <PatientRows data={el} key={index + Date.now()} index={index} />
                                )
                            })
                    }
                </div>
                <div className={classes.pagination_container}>
                    <div className={classes.page_item}>{`Showing ${rowsPerPage * (pageNo - 1) + 1
                        } to ${pageNo * rowsPerPage <= displayData?.length
                            ? pageNo * rowsPerPage
                            : displayData?.length
                        } of ${displayData?.length} items`}</div>
                    <div className={classes.pagination}>
                        <Button
                            size="small"
                            disabled={pageNo === 1}
                            onClick={() => setPageNo(pageNo - 1)}
                        >
                            <ChevronLeft />
                        </Button>
                        <span>{pageNo}</span>
                        <div>
                            <span>of</span>
                            <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
                        </div>

                        <div>
                            <Button
                                size="small"
                                disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)}
                                onClick={() => setPageNo(pageNo + 1)}
                            >
                                <ChevronRight />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PhydoctorStudents