import React, { useEffect, useState } from 'react'
import classes from "./DentalDoctor.module.css"
import InputDashboard from '../common/InputDashboard/InputDashboard'
import { Avatar, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import FilterListIcon from '@mui/icons-material/FilterList';
import { getAge, numberTh, stringAvatar } from '../../utils/utility'
import BreadCrumbs from '../common/TableComponents/BreadCrumbs'
import { useSelector } from 'react-redux';
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Button } from "@mui/material";


const DentalDoctorStudents = () => {
    const [studentList, setStudentList] = useState([])
    const [selectedClass, setSelectedClass] = useState("all");
    const [classesL, setClassesL] = useState([]);
    const [rows, setRows] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const validatedLocal = useSelector((state) => state.validationReducer?.validatedStudents);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setStudentList(location.state.students)
        setRows(location.state.students);
        // console.log(Array.from(new Set(location.state.students.map((student) => student?.class))));
        setClassesL(Array.from(new Set(location.state.students.map((student) => student?.class))));
    }, []);



    const handleStartValidation = (patientData) => {
        navigate(`/doctor-dentalvalidation/${patientData?.patientName}`, {
            state: {
                patientData: patientData,
                breadCrumbs: [...location?.state?.breadCrumbs, patientData?.patientName]
            }
        });
    }

    const handleSearchStudent = (e) => {
        let typed = e.target.value
        if (typed !== "") {
            setStudentList(rows.filter((i) => i.patientName.toLowerCase().includes(typed.toLowerCase())))
        } else {
            setStudentList(rows)
        }
    }

    const classSelector = (e) => {
        
        setSelectedClass(e.target.value)
       
        if (e.target.value === 'all') {
            setStudentList(rows);
        } else {
            setStudentList(rows.filter((std) => std.class === e.target.value))
        }
    }

    return <div>
        <BreadCrumbs />
        <br />
        <div className={classes.topFilter}>
            <FormControl>
                <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rowsPerPage}
                    label="Rows"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                    size='small'
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ width: "100px" }} >
                <InputLabel id="demo-simple-select-label">Class</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedClass}
                    label="Class"
                    onChange={classSelector}
                    size='small'
                >
                    <MenuItem value={"all"}>All</MenuItem>
                    {
                        classesL.map((cl) => {
                            return <MenuItem value={cl}>{cl}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
            <InputDashboard
                height={"auto"}
                width={"27vw"}
                inputSize={"small"}
                placeholder={"Search Student"}
                change={handleSearchStudent}
            />
            <FilterListIcon sx={{ marginLeft: "20px" }} />
        </div>
        <br />
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{ height: "70px" }}>
                    <TableRow>
                        <TableCell>STUDENT NAME</TableCell>
                        <TableCell>CLASS</TableCell>
                        <TableCell align="right">AGE</TableCell>
                        <TableCell align="right">GENDER</TableCell>
                        <TableCell align="right">STATUS</TableCell>
                        <TableCell align="right">ACTION</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {studentList
                        ?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo).map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    'th': {
                                        height: '70px'
                                    }
                                }}
                                className={classes.studentRows}
                            >
                                <TableCell component="th" scope="row">
                                    <span>
                                        <Avatar {...stringAvatar(row?.patientName)} />
                                        {row?.patientName}
                                    </span>
                                </TableCell>
                                <TableCell align="right">{numberTh(row?.class)}</TableCell>
                                <TableCell align="right">{getAge(row?.DOB)} yrs</TableCell>
                                <TableCell align="right">{row?.gender}</TableCell>
                                <TableCell align="right">
                                    <button className={`${classes.viewSchoolBtn} ${validatedLocal.includes(row.studentId) && classes.greenBtn}`}>
                                        {validatedLocal.includes(row.studentId) ? "VALIDATION DONE" : "VALIDATION PENDING"}
                                    </button>
                                </TableCell>
                                <TableCell align="right">
                                    <button
                                        disabled={validatedLocal.includes(row.studentId) ? true : false}
                                        onClick={() => handleStartValidation(row)}
                                        className={`${classes.action} ${validatedLocal.includes(row.studentId) && classes.dataValidate}`}>
                                        {
                                            validatedLocal.includes(row.studentId)
                                                ? "validated"
                                                : "Start validation"
                                        }
                                    </button>
                                </TableCell>

                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
        <div className={classes.pagination_container}>
            <div className={classes.page_item}>{`Showing ${rowsPerPage * (pageNo - 1) + 1
                } to ${pageNo * rowsPerPage <= studentList?.length
                    ? pageNo * rowsPerPage
                    : studentList?.length
                } of ${studentList?.length} items`}</div>
            <div className={classes.pagination}>
                <Button
                    size="small"
                    disabled={pageNo === 1}
                    onClick={() => setPageNo(pageNo - 1)}
                >
                    <ChevronLeft />
                </Button>
                <span>{pageNo}</span>
                <div>
                    <span>of</span>
                    <span>{Math.ceil(studentList?.length / rowsPerPage)}</span>
                </div>

                <div>
                    <Button
                        size="small"
                        disabled={pageNo === Math.ceil(studentList?.length / rowsPerPage)}
                        onClick={() => setPageNo(pageNo + 1)}
                    >
                        <ChevronRight />
                    </Button>
                </div>
            </div>
        </div>
    </div>
}

export default DentalDoctorStudents