import { API } from "aws-amplify";
import Cookies from "js-cookie";

function shareTypeformQuery(payload) {
  const token = Cookies.get("tokenId");
  const payloadJoin = payload?.payload.join();
  
  return API.graphql({
    query: `query MyQuery {
            sendEmailToSchool(mailAddress: "${payloadJoin}"){
              message
              status
            }
          }`,
    authToken: token,
  });
}

function sendParentsTypeform(payload) {
    const token = Cookies.get('tokenId')
    const params = payload?.payload
    if(params.email?.length>0){
      return API.graphql({
        query: `mutation MyMutation {
          sendTypeform(email: "${params.email}", name: "${params.name}", screeningId: "${params.screeningId}")
        }`,
    authToken: token,
  });
    }
    return API.graphql({
        query: `mutation MyMutation {
            sendTypeform(email: "${params.email ?? ''}", name: "${params.name ?? ''}", class: "${params.class ?? ''}", schoolId: "${params.schoolId ?? ''}", section: "${params.section ?? ''}")
          }`,
    authToken: token,
  });
}

const typeform = {
    shareTypeformQuery,
    sendParentsTypeform
}

export default typeform