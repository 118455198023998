import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./SchoolStudent.module.css";
import SchoolStudentRow from "./SchoolStudentRow";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../TableComponents/BreadCrumbs";
import TabButton from "../../TableComponents/TabButton";
import SearchBar from '../../TableComponents/SearchBar'
import { City } from "country-state-city";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CustomAutoComplete from "../../CustomAutoComplete/CustomAutoComplete";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import Cookies from "js-cookie";
import { Filter } from "../../TableComponents/Filter";



function SchoolStudentPage() {
  const token = Cookies.get("tokenId");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const breadCrumbs = [
    "D2C Logs"
  ]

  const data = [{
    username : ""
  }]

  function getWordBeforeDash() {
    let newStr = "";
    for (let i = 1; i < location.pathname.length; i++) {
      if (location.pathname[i] === "-") {
        return newStr;
      }
      newStr += location.pathname[i];
    }
  }

  const allCities = [...new Set(City.getCitiesOfCountry("IN").map((x)=> x.name))]

  const [open, setOpen] = useState(false);
  const [sec, setSec] = useState(false);
  const [text, setText] = useState("");
  const [displayData, setDisplayData] = useState(data ?? []);
  const [city, setCity] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageNo, setPageNo] = useState(1)




  return (
    <div className= {classes.main_div}>
    <div className={classes.student_layout}>
      <div>
        <BreadCrumbs/>
      </div>
    </div>
    <div className= {classes.student_filters}>
    <div className = {classes.show_text}>Show</div>
    <FormControl>
      <InputLabel id="demo-simple-select-label">Rows</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={rowsPerPage}
        label="Rows"
        onChange={(e)=>setRowsPerPage(e.target.value)}
        size='small'
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={50}>50</MenuItem>
      </Select>
    </FormControl>
      <CustomAutoComplete options={allCities} value={city} placeholder={"City"} onChange={setCity}/>
      <SearchBar id={"searchCy"}  onchange={setText} value={text}/>
      <Filter />
    </div>
      {/* Student Table Header */}
      <div className={classes.student_table_header}>
        <div>PATIENT NAME</div>
        <div>AGE</div>
        <div>GENDER</div>
        <div>PARENTS DETAIL</div>
        <div>ASSESSMENT & SCORE</div>
        <div>VIRTUAL CONSULTAION</div>
        <div>ACTION</div>
      </div>
      {/* Student Column-Rows Section */}
      <div id="schoolRowCy" className={classes.student_column_container}>
        {displayData?.slice(rowsPerPage*(pageNo-1), rowsPerPage*pageNo).map((el, index) => {
          return (
            <SchoolStudentRow data={el} key={index + Date.now()} index={index} />
          )
        })}
      </div>
      <div className={classes.pagination_container}>
        <div className={classes.page_item}>{`Showing ${(rowsPerPage*(pageNo-1))+1} to ${pageNo*rowsPerPage<=displayData?.length ? pageNo*rowsPerPage : displayData?.length} of ${displayData?.length} items`}</div>
        <div className={classes.pagination}>
          <Button size="small" disabled={pageNo===1} onClick={()=>setPageNo(pageNo-1)} >
            <ChevronLeft/>
          </Button>
            <span>{pageNo}</span>
          <div>
            <span>of</span>
            <span>{Math.ceil(displayData?.length/rowsPerPage)}</span>
          </div>

          <div>
            <Button size="small" disabled={pageNo===Math.ceil(displayData?.length/rowsPerPage)} onClick={()=>setPageNo(pageNo+1)} >
              <ChevronRight/>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function SchoolStudent() {

  return (
      <SchoolStudentPage  />
  );
}
export default SchoolStudent;