import React, { useEffect, useState } from 'react'
import classes from './AssessmentsTable.module.css'
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import CustomAutoComplete from '../../common/CustomAutoComplete/CustomAutoComplete';
import SearchBar from '../../common/TableComponents/SearchBar';
import { ChevronLeft, ChevronRight, Filter } from '@mui/icons-material';
import AssessmentsRow from './AssessmentsRow';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import DatePickerInput from '../../common/InputDashboard/DatePickerInput';
import BlankDataScreen from '../../BlankDataScreen/BlankDataScreen';

function AssessmentTable() {
    const location = useLocation()
    console.log(location)
    let { title, type, keyTitle, data, date } = location.state;
    const [displayData, setDisplayData] = useState(data)
    const [text, setText] = useState()
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [pageNo, setPageNo] = useState(1)
    const [fromDate, setFromDate] = useState(date)

    const scoreCheck = (x, arr) => {
        if (arr?.score && keyTitle == "DYSCALCULIA_2") {
            return JSON.parse(x?.['DYSCALCULIA_1'])
        }
        else if (arr?.score && keyTitle == "DYSGRAPHIA_2") {
            return JSON.parse(x?.['DYSGRAPHIA_1'])
        }
        else if (arr?.score && keyTitle == "DYSLEXIA_2") {
            return JSON.parse(x?.['DYSLEXIA_1'])
        }
        else {
            return JSON.parse(x?.[keyTitle])
        }
    }

    const checkDate = (a) => {
        // console.log(a)
        return moment(JSON.parse(a[keyTitle])?.responseAt).format("YYYY-MM-DD") >= moment(fromDate).format("YYYY-MM-DD")
    }

    useEffect(() => {
        if (fromDate && text) {
            setDisplayData(data?.filter((x) => (checkDate(x) && `${x.name}`.toLowerCase().includes(text.toLowerCase()))))
            setPageNo(1)
        }
        else if (text) {
            setDisplayData(data?.filter((x) => `${x.name}`.toLowerCase().includes(text.toLowerCase())))
            setPageNo(1)
        }
        else if (fromDate) {
            setDisplayData(data.filter((x) => checkDate(x)))
            setPageNo(1)
        }
        else {
            setDisplayData(data)
        }
    }, [text, fromDate])
    console.log(data);
    console.log(displayData);
    return (
        <div className={classes.main_div}>
            <div className={classes.student_layout}>
                {title} Assessments
            </div>
            <div className={classes.student_filters}>
                <div className={classes.show_text}>Show</div>
                <FormControl>
                    <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={rowsPerPage}
                        label="Rows"
                        onChange={(e) => setRowsPerPage(e.target.value)}
                        size="small"
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                    </Select>
                </FormControl>
                <SearchBar id={"searchCy"} onchange={setText} value={text} />
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '10px' }}>
                    <p style={{ fontSize: '12px' }}>From Date</p>
                    <DatePickerInput
                        onChange={(e) => {
                            setFromDate(e.target.value);
                        }}
                        futureDate={true}
                        margin={true}
                        placeholder={"Date"}
                        value={fromDate}
                    />
                </div>
            </div>
            {!!displayData.length ?
                <>
                    {/* Student Table Header */}
                    <div className={classes.student_table_header}>
                        <div>PATIENT NAME</div>
                        <div>AGE</div>
                        <div>GENDER</div>
                        <div>SCORE</div>
                        <div>RISK</div>
                        <div>BOOK CONSULTATION</div>
                        <div style={{ justifySelf: "center" }}>ACTION</div>
                    </div>
                    {/* Student Column-Rows Section */}
                    <div id="schoolRowCy" className={classes.student_column_container}>
                        {displayData?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo)?.map(item => (
                            <AssessmentsRow data={item} title={title} pageType={type} keyTitle={keyTitle} />
                        ))}
                    </div>
                    <div className={classes.pagination_container}>
                        <div className={classes.page_item}>{`Showing ${rowsPerPage * (pageNo - 1) + 1
                            } to ${pageNo * rowsPerPage <= displayData?.length
                                ? pageNo * rowsPerPage
                                : displayData?.length
                            } of ${displayData?.length} items`}</div>
                        <div className={classes.pagination}>
                            <Button
                                size="small"
                                disabled={pageNo === 1}
                                onClick={() => setPageNo(pageNo - 1)}
                            >
                                <ChevronLeft />
                            </Button>
                            <span>{pageNo}</span>
                            <div>
                                <span>of</span>
                                <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
                            </div>

                            <div>
                                <Button
                                    size="small"
                                    disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)}
                                    onClick={() => setPageNo(pageNo + 1)}
                                >
                                    <ChevronRight />
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
                :
                <BlankDataScreen
                    title="Oops! No data available"
                    description="Please select the above filters or try changing them."
                    hideButton={true}
                />}
            {/* <ChipModal
                action={chipModal}
                setAction={setChipModal}
                title="Share Add School Typeform"
                subtitle="Please enter email id below to share “Add School” typeform to school representative."
                sendFunctionCall={shareTypeform}
                handleClick={handleClick}
                setOpen={setOpen}
            />
            {!loadingTypeform && (
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                    <Alert
                        onClose={handleClose}
                        severity={!errorTypeform ? "success" : "error"}
                        sx={{ width: "100%" }}
                    >
                        {!errorTypeform
                            ? `Typeform Sent Successfully`
                            : `Something Wrong! Try Again`}
                    </Alert>
                </Snackbar>
            )} */}
        </div>
    )
}

export default AssessmentTable