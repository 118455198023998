import React from 'react'
import classes from './BreadCrumbs.module.css'
import breadcrumbsArrow from '../../../assets/images/breadcrumbsArrow.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter } from '../../../utils/utility'

export default function BreadCrumbs() {
    const navigate = useNavigate()
    const location = useLocation();
    const crumbsArray = location?.state?.breadCrumbs

    // useEffect(() => {

    // }, [])

    const breadCrumbHandler = (index) => {
        if (index !== crumbsArray.length - 1) {
            navigate(-(crumbsArray.length - (index + 1)));
            location.state.breadCrumbs = crumbsArray.slice(0, index + 1)
        }
    }


    return (
        <div className={classes.breadcrumbs_container}>
            {crumbsArray?.map((crumb, index) => {
                return (
                    <>
                        {
                            index > 0 && (
                                <img src={breadcrumbsArrow} alt="triangle" />
                            )
                        }
                        <div onClick={() => { breadCrumbHandler(index) }} className={index !== crumbsArray.length - 1 ? classes.nested_nav_links : ''}>
                            {capitalizeFirstLetter(crumb)}
                        </div>
                    </>
                )
            })
            }


        </div>
    )
}