import React from "react";
import classes from "../Dyslexia/Dyslexia.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";

export const Dysgraphia2 = ({ data }) => {
  const questionBank = [
    {
      qno: 1,
      question:
        "Does your child have a strong dislike of writing and/or drawing?",
      answer: data?.output?.[1]?.answers[0]
    },
    {
      qno: 2,
      question:
        "Does you child have problems with grammar (e.g., makes multiple grammatical or punctuation mistakes in a sentence)?",
      answer: data?.output?.[1]?.answers[1]
    },
    {
      qno: 3,
      question:
        "Does your child have trouble writing down ideas (e.g.,  written expression of ideas lacks clarity, poor paragragh organization).",
      answer: data?.output?.[1]?.answers[2]
    },
    {
      qno: 4,
      question:
        "Does your child tend to lose energy or interest as soon as they start writing",
      answer: data?.output?.[1]?.answers[3]
    },
    {
      qno: 5,
      question:
        "Does your child have trouble writing down thoughts in a logical sequence?",
      answer: data?.output?.[1]?.answers[4]
    },
    {
      qno: 6,
      question:
        "Does your child have difficulty saying words out loud while writing?",
      answer: data?.output?.[1]?.answers[5]
    },
    {
      qno: 7,
      question:
        "Does your child usually leave words unfinished or omits them when writing sentences?",
      answer: data?.output?.[1]?.answers[6]
    },
  ];


  return (
    <>
      {data?.output?.length === 2 && <div className={classes.container}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>
        <div className={classes.title_container}>
          Hey! This is my Dysgraphia Stage 2 assessment report.
        </div>

        <div className={classes.question_container}>
          <div>Assessment Questionnaire</div>
          <div className={classes.question_select_container}>
            {questionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p style={{ flexBasis: "423px" }}>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Yes"
                          checked={el.answer === "YES"}
                        />
                        <p>Yes</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="No"
                          checked={el.answer === "NO"}
                        />
                        <p>No</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.copyright_details_container}>
          <div>
            The information contained in this publication should not be used as
            a substitute for the medical care and advice of your psychologist.
            There may be variations in treatment that your psychologist may
            recommend based on individual facts and circumstances.
          </div>
          <div>
            Copyright @2023 Skids Health
            <br />
            Adapted from DSM-5 ( Diagnostic and Statistical Manual of Mental
            Disorders, Fifth <br />
            Edition) and ICD-10 (International Classification of Diseases,Tenth
            Revision)
          </div>
        </div>

        <div className={classes.footer_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 2 of 2</p>
          </div>
        </div>
      </div>}
    </>
  );
};
