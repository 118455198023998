import { Button, TablePagination } from '@mui/base';
import { CircularProgress, Pagination, Paper, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useLocation } from 'react-router';
import * as XLSX from 'xlsx';
import BreadCrumbs from '../common/TableComponents/BreadCrumbs';
import moment from 'moment';
import schools from '../../graphqlApiServices/schools';
import { useEffect } from 'react';
import students from '../../graphqlApiServices/students';
import { useRef } from 'react';
import gear from '../../assets/images/processing-gear.gif';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function BulkUploadErrorTablePage() {

    const [batchProgress, setBatchProgress] = useState(false);
    const [data, setData] = useState([]);
    const [success, setSuccess] = useState(false)
    const location = useLocation();
    const { getBulkUploadStatus } = schools;
    const { getBatchUpload } = students;
    let intervalId = useRef(null);

    useEffect(() => {
        getBulkUploadStatus(location.state.schoolId, getBulkUploadCallback);
        intervalId.current = setInterval(() => {
            getBulkUploadStatus(location.state.schoolId, getBulkUploadCallback)
        }, 5000);
    }, [])


    function getBatchErCallback(type, Res) {
        if (type === 'success') {
            if (Res?.batchUpload?.length > 0) {
                setData((prev) => {
                    return [...prev,
                    ...Res?.batchUpload]
                });
                if (Res.nextToken) {
                    getBatchUpload({ schoolId: location.state.schoolId, nextToken: Res.nextToken }, getBatchErCallback);
                }
            } else if (Res?.batchUpload?.length === 0) {
                setSuccess(true)
            }
        } else if (type === 'failure') {
            console.log(Res);
            if (Res?.data?.getBatchUpload?.batchUpload?.length > 0) {
                setData((prev) => {
                    return [...prev,
                    ...Res?.data?.getBatchUpload?.batchUpload]
                });
                if (Res?.data?.getBatchUpload?.nextToken) {
                    getBatchUpload({ schoolId: location.state.schoolId, nextToken: Res?.data?.getBatchUpload?.nextToken }, getBatchErCallback);
                }
            } else if (Res?.data?.getBatchUpload?.batchUpload?.length === 0) {
                setSuccess(true)
            }
        }
    }

    function getBulkUploadCallback(resType, Res) {
        if (resType === "success") {
            if (Res.isBatchUpload) {
                setBatchProgress(true);
            } else if (!Res.isBatchUpload) {
                setBatchProgress(false)
                clearInterval(intervalId.current);
                getBatchUpload({ schoolId: location.state.schoolId }, getBatchErCallback);
            }
        } else if (resType === "failure") {
            console.log(Res);
        }
    }



    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };


    const steps = ['Download error excel file', 'Rectify errors in offline mode', 'Upload rectified excel again'];

    const handleDownload = () => {
        const excelData = []
        for (let i = 0; i < data.length; i++) {
            let x = {
                admissionNumber: data[i].admissionNumber ?? "",
                name: data[i].name ?? "",
                DOB: data[i].DOB ?? "",
                gender: data[i].gender ?? "",
                class: data[i].class ?? "",
                section: data[i].section ?? "",
                academicYear: data[i].academicYear ?? "",
                fatherName: data[i].fatherName ?? "",
                fatherMobile: data[i].fatherContact?.mobile ?? "",
                fatherEmail: data[i].fatherContact?.email ?? "",
                motherName: data[i].motherName ?? "",
                motherEmail: data[i].motherContact?.email ?? "",
                motherMobile: data[i].motherContact?.mobile ?? "",
                group: data[i].group ?? "",
                rhType: data[i].rhType ?? "",
                reason: data[i].reason ?? ""
            }
            excelData.push(x)
        }
        console.log(excelData);
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "DataSheet.xlsx");
    }


    return (
        <>
            <div style={{ marginBottom: 25 }}><BreadCrumbs /></div>
            <div style={{ display: "flex", justifyContent: "space-between", paddingRight: 5, marginBottom: 20 }}>
                <Stepper sx={{ width: "50vw" }}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        labelProps.optional = (<Typography variant="caption">{label}</Typography>);
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{`Step ${index + 1}`}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <Button
                    disabled={!!batchProgress}
                    style={{ display: success ? 'none' : 'block', cursor: "pointer", padding: 15, borderRadius: 10, backgroundColor: batchProgress ? "#ffffff" : "#1740A1", color: "#fff", fontWeight: 600, border: "none", height: "fit-content", alignItems: "center" }}
                    onClick={handleDownload}
                >{batchProgress
                    ? <div style={{ display: "flex", alignItems: "center", gap: "10px" }} ><span>Upload in progress (Please wait)</span></div>
                    : "Download Failed Data"
                    }
                </Button>
            </div>
            {
                batchProgress
                    ? <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <img style={{ width: "70%", mixBlendMode: "darken", marginTop: "100px" }} src={gear} alt="" />
                        <p style={{ fontSize: "25px", fontWeight: "bold", color: "grey" }}>Please wait batch upload is in progress...</p>
                    </div>
                    : success ? <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: '15%' }}>
                        <CheckCircleIcon sx={{ fontSize: "100px", fill: "green" }} />
                        <p style={{ fontSize: "25px", fontWeight: "bold", color: "grey" }}>Batch successfully uploaded</p>
                    </div>
                        :
                        <>
                            <TableContainer component={Paper} style={{ maxWidth: "79vw" }}>
                                <Table sx={{ minWidth: 650, }} aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: "#E8E7E7" }}>
                                        <TableRow>
                                            <TableCell>Sr no.</TableCell>
                                            <TableCell>Admission No.</TableCell>
                                            <TableCell align="right">Name</TableCell>
                                            <TableCell align="right">Class</TableCell>
                                            <TableCell align="right">Section</TableCell>
                                            <TableCell align="right" style={{ minWidth: '110px' }}>DOB</TableCell>
                                            <TableCell align='right'>Gender</TableCell>
                                            <TableCell align='right'>Blood Group</TableCell>
                                            <TableCell align='right'>rh Type</TableCell>
                                            <TableCell align='right'>Father's Name</TableCell>
                                            <TableCell align='right'>Father's Mobile</TableCell>
                                            <TableCell align='right'>Father's Email</TableCell>
                                            <TableCell align='right'>Mother's Name</TableCell>
                                            <TableCell align='right'>Mother's Mobile</TableCell>
                                            <TableCell align='right'>Mother's Email</TableCell>
                                            <TableCell align='right'>Reason</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data && data?.slice(10 * (page - 1), 10 * page).map((row, i) => (
                                            <TableRow
                                                key={i}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="right" style={{ color: "inherit" }}>{page < 2 ? (i + 1) : ((page - 1) * 10) + i + 1}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.admissionNumber || "-"}
                                                </TableCell>
                                                <TableCell align="right" style={{ color: "inherit" }}>{row.name || "-"}</TableCell>
                                                <TableCell align="right">{row.class || "-"}</TableCell>
                                                <TableCell align="right">{row.section || "-"}</TableCell>
                                                <TableCell align="right">{moment(row.DOB).format("DD-MM-YYYY")}</TableCell>
                                                <TableCell align="right">{row.gender || "-"}</TableCell>
                                                <TableCell align="right">{row.bloodGroup?.group || "-"}</TableCell>
                                                <TableCell align="right">{row.bloodGroup?.rhType || "-"}</TableCell>
                                                <TableCell align="right">{row.fatherName || "-"}</TableCell>
                                                <TableCell align="right">{row.fatherMobile || "-"}</TableCell>
                                                <TableCell align="right">{row.fatherEmail || "-"}</TableCell>
                                                <TableCell align="right">{row.motherName || "-"}</TableCell>
                                                <TableCell align="right">{row.motherMobile || "-"}</TableCell>
                                                <TableCell align="right">{row.motherEmail || "-"}</TableCell>
                                                <TableCell align="right" style={{ backgroundColor: "#FFF5F5" }}>{row.reason}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
                                <Pagination count={Math.ceil(data?.length / 10)} page={page} onChange={handleChange} />
                            </div>
                        </>
            }
        </>
    )
}

export default BulkUploadErrorTablePage