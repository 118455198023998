import { call, put, takeLatest } from "redux-saga/effects";
import typeformReducer from "../reducers/typeform.reducer";
import typeform from "../../graphqlApiServices/typeform";

const {shareTypeformQuery, sendParentsTypeform} = typeform


export function* watchTypeformRequests() {
    yield takeLatest(typeformReducer.actions.shareTypeformRequest, shareTypeformSaga);
    yield takeLatest(typeformReducer.actions.shareParentsTypeformRequest, sendParentsTypeformSaga);
}

export function* shareTypeformSaga(payload) {
    try {
        // 
        const response = yield call(shareTypeformQuery, payload);
        
        if(response?.data?.sendEmailToSchool.status === 200)
        {
            yield put(typeformReducer.actions.shareTypeformSuccess(response?.data))
        }else if(response?.data?.sendEmailToSchool.status === 400){
            // 
            yield put(typeformReducer.actions.shareTypeformFail({error : true}))
        }
    } catch (error) {
        
        // yield put(typeformReducer.actions.shareTypeformFail({})
        // 
    }
}

export function* sendParentsTypeformSaga(payload) {
    try {
        const response = yield call(sendParentsTypeform, payload);
        
        if(response?.data?.sendTypeform==="Email send successfully")
        {
            yield put(typeformReducer.actions.shareTypeformSuccess(response?.data))
        }else{
            yield put(typeformReducer.actions.shareTypeformFail({error : true}))
        }
    } catch (error) {
        
        yield put(typeformReducer.actions.shareTypeformFail(error))
        // 
    }
}