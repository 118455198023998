import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../common/TableComponents/BreadCrumbs'
import classes from "./DentalDoctorValidation.module.css"
import { Avatar, Checkbox, CircularProgress, FormControlLabel, FormGroup } from '@mui/material'
import { Awsdateformatter, getAge, numberTh, stringAvatar, toDateString } from '../../utils/utility'
import Checkanimated from '../../assets/images/AnimatedIcon/Checkmark/Checkmark'
import { useLocation } from 'react-router-dom'
import DentalJaw from '../Dentaljaw/Dentaljaw'
import DocInterpretation from './DocIntertation/DocInterpretation'
import validation from '../../graphqlApiServices/validation'
import LoadingBar from 'react-top-loading-bar'
import studentReducer from "../../redux/reducers/student.reducer";
import { useDispatch, useSelector } from 'react-redux'
import validationReducer from '../../redux/reducers/validation.reducer'
import Cookies from 'js-cookie'
import { useRef } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import screeningReducer from '../../redux/reducers/screening.reducer'
import VideoSlideModal from '../common/StudentName/VideoSlideshow/VideoSlideModal';

const DentalDoctorValidation = () => {

    let subscription = useRef(null);
    const dispatch = useDispatch();
    const updateStudent = studentReducer.actions.updateStudentStatusRequest;
    const getStudentById = studentReducer.actions.getStudentByIDRequest;
    const updateValidationStatus = validationReducer.actions.updateValidationReq;
    const addLocalValidated = validationReducer.actions.addValidatedStudent;
    const currentUser = useSelector((state) => state.userReducer?.currentUser);
    const token = Cookies.get('tokenId');
    const screenData = useSelector((state) => state.screeningReducer.data);
    const { subscribeDentalScreening } = validation
    const toggleDevice = screeningReducer.actions.toggleDeviceReq;
    const studentScreening = screeningReducer.actions.getScreeningByStudentIdRequest;

    let autoStatements = {
        gingivitis: "Gingivitis detected during screening. Dentist consultation is advised. ",
        orthodontic: "Orthodontic problem detected during screening. Dentist consultation is advised.",
        decoloration: "Decoloration problem detected during screening. Dentist consultation is advised."
    }

    const location = useLocation();
    const [progress, setProgress] = useState(0);
    const [dentalInterpretation, setDentalInterpretation] = useState({});
    const { updateDentalInterpretation, getDentalInterpretation } = validation;


    useEffect(() => {
        getDentalScreeening();
        dispatch(studentScreening(location?.state?.patientData?.studentId));
    }, []);

    console.log(screenData);

    function getDentalScreeening() {
        const params = {
            eventId: location?.state?.patientData?.eventId,
            screeningId: location?.state?.patientData?.screeningId
        }
        getDentalInterpretation(params, onGetInterSuccess, onGetInterFailure);
    }
    function onGetInterSuccess(data) {
        setDentalInterpretation(data);
    }
    function onGetInterFailure(error) {
        console.log(error);
    }

    const ToggleDevice = (device) => {
        let params = {
            screeningId: location?.state?.patientData?.screeningId,
            type: device
        }
        // console.log(params);
        dispatch(toggleDevice(params));
    }

    useEffect(() => {
        if (!location?.state?.patientData?.studentId) return;
        // const params = {
        //     studentId: location?.state?.patientData?.studentId
        // }
        // dispatch(getStudentById(params));
        (async function () {
            const token = Cookies.get('tokenId')
            const params = {
                eventId: location?.state?.patientData?.eventId,
                screeningId: location?.state?.patientData?.screeningId
            }
            try {
                const query = subscribeDentalScreening(params)
                const subscriptionTemp = await API.graphql(
                    graphqlOperation(
                        query,
                        {},
                        token,
                    )
                ).subscribe({
                    next: ({ provider, value }) => {
                        // setShowLoading(false);
                        setDentalInterpretation(value?.data?.subscribeToUpdateScreening)
                        setProgress(100);

                    },
                    error: error => console.warn(error)
                });
                subscription.current = subscriptionTemp;
            }
            catch (error) {
                console.log(error);
            }
        })();

        return () => {
            if (subscription.current) {
                subscription.current?.unsubscribe();
            }
        }

    }, [location?.state?.patientData?.screeningId])


    const publishTestUpdates = (type, docInput, e) => {
        if (e) e.preventDefault();
        const now = new Date();
        const awsDate = Awsdateformatter(now);
        setProgress(60);
        const params = {
            awsDate: awsDate,
            eventId: location?.state?.patientData?.eventId,
            screeningId: location?.state?.patientData?.screeningId,
            status: (type === "DENTAL_DONE" && dentalInterpretation?.physicalScreeningDate)
                ? "VALIDATION_DONE"
                : dentalInterpretation.status,
            dentalDone: type === "DENTAL_DONE" ? true : false,
            dentalAssessment: {
                DMFTIndex: dentalInterpretation?.dentalAssessment?.DMFTIndex,
                DMFTIndexInterpretation: type === "DMFT"
                    ? docInput
                    : dentalInterpretation?.dentalAssessment?.DMFTIndexInterpretation
                    ?? "",

                decoloration: type === "decoloration"
                    ? docInput
                    : dentalInterpretation?.dentalAssessment?.decoloration,
                decolorationInterpretation: type === "decolorationInter"
                    ? docInput
                    : (type === "decoloration" && !docInput)
                        ? ""
                        : (type === "decoloration" && docInput)
                            ? autoStatements.decoloration
                            : dentalInterpretation?.dentalAssessment?.decolorationInterpretation
                            || "",

                gingivitis: type === "gingivitis"
                    ? docInput
                    : dentalInterpretation?.dentalAssessment?.gingivitis,
                gingivitisInterpretation: type === "gingivitisInter"
                    ? docInput
                    : (type === "gingivitis" && !docInput)
                        ? ""
                        : (type === "gingivitis" && docInput)
                            ? autoStatements.gingivitis
                            : dentalInterpretation?.dentalAssessment?.gingivitisInterpretation
                            || "",

                orthodontic: type === "orthodontic"
                    ? docInput
                    : dentalInterpretation?.dentalAssessment?.orthodontic,
                orthodonticInterpretation: type === "orthodonticInter"
                    ? docInput
                    : (type === "orthodontic" && !docInput)
                        ? ""
                        : (type === "orthodontic" && docInput)
                            ? autoStatements.orthodontic
                            : dentalInterpretation?.dentalAssessment?.orthodonticInterpretation
                            || "",
                tooth: type === "tooth"
                    ? docInput
                    : dentalInterpretation?.dentalAssessment?.tooth?.[0]?.split(","),
                fillingPresent: type === "fillingPresent"
                    ? docInput
                    : dentalInterpretation?.dentalAssessment?.fillingPresent,
                fillingPresentInterpretation: dentalInterpretation?.dentalAssessment?.fillingPresentInterpretation,
                missedTooth: type === "missedTooth"
                    ? docInput
                    : dentalInterpretation?.dentalAssessment?.missedTooth,
                missedToothInterpretation: dentalInterpretation?.dentalAssessment?.missedToothInterpretation,
                poorOralHygiene: type === "poorOralHygiene"
                    ? docInput
                    : dentalInterpretation?.dentalAssessment?.poorOralHygiene,
                poorOralHygieneInterpretation: dentalInterpretation?.dentalAssessment?.poorOralHygieneInterpretation,
                cariesDecay: type === "cariesDecay"
                    ? docInput
                    : dentalInterpretation?.dentalAssessment?.cariesDecay,
                cariesDecayInterpretation: dentalInterpretation?.dentalAssessment?.cariesDecayInterpretation,

            },
            screeningStatus: {
                dental: {
                    decoloration: dentalInterpretation?.screeningStatus?.dental?.decoloration,
                    gingivitis: dentalInterpretation?.screeningStatus?.dental?.gingivitis,
                    orthodontic: dentalInterpretation?.screeningStatus?.dental?.orthodontic,
                    tooth: dentalInterpretation?.screeningStatus?.dental?.tooth,
                    resultAwait: dentalInterpretation?.screeningStatus?.dental?.resultAwait
                },
                abdomin: {
                    auscultation: dentalInterpretation?.screeningStatus?.abdomin?.auscultation,
                },
                cough: {
                    exam: dentalInterpretation?.screeningStatus?.cough?.exam,
                },
                ear: {
                    leftEarVideo: dentalInterpretation?.screeningStatus?.ear?.leftEarVideo,
                    rightEarVideo: dentalInterpretation?.screeningStatus?.ear?.rightEarVideo,
                },
                eye: {
                    normalVision: dentalInterpretation?.screeningStatus?.eye?.normalVision,
                },
                hair: {
                    color: dentalInterpretation?.screeningStatus?.hair?.color,
                    dandruff: dentalInterpretation?.screeningStatus?.hair?.dandruff,
                    lossOfHair: dentalInterpretation?.screeningStatus?.hair?.lossOfHair,
                    pediculosis: dentalInterpretation?.screeningStatus?.hair?.pediculosis,
                },
                hearing: {
                    resultAwait: dentalInterpretation?.screeningStatus?.hearing?.resultAwait,
                    hearingAssessment: dentalInterpretation?.screeningStatus?.hearing?.hearingAssessment,
                },
                heart: {
                    estimation: dentalInterpretation?.screeningStatus?.heart?.estimation,
                },
                lungs: {
                    auscultation: dentalInterpretation?.screeningStatus?.lungs?.auscultation,
                },
                skin: {
                    video: dentalInterpretation?.screeningStatus?.skin?.video,
                },
                throat: {
                    video: dentalInterpretation?.screeningStatus?.throat?.video,
                },
                vitalSigns: {
                    heightAwait: dentalInterpretation?.screeningStatus?.vitalSigns?.heightAwait,
                    weightAwait: dentalInterpretation?.screeningStatus?.vitalSigns?.weightAwait,
                    SpO2: dentalInterpretation?.screeningStatus?.vitalSigns?.SpO2,
                    bloodPressure: dentalInterpretation?.screeningStatus?.vitalSigns?.bloodPressure,
                    heartRate: dentalInterpretation?.screeningStatus?.vitalSigns?.heartRate,
                    height: dentalInterpretation?.screeningStatus?.vitalSigns?.height,
                    hemoglobin: dentalInterpretation?.screeningStatus?.vitalSigns?.hemoglobin,
                    temperature: dentalInterpretation?.screeningStatus?.vitalSigns?.temperature,
                    weight: dentalInterpretation?.screeningStatus?.vitalSigns?.weight,
                }
            }
        }
        updateDentalInterpretation(params, onDentalUpdateSuccess, onDentalUpdateFailure);
    }
    function onDentalUpdateSuccess(data) {
    }
    function onDentalUpdateFailure(error) {
        setProgress(100);
        console.log(error);
    }


    const handleTeethUpdate = async (teethUpdate, activeTeeth) => {
        let tempTeethArray = dentalInterpretation?.dentalAssessment?.tooth[0]?.split(',')?.filter((element) => (!element.includes(activeTeeth.current)));
        switch (teethUpdate) {
            case 'normal':
                tempTeethArray.push(activeTeeth.current + "_" + "N".toString())
                break;
            case 'decayed':
                tempTeethArray.push(activeTeeth.current + "_" + "D".toString())
                break;
            case 'filled':
                tempTeethArray.push(activeTeeth.current + "_" + "F".toString())
                break;
            case 'missing':
                tempTeethArray.push(activeTeeth.current + "_" + "M".toString())
                break;
        }
        publishTestUpdates('tooth', tempTeethArray);
    }

    const handleValidationComplete = () => {
        const now = new Date();
        const awsDate = Awsdateformatter(now);
        const params = {
            awsDate: awsDate,
            userId: currentUser?.userId,
            validationId: location?.state?.patientData?.validationId
        }
        publishTestUpdates("DENTAL_DONE", true);
        if (dentalInterpretation?.physicalScreeningDate) {
            dispatch(updateStudent({ studentId: location?.state?.patientData?.studentId, schoolId: location?.state?.patientData?.schoolId, status: "VALIDATION_DONE" }))
        }
        dispatch(updateValidationStatus(params));
        dispatch(addLocalValidated(location?.state?.patientData?.studentId));
    }


    return (
        <div>
            <LoadingBar
                color='#5770e6'
                height={4}
                progress={progress}
                onLoaderFinished={() => setProgress(0)} />
            <BreadCrumbs />
            <div className={classes.studentDetails}>
                <div className={classes.avtarSection}>
                    <Avatar {...stringAvatar(location?.state?.patientData?.patientName)} sx={{ width: 64, height: 64, fontSize: 48, fontWeight: "bold" }} />
                    <div className={classes.student_details_information_name}>{location?.state?.patientData?.patientName}</div>
                </div>
                <div>
                    <div>
                        <p>Age</p>
                        <p>{getAge(location?.state?.patientData?.DOB)} Yrs</p>
                    </div>
                    <div>
                        <p>Gender</p>
                        <p>{location?.state?.patientData?.gender}</p>
                    </div>
                    <div>
                        <p>Class</p>
                        <p>{numberTh(location?.state?.patientData?.class)} {location?.state?.patientData?.section}</p>
                    </div>
                    <div>
                        <p>Screening Date {"&"} Time</p>
                        <p>{toDateString(location?.state?.patientData?.screeningDate)}</p>
                    </div>
                    <div>
                        <p>Due Date</p>
                        <p>{toDateString(location?.state?.patientData?.validationDueDate)}</p>
                    </div>
                    <div>
                        <Checkanimated active={(dentalInterpretation?.dentalScreeningDate) ? true : false} onClick={handleValidationComplete} />
                    </div>
                </div>
            </div>

            <div className={classes.container}>
                <div className={classes.parameterContainer}>
                    <>
                        <p className={classes.parameterHeading}>Dental Validation</p>
                        <div className={classes.dentalHigoCon}>
                            <div className={classes.heart_poll_container}>
                                <div className={classes.subParaHeading} >Tooth Decay, Missing and Filled</div>
                                <DentalJaw disabled={!(screenData?.package === "ADVANCED")} handleTeethUpdate={handleTeethUpdate} tooths={dentalInterpretation?.dentalAssessment?.tooth} />
                            </div>
                            <div>
                                <>
                                    <p>issues</p>
                                    <div>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox
                                                onChange={(e) => { publishTestUpdates("gingivitis", e.target.checked) }}
                                                checked={!!dentalInterpretation?.dentalAssessment?.gingivitis}
                                            />} label="Gingivitis" />
                                            <FormControlLabel control={<Checkbox
                                                onChange={(e) => { publishTestUpdates('orthodontic', e.target.checked) }}
                                                checked={!!dentalInterpretation?.dentalAssessment?.orthodontic} />}
                                                label="Orthodontic / Malalignment" />
                                            <FormControlLabel control={<Checkbox
                                                onChange={(e) => { publishTestUpdates('decoloration', e.target.checked) }}
                                                checked={!!dentalInterpretation?.dentalAssessment?.decoloration} />}
                                                label="Discoloration" />
                                            <FormControlLabel control={<Checkbox
                                                onChange={(e) => { publishTestUpdates('poorOralHygiene', e.target.checked) }}
                                                checked={!!dentalInterpretation?.dentalAssessment?.poorOralHygiene}
                                            />} label="Poor Oral Hygiene" />
                                            {!(screenData?.package === "ADVANCED") &&
                                                <>
                                                    <FormControlLabel control={<Checkbox
                                                        onChange={(e) => { publishTestUpdates('cariesDecay', e.target.checked) }}
                                                        checked={!!dentalInterpretation?.dentalAssessment?.cariesDecay}
                                                    />} label="Caries / Decay" />
                                                    <FormControlLabel control={<Checkbox
                                                        onChange={(e) => { publishTestUpdates('missedTooth', e.target.checked) }}
                                                        checked={!!dentalInterpretation?.dentalAssessment?.missedTooth}
                                                    />} label="Missed Tooth" />
                                                    <FormControlLabel control={<Checkbox
                                                        onChange={(e) => { publishTestUpdates('fillingPresent', e.target.checked) }}
                                                        checked={!!dentalInterpretation?.dentalAssessment?.fillingPresent}
                                                    />} label="Filling Present" />
                                                </>
                                            }
                                        </FormGroup>
                                    </div>
                                </>
                            </div>
                            <div>
                                <p>video</p>
                                <div>
                                    {
                                        screenData?.higo?.throat.length > 0 &&
                                        <VideoSlideModal size={350} videoUrl={screenData?.higo?.throatVideo} imgs={screenData?.higo?.throat} />
                                    }
                                    {
                                        screenData?.ent?.entDentalVideo &&
                                        <VideoSlideModal size={350} videoUrl={screenData?.ent?.entDentalVideo} />
                                    }
                                    {
                                        screenData?.ent?.entThroatVideo &&
                                        <VideoSlideModal size={350} videoUrl={screenData?.ent?.entThroatVideo} />
                                    }
                                </div>
                            </div>
                        </div>
                        {/* {dentalInterpretation?.screeningPackageDetails?.dental?.includes('GINGIVITIS')
                            &&
                            <div className={classes.poll_container}>
                                <div className={classes.heart_poll_container}>
                                    <div>Gingivitis</div>
                                    <div>Select option</div>
                                    <div>
                                        <button onClick={() => { publishTestUpdates('gingivitis', true) }}
                                            className={dentalInterpretation?.dentalAssessment?.gingivitis
                                                ? `${classes.heart_poll_container_button_active}`
                                                : `${classes.heart_poll_container_button_inactive}`}>Present</button>
                                        <button
                                            onClick={() => { publishTestUpdates('gingivitis', false) }}
                                            className={!dentalInterpretation?.dentalAssessment?.gingivitis
                                                ? `${classes.heart_poll_container_button_active}`
                                                : `${classes.heart_poll_container_button_inactive}`}>Absent</button>
                                    </div>
                                </div>
                                {dentalInterpretation?.dentalAssessment?.gingivitis &&
                                    <DocInterpretation
                                        updateType="gingivitisInter"
                                        updateInterpretation={publishTestUpdates}
                                        interpretation={
                                            dentalInterpretation?.dentalAssessment?.gingivitisInterpretation || autoStatements.gingivitis
                                        } />}
                            </div>}
                        {dentalInterpretation?.screeningPackageDetails?.dental?.includes('ORTHODONTIC_PROBLEM')
                            &&
                            <div className={classes.poll_container}>
                                <div className={classes.heart_poll_container}>
                                    <div>Orthodontic / Malallignment Problem</div>
                                    <div>Select option </div>
                                    <div>
                                        <button onClick={() => { publishTestUpdates('orthodontic', true) }}
                                            className={dentalInterpretation?.dentalAssessment?.orthodontic
                                                ? `${classes.heart_poll_container_button_active}`
                                                : `${classes.heart_poll_container_button_inactive}`}>Present</button>
                                        <button
                                            onClick={() => { publishTestUpdates('orthodontic', false) }}
                                            className={!dentalInterpretation?.dentalAssessment?.orthodontic
                                                ? `${classes.heart_poll_container_button_active}`
                                                : `${classes.heart_poll_container_button_inactive}`}>Absent</button>
                                    </div>
                                </div>
                                {dentalInterpretation?.dentalAssessment?.orthodontic &&
                                    <DocInterpretation
                                        updateType="orthodonticInter"
                                        updateInterpretation={publishTestUpdates}
                                        interpretation={
                                            dentalInterpretation?.dentalAssessment?.orthodonticInterpretation || autoStatements.orthodontic
                                        } />}
                            </div>}
                        {dentalInterpretation?.screeningPackageDetails?.dental?.includes('DECOLORATION')
                            &&
                            <div className={classes.poll_container}>
                                <div className={classes.heart_poll_container}>
                                    <div>Discoloration</div>
                                    <div>Select option</div>
                                    <div>
                                        <button onClick={() => { publishTestUpdates('decoloration', true) }}
                                            className={dentalInterpretation?.dentalAssessment?.decoloration
                                                ? `${classes.heart_poll_container_button_active}`
                                                : `${classes.heart_poll_container_button_inactive}`}>Present</button>
                                        <button
                                            onClick={() => {
                                                publishTestUpdates
                                                    ('decoloration', false)
                                            }}
                                            className={!dentalInterpretation?.dentalAssessment?.decoloration
                                                ? `${classes.heart_poll_container_button_active}`
                                                : `${classes.heart_poll_container_button_inactive}`}>Absent</button>
                                    </div>
                                </div>
                                {dentalInterpretation?.dentalAssessment?.decoloration &&
                                    <DocInterpretation
                                        updateType="decolorationInter"
                                        updateInterpretation={publishTestUpdates}
                                        interpretation={
                                            dentalInterpretation?.dentalAssessment?.decolorationInterpretation || autoStatements.decoloration
                                        } />}
                            </div>}
                        <div className={classes.poll_container}>
                            <div className={classes.heart_poll_container}>
                                <div>Update CareStream Data</div>
                                <div>
                                    {dentalInterpretation?.screeningStatus?.dental?.resultAwait
                                        ? "In Progress please wait..."
                                        : "Click Start to continue"
                                    }
                                </div>
                                <div>
                                    <button
                                        // onClick={() => { publishTestUpdates('decoloration', true) }}
                                        className={dentalInterpretation?.screeningStatus?.dental?.tooth
                                            ? `${classes.heart_poll_container_button_active}`
                                            : `${classes.heart_poll_container_button_inactive}`}>Updated</button>
                                    <button
                                        // onClick={() => { publishTestUpdates('decoloration', false) }}
                                        className={!dentalInterpretation?.screeningStatus?.dental?.tooth
                                            ? `${classes.heart_poll_container_button_active}`
                                            : `${classes.heart_poll_container_button_inactive}`}>Pending</button>
                                    <button
                                        // disabled={dentalInterpretation?.screeningStatus?.dental?.resultAwait}
                                        onClick={() => { ToggleDevice("CARESTREAM") }}
                                        className={classes.heart_poll_container_button_inactive}>
                                        {
                                            (dentalInterpretation?.screeningStatus?.dental?.resultAwait)
                                                ? <CircularProgress color='secondary' size={30} />
                                                : "Start"
                                        }
                                    </button>
                                </div>
                            </div>
                            {dentalInterpretation?.dentalAssessment?.decoloration &&
                                <DocInterpretation
                                    updateType="decolorationInter"
                                    updateInterpretation={publishTestUpdates}
                                    interpretation={
                                        dentalInterpretation?.dentalAssessment?.decolorationInterpretation || autoStatements.decoloration
                                    } />}
                        </div> */}
                    </>
                </div>
            </div>
        </div>
    )
}

export default DentalDoctorValidation