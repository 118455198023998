import React, {useState} from 'react'
import Calendar from 'react-calendar'
import './ScheduleCalendar.css';

export default function ScheduleCalendar({value, onchange}) {


  return (
    <>
      <Calendar 
      value={value}
      onChange={(e)=>onchange(e)}
      selectRange = {true}
      />
    </>
  )
}
