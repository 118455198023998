import React, { useEffect, useState } from 'react'
import classes from './BulkReportDownload.module.css'
import { Button, CircularProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import studentReducer from '../../redux/reducers/student.reducer'
import InputDropdown from '../common/InputDashboard/InputDropdown'
import { textFormat } from '../../utils/utility'
import BlankDataScreen from '../BlankDataScreen/BlankDataScreen'
import { Link } from 'react-router-dom'
import ReportDownloadPage from './ReportDownloadPage'
import screeningReducer from '../../redux/reducers/screening.reducer'
import { Buffer } from "buffer";
import eventReducer from '../../redux/reducers/event.reducer'
import { API } from 'aws-amplify'
import Cookies from 'js-cookie'
import axios from 'axios'
import JSZip, { generateAsync } from 'jszip';
import saveAs from 'file-saver'

function BulkReportDownload() {

    const dispatch = useDispatch()
    const location = useLocation()

    console.log(location.state.schoolData)

    const eventMapData = useSelector((state) => state.eventReducer.eventMapData);

    const [selectedClass, setSelectedClass] = useState("")
    const [page, setPage] = useState(1);
    const [displayData, setDisplayData] = useState([])
    const [downloadReport, setDownloadReport] = useState(false)
    const [screeningIds, setScreeningIds] = useState([])
    const [loading, setLoading] = useState(false)
    const [count, setCount] = useState(0)
    
    const handleChange = (event, value) => {
        setPage(value);
    };

    const classList = Object.keys(JSON.parse(location.state.schoolData?.classMap)).map((x)=>x.split("_").join("-"))
    console.log(classList)

    const students = useSelector((state)=>state.studentReducer.data)

    const getStudentsbySection = studentReducer.actions.getStudentBySectionRequest;
    const getReport = screeningReducer.actions.getBulkReportRequest;
    const reportData = useSelector((state)=>state.screeningReducer.bulkReportData)
    const getEventMaps = eventReducer.actions.getEventMapsRequest;
    const resetReportData = screeningReducer.actions.resetBulkReportData

    useEffect(()=>{
        console.log(reportData)
    },[reportData])

    useEffect(()=>{
        dispatch(getEventMaps(location.state?.schoolData?.schoolId))
    },[])

    useEffect(() => {
        dispatch(resetReportData())
    }, [downloadReport])
    

    useEffect(()=>{
        if(location.state.schoolData.isClinic){
            dispatch(getStudentsbySection({
                schoolId: location.state.schoolData.schoolId, 
                classVal: `${selectedClass.split("-")[0]}-${selectedClass.split("-")[1]}`, 
                section: selectedClass.split("-")[2]
            }))
        }
        else{
            dispatch(getStudentsbySection({
                schoolId: location.state.schoolData.schoolId, 
                classVal: selectedClass.split("-")[0], 
                section: selectedClass.split("-")[1]
            }))
        }
    },[selectedClass])

    useEffect(()=>{
        const x = students?.filter((y)=>y?.status==="VALIDATION_DONE")
        const z = x.map((el)=>el?.screenings?.[el.screenings?.length-1])
        setScreeningIds(z)
        setDisplayData(x)
    },[students])

    const handleBulkUpload = () => {
        for(let i=0; i<screeningIds?.length;i++){
            dispatch(getReport(screeningIds[i]))
        }
        setDownloadReport(true)
    } 


    const getReportPdf = (sId) => {

    }

    const handleBulkDownload = async () => {

        setLoading(true)

        const token = Cookies.get("tokenId")
        let cls = selectedClass.split("-")[0]
        let sec = selectedClass.split("-")[1]
        if(location.state.schoolData.isClinic){
            cls = `${selectedClass.split("-")[0]}-${selectedClass.split("-")[1]}`
            sec = selectedClass.split("-")[2]
        }
        const x = eventMapData.filter((el)=> el.class===cls && el.section===sec)
        console.log(cls, sec, x)
        const params = {
            eventId: x[0]?.eventId,
            mappingId: x[0]?.mapId,
            schoolId: x[0]?.schoolId
        }

        const init = {
            method: 'GET',
            body: params,
            response: true,
            queryStringParameters: params,
            headers: {
              "Authorization": token
            }
        }

        const y = await API.get("bulkReportDownload",'/report-download', init).then((res)=> res).catch((err)=>err)
        const urls = y.data.data.urls
        const zip = new JSZip();
        let i = 0
        for (let url of urls) {
            const x = await fetch(url)
            const pdfBlob = await x.blob();
            const sId = url.split('/')[7].split('.')[0]
            const l = displayData.filter((q)=>q.screenings.includes(sId))
            zip.file(`${l[0].firstName} ${l[0].lastName}.pdf`, pdfBlob)
            setCount(prev=>prev+1)
        }
        console.log(zip)
        zip.generateAsync({type:"blob"}).then(function(content) {
            // see FileSaver.js
            saveAs(content, `${selectedClass} Reports.zip`);
            setLoading(false)
            setCount(0)
        });
    }

    // const toBase64 = (x) => new Promise((resolve, reject) => {
    //     if (x) {
    //       const readers = new FileReader();
    //       readers.readAsArrayBuffer(x);
    //       readers.onload = () => {
    //         resolve(Buffer.from(readers.result).toJSON())
    
    //       };
    //       readers.onerror = error => reject(error);
    //     }
    
    //   });
      const uploadReport = screeningReducer.actions.uploadReportRequest;

    
    // const handle = async(e) => {
    //     const x = e.target.files[0]
    //     const y = await toBase64(x)
    //     dispatch(uploadReport({logo: JSON.stringify(y), screeningId: "screeningIds[i]", schoolId:"location.state.schoolData?.schoolId"}))
    // }
  return (
    <>
        {downloadReport ? <ReportDownloadPage screeningIds={screeningIds} data={reportData} setDownloadReport={setDownloadReport}/> : 
        (loading ? 
            <>
                <div style={{display: "flex", justifyContent:"center", height:"90%", alignItems:"center", flexDirection:"column", width:"70%", zIndex:999, position:"absolute",}}>
                    <CircularProgress size={50}/>
                    <div style={{marginTop:40}}>Please Wait, your reports are being downloaded</div>
                    <div style={{marginTop:20}}>{`${count}/${screeningIds.length}`}</div>
                </div>
            </> :
            <> <div className={classes.filterContainer}>
            <InputDropdown
                placeholder={"Class"}
                width={"15vw"}
                bgColor="#ffffff"
                margin={2.4}
                value={selectedClass}
                setValue={setSelectedClass}
                defaultValues={classList}
            />
            <div style={{display:"flex", gap:10}}>
                <Button
                    style={{cursor:"pointer", borderRadius:10, backgroundColor:"#1740A1", color:"#fff", fontWeight:600,border:"none", height:"fit-content", alignItems:"center"}}
                    onClick={handleBulkUpload}
                    className={classes.downloadButton}
                >
                    Upload Reports
                </Button>
                <Button
                    style={{cursor:"pointer", borderRadius:10, backgroundColor:"#1740A1", color:"#fff", fontWeight:600,border:"none", height:"fit-content", alignItems:"center"}}
                    onClick={handleBulkDownload}
                    className={classes.downloadButton}
                >
                    Download Reports
                </Button>
            </div>
        </div>
        {(selectedClass && displayData.length>0) ? <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={{backgroundColor:"#E8E7E7"}}>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Class</TableCell>
                        <TableCell align="right">DOB</TableCell>
                        <TableCell align="right">Gender</TableCell>
                        <TableCell align="right">Package</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {displayData?.slice(10*(page-1), 10*page).map((row) => (
                        <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row.firstName + " " + row.lastName}
                        </TableCell>
                        <TableCell align="right">{row.class + " " + row.section}</TableCell>
                        <TableCell align="right">{row.DOB}</TableCell>
                        <TableCell align="right">{row.gender}</TableCell>
                        <TableCell align="right">{textFormat(row.package)}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{marginTop:20, display:"flex",justifyContent:"center", marginTop:30}}>
                <Pagination count={Math.ceil(displayData?.length/10) ?? 1} page={page} onChange={handleChange} />
            </div>
        </> : 
        <BlankDataScreen
            title="Oops! No data available" 
            description="Please select the class from above or try changing it." 
            hideButton={true}  
        />
        }</>)}
    </>
  )
}

export default BulkReportDownload