import React, { useEffect, useState } from 'react'
import classes from './DocInterpretation.module.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SendIcon from '@mui/icons-material/Send';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ChatCompletionResponseMessageRoleEnum } from 'openai';

const DocInterpretation = (props) => {
    const { updateInterpretation, updateType, } = props;
    const [isEdit, setIsEdit] = useState(false);
    const [docInput, setDocInput] = useState();

    const handleInterpretationUpdate = (e) => {
        if (e) {
            e.preventDefault();
        }
        let updatedInterpretation
        if (props?.objArray) {

            updatedInterpretation = [
                ...props?.objArray
                    .filter((objs) => !(objs.issue === props.issueType)),
                {
                    issue: props?.issueType,
                    interpretation: docInput
                }
            ]
        } else {
            updatedInterpretation = docInput;
        }
        updateInterpretation(updateType, updatedInterpretation, e);
        setIsEdit(false);
    }

    useEffect(() => {
        setDocInput(props?.interpretation)
    }, [props.interpretation])


    const handleInputChange = (e) => {
        setDocInput(e.target.value);
    }

    return (
        <div className={classes.docInterpretationCon}>
            {props?.interpretation
                ?
                <div>
                    <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>
                            {
                                props.issueType ?
                                    props.issueType + " " + "Interpretation"
                                    : "Interpretation"
                            }
                        </div>
                    </div>
                    {
                        isEdit
                            ? <div className={classes.editInterpretation}>
                                <form onSubmit={handleInterpretationUpdate} style={{ width: "100%" }}>
                                    <input autoFocus onChange={handleInputChange} style={{ width: "100%", textAlign: 'start' }} value={docInput} type="text" name="" id="" placeholder="Enter your interptrtation" />
                                </form>
                                <SendIcon sx={{ fill: '#222CC9' }} onClick={(e) => { handleInterpretationUpdate(e) }} />
                            </div>
                            :
                            <div>
                                <div className={classes.docEditText} >
                                    <p>{props?.interpretation}</p>
                                    <EditNoteIcon onClick={() => { setIsEdit(true) }} sx={{ fill: "#222CC9", fontSize: "20px" }} />
                                </div>
                            </div>
                    }
                </div>
                :
                <div className={classes.docInterpretation}>
                    <div>
                        <AddCircleIcon sx={{ fill: "#66707E", fontSize: "20px" }} />
                        <p>Add doctor interpretation</p>
                    </div>
                    <input type="checkbox" />
                    <div className={classes.addInterpretation}>
                        <form onSubmit={(e) => { updateInterpretation(updateType, docInput, e) }} style={{ width: "100%" }}>
                            <input autoFocus type="text" name="" style={{ width: "100%", textAlign: 'start' }} onChange={handleInputChange} placeholder="Enter your interptrtation" value={docInput} />
                        </form>
                        <SendIcon sx={{ fill: '#222CC9' }} onClick={(e) => { updateInterpretation(updateType, docInput, e) }} />
                    </div>
                </div>
            }
        </div>
    )
}

export default DocInterpretation