import React, { useState } from "react";
import classes from "./BlogCard.module.css";
import { Link } from 'react-router-dom'
import VideoCardModal from './VideoCardModal'

export const BlogCard = ({text, articleLink, type, videoTopic, videoLink}) => {
  return (
    <>
    {type === 'Blog' ? 
    (
      <div className={classes.blog_cards_container}>
      <div>{text}</div>
      <Link>
        <div>Link to document or article</div>
      </Link>
    </div> 
    ) : 
    (
      <VideoCardModal 
      videoLink = {videoLink}
      videoTopic = {videoTopic}
       />
    )}
    </>
  );
};
