import React, { useEffect, useState } from "react";
import classes from "./EditIndividual.module.css";
import ArrowRight from "@mui/icons-material/ArrowRight";
import schoollogo from "../../assets/images/schoollogo.png";
import { Link, useParams } from "react-router-dom";
import InputDashboard from "../common/InputDashboard/InputDashboard";
import InputDropdown from "../common/InputDashboard/InputDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import DatePickerInput from "../common/InputDashboard/DatePickerInput";
import { State, City } from "country-state-city";
import { useDispatch } from "react-redux";
import studentReducer from "../../redux/reducers/student.reducer";
import DashboardLayout from "../common/DashboardLayout/DashboardLayout";
import { Alert, FormControl, InputLabel, MenuItem, Select, Snackbar } from "@mui/material";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import PackageDrawer from "../common/PackageDrawer/PackageDrawer";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";
import Nortificaton from "../../assets/audios/notificationSound.mp3"

function AddEditPerson(props) {
  const notify = new Audio(Nortificaton);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [dobError, setDobError] = useState(false);
  const [dateOfJoining, setDateOfJoining] = useState(null);
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [admissionNo, setAdmissionNo] = useState();
  const [studentClass, setStudentClass] = useState("");
  const [studentSection, setStudentSection] = useState("");
  const [studentBloodGroup, setStudentBloodGroup] = useState("");
  const [studentRhType, setStudentRhType] = useState("");
  const [selectedState, setSelectedState] = useState();
  const [studentFatherName, setStudentFatherName] = useState("");
  const [studentFatherMobileno, setStudentFatherMobileno] = useState("");
  const [studentFatherEmailid, setStudentFatherEmailid] = useState("");
  const [studentMotherName, setStudentMotherName] = useState("");
  const [studentMotherMobileno, setStudentMotherMobileno] = useState("");
  const [studentMotherEmailid, setStudentMotherEmailid] = useState("");
  const [showPackageDrawer, setShowPackageDrawer] = useState(false);
  const [planName, setPlanName] = useState("")

  const [vitalSigns, setVitalSigns] = useState([]);
  const [hair, setHair] = useState([])
  const [eye, setEye] = useState([])
  const [ear, setEar] = useState([])
  const [dental, setDental] = useState([])
  const [throat, setThroat] = useState([])
  const [heart, setHeart] = useState([])
  const [lungs, setLungs] = useState([])
  const [abdomen, setAbdomen] = useState([])
  const [skin, setSkin] = useState([])
  const [behavioral, setBehavioral] = useState([])
  const [learning, setLearning] = useState([])


  const location = useLocation();
  const navigate = useNavigate();

  

  useEffect(() => {
    const states = State.getStatesOfCountry("IN").map((state) => state.name);
    setStateOptions(states);
  }, []);

  useEffect(() => {
    if (selectedState) {
      const selectedStateObject = State.getStatesOfCountry("IN").filter(
        (x) => x.name === selectedState
      );
      setCityOptions(
        City.getCitiesOfState("IN", selectedStateObject[0].isoCode).map(
          (x) => x.name
        )
      );
    }
  }, [selectedState]);

  const textFormat = (text) => {
    if (text === "SPO2") {
      return 'SpO2'
    } else if (text === "HEIGHT_AND_WEIGHT") {
      return "Height and Weight"
    } else if (text === "COLOR_OF_HAIR") {
      return "Color of Hair"
    } else if (text === "Foreign_Body_In_Canal".toUpperCase()) {
      return "Foreign Body in Canal"
    } else if (text === "DMFT") {
      return "DMFT"
    } else if (text === "ADHD") {
      return "ADHD"
    } else if (text === "LOSS_OF_HAIR") {
      return "Loss of Hair"
    }
    text = text?.split("_")
    let a = ""
    for (let i = 0; i < text?.length; i++) {
      const element = text[i];
      const x = element.slice(0, 1).toUpperCase() + element.slice(1).toLowerCase()
      if (i === 0) {
        a = a + x
      } else {
        a = a + " " + x;
      }
    }
    return a
  }

  const changePlan = () => {

  }

  useEffect(() => {
    let vitalSigns
    let hair
    let eye
    let ear
    let dental
    let throat
    let heart
    let lungs
    let abdomen
    let skin
    let behavioral
    let learning
    if (planName === "Basic") {
      vitalSigns = (["Temperature", "Height and Weight", "SpO2", "Heart Rate"])
      hair = ([])
      eye = (["Myopia", "Hyperopia"])
      ear = (["Otitis Media", "Otitis Externa", "Ear Wax", "Foreign Body in Canal"])
      dental = (["Gingivitis"])
      throat = (["Throat Congestion"])
      heart = ([])
      lungs = ([])
      abdomen = ([])
      skin = ([])
      behavioral = (["ADHD", "Autism", "Anxiety", "Digital Dependency"])
      learning = ([])
    } else if (planName === "Advanced") {
      vitalSigns = (["Temperature", "Height and Weight", "SpO2", "Heart Rate", "Hemoglobin", "Blood Pressure"])
      hair = (["Dandruff", "Pediculosis (louse/lice)", "Loss of Hair"])
      eye = (["Myopia", "Hyperopia", "Astigmatism", "Anisocoria", "Anisometropia", "Gaze Asymmetry"])
      ear = (["Otitis Media", "Otitis Externa", "Ear Wax", "Foreign Body in Canal", "Hearing"])
      dental = (["DMFT", "Gingivitis"])
      throat = (["Stomatitis", "Glossitis", "Pharyngitis", "Tonsillitis", "White Patches", "Throat Congestion"])
      heart = (["Arrhythmia", "Heart Murmur"])
      lungs = (["Dyspnea", "Wheezing", "Cough"])
      abdomen = (["Stomach Pain", "Acidity", "Constipation"])
      skin = (["Scabies", "Rashes", "Blisters", "Urticaria"])
      behavioral = (["ADHD", "Autism", "Anxiety", "Conduct Disorder", "Oppositional Defiant Disorder", "Digital Dependency", "Depression", "Development Milestones"])
      learning = (["Dyslexia", "Dyscalculia", "Dysgraphia"])
    } else if (planName === "Certificate Plus") {
      vitalSigns = (["Temperature", "Height and Weight", "SpO2", "Heart Rate", "Hemoglobin", "Blood Pressure"])
      hair = (["Dandruff", "Pediculosis (louse/lice)", "Loss of Hair"])
      eye = (["Myopia", "Hyperopia"])
      ear = (["Otitis Media", "Otitis Externa", "Ear Wax", "Foreign Body in Canal"])
      dental = (["DMFT", "Gingivitis"])
      throat = (["Throat Congestion"])
      heart = ([])
      lungs = (["Cough"])
      abdomen = ([])
      skin = (["Scabies", "Rashes", "Blisters", "Urticaria"])
      behavioral = (["ADHD", "Autism", "Anxiety", "Digital Dependency"])
      learning = ([])
    } else {

      const packageDetails = location.state.data?.screeningPackageDetails

      ear = packageDetails.ear?.map((x) => textFormat(x))
      eye = packageDetails.eye?.map((x) => textFormat(x))
      abdomen = packageDetails.abdomen?.map((x) => textFormat(x))
      vitalSigns = packageDetails.vitalSigns?.map((x) => textFormat(x))
      hair = packageDetails.hair?.map((x) => textFormat(x))
      dental = packageDetails.dental?.map((x) => textFormat(x))
      throat = packageDetails.throat?.map((x) => textFormat(x))
      heart = packageDetails.heart?.map((x) => textFormat(x))
      lungs = packageDetails.lungs?.map((x) => textFormat(x))
      skin = packageDetails.skin?.map((x) => textFormat(x))
      behavioral = packageDetails.behavioralScreening?.map((x) => textFormat(x))
      learning = packageDetails.learningScreening?.map((x) => textFormat(x))
    }

    setVitalSigns(vitalSigns)
    setHair(hair)
    setEye(eye)
    setEar(ear)
    setDental(dental)
    setThroat(throat)
    setHeart(heart)
    setLungs(lungs)
    setAbdomen(abdomen)
    setSkin(skin)
    setBehavioral(behavioral)
    setLearning(learning)
  }, [planName])


  useEffect(() => {
    const values = location.state?.data;

    let rhType;
    if (values?.bloodGroup?.rhType === "Pos") {
      rhType = "+";
    } else if (values?.bloodGroup?.rhType === "Neg") {
      rhType = "-";
    }
    setAdmissionNo(values?.admissionNumber ?? "");
    setFirstName(values?.firstName ?? "");
    setLastName(values?.lastName ?? "");
    setGender(values?.gender ?? "");
    setDateOfBirth(values?.DOB ?? "");
    setStudentClass(textFormat(values?.class) ?? "");
    setStudentSection(values?.section ?? "");
    setStudentBloodGroup(values?.bloodGroup?.group ?? "");
    setStudentRhType(rhType);
    setStudentFatherName(values?.fatherName ?? "");
    setStudentFatherMobileno(values?.fatherContact?.mobile ?? "");
    setStudentFatherEmailid(values?.fatherContact?.email ?? "");
    setStudentMotherName(values?.motherName ?? "");
    setStudentMotherMobileno(values?.motherContact?.mobile ?? "");
    setStudentMotherEmailid(values?.motherContact?.email ?? "");
    setPlanName(textFormat(location.state.data?.package) ?? "")
  }, []);

  useEffect(() => {

    if (location.state.data?.screeningPackageDetails) {
      const packageDetails = location.state.data?.screeningPackageDetails


      // setVitalSigns(packageDetails.vitalSigns ?? [])
      // setHair(packageDetails.throat ?? [])
      // setEye(packageDetails.eye ?? [])
      // setEar(packageDetails.ear ?? [])
      // setDental(packageDetails.dental ?? [])
      // setThroat(packageDetails.throat ?? [])
      // setHeart(packageDetails.heart ?? [])
      // setLungs(packageDetails.lungs ?? [])
      // setAbdomen(packageDetails.abdomen ?? [])
      // setSkin(packageDetails.skin ?? [])
      // setBehavioral(packageDetails.behavioralScreening ?? [])
      // setLearning(packageDetails.learningScreening ?? [])
      const earText = packageDetails.ear?.map((x) => textFormat(x))
      const eyeText = packageDetails.eye?.map((x) => textFormat(x))
      const abdomenText = packageDetails.abdomen?.map((x) => textFormat(x))
      const vitalSignsText = packageDetails.vitalSigns?.map((x) => textFormat(x))
      const hairText = packageDetails.hair?.map((x) => textFormat(x))
      const dentalText = packageDetails.dental?.map((x) => textFormat(x))
      const throatText = packageDetails.throat?.map((x) => textFormat(x))
      const heartText = packageDetails.heart?.map((x) => textFormat(x))
      const lungsText = packageDetails.lungs?.map((x) => textFormat(x))
      const skinText = packageDetails.skin?.map((x) => textFormat(x))
      const behavoralText = packageDetails.behavioralScreening?.map((x) => textFormat(x))
      const learningText = packageDetails.learningScreening?.map((x) => textFormat(x))

      setVitalSigns(vitalSignsText ?? [])
      setHair(hairText ?? [])
      setEye(eyeText ?? [])
      setEar(earText ?? [])
      setDental(dentalText ?? [])
      setThroat(throatText ?? [])
      setHeart(heartText ?? [])
      setLungs(lungsText ?? [])
      setAbdomen(abdomenText ?? [])
      setSkin(skinText ?? [])
      setBehavioral(behavoralText ?? [])
      setLearning(learningText ?? [])
    }
  }, [location.state])

  useEffect(() => {
    
  }, [behavioral])

  useEffect(() => {
    const selectedDate = new Date(dateOfBirth);
    const currentDate = new Date();
    
    if (selectedDate > currentDate) {
      setDobError(true);
    } else {
      setDobError(false);
    }
  }, [dateOfBirth]);

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
    notify.play();
  };
  const loading = studentReducer.actions.loading;
  const error = studentReducer.actions.error
  const handleClose = (event, reason) => {
    setOpen(false);
  };
  // 

  const createStudent = studentReducer.actions;
  const updateStudent = studentReducer.actions.updateStudentRequest;

  function camelize(text) {
    const a = text.toLowerCase()
      .replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
    return a.substring(0, 1).toLowerCase() + a.substring(1);
  }

  const handleSubmit = async () => {
    const earjoin = ear.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const eyejoin = eye.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const abdomenjoin = abdomen.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const vitalSignsjoin = vitalSigns.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const hairjoin = hair.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const dentaljoin = dental.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const throatjoin = throat.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const heartjoin = heart.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const lungsjoin = lungs.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const skinjoin = skin.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const behavoraljoin = behavioral.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const learningjoin = learning.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))

    const formData = {
      studentId: location.state?.data?.studentId,
      admissionNo,
      firstName,
      lastName,
      gender,
      dateOfBirth,
      role: "student",
      studentClass: studentClass.toUpperCase(),
      studentSection,
      studentBloodGroup,
      studentRhType,
      studentFatherName,
      studentFatherEmailid,
      studentFatherMobileno,
      studentMotherName,
      studentMotherMobileno,
      studentMotherEmailid,
      schoolId: location.state?.data?.schoolId,
      ear: earjoin,
      eye: eyejoin,
      abdomen: abdomenjoin,
      hair: hairjoin,
      skin: skinjoin,
      throat: throatjoin,
      heart: heartjoin,
      lungs: lungsjoin,
      vitalSigns: vitalSignsjoin,
      dental: dentaljoin,
      behavoral: behavoraljoin,
      learning: learningjoin,
      planName: location.state.data?.package,
      schoolCode: location.state.data?.schoolCode,
      package: planName.toUpperCase()?.split(" ")?.join("_")
    };
    await dispatch(updateStudent(formData));
    handleClick()
    // if(loading === false && error === false){
    //   setTimeout(() => {
    //     navigate(-1)
    //   }, 5000)
    // }
  };

  return (
    <div className={classes.mainDiv}>
      <BreadCrumbs />
      <div className={classes.container}>
        <h4>Student registration information</h4>
        {location.pathname.includes("student") ? (
          <></>
        ) : (
          <InputDashboard
            placeholder={"Email"}
            width={"32%"}
            className={classes.input_layout_schoolname}
            change={(e) => {
              setEmail(e.target.value);
            }}
            bgColor="#ffffff"
            value={email}
          />
        )}

        <div className={classes.lowlight_text}>
          {location.pathname.includes("student")
            ? "Personal information"
            : "New user login credentials will be sent to this email id"}
        </div>
        <div className={classes.upload_logo}>
          {/* <img alt="" src={schoollogo}></img> */}
          {/* <div className={classes.upload}>
                        <img alt="" src={upload}></img>
                        <div className={classes.upload_text}>Upload School Logo</div>
                    </div> */}
          <div className={classes.no_upload_logo}>
            <ImageRoundedIcon
              sx={{ fill: "#E8E7E7", width: "56px", height: "56px" }}
            />
          </div>
        </div>
        <div className={classes.input_school_layout}>
          <InputDashboard
            placeholder={"First Name"}
            value={firstName}
            width={"95%"}
            className={classes.input_layout_schoolname}
            change={(e) => {
              setFirstName(e.target.value);
            }}
            bgColor="#ffffff"

          />
          <InputDashboard
            value={lastName}
            placeholder={"Last Name"}
            width={"95%"}
            className={classes.input_layout_schoolname}
            change={(e) => {
              setLastName(e.target.value);
            }}
            bgColor="#ffffff"

          />
          {location.pathname.includes("student") ? (
            <></>
          ) : (
            <InputDropdown
              value={gender}
              placeholder={"Gender"}
              width={"95%"}
              className={classes.input_layout_boardname}
              bgColor="#fff"
              defaultValues={["male", "female", "other"]}
              setValue={setGender}
            />
          )}
        </div>
        <div className={classes.input_school_layout}>
          <div>

            <DatePickerInput
              onChange={(e) => {
                setDateOfBirth(e.target.value);
              }}
              width={"95%"}
              placeholder={"Date of birth"}
              value={dateOfBirth}
            />
            {dobError && (
              <div className={classes.validation_text}>
                Please enter Valid Date of birth*
              </div>
            )}
          </div>

          {location.pathname.includes("student") ? (
            <InputDropdown
              margin={2}
              bgColor={"#FFFFFF"}
              placeholder={"Gender"}
              width={"95%"}
              className={classes.input_layout_boardname}
              value={gender}
              defaultValues={["Male", "Female", "Other"]}
              setValue={setGender}
            />
          ) : (
            <DatePickerInput
              onChange={(e) => {
                setDateOfJoining(e.target.value);
              }}
              width={"95%"}
              placeholder={"Date of Joining"}
              value={dateOfJoining}
            />
          )}

          <InputDashboard
            margin={2}
            placeholder={"Admission Number"}
            width={"95%"}
            className={classes.input_layout_schoolname}
            change={(e) => {
              setAdmissionNo(e.target.value);
            }}
            bgColor="#ffffff"
            value={admissionNo}
            disabled={true}
          />
          {/* <BasicDatePicker /> */}
        </div>
        {location.pathname.includes("student") ? (
          <div className={classes.input_school_layout}>
            <InputDropdown
              disabled={true}
              margin={2.4}
              bgColor={"#FFFFFF"}
              placeholder={"Class"}
              width={"95%"}
              className={classes.input_layout_boardname}
              value={studentClass}
              defaultValues={[
                "Nursery",
                "LKG",
                "UKG",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12"
              ]}
              setValue={setStudentClass}
            />
            <InputDropdown
              disabled={true}
              margin={2.4}
              bgColor={"#FFFFFF"}
              placeholder={"Section"}
              width={"95%"}
              className={classes.input_layout_boardname}
              value={studentSection}
              defaultValues={[
                "A", "B", "C","D","E","F","G","H",
                "I","J","K","L","M","N","O","P","Q",
                "R","S","T","U","V","W","X","Y","Z"]}
              setValue={setStudentSection}
            />
            <FormControl
              variant="outlined"
              sx={{
                width: "23vw",
                height: "6.5vh",
                mt: 2.4,
              }}
              required
            >
              <InputLabel
                id="demo-simple-select-filled-label"
                sx={{
                  backgroundColor: "#FFF",
                  color: "#9d9d9d !important",
                  fontFamily: "Inter",
                  fontSize: "14px"
                }}
                size="small"
              >
                {"Package"}
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                onChange={(e) => {
                  setPlanName(e.target.value);
                }}
                size="small"
                value={planName}
                sx={{
                  backgroundColor: "#FFF",
                  "&:hover": {
                    backgroundColor: "#FFF",
                    color: '#9d9d9d'
                  },
                  "&:active": {
                    backgroundColor: "#FFF",
                    color: '#9d9d9d'
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E8E7E7",
                    borderRadius: "8px",
                    color: '#9d9d9d !important'
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E8E7E7",
                    color: '#9d9d9d !important'
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E8E7E7",
                    color: '#9d9d9d !important'
                  },
                  "& label.Mui-focused": {
                    color: "#9d9d9d !important"
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      color: "#9d9d9d !important"
                    }
                  },
                  ".MuiSvgIcon-root ": {
                    fill: "#6F7E90 !important",
                  },
                }}
              >
                {["Basic", "Advanced", "Certificate Plus", "Custom"]?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {planName === "Custom" && <div className={classes.package_drawer}>
              <PackageDrawer
                planName={location.state.data?.screeningPackageDetails?.packageType}
                showPackageDrawer={showPackageDrawer}
                setShowPackageDrawer={setShowPackageDrawer}
                vitalSigns={vitalSigns}
                setVitalSigns={setVitalSigns}
                hair={hair}
                setHair={setHair}
                eye={eye}
                setEye={setEye}
                ear={ear}
                setEar={setEar}
                dental={dental}
                setDental={setDental}
                throat={throat}
                setThroat={setThroat}
                heart={heart}
                setHeart={setHeart}
                abdomen={abdomen}
                setAbdomen={setAbdomen}
                lungs={lungs}
                setLungs={setLungs}
                skin={skin}
                setSkin={setSkin}
                behavioral={behavioral}
                setBehavioral={setBehavioral}
                learning={learning}
                setLearning={setLearning}
              />
            </div>}
          </div>
        ) : (
          <></>
        )}

        {location.pathname.includes("student") ? (
          <>
            <div className={classes.contact_info}>Health information</div>
            <div className={classes.input_layout}>
              <InputDropdown
                margin={2}
                placeholder={"Blood group"}
                width={"95%"}
                bgColor="#ffffff"
                value={studentBloodGroup}
                setValue={setStudentBloodGroup}
                defaultValues={["O", "A", "B", "AB"]}
                required={false}
              />

              <InputDropdown
                margin={2}
                placeholder={"Rh type"}
                width={"95%"}
                bgColor="#ffffff"
                value={studentRhType}
                setValue={setStudentRhType}
                defaultValues={["+", "-"]}
                required={false}
              />
            </div>
            <div className={classes.contact_info}>Parents information</div>
            <div className={classes.input_layout}>
              <InputDashboard
                margin={2}
                placeholder={"Fathers Name"}
                width={"95%"}
                className={classes.input_layout_schoolname}
                change={(e) => {
                  setStudentFatherName(e.target.value);
                }}
                bgColor="#ffffff"
                value={studentFatherName}
                required={false}
              />
              <InputDashboard
                margin={2}
                placeholder={"Mobile Number"}
                width={"95%"}
                className={classes.input_layout_schoolname}
                change={(e) => {
                  setStudentFatherMobileno(e.target.value);
                }}
                bgColor="#ffffff"
                value={studentFatherMobileno}
                required={false}
              />

              <InputDashboard
                margin={2}
                placeholder={"Email Id"}
                width={"95%"}
                className={classes.input_layout_schoolname}
                change={(e) => {
                  setStudentFatherEmailid(e.target.value);
                }}
                bgColor="#ffffff"
                value={studentFatherEmailid}
                required={false}
              />
            </div>
            <div className={classes.input_layout}>
              <InputDashboard
                margin={2}
                placeholder={"Mothers Name"}
                width={"95%"}
                className={classes.input_layout_schoolname}
                change={(e) => {
                  setStudentMotherName(e.target.value);
                }}
                bgColor="#ffffff"
                value={studentMotherName}
                required={false}
              />
              <InputDashboard
                margin={2}
                placeholder={"Mobile Number"}
                width={"95%"}
                className={classes.input_layout_schoolname}
                change={(e) => {
                  setStudentMotherMobileno(e.target.value);
                }}
                bgColor="#ffffff"
                value={studentMotherMobileno}
                required={false}
              />

              <InputDashboard
                margin={2}
                placeholder={"Email Id"}
                width={"95%"}
                className={classes.input_layout_schoolname}
                change={(e) => {
                  setStudentMotherEmailid(e.target.value);
                }}
                bgColor="#ffffff"
                value={studentMotherEmailid}
                required={false}
              />
            </div>
          </>
        ) : (
          <>
            <div className={classes.contact_info}>Contact information</div>
            <div className={classes.input_layout}>
              <InputDashboard
                placeholder={"Phone number"}
                width={"23vw"}
                change={(e) => {
                  setNumber(e.target.value);
                }}
                bgColor="#ffffff"
                value={number}
              />
            </div>
            <InputDashboard
              placeholder={"Address"}
              width={"65%"}
              change={(e) => {
                setAddress(e.target.value);
              }}
              bgColor="#ffffff"
              value={address}
            />
            <div className={classes.input_layout}>
              <InputDashboard
                placeholder={"Pincode"}
                width={"95%"}
                change={(e) => {
                  setPincode(e.target.value);
                }}
                bgColor="#ffffff"
                value={pincode}
              />
              <InputDropdown
                placeholder={"City"}
                width={"95%"}
                bgColor="#ffffff"
                value={city}
                setValue={setCity}
                defaultValues={cityOptions}
              />
              <InputDropdown
                placeholder={"State"}
                width={"95%"}
                bgColor="#ffffff"
                value={state}
                setValue={setSelectedState}
                defaultValues={stateOptions}
              />
            </div>
          </>
        )}

        {/* <div className={classes.contact_info}>Staff & Ameneties</div>
                <div className={classes.input_layout}>
                    <InputDashboard
                        placeholder={"Teachers"}
                        width={"23vw"}
                        change={(e) => {
                            
                            setSchoolName(e.target.value);
                        }}
                        bgColor="#ffffff"
                        value={schoolName}
                    />
                    <InputDashboard
                        placeholder={"Students"}
                        width={"23vw"}
                        change={(e) => {
                            
                            setSchoolName(e.target.value);
                        }}
                        bgColor="#ffffff"
                        value={schoolName}
                    />
                    <InputDropdown
                        placeholder={"Infirmary"}
                        width={"23vw"}
                        bgColor="white"
                        value={infirmary}
                        setValue={setInfirmary}
                    />

                    <CustomMultiSelectDropdown amenities={amenities} setAmenities={setAmenities} />
                </div> */}
        {/* <div className={classes.button_amenities_container}>
                    {amenities.map((el, index) => {
                        return (
                            <div key={index + Date.now()} className={classes.button_amenities}>
                                <p>{el}</p>
                                <button onClick={() => handleDeleteAmenities(el)}>
                                    <img src={NotificationClose} alt="Notification-Close" />
                                </button>
                            </div>
                        )
                    })}
                </div> */}

        {!loading &&
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={
                !error
                  ? "success"
                  : "error"
              }
              sx={{ width: "100%" }}
            >
              {!error ? "Successfully Updated Student" : "Something Wrong! Try Again"}
            </Alert>
          </Snackbar>
        }
        <div className={classes.buttons}>
          {/* <button className={classes.button_with_border}>
                        {schoolName && amenities && board && orientation && city && state && infirmary && amenities.length > 0 ?
                            "CANCEL" : "SAVE"}
                    </button> */}
          <button onClick={handleSubmit} className={classes.button_filled}
            disabled={
              firstName &&
                lastName &&
                !dobError &&
                dateOfBirth &&
                admissionNo &&
                studentClass &&
                studentSection
                ? false
                : true
            }>
            Update Student
          </button>
        </div>
      </div>
    </div>
  );
}

function EditIndividual() {
  const { person } = useParams();
  let formDetails;
  if (person === "doctor") {
    formDetails = {
      breadCrumTitle: "Edit Doctor",
      formTitle: "Doctor registration information",
    };
  } else if (person === "nurse") {
    formDetails = {
      breadCrumTitle: "Edit Nurse",
      formTitle: "Nurse registration information",
    };
  } else if (person === "staff") {
    formDetails = {
      breadCrumTitle: "Edit Staff",
      formTitle: "Staff registration information",
    };
  } else if (person === "schooladmin") {
    formDetails = {
      breadCrumTitle: "Edit School Admin",
      formTitle: "School Admin registration information",
    };
  } else if (person === "principal") {
    formDetails = {
      breadCrumTitle: "Edit Principal",
      formTitle: "Principal registration information",
    };
  } else if (person === "teacher") {
    formDetails = {
      breadCrumTitle: "Edit Teacher",
      formTitle: "Teacher registration information",
    };
  } else if (person === "counsellor") {
    formDetails = {
      breadCrumTitle: "Edit Counsellor",
      formTitle: "Counsellor registration information",
    };
  } else if (person === "infirmary") {
    formDetails = {
      breadCrumTitle: "Edit Infirmary",
      formTitle: "Infirmary registration information",
    };
  } else if (person === "student") {
    formDetails = {
      breadCrumTitle: "Edit Student",
      formTitle: "Student registration information",
    };
  }

  return <AddEditPerson formDetails={formDetails} />;
}

export default EditIndividual;
