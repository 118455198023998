import React from "react";
import classes from "./Anxiety2.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import clsx from "clsx";


export const Anxiety3 = ({ data }) => {
  const response = JSON.parse(data?.ANXIETY)?.response
  const performanceQuestionBank = [
    {
      qno: 27,
      question: "When my child gets frightened, he/she feels like he/she is choking?",
      answer: response?.[26]
    },
    {
      qno: 28,
      question:
        "People tell me that my child worries too much?",
      answer: response?.[27]
    },
    {
      qno: 29,
      question: "My child doesn’t like to be away from his/her family?",
      answer: response?.[28]
    },
    {
      qno: 30,
      question: "My child is afraid of having anxiety (or panic) attacks?",
      answer: response?.[29]
    },
    {
      qno: 31,
      question:
        "My child worries that something bad might happen to his/her parents?",
      answer: response?.[30]
    },
    {
      qno: 32,
      question:
        "My child feels shy with people he/she doesn’t know well?",
      answer: response?.[31]
    },
    {
      qno: 33,
      question: "My child worries about what is going to happen in the future?",
      answer: response?.[32]
    },
    {
      qno: 34,
      question: "When my child gets frightened, he/she feels like throwing up?",
      answer: response?.[33]
    },

    {
      qno: 35,
      question: "My child worries about how well he/she does things?",
      answer: response?.[34]
    },

    {
      qno: 36,
      question:
        "My child is scared to go to school?",
      answer: response?.[35]
    },

    {
      qno: 37,
      question: "My child worries about things that have already happened?",
      answer: response?.[0]
    },

    {
      qno: 38,
      question: "When my child gets frightened, he/she feels dizzy?",
      answer: response?.[36]
    },

    {
      qno: 39,
      question: "My child feels nervous when he/she is with other children or adults and he/she has to do something while they watch him/her (for example: read aloud, speak, play a game, play a sport)?",
      answer: response?.[37]
    },

    {
      qno: 40,
      question: "My child feels nervous when he/she is going to parties, dances, or any place where there will be people that he/she doesn’t know well?",
      answer: response?.[38]
    },

    {
      qno: 41,
      question: "My child is shy?",
      answer: response?.[39]
    }
  ];

  return (
    <>
      <div className={clsx(classes.container, 'xyz')}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>

        <div className={classes.question_container}>
          <div>Assessment Questionnaire Continued...</div>
          <div className={classes.question_select_container}>
            {performanceQuestionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Not true"
                          checked={el.answer === "NOT_TRUE"}
                        />
                        <p style={{ width: "60px" }}>
                          Not true
                        </p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Somewhat true"
                          checked={el.answer === "SOMEWHAT_TRUE"}
                        />
                        <p style={{ width: "60px" }}>
                          Somewhat true
                        </p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Very true"
                          checked={el.answer === "VERY_TRUE"}
                        />
                        <p style={{ width: "55px" }}>Very true</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes.footer_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 3 of 4</p>
          </div>
        </div>
      </div>
    </>
  );
};
