import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Validation.module.css";
import ValidationRow from "./ValidationRow";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";
import TabButton from "../common/TableComponents/TabButton";
import SearchBar from '../common/TableComponents/SearchBar'
import { City } from "country-state-city";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CustomAutoComplete from "../common/CustomAutoComplete/CustomAutoComplete";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import Cookies from "js-cookie";
import { Filter } from "../common/TableComponents/Filter";
import { useDispatch, useSelector } from "react-redux";
import validationReducer from "../../redux/reducers/validation.reducer";
import moment from "moment";
import BlankDataScreen from "../BlankDataScreen/BlankDataScreen";

function ValidationProgressPage() {
  const token = Cookies.get("tokenId");
  const location = useLocation();
  const navigate = useNavigate();

  const data = useSelector((state) => state.validationReducer.data)
  const loading = useSelector((state) => state.validationReducer.loading)

  useEffect(() => {
    
  }, [data])



  const allCities = [...new Set(City.getCitiesOfCountry("IN").map((x) => x.name))]

  const [open, setOpen] = useState(false);
  const [sec, setSec] = useState(false);
  const [text, setText] = useState("");
  const [displayData, setDisplayData] = useState(data ?? []);
  const [city, setCity] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageNo, setPageNo] = useState(1)

  useEffect(() => {
    if (city && text) {
      setDisplayData(data.filter((x) => x.city === city && x.schoolName.toLowerCase().includes(text.toLowerCase())))
      setPageNo(1)
    }
    else if (city) {
      setDisplayData(data.filter((x) => x.city === city))
      setPageNo(1)
    }
    else if (text) {
      setDisplayData(data.filter((x) => x?.doctorName?.toLowerCase()?.includes(text?.toLowerCase())))
      setPageNo(1)
    }
    else {
      setDisplayData(data)
    }
  }, [city, text])

  useEffect(() => {
    setDisplayData(data)
  }, [data])


  const handleOpen = () => {

    setOpen(!open);
  };
  const handleCity = (x) => {
    setCity(x)

    if (displayData?.length === data?.length) {
      setDisplayData(data.filter((x) => x.city === x))
    }
    // setDisplayData()
  };


  return (
    <div className={classes.main_div}>
      <div className={classes.student_layout}>
        <div>
          <BreadCrumbs />
        </div>
        <div className={classes.tabBtn_container}>
          <TabButton role="Doctor" />
        </div>
      </div>
      {
        data?.length > 0 ?
          <>
            <div className={classes.student_filters}>
              <div className={classes.show_text}>Show</div>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={rowsPerPage}
                  label="Rows"
                  onChange={(e) => setRowsPerPage(e.target.value)}
                  size='small'
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              {/* <CustomAutoComplete options={allCities} value={city} placeholder={"City"} onChange={setCity}/> */}
              <SearchBar id={"searchCy"} onchange={setText} value={text} />
              {/* <Filter /> */}
            </div>
            {/* Student Table Header */}
            <div className={classes.student_table_header}>
              <div>DOCTOR NAME</div>
              <div>TITLE</div>
              <div>EMAIL ID</div>
              <div>VALIDATION TYPE</div>
              <div>VALIDATION PROGRESS</div>
              <div>STATUS</div>
              <div>ACTION</div>
            </div>
            {/* Student Column-Rows Section */}
            <div id="schoolRowCy" className={classes.student_column_container}>
              {Array.isArray(displayData) && displayData?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo).map((el, index) => {
                return (
                  <ValidationRow data={el} key={index + Date.now()} index={index} />
                )
              })}
            </div>
            <div className={classes.pagination_container}>
              <div className={classes.page_item}>{`Showing ${(rowsPerPage * (pageNo - 1)) + 1} to ${pageNo * rowsPerPage <= displayData?.length ? pageNo * rowsPerPage : displayData?.length} of ${displayData?.length} items`}</div>
              <div className={classes.pagination}>
                <Button size="small" disabled={pageNo === 1} onClick={() => setPageNo(pageNo - 1)} >
                  <ChevronLeft />
                </Button>
                <span>{pageNo}</span>
                <div>
                  <span>of</span>
                  <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
                </div>

                <div>
                  <Button size="small" disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)} onClick={() => setPageNo(pageNo + 1)} >
                    <ChevronRight />
                  </Button>
                </div>
              </div>
            </div>
          </>
          :
          loading ? "" :
            <BlankDataScreen title="Oops! No data available" description="There is no data uploaded in this table to be shown. Click on Add Doctor to upload data." handleClick="" buttonText="Add Doctor" role="doctor" />

      }


    </div>
  );
}

function ValidationProgress() {

  const dispatch = useDispatch();

  const getValidationProgress = validationReducer.actions.getDoctorValidationProgressRequest;
  const today = moment().format("YYYY-MM-DD")
  const threedaysBefore = moment().subtract(3, 'd').format('YYYY-MM-DD')

  useEffect(() => {
    dispatch(getValidationProgress({ fromDate: threedaysBefore, toDate: today }))
  }, [])


  return (
    <ValidationProgressPage />
  );
}
export default ValidationProgress;