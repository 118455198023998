import {Auth} from 'aws-amplify';

export async function signUp(countryOption, phone, username, firstName, lastName) {
    
    const phone_number = `${countryOption}${phone}`
    const password = '';
    try {
        const { user } = await Auth.signUp({
            username,
            password,
            attributes: {
                phone_number,
                given_name: firstName,
                family_name: lastName,
                middle_name:'',
                gender: 'male'
            },
        });
        
    } catch (error) {
        alert('error signing up:', error);
    }
}