import React, { useEffect, useState } from 'react'
import VideoComp from '../VideoSlideshow/VideoComp';
import { Button, Modal } from '@mui/material';
import axios from 'axios';
import { Buffer } from 'buffer';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { useDispatch, useSelector } from 'react-redux';
import incrementReducer from '../../../../redux/reducers/increment.reducer';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';

function ImageToVideo({ data }) {

    const dispatch = useDispatch()
    const increment = incrementReducer.actions.incrementRequest;
    const pause = incrementReducer.actions.pauseVideo;
    const playVideo = incrementReducer.actions.playVideo;
    const resetVideo = incrementReducer.actions.resetIncrementRequest;
    const index = useSelector((state)=>state.incrementReducer.index)
    const play = useSelector((state)=>state.incrementReducer.play)

    const [imgArr, setImgArr] = useState([])
    const [tempdata, settempData] = useState(data ?? [])
    const [videoURL, setVideoURL] = useState(null)
    // const [index, setIndex] = useState(0)
    // const [play, setPlay] = useState(false);

    const slideshow_container = {
        position: 'relative',
        height: '550px',
        width: '740px',
        borderRadius: '10px',
        top: '50%',
        left: '50%',
        zIndex: 9999,
        backgroundColor: '#000',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: "none"
    }

    // const toBase64 = (z) => {
    //     let base64 = ''
    //     axios.get(z).then((x) => {
    //         const buffer = Buffer.from(x.data, 'binary');
    //         base64 = buffer.toString('base64');
    //         setImgArr([...imgArr, base64])
    //         return base64;
    //     }).catch(err=>
    // }

    useEffect(()=>{
        const intervalId = setInterval(() => {
            dispatch(increment(data?.length))
        }, 200);
    },[])
    


    async function startTimer() {
        // startInter==
    }

    function pauseTimer() {
        // clearInterval(intervalId)
        dispatch(pause())
    }

    function resumeTimer() {
        dispatch(playVideo())
        // startTimer();
    }

    // useEffect(() => {
    //     const base = data?.map(z => toBase64(z))
    //     
    // }, [data])

    useEffect(() => {
        
    }, [imgArr])


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {setOpen(false); dispatch(resetVideo())};

    return (
        <div>
            <VideoComp onClick={() => { handleOpen() }} imageURL={Array.isArray(data) ? data[0] : ""} />
            <div id="myLoopHolder">
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {/* <Box sx={style}> */}
                <div style={slideshow_container}>
                    <img src={Array.isArray(data) ? data[index] : ''} width={"100%"} height={"85%"} />
                    {/* <button onClick={resumeTimer}>Start</button>
                    <button onClick={pauseTimer}>Stop</button> */}
                    <div style={{width:"100%", display:'flex', justifyContent:'center'}}>
                        <Button
                            style={{border:"1px solid #fff", borderRadius:50, opacity:0.4, width:70, height:70}} 
                            onClick={()=>play ? pauseTimer() : resumeTimer()} 
                        >
                            {play ? 
                                <PauseRoundedIcon sx={{fill:"#fff",width:50,height:50,opacity:0.4}}/> : 
                              <PlayArrowRoundedIcon sx={{fill:"#fff",width:50,height:50,}}/>
                            }
                        </Button>
                    </div>
                </div>
                {/* </Box> */}
            </Modal>
        </div>
    )
}

export default ImageToVideo