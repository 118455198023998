import React from 'react'
import classes from './Rowpopup.module.css'
import view from '../../../assets/images/view.svg'
import deleteIcon from '../../../assets/images/icons-delete.svg'
import edit from '../../../assets/images/edit.svg'
import EventIcon from '@mui/icons-material/Event';
import { useNavigate, useLocation } from 'react-router-dom'
import SendIcon from '@mui/icons-material/Send';
import schoolReducer from '../../../redux/reducers/school.reducer'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Snackbar } from '@mui/material'
import { CalendarMonthRounded, Download, PublishOutlined } from '@mui/icons-material'
import blogReducer from '../../../redux/reducers/blog.reducer'


export default function RowPopup({ navLink, data, handlePublishBlog }) {

   const navigate = useNavigate()
   const location = useLocation()
   const dispatch = useDispatch()

   const [open, setOpen] = React.useState(false);

   const releaseReport = schoolReducer.actions.releaseReportRequest;
   const updateBlogStatus = blogReducer.actions.updateBlogStatusRequest;
   const getBlogs = blogReducer.actions.getBlogsRequest;


   const handleView = (whereTo) => {
      if (whereTo === 'edit') {
         navLink('edit');
      } else if (whereTo === 'info') {
         navLink('info');
      }else{
         
      }
   }

   const handleEventMap = () => {
      navigate(location.pathname.includes('partner') ? '/partner-events' : '/superadmin-events', {state: {schoolData: data}})
   } 
   
   const handleDownloadReport = () => {
      navigate(location.pathname.includes('partner') ? '/partner-download-reports' : '/superadmin-download-reports', {state: {schoolData: data}})
   }

   const handlePublish = () => {
      handlePublishBlog(data)
   }

   const handleReleasereport = () => {
      dispatch(releaseReport({schoolId: data?.schoolId, schoolCode: data?.schoolCode}))
   }

   return (<>
      <div className={classes.rowpopup_container}>
         <button onClick={() => { handleView('info') }}>
            <img src={view} alt="view" />
            <span>View</span>
         </button>
         <button onClick={() => { handleView('edit') }}>
            <img src={edit} alt="edit" />
            <span>Edit</span>
         </button>
         {/* <button onClick={() => { handleView('delete') }}>
            <img src={deleteIcon} alt="deleteIcon" />
            <span>Delete</span>
         </button> */}
         {!data?.isReport && (location.pathname==="/superadmin-school" || location.pathname==="/partner-school") &&
         <button onClick={handleReleasereport}>
            <SendIcon style={{width:13, height:13}}/>
            <span>Release Reports</span>
         </button>}
         {(location.pathname==="/superadmin-school" || location.pathname==="/partner-school") &&
         <button onClick={handleEventMap}>
            <CalendarMonthRounded style={{width:13, height:13}}/>
            <span>Map Events</span>
         </button>}
         {data?.isReport && (location.pathname==="/superadmin-school" || location.pathname==="/partner-school") &&
         <button onClick={handleDownloadReport}>
            <Download style={{width:13, height:13}}/>
            <span>Download Reports</span>
         </button>}
         {location.pathname==="/writer-dashboard" &&
         <button onClick={handlePublish}>
            <PublishOutlined style={{width:13, height:13}}/>
            <span>{data?.status === "PUBLISHED" ? "Unpublish" : "Publish"}</span>
         </button>}
      </div>
   </>
   )
}