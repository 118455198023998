import { Avatar } from "@mui/material";
import React, { useState } from "react";
import classes from "./ManageScreeningRow.module.css";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { useLocation, useNavigate } from "react-router";
import RowPopup from "../common/TableComponents/RowPopup";
import { useDispatch } from "react-redux";
import InfoIcon from '@mui/icons-material/Info';
import { info } from "logrocket";
import { CollapseTable } from "../common/StudentTable/CollapseTable"; 

export default function ManageScreeningRows({data}) {
  const { studentId, firstName, lastName, DOB, gender, status } = data;
  const [user, setUser] = useState(false);
  const [openPop, setOpenPop] = useState(false)
  const location = useLocation();
  const navigate = useNavigate()

  const dispatch = useDispatch()
  function navigateStudent(x) {
    
    // if(location.pathname.includes('superadmin')){
    //   if(x === 'info'){
    //     navigate('/superadmin-screening', { state: { ...data, breadCrumbs: [...location.state.breadCrumbs, firstName + " " + lastName] } })
    //   }
    //   else if(x === 'edit') {
    //     navigate(`/superadmin/individual/edit/student`)
    //   }
    // }else if(location.pathname.includes('principal')){
    //   if(x === 'info'){
    //     navigate('/principal-screening', { state: { ...data } })
    //   }
    //   else if(x === 'edit') {
    //     navigate(`/principal/individual/student`, { state : {...data}})
    //   }
    //   else if(x === 'delete'){
    //       // const {  schoolId, studentId } = data
    //       // dispatch(deleteStudent({ schoolId, studentId}))
    //   }
    // }else if(location.pathname.includes('teacher')){
    //   if(x === 'info'){
    //     navigate('/teacher-screening', { state: { ...data } })
    //   }
    //   else if(x === 'edit') {
    //     navigate(`/teacher/individual/student`, { state : {...data}})
    //   }
    // }

  }

  const coldata = 
  [
    {
    labelName : "Form Received on 08-01-2023"
    }
  ]

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  const getWordAfterDash = () => {
    let word = location.pathname.split('-')
    return word[1];
  }

  const getSchoolInfo = (whereTo) => {
    function getWordBeforeDash() {
      let newStr = "";
      for (let i = 1; i < location.pathname.length; i++) {
        if (location.pathname[i] === "-") {
          return newStr;
        }
        newStr += location.pathname[i];
      }
    }

    if (getWordBeforeDash() === "superadmin") {
      navigate(`/${getWordBeforeDash()}-${getWordAfterDash()}/${whereTo}/${firstName}`, { state: data });
    }
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 12,
        height: 12,
        padding: 2
      },
      children: `${name[0]}`,
    };
  }

  const getAge = (age) => {
    var today = new Date();
    var birthDate = new Date(age);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }


  const handleReport = () => {
    setUser(true)
  }
  return (
    <>
      <div className={classes.studentRow_container}>

        {/* Name and Avatar Container */}
        <div>
          {/* Avatar Container */}
          <div>
            <Avatar {...stringAvatar(`${firstName} ${lastName}`)} />
          </div>

          {/* Name Container */}
          <div>
            <p>{(firstName && lastName) ? `${firstName} ${lastName}` : "Unavailable"}</p>
          </div>
        </div>

        {/* Age Container */}
        <div>
          <p>{DOB ? getAge(DOB) : "unavailable"}</p>
        </div>

        {/* Gender Container */}
        <div>
          <p>{gender ? gender : "unavailable"}</p>
        </div>


        <div>
          {/* <CollapseTable data={data.data} /> */}
          <CollapseTable data = {coldata}/>
        </div>
        {/* Screening Container */}
        <div>
          <div className={data?.screening  ? `${classes.screened_btn}` : `${classes.report_btn}`}>{data?.screening ? 'Schedule' : 'REPORT'}</div>
          {/* <div>{data?.screening ? 'Schedule' : 'Not Scheduled'}</div> */}
        </div>

        <div>
          <p>2 Doctors</p>
          <div className={classes.iconHover}>
                    <InfoIcon sx={{ fontSize: '17px', fill: '#66707E' }} />
                    <div className={classes.validationOnHover}  >
                      <p>Dr. Sanjay Singh</p>
                      <p>Validated on : 10-01-2023, 10:55 AM</p>
                    </div>
                  </div>
        </div>

        {/* Conditional Buttons Rendering */}


        {/* Action Container */}
        <div style={{ position: "relative", cursor: "pointer" }} onClick={() => setOpenPop(!openPop)}>
        <div className={classes.infoHover}>
            <p>...</p>
            <div className={classes.popOnHover}>
              <RowPopup navLink={navigateStudent} />
            </div>
          </div>
        </div>

      </div>

    </>
  );
}