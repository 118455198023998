import * as React from "react"
const SvgComponent = (props) => (
    <div style={{ ...props.customStyle }}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke="#7042C9"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                clipPath="url(#a)"
            >
                <path d="M21.613 35.565H7.988v-3.268h13.625v3.268Z" />
                <path d="M9.843 32.298v-3.462L7.727 20.91s-.62-1.538.619-3.845c1.238-2.307 3.62-6.149 3.62-6.149s1.445-1.873 2.838-1.248c1.392.624.413 2.834.413 2.834l-2.788 5.43s3.51.961 5.11 3.603c1.6 2.643.878 6.248.878 6.248" />
                <path d="M25.64 7.505V4.862s.281-2.343-2.517-2.343h-8.666s-2.61-.193-2.61 2.428v5.97M25.648 20.664v3.388s.144 2.144-2.495 2.144h-4.405M25.634 7.575l-2.427 2.26c-.631.587-.631 1.54 0 2.127h0c.631.588 1.654.588 2.284 0l2.428-2.26c.631-.587.631-1.54 0-2.126v-.001a1.701 1.701 0 0 0-2.285 0ZM25.634 11.848l-2.427 2.26c-.631.588-.631 1.54 0 2.127h0a1.7 1.7 0 0 0 2.284 0l2.428-2.26c.631-.587.631-1.539 0-2.126h0a1.701 1.701 0 0 0-2.285 0ZM25.634 16.13l-2.427 2.26c-.631.587-.631 1.54 0 2.126v.001c.631.587 1.654.587 2.284 0l2.428-2.26c.631-.588.631-1.54 0-2.127h0a1.701 1.701 0 0 0-2.285 0ZM28.626 23.335l-.105.045c-.732.312-1.054 1.118-.719 1.8l.455.925c.336.682 1.201.981 1.934.67l.104-.045c.733-.313 1.055-1.119.72-1.8l-.456-.925c-.335-.682-1.2-.982-1.933-.67ZM31.477 28.823l-.108-.037c-.755-.262-1.595.096-1.875.8l-.381.953c-.281.702.103 1.484.858 1.746l.108.037c.755.261 1.595-.097 1.876-.8l.38-.953c.281-.703-.103-1.484-.858-1.746ZM27.803 33.98v-.106c0-.75-.653-1.358-1.459-1.358h-1.092c-.806 0-1.459.608-1.459 1.358v.107c0 .75.653 1.358 1.459 1.358h1.092c.806 0 1.459-.608 1.459-1.358ZM21.613 33.93h2.736M27.475 33.417l1.933-1.8M30.563 29.015l-.606-2.484M28.44 24.111l-2.551-2.377M25.799 22.492h-7.791M20.375 24.32h-1.799M11.887 4.883h13.676" />
                <path d="M20.016 30.561s3.484-1.17 3.612-4.366M20.014 30.664v1.634M9.717 35.563v1.921M20.014 35.563v1.921" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M6.666 1.664h26.667v36.667H6.666z" />
                </clipPath>
            </defs>
        </svg>
    </div>
)
export default SvgComponent
