import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import classes from "./OTP.css";
import Layout from "../Layout/Layout";
// import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import InputPage from "../Input/Input";
import ResetPassword from "../../ResetPassword/ResetPassword";
import { verifyPassword } from "../commonFunctions";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Alert, Snackbar } from "@mui/material";
// import { Mixpanel } from "../../../utils/mixpanelWrapper";

function OTPPage() {
  //assigning hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const itemsRef = useRef([]);

  //assigning required useStates
  const otp = new Array(6).fill(0);
  const [userInput, setUserInput] = useState([]);
  const [password, setPassword] = useState("");
  // const [username, setUsername] = useState(localStorage.getItem("username"));
  const [counter, setCounter] = useState(25);
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorStatement, setErrorStatement] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);

  const errors = {
    capital: "The password must contain at least 1 Uppercase character",
    lower: "The password must contain at least 1 Lowercase character",
    digit: "The password must contain at least 1 numeric character",
    specialcharacter:
      "The password must contain at least one special character",
    lengthOfPass: "The password must be 8 characters or longer",
    passwordDoesNotMatch: "The entered paswords do not match"
  };

  const password_conditions = [
    {
      condition: "At least 8 character(s)"
    },
    {
      condition: "At least 1 uppercase"
    },
    {
      condition: "At least 1 special character"
    },
    {
      condition: "At least 1 number"
    },
    {
      condition: "At least 1 lowercase"
    },
    {
      condition: "Password do not match"
    }

  ]

  const [open, setOpen] = useState({
    state: false,
    message: '',
    toast: 'success'
  });

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  var otp_inputs = document.querySelectorAll(".otp__digit")
  var mykey = "0123456789".split("")
  otp_inputs.forEach((_) => {
    _.addEventListener("keyup", handle_next_input)
  })
  function handle_next_input(event) {
    let current = event.target
    let index = parseInt(current.classList[1].split("__")[2])
    current.value = event.key

    if (event.keyCode == 8 && index > 1) {
      current.previousElementSibling.focus()
    }
    if (index < 6 && mykey.indexOf("" + event.key + "") != -1) {
      var next = current.nextElementSibling;
      next.focus()
    }
    var _finalKey = ""
    for (let { value } of otp_inputs) {
      _finalKey += value
    }
    setUserInput(_finalKey)
    // if(_finalKey.length == 6){
    //   document.querySelector("#_otp").classList.replace("_notok", "_ok")
    //   document.querySelector("#_otp").innerText = _finalKey
    // }else{
    //   document.querySelector("#_otp").classList.replace("_ok", "_notok")
    //   document.querySelector("#_otp").innerText = _finalKey
    // }
  }

  const handlePassword = (item) => {
    let pass = verifyPassword(item);

    if (pass.capital) {
      setErrorStatement(errors.capital);
      setErrorPassword(true);
    } else if (pass.lower) {
      setErrorStatement(errors.lower);
      setErrorPassword(true);
    } else if (pass.digit) {
      setErrorStatement(errors.digit);
      setErrorPassword(true);
    } else if (pass.specialcharacter) {
      setErrorStatement(errors.specialcharacter);
      setErrorPassword(true);
    } else if (item.length < 8) {
      setErrorStatement(errors.lengthOfPass);
      setErrorPassword(true);
    } else {
      setErrorStatement("");
      setErrorPassword(false);
    }
    setPassword(item);
  };

  //checks if the both the password input is same
  const handleConfirmPassword = (item) => {
    setConfirmPassword(item);
    if (item === password) {
      setErrorConfirmPassword(false);
      setIsSubmit(true);
    } else {
      setErrorConfirmPassword(true);
      setIsSubmit(false);
    }
  };

  const handleClose = () => {
    setOpen(() => {
      return {
        message: "",
        toast: "error",
        state: false
      }
    });
  }

  const handleForgotPassword = () => {
    const email = location.state.email
    Auth.forgotPassword(email)
      .then((data) => {
        setOpen((prev) => {
          return {
            ...prev,
            message: "OTP sent successfully on your email",
            toast: "success",
            state: true
          }
        })
      })
      .catch((err) => {
        setOpen((prev) => {
          return {
            ...prev,
            message: "Problem while sending OTP, please try after sometime",
            toast: "error",
            state: true
          }
        })
      });
  };


  async function confirmSignUp() {
    const email = location.state.email
    Auth.forgotPasswordSubmit(email, userInput, password)
      .then((data) => {
        setOpen((prev) => {
          return {
            ...prev,
            message: "Password changed successfully",
            toast: "success",
            state: true
          }
        });
        setTimeout(() => { navigate("/login") }, 3000)
      })
      .catch((err) => setOpen((prev) => {
        return {
          ...prev,
          message: "Problem while changing password",
          toast: "error",
          state: true
        }
      }))
  }

  let passwordIconRender;
  const passwordConditionCheck = (index) => {
    switch (index) {
      case 0:
        if (password.length && password.length >= 8) {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#149457', color: '#ffffff' }} />
        } else if (password.length && password.length < 8) {
          return passwordIconRender = <CancelIcon sx={{ fill: '#D95A59', color: '#ffffff' }} />
        } else {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#EBECEE', color: '#ffffff' }} />
        }


      case 1:
        if (password.length && /[A-Z]/.test(password)) {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#149457', color: '#ffffff' }} />
        } else if (password.length && !(/[A-Z]/.test(password))) {
          return passwordIconRender = <CancelIcon sx={{ fill: '#D95A59', color: '#ffffff' }} />
        } else {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#EBECEE', color: '#ffffff' }} />
        }


      case 2:
        if (password.length && /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#149457', color: '#ffffff' }} />
        } else if (password.length && !(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password))) {
          return passwordIconRender = <CancelIcon sx={{ fill: '#D95A59', color: '#ffffff' }} />
        } else {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#EBECEE', color: '#ffffff' }} />
        }


      case 3:
        if (password.length && /\d/.test(password)) {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#149457', color: '#ffffff' }} />
        } else if (password.length && !(/\d/.test(password))) {
          return passwordIconRender = <CancelIcon sx={{ fill: '#D95A59', color: '#ffffff' }} />
        } else {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#EBECEE', color: '#ffffff' }} />
        }


      case 4:
        if (password.length && /[a-z]/.test(password)) {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#149457', color: '#ffffff' }} />
        } else if (password.length && !(/[a-z]/.test(password))) {
          return passwordIconRender = <CancelIcon sx={{ fill: '#D95A59', color: '#ffffff' }} />
        } else {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#EBECEE', color: '#ffffff' }} />
        }

      case 5:
        if (password.length && confirmPassword.length && password === confirmPassword) {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#149457', color: '#ffffff' }} />
        } else if (password.length && confirmPassword.length && password !== confirmPassword) {
          return passwordIconRender = <CancelIcon sx={{ fill: '#D95A59', color: '#ffffff' }} />
        } else {
          return passwordIconRender = <CheckCircleIcon sx={{ fill: '#EBECEE', color: '#ffffff' }} />
        }

    }
  }


  return (
    <div>
      <div className={"otp_page"}>
        <div className={"otp_container"}>

          <form action="javascript: void(0)" class="otp-form" name="otp-form">
            <div class="otp-input-fields">
              <input style={{ padding: 5, height: 50, textAlign: "center" }} type="number" className="otp__digit otp__field__1" />
              <input style={{ padding: 5, height: 50, textAlign: "center" }} type="number" className="otp__digit otp__field__2" />
              <input style={{ padding: 5, height: 50, textAlign: "center" }} type="number" className="otp__digit otp__field__3" />
              <input style={{ padding: 5, height: 50, textAlign: "center" }} type="number" className="otp__digit otp__field__4" />
              <input style={{ padding: 5, height: 50, textAlign: "center" }} type="number" className="otp__digit otp__field__5" />
              <input style={{ padding: 5, height: 50, textAlign: "center" }} type="number" className="otp__digit otp__field__6" />
            </div>
            <div class="result"><p id="_otp" class="_notok"></p></div>
          </form>

        </div>
        <div className={"resend"}>
          <div className={"bottom_text"}>Didn’t Receive OTP?</div>
          <div className={"resend_send_again"}>
            {" "}
            <div className={"timer"}>
              00:{counter === 0 ? <>00</> : counter}
            </div>{" "}
            <div
              className={"resend"}
              onClick={() => {
                if (counter === 0) {
                  handleForgotPassword();
                }
              }}
            >
              RESEND
            </div>{" "}
          </div>
        </div>
        <div style={{ marginBottom: 30 }}>
          <InputPage
            placeholder={"Enter new password"}
            isPassword={true}
            width={'100%'}
            change={(e) => {
              handlePassword(e.target.value);
            }}
            value={password}
          ></InputPage>
          <InputPage
            placeholder={"Confirm new password"}
            isPassword={true}
            width={'100%'}
            change={(e) => {
              handleConfirmPassword(e.target.value);
            }}
            value={confirmPassword}
          ></InputPage>
        </div>
        <div className={"password_parameters"}>
          <div className={"password_parameters_containers"}>
            {password_conditions.map((el, index) => {
              return index < 3 && (
                <div className={"password_parameters_boxes"}>
                  {passwordConditionCheck(index)}
                  <p>{el.condition}</p>
                </div>

              )
            })}
          </div>
          <div className={"password_parameters_containers"}>
            {password_conditions.map((el, index) => {
              return index >= 3 && (
                <div className={'password_parameters_boxes'}>
                  <div>
                    {passwordConditionCheck(index)}
                  </div>
                  <p>{el.condition}</p>
                </div>
              )
            })}
          </div>
        </div>
        {(isSubmit && userInput?.length === 6) ? (
          <button
            className={"submit_button"}
            style={{ opacity: 1 }}
            onClick={() => {
              confirmSignUp();
            }}
          >
            Verify
          </button>
        ) : (
          <button
            className={"submit_button"}
            style={{ opacity: 0.25 }}
            disabled
          >
            Verify
          </button>
        )}
        <Snackbar open={open.state} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert onClose={handleClose} severity={open.toast} sx={{ width: '100%' }}>
            {open.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
function OTP() {
  const location = useLocation()
  return (
    <>
      <Layout
        component={OTPPage()}
        head={"Verify OTP"}
        subhead={`We have sent a 6 digit OTP to ${location.state?.email} `}
      />
    </>
  );
}

export default OTP;
