import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import classes from "./DoctorDashboard.module.css";
import PatientRow from "./PatientRow";
import { Autocomplete, Avatar, Button, FormControl, Input, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { InfoCard } from '../DashboardSuperAdmin/InfoCard'
import { useDispatch, useSelector } from "react-redux";
import validationReducer from "../../redux/reducers/validation.reducer";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";
import { compareDate, stringAvatar } from "../../utils/utility";
import moment from "moment";
import { DoctorInfoCard } from "./DoctorInfoCard";
import FilterListIcon from '@mui/icons-material/FilterList';
import InputDashboard from "../common/InputDashboard/InputDashboard";
import validation from "../../graphqlApiServices/validation";
import schoolReducer from "../../redux/reducers/school.reducer";

// function DoctorDashboardPage() {
//   const data = useSelector((state) => state.validationReducer.data);


//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [pageNo, setPageNo] = useState(1);
//   const [status, setStatus] = useState("Pending")
//   const [dueDate, setDueDate] = useState({ value: "Latest" })
//   const [displayData, setDisplayData] = useState([])

//   useEffect(() => {
//     if (Array.isArray(data)) {
//       const y = data?.slice()?.sort((a, b) => {
//         return b?.createdAt?.localeCompare(a?.createdAt)
//       })
//       const x = y?.filter(z => z?.validated === false)
//       setDisplayData(x)
//       setStatus("Pending")
//       let newValue = { value: 'Latest' }
//       setDueDate(newValue)
//     }
//   }, [data])

//   useEffect(() => {
//     let sorted
//     if (Array.isArray(data)) {
//       console.log("1");
//       setDueDate("");
//       if (status === "Pending") {
//         sorted = data?.filter((x) => x?.validated === false)
//         const y = sorted?.slice()?.sort((a, b) => {
//           return b?.createdAt.localeCompare(a?.createdAt)
//         })
//         setDisplayData(y)
//       }
//       else {
//         sorted = data?.filter(x => x?.validated)
//         const y = sorted?.slice()?.sort((a, b) => {
//           return b?.updatedAt.localeCompare(a?.updatedAt)
//         })
//         setDisplayData(y)
//       }

//       // const y = sorted?.slice()?.sort((a, b) => {
//       //   return b?.createdAt.localeCompare(a?.createdAt)
//       // })
//       // setDisplayData(y)
//       console.log('hello');
//       let newValue = { value: 'Latest' }
//       setDueDate(newValue)
//     }
//   }, [status])

//   useEffect(() => {
//     // if (!displayData.length) return;
//     console.log('hello');
//     const newData = [...displayData];
//     console.log(newData);
//     if (dueDate.value == "Latest") {
//       newData.sort((a, b) => a.validationDueDate > b.validationDueDate ? -1 : 1)
//       setDisplayData(newData)
//     }
//     else {
//       newData.sort((a, b) => b.validationDueDate < a.validationDueDate ? 1 : -1)
//       setDisplayData(newData)
//     }
//   }, [dueDate])

//   const getTotalStudents = () => {
//     let x
//     if (Array.isArray(data)) {
//       x = data?.filter((a) => a.validated === true)
//     }
//     return `${x?.length}/${data?.length}`
//   }

//   return (
//     <>
//       <BreadCrumbs />
//       <div className={classes.infocard_container}>
//         <DoctorInfoCard
//           title={`Validation Data`}
//           entity={"School"}
//           subtitle={"Class 1 - Class 12 (All Sections)"}
//           totalText={"Validation Progress"}
//           totalCount={"4 "}
//           totalPupilCount={getTotalStudents()}
//           live={"Live"}
//           data={data}
//         />

//       </div>
//       <div className={classes.header_text}>Real Time Activity</div>
//       <div className={classes.main_div}>
//         <div className={classes.student_filters}>
//           <FormControl>
//             <InputLabel id="demo-simple-select-label">Rows</InputLabel>
//             <Select
//               labelId="demo-simple-select-label"
//               id="demo-simple-select"
//               value={rowsPerPage}
//               label="Rows"
//               onChange={(e) => setRowsPerPage(e.target.value)}
//               size='small'
//             >
//               <MenuItem value={10}>10</MenuItem>
//               <MenuItem value={20}>20</MenuItem>
//               <MenuItem value={50}>50</MenuItem>
//             </Select>
//           </FormControl>
//           <FormControl>
//             <InputLabel id="demo-simple-select-label">Status</InputLabel>
//             <Select
//               labelId="demo-simple-select-label"
//               id="demo-simple-select"
//               value={status}
//               label="Rows"
//               onChange={(e) => setStatus(e.target.value)}
//               size='small'
//             >
//               <MenuItem value={"Pending"}>Pending</MenuItem>
//               <MenuItem value={"Validated"}>Validated</MenuItem>
//             </Select>
//           </FormControl>
//           <FormControl>
//             <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
//             <Select
//               labelId="demo-simple-select-label"
//               id="demo-simple-select"
//               value={dueDate.value}
//               label="Rows"
//               onChange={(e) => {
//                 let newValue = { value: e.target.value }
//                 setDueDate(newValue)
//               }}
//               size='small'
//             >
//               <MenuItem value={"Latest"}>Latest</MenuItem>
//               <MenuItem value={"Oldest"}>Oldest</MenuItem>
//             </Select>
//           </FormControl>
//         </div>
//         {/* Student Table Header */}
//         <div className={classes.student_table_header}>
//           <div>PATIENT NAME</div>
//           <div>AGE</div>
//           <div>GENDER</div>
//           <div>SCREENING DATE</div>
//           <div>VALIDATION DUE DATE</div>
//           <div>STATUS</div>
//           <div>ACTION</div>
//         </div>
//         {/* Student Column-Rows Section */}
//         <div id="schoolRowCy" className={classes.student_column_container}>
//           {
//             //   [
//             //   {validated : false, pending : false},
//             //   {validated : true, pending : false},
//             //   {validated : false, pending : true}
//             // ]
//             Array.isArray(displayData) && displayData
//               ?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo).map((el, index) => {
//                 return (
//                   <PatientRow data={el} key={index + Date.now()} index={index} />
//                 )
//               })}
//         </div>
//         <div className={classes.pagination_container}>
//           <div className={classes.page_item}>{`Showing ${rowsPerPage * (pageNo - 1) + 1
//             } to ${pageNo * rowsPerPage <= displayData?.length
//               ? pageNo * rowsPerPage
//               : displayData?.length
//             } of ${displayData?.length} items`}</div>
//           <div className={classes.pagination}>
//             <Button
//               size="small"
//               disabled={pageNo === 1}
//               onClick={() => setPageNo(pageNo - 1)}
//             >
//               <ChevronLeft />
//             </Button>
//             <span>{pageNo}</span>
//             <div>
//               <span>of</span>
//               <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
//             </div>

//             <div>
//               <Button
//                 size="small"
//                 disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)}
//                 onClick={() => setPageNo(pageNo + 1)}
//               >
//                 <ChevronRight />
//               </Button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

function DentalDoctorDashboard({ user }) {

  const getSchools = schoolReducer.actions.getSchoolsRequest;
  const location = useLocation();
  const dispatch = useDispatch();
  const [schoolList, setSchoolList] = useState([])
  const [rowData, setRowData] = useState([])
  const [validations, setValidations] = useState([])
  const navigate = useNavigate();
  const data = useSelector((state) => state.schoolReducer.data);

  const { getDentalValidation } = validation;
  let rows;

  useEffect(() => {
    if (user === 'DENTAL') {
      getDentalValidation(callBack)
    } else {
      dispatch(getSchools());
    }
  }, [])

  useEffect(() => {
    if (data.length) {
      setRowData(data);
      setSchoolList(data);
    }
  }, [data])


  function callBack(type, res) {
    if (type === 'success') {
      // console.log(res);
      // console.log(Object?.values(res.schoolDetails));
      setValidations(res.schoolValidation);
      setRowData(Object?.values(res.schoolDetails));
      setSchoolList(Object?.values(res.schoolDetails));
    } else if (type = "failure") {
      console.log(res);
    }
  }


  const handleSearchSchool = (e) => {
    let typed = e.target.value
    if (typed !== "") {
      setSchoolList(rowData?.filter((i) => i?.schoolName?.toLowerCase()?.includes(typed?.toLowerCase())))
    } else {
      setSchoolList(rowData)
    }
  }

  const handleViewSchool = (i, n) => {
    if (user === 'DENTAL') {
      let studentsToValidate = validations[i];
      navigate(`/doctor-school/${i}`, { state: { students: studentsToValidate, breadCrumbs: [...location.state.breadCrumbs, n] } })
    } else {
      navigate(`/doctor-physchool/${i}`, { state: { breadCrumbs: [...location.state.breadCrumbs, n] } })
    }
  }



  return <div>
    <BreadCrumbs />
    <br />
    <div className={classes.pagination}>
      <InputDashboard
        width={"27vw"}
        inputSize={"Large"}
        placeholder={"Search school"}
        change={handleSearchSchool}
      />
      <FilterListIcon sx={{ marginLeft: "20px" }} />
    </div>
    <br />
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ height: "70px" }}>
          <TableRow>
            <TableCell>School Name</TableCell>
            <TableCell align="right">Students</TableCell>
            <TableCell align="right">School code</TableCell>
            <TableCell align="right">Start&nbsp;date</TableCell>
            <TableCell align="right">End&nbsp;date</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schoolList.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                'th': {
                  height: '70px'
                }
              }}
            >
              <TableCell component="th" scope="row">
                <div className={classes.tabBtn_container}>
                  {
                    row?.url
                      ? <img style={{ width: "32px", height: "32px", borderRadius: "20px", objectFit: "cover" }} src={row?.url} alt="" />
                      : <Avatar {...stringAvatar(row?.schoolName)} />
                  }
                  {row?.schoolName}
                </div>
              </TableCell>
              <TableCell align="right">{row?.strength}</TableCell>
              <TableCell align="right">{row?.schoolCode}</TableCell>
              <TableCell align="right">{row?.screeningFrom}</TableCell>
              <TableCell align="right">{row?.screeningTo}</TableCell>
              <TableCell align="right">
                <button onClick={() => handleViewSchool(row.schoolId, row?.schoolName)} className={classes.viewSchoolBtn}>
                  View school
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  </div>
}

function DoctorDashboard() {
  const location = useLocation();
  const user = useSelector((state) => state.userReducer.currentUser);
  location.state = { breadCrumbs: ['Doctor validation'] };

  return (
    <DentalDoctorDashboard user={user?.specialities} />
  )
}
export default DoctorDashboard;