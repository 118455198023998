import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import DashboardLayout from '../components/common/DashboardLayout/DashboardLayout'
import Navbar from '../components/common/Navbar/Navbar'
import EditOrgSchool from '../components/common/EditOrganization/EditOrganization';
import Studentscreening from '../components/common/StudentName/StudentName';
import OpsIndividualScreening from '../components/OpsTeam/OpsIndividualScreening/IndividualScreening'


function PartnerRoutes({ component }) {

  const user = useSelector((state) => state.userReducer.currentUser)
  const loggedIn = useSelector((state) => state.authReducer.loggedIn)
  const location = useLocation()


  if (!loggedIn) {
    return <Navigate to={"/login"} />
  }
  else if (user && !user?.groups?.includes("skids-partner")) {
    
    // return <Navigate to={`/404`}/>
  }
  else {
    return (
      <>
        <div className='baseStructure'>
          <DashboardLayout />
          <div className='bsrs'>
            <Navbar />
            <div className='bsrb'>
              {component}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default PartnerRoutes;