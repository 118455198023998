import React, { useEffect, useRef, useState } from 'react'
import DashboardLayout from '../../common/DashboardLayout/DashboardLayout'
import classes from './IndividualScreening.module.css'
import ClassSectionFilter from '../../common/TableComponents/ClassSectionFilter'
import TabButton from '../../common/TableComponents/TabButton'
import BreadCrumbs from '../../common/TableComponents/BreadCrumbs'
import fullbody from '../../../assets/images/fullbody.svg'
import { Alert, Avatar, CircularProgress, Snackbar, StepperContext, TextField, Typography } from '@mui/material'
import stethoscopegreen from '../../../assets/images/stethoscopegreen.png'
import checkcircle from '../../../assets/images/check-circle.svg'
import visilbilty from '../../../assets/images/visibility.svg'
import wind from '../../../assets/images/wind.png'
import earImg from '../../../assets/images/ear.png'
import ent from '../../../assets/images/ent.svg'
import heartmonitor from '../../../assets/images/heartmonitor.svg'
import pulmonology from '../../../assets/images/pulmonology.svg'
import gastroenterology from '../../../assets/images/gastroenterology.svg';
import fingerprintgreen from '../../../assets/images/fingerprint_green.svg'
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from 'react-redux'
import screeningReducer from '../../../redux/reducers/screening.reducer'
import studentReducer from '../../../redux/reducers/student.reducer'
import screening from '../../../graphqlApiServices/screening'
import { API, graphqlOperation } from 'aws-amplify';
import Cookies from 'js-cookie'
import LoadingBar from 'react-top-loading-bar'
import { useNavigate, useParams } from 'react-router-dom';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DentalJaw from '../../Dentaljaw/Dentaljaw'
import Checkanimated from '../../../assets/images/AnimatedIcon/Checkmark/Checkmark'
import { calculateAge, ImgDataToBuffer, numberTh } from '../../../utils/utility'
import SkidsCamera from '../../Camera/SkidsCamera';
import ScreenLoader from '../../common/ScreenLoader/ScreenLoader';
import EditableText from '../../EditableText/EditableText'
import dashboardReducer from '../../../redux/reducers/dashboard.reducer'
import axios from 'axios'
import { Buffer } from 'buffer';
import schoolReducer from '../../../redux/reducers/school.reducer'
import user from '../../../graphqlApiServices/users'
// import screening from '../../../graphqlApiServices/screening'
import InputDropdown from '../../common/InputDashboard/InputDropdown'
import validation from '../../../graphqlApiServices/validation'

function IndividualScreeningPage() {

  const { getDoctorsBySpeciality } = user
  const { immediateAssign } = screening

  const navigate = useNavigate()

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const { updateScreeningSub, markScreeningDoneOnMap, defectUpload, hearingUpload } = screening
  const { screeningId } = useParams()
  let subscription = useRef(null);

  const updateScreenPub = screeningReducer.actions.updateScreeningByIdRequest;
  const dashboardUpdate = dashboardReducer.actions.updateDashboardReq;
  const postMasimoPhoto = screeningReducer.actions.postMasimoPhotoReq;
  const getOpsTeamScreen = screeningReducer.actions.getOpsTeamScreenReq;
  const toggleDevice = screeningReducer.actions.toggleDeviceReq;
  const getStudentById = studentReducer.actions.getStudentByIDRequest;
  const getOpsTeamScreenSuccess = screeningReducer.actions.getOpsTeamScreenSuccess;
  const testData = useSelector((state) => state.screeningReducer?.testData);
  console.log(testData, 'testData')
  const studentData = useSelector((state) => state.studentReducer?.data);
  const schoolData = useSelector((state) => state.schoolReducer.data)
  const updateStudentStatus = studentReducer.actions.updateStudentStatusRequest;
  const { screeningPackageDetails, screeningStatus: { abdomin, cough, dental, ear, eye, hair, hearing, heart, lungs, skin, throat, vitalSigns } } = testData;
  console.log(screeningPackageDetails, 'screeningPackageDetails');
  console.log(testData.screeningStatus, 'screeningStatus');
  const organs = [
    "Vital",
    "Hair",
    "Eye",
    "Ear",
    "Hearing",
    "Dental",
    "Throat",
    "Cough",
    "Heart",
    "Lungs",
    "Abdominal",
    "Skin",
  ]
  let testsBlock
  const [isorganActive, setOrganActive] = useState("Vital");
  const [testUpdate, setTestUpdate] = useState({})
  const [viewCamera, setViewCamera] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  // const [subState, setSubState] = useState();
  const dispatch = useDispatch()
  const { completeReExam } = validation
  const [image, setImage] = useState();
  const [issueImage, setIssueImage] = useState()
  const [viewIssueCamera, setIssueCamera] = useState(false)
  const [comment, setComment] = useState("")
  const [error, setError] = useState(false)
  const [phyDoctorList, setPhyDoctorList] = useState([])
  const [bhvDoctorList, setbhvDoctorList] = useState([])
  const [dentalDoctorList, setdentaloctorList] = useState([])
  const [phyDoctor, setPhyDoctor] = useState([])
  const [bhvDoctor, setbhvDoctor] = useState([])
  const [dentalDoctor, setdentalDoctor] = useState([])
  const currentUser = useSelector(state => state.userReducer.currentUser)
  const [hearingImage, setHearingImage] = useState();
  const fileRef = useRef();

  useEffect(() => {
    getDoctorsBySpeciality({ payload: "PAEDIATRICIAN" }).then((res) => {
      setPhyDoctorList(res.data.getDoctorsBySpeciality)
    }).catch((err) => { console.log(err) })

    getDoctorsBySpeciality({ payload: "DENTAL" }).then((res) => {
      setdentaloctorList(res.data.getDoctorsBySpeciality)
    }).catch((err) => { console.log(err) })

    getDoctorsBySpeciality({ payload: "BEHAVIORAL" }).then((res) => {
      setbhvDoctorList(res.data.getDoctorsBySpeciality)
    }).catch((err) => { console.log(err) })
  }, [])


  const handleClose = () => {
    // if (stream !== null) {
    //     stream.getTracks().forEach(t => {
    //         t.stop();
    //     })
    // }
    setViewCamera(false);
  }

  const handleIssueClose = () => {
    setIssueCamera(false)
  }


  const updateStudent = () => {
    dispatch(updateStudentStatus({ studentId: studentData[0]?.studentId, schoolId: studentData[0]?.schoolId, status: "SCREENING_DONE" }))
    dispatch(dashboardUpdate({ eventId: testData?.eventId, screeningId: screeningId, mapId: testData?.mappingId }));
  }

  const uploadMasimoPhoto = async () => {
    setShowLoading(true);
    let buffer = ImgDataToBuffer(image);
    const params = {
      buffer: image,
      studentId: studentData?.[0]?.studentId,
      screeningId: screeningId
    }
    const token = Cookies.get("tokenId")
    // const init = {
    //   method: 'POST',
    //   body : {screeningId: screeningId,file: image},
    //   headers : {
    //     "Authorization" : token
    //   }
    // }

    // API.post('massimoUpload', `/${screeningId}`, init)
    //     .then(response =>     
    //       {
    //         handleClick()
    //         setError(false)
    //       }
    //     )
    //     .catch(error => {
    //       handleClick()
    //       setError(true)
    //     })

    dispatch(postMasimoPhoto(params));
  }


  useEffect(() => {
    if (vitalSigns?.temperature && vitalSigns?.bloodPressure && vitalSigns?.SpO2 && vitalSigns?.heartRate && vitalSigns?.height && vitalSigns?.hemoglobin && vitalSigns?.weight) {
      setTestUpdate((prev) => {
        return {
          ...prev,
          Vital: true
        }
      })
    }
    if (hair?.color && hair?.dandruff && hair?.lossOfHair && hair?.pediculosis) {
      setTestUpdate((prev) => {
        return {
          ...prev,
          Hair: true
        }
      })
    }
    if (eye?.normalVision) {
      setTestUpdate((prev) => {
        return {
          ...prev,
          Eye: true
        }
      })
    }

    if (hearing?.hearingAssessment) {
      setTestUpdate((prev) => {
        return {
          ...prev,
          Hearing: true
        }
      })
    }
    if (ear?.leftEarVideo && ear?.rightEarVideo) {
      setTestUpdate((prev) => {
        return {
          ...prev,
          Ear: true
        }
      })
    }
    if (dental?.decoloration && dental?.gingivitis && dental?.orthodontic && dental?.tooth) {
      setTestUpdate((prev) => {
        return {
          ...prev,
          Dental: true
        }
      })
    }
    if (throat?.video) {
      setTestUpdate((prev) => {
        return {
          ...prev,
          Throat: true
        }
      })
    }
    if (cough?.exam) {
      setTestUpdate((prev) => {
        return {
          ...prev,
          Cough: true
        }
      })
    }
    if (heart?.estimation) {
      setTestUpdate((prev) => {
        return {
          ...prev,
          Heart: true
        }
      })
    }
    if (lungs?.auscultation) {
      setTestUpdate((prev) => {
        return {
          ...prev,
          Lungs: true
        }
      })
    }
    if (abdomin?.auscultation) {
      setTestUpdate((prev) => {
        return {
          ...prev,
          Abdominal: true
        }
      })
    }
    if (skin?.video) {
      setTestUpdate((prev) => {
        return {
          ...prev,
          Skin: true
        }
      })
    }
  }, [testData])


  const [progress, setProgress] = useState(0);
  // const [hairDandruff, setHairDandruff] = useState(false)
  // const [hairFall, setHairFall] = useState(false)
  // const [earHearing, setEarHearing] = useState(false)
  // const [earPhysical, setEarPhysical] = useState(false)
  // const [eye, setEye] = useState(false)
  // const [cough, setCough] = useState(false)
  // const [throat, setThroat] = useState(false)
  // const [heartRate, setHeartRate] = useState(false)
  // const [lungs, setLungs] = useState(false)
  // const [skin, setSkin] = useState(false)
  // const [abdominal, setAbdominal] = useState(false);
  // const [height, setHeigth] = useState(false);
  // const [weight, setWeight] = useState(false);
  // const [bmi, setBmi] = useState(false);
  // const [spO2, setSpO2] = useState(false);
  // const [SPGHB, setSPGHB] = useState(false);
  // const [BP, setBP] = useState(false);
  // const [dental, setDental] = useState(false);
  // const [subscriptionData, setSubscriptionData] = useState({});

  // useEffect(() => {
  //   if (screeningStatus?.)
  // }, [testData])

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    setOpen(false);
  };



  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#149457' : '#149457',
    },
  }));


  useEffect(() => {
    const params = {
      eventId: "",
      screeningId: screeningId
    }
    dispatch(getOpsTeamScreen(params));

  }, []);

  useEffect(() => {
    if (!testData?.studentId) return;
    const params = {
      studentId: testData?.studentId
    }
    dispatch(getStudentById(params));
    (async function () {
      const token = Cookies.get('tokenId')
      const params = {
        eventId: testData?.eventId,
        screeningId: screeningId
      }
      try {
        const query = updateScreeningSub(params)
        const subscriptionTemp = await API.graphql(
          graphqlOperation(
            query,
            {},
            token,
          )
        ).subscribe({
          next: ({ provider, value }) => {
            setShowLoading(false);
            dispatch(getOpsTeamScreenSuccess(value?.data?.subscribeToUpdateScreening))
          },
          error: error => console.warn(error)
        });
        subscription.current = subscriptionTemp;
      }
      catch (error) {
        console.log(error);
      }
    })();

    return () => {
      if (subscription.current) {
        console.log(subscription.current);
        subscription.current?.unsubscribe();
      }
    }

  }, [testData?.firstName])



  // const handleTeethUpdate = async (teethUpdate, activeTeeth) => {
  //   let tempTeethArray = testData?.dentalAssessment?.tooth[0]?.split(',')?.filter((element) => (!element.includes(activeTeeth.current)));
  //   switch (teethUpdate) {
  //     case 'normal':
  //       tempTeethArray.push(activeTeeth.current + "_" + "N".toString())
  //       break;
  //     case 'decayed':
  //       tempTeethArray.push(activeTeeth.current + "_" + "D".toString())
  //       break;
  //     case 'filled':
  //       tempTeethArray.push(activeTeeth.current + "_" + "F".toString())
  //       break;
  //     case 'missing':
  //       tempTeethArray.push(activeTeeth.current + "_" + "M".toString())
  //       break;
  //   }
  //   const params = {
  //     eventId: testData?.eventId,
  //     screeningId: screeningId,
  //     test: {
  //       ...testData,
  //       dentalAssessment: {
  //         ...testData.dentalAssessment,
  //         tooth: tempTeethArray
  //       }
  //     }
  //   }
  //   setProgress(70);
  //   await dispatch(updateScreenPub(params))
  //   setTimeout(() => {
  //     setProgress(100);
  //   }, 500);
  // }

  function completeScreeningInMap() {
    const params = {
      screeningId: screeningId,
      mapId: testData?.mappingId,
      eventId: testData?.eventId
    }
    markScreeningDoneOnMap(params, (type, res) => {
      if (type === "success") {
        console.log(res);
      } else if (type === "failure") {
        console.log(res);
      }
    });
  }

  useEffect(() => {
    console.log(studentData)
    dispatch(schoolReducer.actions.getSchoolbyIdRequest({ schoolId: studentData[0]?.schoolId }))
  }, [studentData])

  const publishTestUpdates = async (c, v) => {
    setProgress(40)
    const params = {
      eventId: testData?.eventId,
      screeningId: screeningId,
      defect: c === "defect" ? testData.defects === null ? [v] : [...testData.defects, v] : testData.defects,
      status: c === "screeningStatus" ? v : testData?.status,
      auditTrail: {
        ...JSON.parse(testData?.auditTrail),
        [c]: {
          name: currentUser?.given_name + " " + currentUser?.family_name,
          id: currentUser?.userId
        }
      },
      test: {
        dentalAssessment: {
          decoloration: c === 'decoloration' ? v : testData?.dentalAssessment?.decoloration,
          gingivitis: c === 'gingivitis' ? v : testData?.dentalAssessment?.gingivitis,
          orthodontic: c === 'orthodontic' ? v : testData?.dentalAssessment?.orthodontic,
          fillingPresent: testData?.dentalAssessment?.fillingPresent,
          missedTooth: testData?.dentalAssessment?.missedTooth,
          cariesDecay: testData?.dentalAssessment?.cariesDecay,
          poorOralHygiene: testData?.dentalAssessment?.poorOralHygiene,
          tooth: testData?.dentalAssessment?.tooth
        },
        hairAssessment: {
          color: c === 'colorOfHair' ? v : testData?.hairAssessment?.color,
          dandruff: c === 'dandruff' ? v : testData?.hairAssessment?.dandruff,
          lossOfHair: c === 'lossOfHair' ? v : testData?.hairAssessment?.lossOfHair,
          pediculosis: c === 'pediculosis' ? v : testData?.hairAssessment?.pediculosis
        },
        hearingAssessment: {
          reportUrl: c === 'hearingAssessment' ? v : testData?.hearingAssessment?.reportUrl
        },
        vitalSignsAssessment: {
          ...testData?.vitalSignsAssessment,
          height: c === 'secaHeight' ? v : testData?.vitalSignsAssessment?.height,
          weight: c === 'secaWeight' ? v : testData?.vitalSignsAssessment?.weight,
          bloodPressure: c === 'morphenBp' ? v : testData?.vitalSignsAssessment?.bloodPressure,
          hemoglobin: c === 'masimoHemo' ? v : testData?.vitalSignsAssessment?.hemoglobin,
          heartRate: c === 'masimoHeart' ? v : testData?.vitalSignsAssessment?.heartRate,
          SpO2: c === 'masimoSpO2' ? v : testData?.vitalSignsAssessment?.SpO2,
        },
        screeningStatus: {
          abdomin: {
            auscultation: c === 'abdominal' ? v : testData?.screeningStatus?.abdomin?.auscultation,
          },
          cough: {
            exam: c === 'cough' ? v : testData?.screeningStatus?.cough?.exam,
          },
          dental: {
            decoloration: c === 'dentalScreening' ? v : testData?.screeningStatus?.dental?.decoloration,
            gingivitis: c === 'dentalScreening' ? v : testData?.screeningStatus?.dental?.gingivitis,
            orthodontic: c === 'dentalScreening' ? v : testData?.screeningStatus?.dental?.orthodontic,
            tooth: testData?.screeningStatus?.dental?.tooth,
            resultAwait: testData?.screeningStatus?.dental?.resultAwait
          },
          ear: {
            leftEarVideo: c === 'leftEar' ? v : testData?.screeningStatus?.ear?.leftEarVideo,
            rightEarVideo: c === 'rigthEar' ? v : testData?.screeningStatus?.ear?.rightEarVideo,
          },
          eye: {
            normalVision: c === 'normalVision' ? v : testData?.screeningStatus?.eye?.normalVision,
          },
          hair: {
            color: c === 'colorOfHair' ? true : testData?.screeningStatus?.hair?.color,
            dandruff: c === 'dandruff' ? true : testData?.screeningStatus?.hair?.dandruff,
            lossOfHair: c === 'lossOfHair' ? true : testData?.screeningStatus?.hair?.lossOfHair,
            pediculosis: c === 'pediculosis' ? true : testData?.screeningStatus?.hair?.pediculosis,
          },
          hearing: {
            resultAwait: testData?.screeningStatus?.hearing?.resultAwait,
            hearingAssessment: c === 'hearing' ? v : testData?.screeningStatus?.hearing?.hearingAssessment,
          },
          heart: {
            estimation: c === 'heartEstimation' ? v : testData?.screeningStatus?.heart?.estimation,
          },
          lungs: {
            auscultation: c === 'lungsAuscultation' ? v : testData?.screeningStatus?.lungs?.auscultation,
          },
          skin: {
            video: c === 'skin' ? v : testData?.screeningStatus?.skin?.video,
          },
          throat: {
            video: c === 'throat' ? v : testData?.screeningStatus?.throat?.video,
          },
          vitalSigns: {
            heightAwait: testData?.screeningStatus?.vitalSigns?.heightAwait,
            weightAwait: testData?.screeningStatus?.vitalSigns?.weightAwait,
            SpO2: c === 'spO2' ? v : testData?.screeningStatus?.vitalSigns?.SpO2,
            bloodPressure: c === 'bloodPressure' ? v : testData?.screeningStatus?.vitalSigns?.bloodPressure,
            heartRate: c === 'heartRate' ? v : testData?.screeningStatus?.vitalSigns?.heartRate,
            height: c === 'height' ? v : testData?.screeningStatus?.vitalSigns?.height,
            hemoglobin: c === 'hemoglobin' ? v : testData?.screeningStatus?.vitalSigns?.hemoglobin,
            temperature: c === 'temperature' ? v : testData?.screeningStatus?.vitalSigns?.temperature,
            weight: c === 'weight' ? v : testData?.screeningStatus?.vitalSigns?.weight,
          }
        }
      }
    }
    setProgress(70);
    console.log(params)
    await dispatch(updateScreenPub(params))
    setTimeout(() => {
      setProgress(100);
      setIssueImage("")
      setComment("")

    }, 500);
  }

  const ToggleDevice = (device) => {
    let params = {
      screeningId: screeningId,
      type: device
    }
    // console.log(params);
    dispatch(toggleDevice(params));
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`?.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 12,
        height: 12,
        padding: 2
      },
      children: `${name[0]}`,
    };
  }

  const findDefect = (x) => {
    const q = testData?.defects?.filter((z) => x === z.onType)
    console.log(q)
    if (q?.length > 0) {
      return true
    } else {
      return false
    }
  }

  const toArrayBuffer = () => new Promise((resolve, reject) => {
    const arr = issueImage.split(',');
    fetch(issueImage)
      .then(function (result) {
        return resolve(result.arrayBuffer())
      }).catch((err) => { return reject(err) });
  });

  const uploadIssuePhoto = async (organ) => {
    const res = await defectUpload({ organ, screeningId })

    const urls = res.data.defectUpload
    console.log(issueImage)
    const a = await toArrayBuffer()
    console.log(a)
    const config = {
      method: "PUT",
      // headers: { Authorization: token }
    };
    axios.put(`${urls?.signedURL}`, a, config).then((x) => {
      publishTestUpdates("defect", {
        url: urls.URL,
        onType: organ,
        comment: comment
      })
    }).catch((e) => { return e })
  }

  const convertToArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = (e) => {
        resolve(e.target.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };


  const handleHearingUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const res = await hearingUpload({ screeningId })

      const urls = res.data.hearingUpload
      console.log(file)
      const a = await convertToArrayBuffer(file)
      console.log(a)
      const config = {
        method: "PUT",
        // headers: { Authorization: token }
      };
      axios.put(`${urls?.signedURL}`, a, config).then((x) => {
        publishTestUpdates("hearingAssessment", urls.URL)
      }).catch((e) => { return e }).finally(() => {
        if(fileRef?.current) {
          fileRef.current.value = ''
        }
      })
    }
  }

  const [modalOpen, setmodalOpen] = React.useState(false);
  const handleOpenModal = () => setmodalOpen(true);
  const handleCloseModal = () => setmodalOpen(false);

  const isScreeningDone = () => {
    return screeningPackageDetails.includes()
  }

  const handleImmediateAssign = () => {
    immediateAssign({
      bhv: bhvDoctor,
      dental: dentalDoctor,
      phy: phyDoctor,
      screeningId
    }).then((x) => {
      handleCloseModal()
      navigate(-1)
    })
  }

  const handleScreeningDone = () => {
    let params = {
      screeningId: testData?.screeningId,
      userId: currentUser?.userId
    }
    if (testData?.reExam) {
      completeReExam(params)
    }
    else {
      publishTestUpdates('screeningStatus', "SCREENING_DONE");
      updateStudent();
      completeScreeningInMap();
    }
  }

  const handleScreeningPending = () => {
    publishTestUpdates('screeningStatus', "PATIENT_ADDED");
    dispatch(updateStudentStatus({ studentId: studentData[0]?.studentId, schoolId: studentData[0]?.schoolId, status: "PATIENT_ADDED" }))
    dispatch(dashboardUpdate({ eventId: testData?.eventId, screeningId: screeningId, mapId: testData?.mappingId }));
    // completeScreeningInMap();
  }

  switch (isorganActive) {
    case "Vital": testsBlock = <>
      <p className={classes.parameterHeading}>Vital Sign Screening</p>
      {
        testData?.screeningPackageDetails?.vitalSigns?.includes('TEMPERATURE')
        &&
        <div className={classes.poll_container}>
          <div className={classes.heart_poll_container}>
            <div>Temprature</div>
            <div>
              {
                testData?.screeningStatus?.vitalSigns?.temperature
                  ? "Screening done"
                  : "Screening pending"
              }
            </div>
            <div>
              <button onClick={() => { publishTestUpdates('temperature', true) }}
                className={testData?.screeningStatus?.vitalSigns?.temperature ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
              <button
                onClick={() => { publishTestUpdates('temperature', false) }}
                className={!testData?.screeningStatus?.vitalSigns?.temperature ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>
            </div>
          </div>
        </div>
      }
      {
        testData?.screeningPackageDetails?.vitalSigns?.includes('HEART_RATE')
        &&
        <div className={classes.poll_container}>
          <div className={classes.heart_poll_container}>
            <div>Heart Rate</div>
            <div>
              {
                testData?.screeningStatus?.vitalSigns?.heartRate
                  ? "Screening done"
                  : "Screening pending"
              }
            </div>
            <div>
              <button onClick={() => { publishTestUpdates('heartRate', true) }}
                className={testData?.screeningStatus?.vitalSigns?.heartRate ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
              <button
                onClick={() => { publishTestUpdates('heartRate', false) }}
                className={!testData?.screeningStatus?.vitalSigns?.heartRate ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>
            </div>
          </div>
        </div>
      }


      {testData?.screeningPackageDetails?.vitalSigns?.includes('HEIGHT_AND_WEIGHT')
        &&
        <div style={{ borderBottomWidth: 1, borderBottomColor: "#E8E7E7", borderBottomStyle: "solid", paddingBottom: 20 }}>
          <div style={{ position: "relative" }} className={classes.poll_container}>
            {/* {
            (testData.screeningStatus.hearing.resultAwait
              || testData.screeningStatus.vitalSigns.heightAwait
              || testData.screeningStatus.vitalSigns.weightAwait) &&
            <ScreenLoader />
          } */}
            <div className={classes.heart_poll_container}>
              <div>Height</div>
              <div>
                {
                  testData?.screeningStatus?.vitalSigns?.height
                    ? "Screening done"
                    : "Screening pending"
                }
              </div>
              <div>
                <button
                  // onClick={() => { ToggleDevice("SECA") }}
                  className={testData?.screeningStatus?.vitalSigns?.height ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
                <button
                  // onClick={() => { publishTestUpdates('height', false) }}
                  className={!testData?.screeningStatus?.vitalSigns?.height ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>
              </div>
              <br />
              <div>Height :
                <EditableText c={"secaHeight"} title={""} publishUpdates={publishTestUpdates} value={testData?.vitalSignsAssessment?.height} placeholder={"Height in cm"} />
              </div>
            </div>
            <div className={classes.heart_poll_container}>
              <div>Weight</div>
              <div>
                {
                  testData?.screeningStatus?.vitalSigns?.weight
                    ? "Screening done"
                    : "Screening pending"
                }
              </div>
              <div>
                <button
                  // onClick={() => { ToggleDevice("SECA") }}
                  className={testData?.screeningStatus?.vitalSigns?.weight ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
                <button
                  // onClick={() => { publishTestUpdates('weight', false) }}
                  className={!testData?.screeningStatus?.vitalSigns?.weight ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>

                <button
                  onClick={() => { ToggleDevice("SECA") }}
                  className={classes.heart_poll_container_button_inactive}
                  style={{ marginLeft: "50px" }}
                >
                  {
                    (testData?.screeningStatus?.vitalSigns?.heightAwait
                      || testData?.screeningStatus?.vitalSigns?.weightAwait)
                      ? <CircularProgress color='secondary' size={30} />
                      : "Start"
                  }
                </button>
              </div>
              <br />
              <div>Weight :
                <EditableText c={"secaWeight"} title={""} publishUpdates={publishTestUpdates} value={testData?.vitalSignsAssessment?.weight} placeholder={"weight in kg"} />
              </div>
            </div>

          </div>

          <div className={classes.masimoCon}>
            {issueImage &&
              <img className={classes.masimoPicture} src={issueImage} alt="" />
            }
            {
              showLoading
                ? <>Uploading Photo... <CircularProgress color='secondary' size={20} /></>
                : (
                  !findDefect('heightWeight') ? <div className={!issueImage ? classes.preCaptureBtn : classes.postCaptureBtn} onClick={() => { setIssueCamera(!viewIssueCamera) }}>{issueImage ? "Re-capture" : "Capture Issue Photo"}</div>
                    : <div className={!issueImage ? classes.preCaptureBtn : classes.postCaptureBtn}>{"Photo Uploaded"}</div>
                )
            }
          </div>
          {
            issueImage &&
            <>
              <TextField
                value={comment}
                onChange={(e) => { setComment(e.target.value) }}
                placeholder='Issue'
                style={{ width: "500px", marginTop: 10 }}
              />
              <div className={classes.uploadImg} onClick={() => { uploadIssuePhoto('heightWeight') }} >Upload Photo</div>
            </>
          }
        </div>
      }

      {
        testData?.screeningPackageDetails?.vitalSigns?.includes('HEMOGLOBIN')
        &&
        <div className={classes.poll_container}>
          <div className={classes.heart_poll_container}>
            <div>Hemoglobin</div>
            <div>
              {
                testData?.screeningStatus?.vitalSigns?.hemoglobin
                  ? "Screening done"
                  : "Screening pending"
              }
            </div>
            <div>
              <button onClick={() => { publishTestUpdates('hemoglobin', true) }}
                className={testData?.screeningStatus?.vitalSigns?.hemoglobin ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
              <button
                onClick={() => { publishTestUpdates('hemoglobin', false) }}
                className={!testData?.screeningStatus?.vitalSigns?.hemoglobin ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>
            </div>
          </div>
        </div>
      }

      {testData?.screeningPackageDetails?.vitalSigns?.includes('BLOOD_PRESSURE')
        &&
        <div className={classes.poll_container}>
          <div className={classes.heart_poll_container}>
            <div>Blood Pressure</div>
            <div>
              {
                testData?.screeningStatus?.vitalSigns?.bloodPressure
                  ? "Screening done"
                  : "Screening pending"
              }
            </div>
            <div>
              <button onClick={() => { publishTestUpdates('bloodPressure', true) }}
                className={testData?.screeningStatus?.vitalSigns?.bloodPressure ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
              <button
                onClick={() => { publishTestUpdates('bloodPressure', false) }}
                className={!testData?.screeningStatus?.vitalSigns?.bloodPressure ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>
            </div>
            <br />
            <EditableText c={"morphenBp"} title={"BP"} publishUpdates={publishTestUpdates} value={testData?.vitalSignsAssessment?.bloodPressure} placeholder={"High/Low ie. 80/120"} />
          </div>
        </div>}

      {testData?.screeningPackageDetails?.vitalSigns?.includes('SPO2')
        &&
        <div className={classes.poll_container}>
          <div className={classes.heart_poll_container}>
            <div>SpO2</div>
            <div>
              {
                testData?.screeningStatus?.vitalSigns?.SpO2
                  ? "Screening done"
                  : "Screening pending"
              }
            </div>
            <div>
              <button onClick={() => { publishTestUpdates('spO2', true) }}
                className={testData?.screeningStatus?.vitalSigns?.SpO2 ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
              <button
                onClick={() => { publishTestUpdates('spO2', false) }}
                className={!testData?.screeningStatus?.vitalSigns?.SpO2 ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>
            </div>
          </div>
        </div>}
      <div className={classes.poll_container} style={{ alignItems: "center" }}>
        <div className={classes.heart_poll_container}>
          <div>Masimo Image</div>
          <div>{image ? "Captured" : "Capture image"}</div>
          <div className={classes.masimoCon}>
            {image &&
              <img className={classes.masimoPicture} src={image} alt="" />
            }
            {
              showLoading
                ? <>Uploading masimo... <CircularProgress color='secondary' size={20} /></>
                : <div className={!image ? classes.preCaptureBtn : classes.postCaptureBtn} onClick={() => { setViewCamera(!viewCamera) }}>{image ? "Re-capture" : "capture photo"}</div>
            }
          </div>
          {
            image &&
            <div className={classes.uploadImg} onClick={uploadMasimoPhoto} >Upload Masimo</div>
          }
        </div>
      </div>
      {
        // (testData?.vitalSignsAssessment?.Spo2 || testData?.vitalSignsAssessment?.hemoglobin || testData?.vitalSignsAssessment?.heartRate) &&
        <div className={classes.masimoValues}>
          <EditableText c={"masimoSpO2"} title={"SpO2"} publishUpdates={publishTestUpdates} value={testData?.vitalSignsAssessment?.SpO2} placeholder={"Spo2"} />
          <EditableText c={"masimoHemo"} title={"Hemoglobin"} publishUpdates={publishTestUpdates} value={testData?.vitalSignsAssessment?.hemoglobin} placeholder={"Hemoglobin"} />
          <EditableText c={"masimoHeart"} title={"Heart rate"} publishUpdates={publishTestUpdates} value={testData?.vitalSignsAssessment?.heartRate} placeholder={"Heart rate"} />
        </div>
      }
    </>
      break;
    case "Hair": testsBlock = <>
      <p className={classes.parameterHeading}>Hair Screening</p>
      {testData?.screeningPackageDetails?.hair?.includes('COLOR_OF_HAIR')
        &&
        <div className={classes.poll_container}>
          <div className={classes.heart_poll_container}>
            <div>Color of hair</div>
            <div>
              {
                testData?.screeningStatus?.hair?.color
                  ? "Screening done"
                  : "Screening pending"
              }
            </div>
            <div>
              <button onClick={() => { publishTestUpdates('colorOfHair', 'black') }}
                className={testData?.hairAssessment?.color === 'black' ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Black</button>
              <button
                onClick={() => { publishTestUpdates('colorOfHair', 'grey') }}
                className={testData?.hairAssessment?.color === 'grey' ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Grey</button>
              <button
                onClick={() => { publishTestUpdates('colorOfHair', 'white') }}
                className={testData?.hairAssessment?.color === 'white' ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>White</button>
              <button
                onClick={() => { publishTestUpdates('colorOfHair', 'brown') }}
                className={testData?.hairAssessment?.color === 'brown' ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Brown</button>
              <button
                onClick={() => { publishTestUpdates('colorOfHair', 'red') }}
                className={testData?.hairAssessment?.color === 'red' ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Red</button>
            </div>
          </div>
        </div>}
      {testData?.screeningPackageDetails?.hair?.includes('DANDRUFF')
        &&
        <div className={classes.poll_container}>
          <div className={classes.heart_poll_container}>
            <div>Dandruff</div>
            <div>
              {
                testData?.screeningStatus?.hair?.dandruff
                  ? "Screening done"
                  : "Screening pending"
              }
            </div>
            <div>
              <button onClick={() => { publishTestUpdates('dandruff', true) }}
                className={testData?.screeningStatus?.hair?.dandruff && testData?.hairAssessment?.dandruff
                  ? `${classes.heart_poll_container_button_active}`
                  : `${classes.heart_poll_container_button_inactive}`}>Present</button>
              <button onClick={() => { publishTestUpdates('dandruff', false) }}
                className={testData?.screeningStatus?.hair?.dandruff && !testData?.hairAssessment?.dandruff
                  ? `${classes.heart_poll_container_button_active}`
                  : `${classes.heart_poll_container_button_inactive}`}>Absent</button>
            </div>
          </div>
        </div>}
      {testData?.screeningPackageDetails?.hair?.includes('PEDICULOSIS_(LOUSE/LICE)')
        &&
        <div className={classes.poll_container}>
          <div className={classes.heart_poll_container}>
            <div>Pediculosis (louse/lice)</div>
            <div>
              {
                testData?.screeningStatus?.hair?.pediculosis
                  ? "Screening done"
                  : "Screening pending"
              }
            </div>
            <div>
              <button onClick={() => { publishTestUpdates('pediculosis', true) }}
                className={testData?.screeningStatus?.hair?.pediculosis && testData?.hairAssessment?.pediculosis
                  ? `${classes.heart_poll_container_button_active}`
                  : `${classes.heart_poll_container_button_inactive}`}>Present</button>
              <button
                onClick={() => { publishTestUpdates('pediculosis', false) }}
                className={testData?.screeningStatus?.hair?.pediculosis && !testData?.hairAssessment?.pediculosis
                  ? `${classes.heart_poll_container_button_active}`
                  : `${classes.heart_poll_container_button_inactive}`}>Absent</button>
            </div>
          </div>
        </div>}
      {testData?.screeningPackageDetails?.hair?.includes('LOSS_OF_HAIR')
        &&
        <div className={classes.poll_container}>
          <div className={classes.heart_poll_container}>
            <div>Loss of hair</div>
            <div>
              {
                testData?.screeningStatus?.hair?.lossOfHair
                  ? "Screening done"
                  : "Screening pending"
              }
            </div>
            <div>
              <button onClick={() => { publishTestUpdates('lossOfHair', true) }}
                className={testData?.screeningStatus?.hair?.lossOfHair && testData?.hairAssessment?.lossOfHair
                  ? `${classes.heart_poll_container_button_active}`
                  : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
              <button
                onClick={() => { publishTestUpdates('lossOfHair', false) }}
                className={testData?.screeningStatus?.hair?.lossOfHair && !testData?.hairAssessment?.lossOfHair
                  ? `${classes.heart_poll_container_button_active}`
                  : `${classes.heart_poll_container_button_inactive}`}>No</button>
            </div>
          </div>
        </div>}
    </>
      break;
    case "Eye": testsBlock = <>
      <p className={classes.parameterHeading}>Eye Screening</p>
      <div className={classes.poll_container}>
        <div className={classes.heart_poll_container}>
          <div>Vision</div>
          <div>
            {
              testData?.screeningStatus?.eye?.normalVision
                ? "Screening done"
                : "Screening pending"
            }
          </div>
          <div>
            <button onClick={() => { publishTestUpdates('normalVision', true) }}
              className={testData?.screeningStatus?.eye?.normalVision ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
            <button
              onClick={() => { publishTestUpdates('normalVision', false) }}
              className={!testData?.screeningStatus?.eye?.normalVision ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>
          </div>

          <div className={classes.masimoCon} style={{ marginTop: 20 }}>
            {issueImage &&
              <img className={classes.masimoPicture} src={issueImage} alt="" />
            }
            {
              showLoading
                ? <>Uploading Photo... <CircularProgress color='secondary' size={20} /></>
                : (!findDefect('eye') ? <div className={!issueImage ? classes.preCaptureBtn : classes.postCaptureBtn} onClick={() => { setIssueCamera(!viewIssueCamera) }}>{issueImage ? "Re-capture" : "Capture Issue Photo"}</div>
                  : <div className={!issueImage ? classes.preCaptureBtn : classes.postCaptureBtn}>{"Photo Uploaded"}</div>)
            }
          </div>

          {
            issueImage &&
            <>
              <TextField
                value={comment}
                onChange={(e) => { setComment(e.target.value) }}
                placeholder='Issue'
                style={{ width: "500px", marginTop: 10 }}
              />
              <div className={classes.uploadImg} onClick={() => { uploadIssuePhoto('eye') }} >Upload Photo</div>
            </>
          }

          {/* <div className={classes.heart_poll_container}>
          <div>Issue Image</div>
          <div>{issueImage ? "Captured" : "Capture image"}</div>
        </div> */}
        </div>
      </div>
    </>
      break;
    case "Ear": testsBlock = <>
      <p className={classes.parameterHeading}>Ear Screening</p>
      <div className={classes.poll_container}>
        <div className={classes.heart_poll_container}>
          <div>Right Ear Video</div>
          <div>
            {
              testData?.screeningStatus?.ear?.rightEarVideo
                ? "Screening done"
                : "Screening pending"
            }
          </div>
          <div style={{ marginBottom: 20 }}>
            <button onClick={() => { publishTestUpdates('rigthEar', true) }}
              className={testData?.screeningStatus?.ear?.rightEarVideo ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>

            <button
              onClick={() => { publishTestUpdates('rigthEar', false) }}
              className={!testData?.screeningStatus?.ear?.rightEarVideo ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>
          </div>
          <div className={classes.masimoCon}>
            {issueImage &&
              <img className={classes.masimoPicture} src={issueImage} alt="" />
            }
            {
              showLoading
                ? <>Uploading Photo... <CircularProgress color='secondary' size={20} /></>
                : (!findDefect('rigthEar') ? <div className={!issueImage ? classes.preCaptureBtn : classes.postCaptureBtn} onClick={() => { setIssueCamera(!viewIssueCamera) }}>{issueImage ? "Re-capture" : "Capture Issue Photo"}</div>
                  : <div className={!issueImage ? classes.preCaptureBtn : classes.postCaptureBtn}>{"Photo Uploaded"}</div>)
            }
          </div>
          {
            issueImage &&
            <>
              <TextField
                value={comment}
                onChange={(e) => { setComment(e.target.value) }}
                placeholder='Issue'
                style={{ width: "500px", marginTop: 10 }}
              />
              <div className={classes.uploadImg} onClick={() => { uploadIssuePhoto('rightEar') }} >Upload Photo</div>
            </>
          }
        </div>
      </div>
      <div className={classes.poll_container}>
        <div className={classes.heart_poll_container}>
          <div>Left Ear Video</div>
          <div>
            {
              testData?.screeningStatus?.ear?.leftEarVideo
                ? "Screening done"
                : "Screening pending"
            }
          </div>
          <div style={{ marginBottom: 20 }}>
            <button onClick={() => { publishTestUpdates('leftEar', true) }}
              className={testData?.screeningStatus?.ear?.leftEarVideo ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>

            <button
              onClick={() => { publishTestUpdates('leftEar', false) }}
              className={!testData?.screeningStatus?.ear?.leftEarVideo ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>
          </div>
          <div className={classes.masimoCon}>
            {issueImage &&
              <img className={classes.masimoPicture} src={issueImage} alt="" />
            }
            {
              showLoading
                ? <>Uploading Photo... <CircularProgress color='secondary' size={20} /></>
                : (!findDefect('leftEar') ? <div className={!issueImage ? classes.preCaptureBtn : classes.postCaptureBtn} onClick={() => { setIssueCamera(!viewIssueCamera) }}>{issueImage ? "Re-capture" : "Capture Issue Photo"}</div>
                  : <div className={!issueImage ? classes.preCaptureBtn : classes.postCaptureBtn}>{"Photo Uploaded"}</div>)
            }
          </div>
          {
            issueImage &&
            <>
              <TextField
                value={comment}
                onChange={(e) => { setComment(e.target.value) }}
                placeholder='Issue'
                style={{ width: "500px", marginTop: 10 }}
              />
              <div className={classes.uploadImg} onClick={() => { uploadIssuePhoto('leftEar') }} >Upload Photo</div>
            </>
          }
        </div>
      </div>
    </>
      break;
    case "Hearing": testsBlock = <>
      <p className={classes.parameterHeading}>Hearing Proficiency Screening</p>
      {testData?.screeningPackageDetails?.ear?.includes('HEARING')
        &&
        <div className={classes.poll_container}>
          <div className={classes.heart_poll_container}>
            <div>Hearing Assessment</div>
            <div>
              {
                testData?.screeningStatus?.hearing?.hearingAssessment
                  ? "Screening done"
                  : "Screening pending"
              }
            </div>
            <div>
              <button
                onClick={() => { publishTestUpdates('hearing', true) }}
                className={testData?.screeningStatus?.hearing?.hearingAssessment ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
              <button
                onClick={() => { publishTestUpdates('hearing', false) }}
                className={!testData?.screeningStatus?.hearing?.hearingAssessment ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>
              {/* <button
                disabled={testData.screeningStatus.hearing.resultAwait}
                onClick={() => { ToggleDevice("AMPLIVOX") }}
                className={classes.heart_poll_container_button_inactive}
                style={{ marginLeft: "50px" }}
              >
                {
                  (testData.screeningStatus.hearing.resultAwait)
                    ? <CircularProgress color='secondary' size={30} />
                    : "Start"
                }
              </button> */}
            </div>
            {testData?.hearingAssessment?.reportUrl && <p style={{marginTop: 20, color: "green"}}>Report is already uploaded... </p>}
            <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
              <div style={{ marginTop: 20 }}>
                <div className={classes.preCaptureBtn} onClick={() => window.open("https://www.resound.com/en/online-hearing-test/take-test#", "_blank")}>Start Test</div>
              </div>
              <div style={{ marginTop: 20 }}>
                <div className={classes.preCaptureBtn} onClick={() => fileRef.current.click()}>{testData?.hearingAssessment?.reportUrl ? "Re-upload" : "Upload Photo"}</div>
              </div>
              <input ref={fileRef} type="file" style={{ display: "none" }} accept='image/*' onChange={(e) => handleHearingUpload(e)} />
            </div>
          </div>
        </div>}
    </>
      break;
    case "Dental": testsBlock = <>
      <p className={classes.parameterHeading}>Dental Screening</p>
      {/* <div className={classes.poll_container}>
        <div className={classes.heart_poll_container}>
          <div className={classes.subParaHeading} >Tooth Decay, Missing and Filled</div>
          <DentalJaw handleTeethUpdate={handleTeethUpdate} tooths={testData?.dentalAssessment?.tooth} />
        </div>
      </div> */}
      {/* {testData?.screeningPackageDetails?.dental?.includes('GINGIVITIS')
        &&
        <div className={classes.poll_container}>
          <div className={classes.heart_poll_container}>
            <div>Gingivitis</div>
            <div>
              {
                testData?.screeningStatus?.dental?.gingivitis
                  ? "Screening done"
                  : "Screening pending"
              }
            </div>
            <div>
              <button onClick={() => { publishTestUpdates('gingivitis', true) }}
                className={testData?.screeningStatus?.dental?.gingivitis && testData?.dentalAssessment?.gingivitis
                  ? `${classes.heart_poll_container_button_active}`
                  : `${classes.heart_poll_container_button_inactive}`}>Present</button>
              <button
                onClick={() => { publishTestUpdates('gingivitis', false) }}
                className={testData?.screeningStatus?.dental?.gingivitis && !testData?.dentalAssessment?.gingivitis
                  ? `${classes.heart_poll_container_button_active}`
                  : `${classes.heart_poll_container_button_inactive}`}>Absent</button>
            </div>
          </div>
        </div>}
      {testData?.screeningPackageDetails?.dental?.includes('ORTHODONTIC_PROBLEM')
        &&
        <div className={classes.poll_container}>
          <div className={classes.heart_poll_container}>
            <div>Orthodontic Problem</div>
            <div>
              {
                testData?.screeningStatus?.dental?.orthodontic
                  ? "Screening done"
                  : "Screening pending"
              }
            </div>
            <div>
              <button onClick={() => { publishTestUpdates('orthodontic', true) }}
                className={testData?.screeningStatus?.dental?.orthodontic && testData?.dentalAssessment?.orthodontic
                  ? `${classes.heart_poll_container_button_active}`
                  : `${classes.heart_poll_container_button_inactive}`}>Present</button>
              <button
                onClick={() => { publishTestUpdates('orthodontic', false) }}
                className={testData?.screeningStatus?.dental?.orthodontic && !testData?.dentalAssessment?.orthodontic
                  ? `${classes.heart_poll_container_button_active}`
                  : `${classes.heart_poll_container_button_inactive}`}>Absent</button>
            </div>
          </div>
        </div>} */}
      {testData?.screeningPackageDetails?.dental?.includes('DECOLORATION')
        &&
        <div className={classes.poll_container}>
          <div className={classes.heart_poll_container}>
            <div>Dental Screening</div>
            <div>
              {
                testData?.screeningStatus?.dental?.decoloration
                  ? "Screening done"
                  : "Screening pending"
              }
            </div>
            <div>
              <button
                onClick={() => { publishTestUpdates('dentalScreening', true) }}
                className={testData?.screeningStatus?.dental?.decoloration
                  ? `${classes.heart_poll_container_button_active}`
                  : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
              <button
                onClick={() => { publishTestUpdates('dentalScreening', false) }}
                className={!testData?.screeningStatus?.dental?.decoloration
                  ? `${classes.heart_poll_container_button_active}`
                  : `${classes.heart_poll_container_button_inactive}`}>No</button>
              {/* <button
                onClick={() => { ToggleDevice("CARESTREAM") }}
                className={classes.heart_poll_container_button_inactive}
                style={{ marginLeft: "50px" }}
              >
                {
                  (testData?.screeningStatus?.dental?.resultAwait)
                    ? <CircularProgress color='secondary' size={30} />
                    : "Start"
                }
              </button> */}
            </div>
          </div>
        </div>}
    </>
      break;
    case "Throat": testsBlock = <>
      <p className={classes.parameterHeading}>Throat Screening</p>
      {testData?.screeningPackageDetails?.throat?.length > 0 && <div className={classes.poll_container}>
        <div className={classes.heart_poll_container}>
          <div>Throat Video</div>
          <div>
            {
              testData?.screeningStatus?.throat?.video
                ? "Screening done"
                : "Screening pending"
            }
          </div>
          <div>
            <button onClick={() => { publishTestUpdates('throat', true) }}
              className={testData?.screeningStatus?.throat?.video ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
            <button
              onClick={() => { publishTestUpdates('throat', false) }}
              className={!testData?.screeningStatus?.throat?.video ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>
          </div>
        </div>
      </div>}
    </>
      break;
    case "Cough": testsBlock = <>
      <p className={classes.parameterHeading}>Cough Screening</p>
      {testData?.screeningPackageDetails?.lungs?.includes("COUGH") && <div className={classes.poll_container}>
        <div className={classes.heart_poll_container}>
          <div>Cough Exam</div>
          <div>
            {
              testData?.screeningStatus?.cough?.exam
                ? "Screening done"
                : "Screening pending"
            }
          </div>
          <div>
            <button onClick={() => { publishTestUpdates('cough', true) }}
              className={testData?.screeningStatus?.cough?.exam ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
            <button
              onClick={() => { publishTestUpdates('cough', false) }}
              className={!testData?.screeningStatus?.cough?.exam ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>
          </div>
        </div>
      </div>}
    </>
      break;

    case "Heart": testsBlock = <>
      <p className={classes.parameterHeading}>Heart Screening</p>
      {testData?.screeningPackageDetails?.heart?.length > 0 && <div className={classes.poll_container}>
        <div className={classes.heart_poll_container}>
          <div>Heart Estimation & Auscultation</div>
          <div>
            {
              testData?.screeningStatus?.heart?.estimation
                ? "Screening done"
                : "Screening pending"
            }
          </div>
          <div>
            <button onClick={() => { publishTestUpdates('heartEstimation', true) }}
              className={testData?.screeningStatus?.heart?.estimation ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
            <button
              onClick={() => { publishTestUpdates('heartEstimation', false) }}
              className={!testData?.screeningStatus?.heart?.estimation ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>
          </div>
        </div>
      </div>}
    </>
      break;
    case "Lungs": testsBlock = <>
      <p className={classes.parameterHeading}>Lungs Screening</p>
      {(testData?.screeningPackageDetails?.lungs?.includes("RESPIRATORY_SOUNDS") ||
        testData?.screeningPackageDetails?.lungs?.includes("DYSPNEA") ||
        testData?.screeningPackageDetails?.lungs?.includes("WHEEZING") ||
        testData?.screeningPackageDetails?.lungs?.includes("RATTLING")
      ) && <div className={classes.poll_container}>
          <div className={classes.heart_poll_container}>
            <div>Lungs Auscultation</div>
            <div>
              {
                testData?.screeningStatus?.lungs?.auscultation
                  ? "Screening done"
                  : "Screening pending"
              }
            </div>
            <div>
              <button onClick={() => { publishTestUpdates('lungsAuscultation', true) }}
                className={testData?.screeningStatus?.lungs?.auscultation ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
              <button
                onClick={() => { publishTestUpdates('lungsAuscultation', false) }}
                className={!testData?.screeningStatus?.lungs?.auscultation ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>
            </div>
          </div>
        </div>}
    </>
      break;
    case "Abdominal": testsBlock = <>
      <p className={classes.parameterHeading}>Abdomen Screening</p>
      {testData?.screeningPackageDetails?.abdomen?.length > 0 && <div className={classes.poll_container}>
        <div className={classes.heart_poll_container}>
          <div>Abdominal Auscultation</div>
          <div>
            {
              testData?.screeningStatus?.abdomin?.auscultation
                ? "Screening done"
                : "Screening pending"
            }
          </div>
          <div>
            <button onClick={() => { publishTestUpdates('abdominal', true) }}
              className={testData?.screeningStatus?.abdomin?.auscultation ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
            <button
              onClick={() => { publishTestUpdates('abdominal', false) }}
              className={!testData?.screeningStatus?.abdomin?.auscultation ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>
          </div>
        </div>
      </div>}
    </>
      break;
    case "Skin": testsBlock = <>
      <p className={classes.parameterHeading}>Skin Screening</p>
      {testData?.screeningPackageDetails?.skin?.length > 0 && <div className={classes.poll_container}>
        <div className={classes.heart_poll_container}>
          <div>Skin Video</div>
          <div>
            {
              testData?.screeningStatus?.skin?.video
                ? "Screening done"
                : "Screening pending"
            }
          </div>
          <div>
            <button onClick={() => { publishTestUpdates('skin', true) }}
              className={testData?.screeningStatus?.skin?.video ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
            <button
              onClick={() => { publishTestUpdates('skin', false) }}
              className={!testData?.screeningStatus?.skin?.video ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>No</button>
          </div>
        </div>
      </div>}
    </>
      break;

    // case "Vital-2": testsBlock = <>
    //   <div className={classes.heart_poll_container}>
    //     <div>SpO2</div>
    //     <div>
    //       <button onClick={() => { publishTestUpdates('spo2', true) }}
    //         className={testData?.spO2 ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}>Yes</button>
    //       <button
    //         onClick={() => { publishTestUpdates('spo2', false) }}
    //         className={!testData?.spO2 ? `${classes.heart_poll_container_button_active}` : `${classes.heart_poll_container_button_inactive}`}
    //       >No</button>
    //     </div>
    //   </div>
    // </>
    //   break;
  }

  useEffect(() => {
    console.log(dentalDoctor)
  }, [dentalDoctor])

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormControl fullWidth style={{ marginBottom: 20 }}>
            <InputLabel id="demo-simple-select-label">Physical Doctor</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={phyDoctor}
              label="Physical Doctor"
              onChange={(e) => setPhyDoctor(e.target.value)}
            >
              {phyDoctorList.map((x) => {
                return <MenuItem value={x.userId}>{`${x.given_name} ${x.family_name}`}</MenuItem>
              })}

            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 20 }}>
            <InputLabel id="demo-simple-select-label">Behavioral Doctor</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={bhvDoctor}
              label="Physical Doctor"
              onChange={(e) => setbhvDoctor(e.target.value)}
            >
              {bhvDoctorList.map((x) => {
                return <MenuItem value={x.userId}>{`${x.given_name} ${x.family_name}`}</MenuItem>
              })}

            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 20 }}>
            <InputLabel id="demo-simple-select-label">Dental Doctor</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={dentalDoctor}
              label="Physical Doctor"
              onChange={(e) => setdentalDoctor(e.target.value)}
            >
              {dentalDoctorList.map((x) => {
                return <MenuItem value={x.userId}>{`${x.given_name} ${x.family_name}`}</MenuItem>
              })}

            </Select>
          </FormControl>
          <Button onClick={handleImmediateAssign}>
            Assign
          </Button>
        </Box>
      </Modal>
      <LoadingBar
        color='#3b3cea'
        height={4}
        progress={progress}
        onLoaderFinished={() => setProgress(0)} />
      <BreadCrumbs />
      <SkidsCamera viewCamera={viewCamera} setImage={setImage} handleClose={handleClose} />
      <SkidsCamera viewCamera={viewIssueCamera} setImage={setIssueImage} handleClose={handleIssueClose} />
      <div className={classes.studentDetailMainCon}>
        <div className={classes.studentDetails}>
          <div className={classes.avtarSection}>
            <Avatar {...stringAvatar(`${testData?.firstName || "–"}`)} sx={{ width: 55, height: 55, fontSize: 48, fontWeight: "bold" }} />
            <div className={classes.student_details_information_name}>{testData?.firstName || "Fetching..."}</div>
          </div>
          <div>
            <div>
              <p>Age</p>
              <p>{calculateAge(studentData?.[0]?.DOB)}</p>
            </div>
            <div>
              <p>Gender</p>
              <p>{studentData?.[0]?.gender}</p>
            </div>
            <div>
              <p>Class</p>
              <p>{numberTh(studentData?.[0]?.class) + " " + studentData?.[0]?.section}</p>
            </div>
            <div>
              <p>Health Package</p>
              <p>{testData?.package}</p>
            </div>
            <div>
              <p>Screening Progress</p>
              <div>
                <div><span>{Object.keys(testUpdate).length}</span>/12</div>
                <div><BorderLinearProgress variant="determinate" value={(Object.keys(testUpdate).length / 12 * 100)} /></div>
              </div>
            </div>
            {
              testData?.status === "SCREENING_DONE" || testData.status === "ASSIGN_VALIDATION"
                ? <div onClick={handleScreeningPending}><Checkanimated active={true} /></div>
                : (schoolData.isClinic ? <div onClick={() => {
                  handleOpenModal()
                }} className={classes.doneButton}>
                  Done
                </div>
                  : <div onClick={handleScreeningDone} className={classes.doneButton}>
                    Done
                  </div>)
            }

          </div>
        </div>
        <div className={classes.validatedCheck}>
          {/* <CheckCircleIcon onClick={handleValidationComplete} sx={{ fill: "#e5e5e5", fontSize: '50px' }} /> */}
        </div>
      </div>

      <div className={classes.container}>
        <div>
          <p>Parameters to validated</p>
          <div>

            <div
              onClick={() => { setOrganActive("Vital") }}
              className={isorganActive === "Vital" ? classes.parametersCheckActive : classes.parametersCheckInactive}>
              <div className={isorganActive === "Vital" ? classes.parameterActive : classes.parameterInactive}>Vital</div>
              <div>
                <CheckCircleIcon sx={{ fill: testUpdate.Vital ? "#42C134" : "#e5e5e5", fontSize: '20px' }} />
              </div>
            </div>
            <div
              onClick={() => { setOrganActive("Hair") }}
              className={isorganActive === "Hair" ? classes.parametersCheckActive : classes.parametersCheckInactive}>
              <div className={isorganActive === "Hair" ? classes.parameterActive : classes.parameterInactive}>Hair</div>
              <div>
                <CheckCircleIcon sx={{ fill: testUpdate.Hair ? "#42C134" : "#e5e5e5", fontSize: '20px' }} />
              </div>
            </div>
            <div
              onClick={() => { setOrganActive("Eye") }}
              className={isorganActive === "Eye" ? classes.parametersCheckActive : classes.parametersCheckInactive}>
              <div className={isorganActive === "Eye" ? classes.parameterActive : classes.parameterInactive}>Eye</div>
              <div>
                <CheckCircleIcon sx={{ fill: testUpdate.Eye ? "#42C134" : "#e5e5e5", fontSize: '20px' }} />
              </div>
            </div>
            <div
              onClick={() => { setOrganActive("Ear") }}
              className={isorganActive === "Ear" ? classes.parametersCheckActive : classes.parametersCheckInactive}>
              <div className={isorganActive === "Ear" ? classes.parameterActive : classes.parameterInactive}>Ear</div>
              <div>
                <CheckCircleIcon sx={{ fill: testUpdate.Ear ? "#42C134" : "#e5e5e5", fontSize: '20px' }} />
              </div>
            </div>
            <div
              onClick={() => { setOrganActive("Hearing") }}
              className={isorganActive === "Hearing" ? classes.parametersCheckActive : classes.parametersCheckInactive}>
              <div className={isorganActive === "Hearing" ? classes.parameterActive : classes.parameterInactive}>Hearing</div>
              <div>
                <CheckCircleIcon sx={{ fill: testUpdate.Hearing ? "#42C134" : "#e5e5e5", fontSize: '20px' }} />
              </div>
            </div>
            <div
              onClick={() => { setOrganActive("Dental") }}
              className={isorganActive === "Dental" ? classes.parametersCheckActive : classes.parametersCheckInactive}>
              <div className={isorganActive === "Dental" ? classes.parameterActive : classes.parameterInactive}>Dental</div>
              <div>
                <CheckCircleIcon sx={{ fill: testUpdate.Dental ? "#42C134" : "#e5e5e5", fontSize: '20px' }} />
              </div>
            </div>
            <div
              onClick={() => { setOrganActive("Throat") }}
              className={isorganActive === "Throat" ? classes.parametersCheckActive : classes.parametersCheckInactive}>
              <div className={isorganActive === "Throat" ? classes.parameterActive : classes.parameterInactive}>Throat</div>
              <div>
                <CheckCircleIcon sx={{ fill: testUpdate.Throat ? "#42C134" : "#e5e5e5", fontSize: '20px' }} />
              </div>
            </div>
            <div
              onClick={() => { setOrganActive("Cough") }}
              className={isorganActive === "Cough" ? classes.parametersCheckActive : classes.parametersCheckInactive}>
              <div className={isorganActive === "Cough" ? classes.parameterActive : classes.parameterInactive}>Cough</div>
              <div>
                <CheckCircleIcon sx={{ fill: testUpdate.Cough ? "#42C134" : "#e5e5e5", fontSize: '20px' }} />
              </div>
            </div>
            <div
              onClick={() => { setOrganActive("Heart") }}
              className={isorganActive === "Heart" ? classes.parametersCheckActive : classes.parametersCheckInactive}>
              <div className={isorganActive === "Heart" ? classes.parameterActive : classes.parameterInactive}>Heart</div>
              <div>
                <CheckCircleIcon sx={{ fill: testUpdate.Heart ? "#42C134" : "#e5e5e5", fontSize: '20px' }} />
              </div>
            </div>
            <div
              onClick={() => { setOrganActive("Lungs") }}
              className={isorganActive === "Lungs" ? classes.parametersCheckActive : classes.parametersCheckInactive}>
              <div className={isorganActive === "Lungs" ? classes.parameterActive : classes.parameterInactive}>Lungs</div>
              <div>
                <CheckCircleIcon sx={{ fill: testUpdate.Lungs ? "#42C134" : "#e5e5e5", fontSize: '20px' }} />
              </div>
            </div>
            <div
              onClick={() => { setOrganActive("Abdominal") }}
              className={isorganActive === "Abdominal" ? classes.parametersCheckActive : classes.parametersCheckInactive}>
              <div className={isorganActive === "Abdominal" ? classes.parameterActive : classes.parameterInactive}>Abdominal</div>
              <div>
                <CheckCircleIcon sx={{ fill: testUpdate.Abdominal ? "#42C134" : "#e5e5e5", fontSize: '20px' }} />
              </div>
            </div>
            <div
              onClick={() => { setOrganActive("Skin") }}
              className={isorganActive === "Skin" ? classes.parametersCheckActive : classes.parametersCheckInactive}>
              <div className={isorganActive === "Skin" ? classes.parameterActive : classes.parameterInactive}>Skin</div>
              <div>
                <CheckCircleIcon sx={{ fill: testUpdate.Skin ? "#42C134" : "#e5e5e5", fontSize: '20px' }} />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.parameterContainer}>
          {testsBlock}
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >

        <Alert
          onClose={handleCloseSnackBar}
          severity={!error ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {!error
            ? "Image Upload Successfully"
            : "Error While Uploading Image"}
        </Alert>
      </Snackbar>
    </div>
  )
}

function IndividualScreening() {
  return (
    <IndividualScreeningPage />
  )
}
export default IndividualScreening;








{/* <div>
          <div className={classes.avatar_container}>
            <Avatar {...stringAvatar(`${user_name}`)} sx={{ height: 120, width: 120, fontSize: 64, fontWeight: "bold" }} />
            <div className={classes.upload_container}></div>
          </div>
          <div className={classes.text_container}>Sachin Kumar</div>
          <div className={classes.details_container}>
            <div>Age</div>
            <div>Gender</div>
            <div>Class</div>
            <div>6 yrs</div>
            <div>Male</div>
            <div>1st A</div>
          </div>

          <div className={classes.fullbody_physical_outer_container}>
            <div className={classes.fullbody_physical_image_container}>
              {isorganActive !== 'Lungs' ? (
                <div>
                  <div className={classes.stethoscope_check_container}>
                    <div className={classes.stethoscope_check}>
                      <div></div>
                      <img src={stethoscopegreen} alt="stethoscopegreen" />
                    </div>
                  </div>
                  <div className={isorganActive === 'Eye' ? `${classes.visibility_container} ${classes.visibility_container_active}` :
                    `${classes.visibility_container}`} >
                    <div className={isorganActive === 'Eye' ? `${classes.visibility_container_main} ${classes.visibility_container_main_active}`
                      : `${classes.visibility_container_main}`}>
                      <div onClick={() => setOrganActive('Eye')}
                        className={isorganActive === 'Eye' ? `${classes.visibility_container_inner} ${classes.visibility_container_inner_active}` : `${classes.visibility_container_inner}`}>
                        <img src={visilbilty} alt="visibility" />
                      </div>
                    </div>
                  </div>

                  <div className={isorganActive === 'Eye' ? `${classes.visibility_right_container} ${classes.visibility_right_container_active}` :
                    `${classes.visibility_right_container}`} >
                    <div className={isorganActive === 'Eye' ? `${classes.visibility_right_container_main} ${classes.visibility_right_container_main_active}`
                      : `${classes.visibility_right_container_main}`}>
                      <div onClick={() => setOrganActive('Eye')}
                        className={isorganActive === 'Eye' ? `${classes.visibility_right_container_inner} ${classes.visibility_right_container_inner_active}` : `${classes.visibility_container_inner}`}>
                        <img src={visilbilty} alt="visibility" />
                      </div>
                    </div>
                  </div>

                  <div className={isorganActive === 'Cough' ? `${classes.wind_container} ${classes.wind_container_active}` : `${classes.wind_container}`}>
                    <div className={isorganActive === 'Cough' ? `${classes.wind_container_main} ${classes.wind_container_main_active}` : `${classes.wind_container_main}`}>
                      <div onClick={() => setOrganActive('Cough')} className={isorganActive === 'Cough' ? `${classes.wind_container_inner} ${classes.wind_container_inner_active}` :
                        `${classes.wind_container_inner}`}>
                        <img src={wind} alt="wind" />
                      </div>
                    </div>
                  </div>

                  <div className={isorganActive === 'Throat' ? `${classes.ent_container} ${classes.ent_container_active}` : `${classes.ent_container}`}>
                    <div className={isorganActive === 'Throat' ? `${classes.ent_container_main} ${classes.ent_container_main_active} ` : `${classes.ent_container_main}`}>
                      <div onClick={() => setOrganActive('Throat')} className={isorganActive === 'Throat' ? `${classes.ent_container_inner} ${classes.ent_container_inner_active}` : `${classes.ent_container_inner}`}>
                        <img src={ent} alt="ent" />
                      </div>
                    </div>
                  </div>

                  <div className={isorganActive === 'Ear' ? `${classes.ear_container} ${classes.ear_container_active}` :
                    `${classes.ear_container}`}>
                    <div className={isorganActive === 'Ear' ? `${classes.ear_container_main} ${classes.ear_container_main_active}` :
                      `${classes.ear_container_main}`}>
                      <div onClick={() => setOrganActive('Ear')} className={isorganActive === 'Ear' ? `${classes.ear_container_inner} ${classes.ear_container_inner_active}` :
                        `${classes.ear_container_inner}`}>
                        <img src={earImg} alt="ear" />
                      </div>
                    </div>
                  </div>

                  <div className={isorganActive === 'Ear' ? `${classes.ear_left_container} ${classes.ear_left_container_active}` :
                    `${classes.ear_left_container}`}>
                    <div className={isorganActive === 'Ear' ? `${classes.ear_left_container_main} ${classes.ear_left_container_main_active}` :
                      `${classes.ear_left_container_main}`}>
                      <div onClick={() => setOrganActive('Ear')} className={isorganActive === 'Ear' ? `${classes.ear_left_container_inner} ${classes.ear_left_container_inner_active}` :
                        `${classes.ear_container_inner}`}>
                        <img src={earImg} alt="ear" />
                      </div>
                    </div>
                  </div>

                  <div className={isorganActive === 'Heart' ? `${classes.heartmonitor_container} ${classes.heartmonitor_container_active}` : `${classes.heartmonitor_container}`}>
                    <div className={isorganActive === 'Heart' ? `${classes.heartmonitor_container_main} ${classes.heartmonitor_container_main_active}` : `${classes.heartmonitor_container_main}`}>
                      <div onClick={() => setOrganActive('Heart')} className={isorganActive === 'Heart' ? `${classes.heartmonitor_container_inner} ${classes.heartmonitor_container_inner_active}` : `${classes.heartmonitor_container_inner}`}>
                        <div></div>
                        <img src={heartmonitor} alt="heartmonitor" />
                      </div>
                    </div>
                  </div>

                  <div className={classes.pulmonology_container_main}>
                    <div onClick={() => setOrganActive('Lungs')}
                      className={classes.pulmonology_container_inner}>

                      <img src={pulmonology} alt="pulmonology" />
                    </div>
                  </div>

                  <div className={isorganActive === 'Abdominal' ? `${classes.gastroenterology_container} ${classes.gastroenterology_container_active} ` : `${classes.gastroenterology_container}`}>
                    <div className={isorganActive === 'Abdominal' ? `${classes.gastroenterology_container_main} ${classes.gastroenterology_container_main_active}` : `${classes.gastroenterology_container_main}`}>
                      <div onClick={() => setOrganActive('Abdominal')} className={isorganActive === 'Abdominal' ? `${classes.gastroenterology_container_inner} ${classes.gastroenterology_container_inner_active}` : `${classes.gastroenterology_container_inner}`}>
                        <img src={gastroenterology} alt="gastroenterology" />
                      </div>
                    </div>
                  </div>

                  <div className={isorganActive === 'Skin' ? `${classes.fingerprint_container} ${classes.fingerprint_container_active}`
                    : `${classes.fingerprint_container}`}>
                    <div className={isorganActive === 'Skin' ? `${classes.fingerprint_container_main} ${classes.fingerprint_container_main_active}` : `${classes.fingerprint_container_main}`}>
                      <div onClick={() => setOrganActive('Skin')} className={isorganActive === 'Skin' ? `${classes.fingerprint_container_inner} ${classes.fingerprint_container_inner_active}` : `${classes.fingerprint_container_inner}`}>
                        <img src={fingerprintgreen} alt="fingerprint" />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className={classes.lungs_container_main} >
                    <div className={classes.lungs_container}>
                      <img src={checkcircle} alt="lungs-check1" />
                      <img src={checkcircle} alt="lungs-check2" />
                      <img src={checkcircle} alt="lungs-check3" />
                      <img src={checkcircle} alt="lungs-check4" />
                      <img src={checkcircle} alt="lungs-check5" />
                      <img src={checkcircle} alt="lungs-check6" />
                    </div>
                  </div>
                </div>
              )}
              <img src={fullbody} alt="fullbody" />
            </div>
          </div>

        </div> */}

{/* <div className={classes.buttons_container}>
            <div className={classes.buttons}>
              <button className={classes.button_with_border}>
                Cancel
              </button>
              <button disabled={true} className={classes.button_filled}>Completed </button>
            </div>
          </div> */}



{/* <div className={classes.organs_navigator}>
              {organs.map((el) => {
                return (
                  <div onClick={() => setOrganActive(el)} className={isorganActive === el ? `${classes.organs_navigator_elements_active}` : ``}
                  >{el}</div>
                )
              })}
            </div> */}