import React from 'react'
import classes from './AppointmentsRow.module.css'
import { Avatar, Button } from '@mui/material'
import { Visibility } from '@mui/icons-material'
import PackageDrawer from '../../common/PackageDrawer/PackageDrawer'
import AppointmentsSideBar from '../../common/AppointmentsSideBar/AppointmentsSideBar'
import { capitalizeFirstLetter, getAge, stringAvatar, truncateString } from '../../../utils/utility'
import { useEffect } from 'react'
import { useState } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

function AssessmentsRow({ data }) {
    const [consultation, setConsultation] = useState()
    const navigate = useNavigate()
    useEffect(() => {
        setConsultation(JSON.parse(data?.consultation))
    }, [])
    return (
        <>
            <div className={classes.studentRow_container}>

                {/* Name and Avatar Container */}
                <div>
                    {/* <div>
                        {!data?.url ? <Avatar {...stringAvatar(`${data?.schoolName} `)} /> : <img src={data?.url} style={{ borderRadius: 50 }} alt="schoolLogo" width="32" height="32" />}
                    </div> */}
                    <div>
                        <Avatar {...stringAvatar(data?.name)} />
                    </div>
                    {/* )} */}

                    {/* Name Container */}
                    {/* <div title={data?.schoolName}>
                        <p>{data?.schoolName ? capitalizeFirstLetter(truncateString(data?.schoolName, 30)) : "Unavailable"}</p>
                    </div> */}
                    <div>
                        {data?.name ? capitalizeFirstLetter(truncateString(data?.name, 30)) : "Unavailable"}
                    </div>
                </div>

                <div>
                    <p>{getAge(data?.DOB)} yrs</p>
                </div>

                <div>
                    {data?.gender}
                </div>

                <div>
                    {consultation?.type == "VIDEO_CALL" ? 'Video Consultation' : "Consultation"}
                </div>

                <div>{moment.utc(consultation?.startDate).format("D MMM YYYY, hh:mm A")}</div>

                {/* <div>
                    <span>Yes</span>
                    <AppointmentsSideBar data={data} />
                </div> */}

                <div>
                    <Button>SCHEDULED</Button>
                    <AppointmentsSideBar data={data} />
                </div>

                {/* <div style={{ justifySelf: "center" }}>
                    <Visibility />
                </div> */}
                <div className={classes.package_drawer} style={{ justifySelf: "center", cursor: 'pointer' }}>
                    <Visibility onClick={() => navigate("/behavioral-doctor/dashboard/appointments/healthHistory", { state: { data, breadCrumbs: ['Appointments', data?.name] } })} />
                </div>

            </div>
        </>
    )
}

export default AssessmentsRow