import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import './ScheduleModal.css';
import ScheduleCalendar from './ScheduleCalendar'
import moment from 'moment';
import eventReducer from '../../../redux/reducers/event.reducer';
import { useDispatch } from 'react-redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const btn = {
  border: 'none',
  background: 'none',
  fontSize : "12px",
  color : "#3b3cea",
  padding : "0 !important",
  textTransform: 'none'
}

const slideshow_container = {
  position: 'relative',
  width: '60vw',
  padding : "16px 16px 24px 48px",
  borderRadius: '10px',
  top: '50%',
  left: '50%',
  zIndex: 9999,
  backgroundColor: '#FFFFFF',
  transform: 'translate(-50%, -50%)',
}

export default function BasicModal({schoolData}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [date, setDate] = React.useState([])
  const dispatch = useDispatch()
  const createEvent = eventReducer.actions.createEventRequest
  let url

  const getAverageStudents = () => {
    const from = moment(date[0])
    const to = moment(date[1])
    const days = to.diff(from, 'days')
    return (schoolData?.strength/days).toFixed(0)
  }

  const handleSubmit = () => {
    const params = {
      fromDate: moment(date[0]).format("YYYY-MM-DD"),
      isActive: true, 
      location: schoolData?.city, 
      schoolId: schoolData?.schoolId, 
      status: "Confirmed", 
      strength: schoolData?.strength, 
      toDate: moment(date[0]).format("YYYY-MM-DD"),
    }
    dispatch(createEvent(params))
    setDate([])
  }

  return (
    <div>
      <Button style={btn}  onClick={handleOpen}>
         {`S`+`chedule`}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box sx={style}> */}
        <div style={slideshow_container}>
          <div className = "cancel_btn">
          <CancelIcon onClick = {() => handleClose()}sx = {{fill : "#66707E"}}/>
          </div>
          <div className = "schedule_event_header">
            <p>Schedule Event</p>
            <p>Please select available dates for school to schedule screening</p>
          </div>
          <div className = "schedule_event_component">
            <div className = "calendar_container" >
              <ScheduleCalendar  value={date} onchange={setDate} />
            </div>
            <div>
            <div className = "schedule_total_students_text">
              <p>Total Students</p>
              <p>{schoolData?.strength}</p>
            </div>

            <div className = "event_start_info_container">
                <div>
                  <p>Event Start Date</p>
                  <p>{date?.length ? `${date[0]?.getDate()}/${date[0]?.getMonth()+1}/${date[0]?.getFullYear()}`:''}</p>
                </div>

                <div>
                  <p>Event End Date</p>
                  <p>{date?.length ? `${date[1]?.getDate()}/${date[1]?.getMonth()+1}/${date[1]?.getFullYear()}`:''}</p>
                </div>
              </div>  

              <div className = "average_student_text">
                <p>Average Student per day</p>
                <p>{date?.length ? getAverageStudents() : ''}</p>
              </div>

              <div className = "schedule_container">*Different class can be assigned to the above selected dates at later stages by school admin.</div>
            </div>
            
          </div>
          <div className = "decison_buttons_container">
            <button onClick={() => handleClose()}>Cancel</button>
            <button onClick={handleSubmit}>Schedule</button>
          </div>
        </div>
        {/* </Box> */}
      </Modal>
    </div>
  );
}