import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router'
import userReducer from '../../../redux/reducers/user.reducer'
import Infirmary from '../../common/InfirmaryTable/Infirmary'
import clinincReducer from '../../../redux/reducers/clininc.reducer'


export default function PrincipalInfirmary() {
  const { type } = useParams()

  const dispatch = useDispatch()
  const location = useLocation()
  const getPrincipal = userReducer.actions.getUserByGroupRequest;
  const getDoctors = userReducer.actions.getDoctorsByClinicRequest;
  const getNurses = userReducer.actions.getNursesByClinicRequest;
  const getSchoolInfirmary = userReducer.actions.getSchoolInfirmaryRequest
  const data = useSelector((state) => state.userReducer.users)
  const loading = useSelector((state) => state.userReducer.loading)
  

  const user = useSelector((state) => state.userReducer.currentUser)



  useEffect(() => {
    if ((type === "principal")) {
      dispatch(getPrincipal({ role: "school-principal", schoolId: location.state.schoolId }))
    }
    else if (type === "admin") {
      dispatch(getPrincipal({ role: "school-admin", schoolId: location.state.schoolId }))
    }
    else if (type === "doctor" && location.state.schoolId) {
      dispatch(getSchoolInfirmary(location.state.schoolId))
    }
    else if (location.pathname?.includes("principal-infirmary")) {
      dispatch(getSchoolInfirmary(user?.schoolId))
    }
    else {
      if (type === "doctor") {

        dispatch(getDoctors(location.state?.clinicCode))
      }
      else {
        dispatch(getNurses(location.state?.clinicCode))
      }
    }
  }, [])

  return (

    <Infirmary data={data} load={loading} />

  )
}