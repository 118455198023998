import React from 'react'
import classes from './gap.module.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Hr from '../HrLine/Hr';
import bulb from '../../../assets/images/bulb.png'

const GetAdvancePhysical = (props) => {

    return (
        <div className={classes.sbpCon}>
            <p>{props.titleText}</p>
            <p>{props.susbTitleText}</p>
            <div className={classes.testsLists}>
                {
                    props?.tests?.map((test) => {
                        return (
                            <div>
                                <CheckCircleIcon sx={{ fill: '#66707E', fontSize: '15px' }} />
                                <p>{test}</p>
                            </div>
                        )
                    })
                }
            </div>
            <Hr />
            <div className={classes.locateClinics}>
                <img src={bulb} alt="" />
                <div>
                    <p>Interested in getting the {props?.titleText?.split(' ')?.[0]} screening done?</p>
                    <div className={classes.ClickHereBlueBlack}>
                        <span>Click here</span>
                        <p>to locate our clinics for advanced package screening</p>
                    </div>
                </div>
            </div>
            <Hr />
        </div>

    )
}

export default GetAdvancePhysical