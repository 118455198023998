import * as React from "react"
const SvgComponent = (props) => (
    <div style={{ ...props.customStyle }}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke="#197BD2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                clipPath="url(#a)"
            >
                <path d="M30.65 16.426s-2.066-5.373-5.26-6.141c-3.193-.769-4.79-1.056-4.79-1.056s-2.442-.43-3.992 1.775c-1.55 2.206-3.663 5.422-3.663 5.422s-2.279 2.95-.658 4.604c1.62 1.654 3.71-.576 3.71-.576l2.677-3.694s1.403-1.122 2.454-.048c1.052 1.074 2.712 2.976 4.497 3.409M35.839 14.531h-6.097M18.674 28.993s.469-2.406-2.357-2.406H12.24s-2.305-.128-2.305 2.355" />
                <path d="M18.674 28.695s.469 2.407-2.357 2.407H12.24s-2.305.128-2.305-2.354M18.674 24.259s.469-2.406-2.357-2.406H12.24s-2.305-.129-2.305 2.354" />
                <path d="M18.674 23.96s.469 2.408-2.357 2.408H12.24s-2.305.128-2.305-2.354M12.24 21.856s-2.988-.24-2.988-3.052c0-2.81 3.457-2.043 3.457-2.043s-3.711-.094-3.711-2.686 3.054-2.4 3.054-2.4h4.09M18.037 17.64s.836.846.666 1.904c-.17 1.058-1.484 2.098-1.484 2.098M16.56 31.102h3.806s3.687.454 7.092-3.024h8.383" />
                <path d="m9.995 29.395-5.833 1.729 6.435 1.18-2.96 3.453 6.904-2.303 3.432 4.03 2.392-3.838 6.622 2.11-2.067-3.405 7.609-.96-6.077-2.399M20.49 2.508a13.935 13.935 0 0 0-1.504 4.558M16.142 3.281A13.918 13.918 0 0 0 14.64 7.84M11.77 4.219a13.933 13.933 0 0 0-1.502 4.557M24.611 2.89a13.91 13.91 0 0 0-1.504 4.558M28.503 3.93A13.92 13.92 0 0 0 27 8.487" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M3.334 1.664h33.333v36.667H3.334z" />
                </clipPath>
            </defs>
        </svg>
    </div>
)
export default SvgComponent
