import React, { useState } from "react";
import classes from "./EditSchoolDetails.module.css";
import DashboardLayout from "../common/DashboardLayout/DashboardLayout";
import ArrowRight from "@mui/icons-material/ArrowRight";
import schoollogo from "../../assets/images/schoollogo.png";
import upload from "../../assets/images/upload.svg";
import { Link } from "react-router-dom";
import InputDashboard from "../common/InputDashboard/InputDashboard";
import InputDropdown from "../common/InputDashboard/InputDropdown";
import NotificationClose from "../../assets/images/notification-close.svg";
import { useLocation } from "react-router-dom";
import CustomMultiSelectDropdown from "../common/InputDashboard/CustomMultiSelect";
import DatePickerInput from "../common/InputDashboard/DatePickerInput";
import MultipleSelectChip from "../common/MultiSelectDropdown/MultiSelectDropdown";

function EditSchoolDetailsPage() {
  const [schoolName, setSchoolName] = useState("");
  const [established, setEstablished] = useState("");
  const [amenities, setAmenities] = useState([]);

  const handleDeleteAmenities = (element) => {
    const filteredAmenities = amenities.filter((el) => el !== element);
    setAmenities(filteredAmenities);
  };

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.back}>
          {" "}
          <Link
            to="/school-info"
            style={{ textDecoration: "none", color: "#9d9d9d" }}
          >
            School information{" "}
          </Link>{" "}
        </div>
        <ArrowRight />{" "}
        <div className={classes.header_text}>Edit School Information</div>
      </div>
      <div className={classes.container}>
        <div className={classes.logo_text}>School Logo</div>
        <div className={classes.upload_logo}>
          <img alt="" src={schoollogo}></img>
          <div className={classes.upload}>
            <img alt="" src={upload}></img>
            <div className={classes.upload_text}>Upload School Logo</div>
          </div>
        </div>
        <div className={classes.input_school_layout}>
          <InputDashboard
            placeholder={"School name"}
            width={"47.7vw"}
            className={classes.input_layout_schoolname}
            change={(e) => {
              
              setSchoolName(e.target.value);
            }}
            bgColor="#ffffff"
            value={schoolName}
          />
          <InputDropdown
            placeholder={"Board/Affiliation"}
            width={"23vw"}
            className={classes.input_layout_boardname}
          />
        </div>
        <div className={classes.input_layout}>
          <InputDropdown
            placeholder={"Organization"}
            width={"23vw"}
            bgColor="#fff"
            margin={2.4}
          />
          <InputDashboard
            placeholder={"Annual orientation month"}
            width={"23vw"}
            change={(e) => {
              
              setSchoolName(e.target.value);
            }}
            bgColor="#ffffff"
            value={schoolName}
            margin={2.4}
          />
          {/* <InputDashboard
            placeholder={"Established from"}
            width={"23vw"}
            change={(e) => {
              
              setSchoolName(e.target.value);
            }}
            bgColor="#ffffff"
            value={schoolName}
          /> */}
          <DatePickerInput
            onChange={(e) => {
              setEstablished(e.target.value);
            }}
            width={"23vw"}
            placeholder={"Established from"}
            value={established}
          />
        </div>
        <div className={classes.contact_info}>Contact information</div>
        <div className={classes.input_layout}>
          <InputDashboard
            placeholder={"Email id"}
            width={"23vw"}
            change={(e) => {
              
              setSchoolName(e.target.value);
            }}
            bgColor="#ffffff"
            value={schoolName}
            margin={2.4}
          />
          <InputDashboard
            placeholder={"Phone number"}
            width={"23vw"}
            change={(e) => {
              
              setSchoolName(e.target.value);
            }}
            bgColor="#ffffff"
            value={schoolName}
            margin={2.4}
          />
        </div>
        <InputDashboard
          placeholder={"School Address"}
          width={"47.7vw"}
          change={(e) => {
            
            setSchoolName(e.target.value);
          }}
          bgColor="#ffffff"
          value={schoolName}
          margin={2.4}
        />
        <div className={classes.input_layout}>
          <InputDashboard
            placeholder={"Pincode"}
            width={"23vw"}
            change={(e) => {
              
              setSchoolName(e.target.value);
            }}
            bgColor="#ffffff"
            value={schoolName}
            margin={2.4}
          />
          <InputDropdown
            placeholder={"City"}
            width={"23vw"}
            bgColor="#ffffff"
            margin={2.4}
          />
          <InputDropdown
            placeholder={"State"}
            width={"23vw"}
            bgColor="#ffffff"
            margin={2.4}
          />
        </div>
        <div className={classes.contact_info}>Staff & Ameneties</div>
        <div className={classes.input_layout}>
          <InputDashboard
            placeholder={"Teachers"}
            width={"23vw"}
            change={(e) => {
              
              setSchoolName(e.target.value);
            }}
            bgColor="#ffffff"
            value={schoolName}
            margin={2.4}
          />
          <InputDashboard
            placeholder={"Students"}
            width={"23vw"}
            change={(e) => {
              
              setSchoolName(e.target.value);
            }}
            bgColor="#ffffff"
            value={schoolName}
            margin={2.4}
          />
          <InputDropdown
            placeholder={"Infirmary"}
            width={"23vw"}
            bgColor="white"
            margin={2.4}
          />
          <MultipleSelectChip
            entity={"School"}
            amenities={amenities}
            setAmenities={setAmenities}
            options={[
              "Canteen",
              "Hostel",
              "Transportation",
              "Library",
              "Internet Access",
            ]}
            width={"23vw"}
            margin={2.4}
          />
        </div>
        <div className={classes.buttons}>
          <button className={classes.button_with_border}>Cancel</button>
          <button className={classes.button_filled}>Save Changes</button>
        </div>
      </div>
    </div>
  );
}

function EditSchoolDetails() {
  return <EditSchoolDetailsPage />;
}

export default EditSchoolDetails;
