import { API, graphqlOperation } from "aws-amplify";
import Cookies from "js-cookie";

function getEvents() {
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `query MyQuery {
            getEvents {
              events {
                fromDate
                toDate
                isActive
                location
                strength
                status
                schoolId
                eventId
              }
            }
          }`,
    authToken: token
  })
}

function createEvent(payload) {
  const token = Cookies.get('tokenId')

  const params = payload.payload
  return API.graphql({
    query: `mutation MyMutation {
            createEvent(fromDate: "${params.fromDate}", schoolId: "${params?.schoolId}", status: ${params?.status}, toDate: "${params?.toDate}", package: ${params?.package}) {
              toDate
              strength
              status
              location
              fromDate
              eventId
              schoolId
            }
          }`,
    authToken: token
  })
}

function getEventsBySchoolId(payload) {
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `query MyQuery {
            getEventBySchool(schoolId: "${payload.payload}") {
              eventMaps
              eventId
              fromDate
              location
              schoolId
              status
              strength
              toDate
            }
          }`,
    authToken: token
  })
}

function createEventMap(payload) {
  const token = Cookies.get('tokenId')
  const params = payload.payload

  const { classes, sections } = params;
  return API.graphql(graphqlOperation(
    `mutation MyMutation($classes: [String]!, $sections: [String]!,$stationId : String! ){
        createEventMap(
          classVal: $classes, 
          eventDate: "${params.eventDate}", 
          eventId: "${params.eventId}",  
          section: $sections,
          stationId  : $stationId
          )
      }`,
    { classes: params?.classes, sections: params?.sections, stationId: params?.stationId }, token
  ))
}

function getClasses(payload) {
  const token = Cookies.get("tokenId")
  return API.graphql({
    query: `query MyQuery {
      getClassBySchool(schoolId: "${payload.payload}") {
        A_1 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        A_10 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        A_2 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        A_3 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        A_4 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        A_5 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        A_6 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        A_7 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        A_8 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        A_9 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        B_1 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        B_10 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        B_2 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        B_3 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        B_4 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        B_5 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        B_6 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        B_7 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        B_8 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        B_9 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        C_1 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        C_10 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        C_2 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        C_3 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        C_4 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        C_5 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        C_6 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        C_7 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        C_8 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        C_9 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        D_1 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        D_10 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        D_2 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        D_3 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        D_4 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        D_5 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        D_6 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        D_7 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        D_8 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        D_9 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        E_1 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        E_10 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        E_2 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        E_3 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        E_4 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        E_5 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        E_6 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        E_7 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        E_8 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        E_9 {
          class
          schoolId
          screening
          section
          studentId
          admissionNumber
          gender
          lastName
          firstName
          DOB
        }
        A_11 {
          studentId
          section
          schoolId
          screening
          firstName
          class
          admissionNumber
          DOB
          gender
          lastName
        }
        A_12 {
          studentId
          section
          schoolId
          screening
          firstName
          class
          admissionNumber
          DOB
          gender
          lastName
        }
        B_11 {
          studentId
          section
          schoolId
          screening
          firstName
          class
          admissionNumber
          DOB
          gender
          lastName
        }
        B_12 {
          studentId
          section
          schoolId
          screening
          firstName
          class
          admissionNumber
          DOB
          gender
          lastName
        }
        C_12 {
          studentId
          section
          schoolId
          screening
          firstName
          class
          admissionNumber
          DOB
          gender
          lastName
        }
        C_11 {
          studentId
          section
          schoolId
          screening
          firstName
          class
          admissionNumber
          DOB
          gender
          lastName
        }
        D_11 {
          studentId
          section
          schoolId
          screening
          firstName
          class
          admissionNumber
          DOB
          gender
          lastName
        }
        D_12 {
          studentId
          section
          schoolId
          screening
          firstName
          class
          admissionNumber
          DOB
          gender
          lastName
        }
        E_11 {
          studentId
          section
          schoolId
          screening
          firstName
          class
          admissionNumber
          DOB
          gender
          lastName
        }
        E_12 {
          studentId
          section
          schoolId
          screening
          firstName
          class
          admissionNumber
          DOB
          gender
          lastName
        }    
      }
    }
    `,
    authToken: token
  })
}

function getEventMaps(payload) {
  const token = Cookies.get('tokenId')
  const params = payload.payload

  return API.graphql({
    query: `query MyQuery {
        getEventMapBySchoolId(schoolId: "${params}") {
          assessmentsIncludes
          screeningsStatus
          class
          completed
          stationId
          completedAt
          createdAt
          date
          eventId
          package
          schoolId
          section
          updatedAt
          mapId
        }
      }`,
    authToken: token
  })
}


function getStation(callBack) {
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `query MyQuery {
      getStations {
        location
        operatorId
        state
        stationId
      }
    }`,
    authToken: token
  }).then((data) => {
    callBack('success', data?.data?.getStations);
  }).catch((error) => {
    callBack('error', error);
  })
}

function completeEventMap(params, callBack) {
  const token = Cookies.get('tokenId')
  console.log(params);
  return API.graphql(graphqlOperation(`mutation MyMutation {
      completeEventMap(eventId: "${params.eventId}", mapId: "${params.mapId}") {
        assessmentsIncludes
        class
        completed
        completedAt
        createdAt
        date
        eventId
        mapId
        schoolId
        screeningsStatus
        section
        stationId
        updatedAt
      }
    }`,
    {}, token
  )).then((data) => {
    callBack('success', data);
  }).catch((error) => {
    callBack('error', error);
  })
}

const events = {
  getEvents,
  getEventsBySchoolId,
  createEvent,
  createEventMap,
  getClasses,
  getEventMaps,
  getStation,
  completeEventMap
}

export default events