import React, { useState, useEffect, useRef } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import classes from "./DoctorValidation.module.css";
import { Link, useLocation } from "react-router-dom";
import { Avatar, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import ear from '../../assets/images/ear.png'
import ent from '../../assets/images/ent.svg'
import heartmonitor from '../../assets/images/heartmonitor.svg'
import pulmonology from '../../assets/images/pulmonology.svg'
import stethoscopegreen from '../../assets/images/stethoscopegreen.png'
import visilbilty from '../../assets/images/visibility.svg'
import stethoscopeCheck from '../../assets/images/stethoscopeCheck.svg'
import wind from '../../assets/images/wind.png'
import checkcircle from '../../assets/images/check-circle.svg'
import gastroenterology from '../../assets/images/gastroenterology.svg'
import fingerprint from '../../assets/images/fingerprint.svg'
import VideoSlideModal from '../common/StudentName/VideoSlideshow/VideoSlideModal'
import ImageSlideModal from '../common/StudentName/ImageSlideshow/ImageSlideModal'
import screeningReducer from "../../redux/reducers/screening.reducer";
import { useDispatch, useSelector } from 'react-redux'
import Audioplayer from '../Audioplayer/Audioplayer'
import BreadCrumbs from "../common/TableComponents/BreadCrumbs"
import { Awsdateformatter, bmiValidatedInterpretation, calcBmi, calculateAge, checkDentalCondition, getDMFTIndex, numberTh, stringAvatar, toDateString } from "../../utils/utility";
import DentalJaw from "../Dentaljaw/Dentaljaw";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import SkidsGPT from "../SKIDSGPT/SkidsGPT";
import BoySvg from "../../assets/images/Kids/BoySvg";
import GirlSvg from "../../assets/images/Kids/GirlSvg";
import ValidationButtons from "../common/Buttons/ValidationButttons/ValidationButtons";
import SendIcon from '@mui/icons-material/Send';
import validationReducer from "../../redux/reducers/validation.reducer";
import studentReducer from "../../redux/reducers/student.reducer";
import DocInterpretation from "./DocIntertation/DocInterpretation";
import Button from '@mui/material/Button';
import { ConsoleLogger } from "@aws-amplify/core";
import BmiScale from "../../assets/images/BmiScale/BmiScale";
import Height from "../../assets/images/height.svg";
import WeightScale from "../../assets/images/weight-scale.svg";
import Checkanimated from "../../assets/images/AnimatedIcon/Checkmark/Checkmark";
import Lungspoints from "../../assets/Bodypoints/Lungs/LungsPoints";
import Heartpoint from "../../assets/Bodypoints/Heart/Heartpoints";
import Hair from "../../assets/Bodypoints/Hair/Hair";
import Eye from "../../assets/Bodypoints/Eye/Eye";
import Ear from "../../assets/Bodypoints/Ear/Ear";
import Dental from "../../assets/Bodypoints/Dental/Dental";
import Cough from "../../assets/Bodypoints/Cough/Cough";
import Throat from "../../assets/Bodypoints/Throat/Throat";
import Lungs from "../../assets/Bodypoints/Lungs/Lungs";
import Heart from "../../assets/Bodypoints/Heart/Heart";
import Stomach from "../../assets/Bodypoints/Stomach/Stomach";
import Skin from "../../assets/Bodypoints/Skin/Skin";
import screening from "../../graphqlApiServices/screening";
import userReducer from "../../redux/reducers/user.reducer";
import validation from "../../graphqlApiServices/validation";
import VitalsGaugeChart from "../common/StudentName/GaugeChart/VitalsGaugeChart";


function DoctorValidation() {
    const [number, setNumber] = useState(0);
    const { getReExam } = screening;
    const { createReExam, completeReExam } = validation;
    const [assignTo, setAssignTo] = useState();
    const tempOrgan = useRef({});
    const location = useLocation();
    // const localReTakeValues = validationReducer.actions.addRetakeParams;
    const getInternalUsers = userReducer.actions.getUserListforSuperadminRequest;
    const getStudentById = studentReducer.actions.getStudentByIDRequest;
    const updateStudent = studentReducer.actions.updateStudentStatusRequest;
    const currentUser = useSelector((state) => state.userReducer?.currentUser);
    const getDoctorScreening = validationReducer.actions.getDoctorInterpretation;
    const updateDoctorInterpretation = validationReducer.actions.updateDoctorInterpretation;
    const updateValidationStatus = validationReducer.actions.updateValidationReq;
    const studentScreening = screeningReducer.actions.getScreeningByStudentIdRequest;
    const validationData = useSelector((state) => state.validationReducer.data);
    const reTakeParams = useSelector((state) => state.validationReducer.reTake);
    const validationDone = useSelector((state) => state.validationReducer.validationDone);
    const loading = useSelector((state) => state.screeningReducer.loading);
    const screenData = useSelector((state) => state.screeningReducer.data);
    const studentData = useSelector((state) => state.studentReducer?.data);
    const dataOps = useSelector((state) => state.userReducer.users);


    // const [assignTo, setAssignTo] = useState([]);
    const [activeScreeningView, setActiveScreeningView] = useState('physical');
    const dispatch = useDispatch();
    const [displayData, setDisplayData] = useState({});
    const [localDone, setLocalDone] = useState(false);
    const [showIssue, setShowIssue] = useState({
        show: false,
        type: ""
    });
    const [showReason, setShowReason] = useState({
        show: false,
        type: ""
    });


    const [organ, setOrgan] = useState('Vital');
    const [behaviour, setBehaviour] = useState('adhd');
    const [learningDis, setLearningDis] = useState('dyslexia');
    const [response, setResponse] = useState('');
    const [VitalInterpretation, setVitalInterpretation] = useState({
        temperature: "",
        heartRate: "",
        hemoglobin: "",
        bmi: "",
        spo2: "",
        bloodPressure: ""
    });
    const [eyeIssues, setEyeIssues] = useState(["Myopia", "Hyperopia", "Astigmatism", "Aniscoria", "Anisometropia", "Gaze Asymmetry"]);
    const [earIssues, setEarIssues] = useState(["Otisis Media", "Otisis Externa", "Ear Wax", "Foreign Body in Ear canal", "Hearing"]);
    const [hearingIssues, setHearingIssues] = useState(["Mild hearing loss", "Moderate hearing loss", "Severe hearing loss"]);
    const [coughIssues, setCoughIssues] = useState(["Present"]);
    const [throatIssues, setThroatIssues] = useState(["Stomatitis", "Glossitis", "Pharyngitis", "Tonsilitis", "White Patches", "Throat Congestion", "Post nasal drip", "Enlarge tonsils", "Coated tongue"]);
    const [heartAuscultationIssues, setHeartAuscultationIssues] = useState(["Abnormal Heart Sound", "Arrhythmia", "Heart Murmur", "Rhythm Disorder"]);
    const [lungsAuscultationIssues, setLungsAuscultationIssues] = useState(["Respiratory Sounds", "Dyspnea", "Wheezing", "Rattling", "Cough"]);
    const [abdomenIssues, setAbdomenIssues] = useState(["Bowel Obstruction", "Abdominal Bruit", "Intestinal Sounds", "Paralytic Ileus"]);
    const [skinIssues, setSkinIssues] = useState(["Scabies", "Rashes", "Blisters", "Urticaria"]);

    const retakeVideo = [
        "Video too short",
        "Video too dark",
        "Video not clear"
    ];
    const retakeAudio = [
        "Auscultation too short",
        "Too much background noise",
        "No audible sound recorded"
    ]

    let docIssues = [];
    let estimationTitle;
    let componentEstimation;
    let behaviourComponent;
    let learningDisComponent;
    let { vitalSignsAssessment,
        skinAssessment,
        hairAssessment,
        eyeAssessment,
        earAssessment,
        hearingAssessment,
        dentalAssessment,
        coughAssessment,
        throatAssessment,
        heartAssessment,
        lungsAssessment,
        abdomenAssessment,
    } = validationData;


    useEffect(() => {
        if (screenData?.vitalSignsAssessment) {
            setVitalInterpretation({
                temperature: "",
                heartRate: "",
                hemoglobin: "",
                bmi: "",
                spo2: "",
                bloodPressure: ""
            })
            if (screenData?.vitalSignsAssessment?.temperature > 38) {
                setVitalInterpretation((prev) => {
                    return {
                        ...prev,
                        temperature: "Fever is present. Advised consultation with Pediatrician"
                    }
                })
            }
            if (screenData?.vitalSignsAssessment?.heartRate) {
                switch (true) {
                    case (screenData?.student?.age >= 1 && screenData?.student?.age <= 3):
                        if (screenData?.vitalSignsAssessment?.heartRate < 90 || screenData?.vitalSignsAssessment?.heartRate > 150) {
                            setVitalInterpretation((prev) => {
                                return {
                                    ...prev,
                                    heartRate: "Heart rate found to be more/less than normal range. Suggested Pediatric consultation."
                                }
                            })
                        }
                        break;

                    case (screenData?.student?.age > 3 && screenData?.student?.age <= 5):
                        if (screenData?.vitalSignsAssessment?.heartRate < 80 || screenData?.vitalSignsAssessment?.heartRate > 140) {
                            setVitalInterpretation((prev) => {
                                return {
                                    ...prev,
                                    heartRate: "Heart rate found to be more/less than normal range. Suggested Pediatric consultation."
                                }
                            })
                        }
                        break;

                    case (screenData?.student?.age > 5 && screenData?.student?.age <= 12):
                        if (screenData?.vitalSignsAssessment?.heartRate < 70 || screenData?.vitalSignsAssessment?.heartRate > 120) {
                            setVitalInterpretation((prev) => {
                                return {
                                    ...prev,
                                    heartRate: "Heart rate found to be more/less than normal range. Suggested Pediatric consultation."
                                }
                            })
                        }
                        break;


                    case (screenData?.student?.age > 12):
                        if (screenData?.vitalSignsAssessment?.heartRate < 60 || screenData?.vitalSignsAssessment?.heartRate > 100) {
                            setVitalInterpretation((prev) => {
                                return {
                                    ...prev,
                                    heartRate: "Heart rate found to be more/less than normal range. Suggested Pediatric consultation."
                                }
                            })
                        }
                        break;
                }
            }
            if (screenData?.vitalSignsAssessment?.hemoglobin) {
                const age = screenData?.student?.age;
                // switch (screenData?.student?.gender) {
                //     case "Male":
                //         if (screenData?.vitalSignsAssessment?.hemoglobin < 12.7 || screenData?.vitalSignsAssessment?.hemoglobin > 17.1) {
                //             setVitalInterpretation((prev) => {
                //                 return {
                //                     ...prev,
                //                     hemoglobin: "Hemoglobin is out of range, Advised consultation with Pediatrician"
                //                 }
                //             })
                //         }
                //         break;
                //     case "Female":
                //         if (screenData?.vitalSignsAssessment?.hemoglobin < 12 || screenData?.vitalSignsAssessment?.hemoglobin > 16) {
                //             setVitalInterpretation((prev) => {
                //                 return {
                //                     ...prev,
                //                     hemoglobin: "Hemoglobin is out of range, Advised consultation with Pediatrician"
                //                 }
                //             })
                //         }
                //         break;

                //     default:
                //         break;
                // }
                if (age >= 2 && age < 5) {
                    if (screenData?.vitalSignsAssessment?.hemoglobin < 11 || screenData?.vitalSignsAssessment?.hemoglobin > 14) {
                        setVitalInterpretation((prev) => {
                            return {
                                ...prev,
                                hemoglobin: "Hemoglobin is out of range, Advised consultation with Pediatrician"
                            }
                        })
                    }
                } else if (age >= 5 && age < 12) {
                    if (screenData?.vitalSignsAssessment?.hemoglobin < 11.5 || screenData?.vitalSignsAssessment?.hemoglobin > 14) {
                        setVitalInterpretation((prev) => {
                            return {
                                ...prev,
                                hemoglobin: "Hemoglobin is out of range, Advised consultation with Pediatrician"
                            }
                        })
                    }
                } else if (age >= 12 && age < 15) {
                    if (screenData?.vitalSignsAssessment?.hemoglobin < 12 || screenData?.vitalSignsAssessment?.hemoglobin > 16) {
                        setVitalInterpretation((prev) => {
                            return {
                                ...prev,
                                hemoglobin: "Hemoglobin is out of range, Advised consultation with Pediatrician"
                            }
                        })
                    }
                } else if (age >= 15) {
                    if (screenData?.vitalSignsAssessment?.hemoglobin < 13 || screenData?.vitalSignsAssessment?.hemoglobin > 16) {
                        setVitalInterpretation((prev) => {
                            return {
                                ...prev,
                                hemoglobin: "Hemoglobin is out of range, Advised consultation with Pediatrician"
                            }
                        })
                    }
                }
            }
            if (screenData?.vitalSignsAssessment?.SpO2 < 95) {
                setVitalInterpretation((prev) => {
                    return {
                        ...prev,
                        spo2: "Oxygen saturation is below the normal level. Immediate pediatric consultation is advised."
                    }
                })
            }
            if (screenData?.vitalSignsAssessment?.height && screenData?.vitalSignsAssessment?.weight) {
                let bmi = calcBmi(screenData?.vitalSignsAssessment?.height, screenData?.vitalSignsAssessment?.weight);

                setVitalInterpretation((prev) => {
                    return {
                        ...prev,
                        bmi: bmiValidatedInterpretation(bmi, screenData?.student?.age, screenData?.student?.gender)
                    }
                })

                // if (bmi < 18.5) {
                //     setVitalInterpretation((prev) => {
                //         return {
                //             ...prev,
                //             bmi: "BMI appears lower than recommended range. Please consult your paediatrician and nutritionist."
                //         }
                //     })
                // }
                // if (bmi >= 25) {
                //     setVitalInterpretation((prev) => {
                //         return {
                //             ...prev,
                //             bmi: "BMI appears higher than recommended range. Please consult your paediatrician and nutritionist."
                //         }
                //     })
                // }
            }
            // if (screenData?.student?.gender === "Male") {
            //     if (screenData?.student?.age === 12 && (screenData?.vitalSignsAssessment?.bloodPressure?.split('/')[0] > 113 || screenData?.vitalSignsAssessment?.bloodPressure?.split('/')[1] < 75)) {
            //         setVitalInterpretation((prev) => {
            //             return {
            //                 ...prev,
            //                 bloodPressure: "Blood pressure is out of range. Advised consultation with Pediatrician"
            //             }
            //         })
            //     }
            //     else if (screenData?.student?.age >= 13 && (screenData?.vitalSignsAssessment?.bloodPressure?.split('/')[0] > 120 || screenData?.vitalSignsAssessment?.bloodPressure?.split('/')[1] < 80)) {
            //         setVitalInterpretation((prev) => {
            //             return {
            //                 ...prev,
            //                 bloodPressure: "Blood pressure is out of range. Advised consultation with Pediatrician"
            //             }
            //         })
            //     }
            // }
            // else if (screenData?.student?.gender === "Female") {
            //     if (screenData?.student?.age === 12 && (screenData?.vitalSignsAssessment?.bloodPressure?.split('/')[0] > 114 || screenData?.vitalSignsAssessment?.bloodPressure?.split('/')[1] < 75)) {
            //         setVitalInterpretation((prev) => {
            //             return {
            //                 ...prev,
            //                 bloodPressure: "Blood pressure is out of range. Advised consultation with Pediatrician"
            //             }
            //         })
            //     }
            //     else if (screenData?.student?.age >= 13 && (screenData?.vitalSignsAssessment?.bloodPressure?.split('/')[0] > 120 || screenData?.vitalSignsAssessment?.bloodPressure?.split('/')[1] < 80)) {
            //         setVitalInterpretation((prev) => {
            //             return {
            //                 ...prev,
            //                 bloodPressure: "Blood pressure is out of range. Advised consultation with Pediatrician"
            //             }
            //         })
            //     }
            // }
        }

        // if (screenData?.screeningPackageDetails) {
        // setEyeIssues(["Myopia", "Hyperopia", "Astigmatism", "Aniscoria", "Anisometropia", "Gaze Asymmetry"])
        // setEarIssues([])
        // setThroatIssues([])
        // setHeartAuscultationIssues([])
        // setLungsAuscultationIssues([])
        // setAbdomenIssues([])
        // setSkinIssues([])
        // screenData?.screeningPackageDetails?.eye.map((e) => {
        //     if (e === "MYOPIA") {
        //         setEyeIssues((prev) => {
        //             return [...prev, "Myopia"];
        //         })
        //     }
        //     if (e === "HYPEROPIA") {
        //         setEyeIssues((prev) => {
        //             return [...prev, "Hyperopia"];
        //         })
        //     }
        //     if (e === "ASTIGMATISM") {
        //         setEyeIssues((prev) => {
        //             return [...prev, "Astigmatism"];
        //         })
        //     }
        //     if (e === "ANISOCORIA") {
        //         setEyeIssues((prev) => {
        //             return [...prev, "Aniscoria"];
        //         })
        //     }
        //     if (e === "ANISOMETROPIA") {
        //         setEyeIssues((prev) => {
        //             return [...prev, "Anisometropia"];
        //         })
        //     }
        //     if (e === "GAZE_ASYMMETRY") {
        //         setEyeIssues((prev) => {
        //             return [...prev, "Gaze Asymmetry"];
        //         })
        //     }
        // })
        // screenData?.screeningPackageDetails?.ear.map((e) => {
        //     if (e === "OTITIS_MEDIA") {
        //         setEarIssues((prev) => {
        //             return [...prev, "Otisis Media"];
        //         })
        //     }
        //     if (e === "OTITIS_EXTERNA") {
        //         setEarIssues((prev) => {
        //             return [...prev, "Otisis Externa"];
        //         })
        //     }
        //     if (e === "EAR_WAX") {
        //         setEarIssues((prev) => {
        //             return [...prev, "Ear Wax"];
        //         })
        //     }
        //     if (e === "FOREIGN_BODY_IN_CANAL") {
        //         setEarIssues((prev) => {
        //             return [...prev, "Foreign Body in Ear canal"];
        //         })
        //     }
        //     if (e === "HEARING") {
        //         setEarIssues((prev) => {
        //             return [...prev, "Hearing"];
        //         })
        //     }

        // })
        // // screenData?.screeningPackageDetails?.throat.map((e) => {
        //     if (e === "STOMATITIS") {
        //         setThroatIssues((prev) => {
        //             return [...prev, "Stomatitis"]
        //         })
        //     }
        //     if (e === "GLOSSITIS") {
        //         setThroatIssues((prev) => {
        //             return [...prev, "Glossitis"]
        //         })
        //     }
        //     if (e === "PHARYNGITIS") {
        //         setThroatIssues((prev) => {
        //             return [...prev, "Pharyngitis"]
        //         })
        //     }
        //     if (e === "TONSILLITIS") {
        //         setThroatIssues((prev) => {
        //             return [...prev, "Tonsilitis"]
        //         })
        //     }
        //     if (e === "WHITE_PATCHES") {
        //         setThroatIssues((prev) => {
        //             return [...prev, "White Patches"]
        //         })
        //     }
        //     if (e === "THROAT_CONGESTION") {
        //         setThroatIssues((prev) => {
        //             return [...prev, "Throat Congestion"]
        //         })
        //     }
        // })

        // screenData?.screeningPackageDetails?.heart.map((e) => {
        //     if (e === "ABNORMAL_HEART_SOUND") {
        //         setHeartAuscultationIssues((prev) => {
        //             return [...prev, "Abnormal Heart Sound"]
        //         })
        //     }
        //     if (e === "ARRHYTHMIA") {
        //         setHeartAuscultationIssues((prev) => {
        //             return [...prev, "Arrhythmia"]
        //         })
        //     }
        //     if (e === "HEART_MURMUR") {
        //         setHeartAuscultationIssues((prev) => {
        //             return [...prev, "Heart Murmur"]
        //         })
        //     }
        //     if (e === "RHYTHM_DISORDER") {
        //         setHeartAuscultationIssues((prev) => {
        //             return [...prev, "Rhythm Disorder"]
        //         })
        //     }
        // })

        // screenData?.screeningPackageDetails?.lungs.map((e) => {
        //     if (e === "RESPIRATORY_SOUNDS") {
        //         setLungsAuscultationIssues((prev) => {
        //             return [...prev, "Respiratory Sounds"]
        //         })
        //     }
        //     if (e === "DYSPNEA") {
        //         setLungsAuscultationIssues((prev) => {
        //             return [...prev, "Dyspnea"]
        //         })
        //     }
        //     if (e === "WHEEZING") {
        //         setLungsAuscultationIssues((prev) => {
        //             return [...prev, "Wheezing"]
        //         })
        //     }
        //     if (e === "RATTLING") {
        //         setLungsAuscultationIssues((prev) => {
        //             return [...prev, "Rattling"]
        //         })
        //     }
        //     if (e === "COUGH") {
        //         setLungsAuscultationIssues((prev) => {
        //             return [...prev, "Cough"]
        //         })
        //     }
        // })

        // screenData?.screeningPackageDetails?.abdomen.map((e) => {
        //     if (e === "BOWEL_OBSTRUCTION") {
        //         setAbdomenIssues((prev) => {
        //             return [...prev, "Bowel Obstruction"]
        //         })
        //     }
        //     if (e === "ABDOMINAL_BRUIT") {
        //         setAbdomenIssues((prev) => {
        //             return [...prev, "Abdominal Bruit"]
        //         })
        //     }
        //     if (e === "INTESTINAL_SOUNDS") {
        //         setAbdomenIssues((prev) => {
        //             return [...prev, "Intestinal Sounds"]
        //         })
        //     }
        //     if (e === "PARALYTIC_ILEUS") {
        //         setAbdomenIssues((prev) => {
        //             return [...prev, "Paralytic Ileus"]
        //         })
        //     }
        // })
        // screenData?.screeningPackageDetails?.skin.map((e) => {
        //     if (e === "SCABIES") {
        //         setSkinIssues((prev) => {
        //             return [...prev, "Scabies"]
        //         })
        //     }
        //     if (e === "RASHES") {
        //         setSkinIssues((prev) => {
        //             return [...prev, "Rashes"]
        //         })
        //     }
        //     if (e === "BLISTERS") {
        //         setSkinIssues((prev) => {
        //             return [...prev, "Blisters"]
        //         })
        //     }
        //     if (e === "URTICARIA") {
        //         setSkinIssues((prev) => {
        //             return [...prev, "Urticaria"]
        //         })
        //     }
        // })
        // }
        console.log(screenData);
        let params = {
            userId: validationData?.reExam,
            screeningId: validationData?.screeningId
        }
        getReExam(params, getReExamcallBack)

    }, [screenData])

    function getReExamcallBack(type, response) {
        if (type === "success") {
            console.log(response);
        } else if (type === "failure") {
            console.log(response);
        }
    }


    useEffect(() => {
        const params2 = {
            studentId: location?.state?.studentId
        }
        const params = {
            eventId: location?.state?.eventId,
            screeningId: location?.state?.screeningId
        }
        dispatch(studentScreening(location?.state?.studentId));
        dispatch(getStudentById(params2))
        dispatch(getDoctorScreening(params));
        dispatch(getInternalUsers('internal'));

    }, [])

    const checkDefects = (x) => {
        const q = screenData.defectImagesUrl.filter((y) => {
            let a = y.split("?")[0].split("/")[8].split(".")[0]
            if (a === x) {
                return true
            }
            else {
                return false
            }
        })
        if (q.length > 0) {
            return true
        }
        return false
    }

    const getDefectImage = (x) => {
        const q = screenData.defectImagesUrl.filter((y) => {
            let a = y.split("?")[0].split("/")[8].split(".")[0]
            if (a === x) {
                return true
            }
            else {
                return false
            }
        })
        console.log(q)
        return q
    }

    const getDefectComment = (x) => {
        const q = screenData?.screening?.defects?.filter((y) => y?.onType === x)
        return q?.[0]?.comment
    }

    // setInterval(() => {
    //     setNumber((prev) => (prev + 1));
    //     
    // }, 200)



    const requestReexam = () => {
        const params = {
            assignType: currentUser?.specialities,
            doctorId: currentUser?.userId,
            doctorName: currentUser?.given_name + currentUser?.family_name,
            organ: Object.values(tempOrgan.current),
            screeningId: location?.state?.screeningId,
            userId: assignTo,
            validationId: location?.state?.validationId,
        }
        // dispatch(localReTakeValues(tempOrgan.current));
        console.log(tempOrgan.current);
        if (Object.keys(tempOrgan.current).length) {
            createReExam(params, createReExamCallback)
        }
    }

    function createReExamCallback(type, response) {
        if (type === 'success') {
            console.log(response);
        } else {
            console.log(response);
        }
    }

    const handleReExamIssueCheck = (e, reason, organ) => {
        if (e.target.checked) {
            if (tempOrgan.current?.[organ]) {
                tempOrgan.current = {
                    ...tempOrgan.current,
                    [organ]: {
                        ...tempOrgan.current?.[organ],
                        reason: [...tempOrgan.current?.[organ]?.reason, reason]
                    }
                }
            } else {
                tempOrgan.current = {
                    ...tempOrgan.current,
                    [organ]: {
                        organName: organ,
                        reason: [reason]
                    }
                }
            }
        } else {
            if (tempOrgan.current?.[organ].reason.length === 1) {
                delete tempOrgan.current?.[organ];
            } else {
                let filteredReasons = tempOrgan.current?.[organ].reason
                    .filter((arReason) => arReason !== reason);

                tempOrgan.current = {
                    ...tempOrgan.current,
                    [organ]: {
                        ...tempOrgan.current?.[organ],
                        reason: filteredReasons
                    }
                }
            }
        }
    }

    const handleIssueChecks = (e, organ, issueType) => {
        // if (screenData?.status === "VALIDATION_DONE") {
        //     return
        // }
        if (organ === 'eyeValidation') {
            // docIssues = eyeAssessment?.validation?.[0]?.split(",") ?? [];
            switch (issueType) {
                case 'Myopia':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Myopia detected during screening. Ophthalmologist consultation is advised.'
                    }
                    break;
                case 'Hyperopia':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Hyperopia detected during screening. Ophthalmologist consultation is advised.'
                    }
                    break;
                case 'Astigmatism':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Astigmatism/Amblyopia detected during screening. Ophthalmologist consultation is advised.'
                    }
                    break;
                case 'Aniscoria':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Aniscoria detected during screening. Ophthalmologist consultation is advised'
                    }
                    break;
                case 'Anisometropia':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Anisometropia detected during screening. Ophthalmologist consultation is advised'
                    }
                    break;
                case 'Gaze Asymmetry':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Gaze Asymmetry detected during screening. Ophthalmologist consultation is advised.'
                    }
                    break;
                case 'other':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Enter your interpretation here'
                    }
                    break;
            }
            if (e.target.checked) {
                let validationObject = [
                    ...eyeAssessment?.validation,
                    docIssues
                ]
                updateInterpretation(organ, validationObject);
                docIssues = [];
            } else {
                let tempArray = eyeAssessment?.validation
                    .filter((valObj) => !(valObj.issue === issueType))
                docIssues = tempArray;
                updateInterpretation(organ, tempArray);
                docIssues = [];
            }

        } else if (organ === 'leftEarValidation') {
            switch (issueType) {
                case 'Otisis Media':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Features suggestive of Otitis media. Pediatrician consultation is advised.'
                    }
                    break;
                case 'Otisis Externa':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Features suggestive of Otitis externa. Pediatrician consultation is advised.'
                    }
                    break;
                case 'Ear Wax':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Wax is present in ear canal. Pediatrician consultation is advised.'
                    }
                    break;
                case 'Foreign Body in Ear canal':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Foreign body found in ear canal. Immediate Pediatrician consultation advised.'
                    }
                    break;
                case 'Hearing':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Hearing impairment is detected. Pediatrician consultation is advised.'
                    }
                    break;
                case 'other':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Enter your interpretation here'
                    }
                    break;
            }
            if (e.target.checked) {
                let validationObject = [
                    ...earAssessment?.leftEarValidation,
                    docIssues
                ]
                updateInterpretation(organ, validationObject);
                docIssues = [];
            } else {
                let tempArray = earAssessment?.leftEarValidation
                    .filter((valObj) => !(valObj.issue === issueType))
                updateInterpretation(organ, tempArray);
                docIssues = [];
            }
        } else if (organ === 'rightEarValidation') {
            switch (issueType) {
                case 'Otisis Media':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Features suggestive of Otitis media. Pediatrician consultation is advised.'
                    }
                    break;
                case 'Otisis Externa':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Features suggestive of Otitis externa. Pediatrician consultation is advised.'
                    }
                    break;
                case 'Ear Wax':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Wax is present in ear canal. Pediatrician consultation is advised.'
                    }
                    break;
                case 'Foreign Body in Ear canal':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Foreign body found in ear canal. Immediate Pediatrician consultation advised.'
                    }
                    break;
                case 'other':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Enter your interpretation here'
                    }
                    break;
            }
            if (e.target.checked) {
                let validationObject = [
                    ...earAssessment?.rightEarValidation,
                    docIssues
                ]
                updateInterpretation(organ, validationObject);
                docIssues = [];
            } else {
                let tempArray = earAssessment?.rightEarValidation
                    .filter((valObj) => !(valObj.issue === issueType))
                updateInterpretation(organ, tempArray);
                docIssues = [];
            }
            // docIssues = earAssessment?.rightEarValidation?.[0]?.split(",") ?? [];
        } else if (organ === 'hearingValidation') {
            switch (issueType) {
                case 'Mild hearing loss':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Hearing impairment is detected. ENT consultation is advised.'
                    }
                    break;
                case 'Moderate hearing loss':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Hearing impairment is detected. ENT consultation is advised.'
                    }
                    break;
                case 'Severe hearing loss':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Hearing impairment is detected. ENT consultation is advised.'
                    }
                    break;
            }
            if (e.target.checked) {
                let validationObject = [
                    ...hearingAssessment?.validation,
                    docIssues
                ]
                updateInterpretation(organ, validationObject);
                docIssues = [];
            } else {
                let tempArray = hearingAssessment?.validation
                    .filter((valObj) => !(valObj.issue === issueType))
                updateInterpretation(organ, tempArray);
                docIssues = [];
            }
            // docIssues = hearingAssessment?.validation?.[0]?.split(",") ?? [];
        } else if (organ === 'throatValidation') {
            switch (issueType) {
                case 'Stomatitis':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Features of stomatitis. ENT consultation is advised.'
                    }
                    break;
                case 'Glossitis':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Features of glossitis. ENT consultation is advised.'
                    }
                    break;
                case 'Pharyngitis':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Pharyngitis is present. ENT consultation is advised.'
                    }
                    break;
                case 'Tonsilitis':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Tonsillitis is detected. ENT consultation is advised.'
                    }
                    break;
                case 'White Patches':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'White Patches is detected. ENT consultation is advised.'
                    }
                    break;
                case 'Throat Congestion':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Throat Congestion is detected. ENT consultation is advised.'
                    }
                    break;
                case 'Post nasal drip':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Post Nasal Drip is present. ENT consultation is advised'
                    }
                    break;
                case 'Enlarge tonsils':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Enlarged Tonsils detected during screening. ENT consultation is advised'
                    }
                    break;
                case 'Coated tongue':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Coated tounge detected during screening. ENT consultation is advised'
                    }
                    break;
                // case 'Halitosis':
                //     docIssues = {
                //         issue: issueType,
                //         interpretation: 'Halotosis detected during screening. ENT consultation is advised'
                //     }
                //     break;
                case 'other':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Enter your interpretation here'
                    }
                    break;
            }
            if (e.target.checked) {
                let validationObject = [
                    ...throatAssessment?.validation,
                    docIssues
                ]
                updateInterpretation(organ, validationObject);
                docIssues = [];
            } else {
                let tempArray = throatAssessment?.validation
                    .filter((valObj) => !(valObj.issue === issueType))
                updateInterpretation(organ, tempArray);
                docIssues = [];
            }
            // docIssues = throatAssessment?.validation?.[0]?.split(",") ?? [];
        } else if (organ === 'coughValidation') {
            switch (issueType) {
                case 'Present':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Cough is present. Pediatrician consultation is advised. '
                    }
                    break;
                // case 'Absent':
                //     docIssues = {
                //         issue: issueType,
                //         interpretation: 'Child has no cough.'
                //     }
                //     break;
                case 'other':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Enter your interpretation here'
                    }
                    break;
            }
            if (e.target.checked) {
                let validationObject = [
                    ...coughAssessment?.validation,
                    docIssues
                ]
                updateInterpretation(organ, validationObject);
                docIssues = [];
            } else {
                let tempArray = coughAssessment?.validation
                    .filter((valObj) => !(valObj.issue === issueType))
                updateInterpretation(organ, tempArray);
                docIssues = [];
            }
            // docIssues = coughAssessment?.validation?.[0]?.split(",") ?? [];
        } else if (organ === "heartAuscultationValidation") {
            organ = "auscultationValidation";
            switch (issueType) {
                case 'Abnormal Heart Sound':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Abnormal heart sound is detected. Pediatric consultation is advised.'
                    }
                    break;
                case 'Arrhythmia':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Arrhythmia is detected. Pediatric consultation is advised.'
                    }
                    break;
                case 'Rhythm Disorder':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Abnormal Rhythm is detected. Pediatric consultation is advised.'
                    }
                case 'Heart Murmur':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Abnormal Heart Murmur is detected. Pediatric consultation is advised.'
                    }
                    break;
                case 'other':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Enter your interpretation here'
                    }
                    break;
            }
            if (e.target.checked) {
                let validationObject = [
                    ...heartAssessment?.auscultationValidation,
                    docIssues
                ]
                updateInterpretation(organ, validationObject);
                docIssues = [];
            } else {
                let tempArray = heartAssessment?.auscultationValidation
                    .filter((valObj) => !(valObj.issue === issueType))
                updateInterpretation(organ, tempArray);
                docIssues = [];
            }
            // docIssues = heartAssessment?.auscultationValidation?.[0]?.split(",") ?? [];
        } else if (organ === "lungsValidation") {
            switch (issueType) {
                case 'Respiratory Sounds':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Abnormal heart sound is detected. Pediatric consultation is advised.'
                    }
                    break;
                case 'Dyspnea':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Child is having dyspnea. Pediatric consultation advised.'
                    }
                    break;
                case 'Wheezing':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Abnormal heart sound is detected. Pediatric consultation is advised.'
                    }
                    break;
                case 'Cough':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Cough is present. Pediatrician consultation is advised.'
                    }
                    break;
                case 'Rattling':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Rattling is present. Consultation with Pediatrician suggested.'
                    }
                    break;
                case 'other':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Enter your interpretation here'
                    }
                    break;
            }
            if (e.target.checked) {
                let validationObject = [
                    ...lungsAssessment?.auscultationValidation,
                    docIssues
                ]
                updateInterpretation(organ, validationObject);
                docIssues = [];
            } else {
                let tempArray = lungsAssessment?.auscultationValidation
                    .filter((valObj) => !(valObj.issue === issueType))
                updateInterpretation(organ, tempArray);
                docIssues = [];
            }
            // docIssues = lungsAssessment?.auscultationValidation?.[0]?.split(",") ?? [];
        } else if (organ === "abdomenValidation") {
            switch (issueType) {
                case 'Bowel Obstruction':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Bowel Obstruction is detected. Pediatric consultation is advised.'
                    }
                    break;
                case 'Abdominal Bruit':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Child is having Abdominal Bruit. Pediatric consultation advised.'
                    }
                    break;
                case 'Intestinal Sounds':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Intestinal Sounds is detected. Pediatric consultation is advised.'
                    }
                    break;
                case 'Paralytic Ileus':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Paralytic Ileus is present. Consultation with Pediatrician suggested.'
                    }
                    break;
                case 'other':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Enter your interpretation here'
                    }
                    break;
            }
            if (e.target.checked) {
                let validationObject = [
                    ...abdomenAssessment?.validation,
                    docIssues
                ]
                updateInterpretation(organ, validationObject);
                docIssues = [];
            } else {
                let tempArray = abdomenAssessment?.validation
                    .filter((valObj) => !(valObj.issue === issueType))
                updateInterpretation(organ, tempArray);
                docIssues = [];
            }
            // docIssues = abdomenAssessment?.validation?.[0]?.split(",") ?? [];
        } else if (organ === "skinValidation") {
            switch (issueType) {
                case 'Scabies':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Scabies is detected. Pediatric consultation is advised.'
                    }
                    break;
                case 'Rashes':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Child is having Rashes. Pediatric consultation advised.'
                    }
                    break;
                case 'Blisters':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Blisters is detected. Pediatric consultation is advised.'
                    }
                    break;
                case 'Urticaria':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Urticaria is present. Consultation with Pediatrician suggested.'
                    }
                    break;
                case 'other':
                    docIssues = {
                        issue: issueType,
                        interpretation: 'Enter your interpretation here'
                    }
                    break;
            }
            if (e.target.checked) {
                let validationObject = [
                    ...skinAssessment?.validation,
                    docIssues
                ]
                updateInterpretation(organ, validationObject);
                docIssues = [];
            } else {
                let tempArray = skinAssessment?.validation
                    .filter((valObj) => !(valObj.issue === issueType))
                updateInterpretation(organ, tempArray);
                docIssues = [];
            }
            // docIssues = skinAssessment?.validation?.[0]?.split(",") ?? [];
        }
    }



    const updateInterpretation = (type, docInput, e) => {
        if (e) {
            e.preventDefault();
        }
        const params = {
            eventId: location?.state?.eventId,
            screeningId: location?.state?.screeningId,
            physicalScreeningDate: type === "PHYSICAL_DONE" ? docInput : "",
            physicalDone: type === "PHYSICAL_DONE" ? true : false,
            status: (type === "PHYSICAL_DONE" && validationData?.dentalScreeningDate) ? "VALIDATION_DONE" : validationData?.status,
            vitalSignsAssessment: {
                ...vitalSignsAssessment,
                height: vitalSignsAssessment?.height,
                weight: vitalSignsAssessment?.weight,
                temperature: vitalSignsAssessment?.temperature,
                temperatureInterpretation: type === "temperature" ? docInput : vitalSignsAssessment?.temperatureInterpretation ?? VitalInterpretation.temperature,
                hemoglobin: vitalSignsAssessment?.hemoglobin,
                hemoglobinInterpretation: type === "hemoglobin" ? docInput : vitalSignsAssessment?.hemoglobinInterpretation ?? VitalInterpretation.hemoglobin,
                heartRate: vitalSignsAssessment?.heartRate,
                heartRateInterpretation: type === "heartRate" ? docInput : vitalSignsAssessment?.heartRateInterpretation ?? VitalInterpretation.heartRate,
                bloodPressure: vitalSignsAssessment?.bloodPressure,
                bloodPressureInterpretation: type === "bloodPressure" ? docInput : vitalSignsAssessment?.bloodPressureInterpretation ?? VitalInterpretation.bloodPressure,
                SpO2: vitalSignsAssessment?.SpO2,
                SpO2Interpretation: type === "SpO2" ? docInput : vitalSignsAssessment?.SpO2Interpretation ?? VitalInterpretation.spo2,
                BMI: vitalSignsAssessment?.BMI,
                BMIInterpretation: type === "BMI" ? docInput : vitalSignsAssessment?.BMIInterpretation ?? VitalInterpretation.bmi,
            },
            skinAssessment: {
                ...skinAssessment,
                validation: type === "skinValidation" ? docInput : skinAssessment?.validation ?? [],
                validationInterpretation: type === "skin" ? docInput : skinAssessment?.validationInterpretation ?? ""
            },
            hairAssessment: {
                ...hairAssessment,
                color: hairAssessment?.color,
                colorInterpretation: type === "hairColor" ? docInput : hairAssessment?.colorInterpretation ?? "",
                dandruff: hairAssessment?.dandruff,
                dandruffInterpretation: type === "hairDandruff" ? docInput : hairAssessment?.dandruffInterpretation ?? "",
                lossOfHair: hairAssessment?.lossOfHair,
                lossOfHairInterpretation: type === "lossOfHair" ? docInput : hairAssessment?.lossOfHairInterpretation ?? "",
                pediculosis: hairAssessment?.pediculosis,
                pediculosisInterpretation: type === "pediculosis" ? docInput : hairAssessment?.pediculosisInterpretation ?? ""
            },
            eyeAssessment: {
                ...eyeAssessment,
                validation: type === "eyeValidation" ? docInput : eyeAssessment?.validation ?? [],
                validationInterpretation: type === "eye" ? docInput : eyeAssessment?.validationInterpretation ?? ""
            },
            earAssessment: {
                ...earAssessment,
                leftEarInterpretation: type === "leftEar" ? docInput : earAssessment?.leftEarInterpretation ?? "",
                leftEarValidation: type === "leftEarValidation" ? docInput : earAssessment?.leftEarValidation ?? [],
                rightEarInterpretation: type === "rightEar" ? docInput : earAssessment?.rightEarInterpretation ?? "",
                rightEarValidation: type === "rightEarValidation" ? docInput : earAssessment?.rightEarValidation ?? []
            },
            hearingAssessment: {
                ...hearingAssessment,
                validation: type === "hearingValidation" ? docInput : hearingAssessment?.validation ?? [],
                validationInterpretation: type === "hearing" ? docInput : hearingAssessment?.validationInterpretation ?? "",
            },
            dentalAssessment: {
                ...dentalAssessment,
                DMFTIndex: dentalAssessment?.DMFTIndex,
                DMFTIndexInterpretation: type === "DMFT" ? docInput : dentalAssessment?.DMFTIndexInterpretation ?? "",
                decoloration: dentalAssessment?.decoloration,
                decolorationInterpretation: type === "decoloration" ? docInput : dentalAssessment?.decolorationInterpretation ?? "",
                gingivitis: dentalAssessment?.gingivitis,
                gingivitisInterpretation: type === "gingivitis" ? docInput : dentalAssessment?.gingivitisInterpretation ?? "",
                orthodontic: dentalAssessment?.orthodontic,
                orthodonticInterpretation: type === "orthodontic" ? docInput : dentalAssessment?.orthodonticInterpretation ?? "",
                fillingPresent: dentalAssessment?.fillingPresent,
                missedTooth: dentalAssessment?.missedTooth,
                cariesDecay: dentalAssessment?.cariesDecay,
                poorOralHygiene: dentalAssessment?.poorOralHygiene,
                tooth: dentalAssessment?.tooth?.[0]?.split(",")
            },
            coughAssessment: {
                ...coughAssessment,
                validation: type === "coughValidation" ? docInput : coughAssessment?.validation ?? [],
                validationInterpretation: type === "cough" ? docInput : coughAssessment?.validationInterpretation ?? ""
            },
            throatAssessment: {
                ...throatAssessment,
                validation: type === "throatValidation" ? docInput : throatAssessment?.validation ?? [],
                validationInterpretation: type === "throat" ? docInput : throatAssessment?.validationInterpretation ?? "",
            },
            abdomenAssessment: {
                ...abdomenAssessment,
                validation: type === "abdomenValidation" ? docInput : abdomenAssessment?.validation ?? [],
                validationInterpretation: type === "abdomen" ? docInput : abdomenAssessment?.abdomenInterpretation ?? ""
            },
            lungsAssessment: {
                ...lungsAssessment,
                auscultationValidation: type === "lungsValidation" ? docInput : lungsAssessment?.auscultationValidation ?? [],
                auscultationInterpretation: type === "lungs" ? docInput : lungsAssessment?.auscultationInterpretation ?? ""
            },
            heartAssessment: {
                ...heartAssessment,
                estimationValidation: type === "estimationValidation" ? docInput : heartAssessment?.estimationValidation ?? [],
                estimationInterpretation: type === "heartEstimation" ? docInput : heartAssessment?.estimationInterpretation ?? "",
                auscultationValidation: type === "auscultationValidation" ? docInput : heartAssessment?.auscultationValidation ?? [],
                auscultationInterpretation: type === "heartAuscultation" ? docInput : heartAssessment?.auscultationInterpretation ?? "",
            }
        }
        console.log(params);
        dispatch(updateDoctorInterpretation(params));
    }

    const showIssueHandler = (type) => {
        setShowIssue((prev) => {
            return {
                show: !prev.show,
                type: type
            }
        })
    }


    const showRetakeHandler = (type) => {
        setShowReason((prev) => {
            return {
                show: !prev.show,
                type: type
            }
        })
    }

    // 
    useEffect(() => {
        setShowIssue((prev) => {
            return {
                show: false,
                type: ""
            }
        })
    }, [organ])


    const handleValidationComplete = () => {
        const now = new Date();
        const awsDate = Awsdateformatter(now);
        const params = {
            awsDate: awsDate,
            userId: currentUser?.userId,
            validationId: location?.state?.validationId
        }
        // const parasm2 = {
        //     screeningId: location?.state?.screeningId,
        //     userId: currentUser?.userId
        // };
        dispatch(updateValidationStatus(params));
        if (validationData?.dentalScreeningDate) {
            dispatch(updateStudent({ studentId: studentData[0]?.studentId, schoolId: studentData[0]?.schoolId, status: "VALIDATION_DONE" }))
        }
        updateInterpretation("PHYSICAL_DONE", awsDate);
        setLocalDone(true);
        requestReexam();

        // completeReExam(parasm2);
    }


    // ---------------------------------------------------------------------------
    //------------------- Dynamic content for physical screening------------------
    // ---------------------------------------------------------------------------
    switch (organ) {

        case 'Vital':
            estimationTitle = 'Hair Stuff'
            componentEstimation = <>
                {screenData?.screeningPackageDetails?.vitalSigns.includes('HEART_RATE')
                    &&
                    <>
                        <div className={classes.headingWithCheck}>
                            <div className={classes.headingText}>Heart rate</div>
                            <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                        </div>
                        <div className={classes.headingBold}>
                            {
                                (screenData?.vitalSignsAssessment?.heartRate === "null" || !screenData?.vitalSignsAssessment?.heartRate)
                                    ? <div>Not available</div>
                                    : <div>{screenData?.vitalSignsAssessment?.heartRate}{" "}BPM</div>
                            }
                        </div>
                        <DocInterpretation
                            interpretation={
                                (vitalSignsAssessment?.heartRateInterpretation === "" || vitalSignsAssessment?.heartRateInterpretation === null)
                                    ? VitalInterpretation?.heartRate
                                    : vitalSignsAssessment?.heartRateInterpretation
                            }
                            updateType={"heartRate"} updateInterpretation={updateInterpretation} />
                        <br />
                        <div className={classes.hrLine} />
                        <br />
                    </>
                }

                {screenData?.screeningPackageDetails?.vitalSigns.includes('TEMPERATURE')
                    &&
                    <>
                        <div className={classes.headingWithCheck}>
                            <div className={classes.headingText}>Temperature</div>
                            <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                        </div>
                        <div className={classes.headingBold}>
                            <div>{
                                (screenData?.vitalSignsAssessment?.temperature === "null" || !screenData?.vitalSignsAssessment?.temperature)
                                    ? "Not available"
                                    : screenData?.vitalSignsAssessment?.temperature + "°"
                            }</div>
                        </div>
                        <DocInterpretation
                            interpretation={
                                (vitalSignsAssessment?.temperatureInterpretation === "" || vitalSignsAssessment?.temperatureInterpretation === null)
                                    ? VitalInterpretation?.temperature
                                    : vitalSignsAssessment?.temperatureInterpretation
                            }
                            updateType={"temperature"} updateInterpretation={updateInterpretation} />
                        <br />
                        <div className={classes.hrLine} />
                        <br />
                    </>
                }

                {screenData?.screeningPackageDetails?.vitalSigns.includes('HEMOGLOBIN')
                    &&
                    <>
                        <div className={classes.headingWithCheck}>
                            <div className={classes.headingText}>Hemoglobin</div>
                            <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                        </div>
                        <div className={classes.headingBold}>
                            <div>{
                                (screenData?.vitalSignsAssessment?.hemoglobin === "null" || !screenData?.vitalSignsAssessment?.hemoglobin)
                                    ? "Not available"
                                    : screenData?.vitalSignsAssessment?.hemoglobin + " " + "g/dl"
                            }</div>
                        </div>
                        <DocInterpretation
                            interpretation={
                                (vitalSignsAssessment?.hemoglobinInterpretation === "" || vitalSignsAssessment?.hemoglobinInterpretation === null)
                                    ? VitalInterpretation?.hemoglobin
                                    : vitalSignsAssessment?.hemoglobinInterpretation
                            }
                            updateType={"hemoglobin"} updateInterpretation={updateInterpretation} />
                        <br />
                        <div className={classes.hrLine} />
                        <br />
                    </>
                }

                {screenData?.screeningPackageDetails?.vitalSigns.includes('SPO2')
                    &&
                    <>

                        <div className={classes.headingWithCheck}>
                            <div className={classes.headingText}>SpO2</div>
                            <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                        </div>
                        <div className={classes.headingBold}>
                            <div>{
                                (screenData?.vitalSignsAssessment?.SpO2 === "null" || !screenData?.vitalSignsAssessment?.SpO2)
                                    ? "Not available"
                                    : screenData?.vitalSignsAssessment?.SpO2 + " " + "%"
                            }</div>
                        </div>
                        <DocInterpretation
                            interpretation={
                                (vitalSignsAssessment?.SpO2Interpretation === "" || vitalSignsAssessment?.SpO2Interpretation === null)
                                    ? VitalInterpretation?.spo2
                                    : vitalSignsAssessment?.SpO2Interpretation
                            }
                            updateType={"SpO2"} updateInterpretation={updateInterpretation} />
                        <br />
                        <div className={classes.hrLine} />
                        <br />
                    </>
                }

                {screenData?.screeningPackageDetails?.vitalSigns.includes('HEIGHT_AND_WEIGHT')
                    &&
                    <>
                        <div className={classes.flexContainer}>
                            <div>
                                <div className={classes.headingWithCheck}>
                                    <div className={classes.headingText}>Height</div>
                                    <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                                </div>

                                {!checkDefects("heightWeight") ? <div className={classes.headingBold}>
                                    <div>{
                                        (screenData?.vitalSignsAssessment?.height === "null" || !screenData?.vitalSignsAssessment?.height)
                                            ? "Not available"
                                            : screenData?.vitalSignsAssessment?.height + " " + "cms"
                                    }</div>
                                </div> :
                                    <div>
                                        <p style={{ marginTop: 20 }}>Defect Found</p>
                                        <ImageSlideModal imgArray={getDefectImage("heightWeight")} />
                                        <div>{getDefectComment("heightWeight")}</div>
                                    </div>
                                }
                            </div>
                            <div>
                                <div className={classes.headingWithCheck}>
                                    <div className={classes.headingText}>Weight</div>
                                    <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                                </div>
                                <div className={classes.headingBold}>
                                    <div>{
                                        (screenData?.vitalSignsAssessment?.weight === "null" || !screenData?.vitalSignsAssessment?.weight)
                                            ? "Not available"
                                            : screenData?.vitalSignsAssessment?.weight + " " + "kgs"
                                    }</div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className={classes.hrLine} />
                        <br />
                        <div className={classes.headingWithCheck}>
                            <div className={classes.headingText}>BMI</div>
                            <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                        </div>
                        <div className={classes.headingBold}>
                            <div>{calcBmi(screenData?.vitalSignsAssessment?.height, screenData?.vitalSignsAssessment?.weight) + " " + "kg/m²"}</div>
                        </div>
                        <DocInterpretation
                            interpretation={
                                (vitalSignsAssessment?.BMIInterpretation === "" || vitalSignsAssessment?.BMIInterpretation === null)
                                    ? VitalInterpretation?.bmi
                                    : vitalSignsAssessment?.BMIInterpretation
                            } updateType={"BMI"} updateInterpretation={updateInterpretation} />
                        <br />
                        <div className={classes.hrLine} />
                        <br />
                        {screenData.student.age > 10 && screenData.screeningPackageDetails?.vitalSigns.includes('BLOOD_PRESSURE') && <>
                            <div className={classes.headingWithCheck}>
                                <div className={classes.headingText}>Blood Pressure</div>
                                <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                            </div>
                            <div className={classes.headingBold}>
                                <div>{screenData.vitalSignsAssessment.bloodPressure} mmHg</div>
                            </div>
                            <DocInterpretation
                                interpretation={
                                    (vitalSignsAssessment?.bloodPressureInterpretation === "" || vitalSignsAssessment?.bloodPressureInterpretation === null)
                                        ? VitalInterpretation?.bloodPressure
                                        : vitalSignsAssessment?.bloodPressureInterpretation
                                } updateType={"bloodPressure"} updateInterpretation={updateInterpretation} />
                        </>}
                    </>
                }
            </>
            break;
        case 'Hair':
            componentEstimation = <>
                {screenData?.screeningPackageDetails?.hair.includes('COLOR_OF_HAIR')
                    &&
                    <>
                        <div title={"Screened by - " + JSON.parse(validationData?.auditTrail)?.colorOfHair?.name} className={classes.headingWithCheck}>
                            <div className={classes.headingText}>Color of hair</div>
                            <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                        </div>
                        <div className={classes.headingBold}>
                            <div>{screenData?.hairAssessment?.color ?? "-"}</div>
                        </div>
                        <DocInterpretation interpretation={hairAssessment?.colorInterpretation} updateType={"hairColor"} updateInterpretation={updateInterpretation} />
                        <br />
                        <div className={classes.hrLine} />
                        <br />
                    </>
                }

                {screenData?.screeningPackageDetails?.hair.includes('DANDRUFF')
                    &&
                    <>
                        <div title={"Screened by - " + JSON.parse(validationData?.auditTrail)?.dandruff?.name} className={classes.headingWithCheck}>
                            <div className={classes.headingText}>Dandruff</div>
                            <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                        </div>
                        <div className={classes.headingBold}>
                            <div>{screenData?.hairAssessment?.dandruff ? "Present" : "Absent" ?? "-"}</div>
                        </div>
                        <DocInterpretation interpretation={hairAssessment?.dandruffInterpretation} updateType={"hairDandruff"} updateInterpretation={updateInterpretation} />
                        <br />
                        <div className={classes.hrLine} />
                        <br />
                    </>
                }

                {screenData?.screeningPackageDetails?.hair.includes('PEDICULOSIS_(LOUSE/LICE)')
                    &&
                    <>
                        <div title={"Screened by - " + JSON.parse(validationData?.auditTrail)?.pediculosis?.name} className={classes.headingWithCheck}>
                            <div className={classes.headingText}>Pediculosis (louse/lice)</div>
                            <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                        </div>
                        <div className={classes.headingBold}>
                            <div>{screenData?.hairAssessment?.pediculosis ? "Present" : "Absent" ?? "-"}</div>
                        </div>
                        <DocInterpretation interpretation={hairAssessment?.pediculosisInterpretation} updateType={"pediculosis"} updateInterpretation={updateInterpretation} />
                        <br />
                        <div className={classes.hrLine} />
                        <br />
                    </>
                }

                {
                    screenData?.screeningPackageDetails?.hair.includes('LOSS_OF_HAIR')
                    &&
                    <>
                        <div title={"Screened by - " + JSON.parse(validationData?.auditTrail)?.lossOfHair?.name} className={classes.headingWithCheck}>
                            <div className={classes.headingText}>Loss of hair</div>
                            <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                        </div>
                        <div className={classes.headingBold}>
                            <div>{screenData?.hairAssessment?.lossOfHair ? "Yes" : "No" ?? "-"}</div>
                        </div>
                        <DocInterpretation interpretation={hairAssessment?.lossOfHairInterpretation} updateType={"lossOfHair"} updateInterpretation={updateInterpretation} />
                    </>
                }
            </>
            break;

        case 'Eye':
            estimationTitle = 'Eye screening images'
            componentEstimation = <>
                <div title={"Screened by - " + JSON.parse(validationData?.auditTrail)?.normalVision?.name} className={classes.headingWithCheck}>
                    <div className={classes.headingText}>Eye</div>
                    <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                </div>
                {!checkDefects("eye") ? <div className={classes.slides_player_container}>
                    <div className={classes.slides_player_container}>
                        <div className={classes.slides_player_inner_container}>
                            <ImageSlideModal imgArray={[screenData?.welchAllyn?.visionScreeningImage]} />
                            <ImageSlideModal imgArray={[screenData?.welchAllyn?.potentialConditionImage]} />
                        </div>
                        <div className={classes.slides_player_inner_container} >
                            {screenData?.welchAllyn?.otherVisionDetails?.map((x) => {
                                return <ImageSlideModal imgArray={[x]} />
                            })}
                        </div>
                    </div>
                </div> :
                    <div>
                        <p style={{ marginTop: 20 }}>Defect Found</p>
                        <ImageSlideModal imgArray={getDefectImage("eye")} />
                        <div>{getDefectComment("eye")}</div>
                    </div>
                }
                <div className={classes.validationButtonContainer}>
                    <p>Validation</p>
                    <div>
                        <ValidationButtons type={"good"} />
                        <div className={classes.issueButtonCon} >
                            {showIssue.show && showIssue.type === 'eye' &&
                                <div className={classes.issueDropDown}>
                                    <FormGroup>
                                        {
                                            eyeIssues.map((issues, index) => {
                                                return <FormControlLabel
                                                    key={index}
                                                    control={<Checkbox
                                                        checked={(!!eyeAssessment?.validation?.find((obj) => obj.issue === issues))}
                                                        onChange={(e) => { handleIssueChecks(e, 'eyeValidation', issues) }}
                                                        size="small" color="info" sx={{ padding: '5px' }} />}
                                                    label={issues} />
                                            })
                                        }
                                        <FormControlLabel
                                            sx={{ whiteSpace: 'nowrap' }}
                                            control={<Checkbox
                                                checked={(!!eyeAssessment?.validation?.find((obj) => obj.issue === 'other'))}
                                                onChange={(e) => { handleIssueChecks(e, 'eyeValidation', 'other') }}
                                                size="small" color="info" sx={{ padding: '5px' }} />}
                                            label={"Other"} />
                                        {/* <FormControlLabel control={<Checkbox size="small" color="info" sx={{ padding: '5px' }} />}
                                            label={
                                                <TextField
                                                    id="standard-multiline-static"
                                                    placeholder="Add other"
                                                    variant="standard"

                                                />}
                                        /> */}
                                    </FormGroup>
                                    <br />
                                    <Button onClick={() => { setShowIssue(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                        Validate
                                    </Button>
                                </div>
                            }
                            <ValidationButtons type={"issue"} onClick={() => { showIssueHandler('eye') }} />
                        </div>
                    </div>
                </div>
                {
                    eyeAssessment?.validation?.map((issueObj) => {
                        return <DocInterpretation
                            interpretation={issueObj.interpretation}
                            issueType={issueObj.issue}
                            updateType={"eyeValidation"}
                            updateInterpretation={updateInterpretation}
                            objArray={eyeAssessment?.validation} />
                    })
                }
            </>
            break;

        case 'Ear':
            estimationTitle = 'Ear images'
            componentEstimation =
                <>
                    {screenData?.higo?.rightEar?.length > 0 || screenData?.ent?.entRightEarVideo ? <div>
                        <div title={"Screened by - " + JSON.parse(validationData?.auditTrail)?.rightEar?.name} className={classes.headingWithCheck}>
                            <div className={classes.headingText}>Right Ear</div>
                            <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                        </div>
                        <br />
                        {!checkDefects("rightEar") ? <div className={classes.slides_player_container}>
                            {screenData?.higo?.rightEar?.length > 0 && <VideoSlideModal videoUrl={screenData?.higo?.rightEarVideo} imgs={screenData?.higo?.rightEar} />}
                            {screenData?.ent?.entRightEarVideo && <VideoSlideModal videoUrl={screenData?.ent?.entRightEarVideo} />}
                            <div id="captured">

                            </div>
                        </div> :
                            <div>
                                <p style={{ marginTop: 20 }}>Defect Found</p>
                                <ImageSlideModal imgArray={getDefectImage("rightEar")} />
                                <div>{getDefectComment("rightEar")}</div>
                            </div>
                        }
                        <div className={classes.validationButtonContainer}>
                            <p>Validation</p>
                            <div>
                                <ValidationButtons type={"good"} />
                                <div className={classes.issueButtonCon} >
                                    {showIssue.show && showIssue.type === 'rightEar' &&
                                        <div className={classes.issueDropDown}>
                                            <FormGroup>
                                                {
                                                    earIssues.map((issues, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            sx={{ whiteSpace: 'nowrap' }}
                                                            control={<Checkbox
                                                                checked={!!(earAssessment?.rightEarValidation?.find((obj) => obj.issue === issues))}
                                                                onChange={(e) => { handleIssueChecks(e, 'rightEarValidation', issues) }}
                                                                size="small" color="info" sx={{ padding: '5px' }} />}
                                                            label={issues} />
                                                    })
                                                }
                                                <FormControlLabel
                                                    sx={{ whiteSpace: 'nowrap' }}
                                                    control={<Checkbox
                                                        checked={!!(earAssessment?.rightEarValidation?.find((obj) => obj.issue === "other"))}
                                                        onChange={(e) => { handleIssueChecks(e, 'rightEarValidation', 'other') }}
                                                        size="small" color="info" sx={{ padding: '5px' }} />}
                                                    label={"Other"} />
                                            </FormGroup>
                                            <br />
                                            <Button onClick={() => { setShowIssue(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                Validate
                                            </Button>
                                        </div>
                                    }
                                    <ValidationButtons type={"issue"} onClick={() => { showIssueHandler('rightEar') }} />
                                </div>
                                {/* <div className={classes.issueButtonCon} >
                                    {
                                        showReason.show && showReason.type === 'rightEar' &&
                                        <div className={classes.reasonDropDown}>
                                            <FormGroup>
                                                {
                                                    retakeVideo.map((reason, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            control={<Checkbox
                                                                // checked={(!!eyeAssessment?.validation?.find((obj) => obj.issue === issues))}
                                                                // onChange={(e) => { handleIssueChecks(e, 'eyeValidation', issues) }}
                                                                size="small" color="info" sx={{ padding: '5px' }} />}
                                                            label={reason} />
                                                    })
                                                }
                                            </FormGroup>
                                            <br />
                                            <Button onClick={() => { setShowReason(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                Request
                                            </Button>
                                        </div>
                                    }
                                    <ValidationButtons type={"reexam"} onClick={() => { showRetakeHandler('rightEar') }} />
                                </div> */}
                            </div>
                        </div>
                        {
                            earAssessment?.rightEarValidation?.map((issueObj) => {
                                return <DocInterpretation
                                    interpretation={issueObj.interpretation}
                                    issueType={issueObj.issue}
                                    updateType={"rightEarValidation"}
                                    updateInterpretation={updateInterpretation}
                                    objArray={earAssessment?.rightEarValidation} />
                            })
                        }
                        {/* <DocInterpretation interpretation={earAssessment?.rightEarInterpretation} updateType={"rightEar"} updateInterpretation={updateInterpretation} /> */}
                    </div> : <>
                        <div>
                            No data avaialble for Right Ear Assessment
                        </div>
                    </>}
                    <br />
                    <div className={classes.hrLine} />
                    <br />
                    {screenData?.higo?.leftEar?.length > 0 || screenData?.ent?.entLeftEarVideo ? <div>
                        <div title={"Screened by - " + JSON.parse(validationData?.auditTrail)?.leftEar?.name} className={classes.headingWithCheck}>
                            <div className={classes.headingText}>Left Ear</div>
                            <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                        </div>
                        <br />
                        {!checkDefects("leftEar") ? <div className={classes.slides_player_container}>
                            {screenData?.higo?.leftEar?.length > 0 && <VideoSlideModal videoUrl={screenData?.higo?.leftEarVideo} imgs={screenData?.higo?.leftEar} />}
                            {screenData?.ent?.entLeftEarVideo && <VideoSlideModal videoUrl={screenData?.ent?.entLeftEarVideo} />}
                            <div id="captured">
                            </div>
                        </div> :
                            <div>
                                <p style={{ marginTop: 20 }}>Defect Found</p>
                                <ImageSlideModal imgArray={getDefectImage("leftEar")} />
                                <div>{getDefectComment("leftEar")}</div>
                            </div>
                        }
                        <div className={classes.validationButtonContainer}>
                            <p>Validation</p>
                            <div>
                                <ValidationButtons type={"good"} />
                                <div className={classes.issueButtonCon} >
                                    {showIssue.show && showIssue.type === 'leftEar' &&
                                        <div className={classes.issueDropDown}>
                                            <FormGroup>
                                                {
                                                    earIssues.map((issues, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            sx={{ whiteSpace: 'nowrap' }}
                                                            control={<Checkbox
                                                                checked={(!!earAssessment?.leftEarValidation?.find((obj) => obj.issue === issues))}
                                                                onChange={(e) => { handleIssueChecks(e, 'leftEarValidation', issues) }}
                                                                size="small" color="info" sx={{ padding: '5px' }} />}
                                                            label={issues} />
                                                    })
                                                }
                                                <FormControlLabel
                                                    sx={{ whiteSpace: 'nowrap' }}
                                                    control={<Checkbox
                                                        checked={!!(earAssessment?.leftEarValidation?.find((obj) => obj.issue === "other"))}
                                                        onChange={(e) => { handleIssueChecks(e, 'leftEarValidation', 'other') }}
                                                        size="small" color="info" sx={{ padding: '5px' }} />}
                                                    label={"Other"} />
                                            </FormGroup>
                                            <br />
                                            <Button onClick={() => { setShowIssue(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                Validate
                                            </Button>
                                        </div>
                                    }
                                    <ValidationButtons type={"issue"} onClick={() => { showIssueHandler('leftEar') }} />
                                </div>
                            </div>

                            {/*------------------------- reexam--------------------------- */}
                            <div className={classes.reExamButtonCon} >
                                {
                                    showReason.show && showReason.type === 'leftEar' &&
                                    <div className={classes.reasonDropDown}>
                                        <FormGroup>
                                            {
                                                retakeVideo.map((reason, index) => {
                                                    return <FormControlLabel
                                                        key={index}
                                                        control={<Checkbox
                                                            // checked={(!!eyeAssessment?.validation?.find((obj) => obj.issue === issues))}
                                                            onChange={(e) => handleReExamIssueCheck(e, reason, 'ear')}
                                                            size="small" color="info" sx={{ padding: '5px' }} />}
                                                        label={reason} />
                                                })

                                            }
                                            <FormControl sx={{ marginTop: "10px" }}>
                                                <InputLabel id="demo-simple-select-label">Assign</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    // value={"One"}
                                                    label="Rows"
                                                    onChange={(e) => { setAssignTo(e.target.value) }}
                                                    size='small'
                                                >
                                                    {dataOps.filter((user) => user?.groups[0] === 'skids-ops-manager')?.map((x) => <MenuItem key={x} value={x.userId}>{x.family_name + x.given_name}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                        <br />
                                        <Button onClick={() => { setShowReason(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                            Request
                                        </Button>
                                    </div>
                                }
                                <ValidationButtons type={"reexam"} onClick={() => { showRetakeHandler('leftEar') }} />
                            </div>
                        </div>
                        {
                            earAssessment?.leftEarValidation?.map((issueObj) => {
                                return <DocInterpretation
                                    interpretation={issueObj.interpretation}
                                    issueType={issueObj.issue}
                                    updateType={"leftEarValidation"}
                                    updateInterpretation={updateInterpretation}
                                    objArray={earAssessment?.leftEarValidation} />
                            })
                        }
                    </div> :
                        <>
                            <div style={{ fontSize: 14, fontWeight: 600, color: "#66707E" }}>
                                No data avaialble for Left Ear Assessment
                            </div>
                        </>}
                </>
            break;

        case 'Hearing':
            estimationTitle = 'Hearing'
            componentEstimation =
                screenData?.screeningPackageDetails?.ear.includes('HEARING')
                &&
                <>
                    <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>Hearing</div>
                        <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                    </div>
                    <div className={classes.hearingImageSection}>
                        {screenData?.hearingAssessment?.reportUrl != "undefined" &&
                            screenData?.hearingAssessment?.hearingReport && (
                                <div>
                                    <ImageSlideModal
                                        imgArray={[
                                            screenData?.hearingAssessment?.hearingReport,
                                        ]}
                                    />
                                </div>
                            )}
                        <div>
                            <ImageSlideModal imgArray={[screenData?.amplivox?.leftEarImage]} />
                            <ImageSlideModal imgArray={[screenData?.amplivox?.leftEarGraph]} />
                        </div>
                        <div>
                            <ImageSlideModal imgArray={[screenData?.amplivox?.rightEarImage]} />
                            <ImageSlideModal imgArray={[screenData?.amplivox?.rightEarGraph]} />
                        </div>
                    </div>
                    <div className={classes.validationButtonContainer}>
                        <p>Validation</p>
                        <div>
                            <ValidationButtons type={"good"} />
                            <div className={classes.issueButtonCon} >
                                {showIssue.show && showIssue.type === 'hearing' &&
                                    <div className={classes.issueDropDown}>
                                        <FormGroup>
                                            {
                                                hearingIssues.map((issues, index) => {
                                                    return <FormControlLabel
                                                        key={index}
                                                        sx={{ whiteSpace: 'nowrap' }}
                                                        control={<Checkbox
                                                            checked={!!(hearingAssessment?.validation?.find((obj) => obj.issue === issues))}
                                                            onChange={(e) => { handleIssueChecks(e, 'hearingValidation', issues) }}
                                                            size="small" color="info" sx={{ padding: '5px' }} />}
                                                        label={issues} />
                                                })

                                            }
                                        </FormGroup>
                                        <br />
                                        <Button onClick={() => { setShowIssue(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                            Validate
                                        </Button>
                                    </div>
                                }
                                <ValidationButtons type={"issue"} onClick={() => { showIssueHandler('hearing') }} />
                            </div>
                        </div>
                    </div>
                    {
                        hearingAssessment?.validation?.map((issueObj) => {
                            return <DocInterpretation
                                interpretation={issueObj.interpretation}
                                issueType={issueObj.issue}
                                updateType={"hearingValidation"}
                                updateInterpretation={updateInterpretation}
                                objArray={hearingAssessment?.validation} />
                        })
                    }
                    {/* <DocInterpretation interpretation={hearingAssessment?.validationInterpretation} updateType={"hearing"} updateInterpretation={updateInterpretation} /> */}
                </>
            break;

        case 'Cough':
            componentEstimation =
                screenData?.screeningPackageDetails?.lungs.includes('COUGH')
                &&
                <>
                    {screenData?.higo?.cough?.length > 0 ? <><div title={"Screened by - " + JSON.parse(validationData?.auditTrail)?.cough?.name} className={classes.headingWithCheck}>
                        <div className={classes.headingText}>Cough</div>
                        <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                    </div>
                        <br />
                        <div
                            className={classes.audioPlayerWrapper}>
                            {
                                screenData?.higo?.cough?.map((audioUrl, index) =>
                                    <div>
                                        <p style={{ fontSize: '10px' }}>No. {index + 1}</p>
                                        <Audioplayer url={audioUrl} />
                                    </div>
                                )
                            }
                        </div>
                        <div className={classes.validationButtonContainer}>
                            <p>Validation</p>
                            <div>
                                <ValidationButtons type={"good"} />
                                <div className={classes.issueButtonCon} >
                                    {showIssue.show && showIssue.type === 'hearing' &&
                                        <div className={classes.issueDropDown}>
                                            <FormGroup>
                                                {
                                                    coughIssues.map((issues, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            sx={{ whiteSpace: 'nowrap' }}
                                                            control={<Checkbox
                                                                checked={!!(coughAssessment?.validation?.find((obj) => obj.issue === issues))}
                                                                onChange={(e) => { handleIssueChecks(e, 'coughValidation', issues) }}
                                                                size="small" color="info" sx={{ padding: '5px' }} />}
                                                            label={issues} />
                                                    })
                                                }
                                                <FormControlLabel
                                                    sx={{ whiteSpace: 'nowrap' }}
                                                    control={<Checkbox
                                                        checked={!!(coughAssessment?.validation?.find((obj) => obj.issue === "other"))}
                                                        onChange={(e) => { handleIssueChecks(e, 'coughValidation', "other") }}
                                                        size="small" color="info" sx={{ padding: '5px' }} />}
                                                    label={"Other"} />
                                            </FormGroup>
                                            <br />
                                            <Button onClick={() => { setShowIssue(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                Validate
                                            </Button>
                                        </div>
                                    }
                                    <ValidationButtons type={"issue"} onClick={() => { showIssueHandler('hearing') }} />
                                </div>
                                {/* <div className={classes.issueButtonCon} >
                                    {
                                        showReason.show && showReason.type === 'cough' &&
                                        <div className={classes.reasonDropDown}>
                                            <FormGroup>
                                                {
                                                    retakeVideo.map((reason, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            control={<Checkbox
                                                                // checked={(!!eyeAssessment?.validation?.find((obj) => obj.issue === issues))}
                                                                // onChange={(e) => { handleIssueChecks(e, 'eyeValidation', issues) }}
                                                                size="small" color="info" sx={{ padding: '5px' }} />}
                                                            label={reason} />
                                                    })
                                                }
                                            </FormGroup>
                                            <br />
                                            <Button onClick={() => { setShowReason(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                Request
                                            </Button>
                                        </div>
                                    }
                                    <ValidationButtons type={"reexam"} onClick={() => { showRetakeHandler('cough') }} />
                                </div> */}
                            </div>
                        </div>
                        {
                            coughAssessment?.validation?.map((issueObj) => {
                                return <DocInterpretation
                                    interpretation={issueObj.interpretation}
                                    issueType={issueObj.issue}
                                    updateType={"coughValidation"}
                                    updateInterpretation={updateInterpretation}
                                    objArray={coughAssessment?.validation} />
                            })
                        }</> :
                        <>
                            <div>
                                No data avaialble for Cough Assessment
                            </div>
                        </>
                    }
                </>

            break;

        case 'Throat':
            estimationTitle = 'Throat videos'
            componentEstimation =
                (screenData?.screeningPackageDetails?.throat.length > 0) &&
                <>
                    {screenData?.higo?.throat?.length > 0 || screenData?.ent?.entThroatVideo
                        ? <>
                            <div title={"Screened by - " + JSON.parse(validationData?.auditTrail)?.throat?.name} className={classes.headingWithCheck}>
                                <div className={classes.headingText}>Throat</div>
                                <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                            </div>
                            <br />
                            <div className={classes.slides_player_container}>
                                {screenData?.higo?.throat?.length > 0 && <VideoSlideModal videoUrl={screenData?.higo?.throatVideo} imgs={screenData?.higo?.throat} />}
                                {screenData?.ent?.entThroatVideo && <VideoSlideModal videoUrl={screenData?.ent?.entThroatVideo} />}
                            </div>
                            <div className={classes.validationButtonContainer}>
                                <p>Validation</p>
                                <div>
                                    <ValidationButtons type={"good"} />
                                    <div className={classes.issueButtonCon} >
                                        {showIssue.show && showIssue.type === 'throat' &&
                                            <div className={classes.issueDropDown}>
                                                <FormGroup>
                                                    {
                                                        throatIssues.map((issues, index) => {
                                                            return <FormControlLabel
                                                                key={index}
                                                                sx={{ whiteSpace: 'nowrap' }}
                                                                control={<Checkbox
                                                                    checked={!!(throatAssessment?.validation?.find((obj) => obj.issue === issues))}
                                                                    onChange={(e) => { handleIssueChecks(e, 'throatValidation', issues) }}
                                                                    size="small" color="info" sx={{ padding: '5px' }} />}
                                                                label={issues} />
                                                        })
                                                    }
                                                    <FormControlLabel
                                                        sx={{ whiteSpace: 'nowrap' }}
                                                        control={<Checkbox
                                                            checked={!!(throatAssessment?.validation?.find((obj) => obj.issue === "other"))}
                                                            onChange={(e) => { handleIssueChecks(e, 'throatValidation', "other") }}
                                                            size="small" color="info" sx={{ padding: '5px' }} />}
                                                        label={"Other"} />
                                                </FormGroup>
                                                <br />
                                                <Button onClick={() => { setShowIssue(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                    Validate
                                                </Button>
                                            </div>
                                        }
                                        <ValidationButtons type={"issue"} onClick={() => { showIssueHandler('throat') }} />
                                    </div>
                                    {/* <div className={classes.issueButtonCon} >
                                        {
                                            showReason.show && showReason.type === 'throat' &&
                                            <div className={classes.reasonDropDown}>
                                                <FormGroup>
                                                    {
                                                        retakeVideo.map((reason, index) => {
                                                            return <FormControlLabel
                                                                key={index}
                                                                control={<Checkbox
                                                                    // checked={(!!eyeAssessment?.validation?.find((obj) => obj.issue === issues))}
                                                                    // onChange={(e) => { handleIssueChecks(e, 'eyeValidation', issues) }}
                                                                    size="small" color="info" sx={{ padding: '5px' }} />}
                                                                label={reason} />
                                                        })
                                                    }
                                                </FormGroup>
                                                <br />
                                                <Button onClick={() => { setShowReason(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                    Request
                                                </Button>
                                            </div>
                                        }
                                        <ValidationButtons type={"reexam"} onClick={() => { showRetakeHandler('throat') }} />
                                    </div> */}
                                </div>
                                <div className={classes.reExamButtonCon} >
                                    {
                                        showReason.show && showReason.type === 'throat' &&
                                        <div className={classes.reasonDropDown}>
                                            <FormGroup>
                                                {
                                                    retakeVideo.map((reason, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            control={<Checkbox
                                                                // checked={(!!eyeAssessment?.validation?.find((obj) => obj.issue === issues))}
                                                                onChange={(e) => handleReExamIssueCheck(e, reason, 'throat')}
                                                                size="small" color="info" sx={{ padding: '5px' }} />}
                                                            label={reason} />
                                                    })

                                                }
                                                <FormControl sx={{ marginTop: "10px" }}>
                                                    <InputLabel id="demo-simple-select-label">Assign</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        // value={"One"}
                                                        label="Rows"
                                                        onChange={(e) => { setAssignTo(e.target.value) }}
                                                        size='small'
                                                    >
                                                        {dataOps.filter((user) => user?.groups[0] === 'skids-ops-manager')?.map((x) => <MenuItem key={x} value={x.userId}>{x.family_name + x.given_name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </FormGroup>
                                            <br />
                                            <Button onClick={() => { setShowReason(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                Request
                                            </Button>
                                        </div>
                                    }
                                    <ValidationButtons type={"reexam"} onClick={() => { showRetakeHandler('throat') }} />
                                </div>
                            </div>
                            {
                                throatAssessment?.validation?.map((issueObj) => {
                                    return <DocInterpretation
                                        interpretation={issueObj.interpretation}
                                        issueType={issueObj.issue}
                                        updateType={"throatValidation"}
                                        updateInterpretation={updateInterpretation}
                                        objArray={throatAssessment?.validation} />
                                })
                            }</> :
                        <>
                            <div>
                                No data avaialble for Throat Assessment
                            </div>
                        </>}
                </>

            break;

        case 'Heart':
            estimationTitle = 'Heart rate'
            componentEstimation =
                // screenData?.screeningPackageDetails?.heart.length > 0 &&
                <>
                    {/* <div className={classes.estimationBlock}>
                        <div className={classes.headingWithCheck}>
                            <div className={classes.headingText}>Heart estimation</div>
                            <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                        </div>
                        <br />
                        <div
                            className={classes.audioPlayerWrapper}>
                            <p style={{ fontSize: '10px' }}>No. {index + 1}</p>
                            {
                                screenData?.higo?.heart?.map((audioUrl, index) =>
                                    <div>
                                        <p style={{ fontSize: '10px' }}>No. {index + 1}</p>
                                        <Audioplayer url={audioUrl} />
                                    </div>
                                )
                            }
                        </div>

                        <div className={classes.validationButtonContainer}>
                            <p>Validation</p>
                            <div>
                                <ValidationButtons type={"good"} />
                                <div className={classes.issueButtonCon} >
                                    {showIssue.show && showIssue.type === 'heartEstimation' &&
                                        <div className={classes.issueDropDown}>
                                            <FormGroup>
                                                {
                                                    heartEstimationIssues.map((issues, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            sx={{ whiteSpace: 'nowrap' }}
                                                            control={<Checkbox
                                                                checked={heartAssessment?.estimationValidation?.[0]?.split(",").includes(issues)}
                                                                onChange={(e) => { handleIssueChecks(e, 'heartEstimationValidation', issues) }}
                                                                size="small" color="info" sx={{ padding: '5px' }} />}
                                                            label={issues} />
                                                    })
                                                }
                                            </FormGroup>
                                            <br />
                                            <Button onClick={() => { setShowIssue(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                Validate
                                            </Button>
                                        </div>
                                    }
                                    <ValidationButtons type={"issue"} onClick={() => { showIssueHandler('heartEstimation') }} />
                                </div>
                            </div>
                        </div>
                        <DocInterpretation interpretation={heartAssessment?.estimationInterpretation} updateType={"heartEstimation"} updateInterpretation={updateInterpretation} />
                    </div>
                    <br />
                    <div className={classes.hrLine} /> */}
                    {screenData?.higo?.heart?.length > 0 || screenData?.heartAssessment?.heartReportUrl ? <><div>
                        <p title={"Screened by - " + JSON.parse(validationData?.auditTrail)?.heartEstimation?.name} className={classes.ausculation_heading}>Heart ausculation</p>
                        {
                            screenData?.higo?.heart?.map((audioUrl, index) =>
                                <div
                                    className={classes.audioPlayerWrapper}>
                                    <p style={{ fontSize: '10px' }}>No. {index + 1}</p>
                                    <Audioplayer url={audioUrl} />
                                </div>
                            )
                        }
                        {/* {screenData?.ent?.entHeartAudio && <Audioplayer url={screenData?.ent?.entHeartAudio} />} */}
                        {screenData?.heartAssessment?.heartReportUrl ? (
                            <iframe
                                src={screenData?.heartAssessment?.heartReportUrl}
                                style={{ width: "100%" }}
                                height="1000"
                                frameBorder="0"
                            ></iframe>
                        ) : screenData?.entAssessment?.heartReportUrl ? (
                            <iframe
                                src={screenData?.entAssessment?.heartReportUrl}
                                style={{ width: "100%" }}
                                height="1000"
                                frameBorder="0"
                            ></iframe>
                        ) : (
                            <h3>No data available at th time!</h3>
                        )}
                    </div>
                        {screenData?.higo?.heartWav?.length > 0 && <div>
                            <p className={classes.ausculation_heading}>Heart Wav</p>
                            {
                                screenData?.higo?.heartWav?.map((audioUrl, index) =>
                                    <div
                                        className={classes.audioPlayerWrapper}>
                                        <p style={{ fontSize: '10px' }}>No. {index + 1}</p>
                                        <Audioplayer url={audioUrl} />
                                    </div>
                                )
                            }
                        </div>
                        }
                        <div className={classes.validationButtonContainer}>
                            <p>Validation</p>
                            <div>
                                <ValidationButtons type={"good"} />
                                <div className={classes.issueButtonCon} >
                                    {showIssue.show && showIssue.type === 'heartAuscultation' &&
                                        <div className={classes.issueDropDown}>
                                            <FormGroup>
                                                {
                                                    heartAuscultationIssues.map((issues, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            sx={{ whiteSpace: 'nowrap' }}
                                                            control={<Checkbox
                                                                checked={!!(heartAssessment?.auscultationValidation?.find((obj) => obj.issue === issues))}
                                                                onChange={(e) => { handleIssueChecks(e, 'heartAuscultationValidation', issues) }}
                                                                size="small" color="info" sx={{ padding: '5px' }} />}
                                                            label={issues} />
                                                    })
                                                }
                                                <FormControlLabel
                                                    sx={{ whiteSpace: 'nowrap' }}
                                                    control={<Checkbox
                                                        checked={!!(heartAssessment?.auscultationValidation?.find((obj) => obj.issue === "other"))}
                                                        onChange={(e) => { handleIssueChecks(e, 'heartAuscultationValidation', "other") }}
                                                        size="small" color="info" sx={{ padding: '5px' }} />}
                                                    label={"Other"} />
                                            </FormGroup>
                                            <br />
                                            <Button onClick={() => { setShowIssue(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                Validate
                                            </Button>
                                        </div>
                                    }
                                    <ValidationButtons type={"issue"} onClick={() => { showIssueHandler('heartAuscultation') }} />
                                </div>
                                {/* <div className={classes.issueButtonCon} >
                                    {
                                        showReason.show && showReason.type === 'heart' &&
                                        <div className={classes.reasonDropDown}>
                                            <FormGroup>
                                                {
                                                    retakeVideo.map((reason, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            control={<Checkbox
                                                                // checked={(!!eyeAssessment?.validation?.find((obj) => obj.issue === issues))}
                                                                // onChange={(e) => { handleIssueChecks(e, 'eyeValidation', issues) }}
                                                                size="small" color="info" sx={{ padding: '5px' }} />}
                                                            label={reason} />
                                                    })
                                                }
                                            </FormGroup>
                                            <br />
                                            <Button onClick={() => { setShowReason(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                Request
                                            </Button>
                                        </div>
                                    }
                                    <ValidationButtons type={"reexam"} onClick={() => { showRetakeHandler('heart') }} />
                                </div> */}
                            </div>
                        </div>
                        {
                            heartAssessment?.auscultationValidation?.map((issueObj) => {
                                return <DocInterpretation
                                    interpretation={issueObj.interpretation}
                                    issueType={issueObj.issue}
                                    updateType={"heartAuscultation"}
                                    updateInterpretation={updateInterpretation}
                                    objArray={heartAssessment?.auscultationValidation} />
                            })
                        }</> : <>
                        <div>
                            No data avaialble for Heart Assessment
                        </div>
                    </>}
                </>

            break;

        case 'Lung':
            estimationTitle = 'Lungs auscultaion'
            componentEstimation =
                // screenData?.screeningPackageDetails?.lungs.length > 0 &&
                <>
                    {screenData?.higo?.lungs?.length > 0 || screenData?.lungsAssessment?.lungReportUrl_1 || screenData?.lungsAssessment?.lungReportUrl_2 ? <div className={classes.estimationBlock}>
                        <div title={"Screened by - " + JSON.parse(validationData?.auditTrail)?.lungsAuscultation?.name} className={classes.headingWithCheck}>
                            <div className={classes.headingText}>Left Point</div>
                            <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                        </div>
                        <br />
                        {/* <div style={{ margin: '3rem 0' }}>
                            {screenData?.ent?.entLungsAudio_1 ?
                                <Audioplayer url={screenData?.ent?.entLungsAudio_1} />
                                : <h3>No data available at th time!</h3>}
                        </div> */}
                        {screenData?.lungsAssessment?.lungReportUrl_1 ? (
                            <iframe
                                src={screenData?.lungsAssessment?.lungReportUrl_1}
                                style={{ width: "100%" }}
                                height="1000"
                                frameBorder="0"
                            ></iframe>
                        ) : screenData?.entAssessment?.lungsReportUrl_1 ? (
                            <iframe
                                src={screenData?.entAssessment?.lungsReportUrl_1}
                                style={{ width: "100%", marginBottom: "3rem" }}
                                height="1000"
                                frameBorder="0"
                            ></iframe>
                        ) : (
                            <h3>No data available at th time!</h3>
                        )}
                        <div className={classes.headingWithCheck}>
                            <div className={classes.headingText}>Right Point</div>
                            <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                        </div>
                        {/* <div style={{ margin: '3rem 0' }}>
                            {screenData?.ent?.entLungsAudio_2 ?
                                <Audioplayer url={screenData?.ent?.entLungsAudio_2} />
                                : <h3>No data available at th time!</h3>}
                        </div> */}
                        {screenData?.lungsAssessment?.lungReportUrl_2 ? (
                            <iframe
                                src={screenData?.lungsAssessment?.lungReportUrl_2}
                                style={{ width: "100%" }}
                                height="1000"
                                frameBorder="0"
                            ></iframe>
                        ) : screenData?.entAssessment?.lungsReportUrl_2 ? (
                            <iframe
                                src={screenData?.entAssessment?.lungsReportUrl_2}
                                style={{ width: "100%" }}
                                height="1000"
                                frameBorder="0"
                            ></iframe>
                        ) : (
                            <h3>No data available at th time!</h3>
                        )}
                        <div
                            className={classes.audioPlayerWrapper}>
                            {/* <p style={{ fontSize: '10px' }}>No. {index + 1}</p> */}
                            {
                                screenData?.higo?.lungs?.map((audioUrl, index) =>
                                    <div>
                                        <p style={{ fontSize: '10px' }}>No. {index + 1}</p>
                                        <Audioplayer url={audioUrl} />
                                    </div>
                                )
                            }
                        </div>
                        <div className={classes.validationButtonContainer}>
                            <p>Validation</p>
                            <div>
                                <ValidationButtons type={"good"} />
                                <div className={classes.issueButtonCon} >
                                    {showIssue.show && showIssue.type === 'lungsAuscultation' &&
                                        <div className={classes.issueDropDown}>
                                            <FormGroup>
                                                {
                                                    lungsAuscultationIssues.map((issues, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            sx={{ whiteSpace: 'nowrap' }}
                                                            control={<Checkbox
                                                                checked={!!(lungsAssessment?.auscultationValidation?.find((obj) => obj.issue === issues))}
                                                                onChange={(e) => { handleIssueChecks(e, 'lungsValidation', issues) }}
                                                                size="small" color="info" sx={{ padding: '5px' }} />}
                                                            label={issues} />
                                                    })
                                                }
                                                <FormControlLabel
                                                    sx={{ whiteSpace: 'nowrap' }}
                                                    control={<Checkbox
                                                        checked={!!(lungsAssessment?.auscultationValidation?.find((obj) => obj.issue === "other"))}
                                                        onChange={(e) => { handleIssueChecks(e, 'lungsValidation', "other") }}
                                                        size="small" color="info" sx={{ padding: '5px' }} />}
                                                    label={"Other"} />
                                            </FormGroup>
                                            <br />
                                            <Button onClick={() => { setShowIssue(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                Validate
                                            </Button>
                                        </div>
                                    }
                                    <ValidationButtons type={"issue"} onClick={() => { showIssueHandler('lungsAuscultation') }} />
                                </div>
                                {/* <div className={classes.issueButtonCon} >
                                    {
                                        showReason.show && showReason.type === 'lungs' &&
                                        <div className={classes.reasonDropDown}>
                                            <FormGroup>
                                                {
                                                    retakeVideo.map((reason, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            control={<Checkbox
                                                                // checked={(!!eyeAssessment?.validation?.find((obj) => obj.issue === issues))}
                                                                // onChange={(e) => { handleIssueChecks(e, 'eyeValidation', issues) }}
                                                                size="small" color="info" sx={{ padding: '5px' }} />}
                                                            label={reason} />
                                                    })
                                                }
                                            </FormGroup>
                                            <br />
                                            <Button onClick={() => { setShowReason(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                Request
                                            </Button>
                                        </div>
                                    }
                                    <ValidationButtons type={"reexam"} onClick={() => { showRetakeHandler('lungs') }} />
                                </div> */}
                            </div>
                        </div>
                        {
                            lungsAssessment?.auscultationValidation?.map((issueObj) => {
                                return <DocInterpretation
                                    interpretation={issueObj.interpretation}
                                    issueType={issueObj.issue}
                                    updateType={"lungsValidation"}
                                    updateInterpretation={updateInterpretation}
                                    objArray={lungsAssessment?.auscultationValidation} />
                            })
                        }
                    </div> :
                        <>
                            <div>
                                No data avaialble for Lungs Assessment
                            </div>
                        </>}
                </>
            break;

        case 'Abdominal':
            estimationTitle = 'Abdominal auscultation'
            componentEstimation =
                screenData?.screeningPackageDetails?.abdomen.length > 0 &&
                <>
                    <div title={"Screened by - " + JSON.parse(validationData?.auditTrail)?.abdominal?.name} className={classes.headingWithCheck}>
                        <div className={classes.headingText}>Abdominal</div>
                        <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                    </div>
                    {screenData?.higo?.abdomen?.lenght > 0 ? <>{
                        screenData?.higo?.abdominal?.map((audioUrl, index) =>
                            <div
                                className={classes.audioPlayerWrapper}>
                                <p style={{ fontSize: '10px' }}>No. {index + 1}</p>
                                <Audioplayer url={audioUrl} />
                            </div>
                        )
                    }
                        <div className={classes.validationButtonContainer}>
                            <p>Validation</p>
                            <div>
                                <ValidationButtons type={"good"} />
                                <div className={classes.issueButtonCon} >
                                    {showIssue.show && showIssue.type === 'abdomenAuscultation' &&
                                        <div className={classes.issueDropDown}>
                                            <FormGroup>
                                                {
                                                    abdomenIssues.map((issues, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            sx={{ whiteSpace: 'nowrap' }}
                                                            control={<Checkbox
                                                                checked={!!(abdomenAssessment?.validation?.find((obj) => obj.issue === issues))}
                                                                onChange={(e) => { handleIssueChecks(e, 'abdomenValidation', issues) }}
                                                                size="small" color="info" sx={{ padding: '5px' }} />}
                                                            label={issues} />
                                                    })
                                                }
                                                <FormControlLabel
                                                    sx={{ whiteSpace: 'nowrap' }}
                                                    control={<Checkbox
                                                        checked={!!(abdomenAssessment?.validation?.find((obj) => obj.issue === "other"))}
                                                        onChange={(e) => { handleIssueChecks(e, 'abdomenValidation', "other") }}
                                                        size="small" color="info" sx={{ padding: '5px' }} />}
                                                    label={"Other"} />
                                            </FormGroup>
                                            <br />
                                            <Button onClick={() => { setShowIssue(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                Validate
                                            </Button>
                                        </div>
                                    }
                                    <ValidationButtons type={"issue"} onClick={() => { showIssueHandler('abdomenAuscultation') }} />
                                </div>
                                {/* <div className={classes.issueButtonCon} >
                                    {
                                        showReason.show && showReason.type === 'abdomen' &&
                                        <div className={classes.reasonDropDown}>
                                            <FormGroup>
                                                {
                                                    retakeVideo.map((reason, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            control={<Checkbox
                                                                // checked={(!!eyeAssessment?.validation?.find((obj) => obj.issue === issues))}
                                                                // onChange={(e) => { handleIssueChecks(e, 'eyeValidation', issues) }}
                                                                size="small" color="info" sx={{ padding: '5px' }} />}
                                                            label={reason} />
                                                    })
                                                }
                                            </FormGroup>
                                            <br />
                                            <Button onClick={() => { setShowReason(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                Request
                                            </Button>
                                        </div>
                                    }
                                    <ValidationButtons type={"reexam"} onClick={() => { showRetakeHandler('abdomen') }} />
                                </div> */}
                            </div>
                        </div>
                        {
                            abdomenAssessment?.validation?.map((issueObj) => {
                                return <DocInterpretation
                                    interpretation={issueObj.interpretation}
                                    issueType={issueObj.issue}
                                    updateType={"abdomenValidation"}
                                    updateInterpretation={updateInterpretation}
                                    objArray={abdomenAssessment?.validation} />
                            })
                        }</> : <>
                        <div>
                            No data avaialble for Abdomen Assessment
                        </div>
                    </>}
                    {/* <DocInterpretation interpretation={abdomenAssessment?.validationInterpretation} updateType={"abdomen"} updateInterpretation={updateInterpretation} /> */}

                </>
            break;

        case 'Skin':
            estimationTitle = 'Skin images & videos'
            componentEstimation =
                screenData?.screeningPackageDetails?.skin.length > 0 &&
                <>
                    <div title={"Screened by - " + JSON.parse(validationData?.auditTrail)?.skin?.name} className={classes.headingWithCheck}>
                        <div className={classes.headingText}>Skin</div>
                        <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                    </div>
                    {screenData?.higo?.skin?.length > 0 || screenData?.ent?.entSkinImage ? <><div className={classes.slides_player_container}>
                        {screenData?.higo?.skin?.length > 0 && <VideoSlideModal videoUrl={screenData?.higo?.skinVideo} imgs={screenData?.higo?.skin} />}
                        {screenData?.ent?.entSkinImage && (
                            <ImageSlideModal
                                imgArray={[screenData?.ent?.entSkinImage]}
                            />
                        )}
                    </div>
                        <div className={classes.validationButtonContainer}>
                            <p>Validation</p>
                            <div>
                                <ValidationButtons type={"good"} />
                                <div className={classes.issueButtonCon} >
                                    {showIssue.show && showIssue.type === 'skinIssue' &&
                                        <div className={classes.issueDropDown}>
                                            <FormGroup>
                                                {
                                                    skinIssues.map((issues, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            sx={{ whiteSpace: 'nowrap' }}
                                                            control={<Checkbox
                                                                checked={!!(skinAssessment?.validation?.find((obj) => obj.issue === issues))}
                                                                onChange={(e) => { handleIssueChecks(e, 'skinValidation', issues) }}
                                                                size="small" color="info" sx={{ padding: '5px' }} />}
                                                            label={issues} />
                                                    })
                                                }
                                                <FormControlLabel
                                                    sx={{ whiteSpace: 'nowrap' }}
                                                    control={<Checkbox
                                                        checked={!!(skinAssessment?.validation?.find((obj) => obj.issue === 'other'))}
                                                        onChange={(e) => { handleIssueChecks(e, 'skinValidation', 'other') }}
                                                        size="small" color="info" sx={{ padding: '5px' }} />}
                                                    label={"Other"} />
                                            </FormGroup>
                                            <br />
                                            <Button onClick={() => { setShowIssue(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                Validate
                                            </Button>


                                        </div>
                                    }
                                    <ValidationButtons type={"issue"} onClick={() => { showIssueHandler('skinIssue') }} />
                                </div>
                                {/* <div className={classes.issueButtonCon} >
                                    {
                                        showReason.show && showReason.type === 'skin' &&
                                        <div className={classes.reasonDropDown}>
                                            <FormGroup>
                                                {
                                                    retakeVideo.map((reason, index) => {
                                                        return <FormControlLabel
                                                            key={index}
                                                            control={<Checkbox
                                                                // checked={(!!eyeAssessment?.validation?.find((obj) => obj.issue === issues))}
                                                                // onChange={(e) => { handleIssueChecks(e, 'eyeValidation', issues) }}
                                                                size="small" color="info" sx={{ padding: '5px' }} />}
                                                            label={reason} />
                                                    })
                                                }
                                            </FormGroup>
                                            <br />
                                            <Button onClick={() => { setShowReason(false) }} sx={{ width: "90%" }} variant="outlined" size="small">
                                                Request
                                            </Button>
                                        </div>
                                    }
                                    <ValidationButtons type={"reexam"} onClick={() => { showRetakeHandler('skin') }} />
                                </div> */}
                            </div>
                        </div>
                        {
                            skinAssessment?.validation?.map((issueObj) => {
                                return <DocInterpretation
                                    interpretation={issueObj.interpretation}
                                    issueType={issueObj.issue}
                                    updateType={"skinValidation"}
                                    updateInterpretation={updateInterpretation}
                                    objArray={skinAssessment?.validation} />
                            })
                        }</> :
                        <>
                            <div>
                                No data avaialble for Skin Assessment
                            </div>
                        </>}
                </>
            break;

        case 'Dental':
            componentEstimation =
                screenData?.screeningPackageDetails?.dental.length > 0 &&
                <>
                    <div className={classes.dentalConMain}>
                        <div>
                            <div>
                                <div className={checkDentalCondition(screenData?.dentalAssessment?.tooth, "Q1") ? classes.reviewsNormal : classes.reviewsNeedAttention}>{checkDentalCondition(screenData?.dentalAssessment?.tooth, "Q1") ? "Normal" : "NEED ATTENTION"}</div>
                                <div className={checkDentalCondition(screenData?.dentalAssessment?.tooth, "Q3") ? classes.reviewsNormal : classes.reviewsNeedAttention}>{checkDentalCondition(screenData?.dentalAssessment?.tooth, "Q3") ? "Normal" : "NEED ATTENTION"}</div>
                            </div>
                            <div>
                                <DentalJaw tooths={screenData?.dentalAssessment?.tooth} disabled />
                            </div>
                            <div>
                                <div className={checkDentalCondition(screenData?.dentalAssessment?.tooth, "Q2") ? classes.reviewsNormal : classes.reviewsNeedAttention}>{checkDentalCondition(screenData?.dentalAssessment?.tooth, "Q2") ? "Normal" : "NEED ATTENTION"}</div>
                                <div className={checkDentalCondition(screenData?.dentalAssessment?.tooth, "Q4") ? classes.reviewsNormal : classes.reviewsNeedAttention}>{checkDentalCondition(screenData?.dentalAssessment?.tooth, "Q4") ? "Normal" : "NEED ATTENTION"}</div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div>
                                    <div className={classes.decayed} />{" "}
                                    <p>Decayed</p>
                                </div>
                                <div>
                                    <div className={classes.missing} />{" "}
                                    <p>Missing</p>
                                </div>
                                <div>
                                    <div className={classes.filled} />{" "}
                                    <p>Filled</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className={classes.hrLine} />
                    <br />
                    {
                        screenData?.screeningPackageDetails?.dental.includes('DMFT')
                        &&
                        <>
                            <div className={classes.headingWithCheck}>
                                <div className={classes.headingText}>DMFT Index</div>
                                <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                            </div>
                            <div className={classes.headingBold}>
                                <div>{getDMFTIndex(screenData?.dentalAssessment?.tooth?.[0].split(','))}</div>
                            </div>
                            <DocInterpretation interpretation={dentalAssessment?.DMFTIndexInterpretation} updateType={"DMFT"} updateInterpretation={updateInterpretation} />
                            <br />
                            <div className={classes.hrLine} />
                            <br />
                        </>
                    }

                    {screenData?.screeningPackageDetails?.dental.includes('GINGIVITIS')
                        &&
                        <>
                            <div className={classes.headingWithCheck}>
                                <div className={classes.headingText}>Gingivitis</div>
                                <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                            </div>
                            <div className={classes.headingBold}>
                                <div>{screenData?.dentalAssessment?.gingivitis ? "Present" : "Absent" ?? "-"}</div>
                            </div>
                            <DocInterpretation interpretation={dentalAssessment?.gingivitisInterpretation} updateType={"gingivitis"} updateInterpretation={updateInterpretation} />
                            <br />
                            <div className={classes.hrLine} />
                            <br />
                        </>
                    }

                    {screenData?.screeningPackageDetails?.dental.includes('ORTHODONTIC_PROBLEM')
                        &&
                        <>
                            <div className={classes.headingWithCheck}>
                                <div className={classes.headingText}>Orthodontic Problem</div>
                                <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                            </div>
                            <div className={classes.headingBold}>
                                <div>{screenData?.dentalAssessment?.orthodontic ? "Present" : "Absent" ?? "-"}</div>
                            </div>
                            <DocInterpretation interpretation={dentalAssessment?.orthodonticInterpretation} updateType={"orthodontic"} updateInterpretation={updateInterpretation} />
                            <br />
                            <div className={classes.hrLine} />
                            <br />
                        </>
                    }

                    {screenData?.screeningPackageDetails?.dental.includes('DECOLORATION')
                        &&
                        <>
                            <div className={classes.headingWithCheck}>
                                <div className={classes.headingText}>Decoloration</div>
                                <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                            </div>
                            <div className={classes.headingBold}>
                                <div>{screenData?.dentalAssessment?.decoloration ? "Present" : "Absent" ?? "-"}</div>
                            </div>
                            <DocInterpretation interpretation={dentalAssessment?.decolorationInterpretation} updateType={"decoloration"} updateInterpretation={updateInterpretation} />
                        </>
                    }
                </>
            break;
        case 'VHR':
            const vitals = screenData?.entAssessment?.vitals;
            console.log(vitals, 'vitals')
            componentEstimation = <>
                <div className={classes.headingWithCheck}>
                    <div className={classes.headingText}>Vitals Report</div>
                    <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                </div>
                {vitals?.wellnessScore || vitals?.heartRateVariability || vitals?.respirationRate || vitals?.BMI ? <div className={classes.headingBold} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    {vitals?.faceAge && <VitalsGaugeChart
                        label="Face Age"
                        value={parseFloat(vitals?.faceAge)}
                        maxValue={100}
                        suffix="years"
                        colors={["#8eff8e"]}
                    />}
                    {vitals?.BMI && <VitalsGaugeChart
                        label="BMI"
                        value={parseFloat(vitals?.BMI)?.toFixed(1)}
                        maxValue={40}
                        colors={["#ffff8e", "#8eff8e", "#ffff8e", "#ff8e8e", "#ff444f"]}
                        nrOfLevels={5}
                    />}
                    {vitals?.bodyShapeIndex && <VitalsGaugeChart
                        label="Body Shape Index"
                        value={parseFloat(vitals?.bodyShapeIndex)?.toFixed(1)}
                        maxValue={10}
                        colors={["#00de93", "#8eff8e", "#ffff8e", "#ff8e8e", "#ff444f"]}
                        nrOfLevels={5}
                    />}
                    {vitals?.waist2HeightRatio && <VitalsGaugeChart
                        label="Waist to Height Ratio"
                        value={parseFloat(vitals?.waist2HeightRatio)?.toFixed(1)}
                        maxValue={100}
                        colors={["#ffff8e", "#8eff8e", "#ffff8e", "#ff8e8e", "#ff444f"]}
                        // colors={["#ff0", "#0f0", "#ff0", "#ff8000", "#f00"]}
                        nrOfLevels={5}
                    />}
                    {vitals?.heartRateVariability && <VitalsGaugeChart
                        label="Heart Rate Variability"
                        value={parseFloat(vitals?.heartRateVariability)?.toFixed(1)}
                        maxValue={100}
                        colors={["#ff8e8e", "#ffff8e", "#8eff8e"]}
                    />}
                    {vitals?.mindStressIndex && <VitalsGaugeChart
                        label="Mind Stress Index"
                        value={parseFloat(vitals?.mindStressIndex)?.toFixed(1)}
                        maxValue={100}
                        colors={["#00de93", "#8eff8e", "#ffff8e", "#ff8e8e", "#ff444f"]}
                    />}
                    {vitals?.heartRate && <VitalsGaugeChart
                        label="Heart Rate"
                        value={parseFloat(vitals?.heartRate)}
                        maxValue={220}
                        suffix="BPM"
                        unit="BPM"
                        colors={["#ffff8e", "#8eff8e", "#ffff8e"]}
                        nrOfLevels={3}
                    />}
                    {vitals?.respirationRate && <VitalsGaugeChart
                        label="Respiration Rate"
                        value={parseFloat(vitals?.respirationRate)?.toFixed(1)}
                        maxValue={80}
                        suffix="RPM"
                        unit="RPM"
                        colors={["#ffff8e", "#8eff8e", "#ffff8e"]}
                        nrOfLevels={3}
                    />}
                    {vitals?.wellnessScore && <VitalsGaugeChart
                        label="Wellness Score"
                        value={parseFloat(vitals?.wellnessScore)}
                        maxValue={150}
                        nrOfLevels={5}
                        // colors={["#f00", "#ff8000", "#ff0", "#80ff00", "#0f0"]}
                        colors={["#ff444f", "#ff8e8e", "#ffff8e", "#8eff8e", "#00de93"]}
                    />}
                </div> : <>
                    <div>
                        No data avaialble for VHR
                    </div>
                </>}
            </>
            break;
    }

    // ---------------------------------------------------------------------------
    //------------------- Dynamic content for behavioural screening------------------
    // ---------------------------------------------------------------------------
    switch (behaviour) {
        case 'adhd':
            behaviourComponent =
                <div>
                    <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>Total questions</div>
                        <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                    </div>
                    <div className={classes.headingBold}>
                        <div>25</div>
                    </div>
                    <br />
                    <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>Score</div>
                        <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                    </div>
                    <div className={classes.headingBold}>
                        <div>6</div>
                        <div className={classes.ClickHereBlueBlack}>
                            <span>Click here</span>
                            <p>to view detailed report for ADHD assessment</p>
                        </div>
                    </div>
                    <div className={classes.docInterpretation}>
                        <img src={stethoscopeCheck} alt="" />
                        <div>
                            <div>Doctor Interpretation</div>
                            <div>ADHD Score is good and follow up after 6 months is suggested.</div>
                        </div>
                    </div>
                    <br />
                    <div className={classes.hrLine} />
                    <br />
                    <div className={classes.knowMoreSection}>
                        <div>Click here to know more about ADHD.</div>
                        <div>
                            <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                            <div>Ask SkidsGPT</div>
                        </div>
                    </div>
                </div>
            break;
        case 'autism':
            behaviourComponent =
                <div>
                    <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>Total questions</div>
                        <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                    </div>
                    <div className={classes.headingBold}>
                        <div>25</div>
                    </div>
                    <br />
                    <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>Score</div>
                        <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                    </div>
                    <div className={classes.headingBold}>
                        <div>6</div>
                        <div className={classes.ClickHereBlueBlack}>
                            <span>Click here</span>
                            <p>to view detailed report for ADHD assessment</p>
                        </div>
                    </div>
                    <div className={classes.docInterpretation}>
                        <img src={stethoscopeCheck} alt="" />
                        <div>
                            <div>Doctor Interpretation</div>
                            <div>ADHD Score is good and follow up after 6 months is suggested.</div>
                        </div>
                    </div>
                    <br />
                    <div className={classes.hrLine} />
                    <br />
                    <div className={classes.knowMoreSection}>
                        <div>Click here to know more about ADHD.</div>
                        <div>
                            <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                            <div>Ask SkidsGPT</div>
                        </div>
                    </div>
                </div>
            break;
        case 'digitalDependency':
            behaviourComponent =
                <div>
                    <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>Total questions</div>
                        <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                    </div>
                    <div className={classes.headingBold}>
                        <div>25</div>
                    </div>
                    <br />
                    <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>Score</div>
                        <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                    </div>
                    <div className={classes.headingBold}>
                        <div>6</div>
                        <div className={classes.ClickHereBlueBlack}>
                            <span>Click here</span>
                            <p>to view detailed report for ADHD assessment</p>
                        </div>
                    </div>
                    <div className={classes.docInterpretation}>
                        <img src={stethoscopeCheck} alt="" />
                        <div>
                            <div>Doctor Interpretation</div>
                            <div>ADHD Score is good and follow up after 6 months is suggested.</div>
                        </div>
                    </div>
                    <br />
                    <div className={classes.hrLine} />
                    <br />
                    <div className={classes.knowMoreSection}>
                        <div>Click here to know more about ADHD.</div>
                        <div>
                            <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                            <div>Ask SkidsGPT</div>
                        </div>
                    </div>
                </div>
            break;
        case 'anxiety':
            behaviourComponent =
                <div>

                </div>
            break;
        case 'conductDisorder':
            behaviourComponent =
                <div>

                </div>
            break;
        case 'defiantBehaviour':
            behaviourComponent =
                <div>

                </div>
            break;
        case 'dipression':
            behaviourComponent =
                <div>

                </div>
            break;
    }

    // -------------------------------------------------------------------------------------
    // ---------------------Dynamic content for learning disablities------------------------
    // -------------------------------------------------------------------------------------
    switch (learningDis) {
        case 'dyslexia':
            learningDisComponent =
                <div>
                    <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>Total questions</div>
                        <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                    </div>
                    <div className={classes.headingBold}>
                        <div>25</div>
                    </div>
                    <br />
                    <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>Score</div>
                        <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                    </div>
                    <div className={classes.headingBold}>
                        <div>6</div>
                        <div className={classes.ClickHereBlueBlack}>
                            <span>Click here</span>
                            <p>to view detailed report for Dyslexia assessment</p>
                        </div>
                    </div>
                    <div className={classes.docInterpretation}>
                        <img src={stethoscopeCheck} alt="" />
                        <div>
                            <div>Doctor Interpretation</div>
                            <div>Dyslexia Score is good and follow up after 6 months is suggested.</div>
                        </div>
                    </div>
                    <br />
                    <div className={classes.hrLine} />
                    <br />
                    <div className={classes.knowMoreSection}>
                        <div>Click here to know more about Dyslexia.</div>
                        <div>
                            <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                            <div>Ask SkidsGPT</div>
                        </div>
                    </div>
                </div>
            break;
        case 'dyscalculia':
            learningDisComponent =
                <div>
                    <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>Total questions</div>
                        <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                    </div>
                    <div className={classes.headingBold}>
                        <div>25</div>
                    </div>
                    <br />
                    <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>Score</div>
                        <CheckCircleIcon sx={{ fill: '#149457', fontSize: '17px' }} />
                    </div>
                    <div className={classes.headingBold}>
                        <div>6</div>
                        <div className={classes.ClickHereBlueBlack}>
                            <span>Click here</span>
                            <p>to view detailed report for Dyslexia assessment</p>
                        </div>
                    </div>
                    <div className={classes.docInterpretation}>
                        <img src={stethoscopeCheck} alt="" />
                        <div>
                            <div>Doctor Interpretation</div>
                            <div>ADHD Score is good and follow up after 6 months is suggested.</div>
                        </div>
                    </div>
                    <br />
                    <div className={classes.hrLine} />
                    <br />
                    <div className={classes.knowMoreSection}>
                        <div>Click here to know more about ADHD.</div>
                        <div>
                            <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                            <div>Ask SkidsGPT</div>
                        </div>
                    </div>
                </div>
            break;
        case 'dysgraphia':
            learningDisComponent =
                <div>

                </div>
    }

    const PhysicalScreeningView = () => {
        return (
            <div className={classes.fullbody_physical_report_container}>
                <div>
                    <div className={classes.leftBodyFit}>
                        <div className={classes.fullbody_physical_outer_container}>
                            <div className={classes.fullbody_physical_image_container}>
                                {
                                    organ === "Lung"
                                        ?
                                        <Lungspoints
                                            disabled={false}
                                            issue={!!lungsAssessment?.auscultationValidation?.[0]}
                                        />
                                        : organ === "Heart"
                                            ?
                                            <Heartpoint
                                                issue={(heartAssessment?.auscultationValidation?.[0]
                                                    || heartAssessment?.estimationValidation?.[0])}
                                                disabled={false}
                                            />
                                            :
                                            <>
                                                <Hair
                                                    top={"10px"}
                                                    right={"77px"}
                                                    scale={0.8}
                                                    disabled={screenData?.status === "PATIENT_ADDED"}
                                                    onClick={() => { setOrgan("Hair") }}
                                                    active={organ === "Hair" ? true : false}
                                                    issue={(hairAssessment?.dandruff
                                                        || hairAssessment?.pediculosis
                                                        || hairAssessment?.lossOfHair)}
                                                />
                                                <Eye
                                                    top={"44px"}
                                                    right={"88px"}
                                                    scale={0.8}
                                                    disabled={screenData?.status === "PATIENT_ADDED"}
                                                    onClick={() => { setOrgan("Eye") }}
                                                    active={organ === "Eye" ? true : false}
                                                    issue={!!eyeAssessment?.validation?.[0]}
                                                    whichEye={"left"}
                                                />
                                                <Eye
                                                    top={"44px"}
                                                    right={"64px"}
                                                    scale={0.8}
                                                    disabled={screenData?.status === "PATIENT_ADDED"}
                                                    onClick={() => { setOrgan("Eye") }}
                                                    active={organ === "Eye" ? true : false}
                                                    issue={!!eyeAssessment?.validation?.[0]}
                                                />
                                                <Ear
                                                    top={"52px"}
                                                    right={"106px"}
                                                    scale={0.8}
                                                    onClick={() => { setOrgan("Ear") }}
                                                    active={organ === "Ear" ? true : false}
                                                    issue={earAssessment?.leftEarValidation?.[0]}
                                                    whichEar={"left"}
                                                    disabled={screenData?.status === "PATIENT_ADDED"}
                                                />
                                                <Ear
                                                    top={"52px"}
                                                    right={"48px"}
                                                    scale={0.8}
                                                    onClick={() => { setOrgan("Ear") }}
                                                    active={organ === "Ear" ? true : false}
                                                    issue={earAssessment?.rightEarValidation?.[0]}
                                                    whichEar={"right"}
                                                    disabled={screenData?.status === "PATIENT_ADDED"}
                                                />
                                                <Dental
                                                    top={"80px"}
                                                    right={"77px"}
                                                    scale={0.8}
                                                    onClick={() => { setOrgan("Dental") }}
                                                    active={organ === "Dental" ? true : false}
                                                    issue={(getDMFTIndex(screenData?.dentalAssessment?.tooth?.[0].split(',')) > 0
                                                        || dentalAssessment?.decoloration
                                                        || dentalAssessment?.gingivitis
                                                        || dentalAssessment?.orthodontic)}
                                                    disabled={screenData?.status === "PATIENT_ADDED"}
                                                />
                                                <Cough
                                                    top={"62px"}
                                                    right={"77px"}
                                                    scale={0.8}
                                                    onClick={() => { setOrgan("Cough") }}
                                                    active={organ === "Cough" ? true : false}
                                                    issue={coughAssessment?.validation?.[0]}
                                                    disabled={screenData?.status === "PATIENT_ADDED"}
                                                />
                                                <Throat
                                                    top={"102px"}
                                                    right={"77px"}
                                                    scale={0.8}
                                                    ddisabled={screenData?.status === "PATIENT_ADDED"}
                                                    onClick={() => { setOrgan("Throat") }}
                                                    active={organ === "Throat" ? true : false}
                                                    issue={throatAssessment?.validation?.[0]}
                                                />
                                                <Lungs
                                                    top={"140px"}
                                                    right={"77px"}
                                                    scale={0.8}
                                                    disabled={screenData?.status === "PATIENT_ADDED"}
                                                    onClick={() => { setOrgan("Lung") }}
                                                    active={organ === "Lung" ? true : false}
                                                    issue={lungsAssessment?.auscultationValidation?.[0]}
                                                />
                                                <Heart
                                                    top={"160px"}
                                                    right={"55px"}
                                                    scale={0.8}
                                                    disabled={screenData?.status === "PATIENT_ADDED"}
                                                    onClick={() => { setOrgan("Heart") }}
                                                    active={organ === "Heart" ? true : false}
                                                    issue={(heartAssessment?.auscultationValidation?.[0]
                                                        || heartAssessment?.estimationValidation?.[0])}
                                                />
                                                <Stomach
                                                    top={"210px"}
                                                    right={"77px"}
                                                    scale={0.8}
                                                    disabled={screenData?.status === "PATIENT_ADDED"}
                                                    onClick={() => { setOrgan("Abdominal") }}
                                                    active={organ === "Abdominal" ? true : false}
                                                    issue={abdomenAssessment?.validation?.[0]}
                                                />
                                                <Skin
                                                    top={"250px"}
                                                    right={"150px"}
                                                    scale={0.8}
                                                    disabled={screenData?.status === "PATIENT_ADDED"}
                                                    onClick={() => { setOrgan("Skin") }}
                                                    active={organ === "Skin" ? true : false}
                                                    issue={skinAssessment?.validation?.[0]} />
                                            </>
                                }
                                {
                                    (screenData.student?.gender === 'Female') ?
                                        <GirlSvg age={"medium"} />
                                        :
                                        <BoySvg age={"medium"} />


                                }
                            </div>
                        </div>
                        <div className={classes.belowBody}>
                            <div>
                                <img src={Height} alt="" />
                                <div>{screenData?.vitalSignsAssessment?.height}<span>cms</span></div>
                                <div>Height</div>
                            </div>
                            <div>
                                <BmiScale BMI={calcBmi(screenData?.vitalSignsAssessment?.height, screenData?.vitalSignsAssessment?.weight)} />
                                <div>{calcBmi(screenData?.vitalSignsAssessment?.height, screenData?.vitalSignsAssessment?.weight)}<span>kg/m²</span></div>
                                <div>BMI</div>
                            </div>
                            <div>
                                <img src={WeightScale} alt="" />
                                <div>{screenData?.vitalSignsAssessment?.weight}<span>kgs</span></div>
                                <div>Weight</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.physicalRightCon}>
                    <div>
                        <div className={classes.exam_data_header} >Parameters to validate</div>
                        <div className={classes.organs_container}>
                            <div onClick={() => setOrgan('Vital')} className={organ === 'Vital' ? `${classes.organs_container_elements_active}` : ``}>Vital Signs</div>
                            <div onClick={() => setOrgan('Hair')} className={organ === 'Hair' ? `${classes.organs_container_elements_active}` : ``}>Hair</div>
                            <div onClick={() => setOrgan('Eye')} className={organ === 'Eye' ? `${classes.organs_container_elements_active}` : ``}>Eye</div>
                            <div onClick={() => setOrgan('Ear')} className={organ === 'Ear' ? `${classes.organs_container_elements_active}` : ``}>Ear</div>
                            <div onClick={() => setOrgan('Hearing')} className={organ === 'Hearing' ? `${classes.organs_container_elements_active}` : ``}>Hearing</div>
                            {/* <div onClick={() => setOrgan('Dental')} className={organ === 'Dental' ? `${classes.organs_container_elements_active}` : ``}>Dental</div> */}
                            <div onClick={() => setOrgan('Cough')} className={organ === 'Cough' ? `${classes.organs_container_elements_active}` : ``}>Cough</div>
                            <div onClick={() => setOrgan('Throat')} className={organ === 'Throat' ? `${classes.organs_container_elements_active}` : ``}>Throat</div>
                            <div onClick={() => setOrgan('Heart')} className={organ === 'Heart' ? `${classes.organs_container_elements_active}` : ``}>Heart</div>
                            <div onClick={() => setOrgan('Lung')} className={organ === 'Lung' ? `${classes.organs_container_elements_active}` : ``}>Lungs</div>
                            <div onClick={() => setOrgan('Abdominal')} className={organ === 'Abdominal' ? `${classes.organs_container_elements_active}` : ``}>Abdominal</div>
                            <div onClick={() => setOrgan('Skin')} className={organ === 'Skin' ? `${classes.organs_container_elements_active}` : ``}>Skin</div>
                            <div onClick={() => setOrgan('VHR')} className={organ === 'VHR' ? `${classes.organs_container_elements_active}` : ``}>VHR</div>
                            {/* <div onClick={(e) => {setOrgan('Cumulative Report'); }} className={organ === 'Cumulative Report' ? `${classes.organs_container_elements_active}` : ``}>Cumulative Report</div> */}
                        </div>
                        <div className={classes.heartrate_estimation}>
                            {/* <div>{estimationTitle}</div> */}
                            {componentEstimation}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const BehavioralScreeningView = () => {
        return (
            <div className={classes.behavioralMainCon}>
                <div>
                    Lorem ipsum dolor consectetur adipisicing.
                </div>
                <div>
                    <p>Questionnaire Data</p>
                    <div className={classes.organs_container}>
                        <div onClick={() => setBehaviour('adhd')} className={behaviour === 'adhd' ? `${classes.organs_container_elements_active}` : ``}>ADHD</div>
                        <div onClick={() => setBehaviour('autism')} className={behaviour === 'autism' ? `${classes.organs_container_elements_active}` : ``}>Autism</div>
                        <div onClick={() => setBehaviour('digitalDependency')} className={behaviour === 'digitalDependency' ? `${classes.organs_container_elements_active}` : ``}>Digital Dependency</div>
                        <div onClick={() => setBehaviour('anxiety')} className={behaviour === 'anxiety' ? `${classes.organs_container_elements_active}` : ``}>Anxiety</div>
                        <div onClick={() => setBehaviour('conductDisorder')} className={behaviour === 'conductDisorder' ? `${classes.organs_container_elements_active}` : ``}>Conduct Disorder</div>
                        <div onClick={() => setBehaviour('defiantBehaviour')} className={behaviour === 'defiantBehaviour' ? `${classes.organs_container_elements_active}` : ``}>Defiant Behaviour</div>
                        <div onClick={() => setBehaviour('dipression')} className={behaviour === 'dipression' ? `${classes.organs_container_elements_active}` : ``}>Dipression</div>
                    </div>
                    <div>
                        {
                            behaviourComponent
                        }
                    </div>
                </div>
            </div>
        )
    }



    const LearningDisabilities = () => {
        return (
            <div className={classes.behavioralMainCon}>
                <div>
                    Lorem ipsum dolor consectetur adipisicing.
                </div>
                <div>
                    <p>Questionnaire Data</p>
                    <div className={classes.organs_container}>
                        <div onClick={() => setLearningDis('dyslexia')} className={learningDis === 'dyslexia' ? `${classes.organs_container_elements_active}` : ``}>Dyslexia</div>
                        <div onClick={() => setLearningDis('dyscalculia')} className={learningDis === 'dyscalculia' ? `${classes.organs_container_elements_active}` : ``}>Dyscalculia</div>
                        <div onClick={() => setLearningDis('dysgraphia')} className={learningDis === 'dysgraphia' ? `${classes.organs_container_elements_active}` : ``}>Dysgraphia</div>
                    </div>
                    <div>
                        {
                            learningDisComponent
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={classes.main_div}>
            <BreadCrumbs />
            <div className={classes.studentDetails}>
                <div className={classes.avtarSection}>
                    <Avatar {...stringAvatar(screenData?.student?.name)} sx={{ width: 64, height: 64, fontSize: 48, fontWeight: "bold" }} />
                    <div className={classes.student_details_information_name}>{screenData?.student?.name}</div>
                </div>
                <div>
                    <div>
                        <p>Age</p>
                        <p>{screenData?.student?.age} Yrs</p>
                    </div>
                    <div>
                        <p>Gender</p>
                        <p>{screenData?.student?.gender}</p>
                    </div>
                    <div>
                        <p>Class</p>
                        <p>{numberTh(screenData?.student?.class)} {screenData?.student?.section}</p>
                    </div>
                    <div>
                        <p>Screening Date {"&"} Time</p>
                        <p>{toDateString(screenData?.student?.screeningDate)}</p>
                    </div>
                    <div>
                        <p>Due Date</p>
                        <p>{toDateString(location?.state?.validationDueDate)}</p>
                    </div>
                    <div>
                        <Checkanimated active={(localDone || validationData?.physicalScreeningDate) ? true : false} onClick={handleValidationComplete} />
                    </div>
                </div>
            </div>

            {/* <div className={classes.screeningType}> */}
            <div className={classes.screening_container}>
                <div
                    className={activeScreeningView === 'physical' ? classes.screening_container_active : classes.screening_container_inactive}
                    onClick={() => { setActiveScreeningView('physical') }}>
                    <p className={activeScreeningView === 'physical' ? `` : classes.typeText} >Physical Screening</p>

                </div>
                <div
                    className={activeScreeningView === 'behavioral' ? classes.screening_container_active : classes.screening_container_inactive}
                    onClick={() => { setActiveScreeningView('physical') }}>
                    <p className={activeScreeningView === 'behavioral' ? `` : classes.typeText} >Behavioral Screening</p>
                </div>
                <div
                    className={activeScreeningView === 'learningDis' ? classes.screening_container_active : classes.screening_container_inactive}
                    onClick={() => { setActiveScreeningView('physical') }}>
                    <p className={activeScreeningView === 'learningDis' ? `` : classes.typeText} >Learning Disabilities</p>
                </div>
            </div>
            {/* </div> */}
            <div className={classes.container}>
                <div>
                    {
                        activeScreeningView === 'physical'
                            ? PhysicalScreeningView()
                            : activeScreeningView === 'behavioral'
                                ? BehavioralScreeningView()
                                : LearningDisabilities()
                    }
                </div>
            </div>
        </div >
    );
}


export default DoctorValidation;


// if (organ === 'eyeAssessment') {
//     docIssues = eyeAssessment?.validation?.[0]?.split(",");
// } else if (organ === 'leftEarAssessment') {
//     docIssues = earAssessment?.leftEarValidation?.[0]?.split(",");
// } else if (organ === 'rightEarAssessment') {
//     docIssues = earAssessment?.rightEarValidation?.[0]?.split(",");
// } else if (organ === 'hearingAssessment') {
//     docIssues = hearingAssessment?.validation?.[0]?.split(",");
// } else if (organ === 'throatAssessment') {
//     docIssues = throatAssessment?.validation?.[0]?.split(",");
// } else if (organ === 'heartEstimation') {
//     docIssues = heartAssessment?.estimationValidation?.[0]?.split(",");
// } else if (organ === "heartAuscultation") {
//     docIssues = heartAssessment?.auscultationValidation?.[0]?.split(",");
// } else if (organ === "lungsAuscultation") {
//     docIssues = lungsAssessment?.auscultationValidation?.[0]?.split(",");
// } else if (organ === "abdomenAssessment") {
//     docIssues = abdomenAssessment?.validation?.[0]?.split(",");
// } else if (organ === "skinAssessment") {
//     docIssues = skinAssessment?.validation?.[0]?.split(",");
// }
// if (e.target.checked) {
//     docIssues.push(issueType);
//
// } else {
//     let tempArray = docIssues.filter((issue) => !(issue === issueType));
//     docIssues = tempArray;
//
// }