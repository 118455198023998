import React, { useEffect } from "react";
import classes from "./BlogDetail.module.css";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import blogReducer from "../../redux/reducers/blog.reducer";

const BlogDetail = () => {
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const getBlogById = blogReducer.actions.getBlogByIdRequest;
  const currentBlog = useSelector((state) => state.blogReducer.currentBlog);

  useEffect(() => {
    getBlogByBlogId();
  }, []);

  const getBlogByBlogId = () => {
    const payload = { blogId: id };
    dispatch(getBlogById(payload));
  };

  return (
    <div className={classes.blog_info}>
      <div className={classes.blog_header}>
        <div className={classes.header}>
          <BreadCrumbs />
        </div>
      </div>
      <div className={classes.blog_container}>
        <div className={classes.blog_header}>
          <div>
            <div>
              <h4>
                Title: <span>{currentBlog?.title}</span>
              </h4>
            </div>
            <div className={classes.blog_metadata}>
              <div>
                <h6>
                  Author Name: <span>{currentBlog?.author}</span>
                </h6>
              </div>
              <div>
                <h6>
                  Category: <span>{currentBlog?.category}</span>
                </h6>
              </div>
              <div>
                <h6>
                  Status: <span>{currentBlog?.status}</span>
                </h6>
              </div>
            </div>
            {currentBlog?.thumbnail && <img
              style={{
                width: "100%",
                height: "500px",
                objectFit: "cover",
                margin: "10px 0",
              }}
              src={currentBlog?.thumbnail}
              alt="blog image"
            />}
            <p dangerouslySetInnerHTML={{ __html: currentBlog?.description }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
