import InitialStore from "../store/InitialStore";
import { createSlice } from "@reduxjs/toolkit";

export const getEventsRequest = (state, action) => {
    state.data = [];
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
};

export const getEventsSuccess = (state, action) => {
    state.data = action.payload.data;
    state.loading = false;
}

export const getEventsFail = (state, action) => {
    state.error = true;
    state.loading = false
};

export const getEventMapsRequest = (state, action) => {
    state.eventMapData = [];
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
};

export const getEventMapsSuccess = (state, action) => {
    
    state.eventMapData = action.payload.data;
    state.loading = false;
}

export const getEventMapsFail = (state, action) => {
    state.error = true;
    state.loading = false
};

export const getClassesRequest = (state, action) => {
    state.classList = {};
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
};

export const getClassesSuccess = (state, action) => {
    state.classList = action.payload.data;
    state.loading = false;
}

export const getClassesFail = (state, action) => {
    state.error = true;
    state.loading = false
};

export const getEventsBySchoolIdRequest = (state, action) => {
    state.data = [];
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
}
export const createEventRequest = (state, action) => {
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
};

export const createEventSuccess = (state, action) => {
    state.loading = false;
}

export const createEventFail = (state, action) => {
    state.error = true;
    state.loading = false
};

export const createEventMapRequest = (state, action) => {
    state.error = false;
    state.errorMessage = '';
    state.loading = true;
}

export default createSlice({
    name: "eventReducer",
    initialState: InitialStore.events,
    reducers: {
        getEventsRequest,
        getEventsSuccess,
        getEventsFail,
        getEventsBySchoolIdRequest,
        createEventRequest,
        createEventSuccess,
        createEventFail,
        createEventMapRequest,
        getClassesRequest,
        getClassesSuccess,
        getClassesFail,
        getEventMapsRequest,
        getEventMapsSuccess,
        getEventMapsFail
    },
});