import { call, put, takeLatest } from "redux-saga/effects";
import ApiServices from "../../graphqlApiServices";
import blogReducer from "../reducers/blog.reducer";


const { blog } = ApiServices;

const {
    createBlog,
    getBlogs,
    updateBlogStatus,
    updateBlog,
    getBlogById
} = blog

export function* watchBlogRequest() {
    yield takeLatest(blogReducer.actions.createBlogRequest, createBlogSaga);
    yield takeLatest(blogReducer.actions.getBlogsRequest, getBlogsSaga);
    yield takeLatest(blogReducer.actions.getBlogByIdRequest, getBlogByIdSaga);
    // yield takeLatest(supportReducer.actions.getSupportTicketByIdRequest, getSupportTicketByIdSaga);
    yield takeLatest(blogReducer.actions.updateBlogStatusRequest, updateBlogStatusSaga);
    yield takeLatest(blogReducer.actions.updateBlogRequest, updateBlogSaga);
}

export function* createBlogSaga(payload) {
    try {
        const response = yield call(createBlog, payload);
        const data = response?.data.createBlog;
        yield put(blogReducer.actions.createBlogSuccess(data))
    } catch (error) {
        console.log(error, 'error');
        if (error?.data?.createBlog.length > 0) {
            const data = error?.data.createBlog;
            yield put(blogReducer.actions.createBlogFail(data))
        }
        else {
            yield put(blogReducer.actions.createBlogFail({ error: error.errors }))
        }
    }
}

export function* getBlogsSaga(payload) {
    try {
        const response = yield call(getBlogs, payload);
        const data = response?.data.getBlogs;
        yield put(blogReducer.actions.getBlogsSuccess(data))
    } catch (error) {
        if (error?.data.getBlogs.length > 0) {
            const data = error?.data.getBlogs;
            yield put(blogReducer.actions.getBlogsFail(data))
        }
        else {
            yield put(blogReducer.actions.getBlogsFail({ error: error.errors }))
        }
    }
}


export function* getBlogByIdSaga(payload) {
    try {
        const response = yield call(getBlogById, payload);
        const data = response?.data.getBlogById;
        yield put(blogReducer.actions.getBlogByIdSuccess(data))
    } catch (error) {
        if (error?.data.getBlogById.length > 0) {
            const data = error?.data.getBlogById;
            yield put(blogReducer.actions.getBlogByIdFail(data))
        }
        else {
            yield put(blogReducer.actions.getBlogByIdFail({ error: error.errors }))
        }
    }
}

export function* updateBlogStatusSaga(payload) {
    try {
        const response = yield call(updateBlogStatus, payload);
        const data = response?.data.updateBlogStatus;
        yield put(blogReducer.actions.updateBlogStatusSuccess(data))
    } catch (error) {
        console.log(error, 'error')
        if (error?.data.updateBlogStatus.length > 0) {
            const data = error?.data.updateBlogStatus;
            yield put(blogReducer.actions.updateBlogStatusFail(data))
        }
        else {
            yield put(blogReducer.actions.updateBlogStatusFail({ error: error.errors }))
        }
    }
}

export function* updateBlogSaga(payload) {
    try {
        const response = yield call(updateBlog, payload);
        const data = response?.data.updateBlog;
        yield put(blogReducer.actions.updateBlogSuccess(data))
    } catch (error) {
        console.log(error, 'error')
        if (error?.data.updateBlog.length > 0) {
            const data = error?.data.updateBlog;
            yield put(blogReducer.actions.updateBlogFail(data))
        }
        else {
            yield put(blogReducer.actions.updateBlogFail({ error: error.errors }))
        }
    }
}