import React, { useEffect, useState } from 'react'
import classes from './UploadFile.module.css'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Box from '@mui/material/Box';

function UploadFile({ uploadFile, setUploadFile, fileType }) {

    const handleOnChange = (e) => {
        e.preventDefault()
        console.log(e.target.files);
        setUploadFile(e.target.files[0])
        console.log(e.target.files[0])
        e.target.value = ''
    }
    const handleDragOver = e => {
        e.preventDefault();
    };
    const handleDrop = e => {
        e.preventDefault();
        setUploadFile(e.dataTransfer.files[0])
    };

    return (
        <>
            <Box sx={{ textAlign: "left" }}>
                <div className={classes.divContainer}>
                    <form className={classes.formContainer}>
                        <div className={classes.uploadFilesContainer}>
                            <div className={classes.dragFileArea} onDrop={handleDrop} onDragOver={handleDragOver}>
                                <div className={classes.uploadIconDiv}>
                                    <span className={`${"material-icons-outlined"}, ${classes.uploadIcon}`}> <FileUploadOutlinedIcon style={{ width: "20px", height: "20px" }} /> </span>
                                    <span style={{ fontSize: 16 }}>Upload file</span>
                                </div>
                                <h3 className={classes.dynamicMessage}> Drag & drop file</h3>
                                <span className={classes.or} >Or</span>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <label className={classes.label}>
                                        <span className={classes.browseFiles}>
                                            <input type="file" accept={fileType} className={classes.defaultFileInput} onChange={handleOnChange} />
                                            <span className={classes.browseFilesText}>Browse</span>
                                        </span>
                                    </label>
                                    <span className={classes.onlyFiles}>{fileType} format only</span>
                                </div>
                            </div>
                            {uploadFile?.name &&
                                <span className={classes.fileName}>{uploadFile?.name}</span>
                            }

                            <span className={classes.cannotUploadMessage}> <span className="material-icons-outlined">error</span> Please select a file first <span className={`material-icons-outlined ${classes.cancelAlertButton}`}>cancel</span> </span>
                            {/* {file &&
                                    <p className={classes.fileBlock}>
                                        <AttachFileOutlined style={{ fill: "white" }} />
                                        {`${file?.name} (${(file?.size / 1024).toFixed(0)}kb)`}
                                        <DeleteOutlined style={{ fill: "white", cursor: "pointer" }} onClick={"deleteFile"} />
                                    </p>
                                } */}
                        </div>
                    </form>
                </div>
            </Box>
        </>
    )
}


export default UploadFile