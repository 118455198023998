import { Buffer } from 'buffer';

export function compareDate(from, to) {



    if (!from && !to || from === to) {
        return true
    }

    from = new Date(from);
    to = new Date(to);
    if (from < to || from === to) {
        return true;
    } else {
        return false;
    }
}




export function calculateAge(dobString) {
    const birthday = new Date(dobString);
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function truncateString(text, maxLength) {
    if (text.length > maxLength) {
        return text.substr(0, maxLength) + "...";
    }
    return text;
}

export function capitalizeFirstLetter(str) {
    let a = str.split(" ")
    let b = []
    for (let i = 0; i < a.length; i++) {
        // 
        if (a[i]) {
            b.push(a[i].charAt(0).toUpperCase() + a[i].slice(1))
        }
    }
    return b.join(" ")
}


export function numberTh(num) {
    if (num === 'NURSERY' || num === "LKG" || num === "UKG" || num === "School") return num;
    if (!num) return;
    let number = parseInt(num)
    if (number === 1) {
        return number + "st"
    } else if (number === 2) {
        return number + "nd"
    } else if (number === 3) {
        return number + "rd"
    } else {
        return number + "th"
    }
}

export function toDateString(dateString) {
    if (!dateString) return;
    return new Date(dateString).toDateString()
}


export function checkDentalCondition(teethArray, quadrant) {
    if (teethArray) {
        let QTeeths = teethArray[0]?.split(',')?.filter((teeth) => teeth?.includes(quadrant));

        return QTeeths?.every((qteeth) => qteeth?.includes("N"))
    }
}

export function checkReportCondition(x) {
    return x ? "Present" : "Absent"
}

export function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string?.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
}

export function stringAvatar(name) {
    if (!name) return;
    return {
        sx: {
            bgcolor: stringToColor(name),
            width: 12,
            height: 12,
            padding: 2
        },
        children: `${name[0]}`,
    };
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const Awsdateformatter = (date) => {
    let awsdate = new Date(date)
        .toISOString()
        .slice(0, 10);
    return awsdate;
}

export const getDMFTIndex = (array) => {
    if (!array) {
        return 0;
    }
    let dmftIndex = 0;
    array?.forEach(element => {
        if (element.slice(-1) !== "N") {
            dmftIndex += 1;
        }
    });
    return dmftIndex;
}


export const ImgDataToBuffer = (imageData) => {
    const buffer = Buffer.from(imageData).toString('base64');
    return buffer;
}

export const textFormat = (text) => {
    text = text?.split("_")
    let a = ""
    for (let i = 0; i < text?.length; i++) {
        const element = text[i];
        const x = element.slice(0, 1).toUpperCase() + element.slice(1)?.toLowerCase()
        if (i === 0) {
            a = a + x
        } else {
            a = a + " " + x;
        }
    }
    return a
}

export const getAge = (x) => {
    var today = new Date();
    var birthDate = new Date(x);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const calcBmi = (h, w) => {
    if (!h || !w) return "Not available";
    if (h === 'null' || w === 'null') return "Not available"
    let height = h / 100;
    let weight = w;
    let bmi = weight / (height * height);
    return bmi.toFixed(1);
}

export const findValidation = (obj, issue) => {
    const x = obj?.filter((x) => x?.issue === issue)
    if (x?.length > 0) {
        return "Present"
    } else {
        return "Absent"
    }
}

export const findInterpetetion = (obj, issue) => {
    const x = obj?.filter((x) => x?.issue === issue)
    if (x?.length > 0) {
        return x[0]?.interpretation
    } else {
        return "Normal"
    }
}
export const getRiskValue = (number) => {
    if (number >= 0.3 && number <= 0.6) {
        return { value: "Medium Risk", color: '#E09F26' }; // Yellow
    } else if (number > 0.6 && number <= 1) {
        return { value: "High Risk", color: '#EF1210' }; // Red
    } else if (number >= 0 && number < 0.3) {
        return { value: "Low Risk", color: '#1B9446' }; // Green
    } else {
        return "Invalid Number"; // Handle numbers outside the specified ranges
    }
}
export const getRisk = (packageName, riskFactor) => {
    if (packageName || riskFactor) {
        // const a = JSON.parse(row[row?.package[0]])
        // let riskFactor = row?.data?.riskFactor;
        if (packageName === "ADHD" || packageName === "AUTISM" || packageName === "DEPRESSION") {
            if (riskFactor < 0.33) {
                return <span style={{ color: "#149457" }}>Low Risk</span>
            } else if (riskFactor >= 0.33 && riskFactor < 0.66) {
                return <span style={{ color: "#DD8D16" }}>Medium Risk</span>
            } else if (riskFactor >= 0.66) {
                return <span style={{ color: "#D95A59" }}>High Risk</span>
            }
        }
        else {
            if (riskFactor <= 0.50) {
                return <span style={{ color: "#149457" }}>Low Risk</span>
                // } else if (riskFactor >= 0.33 && riskFactor < 0.66) {
                //   return <span style={{ color: "#DD8D16" }}>Medium Risk</span>
            } else if (riskFactor > 0.50) {
                return <span style={{ color: "#D95A59" }}>High Risk</span>
            }
        }
    }
}

export const moveXAxis = (age) => {
    return (age - 5) * 55;
}

const boy_5to18 = {
    "2": {
        "ub": 18.2,
        "lb": 14.8
    }, 
    "3": {
        "ub": 17.4,
        "lb": 14.4
    },
    "4": {
        "ub": 17,
        "lb": 14
    },
    "5": {
        "ub": 15.7,
        "lb": 12.1
    },
    "6": {
        "ub": 16,
        "lb": 12.2
    },
    "7": {
        "ub": 16.3,
        "lb": 12.3
    },
    "8": {
        "ub": 16.7,
        "lb": 12.5
    },
    "9": {
        "ub": 17.3,
        "lb": 12.7
    },
    "10": {
        "ub": 18,
        "lb": 12.9
    },
    "11": {
        "ub": 18.7,
        "lb": 13.1
    },
    "12": {
        "ub": 19.9,
        "lb": 13.3
    },
    "13": {
        "ub": 20.2,
        "lb": 13.6
    },
    "14": {
        "ub": 20.8,
        "lb": 13.8
    },
    "15": {
        "ub": 21.4,
        "lb": 14.2
    },
    "16": {
        "ub": 22,
        "lb": 14.6
    },
    "17": {
        "ub": 22.6,
        "lb": 15.1
    },
    "18": {
        "ub": 23.2,
        "lb": 15.6
    }
}

const girl_5to18 = {
    "2": {
        "ub": 18,
        "lb": 14.4
    }, 
    "3": {
        "ub": 17.2,
        "lb": 14
    },
    "4": {
        "ub": 16.8,
        "lb": 13.8
    },
    "5": {
        "ub": 15.5,
        "lb": 11.9
    },
    "6": {
        "ub": 15.9,
        "lb": 12
    },
    "7": {
        "ub": 16.4,
        "lb": 12.1
    },
    "8": {
        "ub": 16.9,
        "lb": 12.3
    },
    "9": {
        "ub": 17.6,
        "lb": 12.4
    },
    "10": {
        "ub": 18.4,
        "lb": 12.7
    },
    "11": {
        "ub": 19.3,
        "lb": 13
    },
    "12": {
        "ub": 20.2,
        "lb": 13.4
    },
    "13": {
        "ub": 21.1,
        "lb": 13.9
    },
    "14": {
        "ub": 21.8,
        "lb": 14.3
    },
    "15": {
        "ub": 22.3,
        "lb": 14.7
    },
    "16": {
        "ub": 22.6,
        "lb": 15
    },
    "17": {
        "ub": 22.9,
        "lb": 15.4
    },
    "18": {
        "ub": 23.2,
        "lb": 15.7
    }
}

export const getBmiRange = (age, gender) => {
    if (gender === "Male") {
        if (age > 18) {
            age = 18;
        }
        if (age < 2) {
            age = 2;
        }
        return boy_5to18[age];
    } else if ("Female") {
        if (age > 18) {
            age = 18;
        }
        if (age < 2) {
            age = 2;
        }
        return girl_5to18[age];
    }
}

export const bmiValidatedInterpretation = (bmi, age, gender) => {
    if (!bmi || !age) return;
    let interpretations = {
        // high: "BMI appears higher than recommended range. Please consult your paediatrician and nutritionist.",
        high: "BMI appears higher than recommended range.",
        // low: "BMI appears lower than recommended range. Please consult your paediatrician and nutritionist."
        low: "BMI appears lower than recommended range."
    };

    if (gender === "Male") {
        if (age > 18) {
            age = 18;
        }
        if (age < 2) {
            age = 2;
        }
        if (bmi < boy_5to18[age].lb) {
            return interpretations.low;
        } else if (bmi > boy_5to18[age].ub) {
            return interpretations.high;
        } else {
            return "Bmi is in normal range"
        }
    } else if ("Female") {
        if (age > 18) {
            age = 18;
        }
        if (age < 2) {
            age = 2;
        }
        if (bmi < girl_5to18[age].lb) {
            return interpretations.low;
        } else if (bmi > girl_5to18[age].ub) {
            return interpretations.high;
        } else {
            return "Bmi is in normal range"
        }
    }
}