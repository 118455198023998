import { Avatar } from "@mui/material";
import React, { useState } from "react";
import classes from "./NurseStudentRow.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import RowPopup from "../../common/TableComponents/RowPopup";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import moment from "moment";

export default function NurseStudentRows({data}) {
  const navigate = useNavigate();
  const location = useLocation();

  // 

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)} `.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 12,
        height: 12,
        padding: 2
      },
      children: `${name[0]} `,
    };
  }
  const handleViewHistory = () => {
    navigate('/infirmary/nurse/student/health', { state : { data : data, breadCrumbs : location.state ? 
      [...location.state?.breadCrumbs, "Health History"] : ["Health History"]}})
  }

  return (
    <>
      <div className={classes.studentRow_container}>

        {/* Name and Avatar Container */}
        <div>
          <div>
            <Avatar {...stringAvatar(`${data?.firstName} ${data?.lastName}`)} />
          </div>

          <div>
            <p>{`${data?.firstName} ${data?.lastName}`}</p>
          </div>
        </div>

        <div>
          <p>{`${data?.class} ${data?.section}`}</p>
        </div>

        <div>
          <p>{data?.gender}</p>
        </div>
        {/* Email Container */}
        <div className = {classes.last_infirmary_visit_container}>
          {data?.logs?.length ? (
                    <>
                    <p>{`${moment(data?.logs[data?.logs?.length - 1]?.createdAt).format("DD-MM-YYYY")}, ${moment(data?.logs[data?.logs?.length - 1]?.createdAt).format("HH:MM A")}`}</p>
                    <p onClick={() => handleViewHistory()}>View History</p>
                    </>
          ) : 
          (
            <>
            <p style={{cursor : "auto", color : "#001028"}}>-</p>
            </>

          )}

        </div>
        <div>
          <p>{data?.logs ? data?.logs[data?.logs?.length - 1]?.situation : "-"}</p>
        </div>

        <div className = {classes.addlog_container}>
                <AddCircleRoundedIcon sx = {{ height : "16px", weight : "16px", fill : "#66707E"}}/>
                <p onClick={() => navigate('/infirmary/nurse/student/addlog', { state : { data : [data], 
                breadCrumbs : location.state ? 
                [...location.state?.breadCrumbs, "Add Log"] : ["Add Log"]}})}>Add Log</p>
            </div>


        {/* Action Container */}
        <div title="school info" style={{ cursor: 'pointer', position: "relative" }}>
          <div className={classes.infoHover}>
            <p>...</p>
            {/* <div className={classes.popOnHover}>
              <RowPopup navLink={getSchoolInfo} />
            </div> */}
          </div>
        </div>
        </div>
    </>
  );
}