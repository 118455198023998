import React from 'react'
import * as d3 from 'd3';
import { useEffect } from 'react'
import "./BmiChart.css"
import { MoveDown } from '@mui/icons-material';
import { moveXAxis } from '../../../utils/utility';

const BmiChart2point0 = () => {
    var margin = {
        top: 20,
        right: 80,
        bottom: 30,
        left: 50
    };
    // date parser
    // var parseDate = d3.timeParse("%Y%m%d");


    var w = 900 - margin.left - margin.right;
    var h = 500 - margin.top - margin.bottom;

    var scaleX = d3.scaleLinear()
        .range([0, w]);

    var scaleY = d3.scaleLinear()
        .range([h, 0]);

    useEffect(() => {

        var color = d3.scaleOrdinal(["#EC0606", "#F68B66", "#149457", "#F68B66", "#EC0606"]);

        var xAxis = d3.axisBottom()
            .scale(scaleX);


        var yAxis = d3.axisLeft()
            .scale(scaleY)

        var line = d3.line()
            .x(function (d) {
                return scaleX(d.date)
            })
            .y(function (d) {
                return scaleY(d.temperature)
            })
            .curve(d3.curveBasis);

        var svg = d3.select("#d3Container").append("svg")
            .attr("width", w + margin.left + margin.right)
            .attr("height", h + margin.top + margin.bottom)
            // .style("background-color","lightGreen")
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")


        var data = d3.tsvParse(myData);
        console.log("data is", data)

        color.domain(Object.keys(data[0]).filter(function (key) {
            console.log("key", key)
            return key !== "date";

        }))


        // data.forEach(function (d) {
        //     d.date = parseDate(d.date);
        // });

        var cities = color.domain().map(function (name) {

            return {
                name: name,
                values: data.map(function (d) {
                    return {
                        date: d.date,
                        temperature: +d[name]
                    };
                })
            };
        });

        scaleX.domain([5, 19]);
        console.log(d3.extent(data, function (d) {
            return d.date;
        }));
        scaleY.domain([d3.min(cities, function (c) {
            return d3.min(c.values, function (v) {
                return v.temperature
            })
        }), d3.max(cities, function (c) {
            return d3.max(c.values, function (v) {
                return v.temperature;
            })
        })])

        console.log("cities", cities);


        // legend show
        // var legend = svg.selectAll("g")
        //     .data(cities)
        //     .enter()
        //     .append("g")
        //     .attr("class", "legend");

        // legend.append("rect")
        //     .attr("x", w - 20)
        //     .attr("y", function (d, i) {
        //         return i * 20;
        //     })
        //     .attr("width", 10)
        //     .attr("height", 10)
        //     .style("fill", function (d) {
        //         return color(d.name);
        //     });

        // legend.append("text")
        //     .attr("x", w - 8)
        //     .attr("y", function (d, i) {
        //         return (i * 20) + 9;
        //     })
        //     .text(function (d) {
        //         return d.name;
        //     });


        // 55 px for x-axix movement
        // this px for y-axis movement
        let g = svg.append('g');
        g.append('circle')
            .attr('cx', moveXAxis(5))
            .attr('cy', h - 0)
            .attr('r', 7)
            .style('fill', 'black')
            .append("title")
            .text("You are here");

        svg.append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + h + ")")
            .call(xAxis);

        svg.append("g")
            .attr("class", "y axis")
            .call(yAxis)
            .append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 6)
            .attr("dy", ".71em")
            .style("text-anchor", "end")
            .style("fill", "black")
            .text("BMI (Kg/m2)");

        var city = svg.selectAll(".city")
            .data(cities)
            .enter().append("g")
            .attr("class", "city");

        city.append("path")
            .attr("class", "line")
            .attr("d", function (d) {
                return line(d.values);
            })
            .style("stroke", function (d) {
                return color(d.name)
            });

        city.append("text")
            .datum(function (d) {

                return {
                    name: d.name,
                    value: d.values[d.values.length - 1]
                };
            })
            .attr("transform", function (d) {
                return "translate(" + scaleX(d.value.date) + "," + scaleY(d.value.temperature) + ")";
            })
            .attr("x", 3)
            .attr("dy", ".35")
            .text(function (d) {
                return d.name;
            });


        // var mouseG = svg.append("g") // this the black vertical line to folow mouse
        //     .attr("class", "mouse-over-effects");

        // mouseG.append("path")
        //     .attr("class", "mouse-line")
        //     .style("stroke", "black")
        //     .style("stroke-width", "1px")
        //     .style("opacity", "0");

        // var lines = document.getElementsByClassName("line");
        // var mousePerLine = mouseG.selectAll(".mouse-per-line")
        //     .data(cities)
        //     .enter()
        //     .append("g")
        //     .attr("class", "mouse-per-line");

        // mousePerLine.append("circle")
        //     .attr("r", 7)
        //     .style("stroke", function (d) {
        //         return color(d.name);
        //     })
        //     .style("fill", "none")
        //     .style("stroke-width", "1px")
        //     .style("opacity", "0");

        // mousePerLine.append("text")
        //     .attr("transform", "translate(10,3)");

        // mouseG.append("rect")
        //     .attr("width", w)
        //     .attr("height", h)
        //     .attr("fill", "none")
        //     .attr("pointer-events", "all")
        //     .on("mouseout", function () {
        //         d3.select(".mouse-line").style("opacity", "0");
        //         d3.selectAll(".mouse-per-line circle").style("opacity", "0");
        //         d3.selectAll(".mouse-per-line text").style("opacity", "0")
        //     })
        //     .on("mouseover", function () {
        //         d3.select(".mouse-line").style("opacity", "1");
        //         d3.selectAll(".mouse-per-line circle").style("opacity", "1");
        //         d3.selectAll(".mouse-per-line text").style("opacity", "1")

        //     })
        //     .on("mousemove", function () {

        //         var mouse = d3.pointer(this);
        //         console.log("Mouse:", mouse);
        //         d3.select(".mouse-line")
        //             .attr("d", function () {
        //                 var d = "M" + mouse[0] + "," + h;
        //                 d += " " + mouse[0] + "," + 0;
        //                 return d;
        //             })
        //         // .attr("d",function(){
        //         //   var d = "M" + w +"," + mouse[1];
        //         //   d+=" " +0 + "," + mouse[1];
        //         //   return d;
        //         // });

        //         d3.selectAll(".mouse-per-line")
        //             .attr("transform", function (d, i) {
        //                 console.log(w / (mouse[0]));
        //                 var xDate = scaleX.invert(mouse[0]),
        //                     bisect = d3.bisector(function (d) { return d.date; }).right;
        //                 var idx = bisect(d.values, xDate);
        //                 console.log("xDate:", xDate);
        //                 console.log("bisect", bisect);
        //                 console.log("idx:", idx)

        //                 var beginning = 0,
        //                     end = lines[i].getTotalLength(),
        //                     target = null;

        //                 console.log("end", end);

        //                 while (true) {
        //                     target = Math.floor((beginning + end) / 2)
        //                     console.log("Target:", target);
        //                     var pos = lines[i].getPointAtLength(target);
        //                     console.log("Position", pos.y);
        //                     console.log("What is the position here:", pos)
        //                     if ((target === end || target == beginning) && pos.x !== mouse[0]) {
        //                         break;
        //                     }

        //                     if (pos.x > mouse[0]) end = target;
        //                     else if (pos.x < mouse[0]) beginning = target;
        //                     else break; // position found
        //                 }
        //                 d3.select(this).select("text")
        //                     .text(scaleY.invert(pos.y).toFixed(1))
        //                     .attr("fill", function (d) {
        //                         return color(d.name)
        //                     });
        //                 return "translate(" + mouse[0] + "," + pos.y + ")";

        //             });



        //     });
    }, [])



    var myData = "date	P3	P15	P50	P85	P97\n\
5	13.1	14.0	15.2	16.7	18.0\n\
5.5	13.1	14.0	15.2	16.7	18.1\n\
6	13.1	14.0	15.3	16.8	18.2\n\
6.5	13.2	14.0	15.3	16.9	18.5\n\
7	13.2	14.1	15.4	17.1	18.7\n\
7.5	13.3	14.2	15.6	17.2	19.0\n\
8	13.4	14.3	15.7	17.5	19.3\n\
8.5	13.5	14.4	15.8	17.7	19.7\n\
9	13.6	14.5	16.0	17.9	20.1\n\
9.5	13.7	14.7	16.2	18.2	20.5\n\
10	13.8	14.8	16.4	18.5	20.9\n\
10.5	14.0	15.0	16.6	18.9	21.4\n\
11	14.1	15.2	16.9	19.2	21.9\n\
11.5	14.3	15.4	17.2	19.6	22.5\n\
12	14.5	15.7	17.5	20.0	23.0\n\
12.5	14.8	16.0	17.8	20.4	23.6\n\
13	15.0	16.3	18.2	20.9	24.1\n\
13.5	15.3	16.6	18.6	21.4	24.7\n\
14	15.6	16.9	19.0	21.8	25.3\n\
14.5	15.9	17.2	19.3	22.3	25.8\n\
15	16.1	17.5	19.7	22.8	26.3\n\
15.5	16.4	17.8	20.1	23.2	26.8\n\
16	16.6	18.1	20.4	23.6	27.2\n\
16.5	16.9	18.4	20.8	24.0	27.6\n\
17	17.1	18.7	21.1	24.4	28.0\n\
17.5	17.3	18.9	21.4	24.7	28.3\n\
18	17.4	19.1	21.7	25.0	28.6\n\
18.5	17.6	19.3	21.9	25.3	28.9\n\
19	17.7	19.5	22.1	25.5	29.1\n";



    // original graph: https://bl.ocks.org/larsenmtl/e3b8b7c2ca4787f77d78f58d41c3da91




    return (
        <div id='d3Container'></div>
    )
}

export default BmiChart2point0