import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./PrincipalDashboard.module.css";
// import { InfoCard } from "../../DashboardSuperAdmin/InfoCard";
import { Link } from "react-router-dom";
import dashboardReducer from "../../../redux/reducers/dashboard.reducer";
import eventsgreen from "../../../assets/images/eventsgreen.svg";
import schoolsblue from "../../../assets/images/schoolblue.svg";
import screeningsred from "../../../assets/images/screeningsred.svg";
import screeningsyellow from "../../../assets/images/screeningsyellow.svg";
import clinicsviolet from "../../../assets/images/clinicsviolet.svg";
import devicelogo from "../../../assets/images/devicelogo.svg";
import InputDropdown from "../../common/InputDashboard/InputDropdown";
import Stepper from "@mui/material/Stepper";
import CheckIcon from "@mui/icons-material/Check";
import GroupIcon from "@mui/icons-material/Group";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { styled } from "@mui/material/styles";
import teachergrey from "../../../assets/images/teachergrey.svg";
import counsellorgrey from "../../../assets/images/counsellorgrey.svg";
import infirmarygrey from "../../../assets/images/infirmarygrey.svg";
import studentgrey from "../../../assets/images/studentgrey.svg";
import AccordionCard from "../../HelpSupport/AccordionCard";
import { BlogCard } from "../../common/BlogCard/BlogCard";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import moment from "moment";
import ClinicChart from "../../common/Graphs/ClinicChart";
import D2CGraph from "../../common/Graphs/D2CGraph";
import PrincipalScreeningGraph from "../../common/Graphs/PrincipalScreeningGraph";
import { PrincipleInfoCard } from "./PrincipleInfoCard";

export default function PrincipalDashboardPage() {
  const dispatch = useDispatch();
  const getDashboard = dashboardReducer.actions.getPrincipalDashboardRequest;
  const data = useSelector((state) => state.dashboardReducer.data);
  const schoolId = useSelector(
    (state) => state.userReducer.currentUser.schoolId
  );
  const userId = useSelector((state) => state.userReducer.currentUser.userId);

  useEffect(() => {
    if (schoolId && userId) {
      dispatch(getDashboard({ schoolId, userId }));
    }
  }, [schoolId]);

  const loading = useSelector((state) => state.dashboardReducer.loading);

  const [eventTime, setEventTime] = useState("")

  let steps;
  let schoolInfo;
  let Name;
  let Dashstepper;
  let strength;

  useEffect(() => {
    
  }, [data])


  steps = [
    {
      label: "Screening as per calendar",
      linkLabel: "Request date change",
      completed: `${moment(data?.fromDate).format("DD MMM")} - ${moment(data?.toDate).format("DD MMM, YYYY")}`,
    },
    {
      label: "Behavioral Typeform Sent",
      linkLabel: "",
      completed: `${moment(data?.fromDate).format("DD MMM, YYYY")}`,
    },
    {
      label: "Behavioral Typeform Filled",
      linkLabel: "Send Reminders",
      completed: `${data?.bhvScr?.done}/${data?.bhvScr?.outOf}`,
    },
    {
      label: "Reports of screening",
      linkLabel: "Download reports",
      completed: "-",
    },
  ];

  Name = [
    {
      title: ``,
    },
  ];
  Dashstepper = [
    {
      title: "Your Class",
    },
  ];

  const role_activity_obj = [
    {
      roleName: "Teacher",
      icon: teachergrey,
      value: data?.teachers
    },
    {
      roleName: "Student",
      icon: studentgrey,
      value: data?.student
    },
    {
      roleName: "Infirmary Staff",
      icon: infirmarygrey,
      value: data?.infirmary
    },
    {
      roleName: "Counsellors",
      icon: counsellorgrey,
      value: data?.counsellors
    },
  ];

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#f1f1f1",
    zIndex: 1,
    color: "#fff",
    width: 30,
    height: 30,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {}),
    ...(ownerState.completed && {
      backgroundColor: "#42c134",
      color: "white",
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <SettingsIcon style={{ width: 20 }} />,
      2: <GroupAddIcon style={{ width: 20 }} />,
      3: <DocumentScannerIcon style={{ width: 10, fill: "#707070" }} />,
      4: <DocumentScannerIcon style={{ width: 10, fill: "#707070" }} />,
    };
    return !completed ? (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    ) : (
      <ColorlibStepIconRoot ownerState={{ completed }} className={className}>
        <CheckIcon style={{ width: 20, fill: "#FFFFFF" }} />
      </ColorlibStepIconRoot>
    );
  }

  const getScreeningDate = () => {
    const from = moment(data?.schoolProfile?.screeningFrom).format("DD MMM");
    const to = moment(data?.schoolProfile?.screeningTo).format("DD MMM");

    if (from.slice(3) === to.slice(3)) {
      return `${from.slice(0, 2)} - ${to}`;
    } else {
      return `${from}-${to}`;
    }
  };

  const analytics_card = [
    {
      logo: screeningsred,
      cardName: "PHYSICAL SCREENING",
      totalCount: data?.phyScr?.outOf,
      count: data?.phyScr?.done,
      linkName: "Check Progress",
      link: "",
      linkContent: "of ongoing screening of different classes",
    },
    {
      logo: screeningsred,
      cardName: "PHYSICAL VALIDATION",
      totalCount: data?.phyValid?.outOf,
      count: data?.phyValid?.done,
      linkName: "Check Report",
      link: "",
      linkContent: "validated by doctor of screened students",
    },
    {
      logo: screeningsyellow,
      cardName: "BEHAVIORAL SCREENING",
      totalCount: data?.bhvScr?.outOf,
      count: data?.bhvScr?.done,
      linkName: "Check the Progress",
      link: "",
      linkContent: "of assessments filled by parents",
    },
    // {
    //   logo: screeningsyellow,
    //   cardName: "BEHAVIORAL VALIDATION",
    //   totalCount: data?.bhvValid?.outOf,
    //   count: data?.bhvValid?.outOf,
    //   linkName: "Check Progress",
    //   link: "",
    //   linkContent: "validated by doctor of filled assessments",
    // },
  ];

  const getEventTime = () => {
    const today = moment().format("YYYY-MM-DD")
    if(data?.toDate < today) {
      return "Post"
    }
    else if (data?.fromDate <= today && data?.toDate >= today){
      return "Ongoing"
    }
    else{
      return "Pre"
    }
  }

  useEffect(() => {
    const x = getEventTime()
    setEventTime(x)
  }, [data])
  

  let renderDash;
  switch (eventTime) {
    case "Pre":
      renderDash = (
        <>
          {data && <div className={classes.infoCard_container}>
            <PrincipleInfoCard
              title={`${moment(data?.fromDate).format("DD MMM")} - ${moment(data?.toDate).format("DD MMM, YYYY")}`}
              entity={"School"}
              subtitle={"Class 1 - Class 12 (All Sections)"}
              totalText={"students"}
              totalCount={"4 "}
              totalPupilCount={`${data?.student}`}
              live={"Upcoming"}
              data={data?.OSP}
            />
          </div>}
          <div className={classes.analytics_text}>Real Time Activity</div>
          <div className={classes.role_stepper_container_parent}>
            <div className={classes.stepper_container_role}>
              {role_activity_obj.map((el) => {
                return (
                  <div className={classes.role_activity_container}>
                    <div className={classes.role_activity_image_container}>
                      <img src={el.icon} />
                    </div>
                    <div className={classes.role_actvity_description}>
                      <p className={classes.role_activity_description_name}>
                        {el.roleName}
                      </p>
                      <p className={classes.role_activity_description_count}>
                        {el.value}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={classes.stepper_container}>
              <Stepper alternativeLabel activeStep={1}>
                {steps?.map((item, index) => (
                  <Step key={index}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      <div className={classes.label}>{item.label}</div>
                      <div className={classes.completed}>{item.completed}</div>
                      <div className={classes.link_text}>{item.linkLabel}</div>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
          {/* <div className={classes.whatnew_and_faq_wrapper}>
            <div className={classes.whatsnew_container}>
              <div className={classes.whatsnew_header_container}>
                <p>WHAT'S NEW?</p>
                <div>
                  <FormControl style={{ marginRight: 20, width: 100, marginTop: 20 }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      size="small"
                      sx={{
                        backgroundColor: "#FFF",
                        color: "#001028 !important",
                        fontFamily: "Inter",
                        fontSize: "14px"
                      }}
                    >
                      Topic
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={""}
                      label="Rows"
                      onChange={(e) => { }}
                      size="small"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        "&:active": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          borderRadius: "8px",
                          color: "#9d9d9d !important",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "& label.Mui-focused": {
                          color: "#9d9d9d !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            color: "#9d9d9d !important",
                          },
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "#6F7E90 !important",
                        },
                      }}
                    >
                      <MenuItem value={"Today"}>Today</MenuItem>
                      <MenuItem value={"This Week"}>This Week</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={classes.whatsnew_content_container}>
                {[
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                ].map((el) => {
                  return (
                    <div className={classes.whatsnew_content_box}>
                      <span>{el}.</span>
                      <Link>Read more</Link>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={classes.faq_container}>
              <div className={classes.whatsnew_header_container}>
                <p>FAQ's</p>
                <div>
                  <FormControl style={{ marginRight: 20, width: 100, marginTop: 20 }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      size="small"
                      sx={{
                        backgroundColor: "#FFF",
                        color: "#001028 !important",
                        fontFamily: "Inter",
                        fontSize: "14px"
                      }}
                    >
                      Topic
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={""}
                      label="Rows"
                      onChange={(e) => { }}
                      size="small"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        "&:active": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          borderRadius: "8px",
                          color: "#9d9d9d !important",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "& label.Mui-focused": {
                          color: "#9d9d9d !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            color: "#9d9d9d !important",
                          },
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "#6F7E90 !important",
                        },
                      }}
                    >
                      <MenuItem value={"Today"}>Today</MenuItem>
                      <MenuItem value={"This Week"}>This Week</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              {[1, 1, 1, 1].map((el) => {
                return (
                  <div className={classes.faq_card}>
                    <AccordionCard />
                  </div>
                );
              })}
              <Link>
                <span className={classes.faq_link}>Read more FAQ's</span>
              </Link>
            </div>
          </div> */}
          {/* <div className={classes.whatnew_and_faq_wrapper}>
            <div className={classes.blogarticles_container}>
              <div className={classes.whatsnew_header_container}>
                <p>SKIDS BLOG ARTICLES</p>
                <div>
                  <FormControl style={{ marginRight: 20, width: 100, marginTop: 20 }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      size="small"
                      sx={{
                        backgroundColor: "#FFF",
                        color: "#001028 !important",
                        fontFamily: "Inter",
                        fontSize: "14px"
                      }}
                    >
                      Topic
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={""}
                      label="Rows"
                      onChange={(e) => { }}
                      size="small"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        "&:active": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          borderRadius: "8px",
                          color: "#9d9d9d !important",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "& label.Mui-focused": {
                          color: "#9d9d9d !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            color: "#9d9d9d !important",
                          },
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "#6F7E90 !important",
                        },
                      }}
                    >
                      <MenuItem value={"Today"}>Today</MenuItem>
                      <MenuItem value={"This Week"}>This Week</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={classes.blog_cards_wrapper}>
                {[1, 1, 1].map((el) => {
                  return (
                    <BlogCard
                      text={
                        "Lorem Ipsum is a dummy text in place of original text. "
                      }
                      type={"Blog"}
                    />
                  );
                })}
              </div>
            </div>

            <div className={classes.blogarticles_container}>
              <div className={classes.whatsnew_header_container}>
                <p>SELF HELP VIDEOS</p>
                <div>
                  <FormControl style={{ marginRight: 20, width: 100, marginTop: 20 }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      size="small"
                      sx={{
                        backgroundColor: "#FFF",
                        color: "#001028 !important",
                        fontFamily: "Inter",
                        fontSize: "14px"
                      }}
                    >
                      Topic
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={""}
                      label="Rows"
                      onChange={(e) => { }}
                      size="small"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        "&:active": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          borderRadius: "8px",
                          color: "#9d9d9d !important",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "& label.Mui-focused": {
                          color: "#9d9d9d !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            color: "#9d9d9d !important",
                          },
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "#6F7E90 !important",
                        },
                      }}
                    >
                      <MenuItem value={"Today"}>Today</MenuItem>
                      <MenuItem value={"This Week"}>This Week</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={classes.blog_cards_wrapper}>
                {[1, 1, 1].map((el) => {
                  return (
                    <BlogCard
                      type={"Video"}
                      videoLink={
                        "https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"
                      }
                      videoTopic={"Some video"}
                    />
                  );
                })}
              </div>
            </div>
          </div> */}
        </>
      );
      break;

    case "Ongoing":
      renderDash = (
        <>
          <div className={classes.infoCard_container}>
          {data && <div className={classes.infoCard_container}>
            <PrincipleInfoCard
              title={`${moment(data?.fromDate).format("DD MMM")} - ${moment(data?.toDate).format("DD MMM, YYYY")}`}
              entity={"School"}
              subtitle={"Class 1 - Class 12 (All Sections)"}
              totalText={"students"}
              totalCount={"4 "}
              totalPupilCount={`${data?.student}`}
              live={"Live"}
              data={data?.OSP}
            />
          </div>}

            {/* <PrincipleInfoCard
              title={"School Health Index"}
              entity={"Doctor"}
              totalText={"validation"}
              totalCount={"4 "}
              totalPupilCount={"4000"}
            /> */}
          </div>
          <div className={classes.analytics_text}>During Event Analytics</div>
          <div className={classes.analytics_cards_container}>
            {analytics_card.map((el, index) => {
              return (
                <div className={classes.analytics_cards_main}>
                  <div className={classes.analytics_cards_container_inner}>
                    <p
                      className={classes.analytics_cards_container_inner_header}
                    >
                      {el.cardName}
                    </p>
                    <div
                      className={
                        classes.analytics_cards_container_logo_number_container
                      }
                    >
                      <img src={el.logo} />
                      <p
                        className={`${index <= 1
                          ? `${classes.analytics_cards_container_logo_number_container_text} ${classes.analytics_cards_inner_red}`
                          : `${classes.analytics_cards_container_logo_number_container_text} ${classes.analytics_cards_inner_yellow}`
                          }`}
                      >
                        {el.count} <span>/{el.totalCount}</span>
                      </p>
                      <p
                        className={
                          classes.analytics_cards_container_logo_number_container_countText
                        }
                      ></p>
                    </div>

                    <div
                      className={`${classes.analytics_cards_container_inner_subtext}`}
                    >
                      <Link>
                        <span
                          className={`${index <= 1
                            ? `${classes.analytics_cards_inner_red}`
                            : `${classes.analytics_cards_inner_yellow}`
                            }`}
                        >
                          {el.linkName}
                        </span>
                      </Link>{" "}
                      {el.linkContent}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={classes.analytics_text}>Real Time Activity</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 16,
            }}
          >
            <div className={classes.graphTile}>
              <div className={classes.filterContainer}>
                <span className={classes.devices_title}>
                  PHYSICAL SCREENING
                </span>
                <div>
                </div>
              </div>
              <div id="chart--section">{<PrincipalScreeningGraph datax={data} />}</div>
            </div>

            <div className={classes.graphTile}>
              <div className={classes.filterContainer}>
                <span className={classes.devices_title}>
                  BEHAVIORAL SCREENING
                </span>
                <div>
                </div>
              </div>
              {typeof data?.bhvGraph === "object" && Object.keys(data?.bhvGraph).length>0 ? <div>{<D2CGraph datax={data} /> }</div> : <div style={{height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>No Data Available</div>}
            </div>
          </div>
          {/* <div className={classes.whatnew_and_faq_wrapper}>
            <div className={classes.whatsnew_container}>
              <div className={classes.whatsnew_header_container}>
                <p>WHAT'S NEW?</p>
                <div>
                  <FormControl style={{ marginRight: 20, width: 100, marginTop: 20 }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      size="small"
                      sx={{
                        backgroundColor: "#FFF",
                        color: "#001028 !important",
                        fontFamily: "Inter",
                        fontSize: "14px"
                      }}
                    >
                      Health
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={""}
                      label="Rows"
                      onChange={(e) => { }}
                      size="small"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        "&:active": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          borderRadius: "8px",
                          color: "#9d9d9d !important",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "& label.Mui-focused": {
                          color: "#9d9d9d !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            color: "#9d9d9d !important",
                          },
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "#6F7E90 !important",
                        },
                      }}
                    >
                      <MenuItem value={"Today"}>Today</MenuItem>
                      <MenuItem value={"This Week"}>This Week</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={classes.whatsnew_content_container}>
                {[
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                ].map((el) => {
                  return (
                    <div className={classes.whatsnew_content_box}>
                      <span>{el}.</span>
                      <Link>Read more</Link>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={classes.faq_container}>
              <div className={classes.whatsnew_header_container}>
                <p>FAQ's</p>
                <div>
                  <FormControl style={{ marginRight: 20, width: 120, marginTop: 20 }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      size="small"
                      sx={{
                        backgroundColor: "#FFF",
                        color: "#001028 !important",
                        fontFamily: "Inter",
                        fontSize: "14px"
                      }}
                    >
                      Screening
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={""}
                      label="Rows"
                      onChange={(e) => { }}
                      size="small"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        "&:active": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          borderRadius: "8px",
                          color: "#9d9d9d !important",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "& label.Mui-focused": {
                          color: "#9d9d9d !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            color: "#9d9d9d !important",
                          },
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "#6F7E90 !important",
                        },
                      }}
                    >
                      <MenuItem value={"Today"}>Today</MenuItem>
                      <MenuItem value={"This Week"}>This Week</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              {[1, 1, 1, 1].map((el) => {
                return (
                  <div className={classes.faq_card}>
                    <AccordionCard />
                  </div>
                );
              })}
              <Link>
                <span className={classes.faq_link}>Read more FAQ's</span>
              </Link>
            </div>
          </div> */}
        </>
      );
      break;

    case "Post":
      renderDash = (
        <>
          {data && <div className={classes.infoCard_container}>
            <PrincipleInfoCard
              title={`Validation Data`}
              entity={"Doctor"}
              subtitle={"Class 1 - Class 12 (All Sections)"}
              totalText={"students"}
              totalCount={"4 "}
              totalPupilCount={`${data?.student}`}
              live={"Live"}
              data={data?.bhvScr}
            />
          </div>}
          <div className={classes.analytics_text}>Screening Analytics</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 16,
            }}
          >
            <div className={classes.graphTile}>
              <div className={classes.filterContainer}>
                <span className={classes.devices_title}>
                  PHYSICAL SCREENING
                </span>
              </div>
              {/* <div>{!loading && data && <ClinicChart data={data} />}</div> */}
              <div id="chart--section">{<PrincipalScreeningGraph datax={data} />}</div>
            </div>

            <div className={classes.graphTile}>
            <div className={classes.filterContainer}>
                <span className={classes.devices_title}>
                  BEHAVIOURAL SCREENING
                </span>
              </div>
              {typeof data?.bhvGraph === "object" && Object.keys(data?.bhvGraph).length>0 ? <div>{<D2CGraph datax={data} /> }</div> : <div style={{height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>No Data Available</div>}
            </div>
          </div>
          {/* <div className={classes.whatnew_and_faq_wrapper}>
            <div className={classes.whatsnew_container}>
              <div className={classes.whatsnew_header_container}>
                <p>WHAT'S NEW?</p>
                <div>
                  <FormControl style={{ marginRight: 20, width: 100, marginTop: 20 }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      size="small"
                      sx={{
                        backgroundColor: "#FFF",
                        color: "#001028 !important",
                        fontFamily: "Inter",
                        fontSize: "14px"
                      }}
                    >
                      Time
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={""}
                      label="Rows"
                      onChange={(e) => { }}
                      size="small"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        "&:active": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          borderRadius: "8px",
                          color: "#9d9d9d !important",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "& label.Mui-focused": {
                          color: "#9d9d9d !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            color: "#9d9d9d !important",
                          },
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "#6F7E90 !important",
                        },
                      }}
                    >
                      <MenuItem value={"Today"}>Today</MenuItem>
                      <MenuItem value={"This Week"}>This Week</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={classes.whatsnew_content_container}>
                {[
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                ].map((el) => {
                  return (
                    <div className={classes.whatsnew_content_box}>
                      <span>{el}.</span>
                      <Link>Read more</Link>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={classes.faq_container}>
              <div className={classes.whatsnew_header_container}>
                <p>FAQ's</p>
                <div>
                  <FormControl style={{ marginRight: 20, width: 100, marginTop: 20 }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      size="small"
                      sx={{
                        backgroundColor: "#FFF",
                        color: "#001028 !important",
                        fontFamily: "Inter",
                        fontSize: "14px"
                      }}
                    >
                      Time
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={""}
                      label="Rows"
                      onChange={(e) => { }}
                      size="small"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        "&:active": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          borderRadius: "8px",
                          color: "#9d9d9d !important",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "& label.Mui-focused": {
                          color: "#9d9d9d !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            color: "#9d9d9d !important",
                          },
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "#6F7E90 !important",
                        },
                      }}
                    >
                      <MenuItem value={"Today"}>Today</MenuItem>
                      <MenuItem value={"This Week"}>This Week</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              {[1, 1, 1, 1].map((el) => {
                return (
                  <div className={classes.faq_card}>
                    <AccordionCard />
                  </div>
                );
              })}
              <Link>
                <span className={classes.faq_link}>Read more FAQ's</span>
              </Link>
            </div>
          </div>
          <div className={classes.whatnew_and_faq_wrapper}>
            <div className={classes.blogarticles_container}>
              <div className={classes.whatsnew_header_container}>
                <p>SKIDS BLOG ARTICLES</p>
                <div>
                  <FormControl style={{ marginRight: 20, width: 100, marginTop: 20 }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      size="small"
                      sx={{
                        backgroundColor: "#FFF",
                        color: "#001028 !important",
                        fontFamily: "Inter",
                        fontSize: "14px"
                      }}
                    >
                      Topic
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={""}
                      label="Rows"
                      onChange={(e) => { }}
                      size="small"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        "&:active": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          borderRadius: "8px",
                          color: "#9d9d9d !important",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "& label.Mui-focused": {
                          color: "#9d9d9d !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            color: "#9d9d9d !important",
                          },
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "#6F7E90 !important",
                        },
                      }}
                    >
                      <MenuItem value={"Today"}>Today</MenuItem>
                      <MenuItem value={"This Week"}>This Week</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={classes.blog_cards_wrapper}>
                {[1, 1, 1].map((el) => {
                  return (
                    <BlogCard
                      text={
                        "Lorem Ipsum is a dummy text in place of original text. "
                      }
                      type={"Blog"}
                    />
                  );
                })}
              </div>
            </div>

            <div className={classes.blogarticles_container}>
              <div className={classes.whatsnew_header_container}>
                <p>SELF HELP VIDEOS</p>
                <div>
                  <FormControl style={{ marginRight: 20, width: 100, marginTop: 20 }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      size="small"
                      sx={{
                        backgroundColor: "#FFF",
                        color: "#001028 !important",
                        fontFamily: "Inter",
                        fontSize: "14px"
                      }}
                    >
                      Topic
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={""}
                      label="Rows"
                      onChange={(e) => { }}
                      size="small"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        "&:active": {
                          backgroundColor: "#FFFFFF",
                          color: "#9d9d9d",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          borderRadius: "8px",
                          color: "#9d9d9d !important",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E7E7",
                          color: "#9d9d9d !important",
                        },
                        "& label.Mui-focused": {
                          color: "#9d9d9d !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            color: "#9d9d9d !important",
                          },
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "#6F7E90 !important",
                        },
                      }}
                    >
                      <MenuItem value={"Today"}>Today</MenuItem>
                      <MenuItem value={"This Week"}>This Week</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={classes.blog_cards_wrapper}>
                {[1, 1, 1].map((el) => {
                  return (
                    <BlogCard
                      type={"Video"}
                      videoLink={
                        "https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"
                      }
                      videoTopic={"Some video"}
                    />
                  );
                })}
              </div>
            </div>
          </div> */}
        </>
      );
      break;
  }

  return <>{renderDash}</>;
}
