import React from "react";
import classes from "./Anxiety.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import { stringAvatar } from "../../../utils/utility";
import { stringToColor } from "../../../utils/utility";
import { Avatar } from "@mui/material";
import highrisk from "../../../assets/images/highRisk.svg";
import psychologist from "../../../assets/images/psychologist.svg";
import BhvReportHeader from "./BhvReportHeader";

export const Anxiety = ({ data }) => {
  const questionBank = [
    {
      qno: 1,
      question:
        "When my child feels frightened, it is hard for him/her to breathe",
      answer: data?.output?.[0]?.answers?.[0]
    },
    {
      qno: 2,
      question: "My child gets headaches when he/she is at school?",
      answer: data?.output?.[0]?.answers?.[1]
    },
    {
      qno: 3,
      question:
        "My child doesn’t like to be with people he/she doesn’t know well?",
      answer: data?.output?.[0]?.answers?.[2]
    },
    {
      qno: 4,
      question: "My child gets scared if he/she sleeps away from home?",
      answer: data?.output?.[0]?.answers?.[3]
    },
    {
      qno: 5,
      question: "My child worries about other people liking him/her?",
      answer: data?.output?.[0]?.answers?.[4]
    },
    {
      qno: 6,
      question: "When my child gets frightened, he/she feels like passing out?",
      answer: data?.output?.[0]?.answers?.[5]
    },
    {
      qno: 7,
      question: `My child is nervous?`,
      answer: data?.output?.[0]?.answers?.[6]
    },
    {
      qno: 8,
      question: "My child follows me wherever I go?",
      answer: data?.output?.[0]?.answers?.[7]
    },
    {
      qno: 9,
      question: "People tell me that my child looks nervous?",
      answer: data?.output?.[0]?.answers?.[8]
    },
    {
      qno: 10,
      question: "My child feels nervous with people he/she doesn’t know well?",
      answer: data?.output?.[0]?.answers?.[9]
    },
  ];

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>
        <div className={classes.title_container}>
          Hey! This is my Anxiety assessment report.
        </div>

        <BhvReportHeader data={data} />

        <div className={classes.question_container}>
          <div>Assessment Questionnaire</div>
          <div className={classes.question_select_container}>
            {questionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Not true"
                          checked={el.answer === "NOT_TRUE"}
                        />
                        <p style={{ width: "60px" }}>
                          Not true
                        </p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Somewhat true"
                          checked={el.answer === "SOMEWHAT_TRUE"}
                        />
                        <p style={{ width: "60px" }}>
                          Somewhat true
                        </p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Very true"
                          checked={el.answer === "VERY_TRUE"}
                        />
                        <p style={{ width: "55px" }}>Very true</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes.footer_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 1 of 4</p>
          </div>
        </div>
      </div>
    </>
  );
};
