import * as React from "react"
const SvgComponent = (props) => (
    <div style={{ ...props.customStyle }}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke="#B64F35"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                clipPath="url(#a)"
            >
                <path d="M32.511 27.446s4.413-.462 3.061-3.082c-1.352-2.62-3.06-5.167-3.06-5.167S34.756 2.492 20.04 2.492c-14.717 0-18.132 13.095-14.57 19.253l3.56 6.158v7.787s-.144 1.805 1.814 1.805h12.7s1.416-.161 1.416-1.408v-1.955S25.325 33 26.819 33h2.347s2 .143 2.356-1.486c.356-1.628.99-4.067.99-4.067ZM8.236 11.79v3.857M11.705 7.719v7.928M15.172 5.953v9.69M18.64 5.484v10.162M22.11 5.484v10.162M25.576 6.71v8.935M29.043 11.79v3.857" />
                <path d="M27.941 20s-4.468 6.87 0 6.87c4.469 0 0-6.87 0-6.87Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M3.334 1.664h33.333v36.667H3.334z" />
                </clipPath>
            </defs>
        </svg>
    </div>
)
export default SvgComponent
