import React from "react";
import classes from "./Dyslexia.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import clsx from "clsx";


export const Dyslexia2 = ({ data }) => {
  const response = JSON.parse(data?.DYSLEXIA_2)?.response

  const questionBank = [
    {
      qno: 1,
      question:
        "Does your child find it difficult to draw conclusions or inferences from materials read?",
      answer: response[0]
    },
    {
      qno: 2,
      question:
        "Does you child find it difficult to recall facts that are read to him or her?",
      answer: response[1]
    },
    {
      qno: 3,
      question:
        "Does your child have difficulty learning new words (vocabulary), either while reading or hearing",
      answer: response[2]
    },
    {
      qno: 4,
      question:
        "Does your child seem to put more effort than usual while reading? (E.g., reads single words aloud incorrectly or slowly and hesitantly)",
      answer: response[3]
    },
    {
      qno: 5,
      question:
        "Does your child show slow rate of reading, both silently and out loud (e.g., frequently guesses words, has difficulty sounding out words).",
      answer: response[4]
    },
    {
      qno: 6,
      question:
        "Does your child often loose track when reading? (For example, loose place in a text and start reading from different line or paragraph; false starts)",
      answer: response[5]
    },
    {
      qno: 7,
      question:
        "Does your child have difficulty understanding the meaning of what is read (e.g., may read text accurately but does not understand the sequence or deeper meanings of what is read).",
      answer: response[6]
    },
    {
      qno: 8,
      question:
        "Does your child have difficulty with spelling while reading (e.g., may add, omit, or substitute vowels or consonants).",
      answer: response[7]
    },
    {
      qno: 9,
      question:
        "Does your child reverse words in sentences or of letters within words.",
      answer: response[8]
    },
    {
      qno: 10,
      question:
        "Does you child find it difficult to answer questions about a story just read?",
      answer: response[9]
    },
  ];


  return (
    <>
      {JSON.parse(data?.DYSLEXIA_2).outcome && <div className={clsx(classes.container, 'xyz')}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>
        <div className={classes.title_container}>
          Hey! This is my Dyslexia Stage 2 assessment report.
        </div>

        <div className={classes.question_container}>
          <div>Assessment Questionnaire</div>
          <div className={classes.question_select_container}>
            {questionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p style={{ flexBasis: "423px" }}>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Yes"
                          checked={el.answer === "YES"}
                        />
                        <p>Yes</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="No"
                          checked={el.answer === "NO"}
                        />
                        <p>No</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.copyright_details_container}>
          <div>
            The information contained in this publication should not be used as
            a substitute for the medical care and advice of your psychologist.
            There may be variations in treatment that your psychologist may
            recommend based on individual facts and circumstances.
          </div>
          <div>
            Copyright @2023 Skids Health
            <br />
            Adapted from DSM-5 ( Diagnostic and Statistical Manual of Mental
            Disorders, Fifth <br />
            Edition) and ICD-10 (International Classification of Diseases,Tenth
            Revision)
          </div>
        </div>

        <div className={classes.footer_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 2 of 2</p>
          </div>
        </div>
      </div>}
    </>
  );
};
