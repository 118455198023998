import React, { useState } from 'react'
import classes from './PatientsRow.module.css'
import { Avatar, Box, Button, IconButton, Modal, Typography } from '@mui/material'
import { Add, Remove, Visibility } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { getAge, stringAvatar } from '../../../utils/utility'
import { pointer } from 'd3'
import AppointmentsSideBar from '../../common/AppointmentsSideBar/AppointmentsSideBar'
import { PopupModal } from 'react-calendly'
import { Buffer } from 'buffer'
import InputDashboard from '../../common/InputDashboard/InputDashboard'
import { useEffect } from 'react'


function AssessmentsRow({ data }) {
    const [showParentsDetails, setShowParentsDetails] = useState(false)
    const [showAssessmentTreatment, setShowAssessmentTreatment] = useState(false)
    const navigate = useNavigate()

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [showIframe, setShowIframe] = useState(false)
    const [link, setLink] = useState('')

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleOpenSchedule = () => {
        if (data?.email) {
            let x = "https://calendly.com/support-skids/30min"
            const params = JSON.stringify({
                kidId: data?.kidId,
                assessmentId: data?.assessmentId
            })
            const stringify = Buffer.from(params).toString("base64")
            x = x + "?utm_content=" + stringify + "&email=" + data?.email + "&name=" + data?.name
            setLink(x)
            setShowIframe(true)
        } else {
            handleOpen()
        }
    }

    useEffect(() => {
        setEmailError(String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ))
    }, [email])

    const handleSchedule = () => {
        let x = "https://calendly.com/support-skids/30min"
        const params = JSON.stringify({
            kidId: data?.kidId,
            assessmentId: data?.assessmentId
        })
        const stringify = Buffer.from(params).toString("base64")
        x = x + "?utm_content=" + stringify + "&email=" + email + "&name=" + data?.name
        console.log(x)
        setLink(x)
        handleClose()
        setShowIframe(true)
    }
    return (
        <>
            {<PopupModal
                url={link}
                rootElement={document.getElementById("root")}
                text="Click here to schedule!"
                onModalClose={() => setShowIframe(false)}
                open={showIframe}
            />}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ backdropFilter: 'blur(2px)' }}
            >
                <Box sx={style}>
                    {<>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Enter your email id
                        </Typography>
                        <InputDashboard
                            placeholder={"Email"}
                            width={"100%"}
                            margin={2.4}
                            change={(e) => {
                                setEmail(e.target.value);
                            }}
                            bgColor="#ffffff"
                            value={email}
                        />
                        <Button disabled={!emailError} fullWidth className={classes.bookButton} onClick={handleSchedule}>Book Consultation</Button>
                    </>
                    }
                </Box>
            </Modal>
            <div className={classes.studentRow_container}>
                <div>
                    <div>
                        <Avatar {...stringAvatar(data?.name)} />
                    </div>
                    <div>
                        {data?.name}
                    </div>
                </div>
                <div>
                    <p>{getAge(data?.DOB)} yrs</p>
                </div>
                <div>
                    {data?.gender}
                </div>
                <div className={classes.parentDetails}>
                    {/* <div>
                        <button onClick={() => setShowParentsDetails(!showParentsDetails)}>
                            {showParentsDetails ? <Remove /> : <Add />}
                        </button>
                        <h1>Ms. Karishma Singh</h1>
                    </div>

                    {showParentsDetails ? <div>
                        <p>sachinsaxena@gmail.com</p>
                        <p>+91 9876543210</p>
                    </div> : ""} */}
                    <h1>{data?.parentName}</h1>



                </div>
                <div className={classes.treatment}>
                    {/* <div>
                        <button onClick={() => setShowAssessmentTreatment(!showAssessmentTreatment)}>
                            {showAssessmentTreatment ? <Remove /> : <Add />}
                        </button>
                        {showAssessmentTreatment ?
                            <div>
                                {data?.package?.map(el => (
                                    <p>{el}</p>
                                ))}
                            </div> :
                            <p>{data?.package?.length} Assessment</p>
                        }
                    </div> */}
                    <p></p>

                    <div onClick={() => navigate("/behavioral-doctor/dashboard/patients/healthHistory", { state: { data, breadCrumbs: ['Patients', data?.name] } })}>
                        <a>View History</a>
                    </div>
                </div>
                <div>
                    {data?.consultation ? <><Button disabled>SCHEDULED</Button>
                        <AppointmentsSideBar data={data} /></>
                        : <Button style={{ backgroundColor: "#F5F6F7", color: '#001028', border: '1px solid #001028' }} onClick={handleOpenSchedule}>SEND MAIL</Button>}
                </div>

                <div style={{ justifySelf: "center", cursor: 'pointer' }} onClick={() => navigate(`/behavioral-doctor/info/${data.name}`, { state: data })}>
                    <Visibility />
                </div>
            </div>
        </>
    )
}

export default AssessmentsRow