import React from 'react'
import AddUser from '../common/AddIndividual/AddUser'
import DashboardLayout from '../common/DashboardLayout/DashboardLayout'

const AddEditIndividual = () => {
    
    return (
        // <div style={{
        //     display: 'flex',
        //     padding: '25px'
        // }}>
        //     <DashboardLayout />
            <AddUser />
        // </div>
    )
}

export default AddEditIndividual