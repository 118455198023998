import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./SchoolTable.module.css";
import SchoolRow from "./SchoolRow";
import left from "../../../assets/images/left.svg";
import leftArrow from "../../../assets/images/down.svg";
import active from "../../../assets/images/active.svg";
import rightArrow from "../../../assets/images/sort.svg";
import right from "../../../assets/images/right.svg";
import schoolReducer from "../../../redux/reducers/school.reducer";
import typeformReducer from "../../../redux/reducers/typeform.reducer";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../TableComponents/BreadCrumbs";
import ShowPagesFilter from "../TableComponents/ShowPagesFilter";
import TabButton from "../TableComponents/TabButton";
import SearchBar from "../TableComponents/SearchBar";
import ClassSectionFilter from "../TableComponents/ClassSectionFilter";
import InputDropdown from "../InputDashboard/InputDropdown";
import { City } from "country-state-city";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import CustomAutoComplete from "../CustomAutoComplete/CustomAutoComplete";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import typeformUpload from "../../../assets/images/typeformUpload.svg";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ChipModal from "../ChipModal/ChipModal";
import Cookies from "js-cookie";
import ScheduleModal from "./ScheduleModal";
import { Alert, Snackbar } from "@mui/material";
import { Filter } from "../TableComponents/Filter";
import { compareDate } from "../../../utils/utility";
import moment from "moment";
import BlankDataScreen from "../../BlankDataScreen/BlankDataScreen";

function SchoolPage() {
  const token = Cookies.get("tokenId");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getSchools = schoolReducer.actions.getSchoolsRequest;
  const shareTypeform = typeformReducer.actions.shareTypeformRequest;
  const breadCrumbs = ["Registered Schools"];

  // useEffect(() => {
  //   dispatch(getSchools());
  // }, []);

  location.state = { breadCrumbs: breadCrumbs };
  const data = useSelector((state) => state.schoolReducer.data);
  const loading = useSelector((state) => state.schoolReducer.loading);
  const errorTypeform = useSelector((state) => state.typeformReducer.error);
  const loadingTypeform = useSelector((state) => state.typeformReducer.loading);

  function getWordBeforeDash() {
    let newStr = "";
    for (let i = 1; i < location.pathname.length; i++) {
      if (location.pathname[i] === "-") {
        return newStr;
      }
      newStr += location.pathname[i];
    }
  }

  const allCities = [
    ...new Set(City.getCitiesOfCountry("IN").map((x) => x.name)),
  ];

  const [open, setOpen] = useState(false);
  const [sec, setSec] = useState(false);
  const [text, setText] = useState("");
  const [displayData, setDisplayData] = useState(data ?? []);
  const [city, setCity] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [chipModal, setChipModal] = useState(false);
  const [activeFilter, setActiveFilter] = useState(false);
  const [inactiveFilter, setInActiveFilter] = useState(false);
  const handleClose = (event, reason) => {
    setOpen(false);
  };
  useEffect(() => {
    localStorage.removeItem('filter')
  }, [])
  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (city && text) {
      setDisplayData(
        data.filter(
          (x) =>
            x?.city === city &&
            x.schoolName.toLowerCase().includes(text.toLowerCase())
        )
      );
      setPageNo(1)
    } else if (city) {
      setDisplayData(data.filter((x) => x?.city === city));
      setPageNo(1)
    } else if (text) {
      setDisplayData(
        data.filter((x) =>
          x?.schoolName?.toLowerCase()?.includes(text?.toLowerCase())
        )
      );
      setPageNo(1)
    } else {
      setDisplayData(data);
    }
  }, [city, text]);

  useEffect(() => {
    if (Array.isArray(data)) {
      const y = data?.slice()?.sort((a, b) => {
        return b?.createdAt.localeCompare(a?.createdAt)
      })
      setDisplayData(y);
    }
  }, [data]);

  const handleOpen = () => {

    setOpen(!open);
  };
  const handleCity = (x) => {
    setCity(x);

    if (displayData?.length === data.length) {
      setDisplayData(data.filter((x) => x.city === x));
    }
    // setDisplayData()
  };

  const handleAddSchool = () => {
    navigate("/superadmin/school");
  };

  useEffect(() => {
    if (activeFilter && inactiveFilter) {
      setDisplayData(data);
      setPageNo(1)
    } else if (activeFilter) {
      setDisplayData(data?.filter((el) => el?.isActive));
      setPageNo(1)
    } else if (inactiveFilter) {
      setDisplayData(data?.filter((el) => el?.isActive === false || null));
      setPageNo(1)
    } else {
      setDisplayData(data);
      setPageNo(1)
    }
  }, [activeFilter, inactiveFilter]);
  return (
    <div className={classes.main_div}>
      <div className={classes.student_layout}>
        <div>
          <BreadCrumbs />
        </div>
        <div className={classes.tabBtn_container}>
          {/* <button
            onClick={() => setChipModal(true)}
            className={classes.sharetypeform_btn}
          >
            <img src={typeformUpload} />
            <span>Share Typeform</span>
          </button> */}
          <TabButton role="School" />
        </div>
      </div>
      {
        data?.length > 0 ?
          <>
            <div className={classes.student_filters}>
              <div className={classes.show_text}>Show</div>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={rowsPerPage}
                  label="Rows"
                  onChange={(e) => setRowsPerPage(e.target.value)}
                  size="small"
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <CustomAutoComplete
                options={allCities}
                value={city}
                placeholder={"City"}
                onChange={setCity}
              />
              <SearchBar id={"searchCy"} onchange={setText} value={text} />
              <Filter
                label1={"Active"}
                label2={"Inactive"}
                filter1={activeFilter}
                filter2={inactiveFilter}
                setFilter1={setActiveFilter}
                setFilter2={setInActiveFilter}
              />
            </div>
            {/* Student Table Header */}
            <div className={classes.student_table_header}>
              <div>SCHOOL NAME</div>
              <div>PINCODE</div>
              <div>SCHOOL CODE</div>
              <div>STUDENTS</div>
              <div>SCREENING START DATE</div>
              <div>SCREENING END DATE</div>
              <div>STATUS</div>
              <div style={{ justifySelf: "center" }}>ACTION</div>
            </div>
            {/* Student Column-Rows Section */}
            <div id="schoolRowCy" className={classes.student_column_container}>
              {Array.isArray(displayData) &&
                displayData
                  ?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo)
                  .map((el, index) => {
                    return (
                      <SchoolRow data={el} key={index + Date.now()} index={index} />
                    );
                  })}
            </div>
            <div className={classes.pagination_container}>
              <div className={classes.page_item}>{`Showing ${rowsPerPage * (pageNo - 1) + 1
                } to ${pageNo * rowsPerPage <= displayData?.length
                  ? pageNo * rowsPerPage
                  : displayData?.length
                } of ${displayData?.length} items`}</div>
              <div className={classes.pagination}>
                <Button
                  size="small"
                  disabled={pageNo === 1}
                  onClick={() => setPageNo(pageNo - 1)}
                >
                  <ChevronLeft />
                </Button>
                <span>{pageNo}</span>
                <div>
                  <span>of</span>
                  <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
                </div>

                <div>
                  <Button
                    size="small"
                    disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)}
                    onClick={() => setPageNo(pageNo + 1)}
                  >
                    <ChevronRight />
                  </Button>
                </div>
              </div>
            </div>
          </>
          :
          loading ?
            ""
            :
            <BlankDataScreen title="Oops! No data available" description="There is no data uploaded in this table to be shown. Click on Add Student to upload data." handleClick={() =>
              navigate('/superadmin-school/school', { state: { ...location.state, breadCrumbs: [...location.state.breadCrumbs, "Add School"] } })
            } buttonText="Add School" />

      }

      <ChipModal
        action={chipModal}
        setAction={setChipModal}
        title="Share Add School Typeform"
        subtitle="Please enter email id below to share “Add School” typeform to school representative."
        sendFunctionCall={shareTypeform}
        handleClick={handleClick}
        setOpen={setOpen}
      />
      {!loadingTypeform && (
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={!errorTypeform ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {!errorTypeform
              ? `Typeform Sent Successfully`
              : `Something Wrong! Try Again`}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

function School() {
  const dispatch = useDispatch();

  const getSchools = schoolReducer.actions.getSchoolsRequest;
  const getSchoolByState = schoolReducer.actions.getSchoolbyStateRequest;
  const user = useSelector((state) => state.userReducer.currentUser)

  useEffect(() => {
    console.log(user?.groups?.includes("skids-partner"), 'user group includes')
    if (user?.groups?.includes("skids-partner")) {
      if (user?.userAttributes) {
        if(user?.userAttributes?.state) {
          dispatch(getSchoolByState({ state: user?.userAttributes?.state }));
        }
      }
    } else {
      dispatch(getSchools());
    }
    localStorage.removeItem("filter")
  }, [user]);

  const data = useSelector((state) => state.schoolReducer.data);

  return <SchoolPage data={data} />;
}
export default School;
