import React, { useEffect, useRef } from 'react'
import './DatePickerInput.css'
import moment from 'moment';

const DatePickerInput = (props) => {
    const { width, placeholder, onChange, value, futureDate, pastDate, pastDateValue } = props;
    const inputRef = useRef()
    // 

    useEffect(() => {
        if (value?.length) {
            inputRef.current.className = "filledInput"
        } else {
            inputRef.current.className = "clearedInput"
        }
    }, [value])
    return (
        <div style={{
            position: "relative",
            marginTop: props?.margin ? "0" : "16px",
        }}>
            {
                placeholder &&
                <span
                    style={{
                        fontSize: '10px',
                        position: 'absolute',
                        top: '-6%',
                        left: '5%',
                        backgroundColor: 'white',
                        padding: "0px 3px",
                        color: "#9d9d9d"
                    }}
                >{placeholder}</span>
            }
            <input
                onChange={onChange}
                placeholder='dob'
                ref={inputRef}
                type="date"
                value={value}
                min={pastDate ? pastDateValue ?? moment().format("YYYY-MM-DD") : ''}
                max={futureDate ? moment().format("YYYY-MM-DD") : ''}
                style={{
                    width: width,
                    borderRadius: "5px",
                    border: '1px solid #e8e7e7',
                    padding: '1px 10px'
                }
                }
                disabled={props?.disabled ? true : false}
            />
        </div>
    )
}

export default DatePickerInput