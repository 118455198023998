import React from "react";
import classes from "./ODD2.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import mcneil from "../../../assets/images/McNeil.svg";
import nichq from "../../../assets/images/NICHQ.svg";
import americanPediatrics from "../../../assets/images/americanPediatrics.svg";
import clsx from "clsx";


export const ODD2 = ({ data }) => {

  const response = JSON.parse(data?.PERFORMANCE)?.response

  const performanceQuestionBank = [
    {
      qno: 6,
      question: "Relationships with siblings",
      answers: response?.[5]
    },
    {
      qno: 7,
      question: "Relationships with peers",
      answers: response?.[6]
    },
    {
      qno: 8,
      question: "Participation in organised activities (eg, teams)",
      answers: response?.[7]
    },
  ];

  return (
    <>
      <div className={clsx(classes.container, 'xyz')}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>

        <div className={classes.performance_question_container} style={{ margin: "24px 0 0 0" }}>
          <div>Performance Questionnaire</div>
          <div className={classes.question_select_container}>
            {performanceQuestionBank.map((el) => {
              return (
                <div className={`${classes.question_radio_box}`}>
                  <p className={classes.box_min_range}>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form style={{ columnGap: "20px" }}>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Excellent"
                          checked={el.answers === "EXCELLENT"}
                        />
                        <p>Excellent</p>
                      </div>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Above Average"
                          checked={el.answers === "ABOVE_AVERAGE"}
                        />
                        <p>Above Average</p>
                      </div>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Average"
                          checked={el.answers === "AVERAGE"}
                        />
                        <p>Average</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Somewhat of a problem"
                          checked={el.answers === "SOMEWHAT_OF_A_PROBLEM"}
                        />
                        <p style={{ width: "52px" }}>Somewhat of a problem</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Problematic"
                          checked={el.answers === "PROBLEMATIC"}
                        />
                        <p>Problematic</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes.copyright_details_container}>
          <div>
            The information contained in this publication should not be used as
            a substitute for the medical care and advice of your psychologist.
            There may be variations in treatment that your psychologist may
            recommend based on individual facts and circumstances.
          </div>
          <div>
            Copyright @2002 American Academy of Pediatrics and National
            Initiative for Children’s Healthcare Quality
            <br /> Adapted from the Vanderbilt Rating Scale developed by Mark L.
            Wolraich, MD.
            <br /> Revised - 1102
          </div>
        </div>
        <div className={classes.method_framework}>Method Framework</div>

        <div className={classes.certification_container}>
          <img src={americanPediatrics} alt="americanPediatrics" />
          <img src={nichq} alt="nichq" />
          <img src={mcneil} alt="mcneil" />
        </div>

        <div className={classes.footer_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 1 of 2</p>
          </div>
        </div>
      </div>
    </>
  );
};
