import React from "react";
import classes from "./Layout.module.css";
import skidslogo from "../../../assets/images/skids-logo-1.svg";
import { Link } from "react-router-dom";
import loginskybluecircle from '../../../assets/images/loginskybluecircle.svg'
import loginyellowcircle from '../../../assets/images/loginyellowcircle.svg'
import loginvioletcircle from '../../../assets/images/loginvioletcircle.svg'
import logingreencircle from '../../../assets/images/logingreencircle.svg'
import backBtn from '../../../assets/images/backBtn.svg'

function Layout({ component, head, subhead }) {

  const year = new Date().getFullYear();
  //this is a common layout for all the screens for login/registration
  return (
    <>
      <div className={classes.common_layout}>
        <div className={classes.common_layout_left}>
          <div className={classes.common_layout_left_text}>
            <p>Welcome to</p>
            <p>Skids Health</p>
          </div>
          <div className={classes.common_layout_left_subtext}>
            <p>We break the walls and assist you with years records of your</p>
            <p>kids health from anyplace</p>
          </div>
          <div className={classes.common_layout_left_skyblue_circle}>
            <img src={loginskybluecircle} />
          </div>

          <div className={classes.common_layout_left_yellow_circle}>
            <img src={loginyellowcircle} alt="" />
          </div>

          <div className={classes.common_layout_left_violet_circle}>
            <img src={loginvioletcircle} alt="" />
          </div>

          <div className={classes.common_layout_left_green_circle}>
            <img src={logingreencircle} alt="" />
          </div>
        </div>
        <div className={classes.common_layout_right}>
          <div className={classes.common_layout_right_header}>
            {/* {head === 'Reset Password' ?
              (
                <div className={classes.common_layout_backbtn_container}>
                  <Link to="/forgot-password">
                    <img src={backBtn} />
                  </Link>
                  <p>Back to forgot password</p>
                </div>
              )
              :
              ( */}
            {/* <div ></div> */}



            <div>
              <img src={skidslogo} className={classes.skids_logo} alt=""></img>
            </div>
          </div>

          <div className={`${head === "" & subhead === "" ? `${classes.common_layout_content} ${classes.common_layout_content_centre}` :
            `${classes.common_layout_content}`}`}>

            <div className={classes.common_layout_header}>
              {head}
            </div>
            <div className={classes.common_layout_subhead}> {subhead} </div>
            <div>
              {component}
            </div>
          </div>

          <div className={classes.common_layout_policies}>
            <div>
              {" "}
              <Link to="/privacy-policy" style={{ textDecoration: "none", color: "#6b6c6f", fontFamily: "Inter" }}>
                Privacy policy
              </Link>{" "}
              |{" "}
              <Link to="/terms-and-conditions" style={{ textDecoration: "none", color: "#6b6c6f", fontFamily: "Inter" }}>
                Terms & conditions
              </Link>
            </div>
            <Link to="" style={{ textDecoration: "none" }} >
              <div className={classes.copyright_text}>© {year} SKIDS Technology Pvt. Ltd.</div>{" "}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
