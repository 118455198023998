import React, {useState} from "react";
import { verifyEmail } from "../common/commonFunctions";
// import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import Layout from "../common/Layout/Layout";
import InputPage from "../common/Input/Input";
import classes from "./ForgotPassword.module.css";
import { Auth } from "aws-amplify";
import { Alert, Snackbar } from "@mui/material";
// import { Mixpanel } from "../../utils/mixpanelWrapper";

const ForgotPasswordPage = () => {

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [open, setOpen] = useState({
    state: false,
    message: '',
    toast: 'success'
  });

  //handles email Id input and runs validation check
  const handleEmailId = (item) => {
    let emailid = verifyEmail(item);
    setErrorEmail(emailid);
    setEmail(item);
    setIsSubmit(item);
  };

  //generates OTP in email and redirects to OTP Page
  const handleForgotPassword = () => {
    Auth.forgotPassword(email)
      .then((data) => {
        setOpen((prev) => {
          return {
            ...prev,
            message: "OTP sent successfully on your email",
            toast: "success",
            state: true
          }
        })
        navigate('/otp-verification/resetPassword', {state:{email}})
      })
      .catch((err) => {
        setOpen((prev) => {
          return {
            ...prev,
            message: "Problem while sending OTP, please try after sometime",
            toast: "error",
            state: true
          }
        })
      });
    // navigate('/otp-verification/resetPassword')
  };

  const handleClose = () => {
    setOpen(() => {
      return {
        message: "",
        toast: "error",
        state: false
      }
    });
  }

  return (
    <>
      <div className={classes.forget_password_page}>
        <InputPage
          placeholder={"Email Address"}
          isPassword={false}
          width={'31.667vw'}
          change={(e) => {
            handleEmailId(e.target.value);
          }}

          value={email}
        ></InputPage>
        {errorEmail ? (
          <div className={classes.error_text}>Please enter valid Email Id</div>
        ) : (
          <></>
        )}
        {isSubmit && !errorEmail ? (
            <button
              className={classes.submit_button}
              style={{ opacity: 1 }}
              onClick={() => {
                handleForgotPassword();
              }}
            >
              Send OTP
            </button>
        ) : (
          <button
            className={classes.submit_button}
            style={{ opacity: 0.25 }}
            disabled
          >
            Change Password
          </button>
        )}
        <Snackbar open={open.state} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert onClose={handleClose} severity={open.toast} sx={{ width: '100%' }}>
            {open.message}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

function ForgotPassword() {
  return (
    <>
      <Layout
        component={ForgotPasswordPage()}
        head={"Forgot password"}
        subhead={
          "Enter your registered email and we will share an OTP."
        }
      />
    </>
  );
}

export default ForgotPassword;
