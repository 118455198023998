import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./PrincipalCalendar.module.css";
import { City } from "country-state-city";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
// import CustomAutoComplete from "../CustomAutoComplete/CustomAutoComplete";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DashboardLayout from "../../common/DashboardLayout/DashboardLayout";
import DatePickerInput from '../../common/InputDashboard/DatePickerInput'
import SchoolRow from '../../common/SchoolTable/SchoolRow'

function PrincipalCalendarPage() {
  const location = useLocation();
  const navigate = useNavigate()



  function getWordBeforeDash() {
    let newStr = "";
    for (let i = 1; i < location.pathname.length; i++) {
      if (location.pathname[i] === "-") {
        return newStr;
      }
      newStr += location.pathname[i];
    }
  }

  const [open, setOpen] = useState(false);
  const [sec, setSec] = useState(false);
  const [text, setText] = useState("");
  const [displayData, setDisplayData] = useState([]);
  const [city, setCity] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageNo, setPageNo] = useState(1)

  return (
    <div className= {classes.main_div}>
    <div className={classes.student_layout}>

      <div>
        
      </div>
    </div>
    <div className= {classes.student_filters}>
      <div>
        <DatePickerInput placeholder = {"From"}/>
      </div>
      <div>
        <DatePickerInput placeholder = {"To"}/>
      </div>
    </div>
      {/* Student Table Header */}
      <div className={classes.student_table_header}>
        <div>SCHOOL NAME</div>
        <div>PINCODE</div>
        <div>SCHOOL CODE</div>
        <div>BOARD AFFILIATION</div>
        <div>ORGANIZATION</div>
        <div>EVENT DATE</div>
        <div>STATUS</div>
        <div>ACTION</div>
      </div>
      {/* Student Column-Rows Section */}
      <div className={classes.student_column_container}>
        {displayData?.slice(rowsPerPage*(pageNo-1), rowsPerPage*pageNo).map((el, index) => {
          return (
            <SchoolRow data={el} key={index + Date.now()} index={index} />
          )
        })}
      </div>
      <div className={classes.pagination_container}>
        <div className={classes.page_item}>{`Showing ${(rowsPerPage*(pageNo-1))+1} to ${pageNo*rowsPerPage<=displayData.length ? pageNo*rowsPerPage : displayData.length} of ${displayData.length} items`}</div>
        <div className={classes.pagination}>
          <Button size="small" disabled={pageNo===1} onClick={()=>setPageNo(pageNo-1)} >
            <ChevronLeft/>
          </Button>
            <span>{pageNo}</span>
          <div>
            <span>of</span>
            <span>{Math.ceil(displayData.length/rowsPerPage)}</span>
          </div>

          <div>
            <Button size="small" disabled={pageNo===Math.ceil(displayData.length/rowsPerPage)} onClick={()=>setPageNo(pageNo+1)} >
              <ChevronRight/>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function PrincipalCalendar() {
  return (
      <PrincipalCalendarPage />
  );
}
export default PrincipalCalendar;