import React, { useEffect } from "react";
import Infirmary from '../../common/InfirmaryTable/Infirmary'
import { useDispatch, useSelector } from "react-redux";
import userReducer from "../../../redux/reducers/user.reducer";

export default function AdminInfirmary(){
    const dispatch = useDispatch()
    const data = useSelector((state) => state.userReducer.users)
    const user = useSelector((state) => state.userReducer.currentUser.schoolId);
    const getInfirmary = userReducer.actions.getSchoolInfirmaryRequest;

    useEffect(()=>{
        dispatch(getInfirmary(user))
    },[])
    return (
        <>
            <Infirmary data={data} />
        </>
    )
}