import React,{useState} from 'react';
import Collapse from "@mui/material/Collapse";
import AddIcon from "@mui/icons-material/Add";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import  classes from "./CollapseTable.module.css";



export function CollapseTable({data}){
    const [isChecked, setIsChecked] = useState(false);
    
  return(
<div>
      <div className={classes.main_container}>
        <div className={classes.btn_label}>
          <div className={classes.btn} onClick={() => setIsChecked((prev) => !prev)}>
            {isChecked ? (
              <HorizontalRuleIcon
                sx={{ fontSize: "15px" }} style={{fill: "#fff"}}
                className={classes.minus}
              />
            ) : (
              <AddIcon sx={{ fontSize: "15px",}} style={{fill: "#fff"}}  className={classes.add} />
            )}
          </div>
        </div>
        <div className={classes.name_label}>
          <label>{data.fatherName ? data.fatherName : "Unavailable" }</label>
        </div>
      </div>

      <div className={classes.parent_detail}>
        <Collapse in={isChecked}>
          {/* <p>{data?.fatherName}</p> */}
          <p>{data?.fatherContact?.mobile ?? data.labelName}02-01-2023</p>
          {/* <br/> */}
          <p>{data?.fatherContact?.email}</p>
          {/* <p>{data?.motherContact?.mobile}</p> */}
        </Collapse>
      </div>
    </div>
  )
}