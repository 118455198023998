import { createSlice } from "@reduxjs/toolkit";
import InitialStore from "../store/InitialStore";


export const getSupportTicketsRequest = (state, action) => {
    state.tickets = []
    state.loading = true
    state.error = false
    state.errorMessage = ''
}

export const getSupportTicketsSuccess = (state, action) => {
    state.tickets = action.payload
    state.loading = false
}

export const getSupportTicketsFail = (state, action) => {
    state.error = true
    state.loading = false
}

export const getSupportTicketByIdRequest = (state, action) => {
    state.currentTicket = {}
    state.currentTicketLoading = true
    state.error = false
    state.errorMessage = ''
}
export const getSupportTicketByIdSuccess = (state, action) => {
    state.currentTicket = action.payload
    state.currentTicketLoading = false
}
export const getSupportTicketByIdFail = (state, action) => {
    state.currentTicketLoading = false
    state.error = true
}

export const updateSupportTicketRequest = (state, action) => {
    state.currentTicket = {}
    state.currentTicketLoading = true
    state.error = false
    state.errorMessage = ''
}
export const updateSupportTicketSuccess = (state, action) => {
    state.currentTicket = action.payload
    state.currentTicketLoading = false
}
export const updateSupportTicketFail = (state, action) => {
    state.currentTicketLoading = false
    state.error = true
}

export default createSlice({
    name: "supportReducer",
    initialState: InitialStore.support,
    reducers: {
        getSupportTicketsRequest,
        getSupportTicketsSuccess,
        getSupportTicketsFail,
        getSupportTicketByIdRequest,
        getSupportTicketByIdSuccess,
        getSupportTicketByIdFail,
        updateSupportTicketRequest,
        updateSupportTicketSuccess,
        updateSupportTicketFail
    }
})