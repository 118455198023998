import React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import classes from './InputDashboard.module.css'
import { TextField } from "@mui/material";

function InputDashboard({ width, change, inputSize, placeholder, value, bgColor, disabled, margin, required, type, height, multiline }) {


  return (
    <div>
      {/* <FormControl
        required
        onChange={change}
      >
        <InputLabel
          htmlFor="filled-adornment-password"
          sx={{ color: "#6b6c6f", fontSize: "12px" }}
          
        >
          {placeholder}
        </InputLabel> */}
      {/* <TextField
          disabled={disabled}
          id="filled-adornment-password"
          type={"text"}
          sx={{
            backgroundColor: bgColor,
            "&:hover": {
              backgroundColor: bgColor,
            },
          }}
          value={value}
        /> */}
      {/* <InputLabel
          htmlFor="filled-adornment-password"
          sx={{ color: "#6b6c6f", fontSize: "12px" }}
          
        >{placeholder}</InputLabel> */}
      <TextField
        label={placeholder}
        // placeholder={placeholder}
        id="outlined-size-small"
        value={value}
        size={inputSize ?? "small"}
        multiline={multiline ?? false}
        maxRows={multiline ? 5 : 1}
        required={required === false ? false : true}
        sx={
          {
            width: width,
            height: height ?? "6vh",
            // border: "1px solid #e8e7e7",
            // overflow: "hidden",
            // borderRadius: 6,
            backgroundColor: { bgColor },
            mt: margin,
          }
        }
        onChange={change}
        InputProps={{
          classes: {
            notchedOutline: classes['input-border']
          },
        }}
        InputLabelProps={{
          classes: {
            root: classes.inputLabel,
            focused: classes.inputLabel,
            asterisk: classes.labelAsterisk
          }
        }}
        type={type === "number" ? "number" : "text"}
        disabled={disabled}
      />
      {/* </FormControl> */}
    </div>
  );
}

export default InputDashboard;
