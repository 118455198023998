import React, { useState } from "react";
import Layout from "../common/Layout/Layout";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputPage from "../common/Input/Input";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { verifyEmail } from "../common/commonFunctions";
import { countryCode } from "../common/constants";
import classes from "./RegistrationPage.module.css";
import { signUp } from "../../Auth/signup";


const Registration = () => {
  const [role, setRole] = useState("");
  const [phone, setPhone] = useState('');
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [user, setUser] = useState("");
  const [username, setUsername] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorStatement, setErrorStatement] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [countryOption, setCountryOption] = useState("+91");

  //error messages according to the password validation
  const errors = {
    capital: "The password must contain at least 1 Uppercase character",
    lower: "The password must contain at least 1 Lowercase character",
    digit: "The password must contain at least 1 numeric character",
    specialcharacter:
      "The password must contain at least one special character",
    lengthOfPass: "The password must be 8 characters or longer",
  };

  //handles email Id input and runs validation check
  const handleEmailId = (item) => {
    let emailid = verifyEmail(item);
    setErrorEmail(emailid);
    setUsername(item);
  };

  return (
    <>
      <div className={classes.login_page}>
        <div>Select user type</div>
        <div>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="school"
              name="radio-buttons-group"
              style={{ flexDirection: "row" }}
            >
              <FormControlLabel
                value="doctor"
                control={<Radio />}
                label="Doctor"
                onClick={(e) => {
                  setRole(e.target.value);
                }}
              />
              <FormControlLabel
                value="school"
                control={<Radio />}
                label="School"
                onClick={(e) => {
                  setRole(e.target.value);
                }}
              />
              <FormControlLabel
                value="parent"
                control={<Radio />}
                label="Parent"
                onClick={(e) => {
                  setRole(e.target.value);
                }}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.input_name}>
          <InputPage
            placeholder={"Enter first name"}
            isPassword={false}
            width={"12vw"}
            value={firstName}
            change={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <InputPage
            placeholder={"Enter last name"}
            isPassword={false}
            width={"12vw"}
            value={lastName}
            change={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>
        {/* <InputPage
          placeholder={"Enter username"}
          isPassword={false}
        ></InputPage> */}
        <div className={classes.input_name}>
          <select
            value={countryOption}
            onChange={(e) => {
              setCountryOption(e.target.value);
            }}
            className={classes.country_code}
          >
            {countryCode.map((option) => (
              <option value={option.dial_code}>
                {option.code}
                {option.dial_code}
              </option>
            ))}
          </select>
          <InputPage
          placeholder={"Enter mobile number"}
          isPassword={false}
          width={"17vw"}
          value={phone}
          change={(e) => setPhone(e.target.value)}
        ></InputPage>
        </div>
        <InputPage
          placeholder={"Enter email id"}
          isPassword={false}
          width={"25vw"}
          value={username}
          change={(e) => handleEmailId(e.target.value)}
        ></InputPage>
        {errorEmail ? (
          <div className={classes.error_text}>Please enter valid Email Id</div>
        ) : (
          <></>
        )}
        {errorPassword ? (
          <div className={classes.error_text}>{errorStatement}</div>
        ) : (
          <></>
        )}

        {!errorEmail ? (
            <button
              className={classes.submit_button}
              style={{ opacity: 1 }}
              onClick={() => {
                signUp(countryOption, phone, username, firstName, lastName);
              }}
            >
              Create account
            </button>
        ) : (
          <button
            className={classes.submit_button}
            style={{ opacity: 0.25 }}
            disabled
          >
            Create account
          </button>
        )}
        <div style={{ fontSize: "0.8em", textAlign: "center" }}>
          Already have an account?
          <Link
            to="/login"
            style={{ textDecoration: "none", color: "#222cc9" }}
          >
            Login
          </Link>{" "}
        </div>
      </div>
    </>
  );
};

function RegistrationPage() {
  return (
    <>
      <Layout component={Registration()} head={"Create account"} subhead={""} />
    </>
  );
}

export default RegistrationPage;
