import * as React from "react";
import classes from "./Checkmark.module.css";
const Checkanimated = (props) => (
    <div className={classes.wrapper} onClick={props.onClick}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            viewBox="0 0 98.5 98.5"
            {...props}
        >
            <path
                fill="none"
                strokeWidth="7"
                d="M81.7 17.8C73.5 9.3 62 4 49.2 4 24.3 4 4 24.3 4 49.2s20.3 45.2 45.2 45.2 45.2-20.3 45.2-45.2c0-8.6-2.4-16.6-6.5-23.4L45.6 68.2 24.7 47.3"
                className={props?.active ? classes.checkmark : classes.checkmarkInactive}
            />
        </svg>
    </div>
)
export default Checkanimated
