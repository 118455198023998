import React, { useRef, useState } from 'react';
import { Camera } from "react-camera-pro";
import CameraIcon from '@mui/icons-material/Camera';
import { Modal } from '@mui/material';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import classes from './SkidsCamera.module.css';


const SkidsCamera = (props) => {
    const { viewCamera, handleClose, setImage } = props;
    const [cameraFront, setCameraFront] = useState(false);
    const camera = useRef();
    function handleCapture() {
        if (!!camera.current) {
            setImage(camera.current.takePhoto())
            handleClose();
        }
    }
    return (
        <Modal onClose={handleClose} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} open={viewCamera}>
            <div className={classes.modalContainer}>
                <Camera
                    aspectRatio={16 / 12}
                    ref={camera}
                    facingMode={cameraFront ? "user" : "environment"}
                />
                <div className={classes.captureButton}>
                    <CameraIcon onClick={handleCapture} sx={{ fontSize: '50px', fill: 'white' }} />
                </div>
                <div className={classes.flipButton}>
                    <FlipCameraAndroidIcon onClick={() => { setCameraFront(!cameraFront) }} sx={{ fill: 'white', fontSize: '40px' }} />
                </div>
            </div>
        </Modal>
    )
}

export default SkidsCamera