import { createSlice } from "@reduxjs/toolkit";
import InitialStore from "../store/InitialStore";

export const shareTypeformRequest = (state, action) => {
    state.loading = true
    state.error=false
    state.errorMessage=''
};

export const shareParentsTypeformRequest = (state, action) => {
    state.loading = true
    state.error=false
    state.errorMessage=''
};

export const shareTypeformSuccess = (state, action) => {
    state.loading = false
    state.error = false
};

export const shareTypeformFail = (state, action) => {
    
    state.error = true;
    state.errorMessage = action.payload.errorMessage
    state.loading = false
};

export default createSlice({
    name: "typeformReducer",
    initialState: InitialStore.typeform,
    reducers: {
        shareTypeformRequest,
        shareTypeformSuccess,
        shareTypeformFail,
        shareParentsTypeformRequest
    }
})