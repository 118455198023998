import { call, put, take, takeLatest } from "redux-saga/effects";
import clinincReducer from "../reducers/clininc.reducer";
import ApiServices from "../../graphqlApiServices";

const {clinics} = ApiServices

const {getclinics, getclinicById, createClinic, updateClinic, deactivateClinic, activateClinic, uploadClinicPhoto} = clinics

export function* watchClinicRequests() {
    yield takeLatest(clinincReducer.actions.getClinicRequest, getClinicSaga);
    yield takeLatest(clinincReducer.actions.getClinicByIdRequest, getClinicByIdSaga)
    yield takeLatest(clinincReducer.actions.createClinicRequest, createClinicSaga);
    yield takeLatest(clinincReducer.actions.updateClinicRequest, updateClinicSaga)
    yield takeLatest(clinincReducer.actions.deactivateClinicRequest, deactivateClinicSaga)
    yield takeLatest(clinincReducer.actions.activateClinicRequest, activateClinicSaga)
    yield takeLatest(clinincReducer.actions.uploadPhotoRequest, uploadLogoSaga)
}

export function* getClinicSaga() {
    try {
        const response = yield call(getclinics);
        const data = response.data.getClinics;
        yield put(clinincReducer.actions.getClinicSuccess({data}))
    } catch (error) {
        if(error.data?.getClinics?.length>0){
            const data = error.data.getClinics;
            yield put(clinincReducer.actions.getClinicSuccess({data}))
        }
        else{
            yield put(clinincReducer.actions.getClinicFail({error: error.errors[0]}))
        }
    }
}

export function* getClinicByIdSaga(payload) {
    try {
        const response = yield call(getclinicById, payload);
        const data = response.data.getClinicById;
        yield put(clinincReducer.actions.getClinicSuccess({data}))
    } catch (error) {
        if(error.data?.getClinics?.length>0){
            const data = error.data.getClinics;
            yield put(clinincReducer.actions.getClinicSuccess({data}))
        }
        else{
            yield put(clinincReducer.actions.getClinicFail({error: error.errors[0]}))
        }
    }
}

export function* createClinicSaga(payload) {
    try {
        const response = yield call(createClinic, payload);
        console.log(response)
        const data = response.data;
        yield put(clinincReducer.actions.createClinicSuccess(data))
        const formData = {
            pincode: payload.payload.pincode,
            logo: payload.payload.logo,
            clinicId: response?.data?.createClinic?.clinicCode
        }
        console.log(formData)
        yield put(clinincReducer.actions.uploadPhotoRequest(formData))
    } catch (error) {
        yield put(clinincReducer.actions.createClinicFail({error}))
    }
}

export function* updateClinicSaga(payload) {
    try {
        const response = yield call(updateClinic, payload);
        const data = response.data;
        yield put(clinincReducer.actions.updateClinicSuccess(data))
        if(payload.payload.logo){
            const formData = {
                pincode: payload.payload.pincode,
                logo: payload.payload.logo,
                clinicId: payload.payload.clinicCode
            }
            yield put(clinincReducer.actions.uploadPhotoRequest(formData))
        } else{
            setTimeout(() => window.history.back(), 1500)
        }
    } catch (error) {
        yield put(clinincReducer.actions.updatelinicFail({error}))
    }
}

export function* deactivateClinicSaga(payload) {
    try {
        const response = yield call(deactivateClinic, payload);
        const data = response.data;
        yield put(clinincReducer.actions.deactivateClinicSuccess({data}))
    } catch (error) {
        yield put(clinincReducer.actions.deactivateClinicFail({error}))
    }
}


export function* activateClinicSaga(payload) {
    try {
        const response = yield call(activateClinic, payload);
        const data = response.data;
        
        yield put(clinincReducer.actions.activateClinicSuccess({data}))
    } catch (error) {
        yield put(clinincReducer.actions.activateClinicFail({error}))
    }
}


export function* uploadLogoSaga(payload) {
    try {
        const response = yield call(uploadClinicPhoto, payload);
        const data = response.data.uploadSchoolLogo

        yield put(clinincReducer.actions.uploadPhotoSuccess({ data }))
        setTimeout(() => window.history.back(), 1500)
    } catch (error) {
        
        if (error.data) {
            const data = error.data.uploadSchoolLogo;
            yield put(clinincReducer.actions.uploadPhotoSuccess({ data }))
            setTimeout(() => window.history.back(), 1500)
        } else {
            yield put(clinincReducer.actions.uploadPhotoFail({ error: error }))
        }
    }
}