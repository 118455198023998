import * as React from "react"
import "../Main.css"
const Lungspoints = (props) => {
    const { issue, disabled } = props;
    return (
        <div
            className="lungspointCon"
            style={{
                top: "155px",
                right: "55px"
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={64}
                height={60}
                fill="none"
                {...props}
            >
                {/* <path
                    fill={disabled ? "#B2B7BE" : issue ? "#FF001D" : "#00B817"}
                    d="M20 8a4.057 4.057 0 0 1-1.58-.305 3.904 3.904 0 0 1-1.27-.845 3.903 3.903 0 0 1-.845-1.27A4.058 4.058 0 0 1 16 4c0-.56.102-1.083.305-1.57A3.903 3.903 0 0 1 18.42.31 4 4 0 0 1 20 0a3.975 3.975 0 0 1 2.84 1.16A3.974 3.974 0 0 1 24 4a4 4 0 0 1-1.16 2.85c-.36.36-.783.642-1.27.845A4.031 4.031 0 0 1 20 8Z"
                />
                <path
                    fill="#fff"
                    d="m19.41 4.91-.98-.98a.3.3 0 0 0-.22-.09.3.3 0 0 0-.22.09.322.322 0 0 0-.1.235c0 .09.03.165.09.225l1.22 1.22c.054.053.124.08.21.08a.284.284 0 0 0 .21-.08l2.4-2.4a.316.316 0 0 0-.01-.45.305.305 0 0 0-.224-.09.322.322 0 0 0-.235.1l-2.14 2.14Z"
                /> */}
                <path
                    fill={disabled ? "#B2B7BE" : issue ? "#FF001D" : "#00B817"}
                    d="M12 40a4.058 4.058 0 0 1-1.58-.305 3.903 3.903 0 0 1-1.27-.845 3.904 3.904 0 0 1-.845-1.27A4.057 4.057 0 0 1 8 36c0-.56.102-1.083.305-1.57a3.904 3.904 0 0 1 2.115-2.12A4 4 0 0 1 12 32a3.974 3.974 0 0 1 2.84 1.16A3.974 3.974 0 0 1 16 36a4 4 0 0 1-1.16 2.85c-.36.36-.783.642-1.27.845A4.032 4.032 0 0 1 12 40Z"
                />
                <path
                    fill="#fff"
                    d="m11.41 36.91-.98-.98a.3.3 0 0 0-.22-.09.3.3 0 0 0-.22.09.322.322 0 0 0-.1.235c0 .09.03.165.09.225l1.22 1.22c.054.053.124.08.21.08a.284.284 0 0 0 .21-.08l2.4-2.4a.316.316 0 0 0-.01-.45.305.305 0 0 0-.224-.09.322.322 0 0 0-.235.1l-2.14 2.14Z"
                />
                {/* <path
                    fill={disabled ? "#B2B7BE" : issue ? "#FF001D" : "#00B817"}
                    d="M4 60a4.058 4.058 0 0 1-1.58-.305 3.903 3.903 0 0 1-1.27-.845 3.904 3.904 0 0 1-.845-1.27A4.057 4.057 0 0 1 0 56c0-.56.102-1.083.305-1.57a3.904 3.904 0 0 1 2.115-2.12A4 4 0 0 1 4 52a3.974 3.974 0 0 1 2.84 1.16A3.975 3.975 0 0 1 8 56a4 4 0 0 1-1.16 2.85c-.36.36-.783.642-1.27.845A4.032 4.032 0 0 1 4 60Z"
                />
                <path
                    fill="#fff"
                    d="m3.41 56.91-.98-.98a.3.3 0 0 0-.22-.09.3.3 0 0 0-.22.09.322.322 0 0 0-.1.235c0 .09.03.165.09.225l1.22 1.22c.054.053.124.08.21.08a.284.284 0 0 0 .21-.08l2.4-2.4a.316.316 0 0 0-.01-.45.305.305 0 0 0-.224-.09.322.322 0 0 0-.235.1l-2.14 2.14Z"
                />
                <path
                    fill={disabled ? "#B2B7BE" : issue ? "#FF001D" : "#00B817"}
                    d="M44 8a4.057 4.057 0 0 1-1.58-.305 3.904 3.904 0 0 1-1.27-.845 3.903 3.903 0 0 1-.845-1.27A4.058 4.058 0 0 1 40 4c0-.56.102-1.083.305-1.57A3.903 3.903 0 0 1 42.42.31 4 4 0 0 1 44 0a3.975 3.975 0 0 1 2.84 1.16A3.974 3.974 0 0 1 48 4a4 4 0 0 1-1.16 2.85c-.36.36-.783.642-1.27.845A4.031 4.031 0 0 1 44 8Z"
                />
                <path
                    fill="#fff"
                    d="m43.41 4.91-.98-.98a.3.3 0 0 0-.22-.09.3.3 0 0 0-.22.09.322.322 0 0 0-.1.235c0 .09.03.165.09.225l1.22 1.22c.054.053.124.08.21.08a.284.284 0 0 0 .21-.08l2.4-2.4a.316.316 0 0 0-.01-.45.305.305 0 0 0-.224-.09.322.322 0 0 0-.235.1l-2.14 2.14Z"
                /> */}
                <path
                    fill={disabled ? "#B2B7BE" : issue ? "#FF001D" : "#00B817"}
                    d="M52 40a4.058 4.058 0 0 1-1.58-.305 3.903 3.903 0 0 1-1.27-.845 3.903 3.903 0 0 1-.845-1.27A4.058 4.058 0 0 1 48 36c0-.56.102-1.083.305-1.57a3.903 3.903 0 0 1 2.115-2.12A4 4 0 0 1 52 32a3.974 3.974 0 0 1 2.84 1.16A3.974 3.974 0 0 1 56 36a4 4 0 0 1-1.16 2.85c-.36.36-.783.642-1.27.845A4.032 4.032 0 0 1 52 40Z"
                />
                <path
                    fill="#fff"
                    d="m51.41 36.91-.98-.98a.3.3 0 0 0-.22-.09.3.3 0 0 0-.22.09.322.322 0 0 0-.1.235c0 .09.03.165.09.225l1.22 1.22c.054.053.124.08.21.08a.284.284 0 0 0 .21-.08l2.4-2.4a.316.316 0 0 0-.01-.45.305.305 0 0 0-.224-.09.322.322 0 0 0-.235.1l-2.14 2.14Z"
                />
                {/* <path
                    fill={disabled ? "#B2B7BE" : issue ? "#FF001D" : "#00B817"}
                    d="M60 60a4.058 4.058 0 0 1-1.58-.305 3.903 3.903 0 0 1-1.27-.845 3.903 3.903 0 0 1-.845-1.27A4.058 4.058 0 0 1 56 56c0-.56.102-1.083.305-1.57a3.903 3.903 0 0 1 2.115-2.12A4 4 0 0 1 60 52a3.974 3.974 0 0 1 2.84 1.16A3.974 3.974 0 0 1 64 56a4 4 0 0 1-1.16 2.85c-.36.36-.783.642-1.27.845A4.032 4.032 0 0 1 60 60Z"
                />
                <path
                    fill="#fff"
                    d="m59.41 56.91-.98-.98a.3.3 0 0 0-.22-.09.3.3 0 0 0-.22.09.322.322 0 0 0-.1.235c0 .09.03.165.09.225l1.22 1.22c.054.053.124.08.21.08a.284.284 0 0 0 .21-.08l2.4-2.4a.316.316 0 0 0-.01-.45.305.305 0 0 0-.224-.09.322.322 0 0 0-.235.1l-2.14 2.14Z"
                /> */}
            </svg>
        </div>
    )
}
export default Lungspoints
