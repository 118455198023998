import React, { useState } from "react";
import classes from './VideoComp.module.css';
import { PlayArrow } from "@mui/icons-material";

export default function VideoComp(props) {
    const { onClick, imageURL } = props;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // const imageURL = "https://my.clevelandclinic.org/-/scassets/Images/org/health/articles/21765-parts-of-the-mouth"


    return (
        <div style={{
            width: props?.size ? `${props.size}px` : "200px",
            height: props?.size ? `${props.size}px` : "200px"
        }} onClick={onClick} className={classes.slideshow_container}>
            <img className={classes.image_slides} src={props?.img} />
            <div className={classes.play_btn}><PlayArrow /></div>

        </div>
    )
}