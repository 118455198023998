import { Avatar, Box, Modal } from "@mui/material";
import React, { useState } from "react";
import classes from "./OpsStudentRow.module.css";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { useNavigate, useLocation } from "react-router-dom";
import RowPopup from "../../../common/TableComponents/RowPopup";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { numberTh } from "../../../../utils/utility";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center"
};

export default function OpsStudentRows({ data, index, cls }) {
  // const { id, schoolName, pincode, schoolCode, board, organization, eventDate, status, action, url } = data;
  const [user, setUser] = useState(false);
  const navigate = useNavigate();
  const location = useLocation()
  const [openPop, setOpenPop] = useState(false)

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getWordAfterDash = () => {
    let word = location.pathname.split('-')
    return word[1];
  }

  const getSchoolInfo = (whereTo) => {
    function getWordBeforeDash() {
      let newStr = "";
      for (let i = 1; i < location.pathname.length; i++) {
        if (location.pathname[i] === "-") {
          return newStr;
        }
        newStr += location.pathname[i];
      }
    }
    if (getWordBeforeDash() === "superadmin") {
      location.state.breadCrumbs.push(data.schoolName);
      // 
      navigate(`/${getWordBeforeDash()}-${getWordAfterDash()}/${whereTo}/${data?.schoolName}`, { state: { ...location.state, ...data } });
    }
  }



  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }


  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 12,
        height: 12,
        padding: 2
      },
      children: `${name[0]}`,
    };
  }


  const handleReport = () => {
    setUser(true)
  }
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img src={data?.qrCode} alt="Unavailable" />
          <p>{data?.studentName}</p>
        </Box>
      </Modal>
      <div className={classes.studentRow_container}>

        {/* Name and Avatar Container */}
        <div>
          {/* Avatar Container */}
          {/* {url ? (
            <div>
              <img src={url} alt="schoolLogo" width="32" height="32" />
            </div>
          ) : ( */}
          <div>
            <Avatar {...stringAvatar(`${data?.studentName} `)} />
          </div>
          {/* )} */}


          {/* Name Container */}
          <div>
            <p>{data?.studentName ?? "Unavailable"}</p>
          </div>
        </div>

        {/* Gender Container */}
        <div>
          <p>{data?.DOB ?? "Unavailable"}</p>
        </div>

        {/* Class Container */}
        <div>
          <p>{data?.gender ?? "Unavailable"}</p>
        </div>

        {/* Email Container */}
        <div>
          <p>{(numberTh(data?.class) + "-" + data?.section) ?? "Unavailable"}</p>

        </div>
        {/* Screening Container */}
        <div>
          <p>{data?.uuid ?? "Unavailable"}</p>
        </div>



        {/*  Validated Container */}
        <div>
          {/* <p>{`${data?.screeningFrom ?? "Unavailable"} - ${data?.screeningTo ?? "Unavailable"}`}</p> */}
          <p onClick={handleOpen}><img src={data?.qrCode} alt="Unavailable" width={29} /></p>
        </div>


        {/* Conditional Buttons Rendering --Role Section */}
        {/* {index < 3 ? (
          <div>
            {user ? (<button className={classes.screened_btn}>INACTIVE</button>) :
              (<button onClick={handleReport} className={classes.report_btn}>SAVED</button>)
            }
          </div>
        ) : ( */}
        <div>
          {
            (<div className={classes.generated_btn}>GENERATED</div>)
          }
        </div>
        {/* )} */}

        {/* Action Container */}
        <div style={{ cursor: 'pointer', position: "relative", justifySelf: "center" }} onClick={() => setOpenPop(!openPop)}>
          <p>...</p>
          {/* {openPop ? <RowPopup navLink={getSchoolInfo} /> : <></>} */}
        </div>
      </div>

    </>
  );
}