import * as React from "react"
const SvgComponent = (props) => (
  <div style={{ ...props.customStyle }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
      {...props}
    >
      <g
        stroke="#EF1210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        clipPath="url(#a)"
      >
        <path d="M32.513 27.447s4.413-.462 3.06-3.082c-1.352-2.621-3.06-5.167-3.06-5.167S34.759 2.492 20.042 2.492 1.908 15.59 5.467 21.745l3.56 6.159v7.787s-.143 1.804 1.814 1.804h12.704s1.416-.161 1.416-1.408v-1.954S25.324 33 26.82 33h2.341s2 .142 2.356-1.485c.355-1.628.996-4.068.996-4.068Z" />
        <path d="M28.195 8.762a2.444 2.444 0 0 0-1.93-.629S25.19 4.955 21.647 6.34c0 0-3.827-3.275-6.611 1.037 0 0-4.143-2.263-5.377 2.299 0 0-4.646 2.012-1.012 5.977 0 0-1.487 2.422.063 4.467M29.49 11.54s.57 1.385 0 2.735c0 0 1.868 6.513-4.838 4.563 0 0-1.456 2.673-4.113 1.663 0 0-2.278 2.982-5.188.972 0 0-2.309 2.425-4.646 1.105" />
        <path d="m25.093 11.727 2.436 5.032-5.125-2.265M25.689 12.96l-2.082 2.071M19.713 7.875s-1.644.944-1.3 2.61c.343 1.668 2.31 1.889 3.132 1.48.822-.41 1.866-1.762 1.138-3.05M11.521 10.705 13.2 9.289l2.498 3.024-1.455 1.286M13.072 12.002l1.308-1.127M12.533 15.117l1.847 4.251s-1.942 1.824-2.861.473c-.92-1.35 1.552-2.265 1.552-2.265s-2.122 1.023-2.438-.228c.002-.002-.473-1.48 1.9-2.23ZM17.155 14.18l3.922 2.151s-1.153 2.772-3.258 1.557c-2.105-1.215-1.147-2.85-.664-3.708Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M3.334 1.664h33.333v36.667H3.334z" />
        </clipPath>
      </defs>
    </svg>
  </div>
)
export default SvgComponent

