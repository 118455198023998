import * as React from "react"
const Heartpoint = (props) => {
    const { issue, disabled } = props;
    return (
        <div
            style={{
                top: "140px",
                right: "55px"
            }}
            className="heartpointsCon">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={36}
                height={32}
                fill="none"
                {...props}
            >
                <path
                    fill={disabled ? "#B2B7BE" : issue ? "#FF001D" : "#00B817"}
                    d="M25 6c-.425 0-.82-.076-1.185-.229a2.928 2.928 0 0 1-.953-.633 2.928 2.928 0 0 1-.633-.953A3.043 3.043 0 0 1 22 3c0-.42.076-.813.229-1.177a2.928 2.928 0 0 1 1.586-1.59A3 3 0 0 1 25 0a2.98 2.98 0 0 1 2.767 1.823C27.922 2.188 28 2.58 28 3a3 3 0 0 1-.87 2.138c-.27.27-.587.48-.953.633A3.023 3.023 0 0 1 25 6Z"
                />
                <path
                    fill="#fff"
                    d="m24.558 3.682-.735-.735a.224.224 0 0 0-.165-.068.224.224 0 0 0-.165.068.241.241 0 0 0-.075.176c0 .068.023.124.067.169l.916.915c.04.04.092.06.157.06s.117-.02.157-.06l1.8-1.8a.237.237 0 0 0-.008-.338.23.23 0 0 0-.168-.067.241.241 0 0 0-.176.075l-1.605 1.605Z"
                />
                {/* <path
                    fill={disabled ? "#B2B7BE" : issue ? "#FF001D" : "#00B817"}
                    d="M25 20c-.425 0-.82-.076-1.185-.229a2.928 2.928 0 0 1-.953-.634 2.928 2.928 0 0 1-.633-.952A3.043 3.043 0 0 1 22 17c0-.42.076-.813.229-1.178a2.928 2.928 0 0 1 1.586-1.59A3 3 0 0 1 25 14a2.98 2.98 0 0 1 2.767 1.822c.155.366.233.758.233 1.178a3 3 0 0 1-.87 2.137c-.27.27-.587.482-.953.634A3.023 3.023 0 0 1 25 20Z"
                />
                <path
                    fill="#fff"
                    d="m24.558 17.682-.735-.735a.224.224 0 0 0-.165-.067.224.224 0 0 0-.165.067.241.241 0 0 0-.075.176.23.23 0 0 0 .067.169l.916.915c.04.04.092.06.157.06s.117-.02.157-.06l1.8-1.8a.237.237 0 0 0-.008-.337.23.23 0 0 0-.168-.068.241.241 0 0 0-.176.075l-1.605 1.605Z"
                />
                <path
                    fill={disabled ? "#B2B7BE" : issue ? "#FF001D" : "#00B817"}
                    d="M33 32c-.425 0-.82-.076-1.185-.229a2.928 2.928 0 0 1-.953-.634 2.928 2.928 0 0 1-.633-.952A3.043 3.043 0 0 1 30 29c0-.42.076-.813.229-1.177a2.928 2.928 0 0 1 1.586-1.59A3 3 0 0 1 33 26a2.98 2.98 0 0 1 2.767 1.823c.155.364.233.757.233 1.177a3 3 0 0 1-.87 2.137c-.27.27-.588.482-.953.634A3.023 3.023 0 0 1 33 32Z"
                />
                <path
                    fill="#fff"
                    d="m32.558 29.682-.735-.735a.224.224 0 0 0-.165-.067.224.224 0 0 0-.165.067.241.241 0 0 0-.075.176.23.23 0 0 0 .067.169l.916.915c.04.04.092.06.157.06s.117-.02.157-.06l1.8-1.8a.237.237 0 0 0-.008-.337.23.23 0 0 0-.168-.068.241.241 0 0 0-.176.075l-1.605 1.605Z"
                />
                <path
                    fill={disabled ? "#B2B7BE" : issue ? "#FF001D" : "#00B817"}
                    d="M3 6c-.425 0-.82-.076-1.185-.229a2.928 2.928 0 0 1-.952-.633 2.928 2.928 0 0 1-.634-.953A3.043 3.043 0 0 1 0 3c0-.42.076-.813.229-1.177A2.928 2.928 0 0 1 1.815.232 3 3 0 0 1 3 0a2.98 2.98 0 0 1 2.768 1.823C5.923 2.188 6 2.58 6 3a3 3 0 0 1-.87 2.138c-.27.27-.587.48-.952.633A3.023 3.023 0 0 1 3 6Z"
                />
                <path
                    fill="#fff"
                    d="m2.558 3.682-.735-.735a.224.224 0 0 0-.165-.068.224.224 0 0 0-.165.068.241.241 0 0 0-.075.176c0 .068.022.124.067.169l.915.915c.04.04.093.06.158.06s.117-.02.157-.06l1.8-1.8a.237.237 0 0 0-.007-.338.229.229 0 0 0-.169-.067.241.241 0 0 0-.176.075L2.558 3.682Z"
                /> */}
            </svg>
        </div>
    )
}
export default Heartpoint
