import * as React from "react";
import "../Main.css";

// const Eye = (props) => {
//   const { active, issue, onClick, disabled, top, right, scale } = props;

//   return disabled ? (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width={16}
//       height={16}
//       viewBox="0 0 14 14"
//       fill="none"
//       className={`mainCon`}
//       style={{
//         right: right,
//         top: top,
//         transform: `scale(${scale ?? 1})`,
//       }}
//       {...props}
//     >
//       <circle cx={7} cy={7} r={7} fill="#B2B7BE" opacity="0.16" />
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M3.07892 6.56487C3.50296 6.05397 4.14652 5.46868 4.97264 5.07032C4.53973 5.58124 4.27618 6.25787 4.27618 7.00003C4.27618 7.74219 4.53973 8.41882 4.97264 8.92974C4.14652 8.53137 3.50296 7.94609 3.07892 7.43519C2.86979 7.18325 2.86979 6.81681 3.07892 6.56487ZM2.74063 6.24682C3.50975 5.32017 4.97158 4.13994 6.94418 4.11799C6.95756 4.11777 6.97096 4.11768 6.98439 4.11768H6.99096H7.00012C9.00102 4.11768 10.4832 5.31145 11.2596 6.24682C11.6195 6.68045 11.6195 7.31961 11.2596 7.75324C10.4832 8.68861 9.00102 9.88238 7.00012 9.88238H6.99096H6.98439C6.97096 9.88238 6.95756 9.88229 6.94418 9.88207C4.97158 9.86012 3.50975 8.67989 2.74064 7.75324C2.38073 7.31961 2.38072 6.68045 2.74063 6.24682ZM6.9908 4.59807C6.9771 4.59812 6.96343 4.59819 6.94977 4.59836C5.71932 4.61803 4.72755 5.68577 4.72755 7.00003C4.72755 8.31429 5.71932 9.38203 6.94977 9.4017C6.96343 9.40187 6.9771 9.40194 6.9908 9.40199C8.23428 9.39831 9.24124 8.32433 9.24124 7.00003C9.24124 5.67573 8.23428 4.60174 6.9908 4.59807ZM9.69261 7.00003C9.69261 7.75446 9.42027 8.44118 8.97452 8.95494C9.82617 8.55636 10.4882 7.95697 10.9213 7.43519C11.1304 7.18325 11.1304 6.81681 10.9213 6.56487C10.4882 6.04309 9.82617 5.4437 8.97452 5.04512C9.42027 5.55888 9.69261 6.2456 9.69261 7.00003ZM7.0001 8.44121C7.74795 8.44121 8.35421 7.79597 8.35421 7.00003C8.35421 6.77753 8.30684 6.56683 8.22225 6.37869C8.14055 6.46576 8.02762 6.51964 7.90286 6.51964C7.65357 6.51964 7.45149 6.30456 7.45149 6.03924C7.45149 5.90646 7.50211 5.78627 7.58392 5.69932C7.40715 5.60927 7.20915 5.55885 7.0001 5.55885C6.25225 5.55885 5.64599 6.20409 5.64599 7.00003C5.64599 7.79597 6.25225 8.44121 7.0001 8.44121Z"
//         fill="#B2B7BE"
//       />
//     </svg>
//   ) : (
//     <svg
//       width={16}
//       height={16}
//       viewBox="0 0 14 14"
//       fill="none"
//       onClick={onClick}
//       className={`mainCon
//             ${active && (issue ? "activeRed" : "activeGreen")}
//             `}
//       style={{
//         right: right,
//         top: top,
//         transform: `scale(${scale ?? 1})`,
//       }}
//       {...props}
//     >
//       <circle cx={7} cy={7} r={7} fill={issue ? "#f0c5c5" : "#CAEFCF"} />
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M3.07892 6.56487C3.50296 6.05397 4.14652 5.46868 4.97264 5.07032C4.53973 5.58124 4.27618 6.25787 4.27618 7.00003C4.27618 7.74219 4.53973 8.41882 4.97264 8.92974C4.14652 8.53137 3.50296 7.94609 3.07892 7.43519C2.86979 7.18325 2.86979 6.81681 3.07892 6.56487ZM2.74063 6.24682C3.50975 5.32017 4.97158 4.13994 6.94418 4.11799C6.95756 4.11777 6.97096 4.11768 6.98439 4.11768H6.99096H7.00012C9.00102 4.11768 10.4832 5.31145 11.2596 6.24682C11.6195 6.68045 11.6195 7.31961 11.2596 7.75324C10.4832 8.68861 9.00102 9.88238 7.00012 9.88238H6.99096H6.98439C6.97096 9.88238 6.95756 9.88229 6.94418 9.88207C4.97158 9.86012 3.50975 8.67989 2.74064 7.75324C2.38073 7.31961 2.38072 6.68045 2.74063 6.24682ZM6.9908 4.59807C6.9771 4.59812 6.96343 4.59819 6.94977 4.59836C5.71932 4.61803 4.72755 5.68577 4.72755 7.00003C4.72755 8.31429 5.71932 9.38203 6.94977 9.4017C6.96343 9.40187 6.9771 9.40194 6.9908 9.40199C8.23428 9.39831 9.24124 8.32433 9.24124 7.00003C9.24124 5.67573 8.23428 4.60174 6.9908 4.59807ZM9.69261 7.00003C9.69261 7.75446 9.42027 8.44118 8.97452 8.95494C9.82617 8.55636 10.4882 7.95697 10.9213 7.43519C11.1304 7.18325 11.1304 6.81681 10.9213 6.56487C10.4882 6.04309 9.82617 5.4437 8.97452 5.04512C9.42027 5.55888 9.69261 6.2456 9.69261 7.00003ZM7.0001 8.44121C7.74795 8.44121 8.35421 7.79597 8.35421 7.00003C8.35421 6.77753 8.30684 6.56683 8.22225 6.37869C8.14055 6.46576 8.02762 6.51964 7.90286 6.51964C7.65357 6.51964 7.45149 6.30456 7.45149 6.03924C7.45149 5.90646 7.50211 5.78627 7.58392 5.69932C7.40715 5.60927 7.20915 5.55885 7.0001 5.55885C6.25225 5.55885 5.64599 6.20409 5.64599 7.00003C5.64599 7.79597 6.25225 8.44121 7.0001 8.44121Z"
//         fill="black"
//       />
//     </svg>
//   );
// };
// export default Eye;

const Eye = (props) => {
  const { active, issue, onClick, disabled, top, right, scale } = props;

  return disabled ? (
    <div
      className={`mainCon`}
      style={{
        right: right,
        top: top,
        transform: `scale(${scale ?? 1})`,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <circle cx={12} cy={12} r={12} fill="#B2B7BE" opacity="0.16" />
        <path
          fill="#B2B7BE"
          fillRule="evenodd"
          d="M6.23 11.396a8.468 8.468 0 0 1 2.797-2.074A3.986 3.986 0 0 0 8 12c0 1.03.389 1.969 1.028 2.678a8.47 8.47 0 0 1-2.797-2.074.903.903 0 0 1 0-1.208Zm-.5-.441C6.868 9.669 9.027 8.03 11.94 8H12.021c2.955 0 5.144 1.657 6.291 2.955a1.57 1.57 0 0 1 0 2.09C17.166 14.343 14.977 16 12.022 16H11.939c-2.913-.03-5.072-1.669-6.208-2.955a1.57 1.57 0 0 1 0-2.09Zm6.278-2.288h-.06a3.333 3.333 0 1 0 .06 0ZM15.998 12c0 1.047-.401 2-1.06 2.713a8.455 8.455 0 0 0 2.876-2.11.903.903 0 0 0 0-1.207 8.455 8.455 0 0 0-2.876-2.109A3.985 3.985 0 0 1 15.998 12Zm-3.976 2a2 2 0 0 0 1.805-2.862.667.667 0 1 1-.943-.943A2 2 0 1 0 12.022 14Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  ) : (
    <div
      onClick={onClick}
      className={`mainCon  
            ${active && (issue ? "activeRed" : "activeGreen")} 
            `}
      style={{
        right: right,
        top: top,
        backgroundColor: active ? "#ffffff" : "",
        transform: `scale(${scale ?? 1})`,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <circle
          cx={12}
          cy={12}
          r={12}
          fill={active ? "#ffffff" : issue ? "#f0c5c5" : "#CAEFCF"}
        />
        <path
          fill="#115EA3"
          fillRule="evenodd"
          d="M6.23 11.396a8.468 8.468 0 0 1 2.797-2.074A3.986 3.986 0 0 0 8 12c0 1.03.389 1.969 1.028 2.678a8.47 8.47 0 0 1-2.797-2.074.903.903 0 0 1 0-1.208Zm-.5-.441C6.868 9.669 9.027 8.03 11.94 8H12.021c2.955 0 5.144 1.657 6.291 2.955a1.57 1.57 0 0 1 0 2.09C17.166 14.343 14.977 16 12.022 16H11.939c-2.913-.03-5.072-1.669-6.208-2.955a1.57 1.57 0 0 1 0-2.09Zm6.278-2.288h-.06a3.333 3.333 0 1 0 .06 0ZM15.998 12c0 1.047-.401 2-1.06 2.713a8.455 8.455 0 0 0 2.876-2.11.903.903 0 0 0 0-1.207 8.455 8.455 0 0 0-2.876-2.109A3.985 3.985 0 0 1 15.998 12Zm-3.976 2a2 2 0 0 0 1.805-2.862.667.667 0 1 1-.943-.943A2 2 0 1 0 12.022 14Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};
export default Eye;
