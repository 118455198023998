import React, { useState, useEffect } from "react";
import classes from "../common/AddOrganization/AddOrganization.module.css";
import upload from "../../assets/images/upload.svg";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import InputDashboard from "../common/InputDashboard/InputDashboard";
import InputDropdown from "../common/InputDashboard/InputDropdown";
import DatePickerInput from "../common/InputDashboard/DatePickerInput";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { State, City } from "country-state-city";
import Cookies from "js-cookie";
import MultipleSelectChip from "../common/MultiSelectDropdown/MultiSelectDropdown";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import { textFormat, validateEmail } from "../../utils/utility";
import { Buffer } from "buffer";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";
import notification from "../../assets/audios/notificationSound.mp3"
import moment from "moment";
import clinincReducer from "../../redux/reducers/clininc.reducer";

function EditClinicPage() {
  let notify = new Audio(notification);
  const token = Cookies.get("tokenId");

  const location = useLocation()
  const { entity } = useParams();
  const navigate = useNavigate();
  const updateClinic = clinincReducer.actions.updateClinicRequest;
  const dispatch = useDispatch();
  const [schoolName, setSchoolName] = useState("");
  const [established, setEstablished] = useState("");
  const [screeningTo, setScreeningTo] = useState("");
  const [schoolCode, setSchoolCode] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [board, setBoard] = useState("");
  const [orientation, setOrientation] = useState("");
  const [city, setCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [teacher, setTeacher] = useState("");
  const [student, setStudent] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [staff, setStaff] = useState("");
  const [doctor, setDoctor] = useState(null);
  const [nurse, setNurse] = useState(null);
  const [planName, setPlanName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [logoRes, setLogoRes] = useState(false);
  const [receivedData, setReceivedData] = useState(null);
  const [pincodeError, setPincodeError] = useState(false);
  const [adminNumberError, setAdminNumberError] = useState(false)
  const [schoolCodeError, setSchoolCodeError] = useState(false)
  const [phoneNumberError, setPhoneNumberError] = useState(false)
  const [imageShow, setImageShow] = useState("")
  const [establishedError, setEstablishedError] = useState(false);
  const [clinicCode, setClinicCode] = useState("");

  const errorClinic = useSelector((state) => state.clinicReducer.error);

  const loadingClinic = useSelector((state) => state.clinicReducer.loading);

    useEffect(()=>{
      const values = location.state;
      console.log(values)
      const name = values?.clinicAdmin?.name.split(" ");
      let spec;
      if (values?.specialty === "Multispeciality") {
        spec = "Multi speciality";
      } else if (values?.specialty === "Superspeciality") {
        spec = "Super speciality";
      } else {
        spec = "Advisory";
      }
      setAddress(values?.address);
      setCity(values?.city);
      setSelectedState(values?.state)
      setSchoolName(values?.name);
      setBoard(textFormat(values?.type));
      setSpeciality(spec);
      setEstablished(values?.establishment);
      setEmail(values?.email);
      setPhoneNumber(values?.phone);
      setPincode(values?.pincode);
      setAmenities(
        Array.isArray(values?.ameneties) ? values?.ameneties[0]?.split(",") : []
      );
      setTeacher(values?.doctor);
      setStaff(values?.staff);
      setStudent(values?.nurse);
      setLogoRes(values?.url)
      setSchoolCode(location.state?.clinicCode ?? "");
    },[])

  useEffect(() => {
    if (pincode.length) {
      if (pincode.length === 6) {
        setPincodeError(false)
      }
      else {
        setPincodeError(true)
      }
    }
    else {
      setPincodeError(false)
    }
  }, [pincode]);

  const blob2img = async () => {
    
    if (logoRes && typeof logoRes === "object") {
      var reader = new FileReader();
      reader.readAsDataURL(logoRes);
      reader.onloadend = function () {
        var base64data = reader.result;
        
        setImageShow(base64data)
      }
    }
    else {
      setImageShow(logoRes)
    }
    // setImageShow(x?.data)
  }

  useEffect(() => {
    const phoneRegEx = /^[6-9]{1}[0-9]{9}$/;
    if (phoneNumber.length > 0) {
      setPhoneNumberError(!phoneRegEx.test(phoneNumber))
    }
  }, [phoneNumber]);

  useEffect(() => {
    const schoolCodeRegEx = /^([a-zA-Z0-9 -]+)$/;
    if (schoolCode.length > 0) {
      setSchoolCodeError(!schoolCodeRegEx.test(schoolCode))
    }
  }, [schoolCode]);

  useEffect(() => {
    const states = State.getStatesOfCountry("IN").map((state) => state.name);
    setStateOptions(states);
  }, []);

  useEffect(() => {
    if (selectedState) {
      const selectedStateObject = State.getStatesOfCountry("IN").filter(
        (x) => x.name === selectedState
      );
      setCityOptions(
        City.getCitiesOfState("IN", selectedStateObject[0].isoCode).map(
          (x) => x.name
        )
      );
    }
  }, [selectedState]);


  // useEffect(() => {
  //   if(selectedFile){
  //     dispatch(createLogoSuccess(selectedFile))
  //   }
  // }, [selectedFile])

  const handleDeleteAmenities = (element) => {
    const filteredAmenities = amenities.filter((el) => el !== element);
    setAmenities(filteredAmenities);
  };

  const toBase64 = () => new Promise((resolve, reject) => {
    if (logoRes) {
      const readers = new FileReader();
      readers.readAsArrayBuffer(logoRes);
      readers.onload = () => {
        resolve(Buffer.from(readers.result).toJSON())

      };
      readers.onerror = error => reject(error);
    }

  });

  const handleSubmit = async (e) => {
    e.preventDefault()
    let img;      
    if (logoRes && !(typeof logoRes === "string")) {
      img = await toBase64();
    }
      const params = {
        address: address,
        city: city,
        name: schoolName,
        type: board === "Skids Owned" ? "SKIDS_Owned" : board,
        speciality: speciality,
        establishment: established,
        email: email,
        mobNumber: phoneNumber,
        officeNumber: phoneNumber,
        pincode: pincode,
        ameneties: amenities,
        doctor: teacher,
        staff: staff,
        nurse: student,
        state: selectedState,
        logo: JSON.stringify(img),
        clinicCode: schoolCode
      };
      console.log(params)
      await dispatch(updateClinic(params));
      handleClick();
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    notify.play();
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  useEffect(() => {
    const establishedDate = established;
    const currentDate = moment().format("YYYY-MM-DD");
    established > currentDate ? setEstablishedError(true) : setEstablishedError(false);
  }, [established]);


  useEffect(() => {
    blob2img()
  }, [logoRes])


  return (
    <div className={classes.main_div}>
      <BreadCrumbs />
      <div className={classes.container}>
        <div className={classes.logo_text}>{"Clinic"} Logo</div>
        <div className={classes.upload_logo}>
          {!logoRes ? (
            <div className={classes.no_upload_logo}>
              <ImageRoundedIcon
                sx={{ fill: "#E8E7E7", width: "56px", height: "56px" }}
              />
            </div>
          ) : (
            <img alt="" src={imageShow} className={classes.uploadLogo} />
          )}
          {/* <img
            alt=""
            src={selectedFile ? selectedFile : schoollogo}
            className={classes.uploadLogo}
          ></img> */}
          {/* <div className={classes.upload}>
            <img alt="" src={upload}></img>
            <div className={classes.upload_text}>Upload {finalLetter} Logo</div>
          </div> */}
          <div className={classes.upload}>
            <label
              className={classes.uploadLabel}
              htmlFor="openUpload"
              style={{ color: "#3b3cea" }}
            >
              {" "}
              <img alt="" src={upload}></img> Upload Photo
            </label>
            <input
              id="openUpload"
              className={classes.uploadPhoto}
              type="file"
              accept="image/jpeg,"
              onChange={(e) => setLogoRes(e.target.files[0])}
            />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={classes.input_school_layout}>
            <InputDashboard
              placeholder={`${"Clinic"} name`}
              width={"47.7vw"}
              className={classes.input_layout_schoolname}
              change={(e) => {
                setSchoolName(e.target.value);
              }}
              margin={2}
              bgColor="#ffffff"
              value={schoolName}
            />
            <InputDropdown
              placeholder={`${"Type"}`}
              width={"23vw"}
              className={classes.input_layout_boardname}
              bgColor="#ffffff"
              value={board}
              margin={2}
              setValue={setBoard}
              defaultValues={["Affiliated", "Skids Owned"]}
            />
          </div>

            <div>
              <div className={classes.input_layout}>
                <InputDropdown
                  placeholder={"Speciality"}
                  width={"23vw"}
                  bgColor="#ffffff"
                  margin={2.4}
                  value={speciality}
                  setValue={setSpeciality}
                  defaultValues={[
                    "Multi speciality",
                    "Super speciality",
                    "Advisory",
                  ]}
                />
                <div>
                  <DatePickerInput
                    onChange={(e) => {
                      setEstablished(e.target.value);
                    }}
                    width={"23vw"}
                    placeholder={"Established from"}
                    value={established}
                  />
                  {establishedError && (
                    <div className={classes.validation_text}>
                      Please enter Valid Established Date*
                    </div>
                  )}
                </div>
                {/* <BasicDatePicker /> */}
              </div>
            </div>

          <div className={classes.contact_info}>Contact information</div>
          <div className={classes.input_layout}>
            <div>
              <InputDashboard
                placeholder={"Email id"}
                width={"23vw"}
                change={(e) => {
                  setEmail(e.target.value);
                }}
                margin={2.4}
                bgColor="#ffffff"
                value={email}
              />
              {!validateEmail(email) && email.length ? (
                <div className={classes.validation_text}>
                  {" "}
                  Please fill valid email id*
                </div>
              ) : (
                <></>
              )}
            </div>
            <div><InputDashboard
              placeholder={"Phone number"}
              width={"23vw"}
              margin={2.4}
              change={(e) => {
                setPhoneNumber(e.target.value);
              }}
              bgColor="#ffffff"
              value={phoneNumber}
              type={"number"}
            />
              {phoneNumberError &&
                <div className={classes.validation_text}>
                  {" "}
                  Please fill valid 10 digit number*
                </div>
              }
            </div>
            <div>
              <InputDashboard
                placeholder={"Clinic Code"}
                required={true}
                width={"23vw"}
                margin={2.4}
                className={classes.input_layout_schoolname}
                change={(e) => {
                  setSchoolCode(e.target.value);
                }}
                bgColor="#ffffff"
                value={schoolCode}
                disabled={true}
              />
              {schoolCodeError &&
                <div className={classes.validation_text}>
                  {" "}
                  Please enter only alphanumeric values*
                </div>
              }
            </div>
          </div>
          <InputDashboard
            placeholder={`${"Clinic"} address`}
            width={"48vw"}
            margin={2.4}
            change={(e) => {
              setAddress(e.target.value);
            }}
            bgColor="#ffffff"
            value={address}
          />
          <div className={classes.input_layout}>
            <InputDropdown
              placeholder={"State"}
              width={"23vw"}
              bgColor="#ffffff"
              margin={2.4}
              value={selectedState}
              setValue={setSelectedState}
              defaultValues={stateOptions}
            />
            <InputDropdown
              placeholder={"City"}
              width={"23vw"}
              bgColor="#ffffff"
              value={city}
              margin={2.4}
              setValue={setCity}
              defaultValues={cityOptions}
            />
            <div>
              <InputDashboard
                placeholder={"Pincode"}
                width={"23vw"}
                margin={2.4}
                change={(e) => {
                  setPincode(e.target.value);
                }}
                bgColor="#ffffff"
                value={pincode}
                type={"number"}
              />
              {pincodeError &&
                <div className={classes.validation_text}>
                  {" "}
                  Please fill valid 6 digit Pincode*
                </div>
              }
            </div>
          </div>
          <div className={classes.contact_info}>Staff & Ameneties</div>
          <div className={classes.input_layout}>
            <InputDashboard
              placeholder={`${"Doctors"}`}
              width={"23vw"}
              margin={2.4}
              change={(e) => {
                setTeacher(e.target.value);
              }}
              bgColor="#ffffff"
              value={teacher}
              type={"number"}
            />
            <InputDashboard
              placeholder={`${"Nurses"}`}
              width={"23vw"}
              margin={2.4}
              change={(e) => {
                setStudent(e.target.value);
              }}
              bgColor="#ffffff"
              value={student}
              type={"number"}
            />
            
              <InputDashboard
                placeholder={"Staff"}
                width={"23vw"}
                margin={2.4}
                change={(e) => {
                  setStaff(e.target.value);
                }}
                bgColor="#ffffff"
                value={staff}
                type={"number"}
              />
            
          </div>
          {
            <div className={classes.input_layout}>
              <MultipleSelectChip
                entity={entity}
                amenities={amenities}
                setAmenities={setAmenities}
                options={["Ambulance", "Beds", "ECU", "ICU", "Operation Theatre"]}
                width={"23vw"}
                margin={2}
              />
            </div>
          }
  
          {!loadingClinic &&
            <Snackbar
              open={open}
              autoHideDuration={2400}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity={
                  (!errorClinic)
                    ? "success"
                    : "error"
                }
                sx={{ width: "100%" }}
              >
                {!errorClinic
                  ? `Hurrah !! New ${"Clinic"
                  } On-boarded`
                  : `Oh Oh !! Some issue in adding ${"Clinic"
                  }. Try Again`}
              </Alert>
            </Snackbar>
          }
          <div className={classes.buttons}>
            <button className={classes.button_with_border} onClick={() => navigate(-1)}>
              {/* {schoolName &&
                amenities &&
                board &&
                orientation &&
                city &&
                selectedState &&
                infirmary &&
                amenities.length > 0
                ? "CANCEL"
                : "SAVE"} */}
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              className={classes.button_filled}
              disabled={
                    (
                      validateEmail(email)
                        && schoolName && board && speciality
                        && established && email && phoneNumber && !establishedError
                        && schoolCode && address && stateOptions && city && pincode && teacher &&
                        student && staff && amenities && !pincodeError && !phoneNumberError
                        ? false : true
                    )
                }
            >
              Update Clinic
            </button>
            {/* const params = {
        address: address,
        city: city,
        clinicAdmin: {
          email: adminEmail,
          name: `${adminTitle} ${adminFirstName} ${adminLastName}`,
          phone: adminPhone,
        },
        name: schoolName,
        type: board === "Skids Owned" ? "SKIDS_Owned" : board,
        speciality: speciality,
        establishment: established,
        email: email,
        mobNumber: phoneNumber,
        officeNumber: phoneNumber,
        pincode: pincode,
        ameneties: amenities,
        doctor: teacher,
        staff: staff,
        nurse: student,
        state: selectedState,
      }; */}
          </div>
        </form>
      </div>
    </div>
  );
}

function EditClinic() {
  return <EditClinicPage />;
}

export default EditClinic;
