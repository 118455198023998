import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import classes from "./Infirmary.module.css";
import rectangle from "../../../assets/images/rectangle.png";
import SearchIcon from "@mui/icons-material/Search";
import { Avatar, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import InfirmaryRow from "./InfirmaryRows";
import left from "../../../assets/images/left.svg";
import leftArrow from "../../../assets/images/down.svg"
import active from "../../../assets/images/active.svg";
import rightArrow from "../../../assets/images/sort.svg";
import right from "../../../assets/images/right.svg";
import triangle from "../../../assets/images/triangle.svg";
import searchIcon from "../../../assets/images/search-icon.svg";
import addCircleFill from '../../../assets/images/add-circle-fill.svg'
import bulkupload from '../../../assets/images/upload-bulk.svg'
import BreadCrumbs from "../TableComponents/BreadCrumbs";
import ShowPagesFilter from "../TableComponents/ShowPagesFilter";
import SearchBar from "../TableComponents/SearchBar";
import TabButton from "../TableComponents/TabButton";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import BlankDataScreen from "../../BlankDataScreen/BlankDataScreen";

function InfirmaryPage({ data, load }) {

  const location = useLocation();
  let role;
  let btnName;
  if (location.pathname.includes('calendar')) {
    role = "Event"
    btnName = "Add Event"
  }
  else if (location.pathname.includes('clinic') && location.pathname.includes('doctor')) {
    role = 'Doctor'
    btnName = "Add Doctor"
  } else if (location.pathname.includes('clinic') && location.pathname.includes('nurse')) {
    role = 'Nurse'
    btnName = "Add Nurse"
  } else if (location.pathname.includes('clinic') && location.pathname.includes('staff')) {
    role = 'Staff'
    btnName = "Add Staff"
  } else if (location.pathname.includes('school') && location.pathname.includes('counsellor')) {
    role = 'Counsellor'
    btnName = "Add Counsellor"
  } else if (location.pathname.includes('users')) {
    role = 'Users'
    btnName = "Add User"
  } else if (location.pathname.includes('school') && location.pathname.includes('principal')) {
    role = 'Principal'
    btnName = "Add Principal"
  }
  else if (location.pathname.includes('superadmin') && location.pathname.includes('doctor') && location.pathname.includes('school')) {
    role = 'Doctor / Nurse'
  }
  else if (location.pathname.includes('school') && location.pathname.includes('admin')) {
    role = 'Admin'
  }
  else {
    role = 'Infirmary'
    btnName = "Add Infirmary"
  }


  const [open, setOpen] = useState(false);
  const [sec, setSec] = useState(false);
  const [text, setText] = useState("");
  const [displayData, setDisplayData] = useState(data ?? []);
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageNo, setPageNo] = useState(1)

  useEffect(() => {
    if (text) {
      setDisplayData(data.filter((x) => {
        return x?.given_name?.toLowerCase()?.includes(text?.toLowerCase())
      }))
      setPageNo(1)
    }
    else {
      setDisplayData(data)
    }
  }, [text])

  useEffect(() => {
    let y = data
    if (Array.isArray(data) && location.pathname.includes("superadmin-calendar")) {
      y = data?.slice()?.sort((a, b) => {
        return b?.createdAt.localeCompare(a?.createdAt)
      })
    }
    setDisplayData(y)
  }, [data])

  const dropdown_obj = [
    { label: "1st A" },
    { label: "1st B" },
    { label: "1st C" },
  ];
  const sectionDrop_obj = [
    { label: " A Section" },
    { label: "B Section" },
    { label: " C Section" },
  ];
  const numberDown_obj = [{ label: "20" }, { label: "30" }, { label: "40" }];

  const handleOpen = () => {

    setOpen(!open);
  };
  const handleSection = () => {

    setSec(!sec);
  };
  const handleText = () => {

    setText(!text);
  };


  const renderTableHeader = () => {
    if (role === 'Event') {
      return (
        <>
          <div className={classes.event_table_header}>
            <div>SCHOOL NAME</div>
            <div>PINCODE</div>
            <div>SCHOOL CODE</div>
            <div>STUDENTS</div>
            <div>PACKAGE</div>
            <div>EVENT DATE</div>
            <div  >ACTION</div>
          </div >
        </>
      )
    } else if (role === 'Users') {
      return (<>
        <div className={classes.users_table_header}>
          <div>{role?.toUpperCase()} NAME</div>
          <div>EMAIL ID</div>
          <div>PHONE NUMBER</div>
          <div>ACCESS</div>
          <div></div>
          <div>STATUS</div>
          <div style={{ justifySelf: "center" }}>ACTION</div>
        </div>
      </>
      )
    } else if (role === 'Admin') {
      return (<>
        <div className={classes.student_table_header}>
          <div>{role?.toUpperCase()} NAME</div>
          <div>{"GENDER"}</div>
          <div>EMAIL ID</div>
          <div>{"EMPLOYMENT"}</div>
          <div>ROLE</div>
          <div>STATUS</div>
          <div style={{ justifySelf: "center" }}>ACTION</div>
        </div>
      </>)
    } else {
      return (
        <>
          <div className={classes.student_table_header}>
            <div>{role?.toUpperCase()} NAME</div>
            <div>{role === "Principal" || "Counsellor" ? "TITLE" : "GENDER"}</div>
            <div>EMAIL ID</div>
            <div>{role === "Principal" ? "ROLE" : "EMPLOYMENT"}</div>
            <div>{role == "Principal" ? "" : "SPECIALITY"}</div>
            <div>STATUS</div>
            <div style={{ justifySelf: "center" }}>ACTION</div>
          </div>
        </>
      )
    }
  }

  return (
    <div className={classes.main_div} >
      <div className={classes.student_layout}>
        <div style={{ marginBottom: 20 }}>
          {role === 'Event' ? (<div>Calendar</div>) : (
            <BreadCrumbs />
          )}

        </div>
        {role !== "Event" && <div>
          <TabButton uploadBulk={false} role={role} navLink="" />
        </div>}
      </div>
      {
        data?.length > 0 ?
          <>
            <div className={classes.student_filters}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={rowsPerPage}
                  label="Rows"
                  onChange={(e) => setRowsPerPage(e.target.value)}
                  size='small'
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <SearchBar onchange={setText} value={text} />
            </div>
            {/* Student Table Header */}
            {renderTableHeader()}
            {/* {role === "Event" && (
<>
<div className = {classes.event_table_header}>
<div>SCHOOL NAME</div>
<div>PINCODE</div>
<div>SCHOOL CODE</div>
<div>STUDENTS</div>
<div>OPS TEAM</div>
<div>EVENT DATE</div>
<div>ACTION</div>
</div>
</>)}

{role === "Users" ? (<>
    <div className = {classes.users_table_header}>
<div>{role.toUpperCase()} NAME</div>
<div>EMAIL ID</div>
<div>PHONE NUMBER</div>
<div>ACCESS</div>
<div></div>
<div>STATUS</div>
<div>ACTION</div>
</div>
</>) : (<>
    <div className = {classes.student_table_header}>
<div>{role.toUpperCase()} NAME</div>
<div>{role === "Principal" ? "Sal." : "GENDER"}</div>
<div>EMAIL ID</div>
<div>{role === "Principal" ? "ROLE" : "EMPLOYMENT"}</div>
<div>{role == undefined ? "ROLE" : "SPECIALITY"}</div>
<div>STATUS</div>
<div>ACTION</div>
</div>
</>)} */}






            {/* Student Column-Rows Section */}
            <div className={classes.student_column_container}>
              {Array.isArray(displayData) && displayData?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo)?.map((el, index) => {
                // if (el?.screeningFrom && el.screeningTo) {
                return (
                  <InfirmaryRow data={el} key={index + Date.now()} userRole={role} />
                )
                // }
              })}
            </div>
            <div className={classes.pagination_container}>
              <div className={classes.page_item}>{`Showing ${(rowsPerPage * (pageNo - 1)) + 1} to ${pageNo * rowsPerPage <= displayData?.length ? pageNo * rowsPerPage : displayData?.length} of ${displayData?.length} items`}</div>
              <div className={classes.pagination}>
                <Button size="small" disabled={pageNo === 1} onClick={() => setPageNo(pageNo - 1)} >
                  <ChevronLeft />
                </Button>
                <span>{pageNo}</span>
                <div>
                  <span>of</span>
                  <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
                </div>
                <div>
                  <Button size="small" disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)} onClick={() => setPageNo(pageNo + 1)} >
                    <ChevronRight />
                  </Button>
                </div>
              </div>
            </div>
          </>

          :
          load ?
            ""
            :
            <BlankDataScreen title="Oops! No data available" role={role} description={`There is no data uploaded in this table to be shown. Click on Add ${role} to upload data.`} buttonText={`Add ${role}`} />

      }
    </div>
  );
}

function Infirmary({ data, load }) {
  return (

    <InfirmaryPage data={data} load={load} />

  );
}
export default Infirmary;