import React, { useEffect, useState } from "react";
import classes from "./AddUser.module.css";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import ArrowRight from "@mui/icons-material/ArrowRight";
import schoollogo from "../../../assets/images/schoollogo.png";
import upload from "../../../assets/images/upload.svg";
import { Link, useParams } from "react-router-dom";
import InputDashboard from "../InputDashboard/InputDashboard";
import InputDropdown from "../InputDashboard/InputDropdown";
import NotificationClose from "../../../assets/images/notification-close.svg";
import { useLocation } from "react-router-dom";
import CustomMultiSelectDropdown from "../InputDashboard/CustomMultiSelect";
import BasicDatePicker from "../InputDashboard/InputDatePicker";
import MaterialUIPickers from "../InputDashboard/InputDatePicker";
import InputDatePicker from "../InputDashboard/InputDatePicker";
import DatePickerInput from "../InputDashboard/DatePickerInput";
import { Country, State, City } from "country-state-city";
import studentReducer from "../../../redux/reducers/student.reducer";
import { useDispatch, useSelector } from "react-redux";
import userReducer from "../../../redux/reducers/user.reducer";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Alert, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, textFormat, validateEmail } from "../../../utils/utility";
import BreadCrumbs from "../TableComponents/BreadCrumbs";
import notification from "../../../assets/audios/notificationSound.mp3";
function AddEditUser() {
  let notify = new Audio(notification);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthDay] = useState("");
  const [role, setRole] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [schoolClass, setSchoolClass] = useState("");
  const [specialty, setSpeciality] = useState("");
  const [section, setSection] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  const createUser = userReducer.actions.createUserRequest;
  const createSchoolInfirmary =
    userReducer.actions.createSchoolInfirmaryRequest;

  useEffect(() => {
    setRole(textFormat(location.state?.type) ?? "");
  }, []);

  useEffect(() => {
    const phoneRegEx = /^[6-9]{1}[0-9]{9}$/;
    if (phoneNumber.length > 0) {
      setPhoneNumberError(!phoneRegEx.test(phoneNumber));
    } else{
      setPhoneNumberError(false)
    }
  }, [phoneNumber]);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    notify.play();
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const error = useSelector((state) => state.userReducer.error);
  const errorMessage = useSelector((state) => state.userReducer.errorMessage);
  const loading = useSelector((state) => state.userReducer.loading);
  

  let schoolId = useSelector((state) => state.userReducer?.currentUser?.schoolId);

  const handleSubmit = async () => {
    if (location.pathname.includes("superadmin")) {

      schoolId = location.state?.schoolId?.schoolId;
    }
    let formData;
    if (location.state.type !== "infirmary") {
      if (role === "Teacher") {
        formData = {
          email,
          title,
          firstName: capitalizeFirstLetter(firstName),
          lastName: capitalizeFirstLetter(lastName),
          schoolClass: schoolClass,
          section: section,
          role: `school-${role?.toLowerCase()}`,
          phoneNumber,
          schoolId: schoolId,
          gender,
          salutation: title,
          phone: phoneNumber,
        };
        
      } else {
        console.log(location.state?.schoolId?.clinicCode)
        formData = {
          email,
          title,
          firstName: capitalizeFirstLetter(firstName),
          lastName: capitalizeFirstLetter(lastName),
          birthday,
          role: `school-${role.toLowerCase()}`,
          phoneNumber,
          schoolId:
            role === "doctor" || role === "nurse"
              ? location.state?.schoolId?.clinicCode
              : schoolId,
          gender,
          salutation: title,
          phone: phoneNumber,
        };
        
      }
      if (location?.state?.type === "doctor") {
        formData.specialities = specialty?.toUpperCase();
      }
      console.log(formData)
      await dispatch(createUser(formData));
    } else {
      formData = {
        email,
        firstName: capitalizeFirstLetter(firstName),
        lastName: capitalizeFirstLetter(lastName),
        birthday,
        role: role?.toLowerCase(),
        phoneNumber,
        schoolId: schoolId,
        gender,
        salutation: title,
        phone: phoneNumber,
      };

      dispatch(createSchoolInfirmary(formData));
    }
    handleClick();
    // if(!loading && !error){
    //   setTimeout(() => navigate(-1), 5000)
    // }
  };

  useEffect(()=>{
    console.log(role)
  },[role])

  const handleOpsSubmit = () => {
    const formData = {
      email,
      firstName: capitalizeFirstLetter(firstName),
      lastName: capitalizeFirstLetter(lastName),
      role: role === "Ops Manager" ? "skids-ops-manager" : "skids-ops-nurse",
      gender,
      salutation: title,
      phone: phoneNumber,
    };
    dispatch(createUser(formData));
    handleClick();
    // if(!loading && !error){
    //   setTimeout(() => navigate(-1), 5000)
    // }
  };

  const handleDoctorAddSubmit = () => {
    const formData = {
      email,
      firstName: capitalizeFirstLetter(firstName),
      lastName: capitalizeFirstLetter(lastName),
      role: `skids-${role.toLowerCase()}`,
      phoneNumber,
      clinicId: location.state?.schoolId?.clinicCode,
      gender,
      salutation: title,
      phone: phoneNumber,
      specialities: specialty?.toUpperCase()
    };
    console.log(formData)
    dispatch(createUser(formData))
  }

  return (
    <div className={classes.mainDiv}>
      <div className={classes.header}>
        {/* <div className={classes.back}>
          {" "}
          <Link
            to="/superadmin-users"
            style={{ textDecoration: "none", color: "#9d9d9d" }}
          >
            Registered Users{" "}
          </Link>{" "}
        </div>
        <ArrowRight /> <div className={classes.header_text}>Add User</div> */}
        <BreadCrumbs />
      </div>
      <div className={classes.container}>
        <div>
          <h4>Skids {role} registration information</h4>
          <br />
          <InputDashboard
            placeholder={"Email Id"}
            margin={1}
            width={"32%"}
            className={classes.input_layout_schoolname}
            change={(e) => {
              setEmail(e.target.value);
            }}
            bgColor="#ffffff"
            value={email}
          />
          {!validateEmail(email) && email?.length ? (
            <div className={classes.validation_text}>
              Please fill valid email id*
            </div>
          ) : (
            <></>
          )}

          <div className={classes.lowlight_text}>
            New user login credentials will be sent to this email id
          </div>
          <br />
          <br />
          <div className={classes.contact_info}>Personal information</div>

          {/* <div className={classes.upload_logo}>
            <div className={classes.no_upload_logo}>
              <ImageRoundedIcon
                sx={{ fill: "#E8E7E7", width: "56px", height: "56px" }}
              />
            </div> */}
          {/* <img alt="" src = {schoollogo}  /> */}
          {/* </div> */}
          <div className={classes.input_user_layout}>
            <div>
              <InputDropdown
                placeholder={"Title"}
                width={"95%"}
                className={classes.input_layout_boardname}
                bgColor="#fff"
                value={title}
                defaultValues={["Mr.", "Mrs.", "Ms.", "Dr."]}
                setValue={setTitle}
              />
              {/* {validateTitle && (
                <div className={classes.validation_text}>
                  Please select title*
                </div>
              )} */}
            </div>

            <div>
              <InputDashboard
                placeholder={"First Name"}
                width={"95%"}
                className={classes.input_layout_schoolname}
                change={(e) => {
                  setFirstName(e.target.value);
                }}
                bgColor="#ffffff"
                value={firstName}
              />
              {/* {validatefirstName && (
                <div className={classes.validation_text}>
                  Please type first name*
                </div>
              )} */}
            </div>
            <div>
              <InputDashboard
                placeholder={"Last Name"}
                width={"95%"}
                className={classes.input_layout_schoolname}
                change={(e) => {
                  setLastName(e.target.value);
                }}
                bgColor="#ffffff"
                value={lastName}
              />
              {/* {validateLastName && (
                <div className={classes.validation_text}>
                  Please type last name*
                </div>
              )} */}
            </div>
          </div>
          <div className={classes.input_school_layout}>
            {!location.state?.type === "doctor" && (
              <DatePickerInput
                onChange={(e) => {
                  setBirthDay(e.target.value);
                }}
                width={"95%"}
                placeholder={"Birthday"}
                value={birthday}
              />
            )}
            {location.state?.type === "doctor" && (
              <InputDropdown
                placeholder={"Specialty"}
                width={"95%"}
                margin={2}
                className={classes.input_layout_boardname}
                bgColor={"#fff"}
                value={specialty}
                defaultValues={["Paediatrician", "Behavioral", "Dental"]}
                setValue={setSpeciality}
              />
            )}
            <InputDropdown
              placeholder={"Gender"}
              width={"95%"}
              margin={2}
              className={classes.input_layout_boardname}
              bgColor="#fff"
              value={gender}
              defaultValues={["Male", "Female"]}
              setValue={setGender}
            />
            <InputDropdown
              placeholder={"Role"}
              width={"95%"}
              margin={2}
              className={classes.input_layout_boardname}
              bgColor="#fff"
              value={role}
              defaultValues={
                location.state?.type !== "infirmary"
                  ? location.state?.type === "Internal User"
                    ? ["Ops Manager", "Ops Nurse"]
                    : ["Principal", "Teacher", "Doctor", "Nurse", "Admin"]
                  : ["Doctor", "Nurse"]
              }
              setValue={setRole}
              disabled={
                location.state?.type === "teacher" ||
                location.state?.type === "principal" ||
                location.state?.type === "doctor" ||
                location.state?.type === "nurse" ||
                location.state?.type === "admin"
              }
              noneMenu
            />
            {location.state?.type === "teacher" && (
              <InputDropdown
                placeholder={"Class"}
                width={"95%"}
                margin={2}
                className={classes.input_layout_boardname}
                bgColor="#fff"
                value={schoolClass}
                defaultValues={[
                  "Nursery",
                  "LKG",
                  "UKG",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "10",
                  "11",
                  "12",
                ]}
                setValue={setSchoolClass}
              />
            )}
          </div>
          {location.state?.type === "teacher" && (
            <div className={classes.input_school_layout}>
              <InputDropdown
                placeholder={"Section"}
                width={"95%"}
                margin={2}
                className={classes.input_layout_boardname}
                bgColor="#fff"
                value={section}
                defaultValues={[
                  "A", "B", "C","D","E","F","G","H",
                  "I","J","K","L","M","N","O","P","Q",
                  "R","S","T","U","V","W","X","Y","Z"]}
                setValue={setSection}
              />
            </div>
          )}
          <div>
            {/* {validateRole && (
                <div className={classes.validation_text}>
                  Please select role*
                </div>
              )} */}
          </div>
          <div className={classes.contact_info}>Contact information</div>
          <div className={classes.input_school_layout}>
            <div>
              <div>
                <InputDashboard
                  placeholder={"Phone Number"}
                  width={"95%"}
                  margin={2}
                  className={classes.input_layout_schoolname}
                  change={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  bgColor="#ffffff"
                  value={phoneNumber}
                />
                {phoneNumberError && (
                  <div className={classes.validation_text}>
                    Please enter valid 10 digit number*
                  </div>
                )}
              </div>
              {/* {validateNumber && (
                <div className={classes.validation_text}>
                  Please type valid phone number*
                </div>
              )} */}
            </div>
          </div>
          {!loading && (
            <Snackbar
              open={open}
              autoHideDuration={2000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity={!error ? "success" : "error"}
                sx={{ width: "100%" }}
              >
                {!error
                  ? `Successfully added ${role}`
                  : `${errorMessage}`}
              </Alert>
            </Snackbar>
          )}
          <div className={classes.buttons}>
            <button
              className={classes.button_with_border}
              onClick={() => navigate(-1)}
            >
              CANCEL
            </button>
            <button
              onClick={() => {
                location.state?.type === "Internal User"
                  ? handleOpsSubmit()
                  : (role==="Doctor" ? handleDoctorAddSubmit() : handleSubmit());
              }}
              className={classes.button_filled}

                disabled = {validateEmail(email) && email && role && title && firstName && lastName && gender && !phoneNumberError && phoneNumber ? false : true}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEditUser;
