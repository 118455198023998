import * as React from "react";
import { useRef , useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseModal from "../../assets/images/closeModal.svg";
import view from '../../assets/images/view.svg'
import Stack from "@mui/material/Stack";
import classes from './ValidationModal.module.css'
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 488,
  bgcolor: "background.paper",
  borderRadius: "16px",
  width: "472px",
  boxShadow: 24,
  paddingBottom : 24,
  p: 2,
};

const btn_container = {
  border: "none",
  background: "none",
  padding: "none",
  border: "none",
  background: "none",
  fontWeight: "600",
  fontSize: "14px",
  cursor: "pointer",
  textAlign: "left",
};

const close_btn_container = {
  display: "flex",
  flexDirection: "row-reverse",
};

const close_btn = {
  background: "none",
  border: "none",
  outline: "none",
  textAlign: "left",
};

const modal_header = {
  margin: "24px 0 0 0",
  fontSize: "20px",
  fontWeight: "normal",
  textAlign: "center",
};

const modal_header_info = {
  margin: "20px 0 0 0",
  textAlign: "center",
  padding: "3px 20px",
  fontSize: "14px",
};

const action_btn_container = {
  margin: "24px 0 0 0",
  display: "flex",
  gap: "32px",
  justifyContent: "center",
};

const action_btn = {
  border: "1px solid #1740A1",
  padding: "16px 0",
  borderRadius: "8px",
  width: "176px",
  height: "56px",
  background: "none",
  fontWeight: "600",
  fontSize: "16px",
  cursor: "pointer",
};

const black_btn = {
  background: "none",
  border: "none",
  padding: "16px 0",
  borderRadius: "8px",
  width: "176px",
  height: "56px",
  fontWeight: "600",
  fontSize: "16px",
  border: "none !important",
  color: "#ffffff",
  background: "#1740A1",
  cursor: "pointer",
};

const chip_input_container = {
  margin: "24px 0 0 0",
  padding: "0 0 0 25px",
  textAlign: "left",
};

const chip_input = {
  borderRadius: "8px",
  border: "1px solid #B2B7BE",
  width: "400px",
};

const chip_details_container = {
  margin: "5px 0 0 0",
  display: "flex",
  gap: "5px",
  flexWrap: "wrap",
  padding: "0 0 0 20px",
  width: "50vh",
};

export default function ChipModal({data, open, handleClose, handleOpen}) {

  const dataObject = JSON.parse(data?.differentMap)

  

  return (
    <>
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={modal_header}>Validation Progress</div>
          <div className= {classes.container_header}>
            <div>
                <p>Doctor</p>
                <p>Dr. {data?.doctorName}</p>
            </div>
            <div>
                <p>Validation Type</p>
                <p>{data?.assignType === "PAEDIATRICIAN" ? "Physical Screening" : (data?.assignType==="BEHAVIORAL" ? "Behavioural Screening" : "Dental Screening")}</p>
            </div>
          </div>

          {Object.keys(dataObject)?.map((x)=>{
            return(
              <div className= {classes.progress_container}>
                <div>
                    <p>Progress</p>
                    <p>{`${dataObject[x][0]}/${dataObject[x][1]}`}</p>
                </div>
                <div>
                    <p>Assigned On</p>
                    <p>{moment(x).format("DD MMMM, YYYY")}</p>
                </div>
                <div>
                    <p>Due date</p>
                    <p>{moment(dataObject[x][2]).format("DD MMMM, YYYY")}</p>
                </div>
              </div>
            )
          })}

          {/* <div className= {classes.progress_container}>
            <div>
                <p>Progress</p>
                <p>200/400</p>
            </div>
            <div>
                <p>Assigned On</p>
                <p>10 September, 2023</p>
            </div>
            <div>
                <p>Due date</p>
                <p>10 September, 2023</p>
            </div>
          </div> */}

          {/* <div className= {classes.progress_container}>
            <div>
                <p>Progress</p>
                <p>200/400</p>
            </div>
            <div>
                <p>Assigned On</p>
                <p>10 September, 2023</p>
            </div>
            <div>
                <p>Due date</p>
                <p>10 September, 2023</p>
            </div>
          </div> */}
          <div style={action_btn_container}>
            <button onClick={() => handleClose()} className = {classes.okay_btn}>
              Okay
            </button>
          </div>
        </Box>
      </Modal>
    </div>
    </>
  );
}
