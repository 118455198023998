import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import dashboardReducer from '../../../redux/reducers/dashboard.reducer'
import { NewSaDashboard } from '../../DashboardSuperAdmin/NewSaDashboard';
import {Configuration, OpenAIApi} from 'openai';


const SuperAdminDashboard = () => {
  const dispatch = useDispatch()

  const getDashboard = dashboardReducer.actions.getSuperAdminDashboardRequest;
  const getLiveData = dashboardReducer.actions.getSuperAdminDashboardLiveScreeningRequest;
  const getDoctorLiveData = dashboardReducer.actions.getSuperAdminDashboardLiveValidationRequest

  useEffect(() => {
      dispatch(getDashboard())
      dispatch(getLiveData())
      dispatch(getDoctorLiveData())
  }, [])  
  return (
    <>
      <NewSaDashboard />  
    </>    
  )
}
export default SuperAdminDashboard