import React, { useEffect, useRef } from 'react';
import "./HealthReport.css"
import { Page1 } from './Page1'
import { DynamicPages } from './Page2'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { CircularProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import screeningReducer from '../../redux/reducers/screening.reducer'
import { useLocation } from 'react-router-dom'
import { AbdomenReportSection, BehavioralReportSection, DentalReportSection, DoctorSection, EndOfReport, EyeReportSection, HairReportSection, HeartReportSection, LearningReportSection, LeftEarReportSection, LungsReportSection, MouthThroatReportSection, RightEarReportSection, SkinReportSection, VitalsReportSection } from './DynamicContent';
import { useState } from 'react';

function HealthReport() {
  let tempPageContent = [];
  let pageViews = [];
  let pdfPageContent = [];
  let currentPage = 1
  const [newView, setNewView] = useState([]);

  const reportTemplateRef = useRef(null);

  const dispatch = useDispatch()
  const location = useLocation()

  const getReport = screeningReducer.actions.getReportRequest;

  const data = useSelector((state) => state.screeningReducer.reportData);
  

  

  useEffect(() => {
    const studentId = localStorage.getItem('sId')
    dispatch(getReport(studentId))
  }, []);



  useEffect(() => {
    
    if (data) {
      const vitals = data?.student?.healthPackage?.vitalSigns
      const vitalsshow = vitals?.length===0
      const hair = data?.student?.healthPackage?.hair
      const hairshow = hair?.length===0
      const eye = data?.student?.healthPackage?.eye
      const eyeshow = eye.validation?.length=== 0
      const lear = data?.student?.healthPackage?.ear
      const learshow = lear?.length===0
      const rear = data?.student?.healthPackage?.ear
      const rearshow = rear?.length===0
      const throat = data?.student?.healthPackage?.throat
      const throatshow = throat?.length===0
      const heart = data?.student?.healthPackage?.heart
      const heartshow = heart?.length===0
      const dental = data?.student?.healthPackage?.dental
      const dentalshow = dental?.length===0
      const lungs = data?.student?.healthPackage?.lungs
      const lungsshow = lungs?.length===0
      const abdomen = data?.student?.healthPackage?.abdomen
      const abdomenshow = abdomen?.length===0
      const skin = data?.student?.healthPackage?.skin
      const skinshow = skin?.length===0
      const behaveshow = data?.ADHD?.score || data?.ANXIETY?.score || data?.AUTISM?.score || data?.CONDUCT_DISORDER?.score || data?.DEPRESSION?.score || data?.DEVELOPMENTAL_DELAY?.score || data?.INTERNET_ADDICTION?.score || data?.ODD?.score;
      const learning = data?.DYSLEXIA_1?.outcome || data?.DYSCALCULIA_1?.outcome || data?.DYSGRAPHIA_1?.outcome


      if (!vitalsshow) {
        pageViews.push(<VitalsReportSection data={data} />)
      }
      if (!hairshow) {
        pageViews.push(<HairReportSection data={data} />)
      }
      if (!eyeshow) {
        pageViews.push(<EyeReportSection data={data} />)
      }
      if (!learshow) {
        pageViews.push(<LeftEarReportSection data={data} />)
      }
      if (!rearshow) {
        pageViews.push(<RightEarReportSection data={data} />)
      }
      if (!throatshow) {
        pageViews.push(<MouthThroatReportSection data={data} />)
      }
      if (!heartshow) {
        pageViews.push(<HeartReportSection data={data} />)
      }
      if (!dentalshow) {
        pageViews.push(<DentalReportSection data={data} />)
      }
      if (!lungsshow) {
        pageViews.push(<LungsReportSection data={data} />)
      }
      if (!abdomenshow) {
        pageViews.push(<AbdomenReportSection data={data} />)
      }
      if (!skinshow) {
        pageViews.push(<SkinReportSection data={data} />)
      }
      if (behaveshow) {
        pageViews.push(<BehavioralReportSection data={data} />)
      }
      if (learning) {
        pageViews.push(<LearningReportSection data={data} />)
      }
      pageViews.push(<DoctorSection data={data} />)
      // pageViews.push(<EndOfReport data={data} />)
      


      setNewView(pageViews?.forEach((section, index) => {

        tempPageContent.push(section)
        if (((index + 1) % 3) === 0 || (index === (pageViews.length - 1))) {
          currentPage += 1;
          pdfPageContent.push(
            <DynamicPages
              data={data}
              pages={Math.floor(pageViews.length / 3) + 2}
              pageContent={tempPageContent}
              currentPage={currentPage} />)
          tempPageContent = [];
        }
      }))
      setNewView(pdfPageContent);
    }
  }, [data])

  

  useEffect(() => {
    if (newView.length > 0) {
      const pdf = new jsPDF('p', 'mm', 'a4');
      const options = {
        background: 'white',
        scale: 3,
      };

      const name = localStorage.getItem('name')

      const elements = document.querySelectorAll(".main")

      elements.forEach((element, index) => {
        html2canvas(element, options).then(canvas => {
          const imageData = canvas.toDataURL('image/png');
          const width = pdf.internal.pageSize.getWidth();
          const height = (canvas.height * width) / canvas.width;
          if (index !== 0) {
            pdf.addPage();
          }
          pdf.addImage(imageData, 'PNG', 0, 0, width, height, '', 'FAST');
          if (index === elements.length - 1) {
            pdf.save(`${name}.pdf`);
            window.close()
          }
        });
      });
    }
  }, [newView])


  return (
    <div style={{ maxHeight: "100vh", overflow:"hidden" }}>
      <div style={{display: "flex", justifyContent:"center", height:"100vh", alignItems:"center", flexDirection:"column", width:"100vw", zIndex:999, position:"absolute", backgroundColor:"white"}}>
        <CircularProgress size={50}/>
        <div style={{marginTop:40}}>Please Wait, your report will be dowloaded shortly</div>
      </div>
      <div id='healthReport' ref={reportTemplateRef}>
        <Page1 data={data} />
        {
          newView?.map((page) => {
            return <>
              <hr />
              {page}
            </>
          })
        }
      </div>
    </div>
  )
}

export default HealthReport
