import * as React from "react";
import "../Main.css";
const Cough = (props) => {
  const { active, issue, onClick, disabled, top, right, scale } = props;

  return disabled ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      className={`mainCon`}
      onClick={onClick}
      style={{
        top: top,
        right: right,
        transform: `scale(${scale})`,
      }}
      fill="none"
    >
      <circle cx={12} cy={12} r={12} fill="#B2B7BE" opacity="0.16" />
      <path
        fill="#B2B7BE"
        d="M12 18H8a.4.4 0 0 1-.4-.4v-2.367s-.016-.269-.033-.36a1.386 1.386 0 0 0-.096-.32c-.155-.382-.573-1.412-.775-1.782C6.372 12.17 6 11.356 6 10.4 6 7.974 7.974 6 10.4 6a4.405 4.405 0 0 1 4.339 5.135l.811 1.472a.4.4 0 0 1-.35.593h-.4v.4a.4.4 0 0 1-.303.388l-1.212.303c-.035.009-.085.073-.085.109 0 .036.05.1.084.108l.044.014.812.303a.4.4 0 0 1 .26.375v.8a.4.4 0 0 1-.4.4h-1.6v1.2a.4.4 0 0 1-.4.4ZM18 13.2a.4.4 0 1 1-.8 0 .4.4 0 0 1 .8 0ZM16.8 13.6a.4.4 0 1 1-.8 0 .4.4 0 0 1 .8 0ZM16.8 15.2a.4.4 0 1 1-.8 0 .4.4 0 0 1 .8 0Z"
      />
      <path
        fill="#B2B7BE"
        d="M15.6 14.4a.4.4 0 1 1-.8 0 .4.4 0 0 1 .8 0ZM18 14.4a.4.4 0 1 1-.8 0 .4.4 0 0 1 .8 0ZM18 15.6a.4.4 0 1 1-.8 0 .4.4 0 0 1 .8 0Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      className={`mainCon  ${active && (issue ? "activeRed" : "activeGreen")}`}
      onClick={onClick}
      style={{
        top: top,
        right: right,
        backgroundColor: active ? "#ffffff" : "",
        transform: `scale(${scale})`,
      }}
      fill="none"
    >
      <circle
        cx={12}
        cy={12}
        r={12}
        fill={active ? "#ffffff" : issue ? "#f0c5c5" : "#CAEFCF"}
      />
      <path
        fill="#115EA3"
        d="M12 18H8a.4.4 0 0 1-.4-.4v-2.367s-.016-.269-.033-.36a1.386 1.386 0 0 0-.096-.32c-.155-.382-.573-1.412-.775-1.782C6.372 12.17 6 11.356 6 10.4 6 7.974 7.974 6 10.4 6a4.405 4.405 0 0 1 4.339 5.135l.811 1.472a.4.4 0 0 1-.35.593h-.4v.4a.4.4 0 0 1-.303.388l-1.212.303c-.035.009-.085.073-.085.109 0 .036.05.1.084.108l.044.014.812.303a.4.4 0 0 1 .26.375v.8a.4.4 0 0 1-.4.4h-1.6v1.2a.4.4 0 0 1-.4.4ZM18 13.2a.4.4 0 1 1-.8 0 .4.4 0 0 1 .8 0ZM16.8 13.6a.4.4 0 1 1-.8 0 .4.4 0 0 1 .8 0ZM16.8 15.2a.4.4 0 1 1-.8 0 .4.4 0 0 1 .8 0Z"
      />
      <path
        fill="#115EA3"
        d="M15.6 14.4a.4.4 0 1 1-.8 0 .4.4 0 0 1 .8 0ZM18 14.4a.4.4 0 1 1-.8 0 .4.4 0 0 1 .8 0ZM18 15.6a.4.4 0 1 1-.8 0 .4.4 0 0 1 .8 0Z"
      />
    </svg>
  );
};
export default Cough;
