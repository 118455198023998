import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseModal from "../../../assets/images/closeModal.svg";
import { textAlign } from "@mui/system";
import schoolReducer from "../../../redux/reducers/school.reducer";
import clinincReducer from "../../../redux/reducers/clininc.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import classes from "./ActionModal.module.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "16px",
  height: "260px",
  width: "472px",
  boxShadow: 24,
  p: 2,
};

const btn_container = {
  border: "none",
  background: "none",
  padding: "none",
  border: "none",
  background: "none",
  fontWeight: "600",
  fontSize: "14px",
  cursor: "pointer",
  textAlign: "left",
};

const close_btn_container = {
  display: "flex",
  flexDirection: "row-reverse",
};

const close_btn = {
  background: "none",
  border: "none",
  outline: "none",
  textAlign: "left",
};

const modal_header = {
  margin: "24px 0 0 0",
  fontSize: "20px",
  fontWeight: "normal",
  textAlign: "center",
};

const modal_header_info = {
  margin: "20px 0 0 0",
  textAlign: "center",
  padding: "3px 20px",
  fontSize: "14px",
};

const action_btn_container = {
  margin: "24px 0 0 0",
  display: "flex",
  gap: "32px",
  justifyContent: "center",
};

const action_btn = {
  padding: "16px 0",
  border: "1px solid #1740A1",
  borderRadius: "8px",
  width: "176px",
  height: "56px",
  background: "none",
  fontWeight: "600",
  fontSize: "16px",
};

const black_btn = {
  padding: "16px 0",
  border: "none",
  borderRadius: "8px",
  width: "176px",
  height: "56px",
  background: "none",
  fontWeight: "600",
  fontSize: "16px",
  border: "none !important",
  color: "#ffffff",
  backgroundColor: "#1740A1",
};

export default function BasicModal({
  actionName,
  org,
  data,
  handleClick,
  setIsActiveStatus,
  isActiveStatus,
  setIsActiveClinicStatus,
}) {
  const dispatch = useDispatch();
  const deactivateSchool = schoolReducer.actions.deactivateSchoolRequest;
  const deactivateClinic = clinincReducer.actions.deactivateClinicRequest;
  const activateSchool = schoolReducer.actions.activateSchoolRequest;
  const activateClinic = clinincReducer.actions.activateClinicRequest;
  const errorClinic = useSelector((state) => state.clinicReducer.error);
  const errorSchool = useSelector((state) => state.schoolReducer.error);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  

  const handleSchoolDeactivate = () => {
    try {
      if (org === "school") {
        if (actionName === "Deactivate") {
          
          dispatch(
            deactivateSchool({
              schoolId: data?.schoolId,
              schoolCode: data?.schoolCode,
            })
          );
          handleClose();
          handleClick();
          if (!errorSchool) {
            // setIsActiveStatus(false);
          }
        } else if (actionName === "Activate") {
          
          dispatch(
            activateSchool({
              schoolId: data?.schoolId,
              schoolCode: data?.schoolCode,
            })
          );
          handleClose();
          handleClick();
          if (!errorSchool) {
            // 
            // locationData.isActive = null
            // setIsActiveStatus(true);
            // 
          }
        }
      } else if (org === "Clinic") {
        if (actionName === "Deactivate") {
          dispatch(
            deactivateClinic({
              clinicCode: data?.clinicCode,
              pincode: data?.pincode,
            })
          );
          handleClose();
          handleClick();
          if (!errorClinic) {
            // setIsActiveStatus(false);
          }
        } else if (actionName === "Activate") {
          
          dispatch(
            activateClinic({
              clinicCode: data?.clinicCode,
              pincode: data?.pincode,
            })
          );
          handleClose();
          handleClick();
          if (!errorClinic) {
            // setIsActiveStatus(true);
          }
        }
      }
    } catch (error) {
      
    }
  };

  return (
    <div>
      <button
        style={btn_container}
        className={`${
          actionName === "Activate"
            ? `${classes.activate_btn}`
            : `${classes.delete_btn}`
        }`}
        onClick={handleOpen}
      >{`${actionName + " " + org}`}</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={close_btn_container}>
            <button onClick={handleClose} style={close_btn}>
              <img src={CloseModal} alt="closeModal" />
            </button>
          </div>

          <div style={modal_header}>{actionName} School</div>

          <div style={modal_header_info}>
            {`Are you sure you want to ${actionName?.toLowerCase()} "${data?.schoolName}".
            Please confirm?`}
          </div>

          <div style={action_btn_container}>
            <button style={action_btn} onClick={handleClose}>
              Cancel
            </button>
            <button onClick = {handleSchoolDeactivate}  style={black_btn}>{actionName}</button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
