import * as React from "react"
const developmentMilestonesSvg = (props) => (
    <div style={{ ...props.customStyle }}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke="#1B9446"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                clipPath="url(#a)"
            >
                <path d="M9.989 27.25h-3.6c-1.265 0-2.291 1.048-2.291 2.342v3.957c0 1.293 1.026 2.342 2.292 2.342h3.599c1.266 0 2.292-1.049 2.292-2.342v-3.957c0-1.294-1.026-2.342-2.292-2.342ZM33.61 17.969h-3.595c-1.267 0-2.294 1.049-2.294 2.343V33.55c0 1.294 1.027 2.343 2.294 2.343h3.595c1.267 0 2.294-1.05 2.294-2.343V20.312c0-1.294-1.027-2.343-2.294-2.343ZM21.785 22.617h-3.597c-1.267 0-2.293 1.049-2.293 2.343v8.59c0 1.293 1.026 2.342 2.293 2.342h3.597c1.266 0 2.293-1.049 2.293-2.343v-8.59c0-1.293-1.027-2.342-2.293-2.342ZM33.219 15.61h-2.814a.925.925 0 0 0-.915.934v.486c0 .516.41.934.915.934h2.814a.925.925 0 0 0 .914-.934v-.486a.925.925 0 0 0-.914-.935ZM31.813 15.137V4.117M31.578 5.323s-1.281-.862-3.36 1.086c-2.08 1.947-4.915 1.17-4.915 1.17v5.334s2.835.78 4.914-1.171c2.08-1.95 3.36-1.086 3.36-1.086" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M3.334 3.336h33.333v33.333H3.334z" />
                </clipPath>
            </defs>
        </svg>
    </div>
)
export default developmentMilestonesSvg
