import React, { useState } from 'react'
import { Autocomplete, Avatar, Box, Button, FormControl, InputLabel, MenuItem, Modal, Paper, Select, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { capitalizeFirstLetter, stringAvatar, truncateString, numberTh } from '../../../../utils/utility'
import classes from '../RetakeTable/RetakeTable.module.css'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { useEffect } from 'react'
import InputDropdown from '../../../common/InputDashboard/InputDropdown'
import schoolReducer from '../../../../redux/reducers/school.reducer';
import { useDispatch, useSelector } from 'react-redux';
import screening from '../../../../graphqlApiServices/screening';
import OpsOperatorModal from '../OpsManager/OpsOperatorModal/OpsOperatorModal';
import moment from 'moment';
import screeningReducer from '../../../../redux/reducers/screening.reducer';
import BlankDataScreen from '../../../BlankDataScreen/BlankDataScreen'
import { useNavigate } from 'react-router-dom'
import QRCode from 'react-qr-code'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
};

function AbsentTable({ type }) {
    const [pageNo, setPageNo] = useState(1);
    const [filterClass, setFilterClass] = useState('');
    const [filterSchool, setFilterSchool] = useState('');
    const [classOption, setClassOption] = useState([])
    const [schoolOption, setSchoolOption] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openMod, setOpenMod] = useState(false);
    const [absentData, setAbsentData] = useState({ schoolId: '', items: [], nextToken: '' })
    const [displayData, setDisplayData] = useState([])
    const [ModalData, setModalData] = useState({ qr: '', name: '', class: '' })
    const [open, setOpen] = useState(false);

    const getSchools = schoolReducer.actions.getSchoolsRequest;
    const getWelchallynCsv = screeningReducer.actions.getWelchallynReq;
    const data = useSelector((state) => state.schoolReducer.data)
    const IntegrationLinks = useSelector((state) => state.screeningReducer.welchallynLink)
    const currentUser = useSelector((state) => state.userReducer?.currentUser);
    const getSchoolByState = schoolReducer.actions.getSchoolbyStateRequest;
    const user = useSelector((state) => state.userReducer.currentUser)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { getScreeningsBySchool, getReExam } = screening

    const handleOpen = (qr, name) => { setOpen(true); setModalData({ ...ModalData, qr: qr, name: name }) }
    const handleClose = () => { setOpen(false); setModalData({ qr: '', name: '' }) }
    const url = 'https://parents.skids.health/ops-screening/'


    // reset Value when component changes 
    useEffect(() => {
        setFilterClass('')
        setFilterSchool('')
        setDisplayData([])
        setPageNo(1);
        setRowsPerPage(10);
        setOpenMod(false);
        setAbsentData({ schoolId: '', items: [], nextToken: '' })
        setModalData({ qr: '', name: '', class: '' })
        setOpen(false);
    }, [type])


    // collect schools 
    useEffect(() => {
        if (user?.userAttributes && user?.userAttributes?.state) {
            dispatch(getSchoolByState({ state: user?.userAttributes?.state }));
        } else {
            dispatch(getSchools());
        }
    }, [])


    // setting school Options 
    useEffect(() => {
        let arr = [];
        data.map(item => { arr.push(item.schoolName) })
        setSchoolOption(arr)
    }, [data])


    // absent and reExam api call when school selected
    useEffect(() => {
        let list = []
        let params;
        setFilterClass('')
        setPageNo(1)
        data.map(item => item.schoolName.includes(filterSchool) && list.push(item))
        if (type == "absent") {
            params = { schoolId: list[0]?.schoolId }
            if (absentData?.nextToken) {
                params = {
                    schoolId: list[0]?.schoolId,
                    nextToken: absentData?.nextToken
                }
            }
            getScreeningsBySchool(params, callBack)
            function callBack(event, res) {
                if (event == "success") {
                    setAbsentData({ ...absentData, schoolId: list[0]?.schoolId, items: res?.data?.getScreeningsBySchool?.items, nextToken: res?.data?.getScreeningsBySchool?.nextToken });
                    setDisplayData(res?.data?.getScreeningsBySchool?.items)
                }
                else { console.log(res); }
            }
        }
        else {
            params = { userId: currentUser?.userId }
            if (absentData?.nextToken) {
                params = {
                    userId: currentUser?.userId,
                    nextToken: absentData?.nextToken
                }
            }
            getReExam(params, callBack)
            function callBack(event, res) {
                if (event == "success") {
                    let items = [];
                    res?.items?.map(el => { (el?.schoolId == list[0]?.schoolId) && items.push(el); })
                    setAbsentData({ ...absentData, schoolId: list[0]?.schoolId, items: items, nextToken: res?.nextToken });
                    setDisplayData(items)
                }
                else { console.log(res); }
            }
        }
    }, [filterSchool])


    // setting class Option 
    useEffect(() => {
        let allClass = []
        absentData?.items?.map(el => allClass.push(`${el.class} ${el.section}`))
        let sortedClasses = [...new Set(allClass)]
        sortedClasses.sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))
        setClassOption(sortedClasses)
    }, [absentData])


    // filterClass and welchAllynLink 
    useEffect(() => {
        if (filterClass) {
            setDisplayData(absentData?.items?.filter(el => filterClass == `${el?.class} ${el?.section}`))
            setPageNo(1)
            let params = {
                schoolId: absentData?.schoolId,
                class: filterClass.split(' ')?.[0],
                section: filterClass.split(' ')?.[1],
            }
            dispatch(getWelchallynCsv(params));
        }
        else {
            setDisplayData(absentData?.items)
            setPageNo(1)
        }
    }, [filterClass])


    // handle organ 
    const handleOrgan = (row) => {
        let text = [];
        row?.organ?.map(el => text.push(capitalizeFirstLetter(el?.organName)))
        return text.join(', ')
    }

    return (
        <div>
            <div>{type == "absent" ? 'Absent Students' : 'Re-exam Students'}</div>
            <OpsOperatorModal
                open={openMod}
                handleClose={() => { setOpenMod(false) }}
                data={{
                    schoolId: absentData?.schoolId,
                    class: filterClass.split(' ')?.[0],
                    section: filterClass.split(' ')?.[1],
                }} />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <QRCode value={`${url}${ModalData?.qr}`} size={180} />
                    <p style={{ paddingTop: '15px' }}>{ModalData?.name}</p>
                </Box>
            </Modal>
            <br />
            <div className={classes.filterBox}>
                <div className={classes.filterOptions}>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={rowsPerPage}
                            label="Rows"
                            onChange={(e) => { setRowsPerPage(e.target.value); setPageNo(1) }}
                            size='small'
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    </FormControl>
                    <Autocomplete
                        disablePortal
                        value={filterSchool}
                        onChange={(e) => setFilterSchool(e.target.innerText)}
                        id="combo-box-demo"
                        options={schoolOption}
                        sx={{
                            width: 300,
                            '.MuiInputBase-input': { height: '8px !important', },
                            ".MuiInputLabel-root": { fontSize: '0.8rem' }
                        }}
                        renderInput={(params) => <TextField {...params} label="Select School" />}
                    />
                    <InputDropdown
                        placeholder={`Select Class`}
                        width={"12vw"}
                        disabled={!filterSchool || !classOption.length}
                        bgColor="#ffffff"
                        value={filterClass}
                        margin={2}
                        setValue={setFilterClass}
                        defaultValues={classOption}
                    />
                </div>
                <div className={classes.filterBtn}>
                    <a href={type == "absent" ? IntegrationLinks?.csvFileScreeningAbsent : IntegrationLinks?.csvFileScreeningRexam}>
                        <button disabled={(!filterClass || !filterSchool)} className={(filterClass && filterSchool)
                            ? classes.opsBtn
                            : classes.OpsBtnInactive
                        }>Welch Allyn CSV</button>
                    </a>
                    <button disabled={(!filterClass || !filterSchool)} className={(filterClass && filterSchool)
                        ? classes.opsBtn
                        : classes.OpsBtnInactive
                    } onClick={() => { setOpenMod(true) }}>
                        HIGO Data Integration</button>
                    <a href={type == "absent" ? IntegrationLinks?.csvFilePrinterAbsent : IntegrationLinks?.csvFilePrinterRexam}>
                        <button disabled={(!filterClass || !filterSchool)} className={(filterClass && filterSchool)
                            ? classes.opsBtn
                            : classes.OpsBtnInactive
                        }>Download/Print QR</button>
                    </a>
                </div>
            </div>
            <br />
            {(!filterSchool) ?
                <>
                    <div style={{ color: '#3E36B0', textAlign: 'center' }}>Please select the field to proceed further</div>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) =>
                        <Box sx={{ pt: 0.5, display: 'flex', gap: '1rem', padding: '10px 0', justifyContent: 'space-between' }} key={index}>
                            <Skeleton animation="wave" variant="circular" width={40} height={40} />
                            {[1, 2, 3, 4, 5].map((el) => <Skeleton variant="rectangular" width={200} height={40} key={el} />)}
                        </Box>)}
                </>
                :
                !!displayData?.length ? <div>
                    <TableContainer component={Paper} sx={{
                        '&.MuiTableContainer-root': {
                            boxShadow: 'none',
                            backgroundColor: 'transparent'
                        },
                        'thead th': {
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '15px'
                        }
                    }}>
                        {type == "absent" ?
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead sx={{ height: "70px" }}>
                                    <TableRow>
                                        <TableCell>STUDENT NAME</TableCell>
                                        <TableCell align="right">DOB</TableCell>
                                        <TableCell align="right">CLASS</TableCell>
                                        <TableCell align="right">PREV. EVENT DATE</TableCell>
                                        <TableCell align="right">UUID</TableCell>
                                        <TableCell align="right">QR CODE</TableCell>
                                        <TableCell align="right">STATUS</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {displayData?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo).map((row) => (
                                        <TableRow
                                            key={row?.qrCode}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 },
                                                'th': { height: '70px' },
                                                ' td:nth-child(2)': { color: '#343434' },
                                                ' td': {
                                                    fontWeight: '400',
                                                    fontSize: '12px',
                                                    lineHeight: '16px',
                                                }
                                            }}>
                                            <TableCell component="th" scope="row">
                                                <div className={classes.tabBtn_container} title={row?.firstName} >
                                                    {row?.url ? <img style={{ width: "32px", height: "32px", borderRadius: "20px", objectFit: "cover" }} src={row?.url} alt="" />
                                                        : <Avatar {...stringAvatar(capitalizeFirstLetter(row?.firstName ?? ''))} />
                                                    }{capitalizeFirstLetter(truncateString(row?.firstName ?? '', 25))}
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">{moment(row?.DOB).format('DD-MM-YYYY')}</TableCell>
                                            <TableCell align="right">{`${numberTh(row?.class)} ${row?.section}`}</TableCell>
                                            <TableCell align="right">{moment(row?.screeningDate).format('DD-MM-YYYY')}</TableCell>
                                            <TableCell align="right" sx={{ textWrap: 'nowrap' }}>{row?.screeningId}</TableCell>
                                            <TableCell align="right" >
                                                <QRCode value={`${url}${row?.screeningId}`} size={35} onClick={() => handleOpen(row?.screeningId, row?.firstName)} style={{ cursor: 'pointer' }} /></TableCell>
                                            <TableCell align="right" >
                                                <button className={classes.statusBtn} style={{ background: row?.status == 'PATIENT_ABSENT' ? '#D95A59' : '#8E8F8F' }}>
                                                    {row?.status == 'PATIENT_ABSENT' ? 'UNSCREENED' : 'INACTIVE'}
                                                </button></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            : <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead sx={{ height: "70px" }}>
                                    <TableRow>
                                        <TableCell>STUDENT NAME</TableCell>
                                        <TableCell align="right">CLASS</TableCell>
                                        <TableCell align="right">SCREENING DATE</TableCell>
                                        <TableCell align="right">RE-EXAM ORGAN</TableCell>
                                        <TableCell align="right">RE-EXAM REQUEST BY </TableCell>
                                        <TableCell align="right">QR CODE</TableCell>
                                        <TableCell align="right">STATUS</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {displayData?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo).map((row) => (
                                        <TableRow key={row?.qrCode}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 },
                                                'th': { height: '70px' },
                                                ' td:nth-child(2)': { color: '#343434' },
                                                ' td': {
                                                    fontWeight: '400',
                                                    fontSize: '12px',
                                                    lineHeight: '16px'
                                                }
                                            }}>
                                            <TableCell component="th" scope="row">
                                                <div className={classes.tabBtn_container}>
                                                    {row?.url ? <img style={{ width: "32px", height: "32px", borderRadius: "20px", objectFit: "cover" }} src={row?.url} alt="" />
                                                        : <Avatar {...stringAvatar(capitalizeFirstLetter(row?.name ?? ''))} />
                                                    }{capitalizeFirstLetter(truncateString(row?.name ?? '', 30))}
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">{`${numberTh(row?.class)} ${row?.section}`}</TableCell>
                                            <TableCell align="right">{row?.screeningDate}</TableCell>
                                            <TableCell align="right">{handleOrgan(row)}</TableCell>
                                            <TableCell align="right">{`Dr. ${row?.doctorName}`}</TableCell>
                                            <TableCell align="right">
                                                <QRCode value={`${url}${row?.screeningId}`} size={35} onClick={() => handleOpen(row?.screeningId, row?.name)} style={{ cursor: 'pointer' }} />
                                            </TableCell>
                                            <TableCell align="right" >
                                                <button className={classes.statusBtn} style={{ background: row?.status == 'REEXAM_PENDING' ? '#E09F26' : '#149457' }}>
                                                    {row?.status == "REEXAM_PENDING" ? 'RE-EXAM' : 'COMPLETED'}
                                                </button></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>}
                    </TableContainer>
                    <div className={classes.pagination_container}>
                        <div className={classes.page_item}>{`Showing ${rowsPerPage * (pageNo - 1) + 1
                            } to ${pageNo * rowsPerPage <= displayData?.length
                                ? pageNo * rowsPerPage
                                : displayData?.length
                            } of ${displayData?.length} items`}</div>
                        <div className={classes.pagination}>
                            <Button
                                size="small"
                                disabled={pageNo === 1}
                                onClick={() => setPageNo(pageNo - 1)}>
                                <ChevronLeft />
                            </Button>
                            <span>{pageNo}</span>
                            <div>
                                <span>of</span>
                                <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
                            </div>
                            <div>
                                <Button
                                    size="small"
                                    disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage) || displayData?.length == 0}
                                    onClick={() => setPageNo(pageNo + 1)}>
                                    <ChevronRight />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                    :
                    <BlankDataScreen title={`Oops! No data of ${type == "absent" ? 'Absents' : 'Re-exam'} available`} handleClick={() => navigate(-1)} buttonText="Go Back" />
            }
        </div>
    )
}

export default AbsentTable
