import { call, put, takeLatest } from "redux-saga/effects";
import ApiServices from "../../graphqlApiServices";
import supportReducer from "../reducers/support.reducer";



const { support } = ApiServices

const {
    getSupportTickets,
    getSupportTicketById,
    updateSupportTicket
} = support

export function* watchSupportRequest() {
    yield takeLatest(supportReducer.actions.getSupportTicketsRequest, getSupportTicketsSaga);
    yield takeLatest(supportReducer.actions.getSupportTicketByIdRequest, getSupportTicketByIdSaga);
    yield takeLatest(supportReducer.actions.updateSupportTicketRequest, updateSupportTicketSaga);
}

export function* getSupportTicketsSaga(payload) {
    try {
        const response = yield call(getSupportTickets, payload);
        const data = response?.data.getSupportTickets;
        yield put(supportReducer.actions.getSupportTicketsSuccess(data))
    } catch (error) {
        if (error?.data.getSupportTickets.length > 0) {
            const data = error?.data.getSupportTickets;
            yield put(supportReducer.actions.getSupportTicketsFail(data))
        }
        else {
            yield put(supportReducer.actions.getSupportTicketsFail({ error: error.errors }))
        }
    }
}

export function* getSupportTicketByIdSaga(payload) {
    try {
        const response = yield call(getSupportTicketById, payload);
        const data = response?.data.getSupportTicketById;
        yield put(supportReducer.actions.getSupportTicketByIdSuccess(data))
    } catch (error) {
        console.log(error, 'error')
        if (error?.data.getSupportTicketById.length > 0) {
            const data = error?.data.getSupportTicketById;
            yield put(supportReducer.actions.getSupportTicketByIdFail(data))
        }
        else {
            yield put(supportReducer.actions.getSupportTicketByIdFail({ error: error.errors }))
        }
    }
}

export function* updateSupportTicketSaga(payload) {
    try {
        const response = yield call(updateSupportTicket, payload);
        const data = response?.data.updateSupportTicket;
        yield put(supportReducer.actions.updateSupportTicketSuccess(data))
    } catch (error) {
        console.log(error, 'error')
        if (error?.data.updateSupportTicket.length > 0) {
            const data = error?.data.updateSupportTicket;
            yield put(supportReducer.actions.updateSupportTicketFail(data))
        }
        else {
            yield put(supportReducer.actions.updateSupportTicketFail({ error: error.errors }))
        }
    }
}