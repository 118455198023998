import React, { useEffect, useState } from "react";
import classes from "./SchoolInfo.module.css";
import DashboardLayout from "../common/DashboardLayout/DashboardLayout";
import schoollogo from "../../assets/images/schoollogo.png";
import email from "../../assets/images/email.svg";
import phone from "../../assets/images/phone.svg";
import address from "../../assets/images/address.svg";
import extraactivities from "../../assets/images/extraactivities.svg";
import infirmary from "../../assets/images/infirmary.svg";
import medical from "../../assets/images/medical.svg";
import library from "../../assets/images/library.svg";
import internet from "../../assets/images/internet.svg";
import teacherblue from "../../assets/images/teacherblue.svg";
import studentsblue from "../../assets/images/studentsblue.svg";
import bus from "../../assets/images/bus.svg";
import cab from "../../assets/images/cab.svg";
import hotel from "../../assets/images/hotel.svg";
import canteen from "../../assets/images/canteen.svg";
import sanitizer from "../../assets/images/sanitizer.svg";
import smartclass from "../../assets/images/smartclass.svg";
import doctor from "../../assets/images/doctor.png";
import oxygensupport from '../../assets/images/oxygensupport.svg'
import ambulance from '../../assets/images/ambulance.svg'
import firstaidbox from '../../assets/images/firstaidbox.svg'
import staff from "../../assets/images/staff.png";
import counsellorsblue from "../../assets/images/counsellorsblue.svg";
import nurse from "../../assets/images/nurse.png";
import students from "../../assets/images/students.png";
import thermometer from '../../assets/images/thermometer.svg'
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import skidsLogo from "../../assets/images/skidsLogo.png";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ActionModal from "../common/ActionModal/ActionModal";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import schoolReducer from "../../redux/reducers/school.reducer";
import { API } from "aws-amplify";
import Cookies from "js-cookie";
import editOrg from "../../assets/images/editOrg.svg";
import uploaddata from "../../assets/images/uploaddata.svg";
import ChipModal from "../common/ChipModal/ChipModal";
import doctorblue from "../../assets/images/doctorblue.svg";
import editdeactivated from "../../assets/images/editdeactivated.svg";
import Tooltip from "@mui/material/Tooltip";
import { Alert, Avatar, Snackbar } from "@mui/material";
import studentReducer from "../../redux/reducers/student.reducer";
import PackageDrawer from "../common/PackageDrawer/PackageDrawer";
import moment from "moment";
import { stringAvatar, textFormat } from "../../utils/utility";

function SchoolInfoPage() {
  const token = Cookies.get("tokenId");
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(skidsLogo);
  const [logoRes, setLogoRes] = useState(false);
  const [chipModal, setChipModal] = useState(false);

  const getStudentsBySchoolId = studentReducer.actions.getStudentBySchoolIDRequest;

  const students = useSelector((state) => state.studentReducer.data)

  const [schoolData, setSchoolData] = useState(null);
  const [open, setOpen] = useState(false);
  const [isActiveClinicStatus, setIsActiveClinicStatus] = useState(
    location?.state?.isActive
  );
  const errorClinic = useSelector((state) => state.clinicReducer.error);
  const errorSchool = useSelector((state) => state.schoolReducer.error);

  const info = useSelector((state) => state.schoolReducer.data);

  const [isActiveStatus, setIsActiveStatus] = useState(info?.isActive);

  useEffect(() => {
    dispatch(getStudentsBySchoolId({ schoolId: location.state.schoolId }))
  }, [info])

  let navLink = "/superadmin-school"
  let navLinkHeaderSec = "Santhiniketan School"
  let editLink = location.pathname.includes('partner') ? `/partner/partner-school/edit/${info?.schoolName}` :  `/superadmin-school/edit/${info?.schoolName}`
  let status = "INACTIVE"
  let entity = location.pathname.includes("school") ? "school" : "clinic"
  let entityName = "Santhiniketan School"
  let entityTitle = "CBSE affiliated"
  let basic_info = [
    {
      label: "Organization type",
      value: info?.organization ?? "Not available",
    },
    {
      label: "Annual orientation month",
      value: info?.annualOrientationMonth ?? "Not available",
    },
    {
      label: "Established from",
      value: info?.establishedFrom ?? "Not available",
    },
    {
      label: "Screening Package",
      value: textFormat(info?.package) ?? "Not available",
    },
    {
      label: "Screening From",
      value: info?.screeningFrom ?? "Not available",
    },
    {
      label: "Screening To",
      value: info?.screeningTo ?? "Not available",
    },
  ];
  let contact_details = [
    {
      icon: email,
      label: "Email id",
      value: info?.contact?.email ?? "Not available",
    },
    {
      icon: phone,
      label: "Mobile no.",
      value: info?.contact?.officeNumber ?? "Not available",
    },
    {
      icon: address,
      label: "Address",
      value: info?.address ?? "Not available",
    },
    {
      icon: teacherblue,
      label: "IT Admin",
      link: "/superadmin-school/table/admin",
      value: "1",
    },
    {
      icon: teacherblue,
      label: "Teachers",
      link: "/superadmin-school/table/teacher",
      value: info?.staffAndAmenities?.teachers ?? "Not available",
    },
    {
      icon: studentsblue,
      label: "Students",
      link: "/superadmin-school/table/student",
      value: `${students?.length ?? 0}/${info?.strength}` ?? 'Not available',
    },

    {
      icon: counsellorsblue,
      label: "Principal",
      link: "/superadmin-school/table/principal",
      value: "1",
    },
    // {
    //   icon: counsellorsblue,
    //   label: "Counsellors",
    //   link: "/superadmin-school/table/counsellor",
    //   value: info?.staffAndAmenities?.counsellors?.counsellorsCount,
    // },
    {
      icon: doctorblue,
      label: "Infirmary Staff",
      link: "/superadmin-school/table/doctor",
      value:
        `${info?.staffAndAmenities?.infirmary?.doctor ?? 0} Doctors, ${info?.staffAndAmenities?.infirmary?.nurse ?? 0} Nurses`
    },
  ];
  let ameities = [
    {
      icon: library,
      value: "Library",
    },
    {
      icon: internet,
      value: "Internet access",
    },
    {
      icon: bus,
      value: "Transportation",
    },
    {
      icon: canteen,
      value: "Canteen",
    },
    {
      icon: hotel,
      value: "Hostel",
    },
    {
      icon: smartclass,
      value: "Smart Class",
    },
    {
      icon: sanitizer,
      value: "Sanitization",
    },
    {
      icon: cab,
      value: "Cab services",
    },
    {
      icon: extraactivities,
      value: "Extra activities",
    },
    {
      icon: medical,
      value: "Medical Room",
    },
    {
      icon: medical,
      value: "Infirmary Bed"
    },

    {
      icon: firstaidbox,
      value: "First Aid Box"
    },
    {
      icon: thermometer,
      value: "Thermometer"
    },
    {
      icon: medical,
      value: "BP Machine"
    },
    {
      icon: ambulance,
      value: "Ambulance"
    },
    {
      icon: oxygensupport,
      value: "Oxygen Support"
    },

  ];
  let userLink;
  if (location.pathname.includes("clinic")) {
    navLink = "/superadmin-clinic";
    editLink = `/superadmin-clinic/edit/${location.state?.name}`;
    status = location.state?.status;
    navLinkHeaderSec = "Skids Clinic, Sec-23";
    entity = "Clinic";
    entityName = "Skids Clinic, Sec-23";
    entityTitle = "Pediatrics Super Speciality";
    basic_info = [
      {
        label: "Speciality",
        value: location.state?.specialty,
      },
      {
        label: "Established from",
        value: location.state?.establishment ? moment(location.state?.establishment).format("DD-MM-YYYY") : "Unavailable",
      },
    ];
    contact_details = [
      {
        icon: email,
        label: "Email id",
        value: location.state?.email ?? "Unavailable",
      },
      {
        icon: phone,
        label: "Mobile no.",
        value: location.state?.phone ?? "Unavailable",
      },
      {
        icon: address,
        label: "Address",
        value: location.state?.address ?? "Unavailable",
      },
      {
        icon: doctorblue,
        label: "Doctor",
        link: "/superadmin-clinic/table/doctor",
        value: location.state?.doctor ?? "Unavailable",
      },
      {
        icon: teacherblue,
        label: "Nurse",
        link: "/superadmin-clinic/table/nurse",
        value: location.state?.nurse ?? "Unavailable",
      },
      {
        icon: studentsblue,
        label: "Staff",
        link: "/superadmin-clinic/table/staff",
        value: location.state?.staff ?? "Unavailable",
      },
    ];
    ameities = [
      {
        icon: library,
        value: "Library",
      },
      {
        icon: internet,
        value: "Internet Access",
      },
      {
        icon: bus,
        value: "Transportation",
      },
      {
        icon: canteen,
        value: "Canteen",
      },
      {
        icon: hotel,
        value: "Hostel",
      },
      {
        icon: smartclass,
        value: "Smart Class",
      },
      {
        icon: sanitizer,
        value: "Sanitization",
      },
      {
        icon: cab,
        value: "Cab Services",
      },
      {
        icon: extraactivities,
        value: "Extra Activities",
      },
      {
        icon: medical,
        value: "Medical Room",
      },
    ];
  }

  // function getWordBeforeDash() {
  //   let newStr = "";
  //   for (let i = 1; i < location.pathname.length; i++) {
  //     if (location.pathname[i] === "-") {
  //       return newStr;
  //     }
  //     newStr += location.pathname[i];
  //   }
  // }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigateLink = () => {
    navigate("/superadmin-screening");
  };


  const fetchOrganizationLogo = async () => {
    const getAuthHeaders = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return await API.get(
      "MyLogoAPIGateway",
      `/${info.schoolId}.jpeg`,
      getAuthHeaders
    )
      .then((res) => {
        setSelectedFile(res.selectedFile);
      })
      .catch((error) => {
        setSelectedFile(skidsLogo);
      });
  };

  useEffect(() => {
    setIsActiveStatus(info?.isActive)
  }, [info])


  useEffect(() => {
    fetchOrganizationLogo();
  }, []);


  //  Link to="/edit"
  return (
    <div className={classes.school_info}>
      <div className={classes.school_header}>
        <div className={classes.header}>
          {/* <div className={classes.back}>
            {" "}
            <Link
              to={navLink}
              style={{ textDecoration: "none", color: "#9d9d9d" }}
            >
              Registered {entity}{" "}
            </Link>{" "}
          </div>
          <ArrowRight />{" "}
          <div className={classes.header_text}>{location?.state?.name}</div> */}
          <BreadCrumbs />
        </div>
        <Link
          to={editLink}
          style={{ textDecoration: "none", color: "white", pointerEvents: entity === "school" && !info?.isActive ? "none" : "all" }}
          state={info}
        >
          {entity === "school" && (info?.isActive ? (
            <button className={classes.edit_button}>
              <img src={editOrg} />
              <span>Edit {entity}</span>
            </button>
          ) : (
            <button className={classes.edit_button_disabled}>
              <img src={editdeactivated} />
              <span>Edit {entity}</span>
            </button>
          ))}
          {entity === "clinic" && (location.state?.isActive ? (
            <button className={classes.edit_button}>
              <img src={editOrg} />
              <span>Edit {entity}</span>
            </button>
          ) : (
            <button className={classes.edit_button_disabled}>
              <img src={editdeactivated} />
              <span>Edit {entity}</span>
            </button>
          ))}
        </Link>
      </div>
      <div className={classes.school_container}>
        <div className={classes.status_container}>
          {info?.isActive && !entity === "Clinic" ? (
            <div
              className={classes.behavioral_container}
              onClick={() => setChipModal(true)}
            >
              <img src={uploaddata} />
              <span>Send Behavioral Master Link</span>
            </div>
          ) : (
            <></>
          )}

          <div
            className={`${info?.isActive
              ? `${classes.status_bar} ${classes.status_bar_active}`
              : `${classes.status_bar} ${classes.status_bar_inactive}`
              }`}
          >
            {info?.isActive ? "ACTIVE" : "INACTIVE"}
          </div>
        </div>
        <div className={classes.brandlogo_container}>
          {info?.url ? <img
            alt=""
            src={info?.url}
            className={classes.brandlogoPulse}
          /> : <Avatar {...stringAvatar(`${info?.schoolName ?? location.state?.name}`)} sx={{ height: '120px', width: '120px', fontSize: 70 }} sizes="large" />
          }
        </div>

        <div className={classes.school_name}>{info?.schoolName}</div>
        <div className={classes.school_title}>{info?.board}</div>
        <div className={classes.school_details}>
          <div className={classes.basic_details}>
            {basic_info.map(
              (item, index) =>
                index < 3 && (
                  <div>
                    <div className={classes.basic_label}>{item.label}</div>
                    <div className={classes.basic_value}>{item.value}</div>
                  </div>
                )
            )}
          </div>

          <div className={classes.basic_details}>
            {basic_info.map(
              (item, index) =>
                index >= 3 && (
                  <div>
                    <div className={classes.basic_label}>{item.label}</div>
                    <div className={`${index === 3 ? `${classes.basic_value} ${classes.basic_value_index}` : `${classes.basic_value}`}`}>{item.value}</div>
                  </div>
                )
            )}
          </div>
        </div>

        <div className={classes.contact_detail}>
          <div>
            {contact_details?.map(
              (item, index) =>
                index < 3 && (
                  <div className={classes.contacts}>
                    <img
                      className={classes.contacts_icon}
                      alt=""
                      src={item.icon}
                    ></img>
                    <div className={classes.contacts_value}>
                      <div>{item.label}</div>
                      <div>{item.value}</div>
                    </div>
                  </div>
                )
            )}
            {/* <div className={classes.contacts}>
              <img className={classes.contacts_icon} alt="" src={email} />
              <div className={classes.contacts_value}>
                <div>Email ID</div>
                <div>{data?.contact?.email}</div>
              </div>
            </div>
            <div className={classes.contacts}>
              <img className={classes.contacts_icon} alt="" src={phone} />
              <div className={classes.contacts_value}>
                <div>Mobile no.</div>
                <div>{data?.contact?.mobNumber}</div>
              </div>
            </div>
            <div className={classes.contacts}>
              <img className={classes.contacts_icon} alt="" src={address} />
              <div className={classes.contacts_value}>
                <div>Address</div>
                <div>{data?.address}</div>
              </div>
            </div> */}
          </div>
          <div className={classes.contact_right_pannel}>
            <Divider orientation="vertical" variant="middle" flexItem />
            <div className={classes.contact_items_right}>
              {contact_details?.map(
                (item, index) =>
                  index < 6 &&
                  index >= 3 && (
                    <div className={classes.contacts}>
                      <img
                        className={classes.contacts_icon}
                        alt=""
                        src={item.icon}
                      ></img>
                      {info?.isActive ? (
                        <Link
                          to={item.link}
                          state={{
                            ...location.state,
                            breadCrumbs: location.state
                              ? [...location.state?.breadCrumbs, item.label]
                              : [item.label],
                          }}
                        >
                          <div className={classes.contacts_value_right}>
                            <div>{item.label}</div>
                            <div>{item.value}</div>
                          </div>
                        </Link>
                      ) : (
                        <Tooltip title="Activate First">
                          <Link to={"#"} state={{ ...location.state }}>
                            <div className={classes.contacts_value_right}>
                              <div>{item.label}</div>
                              <div>{item.value}</div>
                            </div>
                          </Link>
                        </Tooltip>
                      )}
                    </div>
                  )
              )}
            </div>
            <div className={classes.contact_items_right}>
              {contact_details?.map(
                (item, index) =>
                  index < 9 &&
                  index >= 6 && (
                    <div className={classes.contacts}>
                      <img
                        className={classes.contacts_icon}
                        alt=""
                        src={item.icon}
                      ></img>
                      {info?.isActive ? (
                        <Link
                          to={item.link}
                          state={{
                            ...location.state,
                            breadCrumbs: location.state
                              ? [...location.state?.breadCrumbs, item.label]
                              : [item.label],
                          }}
                        >
                          <div className={classes.contacts_value_right}>
                            <div>{item.label}</div>
                            <div>{item.value}</div>
                          </div>
                        </Link>
                      ) : (
                        <Tooltip title="Activate First">
                          <Link to={"#"} state={{ ...location.state }}>
                            <div className={classes.contacts_value_right}>
                              <div>{item.label}</div>
                              <div>{item.value}</div>
                            </div>
                          </Link>
                        </Tooltip>
                      )}
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
        <div>
          <div className={classes.basic_label}>Amenities available</div>
          <div className={classes.ameities}>
            {ameities.map((item) => {
              if (
                info?.staffAndAmenities?.amenitiesAvailable &&
                info?.staffAndAmenities?.amenitiesAvailable[0]?.split(",")?.includes(item.value)
              ) {
                return (
                  <div className={classes.ameities_item}>
                    <img src={item.icon} alt="" />
                    <div className={classes.ameities_text}>{item.value}</div>
                  </div>
                );
              }
            })}
          </div>
        </div>
        {
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={
                (entity === "clinic" ? !errorClinic : !errorSchool)
                  ? "success"
                  : "error"
              }
              sx={{ width: "100%" }}
            >
              {(entity === "clinic" ? !errorClinic : !errorSchool)
                ? ` ${entity === "school" ? "School" : "Clinic"} ${isActiveStatus === false ? `Deactivated!` : `Activated`
                }`
                : `Oh Oh !! Some issue in  ${isActiveStatus === false ? `deactivating!` : `activating!`
                }
             ${entity === "school" ? "School" : "Clinic"}. Try Again`}
            </Alert>
          </Snackbar>
        }

        <div className={classes.activate_services_container}>
          {isActiveStatus === false ? (
            <>
              <ActionModal
                data={info ?? location.state}
                handleClick={handleOpen}
                actionName="Activate"
                isActiveStatus={isActiveStatus}
                setIsActiveStatus={setIsActiveStatus}
                org={entity}
              />
              {/* <ActionModal actionName="Delete" org="School" /> */}
            </>
          ) : (
            // <button className= {classes.delete_btn}>Deactivate Clinic</button>
            <ActionModal
              actionName="Deactivate"
              org={entity}
              data={info}
              handleClick={handleOpen}
              isActiveStatus={isActiveStatus}
              setIsActiveStatus={setIsActiveStatus}
            />
          )}
        </div>
        <ChipModal
          action={chipModal}
          setAction={setChipModal}
          title="Share B.A Master Link"
          subtitle="Please enter email id below to share “B.A. Master Link” typeform to school representative."
        />
      </div>
    </div>
  );
}

function SchoolInfo() {
  const dispatch = useDispatch();
  const location = useLocation();

  const getSchoolDetails = schoolReducer.actions.getSchoolbyIdRequest;
  // const getClinicDetail


  useEffect(() => {
    dispatch(getSchoolDetails({ schoolId: location.state?.schoolId }))
  }, [])


  return <SchoolInfoPage />;
}

export default SchoolInfo;
