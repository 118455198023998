import React, { useEffect, useState } from 'react'
import classes from './AssessmentsRow.module.css'
import { Avatar, Box, Button, Modal, Typography } from '@mui/material'
import { Visibility } from '@mui/icons-material'
import { getAge, getRisk, stringAvatar } from "../../../utils/utility";
import { useLocation, useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer'
import InputDashboard from '../../common/InputDashboard/InputDashboard';
import { PopupModal } from 'react-calendly';
import AppointmentsSideBar from '../../common/AppointmentsSideBar/AppointmentsSideBar';



function AssessmentsRow({ data, title, pageType, keyTitle }) {
    const navigate = useNavigate()
    const [type, setType] = useState()

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [showIframe, setShowIframe] = useState(false)
    const [link, setLink] = useState('')

    // convert text into constant 
    function convertToConstant(str) {
        return str.toUpperCase().replace(/ /g, '_');
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    // title name 
    // function newt(title) {
    //     let value = convertToConstant(title)
    //     if (title == "Screen Dependency Disorder") {
    //         return "INTERNET_ADDICTION"
    //     }
    //     if (title == "Oppositional Defiant Disorder") {
    //         return "ODD"
    //     }
    //     else if (title == "Developmental Milestones") {
    //         return "DEVELOPMENTAL_DELAY"
    //     }
    //     else if (title == "Dyscalculia Stage 1 & 2") {
    //         return "DYSCALCULIA_2"
    //     }
    //     else if (title == "Dysgraphia Stage 1 & 2") {
    //         return "DYSGRAPHIA_2"
    //     }
    //     else if (title == "Dyslexia Stage 1 & 2") {
    //         return "DYSLEXIA_2"
    //     }
    //     else {
    //         return value
    //     }
    // }

    // get report 
    const getReport = () => {
        let reportType = (pageType == 'school' ? 'behavioral-report' : 'd2c-report')
        switch (title) {
            case "ADHD": return `/${reportType}/ADHD`
            case "Autism": return `/${reportType}/AUTISM`
            case "Screen Dependency Disorder": return `/${reportType}/DIGITAL_DEPENDENCY`
            case "Anxiety": return `/${reportType}/ANXIETY`
            case "Conduct Disorder": return `/${reportType}/CONDUCT_DISORDER`
            case "Oppositional Defiant Disorder": return `/${reportType}/OPPOSITIONAL_DEFIANT_DISORDER`
            case "Depression": return `/${reportType}/DEPRESSION`
            case "Developmental Milestones": return `/${reportType}/DEVELOPMENTAL_DELAY`
            case "Dyslexia Stage 1 & 2": return `/${reportType}/DYSLEXIA`
            case "Dyscalculia Stage 1 & 2": return `/${reportType}/DYSCALCULIA`
            case "Dysgraphia Stage 1 & 2": return `/${reportType}/DYSGRAPHIA`
        }
    }

    // set type data 
    // useEffect(() => {
    //     let typeString = data?.[newt(title)]
    //     let learning = JSON.parse(typeString)
    //     learningCheck(learning)
    //     function learningCheck(learning) {
    //         if (!learning?.score && newt(title) == "DYSCALCULIA_2") {
    //             setType(JSON.parse(data?.['DYSCALCULIA_1']))
    //         }
    //         else if (!learning?.score && newt(title) == "DYSGRAPHIA_2") {
    //             setType(JSON.parse(data?.['DYSGRAPHIA_1']))
    //         }
    //         else if (!learning?.score && newt(title) == "DYSLEXIA_2") {
    //             setType(JSON.parse(data?.['DYSLEXIA_1']))
    //         }
    //         else {
    //             setType(JSON.parse(typeString))
    //         }
    //     }
    // }, [])

    useEffect(() => {
        let arr = JSON.parse(data?.[keyTitle])
        if (!arr?.score && keyTitle == "DYSCALCULIA_2") {
            setType(JSON.parse(data?.['DYSCALCULIA_1']))
        }
        else if (!arr?.score && keyTitle == "DYSGRAPHIA_2") {
            setType(JSON.parse(data?.['DYSGRAPHIA_1']))
        }
        else if (!arr?.score && keyTitle == "DYSLEXIA_2") {
            setType(JSON.parse(data?.['DYSLEXIA_1']))
        }
        else {
            setType(JSON.parse(data?.[keyTitle]))
        }
    }, [])

    // schedule Call 
    const handleSchedule = () => {
        let x = "https://calendly.com/support-skids/30min"
        const params = JSON.stringify({
            kidId: data?.kidId,
            assessmentId: data?.assessmentId
        })
        const stringify = Buffer.from(params).toString("base64")
        x = x + "?utm_content=" + stringify + "&email=" + email + "&name=" + data?.name
        console.log(x)
        setLink(x)
        handleClose()
        setShowIframe(true)
    }

    useEffect(() => {
        setEmailError(String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ))
    }, [email])

    const handleOpenSchedule = () => {
        if (data?.email) {
            let x = "https://calendly.com/support-skids/30min"
            const params = JSON.stringify({
                kidId: data?.kidId,
                assessmentId: data?.assessmentId
            })
            const stringify = Buffer.from(params).toString("base64")
            x = x + "?utm_content=" + stringify + "&email=" + data?.email + "&name=" + data?.name
            setLink(x)
            setShowIframe(true)
        } else {
            handleOpen()
        }
    }

    const setLocalStorage = () => {
        const x = { ...data }
        x['student'] = data
        localStorage.setItem("assessment", JSON.stringify(x))
        localStorage.setItem('sId', x?.screeningId)
        localStorage.setItem('name', x?.name)
    }
    return (
        <>
            <div className={classes.studentRow_container}>
                {<PopupModal
                    url={link}
                    rootElement={document.getElementById("root")}
                    text="Click here to schedule!"
                    onModalClose={() => setShowIframe(false)}
                    open={showIframe}
                />}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ backdropFilter: 'blur(2px)' }}
                >
                    <Box sx={style}>
                        {<>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Enter your email id
                            </Typography>
                            <InputDashboard
                                placeholder={"Email"}
                                width={"100%"}
                                margin={2.4}
                                change={(e) => {
                                    setEmail(e.target.value);
                                }}
                                bgColor="#ffffff"
                                value={email}
                            />
                            <Button disabled={!emailError} fullWidth className={classes.bookButton} onClick={handleSchedule}>Book Consultation</Button>
                        </>
                        }
                    </Box>
                </Modal>
                {/* Name and Avatar Container */}
                <div>
                    <div>
                        <Avatar {...stringAvatar(data?.name)} />
                    </div>
                    <div>
                        {data?.name}
                    </div>
                </div>
                <div>
                    <p>{getAge(data?.DOB)} yrs</p>
                </div>
                <div>
                    {data?.gender}
                </div>
                <div>
                    <span>{type?.score}</span>
                    <a href={getReport()} target='_blank' onClick={setLocalStorage}>Check Report</a>
                </div >
                <div>
                    {data?.package?.[0] ?
                        getRisk(data?.package?.[0], JSON.parse(data?.[data?.package?.[0]])?.riskFactor)
                        : getRisk(keyTitle, JSON.parse(data?.[keyTitle])?.riskFactor)
                    }
                    {/* <div style={{ color: getRiskValue(type?.riskFactor).color }}>
                    {getRiskValue(type?.riskFactor).value}
                </div> */}
                </div>

                <div>
                    {data?.consultation ?
                        <>
                            <Button>SCHEDULED</Button>
                            <AppointmentsSideBar data={data} />
                        </>
                        :
                        <Button style={{ backgroundColor: "#F5F6F7", color: '#001028', border: '1px solid #001028' }} onClick={handleOpenSchedule}>SEND MAIL</Button>
                    }
                </div>

                <div style={{ justifySelf: "center", cursor: 'pointer' }}>
                    <Visibility onClick={() => navigate(`/behavioral-doctor/info/${data?.name}`, { state: data })} />
                </div>
            </div >
        </>
    )
}

export default AssessmentsRow