import { createSlice } from "@reduxjs/toolkit";
import InitialStore from "../store/InitialStore";

export const getSchoolsRequest = (state, action) => {
    state.created = false
    state.loading = true
    state.error=false
    state.data=[]
    state.errorMessage=''
};

export const getSchoolsScuccess = (state, action) => {
    
    state.created = false;
    state.data = action.payload.data
    state.loading = false
};

export const getSchoolsFail = (state, action) => {
    state.created = false;
    state.error=true
    state.errorMessage = action.payload.error.message
    state.loading=false
};

export const getSchoolbyIdRequest = (state, action) => {
    state.loading = true
    state.error=false
    state.data=[]
    state.errorMessage=''
};

export const getSchoolbyIdScuccess = (state, action) => {
    state.data = action.payload.data
    state.loading = false
};

export const getSchoolbyIdFail = (state, action) => {
    state.error=true
    state.errorMessage = action.payload.error.message
    state.loading=false
};

export const getSchoolbyStateRequest = (state, action) => {
    state.loading = true
    state.error=false
    state.data=[]
    state.errorMessage=''
};

export const getSchoolbyStateScuccess = (state, action) => {
    state.data = action.payload.data
    state.loading = false
};

export const getSchoolbyStateFail = (state, action) => {
    state.error=true
    state.errorMessage = action.payload.error.message
    state.loading=false
};

export const createSchoolRequest = (state, action) => {
    state.loading = true
    state.error=false
    state.errorMessage=''
    state.created={}
};

export const createSchoolScuccess = (state, action) => {
    state.loading = false
    state.created=action.payload.data
};

export const createSchoolFail = (state, action) => {
    state.error=true
    state.errorMessage = action.payload.error.message
    state.loading=false
};

export const updateSchoolRequest = (state, action) => {
    state.loading = true
    state.error=false
    state.errorMessage=''
    state.created=false
}

export const updateSchoolSuccess = (state, action) => {
    state.data = action.payload.data
    state.loading = false
    state.created=true
}

export const updateSchoolFail = (state, action) => {
    state.error=true
    state.errorMessage = action.payload.error.message
    state.loading=false
}

export const deactivateSchoolRequest = (state, action) => {
    state.loading = true
    state.error = false
}
export const deactivateSchoolSuccess = (state, action) => {
    state.data = action.payload.data
    state.loading = false
    state.error = false
}
export const deactivateSchoolFail = (state, action) => {
    state.error = false
    state.loading = false
    state.errorMessage = action.payload.error.message
}

export const activateSchoolRequest = (state, action) => {
    state.loading = true
    state.error = false
}
export const activateSchoolSuccess = (state, action) => {
    state.data = action.payload.data
    state.loading = false
    state.error = false
}
export const activateSchoolFail = (state, action) => {
    state.error = false
    state.loading = false
    state.errorMessage = action.payload.error.message
}

export const updatePackageRequest = (state, action) => {
    state.loading = true
    state.error = false
}
export const updatePackageSuccess = (state, action) => {
    state.loading = false
}

export const updatePackageFail = (state, action) => {
    state.loading = false
    state.error = true
}

export const releaseReportRequest = (state, action) => {
    state.loading = true
    state.error = false
}
export const releaseReportSuccess = (state, action) => {
    // state.data = action.payload.data
    state.loading = false
    state.error = false
}
export const releaseReportFail = (state, action) => {
    state.error = false
    state.loading = false
    // state.errorMessage = action.payload.error.message
}

export const uploadPhotoRequest = (state, action) => {
    state.loading = true
    state.error = false
}
export const uploadPhotoSuccess = (state, action) => {
    // state.data = action.payload.data
    state.loading = false
    state.error = false
}
export const uploadPhotoFail = (state, action) => {
    state.error = false
    state.loading = false
    // state.errorMessage = action.payload.error.message
}


export default createSlice({
    name: "schoolReducer",
    initialState: InitialStore.schools,
    reducers: {
        getSchoolsRequest,
        getSchoolsScuccess,
        getSchoolsFail,
        getSchoolbyIdRequest,
        getSchoolbyIdScuccess,
        getSchoolbyIdFail,
        getSchoolbyStateRequest,
        getSchoolbyStateScuccess,
        getSchoolbyStateFail,
        createSchoolRequest,
        createSchoolScuccess,
        createSchoolFail,
        updateSchoolRequest,
        updateSchoolSuccess,
        updateSchoolFail,
        deactivateSchoolRequest,
        activateSchoolRequest,
        deactivateSchoolSuccess,
        activateSchoolSuccess,
        deactivateSchoolFail,
        activateSchoolFail,
        updatePackageRequest,
        updatePackageSuccess,
        updatePackageFail,
        releaseReportRequest,
        releaseReportSuccess,
        releaseReportFail,
        uploadPhotoRequest,
        uploadPhotoSuccess,
        uploadPhotoFail
    },
});

