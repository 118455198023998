import React from 'react'
import PrincipalTeacher from '../../PrincipalFlow/PrincipalTeacher/PrincipalTeacher'
import PrincipalStudent from '../../PrincipalFlow/PrincipalStudent/PrincipalStudent'
import PrincipalInfirmary from '../../PrincipalFlow/PrincipalInfirmary/PrincipalInfirmary'
import ITAdminStudent from '../../ITAdminFlow/AdminStudent/AdminStudent'
import { useLocation, useParams } from 'react-router-dom'
import DashboardLayout from '../DashboardLayout/DashboardLayout'


export const IndividualTable = () => {

    const {state} = useLocation();

    const GetTablePageView = () => {
        const { type } = useParams()
        if (type === 'teacher') {
            return <>
                <PrincipalTeacher />
            </>
        } else if (type === 'student') {
            return <>
                <PrincipalStudent />
            </>
        } else if (type === 'infirmary') {
            return <>
                <PrincipalInfirmary />
            </>
        } else if (type === 'admin') {
            return <>
                <PrincipalInfirmary />
            </>
        } else if (type === 'doctor') {
            return <>
                <PrincipalInfirmary />
            </>
        } else if (type === 'nurse') {
            return <>
                <PrincipalInfirmary />
            </>
        } else if (type === 'staff') {
            return <>
                <PrincipalInfirmary />
            </>
        }else if(type === 'counsellor'){
            return <>
            <PrincipalInfirmary />
        </>
        }else if(type === 'principal'){
            return <>
            <PrincipalInfirmary />
        </>
        }
    }

    return (
        <>
            {GetTablePageView()}
        </>
    )  
}
