import React from 'react'
import classes from './SearchBar.module.css'
import searchIcon from "../../../assets/images/search-icon.svg";
import Search from '@mui/icons-material/Search';
import { FormControl, Input, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import InputDashboard from '../InputDashboard/InputDashboard';
import searchicon from '../../../assets/images/search-icon.svg'

export default function SearchBar({value, onchange, id}){
return (
  <>
    <FormControl sx={{ width: '19.444vw' }} variant="outlined">
    <OutlinedInput
      id={id}
      size='small'
      type={'text'}
      placeholder="Search"
      inputProps={{
        sx: {
          textAlign: "left",
          fontSize: "15px",
          "&::placeholder": {
            textAlign: "left",
          },
        },
      }}
      startAdornment={
        <InputAdornment position='start'>
          {/* <Search sx = {{fill : "#66707E", fontWeight : "00"}}/> */}
          <img src = {searchicon}/>
        </InputAdornment>
      }
      onChange={(e)=>onchange(e.target.value)}
    />
  </FormControl>
  </>
  )
}
