import React, { useCallback, useEffect, useRef } from 'react'
import * as d3 from 'd3'

function D2CGraph({data}) {

    const graphRefD2C = useRef(null);

      useEffect(() => {

        document.querySelector("#D2CSVG")?.remove()

        const lineChartData = [
            {
              currency: "USD",
              values: [
                {
                  date: "2018/01/01",
                  close: 230
                },
                {
                  date: "2018/02/01",
                  close: 269
                },
                {
                  date: "2018/03/01",
                  close: 234 
                },
                {
                  date: "2018/04/01",
                  close: 282 
                },
                {
                  date: "2018/05/01",
                  close: 231 
                },
                {
                  date: "2018/06/01",
                  close: 240 
                },
                {
                  date: "2018/07/01",
                  close: 213 
                },
                {
                  date: "2018/08/01",
                  close: 320 
                },
                {
                  date: "2018/09/01",
                  close: 253 
                },
                {
                  date: "2018/10/01",
                  close: 264 
                },
                {
                  date: "2018/11/01",
                  close: 272
                },
                {
                  date: "2018/12/01",
                  close: 290
                }
              ]
            }
          ];
        
          const margin = {
            top: 20,
            bottom: 35,
            left: 30,
            right: 20
          };
        
          const width = 510 - margin.left - margin.right;
          const height = 300 - margin.top - margin.bottom;
          
          const createGradientD2C = select => {
            const gradient = select
              .select('defs')
                .append('linearGradient')
                  .attr('id', 'gradientD2C')
                  .attr('x1', '0%')
                  .attr('y1', '100%')
                  .attr('x2', '0%')
                  .attr('y2', '0%');
        
            gradient
              .append('stop')
                .attr('offset', '0%')
                .attr('style', 'stop-color:#FFFFFF;stop-opacity:0.05');
        
            gradient
              .append('stop')
                .attr('offset', '100%')
                .attr('style', 'stop-color:#6C60F3;stop-opacity:.5');
          }
          
        
          const svgD2C = d3.select(graphRefD2C.current)
            .append('svg')
              .attr('width', 510 + margin.left + margin.right)
              .attr('height', 300 + margin.top + margin.bottom)
              .attr('id', 'D2CSVG')
            .append('g')
              .attr('transform', `translate(${margin.left}, ${margin.top})`);
          
          svgD2C.append('defs');
          svgD2C.call(createGradientD2C);
        
          const parseTime = d3.timeParse('%Y/%m/%d');
          
          const parsedData = lineChartData.map(company => ({
            ticker: company.ticker,
            values: company.values.map(val => ({
              close: val.close,
              date: parseTime(val.date)
            }))
          }));
        
          const xScale = d3.scaleTime()
            .domain([
              d3.min(parsedData, d => d3.min(d.values, v => v.date)),
              d3.max(parsedData, d => d3.max(d.values, v => v.date))
            ])
            .range([0, width]);
          
          const yScale = d3.scaleLinear()
            .domain([
              d3.min(parsedData, d => d3.min(d.values, v => v.close)),
              d3.max(parsedData, d => d3.max(d.values, v => v.close))
            ])
            .range([height, 0]);
          
          const line = d3.line()
            .x(d => xScale(d.date))
            .y(d => yScale(d.close))
            .curve(d3.curveCatmullRom.alpha(0.5));
          
          svgD2C.selectAll('.line')
            .data(parsedData)
            .enter()
            .append('path')
              .attr('d', d => {
                const lineValues = line(d.values).slice(1);
                const splitedValues = lineValues.split(',');
            
                return `M0,${height},${lineValues},l0,${height - splitedValues[splitedValues.length - 1]}`
              })
              .style('fill', 'url(#gradientD2C)')
          
          svgD2C.selectAll('.line')
            .data(parsedData)
            .enter()
            .append('path')
              .attr('d', d => line(d.values))
              .attr('stroke-width', '2')
              .style('fill', 'none')
              // .style('filter', 'url(#glow)')
              .attr('stroke', '#6C60F3');
          
          function setFade(selection, opacity) {
            selection.style('opacity', opacity);
          }
          const xAxis = d3.axisBottom(xScale);
          svgD2C.append('g')
          .attr('id', 'tickHorizontal')
          .attr('transform', `translate(0, ${height})`)
          .call(xAxis);
          
          svgD2C.select('.domain')
            .attr('stroke', '#ddd')


            const tickVertical = svgD2C.append('g')
            .call(d3.axisLeft(yScale).ticks(5))
            .selectAll('.tick')
            .style('transition', '.2s');
        
            tickVertical
            .selectAll('line')
              .attr('stroke-dasharray', `5, 5`)
              .attr('stroke', '#ccc')
              .attr('x2', `${width}px`)
            
              tickVertical
            .append('rect')
              .attr('width', `${(width) + 10}px`)
              .attr('x', `-${0}px`)
              .attr('y', `-${height}px`)
              .attr('height', `${height / 5}px`)
              .style('cursor', 'pointer')
              .style('fill', 'transparent');

              svgD2C.selectAll("#tickHorizontal text")
            .attr("transform", 'rotate(-90) translate(-35,-13)') 
      }, [])
      
    return (
        <div ref={graphRefD2C}></div>
    )
}

export default React.memo(D2CGraph)