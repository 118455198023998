import React, { useState, useEffect} from "react";
import classes from "./NurseAddLog.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";
import { Avatar } from "@mui/material";
import InputDashboard from "../../components/common/InputDashboard/InputDashboard";
import InputDropdown from "../../components/common/InputDashboard/InputDropdown";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import studentReducer from "../../redux/reducers/student.reducer";
import { Alert,  Snackbar } from "@mui/material";
import userReducer from "../../redux/reducers/user.reducer";
import moment from "moment";

export const NurseAddLog = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const studentHeaderData = location?.state?.data[0];
  
  const [open, setOpen] = useState(false);
  const [situation, setSituation] = useState("");
  const [temperature, setTemperature] = useState("");
  const [heartRate, setHeartRate] = useState("");
  const [bloodPressure, setBloodPressure] = useState("");
  const [spO2, setSpO2] = useState("");
  const [problem, setProblem] = useState("");
  const [diagnosis, setDiagnosis] = useState("");
  const [emailRadioValue, setEmailRadioValue] = useState("none");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [showBtn, setShowBtn] = useState(false)

  const currentUser = useSelector((state) => state.userReducer.currentUser)
  const loadingLog = useSelector((state) => state.studentReducer.loading)
  const errorLog = useSelector((state) => state.studentReducer.error)
  

  const handleRadioEmailChange = (event) => {
    setEmailRadioValue(event.target.value);
  };


  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  const submitLog = studentReducer.actions.addStudentHealthLogRequest;
  // 

  const student_description_boxes = [
    {
      label: "Age",
      value: getAge(studentHeaderData.DOB) + " yrs",
    },
    {
      label: "Gender",
      value: studentHeaderData.gender,
    },
    {
      label: "Class",
      value: `${studentHeaderData.class} ${studentHeaderData.section}`,
    },
    {
      label: "Class Teacher",
      value: "-",
    },
    {
      label: "Parents Details",
      value: `${studentHeaderData?.fatherName ? studentHeaderData?.fatherName : "-"}`,
    },
    {
      label: "Parents Phone",
      value: `${studentHeaderData?.fatherContact?.mobile ? studentHeaderData?.fatherContact?.mobile : "-"}`,
    },
  ];
  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)} `.slice(-2);
    }
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 12,
        height: 12,
        padding: 2,
      },
      children: `${name[0]} `,
    };
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    const params = {
      bp: bloodPressure,
      diagnosis: diagnosis,
      heartRate: heartRate,
      problemFaced: problem,
      situation: situation,
      SpO2: spO2,
      temp: temperature,
      studentId: studentHeaderData.studentId,
      schoolId: studentHeaderData.schoolId,
      height : height,
      weight : weight,
      email: emailRadioValue.split("-")[0],
      infirmary : currentUser.userId,
      infirmaryName : currentUser.given_name+" "+currentUser.family_name
    };
    
    try{
      await dispatch(submitLog(params));
    }catch(error){

    }
    finally{
      handleClick()
      if(!loadingLog && !errorLog){
        setTimeout(() => {
          navigate('/infirmary/nurse/student')
        }, 3700)
      }
    }
    
  };

  return (
    <>
      <div className={classes.nurse_student_layout}>
        <div>
          <BreadCrumbs />
        </div>
        <div className={classes.log_screening_container}>
          {/* <button className={classes.log_screening_btn}>
            <VisibilityIcon
              sx={{
                fill: "#1740A1",
                backgroundColor: "#FFF",
                borderRadius: "50%",
                width: "24px",
                height: "24px",
                padding: "3px",
              }}
            />
            <span>Add log</span>
          </button> */}
          {/* <CheckCircleIcon sx={{ fill: "#66707E" }} /> */}
          <button onClick = {() => navigate('/infirmary/nurse/student/screening', { state : { ...studentHeaderData}})} className={classes.log_screening_btn}>
            <VisibilityIcon
              sx={{
                fill: "#1740A1",
                backgroundColor: "#FFF",
                borderRadius: "50%",
                width: "24px",
                height: "24px",
                padding: "3px",
              }}
            />
            <span>Screening</span>
          </button>
        </div>
      </div>

      <div className={classes.student_info_container}>
        <div className={classes.avatar_container}>
          <div>
            <Avatar
              {...stringAvatar(
                `${studentHeaderData.firstName} ${studentHeaderData.lastName}`
              )}
              sx={{
                height: "64px",
                width: "64px",
                fontSize: "40px",
                fontWeight: "600",
                fontFamily: "Inter",
              }}
            />
          </div>
          <div>
            <p>{`${studentHeaderData.firstName} ${studentHeaderData.lastName}`}</p>
          </div>
        </div>

        <div className={classes.student_description_container}>
          {student_description_boxes.map((el) => {
            return (
              <div className={classes.description_boxes}>
                <p>{el.label}</p>
                <p>{el.value}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className={classes.addlog_container}>
        <div className={classes.addlog_top_container}>
          <div className={classes.addlog_top_left}>
            <div className={classes.addlog_header}>Add Log</div>
            <div className={classes.addlog_header_subtext}>
              Please enter available data for children health log
            </div>
            <div className={classes.input_dropdown}>
              <InputDropdown
                placeholder={"Situation"}
                width={"13.056vw"}
                // bgColor="#f8f8f8"
                value={situation}
                defaultValues={["Emergency", "Regular Visit", "First Aid"]}
                setValue={setSituation}
                noneMenu = {true}
              />
            </div>
          </div>

          <div className={classes.addlog_top_right}>
            <div className={classes.checkbox_container_top}>
              <div className={classes.checkbox_container_top_header}>
                Mark email
              </div>
              <Box className={classes.checkbox_inner}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="none"
                    name="radio-buttons-group"
                    style={{ display: "flex", flexDirection: "row" }}
                    value={emailRadioValue}
                    onChange={handleRadioEmailChange}
                  >
                    <FormControlLabel
                      value="none"
                      control={
                        <Radio
                          icon={
                            <RadioButtonUncheckedOutlinedIcon
                              sx={{ fill: "#66707E" }}
                            />
                          }
                          checkedIcon={
                            <CheckCircleIcon sx={{ fill: "#66707E" }} />
                          }
                        />
                      }
                      label="None"
                    />
                    <FormControlLabel
                      value="Principal-email"
                      control={
                        <Radio
                          icon={
                            <RadioButtonUncheckedOutlinedIcon
                              sx={{ fill: "#66707E" }}
                            />
                          }
                          checkedIcon={
                            <CheckCircleIcon sx={{ fill: "#66707E" }} />
                          }
                        />
                      }
                      label="Principal"
                    />
                    <FormControlLabel
                      value="Parents-email"
                      control={
                        <Radio
                          icon={
                            <RadioButtonUncheckedOutlinedIcon
                              sx={{ fill: "#66707E" }}
                            />
                          }
                          checkedIcon={
                            <CheckCircleIcon sx={{ fill: "#66707E" }} />
                          }
                        />
                      }
                      label="Parents"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </div>

            <div className={classes.checkbox_container_bottom}>
              <div className={classes.checkbox_container_top_header}>
                Mark Whatsapp
              </div>
              <Box className={classes.checkbox_inner}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="none"
                    name="radio-buttons-group"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="none"
                      control={
                        <Radio
                          icon={
                            <RadioButtonUncheckedOutlinedIcon
                              sx={{ fill: "#66707E" }}
                            />
                          }
                          checkedIcon={
                            <CheckCircleIcon sx={{ fill: "#66707E" }} />
                          }
                        />
                      }
                      label="None"
                    />
                    <FormControlLabel
                      value="Principal-wh"
                      control={
                        <Radio
                          icon={
                            <RadioButtonUncheckedOutlinedIcon
                              sx={{ fill: "#66707E" }}
                            />
                          }
                          checkedIcon={
                            <CheckCircleIcon sx={{ fill: "#66707E" }} />
                          }
                        />
                      }
                      label="Principal"
                    />
                    <FormControlLabel
                      value="Parents-wh"
                      control={
                        <Radio
                          icon={
                            <RadioButtonUncheckedOutlinedIcon
                              sx={{ fill: "#66707E" }}
                            />
                          }
                          checkedIcon={
                            <CheckCircleIcon sx={{ fill: "#66707E" }} />
                          }
                        />
                      }
                      label="Parents"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </div>
          </div>
        </div>
        <div className={classes.vital_signs_container}>
          <div className={classes.vital_signs_header}>Vital Signs</div>
          <div className={classes.vital_signs_input_top_container}>
            <InputDashboard
              placeholder={"Enter Tempereture (C)"}
              width={"13.056vw"}
              change={(e) => {
                setTemperature(e.target.value);
              }}
              bgColor="#ffffff"
              value={temperature}
            />
            <InputDashboard
              placeholder={"Enter Heart Rate"}
              width={"13.056vw"}
              change={(e) => {
                setHeartRate(e.target.value);
              }}
              bgColor="#ffffff"
              value={heartRate}
            />
            <InputDashboard
              placeholder={"Enter Blood Pressure"}
              width={"13.056vw"}
              change={(e) => {
                setBloodPressure(e.target.value);
              }}
              bgColor="#ffffff"
              value={bloodPressure}
            />
            <InputDashboard
              placeholder={"Enter SpO2"}
              width={"13.056vw"}
              change={(e) => {
                setSpO2(e.target.value);
              }}
              bgColor="#ffffff"
              value={spO2}
            />
            {showBtn ? 
            (
              <>
              <InputDashboard
              placeholder={"Height (cms)"}
              width={"7.4vw"}
              change={(e) => {
                setHeight(e.target.value);
              }}
              bgColor="#ffffff"
              value={height}
            />
            <InputDashboard
              placeholder={"Weight(kgs)"}
              width={"7.4vw"}
              change={(e) => {
                setWeight(e.target.value);
              }}
              bgColor="#ffffff"
              value={weight}
            />
            </>
            ) : 
            (
              <div className={classes.add_vital_container}>
              <AddCircleRoundedIcon
                sx={{ height: "16px", weight: "16px", fill: "#66707E" }}
              />
              <p onClick={() => setShowBtn(!showBtn)} style={{ cursor : "pointer"}}>Add another vital</p>
            </div>
            )}
          </div>
          <div className={classes.vital_signs_input_mid_container}>
            <InputDashboard
              placeholder={"Problem faced by kid"}
              width={"100%"}
              change={(e) => {
                setProblem(e.target.value);
              }}
              bgColor="#ffffff"
              value={problem}
            />
          </div>
          <div className={classes.vital_signs_input_mid_container}>
            <InputDashboard
              placeholder={"Diagnosis Suggested"}
              width={"100%"}
              change={(e) => {
                setDiagnosis(e.target.value);
              }}
              bgColor="#ffffff"
              value={diagnosis}
            />
          </div>
        </div>
        { loadingLog &&
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={
                !errorLog
                  ? "success"
                  : "error"
              }
              sx={{ width: "100%" }}
            >
              {!errorLog ? "Successfully added Log" : "Something Wrong! Try Again."}
            </Alert>
          </Snackbar>
        }

        <div className={classes.decision_btn_container}>
          <button onClick={() => handleSubmit()}>Add Log</button>
          <button>Cancel</button>
        </div>
      </div>
    </>
  );
};
