import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./BehavioralScreening.module.css";
import { Avatar, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import BehavioralScreeningRow from "./BehavioralRow";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";
import SearchBar from "../common/TableComponents/SearchBar";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import screeningsyellow from "../../assets/images/screeningsyellow.svg";
import { Filter } from '../common/TableComponents/Filter'
import { Sort } from "../common/TableComponents/Sort";



export function BehavioralScreeningPage() {

  const navigate = useNavigate()
  const location = useLocation();

  const data = [
    {
      name : "abcd",
      isActive : false
    }]

  function getWordBeforeDash() {
    let newStr = "";
    for (let i = 1; i < location.pathname.length; i++) {
      if (location.pathname[i] === "-") {
        return newStr;
      }
      newStr += location.pathname[i];
    }
  }

  const [open, setOpen] = useState(false);
  const [sec, setSec] = useState(false);
  const [text, setText] = useState("");
  const [displayData, setDisplayData] = useState(data ?? []);
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageNo, setPageNo] = useState(1)
  const [selectedClass, setSelectedClass] = useState("All")
  const [selectedSection, setSelectedSection] = useState("All")
  const [openBulkUpload, setOpenBulkUpload] = useState(false)


  // useEffect(() => {
  //   if (text && selectedClass !== "All" && selectedSection !== "All") {
  //     setDisplayData(
  //       data?.filter((x) => `${x?.firstName} ${x?.lastName}`.toLowerCase().includes(text.toLowerCase()))
  //         .filter((y) => y?.class === selectedClass)
  //         .filter((z) => z?.section === selectedSection)
  //     )
  //   }
  //   else if (text && selectedClass !== "All") {
  //     setDisplayData(
  //       data?.filter((x) => `${x?.firstName} ${x?.lastName}`.toLowerCase().includes(text.toLowerCase()))
  //         .filter((y) => y?.class === selectedClass)
  //     )
  //   }
  //   else if (text && selectedSection !== "All") {
  //     setDisplayData(
  //       data?.filter((x) => `${x?.firstName} ${x?.lastName}`.toLowerCase().includes(text.toLowerCase()))
  //         .filter((z) => z?.section === selectedSection)
  //     )
  //   }
  //   else if (selectedClass !== "All" && selectedSection !== "All") {
  //     setDisplayData(data?.filter((y) => y?.class === selectedClass)
  //       .filter((z) => z?.section === selectedSection))
  //   }
  //   else if (text) {
  //     setDisplayData(data?.filter((x) => `${x?.firstName} ${x?.lastName}`.toLowerCase().includes(text.toLowerCase())))
  //   }
  //   else if (selectedClass !== "All") {
  //     setDisplayData(
  //       data?.filter((y) => y?.class === selectedClass)
  //     )
  //   }
  //   else if (selectedSection !== "All") {
  //     setDisplayData(data?.filter((z) => z?.section === selectedSection))
  //   }
  //   else {
  //     setDisplayData(data)
  //   }

  // }, [text, selectedClass, selectedSection])

  // useEffect(() => {
  //   setDisplayData(data)
  // }, [data])


  const dropdown_obj = [
    { label: "1st A" },
    { label: "1st B" },
    { label: "1st C" },
  ];
  const sectionDrop_obj = [
    { label: " A Section" },
    { label: "B Section" },
    { label: " C Section" },
  ];
  const numberDown_obj = [{ label: "20" }, { label: "30" }, { label: "40" }];

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.student_layout}>
        Behavioral Screening & Validation
      </div>
      <div className = {classes.cards_container}>
        <div>
          <p>BEHAVIORAL SCREENING</p>
          <div>
            <img src = {screeningsyellow} />
            <p><span>7,275</span> till date</p>
          </div>
        </div>
        <div>
          <p>BEHAVIORAL VALIDATION</p>
          <div>
            <img src = {screeningsyellow} />
            <p><span>5,275</span>/7,275</p>
          </div>
        </div>
      </div>
      <div className={classes.student_filters}>
        <div className= {classes.show_text}>Show</div>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Rows</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={rowsPerPage}
            label="Rows"
            onChange={(e) => setRowsPerPage(e.target.value)}
            size='small'
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: 164, backgroundColor : "#fff" }}>
          <InputLabel id="demo-simple-select-label">City</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedSection}
            label="Rows"
            onChange={(e) => setSelectedSection(e.target.value)}
            size='small'
          >
            {["All", "A", "B", "C"].map((x) => <MenuItem key={x} value={x}>{x}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 248 , backgroundColor : "#fff"}}>
          <InputLabel id="demo-simple-select-label">School</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedSection}
            label="Rows"
            onChange={(e) => setSelectedSection(e.target.value)}
            size='small'
          >
            {["All", "A", "B", "C"].map((x) => <MenuItem key={x} value={x}>{x}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 90, backgroundColor : "#fff" }}>
          <InputLabel id="demo-simple-select-label">Class</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedClass}
            label="Rows"
            onChange={(e) => setSelectedClass(e.target.value)}
            size='small'
          >
            {["All", "1", "2", '3', "4", "5", "6", "7", "8", "9", "10"].map((x) => <MenuItem key={x} value={x}>{x}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 90 , backgroundColor : "#fff"}}>
          <InputLabel id="demo-simple-select-label">Section</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedSection}
            label="Rows"
            onChange={(e) => setSelectedSection(e.target.value)}
            size='small'
          >
            {["All", "A", "B", "C"].map((x) => <MenuItem key={x} value={x}>{x}</MenuItem>)}
          </Select>
        </FormControl>
        <Filter />
        <Sort />
      </div>
      {/* Student Table Header */}
      <div className={classes.student_table_header}>
        <div>NAME</div>
        <div>AGE</div>
        <div>GENDER</div>
        <div>BEHAVIORAL FORM STATUS</div>
        <div>STATUS</div>
        <div>VALIDATED BY</div>
        <div>ACTION</div>
      </div>
      {/* Student Column-Rows Section */}
      <div className={classes.student_column_container}>
        {displayData?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo)?.map((el, index) => {
          return (
            <BehavioralScreeningRow data={el} key={index + Date.now()} />
          )
        })}
      </div>

      <div className={classes.pagination_container}>
        <div className={classes.page_item}>{`Showing ${(rowsPerPage*(pageNo-1))+1} to ${pageNo*rowsPerPage<=displayData?.length ? pageNo*rowsPerPage : displayData?.length} of ${displayData?.length} items`}</div>
        <div className={classes.pagination}>
          <Button size="small" disabled={pageNo === 1} onClick={() => setPageNo(pageNo - 1)} >
            <ChevronLeft />
          </Button>
          <span>{pageNo}</span>
          <div>
            <span>of</span>
            <span>{Math.ceil(displayData?.length/rowsPerPage)}</span>
          </div>

          <div>
            <Button size="small" disabled={pageNo===Math.ceil(displayData?.length/rowsPerPage)} onClick={()=>setPageNo(pageNo+1)} >
              <ChevronRight/>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function BehavioralManageScreening() {

  return (
      <BehavioralScreeningPage/>
  );
}


export default BehavioralManageScreening
