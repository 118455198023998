import React, { useEffect, useState } from "react";
import classes from "./Calendar.module.css";
import DashboardLayout from "../common/DashboardLayout/DashboardLayout";
import schoolLogo from "../../assets/images/schoollogo.png";
import TabButton from "../common/TableComponents/TabButton";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { key } from "localforage";
import eventReducer from "../../redux/reducers/event.reducer";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import { Alert, Avatar, List, ListItem, selectClasses, Snackbar, Typography } from "@mui/material";
import MultipleSelectChip from "../common/MultiSelectDropdown/MultiSelectDropdown";
import { stringAvatar } from "../../utils/utility";
import notification from "../../assets/audios/notificationSound.mp3";
import { useNavigate } from "react-router-dom";
import events from "../../graphqlApiServices/events";
import InputDropdown from "../common/InputDashboard/InputDropdown";

function CalendarPage({ data, schoolData }) {
  const [active, setActive] = useState(0);
  const [stations, setStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState([])
  const dispatch = useDispatch();
  const notify = new Audio(notification)
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate()
  const createEventMap = eventReducer.actions.createEventMapRequest;
  const classData = JSON.parse(schoolData?.classMap);
  const [allClasses, setAllClasses] = useState([]);
  const getEventMaps = eventReducer.actions.getEventMapsRequest
  const { getStation } = events
  const eventMapData = useSelector((state) => state.eventReducer.eventMapData);

  useEffect(() => {
    getStation(getStationCallback);
  }, [])


  console.log(stations);

  function getStationCallback(type, res) {
    if (type == "success") {
      setStations(res);
    } else if (type == "error") {
      console.log(res);
    }
  }

  const handleSnackbar = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const error = useSelector((state) => state.eventReducer.error);
  const loading = useSelector((state) => state.eventReducer.loading);

  const dates = [];
  if (schoolData?.screeningFrom && schoolData?.screeningTo) {
    const options = {
      weekDay: "none",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const from = new Date(schoolData?.screeningFrom);
    const to = new Date(schoolData?.screeningTo);
    const date = new Date(from.getTime());

    while (date <= to) {
      dates.push(new Date(date).toLocaleDateString("en-US", options));
      date.setDate(date.getDate() + 1);
    }
  }

  const [selectedDate, setSelectedDate] = useState(
    moment(dates[0]).format("YYYY-MM-DD")
  );
  const [selectedClass, setSelectedClass] = useState([]);

  useEffect(() => {
    if (classData) {
      const classList = [];
      const keys = Object.keys(classData);

      keys?.map((x) => {
        if (classData[x]) {
          classList.push(x.split("_").join("-"));
        }
      });
      classList.sort((a, b) => a.localeCompare(b, 'en', { numeric: true }));
      setAllClasses(classList);
    }
  }, []);

  const handleClick = (e, i) => {
    setActive(i);
    setSelectedDate(moment(e.target.innerHTML).format("YYYY-MM-DD"));
  };
  // console.log(allClasses.sort());
  const handleSubmit = () => {
    if (selectedClass && selectedDate) {
      console.log(selectedClass);
      let classArray = [selectedClass.split('-')?.[0]]
      let sectionArray = [selectedClass.split('-')?.[1]]

      if(schoolData.isClinic){
        classArray = [`${selectedClass.split('-')?.[0]}-${selectedClass.split('-')?.[1]}`]
        sectionArray= [`${selectedClass.split('-')?.[2]}`]
      }
      console.log(selectedStation);

      const params = {
        classes: classArray,
        eventDate: selectedDate,
        eventId: data[0]?.eventId,
        schoolId: schoolData?.schoolId,
        sections: sectionArray,
        package: schoolData?.package,
        stationId: stations.filter((st) => st.location === selectedStation)?.[0]?.stationId
      };
      console.log(params);
      dispatch(createEventMap(params));
      notify.play();
      handleSnackbar()
    }
  };


  return (
    <div className={classes.main_div}>
      <div className={classes.header}>
        <div>
          <BreadCrumbs role="Calendar" nav="Create Event" />
        </div>
        <div>{/* <TabButton role = "Create Event"/> */}</div>
      </div>
      <div className={classes.container}>
        <div className={classes.student_details_container}>
          <div className={classes.student_details}>
            <div>
              <div className={classes.no_upload_logo}>
                {
                  schoolData?.url
                    ?
                    <img src={schoolData?.url} alt="" />
                    :
                    <Avatar style={{ width: '100%', height: '100%', fontSize: '150%' }} {...stringAvatar(schoolData?.schoolName)} />
                }
              </div>
            </div>
            <div className={classes.student_details_information}>
              <div className={classes.student_details_information_name}>
                {schoolData?.schoolName}
              </div>
              <div className={classes.student_details_information_general_info}>
                <p>{`${schoolData?.address}, ${schoolData?.city}, ${schoolData?.state}, ${schoolData?.pincode}`}</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className={classes.physical_report_header}>
            <div>School Code</div>
            <div>Screening Dates</div>
          </div>
          <div className={classes.physical_report_header_data}>
            <div className={classes.schoolCode}>{schoolData?.schoolCode}</div>
            <div className={classes.schoolEvent}>{`${moment(
              schoolData?.screeningFrom
            ).format("DD/MM/YYYY")} - ${moment(schoolData?.screeningTo).format(
              "DD/MM/YYYY"
            )}`}</div>
          </div>

          <div
            className={`${classes.physical_report_header} ${classes.total_ops_student_container}`}
          >
            <div>Total Students</div>
            <div>Ops Team</div>
          </div>
          <div className={classes.physical_report_header_data}>
            <div className={classes.totalStudents_text}>
              {schoolData?.strength}
            </div>
            <div className={classes.totalStudents_text}>-</div>
          </div>
        </div>
        <div className={classes.device_mapping_text}>
          Class and Device mapping
        </div>

        <div className={classes.organs_container}>
          {dates?.map((date, i) => {
            return (
              <div
                className={`${active === i && classes.organs_container_elements_active
                  }`}
                onClick={(e) => handleClick(e, i)}
                key={i}
              >{`${date}`}</div>
            );
          })}
          {/* <div  className = {`${classes.organs_container_elements_active}`}>10 Jan, 2023</div>
          <div className = {`${classes.organs_container_elements}` }>11 Jan, 2023</div>
          <div  className = {`${classes.organs_container_elements}` }>12 Jan, 2023</div>
          <div className = {`${classes.organs_container_elements}`}>13 Jan, 2023</div>
          <div className = {`${classes.organs_container_elements}`}>14 Jan, 2023</div>
          <div  className = {`${classes.organs_container_elements}`}>15 Jan, 2023</div> */}
        </div>
        <div className={classes.class_mapping_text}>Class Mapping</div>
        {/* <div className={classes.select_class_container}>
          <div>From</div>
          <div>
            <select
              onChange={(e) => {
                setSelectedClass(e.target.value.split("-").reverse().join("_"));
              }}
            >
              <option value={""}>Select a class</option>
              {allClasses.map((x, i) => {
                return (
                  <option key={i} value={x}>
                    {x}
                  </option>
                );
              })}
            </select>
          </div>
          <div>To</div>
          <div>
            <select>
              {allClasses.map((x) => {
                return <option>{x}</option>;
              })}
            </select>
          </div>
          <div>
            <p>Students to be screened on this day</p>
            <p>{classData[selectedClass]?.length}</p>
          </div>
        </div> */}

        <InputDropdown
          placeholder={"Available classes"}
          width={"23vw"}
          margin={2.4}
          value={selectedClass}
          setValue={setSelectedClass}
          bgColor="#ffffff"
          defaultValues={[...allClasses]}
        />

        <br />
        <div className={classes.class_mapping_text}>Select Station</div>
        <InputDropdown
          placeholder={"Select station"}
          width={"23vw"}
          margin={2.4}
          value={selectedStation}
          setValue={setSelectedStation}
          bgColor="#ffffff"
          defaultValues={stations.map((x) => x?.location)}
        />
        <div style={{ marginTop: 30 }}>
          <Typography>Scheduled Event Maps</Typography>
          <div style={{ display: "flex", justifyContent: "space-between", width: "20%", marginTop: 15, marginBottom: 20, fontWeight: 600 }}>
            <div>Date</div>
            <div>Class</div>
          </div>
          <List dense>
            {Array.isArray(eventMapData) && eventMapData?.map((x, i) => {
              return (
                <div style={{ display: "flex", justifyContent: "space-between", width: "20%", marginBottom: 15 }}>
                  <div>{x?.date}</div>
                  <div>
                    {
                      x?.class.split(",").map((c, i) => {
                        return (<>
                          <span>{`${c}-${x.section.split(",")?.[i]}`}</span>
                          {i < x?.class.split(",").length - 1 ? "," : ""}
                          &nbsp;
                        </>)
                      })
                    }
                  </div>
                </div>
              )
            })
            }
          </List>
        </div>
        {!loading &&
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={!error && !loading ? "success" : "error"}
              sx={{ width: "100%" }}
            >
              {!error
                ? "Event Added Successfully"
                : "Error While Adding Event"}
            </Alert>
          </Snackbar>
        }
        <div className={classes.buttons}>
          <button className={classes.button_with_border} onClick={() => navigate(-1)}>Cancel</button>
          <button
            disabled={false}
            className={classes.button_filled}
            onClick={handleSubmit}
          >
            Create Event
          </button>
        </div>
      </div>
    </div>
  );
}

function Calendar({ schoolData }) {
  const data = useSelector((state) => state.eventReducer.data);

  return <CalendarPage data={data} schoolData={schoolData} />;
}

export default Calendar;
