import { createSlice } from "@reduxjs/toolkit";
import InitialStore from "../store/InitialStore";

// SETTING COGNITO USER
export const setCognitoUser = (state, action) => {
    state.currentUser = action.payload;
};

export const resetCognitoUser = (state, action) => {
    state.currentUser = {}
}

export const setUser = (state, action) => {
    state.currentUser = action.payload;
};

export const getAllUsersRequest = (state, action) => {
    state.users = []
    state.loading = true
    state.error = false
    state.errorMessage = ''
}

export const getUserByGroupRequest = (state, action) => {
    state.users = []
    state.loading = true
    state.error = false
    state.errorMessage = ''
}

export const getUserByIdRequest = (state, action) => {
    state.currentUser = {}
    state.currentUserLoading = true
    state.error = false
    state.errorMessage = ''
}
export const getUserByIdSuccess = (state, action) => {
    state.currentUser = action.payload
    state.currentUserLoading = false
}
export const getUserByIdFail = (state, action) => {
    state.currentUserLoading = false
    state.error = true
}

export const getAllUsersSuccess = (state, action) => {
    state.users = action.payload
    state.loading = false
}

export const getAllUsersFail = (state, action) => {
    state.error = true
    state.loading = false
}

export const createUserRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.errorMessage = ''
}

export const createSchoolInfirmaryRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.errorMessage = ''
}

export const createUserSuccess = (state, action) => {
    state.loading = false
}

export const createUserFail = (state, action) => {
    state.error = true
    state.loading = false
    state.errorMessage = "Error while adding user"
}

export const getUserListforSuperadminRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.errorMessage = ''
    state.users = []
}

export const getUserListforSuperadminSuccess = (state, action) => {
    state.users = action.payload
    state.loading = false
}

export const getUserListforSuperadminFail = (state, action) => {
    state.error = true
    state.loading = false
}

export const getDoctorsBySpecialityRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.errorMessage = ''
    state.users = []
}

export const getDoctorsBySpecialitySuccess = (state, action) => {
    state.users = action.payload
    state.loading = false
}

export const getDoctorsBySpecialityFail = (state, action) => {
    state.error = true
    state.loading = false
}


export const getDoctorsRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.errorMessage = ''
    state.users = []
}

export const getDoctorsSuccess = (state, action) => {
    state.users = action.payload
    state.loading = false
}

export const getDoctorsFail = (state, action) => {
    state.error = true
    state.loading = false
}

export const getDoctorsByClinicRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.errorMessage = ''
    state.users = []
}

export const getDoctorsByClinicSuccess = (state, action) => {
    state.users = action.payload
    state.loading = false
}

export const getDoctorsByClinicFail = (state, action) => {
    state.error = true
    state.loading = false
}

export const getNursesByClinicRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.errorMessage = ''
    state.users = []
}

export const getNursesByClinicSuccess = (state, action) => {
    state.users = action.payload
    state.loading = false
}

export const getNursesByClinicFail = (state, action) => {
    state.error = true
    state.loading = false
}

export const updateUserRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.errorMessage = ''
    state.users = []
}

export const updateUserSuccess = (state, action) => {
    state.users = action.payload
    state.loading = false
}

export const updateUserFail = (state, action) => {
    state.error = true
    state.loading = false
}

export const getSchoolInfirmaryRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.errorMessage = ''
    state.users = []
}

export const getSchoolInfirmarySuccess = (state, action) => {
    state.users = action.payload
    state.loading = false
}

export const getSchoolInfirmaryFail = (state, action) => {
    state.error = true
    state.loading = false
}

export const bulkUploadTeacherRequest = (state, action) => {
    state.bulkUploadLoading = true
    state.bulkUploadError = false
    state.errorMessage = ''
    // state.users = []
}

export const bulkUploadTeacherSuccess = (state, action) => {
    state.bulkUploadLoading = false
}

export const bulkUploadTeacherFail = (state, action) => {
    state.bulkUploadError = true
    state.bulkUploadLoading = false
}


export const getUserByEmailReq = (state, action) => {
    state.users = {}
    state.loading = true
    state.error = false
    state.errorMessage = ''
}
export const getUserByEmailSuccess = (state, action) => {
    // state.users = action.payload
    state.loading = false
}
export const getUserByEmailFail = (state, action) => {
    state.loading = false
    state.error = true
    state.errorMessage = "User email already exists"
}

export default createSlice({
    name: "userReducer",
    initialState: InitialStore.user,
    reducers: {
        setUser,
        setCognitoUser,
        resetCognitoUser,
        getAllUsersRequest,
        getAllUsersSuccess,
        getAllUsersFail,
        createUserRequest,
        createSchoolInfirmaryRequest,
        createUserSuccess,
        createUserFail,
        getUserByGroupRequest,
        getUserByIdRequest,
        getUserByIdSuccess,
        getUserByIdFail,
        getUserListforSuperadminRequest,
        getUserListforSuperadminSuccess,
        getUserListforSuperadminFail,
        getDoctorsBySpecialityRequest,
        getDoctorsBySpecialitySuccess,
        getDoctorsBySpecialityFail,
        getDoctorsRequest,
        getDoctorsSuccess,
        getDoctorsFail,
        getDoctorsByClinicRequest,
        getDoctorsByClinicSuccess,
        getDoctorsByClinicFail,
        getNursesByClinicRequest,
        getNursesByClinicSuccess,
        getNursesByClinicFail,
        updateUserRequest,
        updateUserSuccess,
        updateUserFail,
        getSchoolInfirmaryRequest,
        getSchoolInfirmarySuccess,
        getSchoolInfirmaryFail,
        bulkUploadTeacherRequest,
        bulkUploadTeacherSuccess,
        bulkUploadTeacherFail,
        getUserByEmailReq,
        getUserByEmailSuccess,
        getUserByEmailFail
    },
});