import * as React from "react"
const SvgComponent = (props) => (
    <div style={{ ...props.customStyle }}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                stroke="#EF1210"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                clipPath="url(#a)"
            >
                <path d="m14.31 9.251 1.096 2.276s.684-4.558 4.582-4.558c3.898 0 4.216 4.528 4.216 4.528M15.406 11.527l2.44-1.191M22.32 14.695l-.845 2.383s3.724-2.742 6.48 0c2.756 2.742-.24 6.167-.24 6.167M21.473 17.078l2.573.873M17 20.026l.846-2.382s-3.724 2.741-6.48 0c-2.758-2.74.24-6.167.24-6.167M17.846 17.639l-2.573-.873M24.944 24.56l-1.095-2.279s-.684 4.558-4.582 4.558c-3.898 0-4.216-4.528-4.216-4.528M23.851 22.281l-2.439 1.192" />
                <path d="M32.513 27.451s4.417-.463 3.06-3.079c-1.356-2.615-3.06-5.166-3.06-5.166S34.76 2.5 20.042 2.5C5.324 2.5 1.909 15.593 5.467 21.75l3.559 6.158v7.787s-.142 1.804 1.815 1.804h12.708s1.416-.161 1.416-1.408v-1.953s.364-1.133 1.858-1.133h2.339s1.998.141 2.354-1.486c.356-1.628.997-4.067.997-4.067Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M3.334 1.664h33.333v36.667H3.334z" />
                </clipPath>
            </defs>
        </svg>
    </div>

)
export default SvgComponent
