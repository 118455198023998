import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { StudentPage } from '../../../components/common/StudentTable/Student'
import studentReducer from '../../../redux/reducers/student.reducer';
import _ from 'lodash'
import DashboardLayout from '../../common/DashboardLayout/DashboardLayout';

function PrincipalStudent() {

  const location = useLocation();

  const dispatch = useDispatch();

  // 

  const data = useSelector((state) => state.studentReducer.data)
  const loading = useSelector((state) => state.studentReducer.loading)

  const getStudents = studentReducer.actions.getStudentBySchoolIDRequest;

  let schoolId = useSelector((state) => state.userReducer?.currentUser?.schoolId)

  if (!schoolId) {
    schoolId = location.state?.schoolId
  }

  // 

  useEffect(() => {
    if (location.pathname?.includes('principal') || location.pathname?.includes('admin') || location.pathname?.includes('partner')) {
      dispatch(getStudents({schoolId}))
    }
  }, [])

  return (
    //   <div style={{
    //     display: 'flex',
    //     padding: '25px'
    // }}>
    //     <DashboardLayout />     {/*SIDE BAR */}
    <StudentPage data={data} schoolId={location.state?.schoolId} loading={loading} />
    // </div>
  )
}

const mapDispatchToProps = {
  getStudents: studentReducer.actions.getStudentBySchoolIDRequest
};

export default connect(undefined, mapDispatchToProps)(PrincipalStudent)