import React, { useState } from 'react'
import DownArrow from '../../assets/images/down-arrow.svg'
import RightArrow from '../../assets/images/rightArrow.svg'
import './AccordionCard.css'

export default function AccordionCard({accordionData}) {
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <div className="accordion">
        <div className="accordion-item">
          <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
            <div>{accordionData?.title}</div>
            <div>{isActive ? <img src={DownArrow} /> : <img src={RightArrow} />}</div>
          </div>
          {isActive && <div className="accordion-content">
            <div>
              <p>{accordionData?.content}</p>
              <p>Was this answer helpful?</p>
            </div>
            <div>
              <button>Yes</button>
              <button>No</button>
            </div>
          </div>}
        </div>
      </div>
    </>
  )
}