import React from "react";
import "./HealthReport.css";
import vitalsigns from "../../assets/images/vitalsignsnewlogo.svg";
import hairwhite from "../../assets/images/harinewlogo.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/CheckCircle";
import {
  checkReportCondition,
  findInterpetetion,
  findValidation,
  getBmiRange,
} from "../../utils/utility";
import earwhite from "../../assets/images/ear.svg";
import throatwhite from "../../assets/images/throatwhite.svg";
import dentalwhite from "../../assets/images/dentalwhite.svg";
import lungswhite from "../../assets/images/lungswhite.svg";
import abdomenwhite from "../../assets/images/abdomenwhite.svg";
import skinwhite from "../../assets/images/fingerprintcopy.svg";
import heartwhite from "../../assets/images/heartwhite.svg";
import bhvlogo from "../../assets/images/bhvlogo.svg";
import doctorlogo from "../../assets/images/doctorlogo.svg";
import QRCode from "react-qr-code";
import { ReportData } from "./ReportData";
import ImageSlideModal from "../common/StudentName/ImageSlideshow/ImageSlideModal";

// const conditionSection = (props) => {
//   return (

//   )
// }

export const HealthyBadge = ({ text }) => (
  <div className="healthy_box">
    <CheckIcon
      sx={{
        fill: "#008c1e",
        width: "16.62px",
        height: "12.08px",
      }}
    />{" "}
    {text ?? "Healthy"}
  </div>
);

export const VitalsReportSection = (props) => {
  const { data } = props;

  const vitals = data?.vitalSignsAssessment;
  const vitalSign = data?.student?.healthPackage?.vitalSigns;
  const show = Object.keys(vitals).every((x) => vitals[x] === null);
  if (show) {
    return <></>;
  }
  const bmiRange = getBmiRange(data?.student?.age, data?.student?.gender);
  const getBMI = () => {
    const w = data?.vitalSignsAssessment?.weight;
    const h = data?.vitalSignsAssessment?.height / 100;
    if (w && h && w !== "null" && h !== "null") {
      return (w / (h * h)).toFixed(1);
    } else {
      return "";
    }
    console.log(data);
  };

  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={vitalsigns} />
          </div>
          <div>BMI</div>
        </div>

        {vitalSign?.includes("HEIGHT_AND_WEIGHT") &&
          (data?.vitalSignsAssessment?.weight != "null" ||
            data?.vitalSignsAssessment?.height != "null") && (
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>BMI</div>
            //   </div>
            //   <div>{getBMI()} kg/m
            //     <sup className="physical_body_information_container_bottom_sup">
            //       2
            //     </sup></div>
            //   <div>
            //     <div>Normal: 18.5 kg/m<sup>2</sup> - 24.9 kg/m<sup>2</sup></div>
            //   </div>
            //   <div>kg/m<sup>2</sup></div>
            //   <div>{data?.vitalSignsAssessment?.BMIInterpretation?.length > 0 ?
            //     data?.vitalSignsAssessment?.BMIInterpretation : "Normal"}</div>
            // </div>
            <div className="report_data">
              {/* <h4>BMI</h4> */}
              <div>
                {/* <h5>Overview</h5> */}
                <p className="report_text">
                  {ReportData.heightWeightBMI.overview}
                </p>
                <p className="report_text text-bold">
                  {data?.vitalSignsAssessment?.BMIInterpretation?.length > 0
                    ? `The value of BMI is ${getBMI()}`
                    : `The value of BMI is ${getBMI()} which is Normal`}
                </p>
              </div>
              {getBMI() > bmiRange.ub ? (
                <div className="issue_box">
                  <h5>
                    {ReportData.heightWeightBMI.conditions[1].condition}{" "}
                    Detected
                  </h5>
                  <div>
                    <p className="report_text">
                      {ReportData.heightWeightBMI.conditions[1].info}
                    </p>
                  </div>
                </div>
              ) : getBMI() < bmiRange.lb ? (
                <div className="issue_box">
                  <h5>
                    {ReportData.heightWeightBMI.conditions[0].condition}{" "}
                    Detected
                  </h5>
                  <div>
                    <p className="report_text">
                      {ReportData.heightWeightBMI.conditions[0].condition}
                    </p>
                  </div>
                </div>
              ) : (
                <HealthyBadge text={`Normal`} />
              )}
            </div>
          )}

        {/* {vitalSign?.includes("TEMPERATURE") && data?.vitalSignsAssessment?.temperature != "null" && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Temperature</div>
            </div>
            <div>{((data?.vitalSignsAssessment?.temperature * (9 / 5) + 32).toFixed(2))}</div>
            <div>
              <div>Normal: 98.6&deg;F, Fever: &gt;99.5&deg;F</div>
            </div>
            <div>&deg;F</div>
            <div>{data?.vitalSignsAssessment?.temperatureInterpretation?.length > 0 ?
              data?.vitalSignsAssessment?.temperatureInterpretation : "Normal"}</div>
          </div>
        )}

        {vitalSign?.includes("HEART_RATE") && data?.vitalSignsAssessment?.heartRate != "null" && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Heart Rate</div>
            </div>
            <div>{data?.vitalSignsAssessment?.heartRate}</div>
            <div>
              <div>1-3 yrs - 90-150bpm</div>
              <div>3-5yr - 80-140 bpm</div>
              <div>5-12yrs - 70-120bpm</div>
              <div>12+yrs - 60-100bpm</div>
            </div>
            <div>BPM</div>
            <div>{data?.vitalSignsAssessment?.heartRateInterpretation?.length > 0 ?
              data?.vitalSignsAssessment?.heartRateInterpretation : "Normal"}</div>
          </div>
        )}

        {vitalSign?.includes("HEMOGLOBIN") && data?.vitalSignsAssessment?.hemoglobin != "null" && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Hemoglobin</div>
            </div>
            <div>{data?.vitalSignsAssessment?.hemoglobin}</div>
            <div>
              <div>6mnth- 5yr - 12g/dl</div>
              <div>5-12yrs - 13g/dl</div>
              <div>12-14yrs Girl-13g/dl Boy- 13.5 g/dl</div>
              <div>14-19yrs. Girl- 13 g/dl. Boy- 15g/dl</div>
            </div>
            <div>g/dl</div>
            <div>{data?.vitalSignsAssessment?.hemoglobinInterpretation?.length > 0 ?
              data?.vitalSignsAssessment?.hemoglobinInterpretation : "Normal"}</div>
          </div>
        )}

        {vitalSign?.includes("BLOOD_PRESSURE") && data?.vitalSignsAssessment?.bloodPressure != "null" && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Blood Pressure</div>
            </div>
            <div>{data?.vitalSignsAssessment?.bloodPressure}</div>
            <div>
              <div>Age 10+yrs: 109/72</div>
            </div>
            <div>mm/Hg</div>
            <div>{data?.vitalSignsAssessment?.bloodPressureInterpretation?.length > 0 ?
              data?.vitalSignsAssessment?.bloodPressureInterpretation : "Normal"}</div>
          </div>
        )}

        {vitalSign?.includes("SPO2") && data?.vitalSignsAssessment?.SpO2 != "null" && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>SPO2</div>
            </div>
            <div>{data?.vitalSignsAssessment?.SpO2}</div>
            <div>
              <div>Normal SpO2: 95-100%</div>
            </div>
            <div>%</div>
            <div>{data?.vitalSignsAssessment?.SpO2Interpretation?.length > 0 ? data?.vitalSignsAssessment?.SpO2Interpretation : "Normal"}</div>
          </div>
        )} */}
        <div class="screening_method">
          <p>METHOD : Pyroelectric sensor, Pulse oximeters</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
      </div>
    </>
  );
};

export const OtherVitalsReportSection = (props) => {
  const { data } = props;

  const vitals = data?.vitalSignsAssessment;
  const vitalSign = data?.student?.healthPackage?.vitalSigns;
  const show = Object.keys(vitals).every((x) => vitals[x] === null);
  if (show) {
    return <></>;
  }

  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={vitalsigns} />
          </div>
          <div>Vitals Signs</div>
        </div>
        <div class="screening_test_header_container" style={{marginBottom: 20}}>
          <p>Physical Screening</p>
          <p>Results</p>
          <p>Bio. Ref. Interval</p>
          <p>Units</p>
          <p>Interpretation</p>
        </div>
        {vitalSign?.includes("TEMPERATURE") &&
          data?.vitalSignsAssessment?.temperature != "null" && (
            <div class="screening_row">
              <div>
                <div></div>
                <div>Temperature</div>
              </div>
              <div>
                {(
                  data?.vitalSignsAssessment?.temperature * (9 / 5) +
                  32
                ).toFixed(2)}
              </div>
              <div>
                <div>Normal: 98.6&deg;F, Fever: &gt;99.5&deg;F</div>
              </div>
              <div>&deg;F</div>
              <div>
                {data?.vitalSignsAssessment?.temperatureInterpretation?.length >
                0
                  ? data?.vitalSignsAssessment?.temperatureInterpretation
                  : "Normal"}
              </div>
            </div>
          )}

        {vitalSign?.includes("HEART_RATE") &&
          data?.vitalSignsAssessment?.heartRate != "null" && (
            <div class="screening_row">
              <div>
                <div></div>
                <div>Heart Rate</div>
              </div>
              <div>{data?.vitalSignsAssessment?.heartRate}</div>
              <div>
                <div>1-3 yrs - 90-150bpm</div>
                <div>3-5yr - 80-140 bpm</div>
                <div>5-12yrs - 70-120bpm</div>
                <div>12+yrs - 60-100bpm</div>
              </div>
              <div>BPM</div>
              <div>
                {data?.vitalSignsAssessment?.heartRateInterpretation?.length > 0
                  ? data?.vitalSignsAssessment?.heartRateInterpretation
                  : "Normal"}
              </div>
            </div>
          )}

        {vitalSign?.includes("HEMOGLOBIN") &&
          data?.vitalSignsAssessment?.hemoglobin != "null" && (
            <div class="screening_row">
              <div>
                <div></div>
                <div>Hemoglobin</div>
              </div>
              <div>{data?.vitalSignsAssessment?.hemoglobin}</div>
              <div>
                <div>6mnth- 5yr - 12g/dl</div>
                <div>5-12yrs - 13g/dl</div>
                <div>12-14yrs Girl-13g/dl Boy- 13.5 g/dl</div>
                <div>14-19yrs. Girl- 13 g/dl. Boy- 15g/dl</div>
              </div>
              <div>g/dl</div>
              <div>
                {data?.vitalSignsAssessment?.hemoglobinInterpretation?.length >
                0
                  ? data?.vitalSignsAssessment?.hemoglobinInterpretation
                  : "Normal"}
              </div>
            </div>
          )}

        {vitalSign?.includes("BLOOD_PRESSURE") &&
          data?.vitalSignsAssessment?.bloodPressure != "null" && (
            <div class="screening_row">
              <div>
                <div></div>
                <div>Blood Pressure</div>
              </div>
              <div>{data?.vitalSignsAssessment?.bloodPressure}</div>
              <div>
                <div>Age 10+yrs: 109/72</div>
              </div>
              <div>mm/Hg</div>
              <div>
                {data?.vitalSignsAssessment?.bloodPressureInterpretation
                  ?.length > 0
                  ? data?.vitalSignsAssessment?.bloodPressureInterpretation
                  : "Normal"}
              </div>
            </div>
          )}

        {vitalSign?.includes("SPO2") &&
          data?.vitalSignsAssessment?.SpO2 != "null" && (
            <div class="screening_row">
              <div>
                <div></div>
                <div>SPO2</div>
              </div>
              <div>{data?.vitalSignsAssessment?.SpO2}</div>
              <div>
                <div>Normal SpO2: 95-100%</div>
              </div>
              <div>%</div>
              <div>
                {data?.vitalSignsAssessment?.SpO2Interpretation?.length > 0
                  ? data?.vitalSignsAssessment?.SpO2Interpretation
                  : "Normal"}
              </div>
            </div>
          )}
        <div class="screening_method" style={{ marginTop: "30px" }}>
          <p>METHOD : Pyroelectric sensor, Pulse oximeters</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
      </div>
    </>
  );
};

export const HairReportSection = (props) => {
  const { data } = props;
  const hair = data?.student?.healthPackage?.hair;
  const hairAssessment = data?.hairAssessment || {};
  const show = Object.keys(hair).every(
    (x) => hair[x] === null || hair[x] === ""
  );
  if (show) {
    return <></>;
  }
  const conditions = [
    {
      name: "dandruff",
      key: "DANDRUFF",
      condition: ReportData.hair.conditions[0].condition,
      info: ReportData.hair.conditions[0].info,
    },
    {
      name: "pediculosis",
      key: "PEDICULOSIS_(LOUSE/LICE)",
      condition: ReportData.hair.conditions[1].condition,
      info: ReportData.hair.conditions[1].info,
    },
    {
      name: "lossOfHair",
      key: "LOSS_OF_HAIR",
      condition: ReportData.hair.conditions[2].condition,
      info: ReportData.hair.conditions[2].info,
    },
  ];

  const detectedIssues = conditions.filter(
    (item) =>
      hair?.includes(item.key.toUpperCase()) && hairAssessment[item.name]
  );

  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={hairwhite} />
          </div>
          <div>Hair</div>
        </div>
        <div className="report_data">
          <div>
            {/* <h5>Overview</h5> */}
            <p className="report_text">{ReportData.hair.overview}</p>
          </div>
          {detectedIssues.length > 0 ? (
            detectedIssues.map(({ key, condition, info }) => (
              <div className="issue_box" key={key}>
                <h5>{condition} Detected</h5>
                <p className="report_text">{info}</p>
              </div>
            ))
          ) : (
            <HealthyBadge />
          )}
          {/* {hair?.includes("COLOR_OF_HAIR") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Color of Hair</div>
            </div>
            <div>{data?.hairAssessment?.color}</div>
            <div>
              <div>Black, Grey, White, Red</div>
            </div>
            <div>-</div>
            <div>{data?.hairAssessment?.color !== "black" ? "Abnormal" : "Normal"}</div>
          </div>
        )} */}

          {/* {hair?.includes("DANDRUFF") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Dandruff</div>
            //   </div>
            //   <div style={checkReportCondition(data?.hairAssessment?.dandruff) === "Present" ? { fontWeight: 600 } : {}}>{checkReportCondition(data?.hairAssessment?.dandruff)}</div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>{data?.hairAssessment?.dandruffInterpretation?.length > 0 ?
            //     data?.hairAssessment?.dandruffInterpretation : (data?.hairAssessment?.dandruff ? "Abnormal" : "Normal")}</div>
            // </div>

            data?.hairAssessment?.dandruff && (
              <div className="issue_box">
                <h5>{ReportData.hair.conditions[0].condition} Detected</h5>
                <p className="report_text">
                  {ReportData.hair.conditions[0].info}
                </p>
              </div>
            )} */}

          {/* {hair?.includes("PEDICULOSIS_(LOUSE/LICE)") !== null &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Pediculosis</div>
            //   </div>
            //   <div style={checkReportCondition(data?.hairAssessment?.pediculosis) === "Present" ? { fontWeight: 600 } : {}}>{checkReportCondition(data?.hairAssessment?.pediculosis)}</div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>{data?.hairAssessment?.pediculosisInterpretation?.length > 0 ?
            //     data?.hairAssessment?.pediculosisInterpretation : (data?.hairAssessment?.pediculosis ? "Abnormal" : "Normal")}</div>
            // </div>
            data?.hairAssessment?.pediculosis && (
              <div className="issue_box">
                <h5>{ReportData.hair.conditions[1].condition} Detected</h5>
                <p className="report_text">
                  {ReportData.hair.conditions[1].info}
                </p>
              </div>
            )} */}

          {/* {hair?.includes("LOSS_OF_HAIR") !== null &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Loss of Hair</div>
            //   </div>
            //   <div style={checkReportCondition(data?.hairAssessment?.lossOfHair) === "Present" ? { fontWeight: 600 } : {}}>{checkReportCondition(data?.hairAssessment?.lossOfHair)}</div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>{data?.hairAssessment?.lossOfHairInterpretation?.length > 0 ?
            //     data?.hairAssessment?.lossOfHairInterpretation : (data?.hairAssessment?.lossOfHair ? "Abnormal" : "Normal")}</div>
            // </div>
            data?.hairAssessment?.lossOfHair && (
              <div className="issue_box">
                <h5>{ReportData.hair.conditions[2].condition} Detected</h5>
                <p className="report_text">
                  {ReportData.hair.conditions[2].info}
                </p>
              </div>
            )} */}
        </div>
        <div class="screening_method">
          <p>METHOD : Visual Screening</p>
        </div>
      </div>
    </>
  );
};

export const EyeReportSection = (props) => {
  const { data } = props;
  const eye = data?.student?.healthPackage?.eye;
  const show = Object.keys(eye).every((x) => eye[x] === null);
  if (show) {
    return <></>;
  }
  // Function to check if any condition is present
  const isConditionPresent = (condition) =>
    findValidation(data?.eyeAssessment?.validation, condition) === "Present";

  const conditions = [
    {
      name: "Myopia",
      condition: ReportData.eye.conditions[0].condition,
      info: ReportData.eye.conditions[0].info,
    },
    {
      name: "Hyperopia",
      condition: ReportData.eye.conditions[1].condition,
      info: ReportData.eye.conditions[1].info,
    },
    {
      name: "Astigmatism",
      condition: ReportData.eye.conditions[2].condition,
      info: ReportData.eye.conditions[2].info,
    },
    {
      name: "Strabismus",
      condition: ReportData.eye.conditions[3].condition,
      info: ReportData.eye.conditions[3].info,
    },
    {
      name: "Anisocoria",
      condition: ReportData.eye.conditions[4].condition,
      info: ReportData.eye.conditions[4].info,
    },
    {
      name: "Anisometropia",
      condition: ReportData.eye.conditions[5].condition,
      info: ReportData.eye.conditions[5].info,
    },
  ];

  const detectedConditions = conditions.filter(
    ({ name }) => eye?.includes(name.toUpperCase()) && isConditionPresent(name)
  );

  return (
    <>
      <div class="screening_container" style={{ marginBottom: "3rem" }}>
        <div class="screening_container_header">
          <div>
            <VisibilityIcon
              sx={{ fill: "#fff", width: "16.62px", height: "12.08px" }}
            />
          </div>
          <div>Eye</div>
        </div>
        <div className="report_data">
          <div>
            {/* <h5>Overview</h5> */}
            <p className="report_text">{ReportData.eye.overview}</p>
          </div>
          <div className="slides_player_container">
            <div className="slides_player_inner_container">
              <ImageSlideModal
                imgArray={[data?.welchAllyn?.visionScreeningImage]}
              />
              <ImageSlideModal
                imgArray={[data?.welchAllyn?.potentialConditionImage]}
              />
            </div>
            <div className="slides_player_inner_container">
              {data?.welchAllyn?.otherVisionDetails?.map((x, index) => (
                <ImageSlideModal imgArray={[x]} key={index} />
              ))}
            </div>
          </div>
          {detectedConditions.length > 0 ? (
            detectedConditions.map(({ key, condition, info }) => (
              <div className="issue_box" key={key}>
                <h5>{condition} Detected</h5>
                <p className="report_text">{info}</p>
              </div>
            ))
          ) : (
            <HealthyBadge />
          )}

          {/* // {eye?.includes("MYOPIA") && */}
          {/* // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Myopia</div>
            //   </div>
            //   <div style={findValidation(data?.eyeAssessment?.validation, "Myopia") === "Present" ? { fontWeight: 600 } : {}}>{findValidation(data?.eyeAssessment?.validation, "Myopia")}</div>
            //   <div>
            //     <div>Mild: 0D to -1.5D</div>
            //     <div>Moderate: -1.5D to -6.0D</div>
            //     <div>High: &gt;-6.0D</div>
            //     <div>Pathological: &gt;-8.0D</div>
            //   </div>
            //   <div>-</div>
            //   <div>{findInterpetetion(data?.eyeAssessment?.validation, "Myopia")}</div>
            // </div>
            // findValidation(data?.eyeAssessment?.validation, "Myopia") ===
            //   "Present" && (
            //   <div className="issue_box">
            //     <h5>{ReportData.eye.conditions[0].condition} Detected</h5>
            //     <p className="report_text">
            //       {ReportData.eye.conditions[0].info}
            //     </p>
            //   </div>
            // )} */}

          {/* // {eye?.includes("HYPEROPIA") && */}
          {/* // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Hypermyopia</div>
            //   </div>
            //   <div style={findValidation(data?.eyeAssessment?.validation, "Hyperopia") === "Present" ? { fontWeight: 600 } : {}}>{findValidation(data?.eyeAssessment?.validation, "Hyperopia")}</div>
            //   <div>
            //     <div>Low: +2.00D</div>
            //     <div>Moderate: +2.25D to +5.0D</div>
            //     <div>High: +5.25D or more</div>
            //   </div>
            //   <div>-</div>
            //   <div>{findInterpetetion(data?.eyeAssessment?.validation, "Hyperopia")}</div>
            // </div>
            // findValidation(data?.eyeAssessment?.validation, "Hyperopia") ===
            //   "Present" && (
            //   <div className="issue_box">
            //     <h5>{ReportData.eye.conditions[1].condition} Detected</h5>
            //     <p className="report_text">
            //       {ReportData.eye.conditions[1].info}
            //     </p>
            //   </div>
            // )} */}

          {/* // {eye?.includes("ASTIGMATISM") && */}
          {/* // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Astigmatism</div>
            //   </div>
            //   <div style={findValidation(data?.eyeAssessment?.validation, "Astigmatism") === "Present" ? { fontWeight: 600 } : {}}>{findValidation(data?.eyeAssessment?.validation, "Astigmatism")}</div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>{findInterpetetion(data?.eyeAssessment?.validation, "Astigmatism")}</div>
            // </div>
            // findValidation(data?.eyeAssessment?.validation, "Astigmatism") ===
            //   "Present" && (
            //   <div className="issue_box">
            //     <h5>{ReportData.eye.conditions[2].condition} Detected</h5>
            //     <p className="report_text">
            //       {ReportData.eye.conditions[2].info}
            //     </p>
            //   </div>
            // )} */}

          {/* // {eye?.includes("GAZE_ASYMMETRY") && */}
          {/* // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Gaze Asymmetry</div>
            //   </div>
            //   <div style={findValidation(data?.eyeAssessment?.validation, "Strabismus") === "Present" ? { fontWeight: 600 } : {}}>{findValidation(data?.eyeAssessment?.validation, "Strabismus")}</div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>{findInterpetetion(data?.eyeAssessment?.validation, "Strabismus")}</div>
            // </div>
            // findValidation(data?.eyeAssessment?.validation, "Strabismus") ===
            //   "Present" && (
            //   <div className="issue_box">
            //     <h5>{ReportData.eye.conditions[3].condition} Detected</h5>
            //     <p className="report_text">
            //       {ReportData.eye.conditions[3].info}
            //     </p>
            //   </div>
            // )} */}
          {/* // {eye?.includes("ANISOCORIA") && */}
          {/* // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Anisocoria</div>
            //   </div>
            //   <div style={findValidation(data?.eyeAssessment?.validation, "Aniscoria") === "Present" ? { fontWeight: 600 } : {}}>{findValidation(data?.eyeAssessment?.validation, "Aniscoria")}</div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>{findInterpetetion(data?.eyeAssessment?.validation, "Aniscoria")}</div>
            // </div>
            // findValidation(data?.eyeAssessment?.validation, "Aniscoria") ===
            //   "Present" && (
            //   <div className="issue_box">
            //     <h5>{ReportData.eye.conditions[4].condition} Detected</h5>
            //     <p className="report_text">
            //       {ReportData.eye.conditions[4].info}
            //     </p>
            //   </div>
            // )} */}
          {/* // {eye?.includes("ANISOMETROPIA") && */}
          {/* // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Anisometropia</div>
            //   </div>
            //   <div style={findValidation(data?.eyeAssessment?.validation, "Anisometropia") === "Present" ? { fontWeight: 600 } : {}}>
            //     {findValidation(data?.eyeAssessment?.validation, "Anisometropia")}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>{findInterpetetion(data?.eyeAssessment?.validation, "Anisometropia")}</div>
            // </div>
            // findValidation(data?.eyeAssessment?.validation, "Anisometropia") ===
            //   "Present" && (
            //   <div className="issue_box">
            //     <h5>{ReportData.eye.conditions[5].condition} Detected</h5>
            //     <p className="report_text">
            //       {ReportData.eye.conditions[5].info}
            //     </p>
            //   </div>
            // )} */}
        </div>
        <div class="screening_method">
          <p>
            METHOD : Imaging & Video Technology{" "}
            <span
              style={{
                marginLeft: "45px",
                color: "#66707E",
                fontWeight: "500",
                fontStyle: "italic",
                fontSize: "6px",
              }}
            >
              check termanology for method from hillrom website
            </span>
          </p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
            {data?.qrCode && (
              <QRCode value={data?.qrCode} size={30}/>
            )}
          </div>
          <div>
            <div>Scan here</div>
            <div>to look at your kids eye screening images</div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const LeftEarReportSection = (props) => {
  const { data } = props;
  const ear = data?.student?.healthPackage?.ear;
  const leftEarValidation = data?.earAssessment?.leftEarValidation;
  const rightEarValidation = data?.earAssessment?.rightEarValidation;
  // const show = Object.keys(ear).every((x) => ear[x] === null);
  let show = false;
  if (show) {
    return <></>;
  }
  const conditions = [
    {
      name: "Otisis Media",
      key: "OTITIS_MEDIA",
      condition: ReportData.ear.conditions[0].condition,
      info: ReportData.ear.conditions[0].info,
    },
    {
      name: "Otisis Externa",
      key: "OTITIS_EXTERNA",
      condition: ReportData.ear.conditions[1].condition,
      info: ReportData.ear.conditions[1].info,
    },
    {
      name: "Ear Wax",
      key: "EAR_WAX",
      condition: ReportData.ear.conditions[2].condition,
      info: ReportData.ear.conditions[2].info,
    },
    {
      name: "Foreign Body in Ear canal",
      key: "FOREIGN_BODY_IN_CANAL",
      condition: ReportData.ear.conditions[3].condition,
      info: ReportData.ear.conditions[3].info,
    },
  ];

  const leftEarIssues = conditions.filter(
    (item) =>
      ear?.includes(item.key.toUpperCase()) &&
      findValidation(leftEarValidation, item.name) === "Present"
  );
  const rightEarIssues = conditions.filter(
    (item) =>
      ear?.includes(item.key.toUpperCase()) &&
      findValidation(rightEarValidation, item.name) === "Present"
  );
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={earwhite} />
          </div>
          <div>Ear</div>
        </div>
        <div className="report_data">
          <div>
            {/* <h5>Overview</h5> */}
            <p className="report_text">{ReportData.ear.overview}</p>
          </div>
          {/* {data?.earAssessment?.leftEarValidation?.length > 0 && (
            <> */}
          <h6>Left Ear</h6>
          {leftEarIssues.length > 0 ? (
            leftEarIssues.map(({ key, condition, info }) => (
              <div className="issue_box" key={key}>
                <h5>{condition} Detected</h5>
                <p className="report_text">{info}</p>
              </div>
            ))
          ) : (
            <HealthyBadge />
          )}
          {/* {ear?.includes("OTITIS_MEDIA") &&
                // <div class="screening_row">
                //   <div>
                //     <div></div>
                //     <div>Otitis Media</div>
                //   </div>
                //   <div style={findValidation(data?.earAssessment?.leftEarValidation, "Otisis Media") === "Present" ? { fontWeight: 600 } : {}}>
                //     {findValidation(data?.earAssessment?.leftEarValidation, "Otisis Media")}</div>
                //   <div>
                //     <div>Present/Absent</div>
                //   </div>
                //   <div>-</div>
                //   <div>{findInterpetetion(data?.earAssessment?.leftEarValidation, "Otisis Media")}</div>
                // </div>
                findValidation(
                  data?.earAssessment?.leftEarValidation,
                  "Otisis Media"
                ) === "Present" && (
                  <div>
                    <h5>{ReportData.ear.conditions[0].condition}</h5>
                    <p className="report_text">
                      {ReportData.ear.conditions[0].info}
                    </p>
                  </div>
                )}

              {ear?.includes("OTITIS_EXTERNA") &&
                // <div class="screening_row">
                //   <div>
                //     <div></div>
                //     <div>Otitis Externa</div>
                //   </div>
                //   <div style={findValidation(data?.earAssessment?.leftEarValidation, "Otisis Externa") === "Present" ? { fontWeight: 600 } : {}}>
                //     {findValidation(data?.earAssessment?.leftEarValidation, "Otisis Externa")}
                //   </div>
                //   <div>
                //     <div>Present/Absent</div>
                //   </div>
                //   <div>-</div>
                //   <div>{findInterpetetion(data?.earAssessment?.leftEarValidation, "Otisis Externa")}</div>
                // </div>
                findValidation(
                  data?.earAssessment?.leftEarValidation,
                  "Otisis Externa"
                ) === "Present" && (
                  <div>
                    <h5>{ReportData.ear.conditions[1].condition}</h5>
                    <p className="report_text">
                      {ReportData.ear.conditions[1].info}
                    </p>
                  </div>
                )}

              {ear?.includes("EAR_WAX") &&
                // <div class="screening_row">
                //   <div>
                //     <div></div>
                //     <div>Ear Wax</div>
                //   </div>
                //   <div style={findValidation(data?.earAssessment?.leftEarValidation, "Ear Wax") === "Present" ? { fontWeight: 600 } : {}}>
                //     {findValidation(data?.earAssessment?.leftEarValidation, "Ear Wax")}</div>
                //   <div>
                //     <div>Present/Absent</div>
                //   </div>
                //   <div>-</div>
                //   <div>{findInterpetetion(data?.earAssessment?.leftEarValidation, "Ear Wax")}</div>
                // </div>
                findValidation(
                  data?.earAssessment?.leftEarValidation,
                  "Ear Wax"
                ) === "Present" && (
                  <div>
                    <h5>{ReportData.ear.conditions[2].condition}</h5>
                    <p className="report_text">
                      {ReportData.ear.conditions[2].info}
                    </p>
                  </div>
                )}

              {ear?.includes("FOREIGN_BODY_IN_CANAL") &&
                // <div class="screening_row">
                //   <div>
                //     <div></div>
                //     <div style={{ textAlign: "left" }}>Foreign Body in Ear Canal</div>
                //   </div>
                //   <div style={findValidation(data?.earAssessment?.leftEarValidation, "Foreign Body in Ear canal") === "Present" ? { fontWeight: 600 } : {}}>
                //     {findValidation(data?.earAssessment?.leftEarValidation, "Foreign Body in Ear canal")}</div>
                //   <div>
                //     <div>Present/Absent</div>
                //   </div>
                //   <div>-</div>
                //   <div>{findInterpetetion(data?.earAssessment?.leftEarValidation, "Foreign Body in Ear canal")}</div>
                // </div>
                findValidation(
                  data?.earAssessment?.leftEarValidation,
                  "Foreign Body in Ear canal"
                ) === "Present" && (
                  <div>
                    <h5>{ReportData.ear.conditions[3].condition}</h5>
                    <p className="report_text">
                      {ReportData.ear.conditions[3].info}
                    </p>
                  </div>
                )} */}
          {/* </>
          )} */}
          {/* {ear?.hearing !== null && <div class="screening_row">
                    <div>
                        <div></div>
                        <div>Hearing</div>
                    </div>
                    <div>{checkReportCondition(data?.earAssessment?.hearing)}</div>
                    <div>
                        <div>Normal Hearing: 0-20dB</div>
                        <div>Slight Loss: 16-25dB</div>
                        <div>Mild Loss: 26-40dB</div>
                        <div>Moderate Loss: 41-69dB</div>
                        <div>Severe Loss: 70-89dB</div>
                        <div>Profound Loss: &gt;90dB</div>
                    </div>
                    <div>dB</div>
                    <div>Normal</div>
                </div>} */}
        </div>
        {/* {data?.earAssessment?.leftEarValidation?.length > 0 && (
          <> */}
        <div className="report_data">
          <h6>Right Ear</h6>
          {rightEarIssues.length > 0 ? (
            rightEarIssues.map(({ key, condition, info }) => (
              <div className="issue_box" key={key}>
                <h5>{condition} Detected</h5>
                <p className="report_text">{info}</p>
              </div>
            ))
          ) : (
            <HealthyBadge />
          )}
          {/* {ear?.includes("OTITIS_MEDIA") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Otitis Media</div>
            //   </div>
            //   <div style={findValidation(data?.earAssessment?.leftEarValidation, "Otisis Media") === "Present" ? { fontWeight: 600 } : {}}>
            //     {findValidation(data?.earAssessment?.leftEarValidation, "Otisis Media")}</div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>{findInterpetetion(data?.earAssessment?.leftEarValidation, "Otisis Media")}</div>
            // </div>
            findValidation(
              data?.earAssessment?.rightEarValidation,
              "Otisis Media"
            ) === "Present" && (
              <div>
                <h5>{ReportData.ear.conditions[0].condition}</h5>
                <p className="report_text">
                  {ReportData.ear.conditions[0].info}
                </p>
              </div>
            )}

          {ear?.includes("OTITIS_EXTERNA") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Otitis Externa</div>
            //   </div>
            //   <div style={findValidation(data?.earAssessment?.leftEarValidation, "Otisis Externa") === "Present" ? { fontWeight: 600 } : {}}>
            //     {findValidation(data?.earAssessment?.leftEarValidation, "Otisis Externa")}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>{findInterpetetion(data?.earAssessment?.leftEarValidation, "Otisis Externa")}</div>
            // </div>
            findValidation(
              data?.earAssessment?.rightEarValidation,
              "Otisis Externa"
            ) === "Present" && (
              <div>
                <h5>{ReportData.ear.conditions[1].condition}</h5>
                <p className="report_text">
                  {ReportData.ear.conditions[1].info}
                </p>
              </div>
            )}

          {ear?.includes("EAR_WAX") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Ear Wax</div>
            //   </div>
            //   <div style={findValidation(data?.earAssessment?.leftEarValidation, "Ear Wax") === "Present" ? { fontWeight: 600 } : {}}>
            //     {findValidation(data?.earAssessment?.leftEarValidation, "Ear Wax")}</div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>{findInterpetetion(data?.earAssessment?.leftEarValidation, "Ear Wax")}</div>
            // </div>
            findValidation(
              data?.earAssessment?.rightEarValidation,
              "Ear Wax"
            ) === "Present" && (
              <div>
                <h5>{ReportData.ear.conditions[2].condition}</h5>
                <p className="report_text">
                  {ReportData.ear.conditions[2].info}
                </p>
              </div>
            )}

          {ear?.includes("FOREIGN_BODY_IN_CANAL") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div style={{ textAlign: "left" }}>Foreign Body in Ear Canal</div>
            //   </div>
            //   <div style={findValidation(data?.earAssessment?.leftEarValidation, "Foreign Body in Ear canal") === "Present" ? { fontWeight: 600 } : {}}>
            //     {findValidation(data?.earAssessment?.leftEarValidation, "Foreign Body in Ear canal")}</div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>{findInterpetetion(data?.earAssessment?.leftEarValidation, "Foreign Body in Ear canal")}</div>
            // </div>
            findValidation(
              data?.earAssessment?.rightEarValidation,
              "Foreign Body in Ear canal"
            ) === "Present" && (
              <div>
                <h5>{ReportData.ear.conditions[3].condition}</h5>
                <p className="report_text">
                  {ReportData.ear.conditions[3].info}
                </p>
              </div>
            )} */}
          {/* {ear?.hearing !== null && <div class="screening_row">
                    <div>
                        <div></div>
                        <div>Hearing</div>
                    </div>
                    <div>{checkReportCondition(data?.earAssessment?.hearing)}</div>
                    <div>
                        <div>Normal Hearing: 0-20dB</div>
                        <div>Slight Loss: 16-25dB</div>
                        <div>Mild Loss: 26-40dB</div>
                        <div>Moderate Loss: 41-69dB</div>
                        <div>Severe Loss: 70-89dB</div>
                        <div>Profound Loss: &gt;90dB</div>
                    </div>
                    <div>dB</div>
                    <div>Normal</div>
                </div>} */}
        </div>
        {/* </>
        )} */}
        <div class="screening_method">
          <p>METHOD : Imaging & Video Technology</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
            {data?.qrCode && (
              <QRCode value={data?.qrCode} size={30}/>
            )}
          </div>
          <div>
            <div>Scan here</div>
            <div>to look at your kids ear screening images and videos</div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const RightEarReportSection = (props) => {
  const { data } = props;
  const ear = data?.student?.healthPackage?.ear;
  // const show = Object.keys(ear).every((x) => ear[x] === null);
  let show = false;
  if (show) {
    return <></>;
  }
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={earwhite} />
          </div>
          <div>Right Ear</div>
        </div>

        {ear?.includes("OTITIS_MEDIA") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Otitis Media</div>
            </div>
            <div
              style={
                findValidation(
                  data?.earAssessment?.rightEarValidation,
                  "Otisis Media"
                ) === "Present"
                  ? { fontWeight: 600 }
                  : {}
              }
            >
              {findValidation(
                data?.earAssessment?.rightEarValidation,
                "Otisis Media"
              )}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>
              {findInterpetetion(
                data?.earAssessment?.rightEarValidation,
                "Otisis Media"
              )}
            </div>
          </div>
        )}

        {ear?.includes("OTITIS_EXTERNA") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Otitis Externa</div>
            </div>
            <div
              style={
                findValidation(
                  data?.earAssessment?.rightEarValidation,
                  "Otisis Externa"
                ) === "Present"
                  ? { fontWeight: 600 }
                  : {}
              }
            >
              {findValidation(
                data?.earAssessment?.rightEarValidation,
                "Otisis Externa"
              )}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>
              {findInterpetetion(
                data?.earAssessment?.rightEarValidation,
                "Otisis Externa"
              )}
            </div>
          </div>
        )}

        {ear?.includes("EAR_WAX") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Ear Wax</div>
            </div>
            <div
              style={
                findValidation(
                  data?.earAssessment?.rightEarValidation,
                  "Ear Wax"
                ) === "Present"
                  ? { fontWeight: 600 }
                  : {}
              }
            >
              {findValidation(
                data?.earAssessment?.rightEarValidation,
                "Ear Wax"
              )}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>
              {findInterpetetion(
                data?.earAssessment?.rightEarValidation,
                "Ear Wax"
              )}
            </div>
          </div>
        )}

        {ear?.includes("FOREIGN_BODY_IN_CANAL") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div style={{ textAlign: "left" }}>Foreign Body in Ear Canal</div>
            </div>
            <div
              style={
                findValidation(
                  data?.earAssessment?.rightEarValidation,
                  "Foreign Body in Ear canal"
                ) === "Present"
                  ? { fontWeight: 600 }
                  : {}
              }
            >
              {findValidation(
                data?.earAssessment?.rightEarValidation,
                "Foreign Body in Ear canal"
              )}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>
              {findInterpetetion(
                data?.earAssessment?.rightEarValidation,
                "Foreign Body in Ear canal"
              )}
            </div>
          </div>
        )}
        {/* {ear?.hearing !== null && <div class="screening_row">
                  <div>
                      <div></div>
                      <div>Hearing</div>
                  </div>
                  <div>{checkReportCondition(data?.earAssessment?.hearing)}</div>
                  <div>
                      <div>Normal Hearing: 0-20dB</div>
                      <div>Slight Loss: 16-25dB</div>
                      <div>Mild Loss: 26-40dB</div>
                      <div>Moderate Loss: 41-69dB</div>
                      <div>Severe Loss: 70-89dB</div>
                      <div>Profound Loss: &gt;90dB</div>
                  </div>
                  <div>dB</div>
                  <div>Normal</div>
              </div>} */}
        <div class="screening_method">
          <p>METHOD : Imaging & Video Technology</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
        <div>
          {data?.qrCode && (
            <QRCode value={data?.qrCode} size={30}/>
          )}
        </div>
        <div>
          <div>Scan here</div>
          <div>to look at your kids ear screening images and videos</div>
        </div>
      </div> */}
      </div>
    </>
  );
};

export const MouthThroatReportSection = (props) => {
  const { data } = props;
  const throat = data?.student?.healthPackage?.throat;
  const throatValidation = data?.throatAssessment?.validation;
  // const show = Object.keys(throat).every((x) => throat[x] === null);
  let show = false;
  if (show) {
    return <></>;
  }
  const conditions = [
    {
      name: "Stomatitis",
      key: "STOMATITIS",
      condition: ReportData.throat.conditions[0].condition,
      info: ReportData.throat.conditions[0].info,
    },
    {
      name: "Glossitis",
      key: "GLOSSITIS",
      condition: ReportData.throat.conditions[1].condition,
      info: ReportData.throat.conditions[1].info,
    },
    {
      name: "Pharyngitis",
      key: "PHARYNGITIS",
      condition: ReportData.throat.conditions[2].condition,
      info: ReportData.throat.conditions[2].info,
    },
    {
      name: "Tonsilitis",
      key: "TONSILLITIS",
      condition: ReportData.throat.conditions[3].condition,
      info: ReportData.throat.conditions[3].info,
    },
    {
      name: "White Patches",
      key: "WHITE_PATCHES",
      condition: ReportData.throat.conditions[3].condition,
      info: ReportData.throat.conditions[3].info,
    },
    {
      name: "Throat Congestion",
      key: "THROAT_CONGESTION",
      condition: ReportData.throat.conditions[3].condition,
      info: ReportData.throat.conditions[3].info,
    },
  ];

  const detectedIssues = conditions.filter(
    (item) =>
      throat?.includes(item.key.toUpperCase()) &&
      findValidation(throatValidation, item.name) === "Present"
  );
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={throatwhite} />
          </div>
          <div>Mouth/Throat</div>
        </div>
        <div className="report_data">
          <div>
            {/* <h5>Overview</h5> */}
            <p className="report_text">{ReportData.throat.overview}</p>
          </div>
          {detectedIssues.length > 0 ? (
            detectedIssues.map(({ key, condition, info }) => (
              <div className="issue_box" key={key}>
                <h5>{condition} Detected</h5>
                <p className="report_text">{info}</p>
              </div>
            ))
          ) : (
            <HealthyBadge />
          )}
          {/* {throat?.includes("STOMATITIS") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Stomatitis</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.throatAssessment?.validation,
            //         "Stomatitis"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(data?.throatAssessment?.validation, "Stomatitis")}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.throatAssessment?.validation,
            //       "Stomatitis"
            //     )}
            //   </div>
            // </div>
            findValidation(data?.throatAssessment?.validation, "Stomatitis") ===
              "Present" && (
              <div>
                <h5>{ReportData.throat.conditions[0].condition}</h5>
                <p className="report_text">
                  {ReportData.throat.conditions[0].info}
                </p>
              </div>
            )}

          {throat?.includes("GLOSSITIS") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Glossitis</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.throatAssessment?.validation,
            //         "Glossitis"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(data?.throatAssessment?.validation, "Glossitis")}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.throatAssessment?.validation,
            //       "Glossitis"
            //     )}
            //   </div>
            // </div>
            findValidation(data?.throatAssessment?.validation, "Glossitis") ===
              "Present" && (
              <div>
                <h5>{ReportData.throat.conditions[1].condition}</h5>
                <p className="report_text">
                  {ReportData.throat.conditions[1].info}
                </p>
              </div>
            )}

          {throat?.includes("PHARYNGITIS") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Pharyngitis</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.throatAssessment?.validation,
            //         "Pharyngitis"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(
            //       data?.throatAssessment?.validation,
            //       "Pharyngitis"
            //     )}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.throatAssessment?.validation,
            //       "Pharyngitis"
            //     )}
            //   </div>
            // </div>
            findValidation(
              data?.throatAssessment?.validation,
              "Pharyngitis"
            ) === "Present" && (
              <div>
                <h5>{ReportData.throat.conditions[2].condition}</h5>
                <p className="report_text">
                  {ReportData.throat.conditions[2].info}
                </p>
              </div>
            )}

          {throat?.includes("TONSILLITIS") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Tonsilitis</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.throatAssessment?.validation,
            //         "Tonsilitis"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(data?.throatAssessment?.validation, "Tonsilitis")}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.throatAssessment?.validation,
            //       "Tonsilitis"
            //     )}
            //   </div>
            // </div>
            findValidation(data?.throatAssessment?.validation, "Tonsilitis") ===
              "Present" && (
              <div>
                <h5>{ReportData.throat.conditions[3].condition}</h5>
                <p className="report_text">
                  {ReportData.throat.conditions[3].info}
                </p>
              </div>
            )}
          {throat?.includes("WHITE_PATCHES") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>White Patches</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.throatAssessment?.validation,
            //         "White Patches"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(
            //       data?.throatAssessment?.validation,
            //       "White Patches"
            //     )}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.throatAssessment?.validation,
            //       "White Patches"
            //     )}
            //   </div>
            // </div>
            findValidation(
              data?.throatAssessment?.validation,
              "White Patches"
            ) === "Present" && (
              <div>
                <h5>{ReportData.throat.conditions[4].condition}</h5>
                <p className="report_text">
                  {ReportData.throat.conditions[4].info}
                </p>
              </div>
            )}
          {throat?.includes("THROAT_CONGESTION") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Throat Congestion</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.throatAssessment?.validation,
            //         "Throat Congestion"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(
            //       data?.throatAssessment?.validation,
            //       "Throat Congestion"
            //     )}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.throatAssessment?.validation,
            //       "Throat Congestion"
            //     )}
            //   </div>
            // </div>
            findValidation(
              data?.throatAssessment?.validation,
              "Throat Congestion"
            ) === "Present" && (
              <div>
                <h5>{ReportData.throat.conditions[5].condition}</h5>
                <p className="report_text">
                  {ReportData.throat.conditions[5].info}
                </p>
              </div>
            )} */}
        </div>
        <div class="screening_method">
          <p>METHOD : Imaging & Video Technology</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>to look at your kids throat screening images and videos</div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const HeartReportSection = (props) => {
  const { data } = props;
  const heart = data?.student?.healthPackage?.heart;
  const heartValidation = data?.heartAssessment?.auscultationValidation;
  // const show = Object.keys(heart).every((x) => heart[x] === null);
  let show = false;
  if (show) {
    return <></>;
  }
  const conditions = [
    {
      name: "Heart Murmur",
      key: "HEART_MURMUR",
      condition: ReportData.heart.conditions[0].condition,
      info: ReportData.heart.conditions[0].info,
    },
    {
      name: "Arrhythmia",
      key: "ARRHYTHMIA",
      condition: ReportData.heart.conditions[1].condition,
      info: ReportData.heart.conditions[1].info,
    },
  ];

  const detectedIssues = conditions.filter(
    (item) =>
      heart?.includes(item.key.toUpperCase()) &&
      findValidation(heartValidation, item.name) === "Present"
  );
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={heartwhite} />
          </div>
          <div>Heart</div>
        </div>
        <div className="report_data">
          <div>
            {/* <h5>Overview</h5> */}
            <p className="report_text">{ReportData.heart.overview}</p>
          </div>
          {/* <div style={{ height: "300px" }}>
            {data?.heartAssessment?.heartReportUrl && (
              <iframe
                src={data?.heartAssessment?.heartReportUrl}
                style={{ width: "100%" }}
                height="250"
                frameBorder="0"
              ></iframe>
            )}
          </div> */}
          {detectedIssues.length > 0 ? (
            detectedIssues.map(({ key, condition, info }) => (
              <div className="issue_box" key={key}>
                <h5>{condition} Detected</h5>
                <p className="report_text">{info}</p>
              </div>
            ))
          ) : (
            <HealthyBadge />
          )}
          {/* {heart?.includes("HEART_MURMUR") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Heart Murmurs</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.heartAssessment?.auscultationValidation,
            //         "Heart Murmur"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(
            //       data?.heartAssessment?.auscultationValidation,
            //       "Heart Murmur"
            //     )}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.heartAssessment?.auscultationValidation,
            //       "Heart Murmur"
            //     )}
            //   </div>
            // </div>
            findValidation(
              data?.heartAssessment?.auscultationValidation,
              "Heart Murmur"
            ) === "Present" && (
              <div>
                <h5>{ReportData.heart.conditions[0].condition}</h5>
                <p className="report_text">
                  {ReportData.heart.conditions[0].info}
                </p>
              </div>
            )}
          {heart?.includes("ARRHYTHMIA") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Arrhythmia</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.heartAssessment?.auscultationValidation,
            //         "Arrhythmia"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(
            //       data?.heartAssessment?.auscultationValidation,
            //       "Arrhythmia"
            //     )}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.heartAssessment?.auscultationValidation,
            //       "Arrhythmia"
            //     )}
            //   </div>
            // </div>
            findValidation(
              data?.heartAssessment?.auscultationValidation,
              "Arrhythmia"
            ) === "Present" && (
              <div>
                <h5>{ReportData.heart.conditions[1].condition}</h5>
                <p className="report_text">
                  {ReportData.heart.conditions[1].info}
                </p>
              </div>
            )}
          {heart?.includes("ABNORMAL_HEART_SOUND") && (
            <></>
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Abnormal Heart Sound</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.heartAssessment?.auscultationValidation,
            //         "Abnormal Heart Sound"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(
            //       data?.heartAssessment?.auscultationValidation,
            //       "Abnormal Heart Sound"
            //     )}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.heartAssessment?.auscultationValidation,
            //       "Abnormal Heart Sound"
            //     )}
            //   </div>
            // </div>
          )}
          {heart?.includes("RHYTHM_DISORDER") && (
            <></>
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Rhythm Disorder</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.heartAssessment?.auscultationValidation,
            //         "Rhythm Disorder"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(
            //       data?.heartAssessment?.auscultationValidation,
            //       "Rhythm Disorder"
            //     )}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.heartAssessment?.auscultationValidation,
            //       "Rhythm Disorder"
            //     )}
            //   </div>
            // </div>
          )} */}
        </div>
        <div class="screening_method">
          <p>METHOD: Auscultation Technology through AI</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>to listen your kids heart auscultations</div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const DentalReportSection = (props) => {
  const { data } = props;
  const dental = data?.dentalAssessment;
  // const show = Object.keys(dental).every((x) => dental[x] === null);
  const getDentalStatus = (x) => {
    if (data.student.package == "BASIC") {
      if (x === "D") {
        return data.dentalAssessment.cariesDecay;
      } else if (x === "M") {
        return data.dentalAssessment.missedTooth;
      } else if (x === "F") {
        return data.dentalAssessment.fillingPresent;
      }
    } else {
      let a = data?.dentalAssessment?.tooth[0].split(",");
      for (let i = 0; i < a.length; i++) {
        if (a[i]?.split("_")[1] === x) {
          return true;
        }
      }
      return false;
    }
  };

  let show = false;
  if (show) {
    return <></>;
  }
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={dentalwhite} />
          </div>
          <div>Dental</div>
        </div>
        <div className="report_data">
          <div>
            {/* <h5>Overview</h5> */}
            <p className="report_text">{ReportData.dental.overview}</p>
          </div>
          {dental?.tooth !== null && getDentalStatus("D") ? (
            <div className="issue_box">
              <h5>{ReportData.dental.conditions[1].condition} Detected</h5>
              <p className="report_text">
                {ReportData.dental.conditions[1].info}
              </p>
            </div>
          ) : // <div class="screening_row">
          //   <div>
          //     <div></div>
          //     <div>Decayed</div>
          //   </div>
          //   <div
          //     style={
          //       data?.dentalAssessment?.tooth && getDentalStatus("D")
          //         ? { fontWeight: 600 }
          //         : {}
          //     }
          //   >
          //     {data?.dentalAssessment?.tooth && getDentalStatus("D")
          //       ? "Present"
          //       : "Absent"}
          //   </div>
          //   <div>
          //     <div>Present/Absent</div>
          //   </div>
          //   <div>-</div>
          //   <div>{getDentalStatus("D") ? "Decayed Teeth" : "Normal"}</div>
          // </div>
          dental?.gingivitis !== null ? (
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Gingivitis</div>
            //   </div>
            //   <div
            //     style={
            //       checkReportCondition(data?.dentalAssessment?.gingivitis) ===
            //       "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {checkReportCondition(data?.dentalAssessment?.gingivitis)}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {data?.dentalAssessment?.gingivitis
            //       ? "Gingivitis Present"
            //       : "Normal"}
            //   </div>
            // </div>
            checkReportCondition(data?.dentalAssessment?.gingivitis) ===
              "Present" && (
              <div className="issue_box">
                <h5>{ReportData.dental.conditions[0].condition} Detected</h5>
                <p className="report_text">
                  {ReportData.dental.conditions[0].info}
                </p>
              </div>
            )
          ) : (
            <HealthyBadge />
          )}

          {dental?.tooth !== null && (
            <></>
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Filled</div>
            //   </div>
            //   <div
            //     style={
            //       data?.dentalAssessment?.tooth && getDentalStatus("F")
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {data?.dentalAssessment?.tooth && getDentalStatus("F")
            //       ? "Present"
            //       : "Absent"}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>{getDentalStatus("F") ? "Filled Teeth" : "Normal"}</div>
            // </div>
          )}

          {/* {dental?.tooth !== null && (
            <></>
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Missing</div>
            //   </div>
            //   <div
            //     style={
            //       data?.dentalAssessment?.tooth && getDentalStatus("M")
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {data?.dentalAssessment?.tooth && getDentalStatus("M")
            //       ? "Present"
            //       : "Absent"}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>{getDentalStatus("M") ? "Missing Teeth" : "Normal"}</div>
            // </div>
          )}

          
          {dental?.orthodontic !== null && (
            <></>
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Orthodontic Problem</div>
            //   </div>
            //   <div
            //     style={
            //       checkReportCondition(data?.dentalAssessment?.orthodontic) ===
            //       "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {checkReportCondition(data?.dentalAssessment?.orthodontic)}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {data?.dentalAssessment?.orthodontic
            //       ? "Orthodontic Problem Present"
            //       : "Normal"}
            //   </div>
            // </div>
          )}
          {dental?.decoloration !== null && (
            <></>
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Decoloration</div>
            //   </div>
            //   <div
            //     style={
            //       checkReportCondition(data?.dentalAssessment?.decoloration) ===
            //       "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {checkReportCondition(data?.dentalAssessment?.decoloration)}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {data?.dentalAssessment?.decoloration
            //       ? "Decoloration Present"
            //       : "Normal"}
            //   </div>
            // </div>
          )}
          {dental?.poorOralHygiene !== null && (
            <></>
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Poor Oral Hygiene</div>
            //   </div>
            //   <div
            //     style={
            //       checkReportCondition(
            //         data?.dentalAssessment?.poorOralHygiene
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {checkReportCondition(data?.dentalAssessment?.poorOralHygiene)}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {data?.dentalAssessment?.poorOralHygiene
            //       ? "Poor Oral Hygiene Present"
            //       : "Normal"}
            //   </div>
            // </div>
          )} */}
        </div>
        <div class="screening_method">
          <p>METHOD : Visual Screening</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>to look at your kids dental layout</div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const LungsReportSection = (props) => {
  const { data } = props;
  const lungs = data?.student?.healthPackage?.lungs;
  const lungsValidation = data?.lungsAssessment?.auscultationValidation;
  let show = false;
  if (show) {
    return <></>;
  }

  const conditions = [
    {
      name: "Dyspnea",
      key: "DYSPNEA",
      condition: ReportData.lungs.conditions[0].condition,
      info: ReportData.lungs.conditions[0].info,
    },
    {
      name: "Wheezing",
      key: "WHEEZING",
      condition: ReportData.lungs.conditions[1].condition,
      info: ReportData.lungs.conditions[1].info,
    },
    {
      name: "Cough",
      key: "COUGH",
      condition: ReportData.lungs.conditions[1].condition,
      info: ReportData.lungs.conditions[1].info,
    },
  ];

  const detectedIssues = conditions.filter((item) => {
    if (item.key === "COUGH") {
      return data?.coughAssessment?.validation?.length > 0;
    } else {
      return (
        lungs?.includes(item.key.toUpperCase()) &&
        findValidation(lungsValidation, item.name) === "Present"
      );
    }
  });

  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={lungswhite} />
          </div>
          <div>Lungs</div>
        </div>
        <div className="report_data">
          <div>
            {/* <h5>Overview</h5> */}
            <p className="report_text">{ReportData.lungs.overview}</p>
          </div>
          {detectedIssues.length > 0 ? (
            detectedIssues.map(({ key, condition, info }) => (
              <div className="issue_box" key={key}>
                <h5>{condition} Detected</h5>
                <p className="report_text">{info}</p>
              </div>
            ))
          ) : (
            <HealthyBadge />
          )}
          {/* {lungs?.includes("RESPIRATORY_SOUNDS") && (
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Respiratory Sound</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.lungsAssessment?.auscultationValidation,
            //         "Respiratory Sounds"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(
            //       data?.lungsAssessment?.auscultationValidation,
            //       "Respiratory Sounds"
            //     )}
            //   </div>
            //   <div>
            //     <div>Normal/Abnormal</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.lungsAssessment?.auscultationValidation,
            //       "Respiratory Sounds"
            //     )}
            //   </div>
            // </div>
            <></>
          )}

          {lungs?.includes("DYSPNEA") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Dyspnea</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.lungsAssessment?.auscultationValidation,
            //         "Dyspnea"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(
            //       data?.lungsAssessment?.auscultationValidation,
            //       "Dyspnea"
            //     )}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.lungsAssessment?.auscultationValidation,
            //       "Dyspnea"
            //     )}
            //   </div>
            // </div>
            findValidation(
              data?.lungsAssessment?.auscultationValidation,
              "Dyspnea"
            ) === "Present" && (
              <div>
                <h5>{ReportData.lungs.conditions[0].condition}</h5>
                <p className="report_text">
                  {ReportData.lungs.conditions[0].info}
                </p>
              </div>
            )}

          {lungs?.includes("WHEEZING") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Wheezing</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.lungsAssessment?.auscultationValidation,
            //         "Wheezing"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(
            //       data?.lungsAssessment?.auscultationValidation,
            //       "Wheezing"
            //     )}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.lungsAssessment?.auscultationValidation,
            //       "Wheezing"
            //     )}
            //   </div>
            // </div>
            findValidation(
              data?.lungsAssessment?.auscultationValidation,
              "Wheezing"
            ) === "Present" && (
              <div>
                <h5>{ReportData.lungs.conditions[1].condition}</h5>
                <p className="report_text">
                  {ReportData.lungs.conditions[1].info}
                </p>
              </div>
            )}

          {lungs?.includes("RATTLING") && (
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Rattling</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.lungsAssessment?.auscultationValidation,
            //         "Rattling"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(
            //       data?.lungsAssessment?.auscultationValidation,
            //       "Rattling"
            //     )}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.lungsAssessment?.auscultationValidation,
            //       "Rattling"
            //     )}
            //   </div>
            // </div>
            <></>
          )}
          {lungs?.includes("COUGH") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Cough</div>
            //   </div>
            //   <div
            //     style={
            //       data?.coughAssessment?.validation?.length > 0
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {data?.coughAssessment?.validation?.length > 0
            //       ? "Present"
            //       : "Absent"}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(data?.coughAssessment?.validation, "Present")}
            //   </div>
            // </div>
            data?.coughAssessment?.validation?.length > 0 && (
              <div>
                <h5>{ReportData.lungs.conditions[2].condition}</h5>
                <p className="report_text">
                  {ReportData.lungs.conditions[2].info}
                </p>
              </div>
            )} */}
        </div>
        <div class="screening_method">
          <p>METHOD : Auscultation Technology through AI</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>to listen your kids lungs auscultations</div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const AbdomenReportSection = (props) => {
  const { data } = props;
  const abdomen = data?.student?.healthPackage?.abdomen;
  const abdomenValidation = data?.abdomenAssessment?.validation;
  let show = false;
  if (show) {
    return <></>;
  }

  const conditions = [
    {
      name: "Stomach Pain",
      key: "STOMACH_PAIN",
      condition: ReportData.abdomen.conditions[0].condition,
      info: ReportData.abdomen.conditions[0].info,
    },
    {
      name: "Acidity",
      key: "ACIDITY",
      condition: ReportData.abdomen.conditions[1].condition,
      info: ReportData.abdomen.conditions[1].info,
    },
    {
      name: "Constipation",
      key: "CONSTIPATION",
      condition: ReportData.abdomen.conditions[2].condition,
      info: ReportData.abdomen.conditions[2].info,
    },
  ];

  const detectedIssues = conditions.filter(
    (item) =>
      abdomen?.includes(item.key.toUpperCase()) &&
      findValidation(abdomenValidation, item.name) === "Present"
  );

  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={abdomenwhite} />
          </div>
          <div>Abdomen</div>
        </div>
        <div className="report_data">
          <div>
            {/* <h5>Overview</h5> */}
            <p className="report_text">{ReportData.abdomen.overview}</p>
          </div>
          {detectedIssues.length > 0 ? (
            detectedIssues.map(({ key, condition, info }) => (
              <div className="issue_box" key={key}>
                <h5>{condition} Detected</h5>
                <p className="report_text">{info}</p>
              </div>
            ))
          ) : (
            <HealthyBadge />
          )}
          {/* {abdomen?.includes("BOWEL_OBSTRUCTION") && (
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Bowel Obstruction</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.abdomenAssessment?.validation,
            //         "Bowel Obstruction"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(
            //       data?.abdomenAssessment?.validation,
            //       "Bowel Obstruction"
            //     )}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.abdomenAssessment?.validation,
            //       "Bowel Obstruction"
            //     )}
            //   </div>
            // </div>
            <></>
          )}
          {abdomen?.includes("ABDOMINAL_BRUIT") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Abdominal Bruit</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.abdomenAssessment?.validation,
            //         "Abdominal Bruit"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(
            //       data?.abdomenAssessment?.validation,
            //       "Abdominal Bruit"
            //     )}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.abdomenAssessment?.validation,
            //       "Abdominal Bruit"
            //     )}
            //   </div>
            // </div>
            findValidation(
              data?.abdomenAssessment?.validation,
              "Abdominal Bruit"
            ) === "Present" && (
              <div>
                <h5>{ReportData.abdomen.conditions[0].condition}</h5>
                <p className="report_text">
                  {ReportData.abdomen.conditions[0].info}
                </p>
              </div>
            )}
          {abdomen?.includes("PARALYTIC_ILEUS") && (
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Paralytic Ileus</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.abdomenAssessment?.validation,
            //         "Paralytic Ileus"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(
            //       data?.abdomenAssessment?.validation,
            //       "Paralytic Ileus"
            //     )}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.abdomenAssessment?.validation,
            //       "Paralytic Ileus"
            //     )}
            //   </div>
            // </div>
            <></>
          )}
          {abdomen?.includes("INTESTINAL_SOUNDS") && (
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Intestinal Sound</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.abdomenAssessment?.validation,
            //         "Intestinal Sounds"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(
            //       data?.abdomenAssessment?.validation,
            //       "Intestinal Sounds"
            //     )}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(
            //       data?.abdomenAssessment?.validation,
            //       "Intestinal Sounds"
            //     )}
            //   </div>
            // </div>
            <></>
          )} */}
        </div>
        <div class="screening_method">
          <p>METHOD : Auscultation Technology through AI</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>to listen your kids abdomen auscultations</div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const SkinReportSection = (props) => {
  const { data } = props;
  const skin = data?.student?.healthPackage?.skin;
  const skinValidation = data?.skinAssessment?.validation;
  // const show = Object.keys(skin).every((x) => skin[x] === null);
  let show = false;
  if (show) {
    return <></>;
  }

  const conditions = [
    {
      name: "Scabies",
      key: "SCABIES",
      condition: ReportData.skin.conditions[0].condition,
      info: ReportData.skin.conditions[0].info,
    },
    {
      name: "Rashes",
      key: "RASHES",
      condition: ReportData.skin.conditions[1].condition,
      info: ReportData.skin.conditions[1].info,
    },
    {
      name: "Blisters",
      key: "BLISTERS",
      condition: ReportData.skin.conditions[2].condition,
      info: ReportData.skin.conditions[2].info,
    },
    {
      name: "Urticaria",
      key: "URTICARIA",
      condition: ReportData.skin.conditions[2].condition,
      info: ReportData.skin.conditions[2].info,
    },
  ];

  const detectedIssues = conditions.filter(
    (item) =>
      skin?.includes(item.key.toUpperCase()) &&
      findValidation(skinValidation, item.name) === "Present"
  );

  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={skinwhite} />
          </div>
          <div>Skin</div>
        </div>
        <div className="report_data">
          <div>
            {/* <h5>Overview</h5> */}
            <p className="report_text">{ReportData.skin.overview}</p>
          </div>
          {detectedIssues.length > 0 ? (
            detectedIssues.map(({ key, condition, info }) => (
              <div className="issue_box" key={key}>
                <h5>{condition} Detected</h5>
                <p className="report_text">{info}</p>
              </div>
            ))
          ) : (
            <HealthyBadge />
          )}
          {skin?.includes("SCABIES") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Scabies</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(data?.skinAssessment?.validation, "Scabies") ===
            //       "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(data?.skinAssessment?.validation, "Scabies")}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(data?.skinAssessment?.validation, "Scabies")}
            //   </div>
            // </div>
            findValidation(data?.skinAssessment?.validation, "Scabies") ===
              "Present" && (
              <div>
                <h5>{ReportData.skin.conditions[0].condition}</h5>
                <p className="report_text">
                  {ReportData.skin.conditions[0].info}
                </p>
              </div>
            )}

          {skin?.includes("RASHES") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Rashes</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(data?.skinAssessment?.validation, "Rashes") ===
            //       "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(data?.skinAssessment?.validation, "Rashes")}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(data?.skinAssessment?.validation, "Rashes")}
            //   </div>
            // </div>
            findValidation(data?.skinAssessment?.validation, "Rashes") ===
              "Present" && (
              <div>
                <h5>{ReportData.skin.conditions[1].condition}</h5>
                <p className="report_text">
                  {ReportData.skin.conditions[1].info}
                </p>
              </div>
            )}

          {skin?.includes("BLISTERS") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Blisters</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(data?.skinAssessment?.validation, "Blisters") ===
            //       "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(data?.skinAssessment?.validation, "Blisters")}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(data?.skinAssessment?.validation, "Blisters")}
            //   </div>
            // </div>
            findValidation(data?.skinAssessment?.validation, "Blisters") ===
              "Present" && (
              <div>
                <h5>{ReportData.skin.conditions[2].condition}</h5>
                <p className="report_text">
                  {ReportData.skin.conditions[2].info}
                </p>
              </div>
            )}

          {skin?.includes("URTICARIA") &&
            // <div class="screening_row">
            //   <div>
            //     <div></div>
            //     <div>Urticaria</div>
            //   </div>
            //   <div
            //     style={
            //       findValidation(
            //         data?.skinAssessment?.validation,
            //         "Urticaria"
            //       ) === "Present"
            //         ? { fontWeight: 600 }
            //         : {}
            //     }
            //   >
            //     {findValidation(data?.skinAssessment?.validation, "Urticaria")}
            //   </div>
            //   <div>
            //     <div>Present/Absent</div>
            //   </div>
            //   <div>-</div>
            //   <div>
            //     {findInterpetetion(data?.skinAssessment?.validation, "Urticaria")}
            //   </div>
            // </div>
            findValidation(data?.skinAssessment?.validation, "Urticaria") ===
              "Present" && (
              <div>
                <h5>{ReportData.skin.conditions[3].condition}</h5>
                <p className="report_text">
                  {ReportData.skin.conditions[3].info}
                </p>
              </div>
            )}
        </div>
        <div class="screening_method">
          <p>METHOD : Auscultation Technology through AI</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>          
          </div>
          <div>
            <div>Scan here</div>
            <div>to look at your kids skin screening images and videos</div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const BehavioralReportSection = (props) => {
  const { data } = props;
  const behave = data?.behavioralAssessment;
  const show =
    data?.ADHD?.score ||
    data?.ANXIETY?.score ||
    data?.AUTISM?.score ||
    data?.CONDUCT_DISORDER?.score ||
    data?.DEPRESSION?.score ||
    data?.DEVELOPMENTAL_DELAY?.score ||
    data?.INTERNET_ADDICTION?.score ||
    data?.ODD?.score;

  if (!show) {
    return <></>;
  }
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={bhvlogo} />
          </div>
          <div>Behavioral Assessment</div>
        </div>

        {data?.INTERNET_ADDICTION?.outcome && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Internet Addiction</div>
            </div>
            <div></div>
            <div>
              <div>Risk indicated/ No risk indicated</div>
            </div>
            <div>-</div>
            <div>{data?.INTERNET_ADDICTION?.outcome}</div>
          </div>
        )}

        {data?.ADHD?.outcome && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>ADHD</div>
            </div>
            <div></div>
            <div>
              <div>Predominantly inattentive/</div>
              <div>Predominantly hyperactive/</div>
              <div>impulsive/ No risk indicated</div>
            </div>
            <div>-</div>
            <div>{data?.ADHD?.outcome}</div>
          </div>
        )}

        {data?.ANXIETY?.outcome && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Anxiety</div>
            </div>
            <div></div>
            <div>
              <div>Risk indicated/ No risk indicated</div>
            </div>
            <div>-</div>
            <div>
              {data?.ANXIETY?.outcome?.map((x) => {
                return x;
              })}
            </div>
          </div>
        )}

        {data?.DEPRESSION?.outcome && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Depression</div>
            </div>
            <div></div>
            <div>
              <div>Risk indicated/ No risk indicated</div>
            </div>
            <div>-</div>
            <div>{data?.DEPRESSION?.outcome}</div>
          </div>
        )}

        {data?.CONDUCT_DISORDER?.outcome && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Conduct Disorder</div>
            </div>
            <div></div>
            <div>
              <div>Risk indicated/ No risk indicated</div>
            </div>
            <div>-</div>
            <div>{data?.CONDUCT_DISORDER?.outcome}</div>
          </div>
        )}
        {data?.ODD?.outcome && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Opp. Defiant Disorder</div>
            </div>
            <div></div>
            <div>
              <div>Risk indicated/ No risk indicated</div>
            </div>
            <div>-</div>
            <div>{data?.ODD?.outcome}</div>
          </div>
        )}
        {data?.AUTISM?.outcome && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Autism</div>
            </div>
            <div></div>
            <div>
              <div>Risk indicated/ No risk indicated</div>
            </div>
            <div>-</div>
            <div>{data?.AUTISM?.outcome}</div>
          </div>
        )}
        <div class="screening_method">
          <p>
            METHOD : Frameworks approved by AAP (American Academy of Pediatrics)
          </p>
        </div>
        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>
              to look at your kids behavioural assessment detailed reports
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const LearningReportSection = (props) => {
  const { data } = props;
  const learning =
    data?.DYSLEXIA_1?.outcome ||
    data?.DYSCALCULIA_1?.outcome ||
    data?.DYSGRAPHIA_1?.outcome;
  if (!learning) {
    return <></>;
  }
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={bhvlogo} />
          </div>
          <div>Learning Disabilities</div>
        </div>
        {data?.DYSLEXIA_1?.outcome && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Dyslexia</div>
            </div>
            <div></div>
            <div>
              <div>Risk indicated/ No risk indicated</div>
            </div>
            <div>-</div>
            <div>{data?.DYSLEXIA_2?.outcome ?? data?.DYSLEXIA_1?.outcome}</div>
          </div>
        )}
        {data?.DYSGRAPHIA_1?.outcome && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Dysgraphia</div>
            </div>
            <div></div>
            <div>
              <div>Risk indicated/ No risk indicated</div>
            </div>
            <div>-</div>
            <div>
              {data?.DYSGRAPHIA_2?.outcome ?? data?.DYSGRAPHIA_1?.outcome}
            </div>
          </div>
        )}
        {data?.DYSCALCULIA_1?.outcome && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Dyscalculia</div>
            </div>
            <div></div>
            <div>
              <div>Risk indicated/ No risk indicated</div>
            </div>
            <div>oF</div>
            <div>
              {data?.DYSCALCULIA_2?.outcome ?? data?.DYSCALCULIA_1?.outcome}
            </div>
          </div>
        )}
        <div class="screening_method">
          <p>
            METHOD : Frameworks approved by AAP (American Academy of Pediatrics)
          </p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>
              to look at your kids learning disabilities detailed reports
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const DoctorSection = (props) => {
  const { data } = props;
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={doctorlogo} />
          </div>
          <div>Our Doctors</div>
        </div>

        <div class="screening_row">
          <div>
            <div></div>
            <div>Dr. Tanuja Mishra</div>
          </div>
          <div>M.B.B.S, M.D</div>
        </div>

        <div class="screening_row">
          <div>
            <div></div>
            <div>Ms. Pratikshya Kar</div>
          </div>
          <div>Clinical Psychologist</div>
        </div>

        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>
              to see the profile of doctor who validated data of your kid
              screening
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const EndOfReport = () => {
  return (
    <>
      <div className="horizontal_line"></div>
      <div className="note_container">
        <p>Note</p>
        <p>1. Results to be clinically correlated.</p>
        <p>
          2. Post test counseling available between 9.00 am to 5:00 pm at SKIDS
          clinic
        </p>
      </div>
      <div className="information_container">
        <p>Important Instructions</p>
        <p>
          Test results released pertain to the screening done. All test results
          are dependent on the quality of the sample received by us. Clinic
          investigations are only a tool to facilitate in arriving at a
          diagnosis and should be clinically correlated by the Referring
          Physician. Report delivery may be delayed due to unforeseen
          circumstances. Test results may show interlaboratory variations. Test
          results are not valid for medico legal purposes.
        </p>
      </div>
      <div className="end_report_container">
        <p>**End Of Report**</p>
        <p>
          Please visit{" "}
          <a href="https://www.skids.health" target="_blank">
            www.skids.health
          </a>{" "}
          for more related Information
        </p>
      </div>
    </>
  );
};
