import React, { useEffect, useLayoutEffect, useState } from "react";
import classes from "./WriterDashboard.module.css";
import BreadCrumbs from "../common/TableComponents/BreadCrumbs";
import TabButton from "../common/TableComponents/TabButton";
import { useLocation, useNavigate } from "react-router-dom";
import blogReducer from "../../redux/reducers/blog.reducer";
import { Alert, Button, FormControl, InputLabel, MenuItem, Select, Snackbar } from "@mui/material";
import SearchBar from "../common/TableComponents/SearchBar";
import { Filter } from "../common/TableComponents/Filter";
import BlogRow from "./BlogRow";
import { useDispatch, useSelector } from "react-redux";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import BlankDataScreen from "../BlankDataScreen/BlankDataScreen";

const WriterDashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [text, setText] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [resolvedFilter, setResolvedFilter] = useState(false);
    const [unresolvedFilter, setUnresolvedFilter] = useState(false);
    const getBlogs = blogReducer.actions.getBlogsRequest;
    const blogs = useSelector((state) => state.blogReducer.blogs);
    const [displayData, setDisplayData] = useState(blogs ?? []);
    const updateBlogStatus = blogReducer.actions.updateBlogStatusRequest;
    const loading = useSelector((state) => state.blogReducer.loading);
    const error = useSelector((state) => state.blogReducer.error);
    const breadCrumbs = [
        "Content Writer Dashboard"
    ]
    
    location.state = { breadCrumbs: breadCrumbs }
    
    useEffect(() => {
      dispatch(getBlogs());
    }, []);


    const handleClick = () => {
        setOpen(true);
      };
    
      useEffect(() => {
        if (text) {
          setDisplayData(
            blogs.filter((x) =>
              x?.title?.toLowerCase()?.includes(text?.toLowerCase()) || x?.description?.toLowerCase()?.includes(text?.toLowerCase())
            )
          );
          setPageNo(1)
        } else {
          setDisplayData(blogs);
        }
      }, [text]);
    
      useEffect(() => {
        if (Array.isArray(blogs)) {
          const y = blogs?.slice()?.sort((a, b) => {
            return b?.createdAt.localeCompare(a?.createdAt)
          })
          setDisplayData(y);
        }
      }, [blogs]);

      const handlePublishBlog = async (data) => {
        if(location.pathname.includes('writer')){
           await dispatch(updateBlogStatus({blogId: data?.blogId, status: data?.status === "PUBLISHED" ? "DRAFT" : "PUBLISHED"}));
           setTimeout(() => {
              dispatch(getBlogs());
           }, 2000);
           handleSnackbar();
        }
     }

     
   const handleSnackbar = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

    return (
      <>
        <div className={classes.main_div}>
            <div className={classes.student_layout}>
                <div>
                    <BreadCrumbs />
                </div>
                <div className={classes.tabBtn_container}>
                    {/* <button
              onClick={() => setChipModal(true)}
              className={classes.sharetypeform_btn}
            >
              <img src={typeformUpload} />
              <span>Share Typeform</span>
            </button> */}
                    <TabButton role="New Blog" />
                </div>
            </div>
            {
          blogs?.length > 0 ?
            <>
              <div className={classes.student_filters}>
                <div className={classes.show_text}>Show</div>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rowsPerPage}
                    label="Rows"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                    size="small"
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
                {/* <CustomAutoComplete
                  options={allCities}
                  value={city}
                  placeholder={"City"}
                  onChange={setCity}
                /> */}
                <SearchBar id={"searchCy"} onchange={setText} value={text} />
                <Filter
                  label1={"Resolved"}
                  label2={"Unresolved"}
                  filter1={resolvedFilter}
                  filter2={unresolvedFilter}
                  setFilter1={setResolvedFilter}
                  setFilter2={setUnresolvedFilter}
                />
              </div>
              {/* Student Table Header */}
              <div className={classes.student_table_header}>
                <div>TITLE</div>
                <div>AUTHOR</div>
                <div>CATEGORY</div>
                {/* <div>DESCRIPTION</div> */}
                <div>STATUS</div>
                <div>CREATED AT</div>
                <div>UPDATED AT</div>
                <div style={{ justifySelf: "center" }}>ACTION</div>
              </div>
              {/* Student Column-Rows Section */}
              <div id="schoolRowCy" className={classes.student_column_container}>
                {Array.isArray(displayData) &&
                  displayData
                    ?.slice(rowsPerPage * (pageNo - 1), rowsPerPage * pageNo)
                    .map((el, index) => {
                      return (
                        <BlogRow data={el} key={index + Date.now()} index={index} handlePublishBlog={handlePublishBlog} />
                      );
                    })}
              </div>
              <div className={classes.pagination_container}>
                <div className={classes.page_item}>{`Showing ${rowsPerPage * (pageNo - 1) + 1
                  } to ${pageNo * rowsPerPage <= displayData?.length
                    ? pageNo * rowsPerPage
                    : displayData?.length
                  } of ${displayData?.length} items`}</div>
                <div className={classes.pagination}>
                  <Button
                    size="small"
                    disabled={pageNo === 1}
                    onClick={() => setPageNo(pageNo - 1)}
                  >
                    <ChevronLeft />
                  </Button>
                  <span>{pageNo}</span>
                  <div>
                    <span>of</span>
                    <span>{Math.ceil(displayData?.length / rowsPerPage)}</span>
                  </div>
  
                  <div>
                    <Button
                      size="small"
                      disabled={pageNo === Math.ceil(displayData?.length / rowsPerPage)}
                      onClick={() => setPageNo(pageNo + 1)}
                    >
                      <ChevronRight />
                    </Button>
                  </div>
                </div>
              </div>
            </>
            :
            loading ?
              ""
              :
              <BlankDataScreen title="Oops! No data available" description="There is no data uploaded in this table to be shown. Click on Add Student to upload data." handleClick={() =>
                navigate('/superadmin-school/school', { state: { ...location.state, breadCrumbs: [...location.state.breadCrumbs, "Add School"] } })
              } buttonText="Add School" />
  
        }
        </div>
        {
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={!error && !loading ? "success" : "error"}
              sx={{ width: "100%" }}
            >
              {!error
                ? "Blog updated successfully"
                : "Something went wrong!"}
            </Alert>
          </Snackbar>
        }
        </>
    );
};

export default WriterDashboard;
