import React from "react";
import skidssuperman from "../../assets/images/SkidsSuperManLogo.svg";
import "./HealthReport.css";
import QRCode from "react-qr-code";


export const DynamicPages = ({ data, pageContent, currentPage, pages }) => {

  return (
    <>
      <div id="healthReport2" class="main">
        <div class="parent_container">
          <div class="parent_container_inner">
            <div>
              <img src={skidssuperman} alt="skidssuperman" />
              {/* <p>Skids Logo</p> */}
            </div>
            <div>
              <p>School Logo</p>
              <img src={skidssuperman} alt="skidssuperman" />
            </div>
          </div>
          {
            currentPage !== pages &&
            <div class="screening_test_header_container">
              <p>Physical Screening</p>
              <p>Results</p>
              <p>Bio. Ref. Interval</p>
              <p>Units</p>
              <p>Interpretation</p>
            </div>}
        </div>
        {
          pageContent ?? <></>
        }
        <div class="footer_container">
        <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={40}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>
              to look at your kids Physical & Behavioral detailed reports
            </div>
          </div>
        </div>
          <p>www.skids.health | support@skids.health</p>
          <p style={{fontSize:8}}>Page {currentPage} of {pages}</p>
        </div>
      </div>
    </>
  );
};
