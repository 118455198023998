import { Avatar } from "@mui/material";
import React, { useState } from "react";
import classes from "./TeacherRow.module.css";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { useLocation, useNavigate } from "react-router";
import RowPopup from "../TableComponents/RowPopup";
import { capitalizeFirstLetter, truncateString } from "../../../utils/utility";

export default function TeacherRows({ data }) {
  const [user, setUser] = useState(false);
  const [openPop, setOpenPop] = useState(false)
  const location = useLocation();
  const navigate = useNavigate()

  const { firstName, lastName } = data

  function navigateStudent(x) {
    // 

    if (location.pathname.includes('superadmin')) {
      if (x === 'info') {
        navigate('/superadmin/user/profile', { state: { ...data, breadCrumbs: [...location.state.breadCrumbs, `${data?.given_name} ${data?.family_name}`] } })
      }
      else if (x === 'edit') {
        navigate(`/superadmin/individual/edit/student`)
      }
    } else if (location.pathname.includes('principal')) {
      if (x === 'info') {
        navigate('/principal/user/profile', { state: { ...data, role: "teacher" } })
      }
      else if (x === 'edit') {
        navigate(`/principal/individual/edit/teacher`, { state: { ...data } })
      }
    } else if (location.pathname.includes('admin')) {
      if (x === 'info') {
        navigate('/admin/user/profile', { state: {...data, role: "teacher" } })
      }
      else if (x === 'edit') {
        navigate(`/principal/individual/edit/teacher`, { state: { ...data } })
      }
    }

  }


  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 12,
        height: 12,
        padding: 2
      },
      children: `${name[0]}`,
    };
  }


  const handleReport = () => {
    setUser(true)
  }

  return (
    <>
      <div className={classes.studentRow_container}>

        {/* Name and Avatar Container */}
        <div>
          {/* Avatar Container */}
          <div>
            <Avatar {...stringAvatar(`${data?.given_name} ${data?.family_name}`)} />
          </div>

          {/* Name Container */}
          <div>
            <p>{capitalizeFirstLetter(truncateString(`${data?.given_name} ${data?.family_name}`, 30))}</p>
          </div>
        </div>

        {/* Gender Container */}
        <div>
          <p>{data?.gender}</p>
        </div>

        {/* Class Container */}
        <div>
          <p>{`${data?.class}-${data?.section}`}</p>
        </div>

        {/* Email Container */}
        <div>
          <p>{truncateString(data?.email)}</p>

        </div>
        {/* Screening Container */}
        <div>
          <p>{data?.students ?? "-"}</p>
        </div>



        {/*  Validated Container */}
        <div>
          <p>{"teacher"}</p>
        </div>


        {/* Conditional Buttons Rendering --Role Section */}
        <div>
          {user ? (<button className={classes.screened_btn}>INACTIVE</button>) :
            (<button onClick={handleReport} className={classes.report_btn}>ACTIVE</button>)
          }
        </div>


        {/* Action Container */}
        <div style={{ position: "relative", cursor: "pointer", justifySelf: "center" }} onClick={() => setOpenPop(!openPop)}>
          <div className={classes.infoHover}>
            <p>...</p>
            <div className={classes.popOnHover}>
              <RowPopup navLink={navigateStudent} />
            </div>
          </div>
        </div>

      </div>

    </>
  );
}