import React, { useEffect, useState } from "react";
import UploadFile from "../../../BehavioralDoctorDashboard/AddLog/UploadFile";
import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Snackbar,
  TextField,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import classes from "../WelchZip/WelchZip.module.css";
import { useDispatch, useSelector } from "react-redux";
import schoolReducer from "../../../../redux/reducers/school.reducer";
import { useLocation } from "react-router-dom";
import screening from "../../../../graphqlApiServices/screening";
import axios from "axios";
import { Buffer } from "buffer";

const EntUpload = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedSchool, setSelectedSchool] = useState();
  const [uploadFile, setUploadFile] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const { uploadEntZip } = screening;

  const schools = useSelector((state) => state.schoolReducer.data);
  const getSchools = schoolReducer.actions.getSchoolsRequest;
  const getSchoolByState = schoolReducer.actions.getSchoolbyStateRequest;
  const user = useSelector((state) => state.userReducer.currentUser)

  useEffect(() => {
    if (user?.userAttributes && user?.userAttributes?.state) {
      dispatch(getSchoolByState({ state: user?.userAttributes?.state }));
    } else {
      dispatch(getSchools());
    }
  }, []);

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const toBase64 = () =>
    new Promise((resolve, reject) => {
      if (uploadFile) {
        const readers = new FileReader();
        readers.readAsArrayBuffer(uploadFile);
        readers.onload = () => {
          resolve(Buffer.from(readers.result));
        };
        readers.onerror = (error) => reject(error);
      }
    });

  const uploadZip = async () => {
    try {
      setLoading(true);
      const params = {
        schoolId: selectedSchool?.schoolId,
      };
      // const buffer = Buffer.from(uploadFile)
      const zip = await toBase64();
      const x = await uploadEntZip(params);
      console.log(x, "res");
      // console.log(x.data.getWelchallynUpload)
      const q = await axios.put(x.data.uploadEntData, zip);
      setOpen(true);
      setLoading(false);
      Reset();
    } catch (error) {
      setError(true);
    }
  };

  const Reset = () => {
    setUploadFile();
    setSelectedSchool("");
  };

  return (
    <div>
      <div>ENT Data Upload</div>
      <br />
      <div className={classes.modalBox}>
        <h1>Ent Data Zip File </h1>
        <h6>Select Date Range</h6>
        {schools && (
          <div style={{ display: "flex", gap: 10 }}>
            <FormControl>
              {/* <InputLabel id="demo-simple-select-label">School</InputLabel> */}
              <Autocomplete
                size="small"
                options={schools}
                value={selectedSchool}
                sx={{ width: 250 }}
                // placeholder={"School"}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label={"School"}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{option.schoolName}</li>
                )}
                getOptionLabel={(x) => {
                  return x?.schoolName ?? "";
                }}
                onChange={(e, value) => {
                  setSelectedSchool(value);
                }}
              />
            </FormControl>
          </div>
        )}
        <p>Please upload the ent data zip file.</p>
        <UploadFile
          uploadFile={uploadFile}
          setUploadFile={setUploadFile}
          fileType={".zip"}
        />
        <div className={classes.btnBox}>
          <Button
            sx={{
              background: "#1740A1",
              color: "#fff",
              ":hover": {
                background: "#1740A1",
              },
              ":disabled": {
                color: "#fff",
              },
            }}
            disabled={!uploadFile || loading}
            onClick={uploadZip}
          >
            {loading ? (
              <CircularProgress size={25} color="primary" />
            ) : (
              "Upload"
            )}
          </Button>
        </div>
        {/* <CloseRounded className={classes.closeBtn} onClick={Reset} /> */}
      </div>
      <Snackbar
        open={open && !loading}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={!error ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {!error
            ? "Data upload in progress..."
            : "Error While Uploading Data"}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EntUpload;
