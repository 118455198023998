import React from 'react'
import DashboardLayout from '../../common/DashboardLayout/DashboardLayout'
import School from '../../common/SchoolTable/SchoolTable'
import SchoolTable from '../../common/SchoolTable/SchoolTable'

export default function SuperAdminSchool() {

    return (
            <School /> 
    )
}