import { combineReducers } from "redux";
import authReducers from "./auth.reducers";
import clinincReducer from "./clininc.reducer";
import dashboardReducer from "./dashboard.reducer";
import schoolReducer from "./school.reducer";
import screeningReducer from "./screening.reducer";
import studentReducer from "./student.reducer";
import userReducer from "./user.reducer";
import eventReducer from "./event.reducer";
import validationReducer from "./validation.reducer";
import typeformReducer from "./typeform.reducer";
import incrementReducer from "./increment.reducer";
import analyticsReducers from "./analytics.reducers";
import supportReducer from "./support.reducer";
import blogReducer from "./blog.reducer";

const rootReducer = combineReducers({
    userReducer: userReducer.reducer,
    authReducer: authReducers.reducer,
    schoolReducer: schoolReducer.reducer,
    studentReducer: studentReducer.reducer,
    clinicReducer: clinincReducer.reducer,
    screeningReducer: screeningReducer.reducer,
    dashboardReducer: dashboardReducer.reducer,
    validationReducer : validationReducer.reducer,
    eventReducer: eventReducer.reducer,
    typeformReducer: typeformReducer.reducer,
    incrementReducer: incrementReducer.reducer,
    analyticsReducer: analyticsReducers.reducer,
    supportReducer: supportReducer.reducer,
    blogReducer: blogReducer.reducer
});

export default rootReducer;
