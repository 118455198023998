import React, { useEffect } from 'react'
import classes from './behavioralProfile.module.css'
import { Avatar, Typography } from '@mui/material'
import dob from '../../../assets/images/parentDob.svg'
import schoolImg from "../../../assets/images/address.svg";
import doctor from '../../../assets/images/doctorblue.svg'
import gender from '../../../assets/images/parentGender.svg'
import email from "../../../assets/images/email.svg";
import phone from '../../../assets/images/phone.svg'
import moment from 'moment'
import { stringAvatar, stringToColor } from '../../../utils/utility'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import studentReducer from '../../../redux/reducers/student.reducer'
import schoolReducer from '../../../redux/reducers/school.reducer'

function BehavioralProfile() {
    const location = useLocation();
    const dispatch = useDispatch();
    const getStudentById = studentReducer.actions.getStudentByIDRequest;
    const getSchoolById = schoolReducer.actions.getSchoolbyIdRequest;
    const studentData = useSelector((state) => state.studentReducer?.data);
    const school = useSelector((state) => state.schoolReducer.data)
    const data = location.state

    useEffect(() => {
        if (data?.patientId) {
            const params = {
                studentId: data?.patientId
            }
            dispatch(getStudentById(params));
        }
    }, [data]);

    useEffect(() => {
        if (studentData && studentData?.length > 0) {
            dispatch(getSchoolById({ schoolId: studentData[0].schoolId }))
        }
    }, [studentData]);

    return (
        <div className={classes.profileContainer}>
            <div className={classes.avatarContainer}>
                <Avatar {...stringAvatar(data?.name)} sx={{ height: '120px', width: '120px', fontSize: 70, margin: 'auto', bgcolor: stringToColor(data?.name) }} />
                <h1>{data?.name}</h1>
            </div>
            <Typography style={{marginTop: '3rem'}}>Personal Information</Typography>
            <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: 20, justifyContent: 'space-between', maxWidth: '800px' }}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20, marginTop: 20, width: '45%' }}>
                    <div className={classes.detailsContainer}>
                        <img src={gender} width={30} />
                        <div>
                            <h6>Gender</h6>
                            <Typography sx={{ fontSize: "14px" }}>{data?.gender}</Typography>
                        </div>
                    </div>
                    <div className={classes.detailsContainer}>
                        <img src={dob} width={30} />
                        <div>
                            <h6>Birthday</h6>
                            <Typography sx={{ fontSize: "14px" }}>{moment(data?.DOB).format("Do MMM, YYYY")}</Typography>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20, marginTop: 20, width: '45%' }}>
                    <div className={classes.detailsContainer}>
                        <img src={schoolImg} width={30} />
                        <div>
                            <h6>School Name</h6>
                            <Typography sx={{ fontSize: "14px" }}>{school?.schoolName}</Typography>
                        </div>
                    </div>
                    <div className={classes.detailsContainer}>
                        <img src={doctor} width={30} />
                        <div>
                            <h6>Screening Package</h6>
                            <Typography sx={{ fontSize: "14px" }}>{data?.package || school?.package}</Typography>
                        </div>
                    </div>
                </div>
            </div>
            <Typography style={{marginTop: '3rem'}}>Contact Information</Typography>
            <div style={{ display: "flex", flexDirection: "row", gap: 20, flexWrap: 'wrap',  justifyContent: 'space-between', maxWidth: '800px' }}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20, marginTop: 20, width: '45%' }}>
                    <div className={classes.detailsContainer}>
                        <img src={email} width={30} />
                        <div>
                            <h6>School's Email</h6>
                            <Typography sx={{ fontSize: "14px" }}>{studentData[0]?.fatherContact.email || studentData[0]?.motherContact?.email}</Typography>
                        </div>
                    </div>
                    <div className={classes.detailsContainer}>
                        <img src={phone} width={30} />
                        <div>
                            <h6>School's Phone</h6>
                            <Typography sx={{ fontSize: "14px" }}>{studentData[0]?.fatherContact?.mobile || studentData[0]?.motherContact?.mobile}</Typography>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20, marginTop: 20, width: '45%' }}>
                    <div className={classes.detailsContainer}>
                        <img src={email} width={30} />
                        <div>
                            <h6>School's Email</h6>
                            <Typography sx={{ fontSize: "14px" }}>{school?.contact?.email}</Typography>
                        </div>
                    </div>
                    <div className={classes.detailsContainer}>
                        <img src={phone} width={30} />
                        <div>
                            <h6>School's Phone</h6>
                            <Typography sx={{ fontSize: "14px" }}>{school?.contact?.mobile}</Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BehavioralProfile