import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from '../../../components/common/DashboardLayout/DashboardLayout'
import Infirmary from '../../../components/common/InfirmaryTable/Infirmary'
import userReducer from '../../../redux/reducers/user.reducer'

export default function SuperAdminUser(){

    const dispatch = useDispatch()

    const getUsers = userReducer.actions.getAllUsersRequest

    const data = useSelector((state)=> state.userReducer.users)
    
    useEffect(() => {
        dispatch(getUsers())
    }, [])    

  return (
        <Infirmary data={data} />
    )
}
